import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import React from 'react';
import { useRecoilState } from 'recoil';
import { termAtom } from '../../../../atoms/atoms';

function SearchBar() {
  const [term, setTerm] = useRecoilState(termAtom);

  return (
    <div className="w-full px-4 py-2 space-y-2 border-b border-neutral-200">
      <div className="relative">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon
            className="h-5 w-5 text-neutral-400"
            aria-hidden="true"
          />
        </div>
        <input
          id="search"
          name="search"
          type="search"
          value={term}
          onChange={(e) => setTerm(e.target.value)}
          className="bg-transparent dark:text-white font-medium block w-full border-0 py-1.5 pl-10 text-xs text-neutral-700 placeholder:text-neutral-400 ring-0 focus:ring-0 outline-none focus:outline-none"
          placeholder="Filtrar facilidades..."
        />
      </div>
    </div>
  );
}

export default SearchBar;
