import { RecoilRoot } from 'recoil';
import MapProvider from '../context/MapProvider';
import Layout from './sharedComponents/Layout';

function App() {
  return (
    <RecoilRoot>
      <MapProvider>
        <Layout />
      </MapProvider>
    </RecoilRoot>
  );
}

export default App;
