import { XMarkIcon } from '@heroicons/react/20/solid';
import { AnimatePresence, motion } from 'framer-motion';
import _ from 'lodash';
import { matchSorter } from 'match-sorter';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  dataAtom,
  sidebarAtom,
  subsetAtom,
  termAtom,
} from '../../../../atoms/atoms';
import IconButton from '../../IconButton';
import SearchBar from './SearchBar';
import Counter from './Counter';
import Legend from './Legend';
import Item from './Item';

function MobileSidebar() {
  const data = useRecoilValue(dataAtom);
  const subset = useRecoilValue(subsetAtom);
  const term = useRecoilValue(termAtom);
  const [open, setOpen] = useRecoilState(sidebarAtom);

  return (
    <AnimatePresence>
      {open && (
        <motion.aside
          id="mobileSidebar"
          initial={{ x: '-100%' }}
          animate={{ x: 0 }}
          exit={{ x: '-100%' }}
          transition={{ duration: 0.5 }}
          className="flex lg:hidden flex-col fixed inset-y-0 left-0 w-2/3 bg-white z-30 shadow-md"
        >
          <div className="flex justify-end p-4 border-b bg-neutral-50">
            <IconButton Icon={XMarkIcon} onClick={() => setOpen(false)} />
          </div>
          <SearchBar />
          <div className="flex-1 overflow-y-auto divide-y scrollbar-hide">
            {_.map(
              _.sortBy(
                matchSorter(subset?.features || data?.features, term, {
                  keys: [
                    'properties.name',
                    'properties.municipality',
                    'properties.address',
                  ],
                }),
                (item) => {
                  return (
                    item.properties?.route?.duration || item.properties.name
                  );
                }
              ),
              (feature) => (
                <Item key={feature.properties.id} feature={feature} />
              )
            )}
          </div>
          <Legend />
          <Counter />
        </motion.aside>
      )}
    </AnimatePresence>
  );
}

export default MobileSidebar;
