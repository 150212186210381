import * as turf from '@turf/turf';
import 'mapbox-gl/dist/mapbox-gl.css';
import { matchSorter } from 'match-sorter';
import { useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import _ from 'lodash';
import { isMobile } from 'react-device-detect';
import { AnimatePresence, motion } from 'framer-motion';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax, import/no-unresolved
import {
  activeAtom,
  dataAtom,
  filterAtom,
  loadingPositionAtom,
  municipalityAtom,
  routeAtom,
  stepAtom,
  subsetAtom,
  termAtom,
  waypointAtom,
} from '../../../atoms/atoms';
import { useMap } from '../../../context/MapProvider';
import boundaries from '../../../utils/boundaries';
import { formatDuration } from '../../../utils/functions';
import Loader from '../Loader';
import Progress from '../Progress';

mapboxgl.accessToken =
  'pk.eyJ1IjoicHJzY2llbmNldHJ1c3QiLCJhIjoiY2w1OGZpeWN5MXpkNTNkbjk4eG5mOXhibiJ9.3YTjKwV6V1h6C5DgLMQZVg';

function Map() {
  const { map, setMap } = useMap();
  const ref = useRef(null);
  const data = useRecoilValue(dataAtom);
  const subset = useRecoilValue(subsetAtom);
  const term = useRecoilValue(termAtom);
  const waypoint = useRecoilValue(waypointAtom);
  const step = useRecoilValue(stepAtom);
  const filter = useRecoilValue(filterAtom);
  const loading = useRecoilValue(loadingPositionAtom);
  const route = useRecoilValue(routeAtom);
  const setSubset = useSetRecoilState(subsetAtom);
  const [active, setActive] = useRecoilState(activeAtom);
  const [municipality, setMunicipality] = useRecoilState(municipalityAtom);

  const popup = new mapboxgl.Popup({
    closeButton: false,
    maxWidth: 300,
  });

  useEffect(() => {
    // eslint-disable-next-line no-shadow
    const initializeMap = ({ setMap, ref }) => {
      // eslint-disable-next-line no-shadow
      const map = new mapboxgl.Map({
        container: ref.current,
        style: 'mapbox://styles/mapbox/streets-v12',
        center: [-66.35535, 18.225],
        zoom: 8.5,
        attributionControl: false,
        preserveDrawingBuffer: true,
      });

      map.addControl(new mapboxgl.NavigationControl());
      map.addControl(new mapboxgl.FullscreenControl());
      const geolocate = new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        fitBoundsOptions: {
          linear: false,
        },
        trackUserLocation: false,
      });
      map.addControl(geolocate);

      map.on('load', () => {
        setMap(map);
        if (isMobile) {
          geolocate.trigger();
          geolocate.on('geolocate', (e) => {
            map.flyTo({
              zoom: 13,
              center: [e.coords.longitude, e.coords.latitude],
            });
          });
        }
      });

      map.on('style.load', () => {
        map.addSource('municipality', {
          type: 'geojson',
          data: municipality,
        });

        map.addSource('geolocation', {
          type: 'geojson',
          data: null,
        });

        map.addSource('route', {
          type: 'geojson',
          data: null,
        });

        map.addSource('waypoint', {
          type: 'geojson',
          data: null,
        });

        map.addSource('step', {
          type: 'geojson',
          data: step,
        });

        map.addSource('shelters', {
          type: 'geojson',
          data: subset,
        });

        map.addSource('buffer', {
          type: 'geojson',
          data: null,
        });

        map.addLayer({
          id: 'municipality',
          type: 'fill',
          source: 'municipality',
          paint: {
            'fill-opacity': 0.25,
            'fill-color': '#fb923c',
          },
        });

        map.addLayer({
          id: 'municipality-border',
          type: 'line',
          source: 'municipality',
          paint: {
            'line-width': 2,
            'line-color': '#ffffff',
            'line-dasharray': [2, 2],
          },
        });

        map.addLayer(
          {
            id: 'buffer',
            type: 'fill',
            source: 'buffer',
            paint: {
              'fill-opacity': 0.25,
              'fill-color': '#f43f5e',
            },
          },
          'water-line-label'
        );

        map.addLayer({
          id: 'route-casing',
          type: 'line',
          source: 'route',
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#2d5f99',
            'line-width': 12,
          },
        });

        map.addLayer({
          id: 'route',
          type: 'line',
          source: 'route',
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#4882c5',
            'line-width': 7,
          },
        });

        map.addLayer({
          id: 'shelters',
          type: 'circle',
          source: 'shelters',
          paint: {
            'circle-color': [
              'match',
              ['get', 'category'],
              'shelter',
              '#fb923c',
              'clinic',
              '#3bb2d0',
              '#ccc',
            ],
            'circle-radius': {
              stops: [
                [8.5, 4],
                [16, 10],
              ],
            },
            'circle-stroke-width': 2,
            'circle-stroke-color': '#ffffff',
          },
        });

        map.addLayer({
          id: 'icon',
          type: 'symbol',
          source: 'shelters',
          minzoom: 13,
          layout: {
            'text-field': ['get', 'name'],
            'text-font': ['Open Sans SemiBold', 'Arial Unicode MS Bold'],
            'text-size': 12,
            'text-offset': [0, 2.5],
          },
          paint: {
            'text-color': '#171717',
            'text-halo-color': '#fff',
            'text-halo-width': 2,
          },
        });

        map.addLayer({
          id: 'geolocation',
          type: 'circle',
          source: 'geolocation',
          paint: {
            'circle-color': '#3bb2d0',
            'circle-radius': 12,
            'circle-stroke-width': 4,
            'circle-stroke-color': '#ffffff',
          },
        });

        map.addLayer({
          id: 'geolocation-label',
          type: 'symbol',
          source: 'geolocation',
          layout: {
            'text-field': 'A',
            'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
            'text-size': 12,
          },
          paint: {
            'text-color': '#fff',
          },
        });

        map.addLayer({
          id: 'waypoint',
          type: 'circle',
          source: 'waypoint',
          paint: {
            'circle-color': '#8a8bc9',
            'circle-radius': 12,
            'circle-stroke-width': 4,
            'circle-stroke-color': '#ffffff',
          },
        });

        map.addLayer({
          id: 'waypoint-label',
          type: 'symbol',
          source: 'waypoint',
          layout: {
            'text-field': 'B',
            'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
            'text-size': 12,
          },
          paint: {
            'text-color': '#fff',
          },
        });

        map.addLayer({
          id: 'step',
          type: 'circle',
          source: 'step',
          paint: {
            'circle-color': '#0ea5e9',
            'circle-radius': 12,
            'circle-stroke-width': 4,
            'circle-stroke-color': '#ffffff',
          },
        });

        map.on('click', 'shelters', (e) => {
          if (e.features[0]) {
            setActive({
              type: 'Feature',
              properties: e.features[0].properties,
              geometry: {
                coordinates: [
                  e.features[0].properties.lng,
                  e.features[0].properties.lat,
                ],
                type: 'Point',
              },
            });
          }
        });

        map.on('mouseenter', 'shelters', () => {
          map.getCanvas().style.cursor = 'pointer';
        });

        map.on('mouseleave', 'shelters', () => {
          map.getCanvas().style.cursor = '';
        });

        map.on('mousemove', 'route', (e) => {
          map.getCanvas().style.cursor = 'pointer';
          const feature = e.features[0];
          const props = feature.properties;

          const { hours, minutes, seconds } = formatDuration(props.duration);

          let html =
            '<div class="text-neutral-500 font-mono text-xs max-w-[240px]">';
          html += `<div class="flex flex-row">`;
          html += `<span class="font-semibold text-neutral-700 tracking-tight mr-1">Duración:</span>`;
          if (hours > 0) {
            html += `<span class="mr-1">${hours} hrs.,</span>`;
          }
          if (minutes > 0) {
            html += `<span class="mr-1">${minutes} mins.,</span>`;
          }
          if (seconds > 0) {
            html += `<span>${seconds} segs.</span>`;
          }
          html += `</div>`;
          html += `<div><span class="font-semibold text-neutral-700 tracking-tight">Distancia:</span> ${`${(
            props.distance / 1000
          ).toFixed(1)} km | ${(props.distance / 1609.344).toFixed(
            1
          )} mi`}</div>`;
          html += '</div>';
          popup.setLngLat(e.lngLat).setHTML(html).addTo(map);
        });

        map.on('mouseleave', 'route', () => {
          map.getCanvas().style.cursor = '';
          popup.remove();
        });
      });
    };

    if (!map) initializeMap({ setMap, ref });
  }, [map, setMap]);

  useEffect(() => {
    const features = matchSorter(subset?.features, term, {
      keys: [
        'properties.name',
        'properties.municipality',
        'properties.address',
      ],
    });

    if (map) {
      map.getSource('shelters').setData({
        type: 'FeatureCollection',
        features,
      });
    }
  }, [term, map]);

  useEffect(() => {
    if (active) {
      const el = document.getElementById(`facility-${active?.properties?.id}`);
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    }

    if (active) {
      let html = '<div class="font-mono text-neutral-500 max-w-[240px]">';
      html += `<div class="text-xs tracking-tight leading-normal font-semibold text-neutral-700">${active?.properties?.name}</div>`;
      html += `<div>${active?.properties?.address}</div>`;
      html += `<div>${active?.properties?.municipality}</div>`;
      html += '</div>';

      popup
        .setLngLat([
          active?.geometry?.coordinates[0],
          active?.geometry?.coordinates[1],
        ])
        .setHTML(html)
        .addTo(map);

      if (!waypoint) {
        map.flyTo({
          center: active?.geometry?.coordinates,
          zoom: 16,
          pitch: 0,
        });
      }
    }

    return popup.remove;
  }, [active]);

  useEffect(() => {
    if (!_.isEmpty(filter)) {
      const filteredData = {
        type: 'FeatureCollection',
        features: _.filter(data.features, {
          properties: filter,
        }),
      };

      const filteredMunicipality = {
        type: 'FeatureCollection',
        features: _.filter(boundaries.features, (e) => {
          return e.properties.name === filter.municipality;
        }),
      };

      map.getSource('municipality').setData(filteredMunicipality);
      map.getSource('shelters').setData(filteredData);
      setSubset(filteredData);
      setMunicipality(filteredMunicipality);
      const bbox = turf.bbox(filteredMunicipality);
      map.fitBounds(bbox, {
        linear: true,
        easing(x) {
          return x * x * x;
        },
        padding: 100,
      });
    }
  }, [filter]);

  return (
    <div
      ref={ref}
      className="relative w-full"
      style={{ height: `calc('100vh' - 120px)` }}
    >
      {route && <Progress />}
      <AnimatePresence>
        {loading && (
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0 }}
            transition={{ delay: 1 }}
            className="flex w-full h-full items-center justify-center absolute z-50 indent-0"
          >
            <Loader dark large />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default Map;
