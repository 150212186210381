import {
  ArrowsPointingInIcon,
  Bars3Icon,
  FunnelIcon,
  GlobeAmericasIcon,
} from '@heroicons/react/24/solid';
import React, { useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import prpht from '../../../assets/img/prpht-logo.svg';
import {
  activeAtom,
  bufferAtom,
  filterAtom,
  geoLocationAtom,
  municipalityAtom,
  routeAtom,
  showAtom,
  sidebarAtom,
  stepAtom,
  subsetAtom,
  waypointAtom,
} from '../../../atoms/atoms';
import { useMap } from '../../../context/MapProvider';
import municipalities from '../../../utils/municipalities';
import Dropdown from '../Dropdown';
import IconButton from '../IconButton';
import Restore from './sharedComponents/Restore';
import TravelTimes from './sharedComponents/TravelTimes';
import Buffer from './sharedComponents/Buffer';

function Header() {
  const { map } = useMap();
  const [buffer, setBuffer] = useRecoilState(bufferAtom);
  const subset = useRecoilValue(subsetAtom);
  const municipality = useRecoilValue(municipalityAtom);
  const setShow = useSetRecoilState(showAtom);
  const [filter, setFilter] = useRecoilState(filterAtom);
  const [step, setStep] = useRecoilState(stepAtom);
  const [geolocation, setGeolocation] = useRecoilState(geoLocationAtom);
  const [open, setOpen] = useRecoilState(sidebarAtom);
  const [route, setRoute] = useRecoilState(routeAtom);
  const [waypoint, setWaypoint] = useRecoilState(waypointAtom);
  const setActive = useSetRecoilState(activeAtom);
  const [style, setStyle] = useState('streets-v12');

  const emptyGeoJSON = {
    type: 'FeatureCollection',
    features: [],
  };

  const options = [
    {
      text: 'Mapa de Carreteras',
      value: 'streets-v12',
      image: require('../../../assets/img/streets-v12.png'),
    },
    {
      text: 'Mapa Claro',
      value: 'light-v11',
      image: require('../../../assets/img/light-v11.png'),
    },
    {
      text: 'Mapa Obscuro',
      value: 'dark-v11',
      image: require('../../../assets/img/dark-v11.png'),
    },
    {
      text: 'Relieve',
      value: 'outdoors-v12',
      image: require('../../../assets/img/outdoors-v12.png'),
    },
    {
      text: 'Imagen de Satélite',
      value: 'satellite-streets-v12',
      image: require('../../../assets/img/satellite-streets-v12.png'),
    },
  ];

  return (
    <header className="h-20 flex flex-shrink-0 bg-neutral-800 text-white px-4 gap-x-4 dark">
      <div className="flex flex-1 items-center space-x-2 bg-neutral-800 h-20 text-xs">
        <img alt="PRPHT logo" src={prpht} />
        <div className="hidden lg:flex lg:flex-col">
          <div className="text-base font-bold text-white">
            Salud Comunitaria
          </div>
          <div className="font-medium">
            Fideicomiso de Salud Pública de Puerto Rico
          </div>
        </div>
      </div>
      <div className="flex items-center gap-x-2">
        <Restore />
        <TravelTimes />
        <Dropdown
          checkmark
          searchable
          center
          Icon={FunnelIcon}
          value={filter?.municipality}
          tooltip="Filtrar por municipios"
          tooltipPlacement="bottom"
          options={municipalities}
          onClick={(value) => {
            setFilter({
              ...filter,
              municipality: value,
            });

            setShow(true);
            setRoute(null);
            setActive(null);
            setStep(null);
            setBuffer(null);
            setGeolocation(null);
            setWaypoint(null);

            if (map) {
              map.getSource('buffer').setData(emptyGeoJSON);
              map.getSource('geolocation').setData(emptyGeoJSON);
              map.getSource('route').setData(emptyGeoJSON);
              map.getSource('waypoint').setData(emptyGeoJSON);
              map.getSource('step').setData(emptyGeoJSON);
            }
          }}
        />
        <Buffer />
        <IconButton
          Icon={ArrowsPointingInIcon}
          tooltip="Estado original del mapa"
          tooltipPlacement="bottom"
          onClick={() => {
            map.flyTo({
              center: [-66.35535, 18.225],
              zoom: 8.5,
              bearing: 0,
              pitch: 0,
            });
          }}
        />
        <Dropdown
          value={style}
          options={options}
          Icon={GlobeAmericasIcon}
          tooltip="Cambiar mapa base"
          tooltipPlacement="left"
          onClick={(val) => {
            setStyle(val);
            map.setStyle(`mapbox://styles/mapbox/${val}`);

            map.on('style.load', () => {
              if (map.getSource('buffer')) {
                map.getSource('buffer').setData(buffer);
              }

              if (map.getSource('geolocation')) {
                map.getSource('geolocation').setData(geolocation);
              }

              if (map.getSource('route')) {
                map.getSource('route').setData(route);
              }

              if (map.getSource('shelters')) {
                map.getSource('shelters').setData(subset);
              }

              if (map.getSource('waypoint')) {
                map.getSource('waypoint').setData(waypoint);
              }

              if (map.getSource('municipality')) {
                map.getSource('municipality').setData(municipality);
              }

              if (map.getSource('step')) {
                map.getSource('step').setData(step);
              }
            });
          }}
        />
        <IconButton
          Icon={Bars3Icon}
          tooltip="Menu"
          onClick={() => setOpen(!open)}
          className="flex lg:hidden"
          tooltipPlacement="bottom"
        />
      </div>
    </header>
  );
}

export default Header;
