import { Menu, Transition } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import _ from 'lodash';
import { matchSorter } from 'match-sorter';
import { Fragment, useState } from 'react';
import Tooltip from './Tooltip';

function Dropdown({
  options,
  onClick,
  Icon,
  tooltip,
  tooltipPlacement,
  className,
  checkmark,
  value,
  center,
  searchable,
}) {
  const [term, setTerm] = useState('');
  const filteredOptions = searchable
    ? matchSorter(options, term, { keys: ['text'] })
    : options;

  const renderedOptions = _.map(filteredOptions, (option) => {
    return (
      <Menu.Item key={option.value}>
        <button
          type="button"
          onClick={() => {
            if (onClick) {
              onClick(option.value);
            }
          }}
          disabled={value === option.value}
          className={clsx(
            value === option.value && 'bg-neutral-100',
            'text-neutral-700 group flex w-full items-center font-semibold rounded-full px-2 py-2 text-xs hover:bg-neutral-100'
          )}
        >
          {option.image && (
            <div
              style={{
                backgroundImage: `url("${option.image}")`,
              }}
              className="mr-2 h-8 w-8 bg-cover bg-center rounded-full ring-2 ring-neutral-200"
            />
          )}
          {checkmark && (
            <CheckCircleIcon
              className={clsx(
                value === option.value ? 'text-green-500' : 'text-neutral-200',
                'w-4 h-4 mr-2'
              )}
            />
          )}
          {option.text}
        </button>
      </Menu.Item>
    );
  });

  const noResultsMessage = (
    <Menu.Item>
      <span className="block py-2 text-xs italic text-neutral-500">
        No results found
      </span>
    </Menu.Item>
  );

  return (
    <Menu as="div" className="relative">
      <Tooltip content={tooltip} placement={tooltipPlacement}>
        <Menu.Button
          className={clsx(
            className,
            'flex items-center justify-center flex-shrink-0 disabled:opacity-50 text-neutral-700 dark:text-neutral-200 font-semibold text-tiny border border-neutral-200 dark:border-neutral-700 hover:bg-neutral-200 hover:dark:bg-neutral-700 h-10 w-10 rounded-full right-0 focus:right-0 outline-none focus:outline-none transition duration-500 ease-in-out'
          )}
        >
          {Icon && <Icon className="h-4 w-4" />}
        </Menu.Button>
      </Tooltip>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={clsx(
            center ? 'translate-x-1/2 origin-center' : 'origin-top-right',
            'absolute mt-2 right-0 w-56 max-h-96 overflow-y-auto scrollbar-hide space-y-0.5 z-50 rounded-2xl bg-white shadow-lg focus:ring-0 focus:outline-none'
          )}
        >
          {searchable && (
            <div className="flex text-neutral-500 placeholder:text-neutral-500 sticky top-0 w-full rounded-t-2xl mb-2 border-b bg-neutral-100 p-2">
              <input
                type="search"
                value={term}
                onChange={(e) => setTerm(e.target.value)}
                placeholder="Buscar..."
                className="w-full rounded-full border border-neutral-200 focus:border-neutral-300 focus:ring-0 text-xs "
              />
            </div>
          )}
          <div className="p-2">
            {renderedOptions.length === 0 ? noResultsMessage : renderedOptions}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default Dropdown;
