import DesktopSidebar from './sharedComponents/DesktopSidebar';
import MobileSidebar from './sharedComponents/MobileSidebar';

function Sidebar() {
  return (
    <>
      <MobileSidebar />
      <DesktopSidebar />
    </>
  );
}

export default Sidebar;
