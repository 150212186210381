import { motion } from 'framer-motion';
import IconButton from './IconButton';
import { Car } from './Icons';
import { formatDuration } from '../../utils/functions';

function Driving({ duration, distance, onClick }) {
  const { hours, minutes, seconds } = formatDuration(duration);

  return (
    <motion.div
      initial={{ opacity: 0, x: '100%' }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: '100%' }}
      className="flex flex-shrink-0 gap-2 items-center font-semibold text-neutral-700 dark:text-neutral-200 text-tiny"
    >
      <IconButton
        Icon={Car}
        tooltip="Ver ruta en el mapa"
        tooltipPlacement="right"
        onClick={onClick}
      />
      <div className="hidden lg:flex lg:flex-col">
        <div className="flex flex-col">
          {hours > 0 && `${hours} horas, `}
          {minutes > 0 && `${minutes} minutos, `}
          {seconds > 0 && `${seconds} segundos`}
        </div>
        <div>
          Distancia:{' '}
          {`${(distance / 1000).toFixed(1)} km | ${(
            distance / 1609.344
          ).toFixed(1)} mi`}
        </div>
      </div>
    </motion.div>
  );
}

export default Driving;
