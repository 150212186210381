import { Transition } from '@headlessui/react';
import { TrashIcon } from '@heroicons/react/24/solid';
import { Fragment } from 'react';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';
import {
  bufferAtom,
  dataAtom,
  filterAtom,
  geoLocationAtom,
  municipalityAtom,
  routeAtom,
  showAtom,
  stepAtom,
  subsetAtom,
  waypointAtom,
} from '../../../../atoms/atoms';
import { useMap } from '../../../../context/MapProvider';

function Restore() {
  const { map } = useMap();
  const data = useRecoilValue(dataAtom);
  const resetStep = useResetRecoilState(stepAtom);
  const resetFilter = useResetRecoilState(filterAtom);
  const setMunicipality = useSetRecoilState(municipalityAtom);
  const setSubset = useSetRecoilState(subsetAtom);
  const setGeolocation = useSetRecoilState(geoLocationAtom);
  const setWaypoint = useSetRecoilState(waypointAtom);
  const setRoute = useSetRecoilState(routeAtom);
  const setBuffer = useSetRecoilState(bufferAtom);
  const [show, setShow] = useRecoilState(showAtom);

  return (
    <Transition
      as={Fragment}
      show={show}
      enter="transform transition ease-in-out duration-500 duration-700"
      enterFrom="opacity-0 translate-x-4"
      enterTo="opacity-100 translate-x-0"
      leave="transform transition ease-in-out duration-700"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <button
        type="button"
        className="flex items-center justify-center space-x-1 rounded-full h-10 w-10 lg:w-40 text-xs font-sans font-semibold text-white bg-rose-500 hover:bg-rose-400 ring-0 focus:ring-0 outline-none focus:outline-none transition duration-500 ease-in-out"
        onClick={() => {
          const emptyGeoJSON = {
            type: 'FeatureCollection',
            features: [],
          };

          setShow(false);
          setSubset(data);
          setRoute(null);
          setGeolocation(null);
          setWaypoint(null);
          setBuffer(null);
          setMunicipality(null);

          resetFilter();
          resetStep();

          if (map) {
            map.getSource('shelters').setData(data);
            map.getSource('buffer').setData(emptyGeoJSON);
            map.getSource('geolocation').setData(emptyGeoJSON);
            map.getSource('route').setData(emptyGeoJSON);
            map.getSource('waypoint').setData(emptyGeoJSON);
            map.getSource('municipality').setData(emptyGeoJSON);
            map.getSource('step').setData(emptyGeoJSON);

            map.flyTo({
              center: [-66.35535, 18.225],
              zoom: 8.5,
              bearing: 0,
              pitch: 0,
            });
          }
        }}
      >
        <TrashIcon className="w-4 h-4" />
        <span className="hidden lg:flex">Eliminar Selección</span>
      </button>
    </Transition>
  );
}

export default Restore;
