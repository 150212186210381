import clsx from 'clsx';
import { motion } from 'framer-motion';
import Loader from './Loader';
import Tooltip from './Tooltip';

function IconButton({
  Icon,
  onClick,
  className,
  tooltip,
  tooltipPlacement,
  loading,
  disabled,
}) {
  return (
    <Tooltip content={tooltip} placement={tooltipPlacement}>
      <motion.button
        whileHover={{ scale: 0.9 }}
        whileTap={{ scale: 0.75 }}
        type="button"
        onClick={onClick}
        disabled={disabled}
        className={clsx(
          className,
          'flex items-center justify-center flex-shrink-0 disabled:opacity-50 text-neutral-700 dark:text-neutral-200 font-semibold text-tiny border border-neutral-200 dark:border-neutral-700 hover:bg-neutral-200 hover:dark:bg-neutral-700 h-10 w-10 rounded-full right-0 focus:right-0 outline-none focus:outline-none transition duration-500 ease-in-out'
        )}
      >
        {loading ? <Loader /> : <Icon className="h-4 w-4" />}
      </motion.button>
    </Tooltip>
  );
}

export default IconButton;
