import { Fragment, useState } from 'react';
import { ClockIcon } from '@heroicons/react/24/solid';
import { Popover, RadioGroup, Transition } from '@headlessui/react';
import clsx from 'clsx';
import axios from 'axios';
import { motion } from 'framer-motion';
import * as turf from '@turf/turf';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { isMobile } from 'react-device-detect';
import { useMap } from '../../../../context/MapProvider';

import {
  activeAtom,
  bufferAtom,
  dataAtom,
  filterAtom,
  geoLocationAtom,
  loadingPositionAtom,
  municipalityAtom,
  positionAtom,
  routeAtom,
  showAtom,
  sidebarAtom,
  stepAtom,
  subsetAtom,
  waypointAtom,
} from '../../../../atoms/atoms';
import Loader from '../../Loader';
import Tooltip from '../../Tooltip';
import { Biking, Car, Walking } from '../../Icons';

function TravelTimes() {
  const { map } = useMap();
  const data = useRecoilValue(dataAtom);
  const setFilter = useSetRecoilState(filterAtom);
  const setPosition = useSetRecoilState(positionAtom);
  const setMunicipality = useSetRecoilState(municipalityAtom);
  const setActive = useSetRecoilState(activeAtom);
  const setOpen = useSetRecoilState(sidebarAtom);
  const setSubset = useSetRecoilState(subsetAtom);
  const setBuffer = useSetRecoilState(bufferAtom);
  const setGeolocation = useSetRecoilState(geoLocationAtom);
  const setWaypoint = useSetRecoilState(waypointAtom);
  const setRoute = useSetRecoilState(routeAtom);
  const setStep = useSetRecoilState(stepAtom);
  const setShow = useSetRecoilState(showAtom);
  const [profile, setProfile] = useState('driving');
  const [minutes, setMinutes] = useState('10');
  const [loading, setLoading] = useRecoilState(loadingPositionAtom);

  const token =
    'pk.eyJ1IjoicHJzY2llbmNldHJ1c3QiLCJhIjoiY2w1OGZpeWN5MXpkNTNkbjk4eG5mOXhibiJ9.3YTjKwV6V1h6C5DgLMQZVg';

  const emptyGeoJSON = {
    type: 'FeatureCollection',
    features: [],
  };

  const modeOptions = [
    {
      text: 'Caminando',
      value: 'walking',
      Icon: Walking,
    },
    {
      text: 'Automóvil',
      value: 'driving',
      Icon: Car,
    },
    {
      text: 'Bicicleta',
      value: 'cycling',
      Icon: Biking,
    },
  ];

  const minutesOptions = [
    {
      text: '10 mins',
      value: '10',
    },
    {
      text: '20 mins',
      value: '20',
    },
    {
      text: '30 mins',
      value: '30',
    },
  ];

  const getRoute = async (originLng, originLat, waypointLng, waypointLat) => {
    const res = await axios.get(
      `https://api.mapbox.com/directions/v5/mapbox/driving-traffic/${originLng},${originLat};${waypointLng},${waypointLat}?steps=true&alternatives=false&geometries=geojson&language=es&access_token=${token}`
    );
    return res.data.routes[0];
  };

  const getIsochrone = async (lng, lat) => {
    const location = turf.point([lng, lat]);
    const url = 'https://api.mapbox.com/isochrone/v1/mapbox';
    const b = await axios.get(
      `${url}/${profile}/${lng},${lat}?contours_minutes=${minutes}&polygons=true&access_token=${token}`
    );

    const box = turf.bbox(b.data);

    map.fitBounds(box, {
      linear: true,
      easing(x) {
        return x * x * x;
      },
      padding: { top: 100, bottom: 100, left: 100, right: 100 },
    });

    map.getSource('buffer').setData(b.data);

    map.getSource('geolocation').setData(location);

    const geojson = {
      type: 'FeatureCollection',
      features: [],
    };

    for (let i = 0; i < data.features.length; i++) {
      if (data.features[i].properties.lat && data.features[i].properties.lng) {
        const pts = turf.booleanPointInPolygon(
          data.features[i],
          b.data.features[0]
        );

        if (pts) {
          const dir = await getRoute(
            lng,
            lat,
            data.features[i].properties.lng,
            data.features[i].properties.lat
          );

          geojson.features.push(
            turf.point(
              [
                data.features[i].properties.lng,
                data.features[i].properties.lat,
              ],
              {
                ...data.features[i].properties,
                route: dir,
              }
            )
          );
        }
      }
    }

    map.getSource('shelters').setData(geojson);

    setSubset(geojson);
    setGeolocation(location);
    setBuffer(b.data);
    setShow(true);
    setLoading(false);
    if (isMobile) {
      setOpen(true);
    }
  };

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Tooltip
            content="Filtrar por tiempo y método de transporte"
            placement="bottom"
          >
            <Popover.Button
              disabled={loading}
              className={clsx(
                open ? '' : '',
                'flex items-center justify-center flex-shrink-0 disabled:opacity-50 text-neutral-700 dark:text-neutral-200 font-semibold text-tiny border border-neutral-200 dark:border-neutral-700 hover:bg-neutral-200 hover:dark:bg-neutral-700 h-10 w-10 rounded-full right-0 focus:right-0 outline-none focus:outline-none transition duration-500 ease-in-out'
              )}
            >
              {loading ? <Loader /> : <ClockIcon className="w-4 h-4" />}
            </Popover.Button>
          </Tooltip>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Popover.Panel className="absolute right-0 z-50 mt-2 w-screen max-w-sm translate-x-1/2 origin-center transform text-neutral-700 text-xs">
              <div className="ring-0 focus:ring-0 outline-none focus:outline-none">
                <div className="divide-y divide-neutral-100 relative rounded-2xl shadow-lg bg-white">
                  <div className="p-4 space-y-4">
                    <div className="text-neutral-500">
                      Utiliza las siguientes opciones para encontrar facilidades
                      cercanas a su localización.
                    </div>
                    <div className="font-semibold">Modo de Transporte:</div>
                    <RadioGroup
                      value={profile}
                      onChange={setProfile}
                      className="grid grid-cols-3 gap-2"
                    >
                      {_.map(modeOptions, (option) => (
                        <RadioGroup.Option
                          key={option.value}
                          value={option.value}
                          className="ring-0 focus:ring-0 outline-none focus:outline-none"
                        >
                          {({ checked }) => (
                            <>
                              <motion.div
                                className={clsx(
                                  checked
                                    ? 'bg-neutral-700 text-white'
                                    : 'hover:bg-neutral-100',
                                  'flex text-tiny items-center justify-center cursor-pointer rounded-md px-4 py-3 font-semibold border transition duration-500 ease-in-out'
                                )}
                              >
                                <option.Icon />
                              </motion.div>
                              {checked ? (
                                <motion.div
                                  className="absolute border-2 left-0 ring-0 h-1 bg-red-500 -bottom-1"
                                  layoutId="underline"
                                />
                              ) : null}
                            </>
                          )}
                        </RadioGroup.Option>
                      ))}
                    </RadioGroup>
                  </div>
                  <div className="p-4 space-y-4">
                    <div className="font-semibold">Duración máxima:</div>
                    <RadioGroup
                      value={minutes}
                      onChange={setMinutes}
                      className="grid grid-cols-3 gap-2"
                    >
                      {_.map(minutesOptions, (option) => (
                        <RadioGroup.Option
                          key={option.value}
                          value={option.value}
                          className="relative ring-0 focus:ring-0 outline-none focus:outline-none"
                        >
                          {({ checked }) => (
                            <div
                              className={clsx(
                                checked
                                  ? 'bg-neutral-700 text-white'
                                  : 'hover:bg-neutral-100',
                                'flex text-tiny items-center justify-center cursor-pointer rounded-md px-4 py-3 font-semibold border transition duration-500 ease-in-out'
                              )}
                            >
                              {option.text}
                            </div>
                          )}
                        </RadioGroup.Option>
                      ))}
                    </RadioGroup>
                  </div>
                  <div className="p-4 grid grid-cols-3 gap-2">
                    <div />
                    <div />
                    <Popover.Button
                      onClick={() => {
                        navigator.geolocation.getCurrentPosition(
                          (res) => {
                            setPosition(res.coords);
                            setWaypoint(null);
                            setStep(null);
                            setRoute(null);
                            setMunicipality(null);
                            setLoading(true);
                            setFilter({});
                            setActive(null);
                            map.getSource('municipality').setData(emptyGeoJSON);
                            map.getSource('route').setData(emptyGeoJSON);
                            map.getSource('waypoint').setData(emptyGeoJSON);
                            map.getSource('step').setData(emptyGeoJSON);
                            getIsochrone(
                              res.coords.longitude,
                              res.coords.latitude
                            );
                          },
                          (err) => console.err(err),
                          {
                            maximumAge: 60000,
                            timeout: 5000,
                            enableHighAccuracy: true,
                          }
                        );
                      }}
                      className="flex items-center justify-center gap-1 px-4 py-3 font-semibold text-white bg-orange-400 hover:bg-orange-500 rounded ring-0 focus:ring-0 outline-none focus:outline-none transition duration-500 ease-in-out"
                    >
                      <div>Filtrar Datos</div>
                    </Popover.Button>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

export default TravelTimes;
