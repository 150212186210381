const municipalities = [
  { text: 'Adjuntas', value: 'Adjuntas' },
  { text: 'Aguada', value: 'Aguada' },
  { text: 'Aguadilla', value: 'Aguadilla' },
  { text: 'Aguas Buenas', value: 'Aguas Buenas' },
  { text: 'Aibonito', value: 'Aibonito' },
  { text: 'Añasco', value: 'Añasco' },
  { text: 'Arecibo', value: 'Arecibo' },
  { text: 'Arroyo', value: 'Arroyo' },
  { text: 'Barceloneta', value: 'Barceloneta' },
  { text: 'Barranquitas', value: 'Barranquitas' },
  { text: 'Bayamón', value: 'Bayamón' },
  { text: 'Cabo Rojo', value: 'Cabo Rojo' },
  { text: 'Caguas', value: 'Caguas' },
  { text: 'Camuy', value: 'Camuy' },
  { text: 'Canóvanas', value: 'Canóvanas' },
  { text: 'Carolina', value: 'Carolina' },
  { text: 'Cataño', value: 'Cataño' },
  { text: 'Cayey', value: 'Cayey' },
  { text: 'Ceiba', value: 'Ceiba' },
  { text: 'Ciales', value: 'Ciales' },
  { text: 'Cidra', value: 'Cidra' },
  { text: 'Coamo', value: 'Coamo' },
  { text: 'Comerío', value: 'Comerío' },
  { text: 'Corozal', value: 'Corozal' },
  { text: 'Culebra', value: 'Culebra' },
  { text: 'Dorado', value: 'Dorado' },
  { text: 'Fajardo', value: 'Fajardo' },
  { text: 'Florida', value: 'Florida' },
  { text: 'Guánica', value: 'Guánica' },
  { text: 'Guayama', value: 'Guayama' },
  { text: 'Guayanilla', value: 'Guayanilla' },
  { text: 'Guaynabo', value: 'Guaynabo' },
  { text: 'Gurabo', value: 'Gurabo' },
  { text: 'Hatillo', value: 'Hatillo' },
  { text: 'Hormigueros', value: 'Hormigueros' },
  { text: 'Humacao', value: 'Humacao' },
  { text: 'Isabela', value: 'Isabela' },
  { text: 'Jayuya', value: 'Jayuya' },
  { text: 'Juana Díaz', value: 'Juana Díaz' },
  { text: 'Juncos', value: 'Juncos' },
  { text: 'Lajas', value: 'Lajas' },
  { text: 'Lares', value: 'Lares' },
  { text: 'Las Marías', value: 'Las Marías' },
  { text: 'Las Piedras', value: 'Las Piedras' },
  { text: 'Loíza', value: 'Loíza' },
  { text: 'Luquillo', value: 'Luquillo' },
  { text: 'Manatí', value: 'Manatí' },
  { text: 'Maricao', value: 'Maricao' },
  { text: 'Maunabo', value: 'Maunabo' },
  { text: 'Mayagüez', value: 'Mayagüez' },
  { text: 'Moca', value: 'Moca' },
  { text: 'Morovis', value: 'Morovis' },
  { text: 'Naguabo', value: 'Naguabo' },
  { text: 'Naranjito', value: 'Naranjito' },
  { text: 'Orocovis', value: 'Orocovis' },
  { text: 'Patillas', value: 'Patillas' },
  { text: 'Peñuelas', value: 'Peñuelas' },
  { text: 'Ponce', value: 'Ponce' },
  { text: 'Quebradillas', value: 'Quebradillas' },
  { text: 'Rincón', value: 'Rincón' },
  { text: 'Río Grande', value: 'Río Grande' },
  { text: 'Sabana Grande', value: 'Sabana Grande' },
  { text: 'Salinas', value: 'Salinas' },
  { text: 'San Germán', value: 'San Germán' },
  { text: 'San Juan', value: 'San Juan' },
  { text: 'San Lorenzo', value: 'San Lorenzo' },
  { text: 'San Sebastián', value: 'San Sebastián' },
  { text: 'Santa Isabel', value: 'Santa Isabel' },
  { text: 'Toa Alta', value: 'Toa Alta' },
  { text: 'Toa Baja', value: 'Toa Baja' },
  { text: 'Trujillo Alto', value: 'Trujillo Alto' },
  { text: 'Utuado', value: 'Utuado' },
  { text: 'Vega Alta', value: 'Vega Alta' },
  { text: 'Vega Baja', value: 'Vega Baja' },
  { text: 'Vieques', value: 'Vieques' },
  { text: 'Villalba', value: 'Villalba' },
  { text: 'Yabucoa', value: 'Yabucoa' },
  { text: 'Yauco', value: 'Yauco' },
];

export default municipalities;
