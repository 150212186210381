const boundaries = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      id: 'zj165kf9377.1',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-67.18902588, 18.26621819],
              [-67.18902588, 18.26652527],
              [-67.18930817, 18.26652527],
              [-67.18930817, 18.2681942],
              [-67.18902588, 18.2681942],
              [-67.18902588, 18.26847267],
              [-67.18875122, 18.26847267],
              [-67.18875122, 18.2701416],
              [-67.18902588, 18.2701416],
              [-67.18902588, 18.27180481],
              [-67.18875122, 18.27180481],
              [-67.18875122, 18.27208328],
              [-67.18902588, 18.27208328],
              [-67.18902588, 18.27458382],
              [-67.18930817, 18.27458382],
              [-67.18930817, 18.27874947],
              [-67.18958282, 18.27874947],
              [-67.18958282, 18.27930641],
              [-67.18985748, 18.27930641],
              [-67.18985748, 18.27986145],
              [-67.19013977, 18.27986145],
              [-67.19013977, 18.28069496],
              [-67.19041443, 18.28069496],
              [-67.19041443, 18.28180504],
              [-67.19069672, 18.28180504],
              [-67.19069672, 18.28347015],
              [-67.19097137, 18.28347015],
              [-67.19097137, 18.28374863],
              [-67.19125366, 18.28374863],
              [-67.19125366, 18.28458405],
              [-67.19152832, 18.28458405],
              [-67.19152832, 18.28486252],
              [-67.19180298, 18.28486252],
              [-67.19180298, 18.28513908],
              [-67.19208527, 18.28513908],
              [-67.19208527, 18.28541756],
              [-67.19235992, 18.28541756],
              [-67.19235992, 18.28569412],
              [-67.19264221, 18.28569412],
              [-67.19264221, 18.28624916],
              [-67.19291687, 18.28624916],
              [-67.19291687, 18.28680611],
              [-67.19319153, 18.28680611],
              [-67.19319153, 18.28708267],
              [-67.19347382, 18.28708267],
              [-67.19347382, 18.28736115],
              [-67.19374847, 18.28736115],
              [-67.19374847, 18.28763962],
              [-67.19403076, 18.28763962],
              [-67.19403076, 18.28791618],
              [-67.19430542, 18.28791618],
              [-67.19430542, 18.28819466],
              [-67.19486237, 18.28819466],
              [-67.19486237, 18.28874969],
              [-67.19513702, 18.28874969],
              [-67.19513702, 18.28902817],
              [-67.19541931, 18.28902817],
              [-67.19541931, 18.29013824],
              [-67.19596863, 18.29013824],
              [-67.19596863, 18.29041672],
              [-67.19652557, 18.29041672],
              [-67.19652557, 18.29069519],
              [-67.19708252, 18.29069519],
              [-67.19708252, 18.29097176],
              [-67.19791412, 18.29097176],
              [-67.19791412, 18.29125023],
              [-67.19875336, 18.29125023],
              [-67.19875336, 18.2915287],
              [-67.20014191, 18.2915287],
              [-67.20014191, 18.29180527],
              [-67.20041656, 18.29180527],
              [-67.20041656, 18.29208374],
              [-67.20097351, 18.29208374],
              [-67.20097351, 18.29236031],
              [-67.20236206, 18.29236031],
              [-67.20236206, 18.29263878],
              [-67.20347595, 18.29263878],
              [-67.20347595, 18.29291534],
              [-67.20513916, 18.29291534],
              [-67.20513916, 18.29319382],
              [-67.20541382, 18.29319382],
              [-67.20541382, 18.29291534],
              [-67.20569611, 18.29291534],
              [-67.20569611, 18.29319382],
              [-67.20708466, 18.29319382],
              [-67.20708466, 18.29291534],
              [-67.20735931, 18.29291534],
              [-67.20735931, 18.29319382],
              [-67.20847321, 18.29319382],
              [-67.20847321, 18.29347229],
              [-67.20874786, 18.29347229],
              [-67.20874786, 18.29402733],
              [-67.20903015, 18.29402733],
              [-67.20903015, 18.2943058],
              [-67.20930481, 18.2943058],
              [-67.20930481, 18.29458427],
              [-67.2104187, 18.29458427],
              [-67.2104187, 18.29486084],
              [-67.21152496, 18.29486084],
              [-67.21152496, 18.29513741],
              [-67.2140274, 18.29513741],
              [-67.2140274, 18.29486084],
              [-67.21430206, 18.29486084],
              [-67.21430206, 18.29513741],
              [-67.21736145, 18.29513741],
              [-67.21736145, 18.29541588],
              [-67.2179184, 18.29541588],
              [-67.2179184, 18.29569435],
              [-67.21819305, 18.29569435],
              [-67.21819305, 18.29597282],
              [-67.21902466, 18.29597282],
              [-67.21902466, 18.29624748],
              [-67.21930695, 18.29624748],
              [-67.21930695, 18.29652977],
              [-67.2215271, 18.29652977],
              [-67.2215271, 18.29624748],
              [-67.2223587, 18.29624748],
              [-67.2223587, 18.29597282],
              [-67.2234726, 18.29597282],
              [-67.2234726, 18.29624748],
              [-67.22374725, 18.29624748],
              [-67.22374725, 18.29597282],
              [-67.22541809, 18.29597282],
              [-67.22541809, 18.29624748],
              [-67.22597504, 18.29624748],
              [-67.22597504, 18.29644775],
              [-67.22564697, 18.29680824],
              [-67.2224884, 18.30304527],
              [-67.22020721, 18.30593681],
              [-67.20864868, 18.30656815],
              [-67.20175934, 18.31046867],
              [-67.19944, 18.3105011],
              [-67.19663239, 18.30767632],
              [-67.19385529, 18.30771255],
              [-67.18279266, 18.31119347],
              [-67.18186951, 18.31167984],
              [-67.18281555, 18.31309891],
              [-67.18097687, 18.31407547],
              [-67.17868042, 18.31553459],
              [-67.17362213, 18.31798172],
              [-67.17269897, 18.31847191],
              [-67.16479492, 18.31523514],
              [-67.16110229, 18.31575775],
              [-67.16065979, 18.31767082],
              [-67.15743256, 18.31913948],
              [-67.1528244, 18.32062721],
              [-67.14868164, 18.32210922],
              [-67.13704681, 18.31653595],
              [-67.13011932, 18.31804848],
              [-67.12966156, 18.31853104],
              [-67.1264267, 18.31904602],
              [-67.12139893, 18.32387543],
              [-67.11209869, 18.32017708],
              [-67.1014328, 18.31839561],
              [-67.08659363, 18.31571388],
              [-67.07777405, 18.31295967],
              [-67.06943512, 18.3125782],
              [-67.06341553, 18.31169319],
              [-67.05364227, 18.30703926],
              [-67.05271149, 18.30657578],
              [-67.0406189, 18.30051422],
              [-67.03772736, 18.2895813],
              [-67.04090118, 18.28334808],
              [-67.04088593, 18.28191757],
              [-67.04031372, 18.27191353],
              [-67.04446411, 18.27091217],
              [-67.05181122, 18.26605988],
              [-67.0532074, 18.26604652],
              [-67.05645752, 18.2674408],
              [-67.06017303, 18.26882744],
              [-67.06204224, 18.27023506],
              [-67.06472015, 18.26162338],
              [-67.06517792, 18.26114082],
              [-67.06746674, 18.25920486],
              [-67.07810211, 18.25908279],
              [-67.08175659, 18.2547493],
              [-67.08677673, 18.24896812],
              [-67.08863068, 18.24942017],
              [-67.09186554, 18.24938202],
              [-67.09558105, 18.25076866],
              [-67.10022736, 18.2526226],
              [-67.10624695, 18.25302696],
              [-67.11174011, 18.24819374],
              [-67.12323761, 18.24280739],
              [-67.13669586, 18.24693489],
              [-67.13547516, 18.26125336],
              [-67.13459015, 18.26460075],
              [-67.1494751, 18.2715683],
              [-67.16105652, 18.27332878],
              [-67.16197205, 18.27236557],
              [-67.1638031, 18.27091026],
              [-67.16610718, 18.26992989],
              [-67.17394257, 18.26792336],
              [-67.18180084, 18.26734734],
              [-67.18902588, 18.26621819],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 1,
        name: 'Añasco',
        hasc_1: 'PR.AN',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-67.22597504, 18.24280739, -67.03772736, 18.32387543],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.2',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.79773712, 18.13364792],
              [-66.80194092, 18.13981247],
              [-66.80335236, 18.14266205],
              [-66.80382538, 18.14409065],
              [-66.80337524, 18.14552307],
              [-66.80432129, 18.14838028],
              [-66.806633, 18.14835739],
              [-66.81357574, 18.14972878],
              [-66.82099152, 18.1530056],
              [-66.82426453, 18.15774345],
              [-66.82613373, 18.16011047],
              [-66.82613373, 18.16058731],
              [-66.82388306, 18.16728783],
              [-66.82714844, 18.1710701],
              [-66.82485962, 18.17347717],
              [-66.82720947, 18.17822647],
              [-66.83184814, 18.18009186],
              [-66.83509827, 18.18244553],
              [-66.83605194, 18.18530083],
              [-66.83612061, 18.19340897],
              [-66.83625793, 18.20961761],
              [-66.83626556, 18.2105732],
              [-66.83494568, 18.21821594],
              [-66.83318329, 18.22824669],
              [-66.8327179, 18.22872734],
              [-66.83088684, 18.23065186],
              [-66.83042908, 18.23113251],
              [-66.82766724, 18.23211288],
              [-66.82489777, 18.23261452],
              [-66.82304382, 18.23215294],
              [-66.8170166, 18.230299],
              [-66.80358124, 18.22755432],
              [-66.80081177, 18.22757912],
              [-66.79989624, 18.22854233],
              [-66.79898071, 18.22998238],
              [-66.79994965, 18.23521423],
              [-66.80045319, 18.2399826],
              [-66.80091858, 18.24093056],
              [-66.80001068, 18.24284744],
              [-66.79497528, 18.24908829],
              [-66.79498291, 18.24956512],
              [-66.79451752, 18.25004768],
              [-66.7931366, 18.25005722],
              [-66.78250122, 18.25062561],
              [-66.77878571, 18.24827576],
              [-66.76436615, 18.23742676],
              [-66.76389313, 18.23599815],
              [-66.76154327, 18.23125267],
              [-66.76058197, 18.22696686],
              [-66.76057434, 18.22601128],
              [-66.76010895, 18.22554207],
              [-66.75593567, 18.22366524],
              [-66.75547028, 18.22319412],
              [-66.73973083, 18.22093964],
              [-66.73927307, 18.22094154],
              [-66.73191833, 18.22624779],
              [-66.70780945, 18.21689987],
              [-66.69347382, 18.21700668],
              [-66.69301605, 18.2170105],
              [-66.69254303, 18.21606064],
              [-66.68741608, 18.20990181],
              [-66.6860199, 18.20895576],
              [-66.68136597, 18.20422363],
              [-66.68136597, 18.2037468],
              [-66.68089294, 18.20231628],
              [-66.68134308, 18.20088577],
              [-66.68271637, 18.19896507],
              [-66.68453217, 18.19370842],
              [-66.68171692, 18.18800545],
              [-66.68032074, 18.18610764],
              [-66.67567444, 18.1828022],
              [-66.67195892, 18.18044281],
              [-66.66963959, 18.17903137],
              [-66.66500092, 18.17572594],
              [-66.66452026, 18.1738224],
              [-66.66497803, 18.17286682],
              [-66.67095184, 18.16805458],
              [-66.67140961, 18.16709328],
              [-66.6769104, 18.1603756],
              [-66.67684174, 18.15083694],
              [-66.67038727, 18.15279388],
              [-66.67171478, 18.1446743],
              [-66.67307281, 18.14037132],
              [-66.67669678, 18.13032532],
              [-66.67761993, 18.12984276],
              [-66.68084717, 18.12933922],
              [-66.6831665, 18.13075256],
              [-66.68502045, 18.1316967],
              [-66.69425201, 18.13067055],
              [-66.7025528, 18.12917519],
              [-66.70301819, 18.12870026],
              [-66.71039581, 18.12816429],
              [-66.71366119, 18.13195419],
              [-66.71734619, 18.13144684],
              [-66.71871185, 18.1276226],
              [-66.7210083, 18.12569618],
              [-66.72375488, 18.12281227],
              [-66.72512817, 18.12137032],
              [-66.7260437, 18.11993027],
              [-66.72509766, 18.11707306],
              [-66.72601318, 18.11563873],
              [-66.72692871, 18.11467552],
              [-66.72737885, 18.1132431],
              [-66.73245239, 18.11224747],
              [-66.7394104, 18.11696243],
              [-66.74402618, 18.1159687],
              [-66.74448395, 18.1159668],
              [-66.75047302, 18.11401176],
              [-66.75048065, 18.1144886],
              [-66.7542038, 18.11922836],
              [-66.75514221, 18.12017059],
              [-66.756073, 18.12207794],
              [-66.75656128, 18.12541008],
              [-66.7593689, 18.1292057],
              [-66.76168823, 18.13109207],
              [-66.76633453, 18.13487053],
              [-66.76725769, 18.13485718],
              [-66.76911163, 18.13484764],
              [-66.77002716, 18.1348381],
              [-66.7783432, 18.13524246],
              [-66.78066254, 18.13618088],
              [-66.78251648, 18.13664055],
              [-66.78529358, 18.1370945],
              [-66.7866745, 18.13660622],
              [-66.78804779, 18.13468552],
              [-66.78896332, 18.13420105],
              [-66.79265594, 18.13369179],
              [-66.79773712, 18.13364792],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 2,
        name: 'Adjuntas',
        hasc_1: 'PR.AJ',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.83626556, 18.11224747, -66.66452026, 18.25062561],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.3',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-67.13011932, 18.31804848],
              [-67.13704681, 18.31653595],
              [-67.14868164, 18.32210922],
              [-67.1528244, 18.32062721],
              [-67.15743256, 18.31913948],
              [-67.16065979, 18.31767082],
              [-67.16110229, 18.31575775],
              [-67.16479492, 18.31523514],
              [-67.17269897, 18.31847191],
              [-67.17362213, 18.31798172],
              [-67.17868042, 18.31553459],
              [-67.18097687, 18.31407547],
              [-67.18281555, 18.31309891],
              [-67.1879425, 18.31589127],
              [-67.19552612, 18.33057213],
              [-67.19785309, 18.33197021],
              [-67.20900726, 18.33563995],
              [-67.20947266, 18.33563423],
              [-67.21040344, 18.33609772],
              [-67.21134186, 18.33703804],
              [-67.22722626, 18.34873962],
              [-67.22594452, 18.35733795],
              [-67.22737122, 18.36065483],
              [-67.23912811, 18.37527084],
              [-67.23955536, 18.37569427],
              [-67.23930359, 18.37569427],
              [-67.23930359, 18.37597275],
              [-67.23902893, 18.37597275],
              [-67.23902893, 18.37624741],
              [-67.23874664, 18.37624741],
              [-67.23874664, 18.37652969],
              [-67.23819733, 18.37652969],
              [-67.23819733, 18.37680626],
              [-67.23569489, 18.37680626],
              [-67.23569489, 18.37708282],
              [-67.23486328, 18.37708282],
              [-67.23486328, 18.37735939],
              [-67.23458099, 18.37735939],
              [-67.23458099, 18.37764168],
              [-67.23402405, 18.37764168],
              [-67.23402405, 18.37791634],
              [-67.23152924, 18.37791634],
              [-67.23152924, 18.37819481],
              [-67.2298584, 18.37819481],
              [-67.2298584, 18.37846947],
              [-67.22736359, 18.37846947],
              [-67.22736359, 18.37875175],
              [-67.22624969, 18.37875175],
              [-67.22624969, 18.37902832],
              [-67.22597504, 18.37902832],
              [-67.22597504, 18.37930489],
              [-67.2251358, 18.37930489],
              [-67.2251358, 18.37958336],
              [-67.2243042, 18.37958336],
              [-67.2243042, 18.37986374],
              [-67.22402954, 18.37986374],
              [-67.22402954, 18.3801384],
              [-67.22374725, 18.3801384],
              [-67.22374725, 18.38041687],
              [-67.22319794, 18.38041687],
              [-67.22319794, 18.38069534],
              [-67.2223587, 18.38069534],
              [-67.2223587, 18.38097382],
              [-67.22208405, 18.38097382],
              [-67.22208405, 18.38125038],
              [-67.22097015, 18.38125038],
              [-67.22097015, 18.38152695],
              [-67.22013855, 18.38152695],
              [-67.22013855, 18.38180542],
              [-67.2195816, 18.38180542],
              [-67.2195816, 18.38208389],
              [-67.2179184, 18.38208389],
              [-67.2179184, 18.38236046],
              [-67.21763611, 18.38236046],
              [-67.21763611, 18.38263893],
              [-67.21736145, 18.38263893],
              [-67.21736145, 18.38236046],
              [-67.21708679, 18.38236046],
              [-67.21708679, 18.38263893],
              [-67.21624756, 18.38263893],
              [-67.21624756, 18.3829174],
              [-67.21569824, 18.3829174],
              [-67.21569824, 18.38319588],
              [-67.21541595, 18.38319588],
              [-67.21541595, 18.38347244],
              [-67.21485901, 18.38347244],
              [-67.21485901, 18.38375092],
              [-67.2140274, 18.38375092],
              [-67.2140274, 18.38402748],
              [-67.2123642, 18.38402748],
              [-67.2123642, 18.38486099],
              [-67.21208191, 18.38486099],
              [-67.21208191, 18.38513947],
              [-67.21180725, 18.38513947],
              [-67.21180725, 18.38541412],
              [-67.21152496, 18.38541412],
              [-67.21152496, 18.3856945],
              [-67.2104187, 18.3856945],
              [-67.2104187, 18.38624954],
              [-67.2095871, 18.38624954],
              [-67.2095871, 18.38652611],
              [-67.20874786, 18.38652611],
              [-67.20874786, 18.38708305],
              [-67.20708466, 18.38708305],
              [-67.20708466, 18.38736153],
              [-67.20680237, 18.38736153],
              [-67.20680237, 18.38763618],
              [-67.20652771, 18.38763618],
              [-67.20652771, 18.38791656],
              [-67.20541382, 18.38791656],
              [-67.20541382, 18.38819504],
              [-67.20513916, 18.38819504],
              [-67.20513916, 18.38903046],
              [-67.20541382, 18.38903046],
              [-67.20541382, 18.38930511],
              [-67.20375061, 18.38930511],
              [-67.20375061, 18.38986015],
              [-67.20291901, 18.38986015],
              [-67.20291901, 18.39014053],
              [-67.20124817, 18.39014053],
              [-67.20124817, 18.3904171],
              [-67.20069122, 18.3904171],
              [-67.20069122, 18.39069366],
              [-67.19985962, 18.39069366],
              [-67.19985962, 18.39097023],
              [-67.19902802, 18.39097023],
              [-67.19902802, 18.3912487],
              [-67.19875336, 18.3912487],
              [-67.19875336, 18.39152718],
              [-67.19708252, 18.39152718],
              [-67.19708252, 18.39180565],
              [-67.19680786, 18.39180565],
              [-67.19680786, 18.39208412],
              [-67.19569397, 18.39208412],
              [-67.19569397, 18.39236259],
              [-67.19513702, 18.39236259],
              [-67.19513702, 18.39263916],
              [-67.19486237, 18.39263916],
              [-67.19486237, 18.39291573],
              [-67.19430542, 18.39291573],
              [-67.19430542, 18.3931942],
              [-67.19403076, 18.3931942],
              [-67.19403076, 18.39347267],
              [-67.19319153, 18.39347267],
              [-67.19319153, 18.39402771],
              [-67.19264221, 18.39402771],
              [-67.19264221, 18.39486122],
              [-67.19235992, 18.39486122],
              [-67.19235992, 18.39541626],
              [-67.19208527, 18.39541626],
              [-67.19208527, 18.39569473],
              [-67.19152832, 18.39569473],
              [-67.19152832, 18.3959713],
              [-67.19125366, 18.3959713],
              [-67.19125366, 18.39624977],
              [-67.19097137, 18.39624977],
              [-67.19097137, 18.39680481],
              [-67.19069672, 18.39680481],
              [-67.19069672, 18.39791679],
              [-67.19097137, 18.39791679],
              [-67.19097137, 18.39847183],
              [-67.19069672, 18.39847183],
              [-67.19069672, 18.39875031],
              [-67.19041443, 18.39875031],
              [-67.19041443, 18.39902687],
              [-67.18985748, 18.39902687],
              [-67.18985748, 18.39958382],
              [-67.18958282, 18.39958382],
              [-67.18958282, 18.39986038],
              [-67.18902588, 18.39986038],
              [-67.18902588, 18.40013885],
              [-67.18846893, 18.40013885],
              [-67.18846893, 18.40041733],
              [-67.18819427, 18.40041733],
              [-67.18819427, 18.40069389],
              [-67.18791962, 18.40069389],
              [-67.18791962, 18.40097237],
              [-67.18708038, 18.40097237],
              [-67.18708038, 18.40125084],
              [-67.18624878, 18.40125084],
              [-67.18624878, 18.4015274],
              [-67.18569183, 18.4015274],
              [-67.18569183, 18.40180588],
              [-67.18458557, 18.40180588],
              [-67.18458557, 18.40208244],
              [-67.18402863, 18.40208244],
              [-67.18402863, 18.40236092],
              [-67.18374634, 18.40236092],
              [-67.18374634, 18.40291595],
              [-67.18319702, 18.40291595],
              [-67.18319702, 18.40319443],
              [-67.18264008, 18.40319443],
              [-67.18264008, 18.4034729],
              [-67.18208313, 18.4034729],
              [-67.18208313, 18.40375137],
              [-67.18152618, 18.40375137],
              [-67.18152618, 18.40402985],
              [-67.18125153, 18.40402985],
              [-67.18125153, 18.40430641],
              [-67.18013763, 18.40430641],
              [-67.18013763, 18.40458107],
              [-67.17986298, 18.40458107],
              [-67.17986298, 18.40485954],
              [-67.17930603, 18.40485954],
              [-67.17930603, 18.40513992],
              [-67.17874908, 18.40513992],
              [-67.17874908, 18.40541649],
              [-67.17791748, 18.40541649],
              [-67.17791748, 18.40569496],
              [-67.17708588, 18.40569496],
              [-67.17708588, 18.40541649],
              [-67.17680359, 18.40541649],
              [-67.17680359, 18.40596962],
              [-67.17652893, 18.40596962],
              [-67.17652893, 18.40680504],
              [-67.17569733, 18.40680504],
              [-67.17569733, 18.40708351],
              [-67.17430878, 18.40708351],
              [-67.17430878, 18.40736389],
              [-67.17402649, 18.40736389],
              [-67.17402649, 18.40763855],
              [-67.17208099, 18.40763855],
              [-67.17208099, 18.40791702],
              [-67.17152405, 18.40791702],
              [-67.17152405, 18.40819359],
              [-67.17041779, 18.40819359],
              [-67.17041779, 18.40847206],
              [-67.1701355, 18.40847206],
              [-67.1701355, 18.40875053],
              [-67.16986084, 18.40875053],
              [-67.16986084, 18.4090271],
              [-67.16958618, 18.4090271],
              [-67.16958618, 18.40930557],
              [-67.16902924, 18.40930557],
              [-67.16902924, 18.40958595],
              [-67.16847229, 18.40958595],
              [-67.16847229, 18.40986061],
              [-67.16764069, 18.40986061],
              [-67.16764069, 18.41013908],
              [-67.1673584, 18.41013908],
              [-67.1673584, 18.41041756],
              [-67.16708374, 18.41041756],
              [-67.16708374, 18.41069412],
              [-67.16680908, 18.41069412],
              [-67.16680908, 18.4109726],
              [-67.16625214, 18.4109726],
              [-67.16625214, 18.41124916],
              [-67.16569519, 18.41124916],
              [-67.16569519, 18.41152763],
              [-67.16486359, 18.41152763],
              [-67.16486359, 18.4118042],
              [-67.16374969, 18.4118042],
              [-67.16374969, 18.41208267],
              [-67.16347504, 18.41208267],
              [-67.16347504, 18.41236115],
              [-67.16319275, 18.41236115],
              [-67.16319275, 18.41319466],
              [-67.16291809, 18.41319466],
              [-67.16291809, 18.41347313],
              [-67.1626358, 18.41347313],
              [-67.1626358, 18.41374969],
              [-67.16208649, 18.41374969],
              [-67.16208649, 18.41402626],
              [-67.16069794, 18.41402626],
              [-67.16069794, 18.41430473],
              [-67.16041565, 18.41430473],
              [-67.16041565, 18.41513824],
              [-67.16014099, 18.41513824],
              [-67.16014099, 18.41541672],
              [-67.16003418, 18.41541672],
              [-67.15856171, 18.41396141],
              [-67.1594162, 18.40823555],
              [-67.15515137, 18.3997097],
              [-67.15465546, 18.39685822],
              [-67.15646362, 18.39349937],
              [-67.15600586, 18.39350891],
              [-67.14718628, 18.39218521],
              [-67.14625549, 18.39172173],
              [-67.14159393, 18.38939857],
              [-67.14066315, 18.38845444],
              [-67.13832855, 18.38705444],
              [-67.13233185, 18.3895092],
              [-67.13078308, 18.37570953],
              [-67.12702942, 18.37146759],
              [-67.12833405, 18.36382484],
              [-67.12736511, 18.36002731],
              [-67.12822723, 18.35477257],
              [-67.13140106, 18.34901619],
              [-67.13415527, 18.34707451],
              [-67.13461304, 18.34659386],
              [-67.13412476, 18.3442173],
              [-67.13365173, 18.34326935],
              [-67.13126373, 18.33662224],
              [-67.13442993, 18.33038712],
              [-67.13013458, 18.31900215],
              [-67.13011932, 18.31804848],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 3,
        name: 'Aguada',
        hasc_1: 'PR.AD',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-67.23955536, 18.31309891, -67.12702942, 18.41541672],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.4',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-67.05622864, 18.46092415],
              [-67.0742569, 18.45642853],
              [-67.07420349, 18.45166588],
              [-67.07647705, 18.44735336],
              [-67.07691956, 18.44591713],
              [-67.08339691, 18.44489098],
              [-67.08889008, 18.4391098],
              [-67.090271, 18.43813705],
              [-67.09303284, 18.43667793],
              [-67.09581757, 18.43664551],
              [-67.09860992, 18.43756485],
              [-67.11188507, 18.4235878],
              [-67.11966705, 18.41539574],
              [-67.12561798, 18.40960693],
              [-67.12467194, 18.40771294],
              [-67.12371063, 18.40486336],
              [-67.12369537, 18.40343475],
              [-67.1268692, 18.39719963],
              [-67.13233185, 18.3895092],
              [-67.13832855, 18.38705444],
              [-67.14066315, 18.38845444],
              [-67.14159393, 18.38939857],
              [-67.14625549, 18.39172173],
              [-67.14718628, 18.39218521],
              [-67.15600586, 18.39350891],
              [-67.15646362, 18.39349937],
              [-67.15465546, 18.39685822],
              [-67.15515137, 18.3997097],
              [-67.1594162, 18.40823555],
              [-67.15856171, 18.41396141],
              [-67.16003418, 18.41541672],
              [-67.1598587, 18.41541672],
              [-67.1598587, 18.41569519],
              [-67.15958405, 18.41569519],
              [-67.15958405, 18.41597176],
              [-67.15930176, 18.41597176],
              [-67.15930176, 18.41624832],
              [-67.1590271, 18.41624832],
              [-67.1590271, 18.41653061],
              [-67.15875244, 18.41653061],
              [-67.15875244, 18.41680527],
              [-67.15847015, 18.41680527],
              [-67.15847015, 18.41708374],
              [-67.1581955, 18.41708374],
              [-67.1581955, 18.4173584],
              [-67.15791321, 18.4173584],
              [-67.15791321, 18.41791725],
              [-67.15763855, 18.41791725],
              [-67.15763855, 18.41819382],
              [-67.15736389, 18.41819382],
              [-67.15736389, 18.41847038],
              [-67.1570816, 18.41847038],
              [-67.1570816, 18.41875267],
              [-67.15680695, 18.41875267],
              [-67.15680695, 18.41986275],
              [-67.15652466, 18.41986275],
              [-67.15652466, 18.42124939],
              [-67.15625, 18.42124939],
              [-67.15625, 18.42291641],
              [-67.15597534, 18.42291641],
              [-67.15597534, 18.42374992],
              [-67.15569305, 18.42374992],
              [-67.15569305, 18.4240284],
              [-67.1554184, 18.4240284],
              [-67.1554184, 18.42569351],
              [-67.15569305, 18.42569351],
              [-67.15569305, 18.42625046],
              [-67.15625, 18.42625046],
              [-67.15625, 18.4268055],
              [-67.15652466, 18.4268055],
              [-67.15652466, 18.42708397],
              [-67.15680695, 18.42708397],
              [-67.15680695, 18.4276371],
              [-67.15652466, 18.4276371],
              [-67.15652466, 18.42847252],
              [-67.15625, 18.42847252],
              [-67.15625, 18.42902756],
              [-67.15597534, 18.42902756],
              [-67.15597534, 18.43014145],
              [-67.15569305, 18.43014145],
              [-67.15569305, 18.43069458],
              [-67.1554184, 18.43069458],
              [-67.1554184, 18.43180466],
              [-67.15569305, 18.43180466],
              [-67.15569305, 18.43319511],
              [-67.1554184, 18.43319511],
              [-67.1554184, 18.43402863],
              [-67.15569305, 18.43402863],
              [-67.15569305, 18.43430519],
              [-67.15597534, 18.43430519],
              [-67.15597534, 18.43486023],
              [-67.15625, 18.43486023],
              [-67.15625, 18.43541718],
              [-67.15652466, 18.43541718],
              [-67.15652466, 18.43736076],
              [-67.15680695, 18.43736076],
              [-67.15680695, 18.4398613],
              [-67.1570816, 18.4398613],
              [-67.1570816, 18.44041634],
              [-67.15736389, 18.44041634],
              [-67.15736389, 18.44097137],
              [-67.15763855, 18.44097137],
              [-67.15763855, 18.44180489],
              [-67.15791321, 18.44180489],
              [-67.15791321, 18.44319534],
              [-67.1581955, 18.44319534],
              [-67.1581955, 18.44347191],
              [-67.15847015, 18.44347191],
              [-67.15847015, 18.44374847],
              [-67.15875244, 18.44374847],
              [-67.15875244, 18.44486237],
              [-67.1590271, 18.44486237],
              [-67.1590271, 18.44514084],
              [-67.15958405, 18.44514084],
              [-67.15958405, 18.44486237],
              [-67.16014099, 18.44486237],
              [-67.16014099, 18.44458389],
              [-67.16041565, 18.44458389],
              [-67.16041565, 18.44514084],
              [-67.16014099, 18.44514084],
              [-67.16014099, 18.4454174],
              [-67.15958405, 18.4454174],
              [-67.15958405, 18.44597244],
              [-67.1598587, 18.44597244],
              [-67.1598587, 18.44680595],
              [-67.16014099, 18.44680595],
              [-67.16014099, 18.44708061],
              [-67.16041565, 18.44708061],
              [-67.16041565, 18.44763947],
              [-67.16069794, 18.44763947],
              [-67.16069794, 18.4481945],
              [-67.1609726, 18.4481945],
              [-67.1609726, 18.44902802],
              [-67.16124725, 18.44902802],
              [-67.16124725, 18.44930649],
              [-67.1609726, 18.44930649],
              [-67.1609726, 18.45013809],
              [-67.16124725, 18.45013809],
              [-67.16124725, 18.45208359],
              [-67.16152954, 18.45208359],
              [-67.16152954, 18.45263863],
              [-67.1618042, 18.45263863],
              [-67.1618042, 18.45291519],
              [-67.16208649, 18.45291519],
              [-67.16208649, 18.45347214],
              [-67.16236115, 18.45347214],
              [-67.16236115, 18.45375061],
              [-67.1626358, 18.45375061],
              [-67.1626358, 18.45458412],
              [-67.16236115, 18.45458412],
              [-67.16236115, 18.45486069],
              [-67.1626358, 18.45486069],
              [-67.1626358, 18.45652771],
              [-67.16319275, 18.45652771],
              [-67.16319275, 18.45680618],
              [-67.16374969, 18.45680618],
              [-67.16374969, 18.45708275],
              [-67.16430664, 18.45708275],
              [-67.16430664, 18.45735931],
              [-67.1645813, 18.45735931],
              [-67.1645813, 18.45791626],
              [-67.16486359, 18.45791626],
              [-67.16486359, 18.45875168],
              [-67.16513824, 18.45875168],
              [-67.16513824, 18.45902824],
              [-67.1654129, 18.45902824],
              [-67.1654129, 18.45958328],
              [-67.16569519, 18.45958328],
              [-67.16569519, 18.45986366],
              [-67.16596985, 18.45986366],
              [-67.16596985, 18.46069527],
              [-67.16652679, 18.46069527],
              [-67.16652679, 18.46125031],
              [-67.16680908, 18.46125031],
              [-67.16680908, 18.46152687],
              [-67.1673584, 18.46152687],
              [-67.1673584, 18.46180534],
              [-67.16764069, 18.46180534],
              [-67.16764069, 18.46236038],
              [-67.16791534, 18.46236038],
              [-67.16791534, 18.46291733],
              [-67.16819763, 18.46291733],
              [-67.16819763, 18.4640274],
              [-67.16847229, 18.4640274],
              [-67.16847229, 18.46458244],
              [-67.16874695, 18.46458244],
              [-67.16874695, 18.46569443],
              [-67.16902924, 18.46569443],
              [-67.16902924, 18.46708298],
              [-67.16930389, 18.46708298],
              [-67.16930389, 18.47069359],
              [-67.16958618, 18.47069359],
              [-67.16958618, 18.4715271],
              [-67.16930389, 18.4715271],
              [-67.16930389, 18.47319412],
              [-67.16958618, 18.47319412],
              [-67.16958618, 18.47569466],
              [-67.16930389, 18.47569466],
              [-67.16930389, 18.47652817],
              [-67.16902924, 18.47652817],
              [-67.16902924, 18.47819519],
              [-67.16930389, 18.47819519],
              [-67.16930389, 18.4790287],
              [-67.16902924, 18.4790287],
              [-67.16902924, 18.47930527],
              [-67.16930389, 18.47930527],
              [-67.16930389, 18.48013878],
              [-67.16902924, 18.48013878],
              [-67.16902924, 18.48041725],
              [-67.16874695, 18.48041725],
              [-67.16874695, 18.48069382],
              [-67.16819763, 18.48069382],
              [-67.16819763, 18.48097229],
              [-67.16764069, 18.48097229],
              [-67.16764069, 18.48125076],
              [-67.1673584, 18.48125076],
              [-67.1673584, 18.48347282],
              [-67.16708374, 18.48347282],
              [-67.16708374, 18.4845829],
              [-67.16680908, 18.4845829],
              [-67.16680908, 18.48485947],
              [-67.16652679, 18.48485947],
              [-67.16652679, 18.48541641],
              [-67.16596985, 18.48541641],
              [-67.16596985, 18.48569489],
              [-67.16569519, 18.48569489],
              [-67.16569519, 18.48625183],
              [-67.1654129, 18.48625183],
              [-67.1654129, 18.48708344],
              [-67.16513824, 18.48708344],
              [-67.16513824, 18.48791695],
              [-67.16486359, 18.48791695],
              [-67.16486359, 18.48819351],
              [-67.1645813, 18.48819351],
              [-67.1645813, 18.48847389],
              [-67.16430664, 18.48847389],
              [-67.16430664, 18.48875046],
              [-67.16402435, 18.48875046],
              [-67.16402435, 18.48902702],
              [-67.16347504, 18.48902702],
              [-67.16347504, 18.48875046],
              [-67.16319275, 18.48875046],
              [-67.16319275, 18.48902702],
              [-67.16291809, 18.48902702],
              [-67.16291809, 18.48875046],
              [-67.1626358, 18.48875046],
              [-67.1626358, 18.48902702],
              [-67.16236115, 18.48902702],
              [-67.16236115, 18.4893055],
              [-67.1618042, 18.4893055],
              [-67.1618042, 18.48958588],
              [-67.16152954, 18.48958588],
              [-67.16152954, 18.48986053],
              [-67.16069794, 18.48986053],
              [-67.16069794, 18.49013901],
              [-67.1598587, 18.49013901],
              [-67.1598587, 18.48986053],
              [-67.1590271, 18.48986053],
              [-67.1590271, 18.49013901],
              [-67.15763855, 18.49013901],
              [-67.15763855, 18.48986053],
              [-67.1570816, 18.48986053],
              [-67.1570816, 18.49013901],
              [-67.15652466, 18.49013901],
              [-67.15652466, 18.49041748],
              [-67.15597534, 18.49041748],
              [-67.15597534, 18.49097252],
              [-67.15513611, 18.49097252],
              [-67.15513611, 18.49124908],
              [-67.15486145, 18.49124908],
              [-67.15486145, 18.49180412],
              [-67.15458679, 18.49180412],
              [-67.15458679, 18.4920826],
              [-67.1543045, 18.4920826],
              [-67.1543045, 18.49263954],
              [-67.15402985, 18.49263954],
              [-67.15402985, 18.49291611],
              [-67.15374756, 18.49291611],
              [-67.15374756, 18.49347305],
              [-67.1534729, 18.49347305],
              [-67.1534729, 18.49430466],
              [-67.15374756, 18.49430466],
              [-67.15374756, 18.4948616],
              [-67.1534729, 18.4948616],
              [-67.1534729, 18.49569511],
              [-67.15319824, 18.49569511],
              [-67.15319824, 18.49624825],
              [-67.15291595, 18.49624825],
              [-67.15291595, 18.49653053],
              [-67.1526413, 18.49653053],
              [-67.1526413, 18.49624825],
              [-67.15208435, 18.49624825],
              [-67.15208435, 18.49653053],
              [-67.15180206, 18.49653053],
              [-67.15180206, 18.49764061],
              [-67.1515274, 18.49764061],
              [-67.1515274, 18.49791718],
              [-67.15125275, 18.49791718],
              [-67.15125275, 18.49819374],
              [-67.1506958, 18.49819374],
              [-67.1506958, 18.49847031],
              [-67.15013885, 18.49847031],
              [-67.15013885, 18.49875259],
              [-67.14958191, 18.49875259],
              [-67.14958191, 18.49902725],
              [-67.14902496, 18.49902725],
              [-67.14902496, 18.49930573],
              [-67.14875031, 18.49930573],
              [-67.14875031, 18.4995842],
              [-67.14847565, 18.4995842],
              [-67.14847565, 18.49986267],
              [-67.14819336, 18.49986267],
              [-67.14819336, 18.50013924],
              [-67.1479187, 18.50013924],
              [-67.1479187, 18.5004158],
              [-67.14763641, 18.5004158],
              [-67.14763641, 18.50097275],
              [-67.14736176, 18.50097275],
              [-67.14736176, 18.50124931],
              [-67.1470871, 18.50124931],
              [-67.1470871, 18.50152779],
              [-67.14680481, 18.50152779],
              [-67.14680481, 18.50180626],
              [-67.14653015, 18.50180626],
              [-67.14653015, 18.50208473],
              [-67.14624786, 18.50208473],
              [-67.14624786, 18.5023613],
              [-67.14597321, 18.5023613],
              [-67.14597321, 18.50263977],
              [-67.14569092, 18.50263977],
              [-67.14569092, 18.50291634],
              [-67.14541626, 18.50291634],
              [-67.14541626, 18.50319481],
              [-67.14458466, 18.50319481],
              [-67.14458466, 18.50347137],
              [-67.14375305, 18.50347137],
              [-67.14375305, 18.50374985],
              [-67.14347076, 18.50374985],
              [-67.14347076, 18.50402832],
              [-67.14319611, 18.50402832],
              [-67.14319611, 18.50430679],
              [-67.14291382, 18.50430679],
              [-67.14291382, 18.50486183],
              [-67.14263916, 18.50486183],
              [-67.14263916, 18.5051384],
              [-67.14208221, 18.5051384],
              [-67.14208221, 18.50541687],
              [-67.14152527, 18.50541687],
              [-67.14152527, 18.50569534],
              [-67.14125061, 18.50569534],
              [-67.14125061, 18.50597191],
              [-67.14041901, 18.50597191],
              [-67.14041901, 18.50625038],
              [-67.13930511, 18.50625038],
              [-67.13930511, 18.50652695],
              [-67.13903046, 18.50652695],
              [-67.13903046, 18.50708389],
              [-67.13847351, 18.50708389],
              [-67.13847351, 18.5079174],
              [-67.13708496, 18.5079174],
              [-67.13708496, 18.50819397],
              [-67.13652802, 18.50819397],
              [-67.13652802, 18.50847244],
              [-67.13541412, 18.50847244],
              [-67.13541412, 18.50819397],
              [-67.13458252, 18.50819397],
              [-67.13458252, 18.5079174],
              [-67.13430786, 18.5079174],
              [-67.13430786, 18.50763893],
              [-67.13291931, 18.50763893],
              [-67.13291931, 18.5079174],
              [-67.13263702, 18.5079174],
              [-67.13263702, 18.50819397],
              [-67.13236237, 18.50819397],
              [-67.13236237, 18.5079174],
              [-67.13153076, 18.5079174],
              [-67.13153076, 18.50819397],
              [-67.13124847, 18.50819397],
              [-67.13124847, 18.50847244],
              [-67.13041687, 18.50847244],
              [-67.13041687, 18.50902748],
              [-67.12985992, 18.50902748],
              [-67.12985992, 18.50958252],
              [-67.12958527, 18.50958252],
              [-67.12958527, 18.50985909],
              [-67.12930298, 18.50985909],
              [-67.12930298, 18.51014137],
              [-67.12819672, 18.51014137],
              [-67.12819672, 18.51041603],
              [-67.12708282, 18.51041603],
              [-67.12708282, 18.5106945],
              [-67.12625122, 18.5106945],
              [-67.12625122, 18.51097107],
              [-67.12569427, 18.51097107],
              [-67.12569427, 18.51125145],
              [-67.12541962, 18.51125145],
              [-67.12541962, 18.51152992],
              [-67.12430573, 18.51152992],
              [-67.12430573, 18.51180649],
              [-67.12208557, 18.51180649],
              [-67.12208557, 18.51208115],
              [-67.12069702, 18.51208115],
              [-67.12069702, 18.51235962],
              [-67.11875153, 18.51235962],
              [-67.11875153, 18.51263809],
              [-67.11569214, 18.51263809],
              [-67.11569214, 18.51291656],
              [-67.11430359, 18.51291656],
              [-67.11430359, 18.51319504],
              [-67.11264038, 18.51319504],
              [-67.11264038, 18.51291656],
              [-67.11235809, 18.51291656],
              [-67.11235809, 18.51263809],
              [-67.11152649, 18.51263809],
              [-67.11152649, 18.51208115],
              [-67.11042023, 18.51208115],
              [-67.11042023, 18.51180649],
              [-67.10624695, 18.51180649],
              [-67.10624695, 18.51235962],
              [-67.10375214, 18.51235962],
              [-67.10375214, 18.51263809],
              [-67.1029129, 18.51263809],
              [-67.1029129, 18.51319504],
              [-67.10263824, 18.51319504],
              [-67.09759521, 18.51235771],
              [-67.09570313, 18.50904846],
              [-67.09192657, 18.50337601],
              [-67.08538818, 18.49916649],
              [-67.08256531, 18.49539185],
              [-67.08298492, 18.49110031],
              [-67.08249664, 18.48920059],
              [-67.07969666, 18.48780251],
              [-67.07923889, 18.48781013],
              [-67.07321167, 18.4878788],
              [-67.06297302, 18.48418617],
              [-67.05918121, 18.47613335],
              [-67.0586319, 18.4685173],
              [-67.05770111, 18.46852875],
              [-67.05631256, 18.46854401],
              [-67.05622864, 18.46092415],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 4,
        name: 'Aguadilla',
        hasc_1: 'PR.AL',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-67.16958618, 18.38705444, -67.05622864, 18.51319504],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.5',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.16880798, 18.22423363],
              [-66.17621613, 18.22946358],
              [-66.1780777, 18.23518181],
              [-66.17809296, 18.2451973],
              [-66.18828583, 18.25566483],
              [-66.19060516, 18.25852394],
              [-66.18461609, 18.26806831],
              [-66.18183899, 18.26998138],
              [-66.17860413, 18.27236938],
              [-66.17676544, 18.27618408],
              [-66.17260742, 18.2785778],
              [-66.16751862, 18.2781105],
              [-66.16659546, 18.27954292],
              [-66.16150665, 18.28240776],
              [-66.15966034, 18.28289032],
              [-66.14392853, 18.27958107],
              [-66.12080383, 18.28152084],
              [-66.11201477, 18.27962494],
              [-66.10830688, 18.27772141],
              [-66.10553741, 18.27867889],
              [-66.09952545, 18.28154755],
              [-66.09398651, 18.29204178],
              [-66.0916748, 18.29299545],
              [-66.08057404, 18.29729652],
              [-66.07687378, 18.29634476],
              [-66.07317352, 18.2977829],
              [-66.07131958, 18.29920769],
              [-66.06623077, 18.3030262],
              [-66.06761932, 18.29826164],
              [-66.06761932, 18.29635239],
              [-66.07084656, 18.28300285],
              [-66.07499695, 18.26774216],
              [-66.07684326, 18.25915909],
              [-66.0772934, 18.24962616],
              [-66.08422089, 18.23770142],
              [-66.08514404, 18.23579407],
              [-66.09576416, 18.22433662],
              [-66.09899902, 18.22051811],
              [-66.10084534, 18.21861267],
              [-66.10315704, 18.21622658],
              [-66.10823822, 18.2143116],
              [-66.11794281, 18.21000671],
              [-66.11886597, 18.20952606],
              [-66.12532806, 18.20665932],
              [-66.12625122, 18.20618057],
              [-66.13733673, 18.19758034],
              [-66.14149475, 18.19757462],
              [-66.14289856, 18.20997238],
              [-66.1438446, 18.22284508],
              [-66.1438446, 18.22427559],
              [-66.14385223, 18.22761154],
              [-66.14385223, 18.22808838],
              [-66.14616394, 18.23047066],
              [-66.14894104, 18.22712898],
              [-66.14985657, 18.22521782],
              [-66.15539551, 18.22139549],
              [-66.15770721, 18.22091675],
              [-66.15909576, 18.2204361],
              [-66.16048431, 18.21995544],
              [-66.16187286, 18.22043228],
              [-66.16233063, 18.22090721],
              [-66.16279602, 18.22090721],
              [-66.16880798, 18.22423363],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 5,
        name: 'Aguas Buenas',
        hasc_1: 'PR.AB',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.19060516, 18.19757462, -66.06623077, 18.3030262],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.6',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.22444916, 18.09532928],
              [-66.22953033, 18.09531593],
              [-66.23506927, 18.09434891],
              [-66.24014282, 18.09290314],
              [-66.24013519, 18.08908653],
              [-66.2396698, 18.08765411],
              [-66.23966217, 18.084795],
              [-66.24011993, 18.08336258],
              [-66.24195099, 18.07715416],
              [-66.24980164, 18.07665634],
              [-66.25764465, 18.07615662],
              [-66.25997162, 18.08282852],
              [-66.26043701, 18.08330536],
              [-66.26274872, 18.08568573],
              [-66.26921082, 18.08614159],
              [-66.27477264, 18.0932827],
              [-66.27708435, 18.09566307],
              [-66.29512787, 18.10610199],
              [-66.31085968, 18.11845589],
              [-66.32058716, 18.12605667],
              [-66.31322479, 18.13609886],
              [-66.31277466, 18.13752937],
              [-66.31277466, 18.13800812],
              [-66.31693268, 18.13990021],
              [-66.31465912, 18.15183449],
              [-66.31191254, 18.15756607],
              [-66.31006622, 18.15948105],
              [-66.30914307, 18.15948677],
              [-66.30867767, 18.15948677],
              [-66.30729675, 18.15997124],
              [-66.30221558, 18.15950966],
              [-66.30175018, 18.15903282],
              [-66.29990387, 18.16046906],
              [-66.29944611, 18.16142654],
              [-66.29807281, 18.16524506],
              [-66.29252625, 18.16526413],
              [-66.27915192, 18.17389488],
              [-66.277771, 18.17437363],
              [-66.27314758, 18.17390823],
              [-66.26621246, 18.1724987],
              [-66.25650787, 18.1710968],
              [-66.25604248, 18.17061996],
              [-66.25556946, 18.16632843],
              [-66.24817657, 18.16587067],
              [-66.24911499, 18.1701622],
              [-66.24773407, 18.17445564],
              [-66.24266815, 18.18019295],
              [-66.22091675, 18.16498756],
              [-66.22875214, 18.15829086],
              [-66.22367096, 18.15543938],
              [-66.22781372, 18.15065956],
              [-66.21995544, 18.14972496],
              [-66.22041321, 18.14495468],
              [-66.21670532, 18.14305305],
              [-66.21669769, 18.13780785],
              [-66.21715546, 18.1359005],
              [-66.21853638, 18.13350868],
              [-66.21945953, 18.1325531],
              [-66.22084045, 18.13159752],
              [-66.22083282, 18.1287365],
              [-66.22035217, 18.12062645],
              [-66.22174072, 18.11919022],
              [-66.22681427, 18.11679459],
              [-66.22540283, 18.10630035],
              [-66.22540283, 18.10534668],
              [-66.22444916, 18.09532928],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 6,
        name: 'Aibonito',
        hasc_1: 'PR.AI',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.32058716, 18.07615662, -66.21669769, 18.18019295],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.7',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.59065247, 18.33786011],
              [-66.59204865, 18.33785248],
              [-66.59343719, 18.33831978],
              [-66.59945679, 18.33922958],
              [-66.60038757, 18.33922768],
              [-66.60084534, 18.33874702],
              [-66.60404205, 18.3315773],
              [-66.60679626, 18.32822227],
              [-66.61237335, 18.33152199],
              [-66.6193161, 18.33195305],
              [-66.62162018, 18.33098221],
              [-66.62392426, 18.32858467],
              [-66.62669373, 18.32808876],
              [-66.62901306, 18.3280735],
              [-66.63040161, 18.3285408],
              [-66.63225555, 18.32852745],
              [-66.63594055, 18.32611847],
              [-66.63824463, 18.32515144],
              [-66.63963318, 18.32466125],
              [-66.64518738, 18.32557869],
              [-66.6484375, 18.32650757],
              [-66.65307617, 18.32790565],
              [-66.65444946, 18.32551003],
              [-66.65628052, 18.32263756],
              [-66.65720367, 18.32215691],
              [-66.66273499, 18.31973457],
              [-66.67156219, 18.3244381],
              [-66.67295074, 18.32442856],
              [-66.67802429, 18.32200813],
              [-66.67940521, 18.32151985],
              [-66.68173218, 18.32245445],
              [-66.68310547, 18.3210125],
              [-66.6854248, 18.32099915],
              [-66.68819427, 18.32002449],
              [-66.68911743, 18.32049179],
              [-66.69052124, 18.32191658],
              [-66.6965332, 18.3218689],
              [-66.69791412, 18.3209095],
              [-66.69930267, 18.32089424],
              [-66.70300293, 18.3203907],
              [-66.7066803, 18.31654549],
              [-66.70852661, 18.31653595],
              [-66.71176147, 18.31555557],
              [-66.7131424, 18.3150692],
              [-66.71731567, 18.31599045],
              [-66.71825409, 18.31788826],
              [-66.71827698, 18.32074928],
              [-66.71966553, 18.32073784],
              [-66.72063446, 18.32692719],
              [-66.72298431, 18.33167458],
              [-66.72023773, 18.33550835],
              [-66.71977997, 18.33646584],
              [-66.72071838, 18.33788681],
              [-66.72351074, 18.33977318],
              [-66.72631073, 18.34356117],
              [-66.73052216, 18.34925079],
              [-66.73514557, 18.34825706],
              [-66.7383728, 18.34680176],
              [-66.73928833, 18.34584236],
              [-66.74066925, 18.34440041],
              [-66.7457428, 18.34197617],
              [-66.75266266, 18.33906364],
              [-66.75681305, 18.33712578],
              [-66.7701416, 18.32509422],
              [-66.76913452, 18.37371635],
              [-66.76696014, 18.39183617],
              [-66.76790619, 18.39469147],
              [-66.76984406, 18.40515709],
              [-66.76986694, 18.4080162],
              [-66.76760864, 18.41566086],
              [-66.76767731, 18.42423439],
              [-66.76770782, 18.42757034],
              [-66.76818085, 18.42899323],
              [-66.77007294, 18.43374443],
              [-66.77055359, 18.43612099],
              [-66.77056122, 18.43707466],
              [-66.76830292, 18.44423485],
              [-66.76831818, 18.44661903],
              [-66.76747131, 18.4566288],
              [-66.76750946, 18.46187019],
              [-66.76581573, 18.48236465],
              [-66.76541901, 18.48362923],
              [-66.76541901, 18.48319435],
              [-66.76513672, 18.48319435],
              [-66.76513672, 18.48291588],
              [-66.76486206, 18.48291588],
              [-66.76486206, 18.48263741],
              [-66.76457977, 18.48263741],
              [-66.76457977, 18.48236084],
              [-66.76264191, 18.48236084],
              [-66.76264191, 18.48208427],
              [-66.76180267, 18.48208427],
              [-66.76180267, 18.4818058],
              [-66.76125336, 18.4818058],
              [-66.76125336, 18.48152733],
              [-66.75986481, 18.48152733],
              [-66.75986481, 18.4818058],
              [-66.75958252, 18.4818058],
              [-66.75958252, 18.48152733],
              [-66.75902557, 18.48152733],
              [-66.75902557, 18.48125076],
              [-66.75819397, 18.48125076],
              [-66.75819397, 18.48097229],
              [-66.75791931, 18.48097229],
              [-66.75791931, 18.48041725],
              [-66.75708008, 18.48041725],
              [-66.75708008, 18.48013878],
              [-66.75541687, 18.48013878],
              [-66.75541687, 18.47958374],
              [-66.75458527, 18.47958374],
              [-66.75458527, 18.47930527],
              [-66.75402832, 18.47930527],
              [-66.75402832, 18.4790287],
              [-66.75375366, 18.4790287],
              [-66.75375366, 18.47875023],
              [-66.75347137, 18.47875023],
              [-66.75347137, 18.47847176],
              [-66.75291443, 18.47847176],
              [-66.75291443, 18.47819519],
              [-66.75208282, 18.47819519],
              [-66.75208282, 18.47791672],
              [-66.75152588, 18.47791672],
              [-66.75152588, 18.47763824],
              [-66.75096893, 18.47763824],
              [-66.75096893, 18.47736168],
              [-66.75013733, 18.47736168],
              [-66.75013733, 18.47708321],
              [-66.74986267, 18.47708321],
              [-66.74986267, 18.47680473],
              [-66.74958038, 18.47680473],
              [-66.74958038, 18.47652817],
              [-66.74903107, 18.47652817],
              [-66.74903107, 18.47624969],
              [-66.74847412, 18.47624969],
              [-66.74847412, 18.47652817],
              [-66.74819183, 18.47652817],
              [-66.74819183, 18.47680473],
              [-66.74708557, 18.47680473],
              [-66.74708557, 18.47624969],
              [-66.74652863, 18.47624969],
              [-66.74652863, 18.47597313],
              [-66.74624634, 18.47597313],
              [-66.74624634, 18.47569466],
              [-66.74541473, 18.47569466],
              [-66.74541473, 18.47541618],
              [-66.74458313, 18.47541618],
              [-66.74458313, 18.47513962],
              [-66.74291992, 18.47513962],
              [-66.74291992, 18.47486115],
              [-66.74236298, 18.47486115],
              [-66.74236298, 18.47458458],
              [-66.74180603, 18.47458458],
              [-66.74180603, 18.47430611],
              [-66.74097443, 18.47430611],
              [-66.74097443, 18.47402763],
              [-66.73958588, 18.47402763],
              [-66.73958588, 18.47374916],
              [-66.73874664, 18.47374916],
              [-66.73874664, 18.47402763],
              [-66.73847198, 18.47402763],
              [-66.73847198, 18.47458458],
              [-66.73735809, 18.47458458],
              [-66.73735809, 18.47402763],
              [-66.73708344, 18.47402763],
              [-66.73708344, 18.47374916],
              [-66.73569489, 18.47374916],
              [-66.73569489, 18.4734726],
              [-66.73458099, 18.4734726],
              [-66.73458099, 18.47374916],
              [-66.73291779, 18.47374916],
              [-66.73291779, 18.4734726],
              [-66.73152924, 18.4734726],
              [-66.73152924, 18.47374916],
              [-66.73124695, 18.47374916],
              [-66.73124695, 18.47402763],
              [-66.73097229, 18.47402763],
              [-66.73097229, 18.47458458],
              [-66.73041534, 18.47458458],
              [-66.73041534, 18.47486115],
              [-66.72958374, 18.47486115],
              [-66.72958374, 18.47513962],
              [-66.72930908, 18.47513962],
              [-66.72930908, 18.47486115],
              [-66.72902679, 18.47486115],
              [-66.72902679, 18.47513962],
              [-66.72875214, 18.47513962],
              [-66.72875214, 18.47486115],
              [-66.72736359, 18.47486115],
              [-66.72736359, 18.47458458],
              [-66.72652435, 18.47458458],
              [-66.72652435, 18.47486115],
              [-66.72541809, 18.47486115],
              [-66.72541809, 18.47513962],
              [-66.72486115, 18.47513962],
              [-66.72486115, 18.47486115],
              [-66.72402954, 18.47486115],
              [-66.72402954, 18.47513962],
              [-66.7234726, 18.47513962],
              [-66.7234726, 18.47541618],
              [-66.72291565, 18.47541618],
              [-66.72291565, 18.47569466],
              [-66.72264099, 18.47569466],
              [-66.72264099, 18.47597313],
              [-66.72208405, 18.47597313],
              [-66.72208405, 18.47569466],
              [-66.7215271, 18.47569466],
              [-66.7215271, 18.47541618],
              [-66.7195816, 18.47541618],
              [-66.7195816, 18.47513962],
              [-66.71930695, 18.47513962],
              [-66.71930695, 18.47486115],
              [-66.71902466, 18.47486115],
              [-66.71902466, 18.47458458],
              [-66.71875, 18.47458458],
              [-66.71875, 18.47430611],
              [-66.71847534, 18.47430611],
              [-66.71847534, 18.47402763],
              [-66.7159729, 18.47402763],
              [-66.7159729, 18.47374916],
              [-66.71263885, 18.47374916],
              [-66.71263885, 18.4734726],
              [-66.71208191, 18.4734726],
              [-66.71208191, 18.47319412],
              [-66.71097565, 18.47319412],
              [-66.71097565, 18.47291756],
              [-66.71069336, 18.47291756],
              [-66.71069336, 18.47263908],
              [-66.71013641, 18.47263908],
              [-66.71013641, 18.47236252],
              [-66.70930481, 18.47236252],
              [-66.70930481, 18.47180557],
              [-66.70874786, 18.47180557],
              [-66.70874786, 18.4715271],
              [-66.70791626, 18.4715271],
              [-66.70791626, 18.47180557],
              [-66.7076416, 18.47180557],
              [-66.7076416, 18.47208405],
              [-66.70680237, 18.47208405],
              [-66.70680237, 18.47236252],
              [-66.70569611, 18.47236252],
              [-66.70569611, 18.47263908],
              [-66.7048645, 18.47263908],
              [-66.7048645, 18.47291756],
              [-66.70402527, 18.47291756],
              [-66.70402527, 18.47319412],
              [-66.70347595, 18.47319412],
              [-66.70347595, 18.4734726],
              [-66.70319366, 18.4734726],
              [-66.70319366, 18.47374916],
              [-66.70263672, 18.47374916],
              [-66.70263672, 18.47402763],
              [-66.70207977, 18.47402763],
              [-66.70207977, 18.47430611],
              [-66.70180511, 18.47430611],
              [-66.70180511, 18.47458458],
              [-66.70153046, 18.47458458],
              [-66.70153046, 18.47486115],
              [-66.70124817, 18.47486115],
              [-66.70124817, 18.47569466],
              [-66.70097351, 18.47569466],
              [-66.70097351, 18.47624969],
              [-66.70069122, 18.47624969],
              [-66.70069122, 18.47652817],
              [-66.70041656, 18.47652817],
              [-66.70041656, 18.47708321],
              [-66.70069122, 18.47708321],
              [-66.70069122, 18.47791672],
              [-66.70041656, 18.47791672],
              [-66.70041656, 18.47847176],
              [-66.70069122, 18.47847176],
              [-66.70069122, 18.4790287],
              [-66.70041656, 18.4790287],
              [-66.70041656, 18.47958374],
              [-66.70069122, 18.47958374],
              [-66.70069122, 18.47986031],
              [-66.70124817, 18.47986031],
              [-66.70124817, 18.47958374],
              [-66.70153046, 18.47958374],
              [-66.70153046, 18.47930527],
              [-66.70180511, 18.47930527],
              [-66.70180511, 18.47958374],
              [-66.70291901, 18.47958374],
              [-66.70291901, 18.47986031],
              [-66.70347595, 18.47986031],
              [-66.70347595, 18.48097229],
              [-66.70319366, 18.48097229],
              [-66.70319366, 18.48125076],
              [-66.70291901, 18.48125076],
              [-66.70291901, 18.48152733],
              [-66.70180511, 18.48152733],
              [-66.70180511, 18.4818058],
              [-66.70153046, 18.4818058],
              [-66.70153046, 18.48208427],
              [-66.70124817, 18.48208427],
              [-66.70124817, 18.48236084],
              [-66.70097351, 18.48236084],
              [-66.70097351, 18.48263741],
              [-66.70069122, 18.48263741],
              [-66.70069122, 18.48291588],
              [-66.70014191, 18.48291588],
              [-66.70014191, 18.48263741],
              [-66.69958496, 18.48263741],
              [-66.69958496, 18.48236084],
              [-66.69930267, 18.48236084],
              [-66.69930267, 18.48263741],
              [-66.69819641, 18.48263741],
              [-66.69819641, 18.48291588],
              [-66.69652557, 18.48291588],
              [-66.69652557, 18.4818058],
              [-66.69569397, 18.4818058],
              [-66.69569397, 18.48152733],
              [-66.69458008, 18.48152733],
              [-66.69458008, 18.48125076],
              [-66.69374847, 18.48125076],
              [-66.69374847, 18.48097229],
              [-66.69264221, 18.48097229],
              [-66.69264221, 18.48125076],
              [-66.69208527, 18.48125076],
              [-66.69208527, 18.48097229],
              [-66.69180298, 18.48097229],
              [-66.69180298, 18.48125076],
              [-66.69152832, 18.48125076],
              [-66.69152832, 18.48097229],
              [-66.68930817, 18.48097229],
              [-66.68930817, 18.48069382],
              [-66.68763733, 18.48069382],
              [-66.68763733, 18.48097229],
              [-66.68736267, 18.48097229],
              [-66.68736267, 18.48069382],
              [-66.68374634, 18.48069382],
              [-66.68374634, 18.48097229],
              [-66.68347168, 18.48097229],
              [-66.68347168, 18.48125076],
              [-66.68319702, 18.48125076],
              [-66.68319702, 18.4818058],
              [-66.68291473, 18.4818058],
              [-66.68291473, 18.48236084],
              [-66.68264008, 18.48236084],
              [-66.68264008, 18.48291588],
              [-66.68235779, 18.48291588],
              [-66.68235779, 18.48319435],
              [-66.68180847, 18.48319435],
              [-66.68180847, 18.48347282],
              [-66.68152618, 18.48347282],
              [-66.68152618, 18.48374939],
              [-66.68125153, 18.48374939],
              [-66.68125153, 18.48402977],
              [-66.68096924, 18.48402977],
              [-66.68096924, 18.48430634],
              [-66.68041992, 18.48430634],
              [-66.68041992, 18.4845829],
              [-66.67986298, 18.4845829],
              [-66.67986298, 18.48485947],
              [-66.67930603, 18.48485947],
              [-66.67930603, 18.48513794],
              [-66.67874908, 18.48513794],
              [-66.67874908, 18.48541641],
              [-66.67763519, 18.48541641],
              [-66.67763519, 18.48569489],
              [-66.67680359, 18.48569489],
              [-66.67680359, 18.48596954],
              [-66.67624664, 18.48596954],
              [-66.67624664, 18.48625183],
              [-66.67597198, 18.48625183],
              [-66.67597198, 18.4865284],
              [-66.67541504, 18.4865284],
              [-66.67541504, 18.48708344],
              [-66.67514038, 18.48708344],
              [-66.67514038, 18.48791695],
              [-66.67485809, 18.48791695],
              [-66.67485809, 18.48819351],
              [-66.67458344, 18.48819351],
              [-66.67458344, 18.48902702],
              [-66.67485809, 18.48902702],
              [-66.67485809, 18.48958588],
              [-66.67514038, 18.48958588],
              [-66.67514038, 18.4893055],
              [-66.67624664, 18.4893055],
              [-66.67624664, 18.48902702],
              [-66.67652893, 18.48902702],
              [-66.67652893, 18.48875046],
              [-66.67736053, 18.48875046],
              [-66.67736053, 18.4893055],
              [-66.67763519, 18.4893055],
              [-66.67763519, 18.48986053],
              [-66.67708588, 18.48986053],
              [-66.67708588, 18.49013901],
              [-66.67375183, 18.49013901],
              [-66.67375183, 18.48986053],
              [-66.67263794, 18.48986053],
              [-66.67263794, 18.49013901],
              [-66.67152405, 18.49013901],
              [-66.67152405, 18.48986053],
              [-66.67124939, 18.48986053],
              [-66.67124939, 18.48875046],
              [-66.67097473, 18.48875046],
              [-66.67097473, 18.48819351],
              [-66.66930389, 18.48819351],
              [-66.66930389, 18.48791695],
              [-66.66847229, 18.48791695],
              [-66.66847229, 18.48763847],
              [-66.66791534, 18.48763847],
              [-66.66791534, 18.48736382],
              [-66.66430664, 18.48736382],
              [-66.66430664, 18.48763847],
              [-66.66347504, 18.48763847],
              [-66.66347504, 18.48791695],
              [-66.6609726, 18.48791695],
              [-66.6609726, 18.48763847],
              [-66.6598587, 18.48763847],
              [-66.6598587, 18.48791695],
              [-66.65763855, 18.48791695],
              [-66.65763855, 18.48819351],
              [-66.65569305, 18.48819351],
              [-66.65569305, 18.48847389],
              [-66.65041351, 18.48847389],
              [-66.65041351, 18.48819351],
              [-66.64930725, 18.48819351],
              [-66.64930725, 18.48847389],
              [-66.6451416, 18.48847389],
              [-66.6451416, 18.48875046],
              [-66.64458466, 18.48875046],
              [-66.64458466, 18.48902702],
              [-66.64430237, 18.48902702],
              [-66.64430237, 18.48958588],
              [-66.64375305, 18.48958588],
              [-66.64375305, 18.48986053],
              [-66.64319611, 18.48986053],
              [-66.64319611, 18.49013901],
              [-66.64291382, 18.49013901],
              [-66.64291382, 18.49041748],
              [-66.64263916, 18.49041748],
              [-66.64263916, 18.49069595],
              [-66.6423645, 18.49069595],
              [-66.6423645, 18.49124908],
              [-66.64208221, 18.49124908],
              [-66.64208221, 18.4920826],
              [-66.64347076, 18.4920826],
              [-66.64347076, 18.49291611],
              [-66.64180756, 18.49291611],
              [-66.64180756, 18.49319458],
              [-66.64069366, 18.49319458],
              [-66.64069366, 18.49291611],
              [-66.63791656, 18.49291611],
              [-66.63791656, 18.49319458],
              [-66.63735962, 18.49319458],
              [-66.63735962, 18.49347305],
              [-66.63680267, 18.49347305],
              [-66.63680267, 18.49374962],
              [-66.63569641, 18.49374962],
              [-66.63569641, 18.49347305],
              [-66.63541412, 18.49347305],
              [-66.63541412, 18.49291611],
              [-66.63486481, 18.49291611],
              [-66.63486481, 18.49263954],
              [-66.63402557, 18.49263954],
              [-66.63402557, 18.49291611],
              [-66.63319397, 18.49291611],
              [-66.63319397, 18.49263954],
              [-66.63263702, 18.49263954],
              [-66.63263702, 18.49236107],
              [-66.63180542, 18.49236107],
              [-66.63180542, 18.4920826],
              [-66.63014221, 18.4920826],
              [-66.63014221, 18.49236107],
              [-66.62847137, 18.49236107],
              [-66.62847137, 18.49263954],
              [-66.62819672, 18.49263954],
              [-66.62819672, 18.49319458],
              [-66.62847137, 18.49319458],
              [-66.62847137, 18.49374962],
              [-66.62819672, 18.49374962],
              [-66.62819672, 18.49402618],
              [-66.62791443, 18.49402618],
              [-66.62791443, 18.49430466],
              [-66.62680817, 18.49430466],
              [-66.62680817, 18.49374962],
              [-66.62708282, 18.49374962],
              [-66.62708282, 18.49291611],
              [-66.62680817, 18.49291611],
              [-66.62680817, 18.49263954],
              [-66.62625122, 18.49263954],
              [-66.62625122, 18.49236107],
              [-66.62458038, 18.49236107],
              [-66.62458038, 18.49263954],
              [-66.62430573, 18.49263954],
              [-66.62430573, 18.49291611],
              [-66.62458038, 18.49291611],
              [-66.62458038, 18.49319458],
              [-66.62486267, 18.49319458],
              [-66.62486267, 18.49347305],
              [-66.62513733, 18.49347305],
              [-66.62513733, 18.49402618],
              [-66.62264252, 18.49402618],
              [-66.62264252, 18.49374962],
              [-66.62180328, 18.49374962],
              [-66.62180328, 18.49347305],
              [-66.62152863, 18.49347305],
              [-66.62152863, 18.49291611],
              [-66.62097168, 18.49291611],
              [-66.62097168, 18.49263954],
              [-66.62069702, 18.49263954],
              [-66.62069702, 18.49180412],
              [-66.62041473, 18.49180412],
              [-66.62041473, 18.49152756],
              [-66.61846924, 18.49152756],
              [-66.61846924, 18.49124908],
              [-66.61763763, 18.49124908],
              [-66.61763763, 18.49097252],
              [-66.61653137, 18.49097252],
              [-66.61653137, 18.49069595],
              [-66.61347198, 18.49069595],
              [-66.61347198, 18.49097252],
              [-66.61264038, 18.49097252],
              [-66.61264038, 18.49124908],
              [-66.61152649, 18.49124908],
              [-66.61152649, 18.49097252],
              [-66.61096954, 18.49097252],
              [-66.61096954, 18.49069595],
              [-66.60930634, 18.49069595],
              [-66.60930634, 18.49041748],
              [-66.60708618, 18.49041748],
              [-66.60708618, 18.49013901],
              [-66.60597229, 18.49013901],
              [-66.60597229, 18.48986053],
              [-66.60541534, 18.48986053],
              [-66.60541534, 18.48958588],
              [-66.6048584, 18.48958588],
              [-66.6048584, 18.4893055],
              [-66.60430908, 18.4893055],
              [-66.60430908, 18.48902702],
              [-66.60346985, 18.48902702],
              [-66.60346985, 18.48875046],
              [-66.60152435, 18.48875046],
              [-66.60152435, 18.48902702],
              [-66.60097504, 18.48902702],
              [-66.60097504, 18.48875046],
              [-66.6001358, 18.48875046],
              [-66.6001358, 18.48847389],
              [-66.59958649, 18.48847389],
              [-66.59958649, 18.48819351],
              [-66.59764099, 18.48819351],
              [-66.59764099, 18.48791695],
              [-66.59680176, 18.48791695],
              [-66.59680176, 18.48763847],
              [-66.59625244, 18.48763847],
              [-66.59625244, 18.48736382],
              [-66.59597015, 18.48736382],
              [-66.59597015, 18.48708344],
              [-66.59541321, 18.48708344],
              [-66.59541321, 18.48680496],
              [-66.59430695, 18.48680496],
              [-66.59430695, 18.4865284],
              [-66.59402466, 18.4865284],
              [-66.59402466, 18.48625183],
              [-66.59263611, 18.48625183],
              [-66.59263611, 18.48596954],
              [-66.59208679, 18.48596954],
              [-66.59208679, 18.48569489],
              [-66.59152985, 18.48569489],
              [-66.59152985, 18.48541641],
              [-66.59069824, 18.48541641],
              [-66.59069824, 18.48513794],
              [-66.58847046, 18.48513794],
              [-66.58847046, 18.48485947],
              [-66.5881958, 18.48485947],
              [-66.5881958, 18.4845829],
              [-66.5873642, 18.4845829],
              [-66.5873642, 18.48430634],
              [-66.58708191, 18.48430634],
              [-66.58708191, 18.4845829],
              [-66.58691406, 18.4845829],
              [-66.58869171, 18.47318268],
              [-66.58634949, 18.46843529],
              [-66.58448029, 18.46654129],
              [-66.57985687, 18.4679985],
              [-66.58073425, 18.45941734],
              [-66.58116913, 18.45512962],
              [-66.58294678, 18.44178009],
              [-66.58293152, 18.43939781],
              [-66.58512878, 18.41937828],
              [-66.58648682, 18.41365242],
              [-66.58738708, 18.41031075],
              [-66.59333801, 18.39883995],
              [-66.59144592, 18.39169884],
              [-66.59008789, 18.38986778],
              [-66.58798981, 18.38970757],
              [-66.5885849, 18.37506866],
              [-66.58213043, 18.3742218],
              [-66.58207703, 18.37365532],
              [-66.58293915, 18.36316681],
              [-66.58844757, 18.3555069],
              [-66.58979797, 18.34882736],
              [-66.59023285, 18.34453201],
              [-66.59065247, 18.33786011],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 7,
        name: 'Arecibo',
        hasc_1: 'PR.AC',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.77056122, 18.3150692, -66.57985687, 18.49430466],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.8',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.01982117, 17.97819519],
              [-66.02069092, 17.97819519],
              [-66.02069092, 17.97791672],
              [-66.02124786, 17.97791672],
              [-66.02124786, 17.97763824],
              [-66.02153015, 17.97763824],
              [-66.02153015, 17.97736168],
              [-66.0220871, 17.97736168],
              [-66.0220871, 17.97708321],
              [-66.02236176, 17.97708321],
              [-66.02236176, 17.97680473],
              [-66.02263641, 17.97680473],
              [-66.02263641, 17.97652817],
              [-66.02375031, 17.97652817],
              [-66.02375031, 17.9762516],
              [-66.02402496, 17.9762516],
              [-66.02402496, 17.97597313],
              [-66.02430725, 17.97597313],
              [-66.02430725, 17.97569466],
              [-66.02458191, 17.97569466],
              [-66.02458191, 17.97541618],
              [-66.0248642, 17.97541618],
              [-66.0248642, 17.97513962],
              [-66.02513885, 17.97513962],
              [-66.02513885, 17.97486115],
              [-66.02541351, 17.97486115],
              [-66.02541351, 17.97430611],
              [-66.0256958, 17.97430611],
              [-66.0256958, 17.97402954],
              [-66.02597046, 17.97402954],
              [-66.02597046, 17.9734726],
              [-66.0265274, 17.9734726],
              [-66.0265274, 17.97319412],
              [-66.02680206, 17.97319412],
              [-66.02680206, 17.97291946],
              [-66.02708435, 17.97291946],
              [-66.02708435, 17.97263718],
              [-66.0276413, 17.97263718],
              [-66.0276413, 17.97208595],
              [-66.02791595, 17.97208595],
              [-66.02791595, 17.97152519],
              [-66.0284729, 17.97152519],
              [-66.0284729, 17.97125053],
              [-66.02874756, 17.97125053],
              [-66.02874756, 17.97097206],
              [-66.02902985, 17.97097206],
              [-66.02902985, 17.97069359],
              [-66.0293045, 17.97069359],
              [-66.0293045, 17.97041512],
              [-66.02958679, 17.97041512],
              [-66.02958679, 17.97013855],
              [-66.03013611, 17.97013855],
              [-66.03013611, 17.96986198],
              [-66.0304184, 17.96986198],
              [-66.0304184, 17.96958351],
              [-66.03069305, 17.96958351],
              [-66.03069305, 17.96930313],
              [-66.03097534, 17.96930313],
              [-66.03097534, 17.96875],
              [-66.03125, 17.96875],
              [-66.03125, 17.96819305],
              [-66.03152466, 17.96819305],
              [-66.03152466, 17.96791649],
              [-66.03180695, 17.96791649],
              [-66.03180695, 17.96763802],
              [-66.0320816, 17.96763802],
              [-66.0320816, 17.96708488],
              [-66.03236389, 17.96708488],
              [-66.03236389, 17.96680641],
              [-66.03263855, 17.96680641],
              [-66.03263855, 17.96652794],
              [-66.03291321, 17.96652794],
              [-66.03291321, 17.96624947],
              [-66.0331955, 17.96624947],
              [-66.0331955, 17.96569443],
              [-66.03347015, 17.96569443],
              [-66.03347015, 17.96486282],
              [-66.03375244, 17.96486282],
              [-66.03375244, 17.9640274],
              [-66.0340271, 17.9640274],
              [-66.0340271, 17.96347237],
              [-66.03430176, 17.96347237],
              [-66.03430176, 17.96264076],
              [-66.03458405, 17.96264076],
              [-66.03458405, 17.96180534],
              [-66.0348587, 17.96180534],
              [-66.0348587, 17.96152878],
              [-66.03569794, 17.96152878],
              [-66.03569794, 17.96180534],
              [-66.0359726, 17.96180534],
              [-66.0359726, 17.96208382],
              [-66.03652954, 17.96208382],
              [-66.03652954, 17.96235847],
              [-66.0368042, 17.96235847],
              [-66.0368042, 17.96264076],
              [-66.03791809, 17.96264076],
              [-66.03791809, 17.96235847],
              [-66.03874969, 17.96235847],
              [-66.03874969, 17.96208382],
              [-66.0395813, 17.96208382],
              [-66.0395813, 17.96180534],
              [-66.04013824, 17.96180534],
              [-66.04013824, 17.96152878],
              [-66.0404129, 17.96152878],
              [-66.0404129, 17.96097183],
              [-66.04069519, 17.96097183],
              [-66.04069519, 17.96069527],
              [-66.04096985, 17.96069527],
              [-66.04096985, 17.96013641],
              [-66.04125214, 17.96013641],
              [-66.04125214, 17.95986176],
              [-66.04152679, 17.95986176],
              [-66.04152679, 17.95958328],
              [-66.04180908, 17.95958328],
              [-66.04180908, 17.95930481],
              [-66.04208374, 17.95930481],
              [-66.04208374, 17.95902634],
              [-66.0423584, 17.95902634],
              [-66.0423584, 17.95874977],
              [-66.04264069, 17.95874977],
              [-66.04264069, 17.95763969],
              [-66.04291534, 17.95763969],
              [-66.04291534, 17.95597267],
              [-66.04402924, 17.95597267],
              [-66.04402924, 17.9556942],
              [-66.04458618, 17.9556942],
              [-66.04458618, 17.95541573],
              [-66.04624939, 17.95541573],
              [-66.04624939, 17.95513916],
              [-66.04652405, 17.95513916],
              [-66.04652405, 17.95347214],
              [-66.04680634, 17.95347214],
              [-66.04680634, 17.9529171],
              [-66.04708099, 17.9529171],
              [-66.04708099, 17.95263863],
              [-66.04736328, 17.95263863],
              [-66.04736328, 17.95236206],
              [-66.04792023, 17.95236206],
              [-66.04792023, 17.95208359],
              [-66.04819489, 17.95208359],
              [-66.04819489, 17.95236206],
              [-66.04846954, 17.95236206],
              [-66.04846954, 17.95263863],
              [-66.04875183, 17.95263863],
              [-66.04875183, 17.95319366],
              [-66.04902649, 17.95319366],
              [-66.04902649, 17.95458221],
              [-66.04930878, 17.95458221],
              [-66.04930878, 17.95541573],
              [-66.05014038, 17.95541573],
              [-66.05014038, 17.9556942],
              [-66.05041504, 17.9556942],
              [-66.05041504, 17.95597267],
              [-66.05069733, 17.95597267],
              [-66.05069733, 17.95624924],
              [-66.05097198, 17.95624924],
              [-66.05097198, 17.95652771],
              [-66.05124664, 17.95652771],
              [-66.05124664, 17.95680428],
              [-66.05180359, 17.95680428],
              [-66.05180359, 17.95708275],
              [-66.05291748, 17.95708275],
              [-66.05291748, 17.95736122],
              [-66.05374908, 17.95736122],
              [-66.05374908, 17.95763969],
              [-66.05403137, 17.95763969],
              [-66.05403137, 17.95819473],
              [-66.05430603, 17.95819473],
              [-66.05430603, 17.9584713],
              [-66.05486298, 17.9584713],
              [-66.05486298, 17.95874977],
              [-66.05625153, 17.95874977],
              [-66.05625153, 17.9584713],
              [-66.05847168, 17.9584713],
              [-66.05847168, 17.95874977],
              [-66.05874634, 17.95874977],
              [-66.05874634, 17.95902634],
              [-66.05930328, 17.95902634],
              [-66.05930328, 17.95930481],
              [-66.06014252, 17.95930481],
              [-66.06014252, 17.95958328],
              [-66.06124878, 17.95958328],
              [-66.06124878, 17.95986176],
              [-66.06180573, 17.95986176],
              [-66.06180573, 17.96013641],
              [-66.06346893, 17.96013641],
              [-66.06346893, 17.9604187],
              [-66.06430817, 17.9604187],
              [-66.06430817, 17.96069527],
              [-66.06458282, 17.96069527],
              [-66.06458282, 17.96152878],
              [-66.06430817, 17.96152878],
              [-66.06430817, 17.96208382],
              [-66.06458282, 17.96208382],
              [-66.06458282, 17.96235847],
              [-66.06597137, 17.96235847],
              [-66.06597137, 17.96264076],
              [-66.06625366, 17.96264076],
              [-66.06625366, 17.96375084],
              [-66.06652832, 17.96375084],
              [-66.06652832, 17.9640274],
              [-66.06680298, 17.9640274],
              [-66.06680298, 17.96430588],
              [-66.06708527, 17.96430588],
              [-66.06708527, 17.96458244],
              [-66.06764221, 17.96458244],
              [-66.06764221, 17.96541595],
              [-66.06791687, 17.96541595],
              [-66.06791687, 17.9659729],
              [-66.06847382, 17.9659729],
              [-66.06847382, 17.96624947],
              [-66.06903076, 17.96624947],
              [-66.06903076, 17.96652794],
              [-66.06986237, 17.96652794],
              [-66.06986237, 17.96624947],
              [-66.07024384, 17.96624947],
              [-66.07247162, 17.97864723],
              [-66.07201385, 17.98724365],
              [-66.07155609, 17.99392891],
              [-66.07663727, 18.00012779],
              [-66.09141541, 18.01777458],
              [-66.09510803, 18.02158928],
              [-66.09510803, 18.02206612],
              [-66.09418488, 18.02350044],
              [-66.09049988, 18.02445793],
              [-66.08957672, 18.02445984],
              [-66.08450317, 18.02828026],
              [-66.0821991, 18.03257751],
              [-66.07943726, 18.03496742],
              [-66.07574463, 18.03735924],
              [-66.05775452, 18.04071236],
              [-66.05683136, 18.04023552],
              [-66.04805756, 18.03546906],
              [-66.04389954, 18.02974129],
              [-66.04297638, 18.02592278],
              [-66.04251862, 18.02210617],
              [-66.04251862, 18.02019691],
              [-66.04159546, 18.01733398],
              [-66.04712677, 18.00921631],
              [-66.0443573, 18.00635338],
              [-66.03927612, 18.00349045],
              [-66.03559113, 17.99824333],
              [-66.02774811, 17.99442863],
              [-66.02635956, 17.99299622],
              [-66.02405548, 17.99013138],
              [-66.02359009, 17.98535728],
              [-66.02266693, 17.98297119],
              [-66.02220917, 17.98249245],
              [-66.01990509, 17.97867584],
              [-66.01982117, 17.97819519],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 8,
        name: 'Arroyo',
        hasc_1: 'PR.AR',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.09510803, 17.95208359, -66.01982117, 18.04071236],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.9',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.58691406, 18.4845829],
              [-66.5866394, 18.4845829],
              [-66.5854187, 18.4845829],
              [-66.5854187, 18.48430634],
              [-66.5845871, 18.48430634],
              [-66.5845871, 18.48402977],
              [-66.58374786, 18.48402977],
              [-66.58374786, 18.48430634],
              [-66.58347321, 18.48430634],
              [-66.58347321, 18.48402977],
              [-66.58152771, 18.48402977],
              [-66.58152771, 18.48374939],
              [-66.57958221, 18.48374939],
              [-66.57958221, 18.48402977],
              [-66.57819366, 18.48402977],
              [-66.57819366, 18.48430634],
              [-66.57707977, 18.48430634],
              [-66.57707977, 18.48402977],
              [-66.57514191, 18.48402977],
              [-66.57514191, 18.48430634],
              [-66.57458496, 18.48430634],
              [-66.57458496, 18.4845829],
              [-66.57430267, 18.4845829],
              [-66.57430267, 18.48485947],
              [-66.57375336, 18.48485947],
              [-66.57375336, 18.48513794],
              [-66.57208252, 18.48513794],
              [-66.57208252, 18.48541641],
              [-66.57041931, 18.48541641],
              [-66.57041931, 18.48569489],
              [-66.56708527, 18.48569489],
              [-66.56708527, 18.48541641],
              [-66.56625366, 18.48541641],
              [-66.56625366, 18.48513794],
              [-66.56569672, 18.48513794],
              [-66.56569672, 18.48541641],
              [-66.56513977, 18.48541641],
              [-66.56513977, 18.48569489],
              [-66.56485748, 18.48569489],
              [-66.56485748, 18.48596954],
              [-66.56430817, 18.48596954],
              [-66.56430817, 18.48625183],
              [-66.56375122, 18.48625183],
              [-66.56375122, 18.4865284],
              [-66.56319427, 18.4865284],
              [-66.56319427, 18.48680496],
              [-66.56291962, 18.48680496],
              [-66.56291962, 18.48736382],
              [-66.56263733, 18.48736382],
              [-66.56263733, 18.48791695],
              [-66.56236267, 18.48791695],
              [-66.56236267, 18.48819351],
              [-66.56208038, 18.48819351],
              [-66.56208038, 18.48875046],
              [-66.56180573, 18.48875046],
              [-66.56180573, 18.48902702],
              [-66.56208038, 18.48902702],
              [-66.56208038, 18.4893055],
              [-66.56236267, 18.4893055],
              [-66.56236267, 18.48958588],
              [-66.56263733, 18.48958588],
              [-66.56263733, 18.48986053],
              [-66.56291962, 18.48986053],
              [-66.56291962, 18.49041748],
              [-66.56208038, 18.49041748],
              [-66.56208038, 18.49013901],
              [-66.56097412, 18.49013901],
              [-66.56097412, 18.48986053],
              [-66.55986023, 18.48986053],
              [-66.55986023, 18.49013901],
              [-66.55930328, 18.49013901],
              [-66.55930328, 18.48986053],
              [-66.55874634, 18.48986053],
              [-66.55874634, 18.48958588],
              [-66.55847168, 18.48958588],
              [-66.55847168, 18.4893055],
              [-66.55819702, 18.4893055],
              [-66.55819702, 18.48902702],
              [-66.55791473, 18.48902702],
              [-66.55791473, 18.48875046],
              [-66.55764008, 18.48875046],
              [-66.55764008, 18.48847389],
              [-66.55708313, 18.48847389],
              [-66.55708313, 18.48819351],
              [-66.55652618, 18.48819351],
              [-66.55652618, 18.48791695],
              [-66.55596924, 18.48791695],
              [-66.55596924, 18.48763847],
              [-66.55513763, 18.48763847],
              [-66.55513763, 18.48736382],
              [-66.55458069, 18.48736382],
              [-66.55458069, 18.48708344],
              [-66.55374908, 18.48708344],
              [-66.55374908, 18.48680496],
              [-66.55319214, 18.48680496],
              [-66.55319214, 18.48708344],
              [-66.55236053, 18.48708344],
              [-66.55236053, 18.48736382],
              [-66.54930878, 18.48736382],
              [-66.54930878, 18.48708344],
              [-66.54875183, 18.48708344],
              [-66.54875183, 18.48680496],
              [-66.54819489, 18.48680496],
              [-66.54819489, 18.4865284],
              [-66.54763794, 18.4865284],
              [-66.54763794, 18.48625183],
              [-66.54680634, 18.48625183],
              [-66.54680634, 18.48596954],
              [-66.54652405, 18.48596954],
              [-66.54652405, 18.48569489],
              [-66.54624939, 18.48569489],
              [-66.54624939, 18.48541641],
              [-66.54569244, 18.48541641],
              [-66.54569244, 18.48513794],
              [-66.54541779, 18.48513794],
              [-66.54541779, 18.48485947],
              [-66.54486084, 18.48485947],
              [-66.54486084, 18.4845829],
              [-66.54374695, 18.4845829],
              [-66.54374695, 18.48430634],
              [-66.54347229, 18.48430634],
              [-66.54347229, 18.48402977],
              [-66.54264069, 18.48402977],
              [-66.54264069, 18.48374939],
              [-66.54152679, 18.48374939],
              [-66.54152679, 18.48347282],
              [-66.54069519, 18.48347282],
              [-66.54069519, 18.48319435],
              [-66.5395813, 18.48319435],
              [-66.5395813, 18.48291588],
              [-66.53902435, 18.48291588],
              [-66.53902435, 18.48263741],
              [-66.53874969, 18.48263741],
              [-66.53874969, 18.48236084],
              [-66.53847504, 18.48236084],
              [-66.53847504, 18.48208427],
              [-66.53819275, 18.48208427],
              [-66.53819275, 18.4818058],
              [-66.53791809, 18.4818058],
              [-66.53791809, 18.48152733],
              [-66.53569794, 18.48152733],
              [-66.53569794, 18.48125076],
              [-66.53514099, 18.48125076],
              [-66.53514099, 18.48069382],
              [-66.5348587, 18.48069382],
              [-66.5348587, 18.48013878],
              [-66.53514099, 18.48013878],
              [-66.53514099, 18.47930527],
              [-66.5348587, 18.47930527],
              [-66.5348587, 18.4790287],
              [-66.53458405, 18.4790287],
              [-66.53458405, 18.47875023],
              [-66.53418732, 18.47875023],
              [-66.53356934, 18.47780609],
              [-66.53076172, 18.4730587],
              [-66.5302887, 18.47115326],
              [-66.53025818, 18.46639061],
              [-66.53162384, 18.46209526],
              [-66.532547, 18.46066475],
              [-66.53716278, 18.45682144],
              [-66.53761292, 18.45491791],
              [-66.53760529, 18.45396614],
              [-66.5366745, 18.45349312],
              [-66.53020477, 18.45686722],
              [-66.52788544, 18.45640373],
              [-66.52649689, 18.45545769],
              [-66.52602386, 18.4540329],
              [-66.53017426, 18.45019913],
              [-66.53202057, 18.4492321],
              [-66.53710937, 18.44729805],
              [-66.53803253, 18.44681931],
              [-66.53941345, 18.44490433],
              [-66.53986359, 18.44347191],
              [-66.5375061, 18.4358654],
              [-66.53704071, 18.43538857],
              [-66.53657532, 18.43491745],
              [-66.53518677, 18.43445015],
              [-66.53101349, 18.43447304],
              [-66.5254364, 18.43069267],
              [-66.52680206, 18.42639732],
              [-66.52957153, 18.42447662],
              [-66.53557587, 18.42158508],
              [-66.54343414, 18.41868019],
              [-66.54342651, 18.41820335],
              [-66.5424881, 18.41487503],
              [-66.5429306, 18.41105652],
              [-66.54337311, 18.40772247],
              [-66.54336548, 18.40629196],
              [-66.54256439, 18.40455246],
              [-66.55014801, 18.40587234],
              [-66.55239868, 18.41107368],
              [-66.56542969, 18.41372108],
              [-66.57155609, 18.41112518],
              [-66.57582855, 18.40565681],
              [-66.58235931, 18.38928032],
              [-66.58798981, 18.38970757],
              [-66.59008789, 18.38986778],
              [-66.59144592, 18.39169884],
              [-66.59333801, 18.39883995],
              [-66.58738708, 18.41031075],
              [-66.58648682, 18.41365242],
              [-66.58512878, 18.41937828],
              [-66.58293152, 18.43939781],
              [-66.58294678, 18.44178009],
              [-66.58116913, 18.45512962],
              [-66.58073425, 18.45941734],
              [-66.57985687, 18.4679985],
              [-66.58448029, 18.46654129],
              [-66.58634949, 18.46843529],
              [-66.58869171, 18.47318268],
              [-66.58691406, 18.4845829],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 9,
        name: 'Barceloneta',
        hasc_1: 'PR.BC',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.59333801, 18.38928032, -66.5254364, 18.49041748],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.10',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.35101318, 18.24136162],
              [-66.34037018, 18.23854065],
              [-66.33621216, 18.23950768],
              [-66.32698059, 18.24431038],
              [-66.32607269, 18.24765015],
              [-66.31867218, 18.24815178],
              [-66.31312561, 18.24912643],
              [-66.30709076, 18.24151611],
              [-66.30662537, 18.24104309],
              [-66.29600525, 18.24584389],
              [-66.29184723, 18.24538231],
              [-66.29138184, 18.24538231],
              [-66.27981567, 18.24351311],
              [-66.26641083, 18.2454586],
              [-66.26824951, 18.24116135],
              [-66.26545715, 18.23354149],
              [-66.27050018, 18.21922302],
              [-66.27050018, 18.21874619],
              [-66.27095795, 18.21731377],
              [-66.27002716, 18.2149334],
              [-66.2644577, 18.20541191],
              [-66.26396942, 18.19634819],
              [-66.26347351, 18.18538284],
              [-66.25653839, 18.18254089],
              [-66.25561523, 18.18397331],
              [-66.25562286, 18.18540573],
              [-66.25377655, 18.18779373],
              [-66.25054932, 18.18923187],
              [-66.24824524, 18.1916256],
              [-66.24408722, 18.19354439],
              [-66.24269867, 18.19164085],
              [-66.24082947, 18.1844902],
              [-66.24359894, 18.18400764],
              [-66.24452209, 18.18304825],
              [-66.2440567, 18.18114471],
              [-66.24359131, 18.18066978],
              [-66.24266815, 18.18019295],
              [-66.24773407, 18.17445564],
              [-66.24911499, 18.1701622],
              [-66.24817657, 18.16587067],
              [-66.25556946, 18.16632843],
              [-66.25604248, 18.17061996],
              [-66.25650787, 18.1710968],
              [-66.26621246, 18.1724987],
              [-66.27314758, 18.17390823],
              [-66.277771, 18.17437363],
              [-66.27915192, 18.17389488],
              [-66.29252625, 18.16526413],
              [-66.29807281, 18.16524506],
              [-66.29944611, 18.16142654],
              [-66.29990387, 18.16046906],
              [-66.30175018, 18.15903282],
              [-66.30221558, 18.15950966],
              [-66.30729675, 18.15997124],
              [-66.30867767, 18.15948677],
              [-66.30914307, 18.15948677],
              [-66.31006622, 18.15948105],
              [-66.31191254, 18.15756607],
              [-66.31465912, 18.15183449],
              [-66.31604767, 18.15230751],
              [-66.32299805, 18.15705109],
              [-66.32209778, 18.16421127],
              [-66.32672119, 18.16610336],
              [-66.33088684, 18.16847229],
              [-66.34197235, 18.1679554],
              [-66.34243774, 18.16795349],
              [-66.34659576, 18.16745949],
              [-66.3493576, 18.16601753],
              [-66.35120392, 18.16553879],
              [-66.36461639, 18.16977692],
              [-66.36786652, 18.17214966],
              [-66.37296295, 18.17546463],
              [-66.36745453, 18.18550301],
              [-66.36748505, 18.19408798],
              [-66.36749268, 18.19694901],
              [-66.36381531, 18.20078087],
              [-66.36010742, 18.1993618],
              [-66.36010742, 18.19888687],
              [-66.35871887, 18.19746017],
              [-66.35592651, 18.19269943],
              [-66.35592651, 18.1922245],
              [-66.35223389, 18.19414711],
              [-66.35177612, 18.19415092],
              [-66.35042572, 18.20560074],
              [-66.35139465, 18.21751976],
              [-66.35139465, 18.21847343],
              [-66.35142517, 18.22514725],
              [-66.35095978, 18.2256279],
              [-66.35050964, 18.22801018],
              [-66.35144806, 18.23182487],
              [-66.35101318, 18.24136162],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 10,
        name: 'Barranquitas',
        hasc_1: 'PR.BQ',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.37296295, 18.15183449, -66.24082947, 18.24912643],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.11',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.20636749, 18.27660179],
              [-66.20822906, 18.28184128],
              [-66.20915985, 18.28469849],
              [-66.21148682, 18.29184532],
              [-66.21148682, 18.29232025],
              [-66.21337128, 18.30518913],
              [-66.21336365, 18.30566597],
              [-66.21337128, 18.30662155],
              [-66.21290588, 18.30709648],
              [-66.20874786, 18.30662918],
              [-66.20642853, 18.3066349],
              [-66.20596313, 18.3066349],
              [-66.20552826, 18.31664467],
              [-66.2013855, 18.32904625],
              [-66.20372009, 18.33666611],
              [-66.19956207, 18.3400116],
              [-66.20050049, 18.34668159],
              [-66.19958496, 18.3547821],
              [-66.19728851, 18.36050797],
              [-66.19728851, 18.36193848],
              [-66.19776154, 18.36527443],
              [-66.19915009, 18.36860466],
              [-66.19961548, 18.36955833],
              [-66.19916534, 18.37289238],
              [-66.19688416, 18.38910294],
              [-66.19550323, 18.39672661],
              [-66.19644165, 18.40005875],
              [-66.19783783, 18.40386772],
              [-66.19645691, 18.40863609],
              [-66.19786072, 18.41482735],
              [-66.19138336, 18.41817474],
              [-66.18952942, 18.4186573],
              [-66.18767548, 18.41913605],
              [-66.18721771, 18.42056847],
              [-66.18536377, 18.42152214],
              [-66.18212128, 18.42200661],
              [-66.17933655, 18.42010689],
              [-66.17841339, 18.41915321],
              [-66.17470551, 18.42011261],
              [-66.17378235, 18.42059135],
              [-66.1723938, 18.42107201],
              [-66.17008209, 18.42441177],
              [-66.16962433, 18.42584038],
              [-66.17055511, 18.42917442],
              [-66.17055511, 18.42964745],
              [-66.16916656, 18.43108177],
              [-66.16592407, 18.43013382],
              [-66.16221619, 18.4282341],
              [-66.15989685, 18.42538261],
              [-66.15850067, 18.42157173],
              [-66.15756989, 18.4210968],
              [-66.15293884, 18.42206192],
              [-66.15201569, 18.42253685],
              [-66.14877319, 18.4230175],
              [-66.14043427, 18.42303085],
              [-66.13580322, 18.42208672],
              [-66.13163757, 18.42447472],
              [-66.13209534, 18.42066193],
              [-66.12885284, 18.41971397],
              [-66.12514496, 18.41876411],
              [-66.12282562, 18.41638756],
              [-66.12097168, 18.41448212],
              [-66.12142944, 18.41400337],
              [-66.12189484, 18.41305161],
              [-66.12420654, 18.40971375],
              [-66.12466431, 18.40685463],
              [-66.1251297, 18.40590286],
              [-66.12650299, 18.39589691],
              [-66.11860657, 18.37922668],
              [-66.11860657, 18.37875175],
              [-66.12230682, 18.37636757],
              [-66.13296509, 18.3815918],
              [-66.13666534, 18.3806324],
              [-66.13759613, 18.3792038],
              [-66.1375885, 18.37729454],
              [-66.13526917, 18.37253571],
              [-66.1412735, 18.36251831],
              [-66.14173126, 18.36156464],
              [-66.14173126, 18.35775185],
              [-66.13986969, 18.35585022],
              [-66.1361618, 18.35013771],
              [-66.13569641, 18.34679985],
              [-66.13522339, 18.34203529],
              [-66.13476563, 18.34108353],
              [-66.13336182, 18.3353653],
              [-66.13382721, 18.33488846],
              [-66.14168549, 18.32915688],
              [-66.14167786, 18.32439041],
              [-66.14121246, 18.32057762],
              [-66.13982391, 18.31819534],
              [-66.13240814, 18.31344223],
              [-66.13240814, 18.31296921],
              [-66.13287354, 18.31296539],
              [-66.14165497, 18.30913925],
              [-66.14118195, 18.30103874],
              [-66.13793945, 18.29818153],
              [-66.14392853, 18.27958107],
              [-66.15966034, 18.28289032],
              [-66.16150665, 18.28240776],
              [-66.16659546, 18.27954292],
              [-66.16751862, 18.2781105],
              [-66.17260742, 18.2785778],
              [-66.17676544, 18.27618408],
              [-66.17860413, 18.27236938],
              [-66.18183899, 18.26998138],
              [-66.18461609, 18.26806831],
              [-66.19060516, 18.25852394],
              [-66.19339752, 18.26757431],
              [-66.20034027, 18.27137375],
              [-66.19988251, 18.27423286],
              [-66.20081329, 18.27566147],
              [-66.20127869, 18.2761364],
              [-66.20220184, 18.27661133],
              [-66.20313263, 18.27660942],
              [-66.20636749, 18.27660179],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 11,
        name: 'Bayamón',
        hasc_1: 'PR.BY',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.21337128, 18.25852394, -66.11860657, 18.43108177],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.13',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.09525299, 18.16901779],
              [-66.10033417, 18.16996765],
              [-66.10264587, 18.16949081],
              [-66.10495758, 18.17139435],
              [-66.10173798, 18.18284225],
              [-66.10915375, 18.20095825],
              [-66.11794281, 18.21000671],
              [-66.10823822, 18.2143116],
              [-66.10315704, 18.21622658],
              [-66.10084534, 18.21861267],
              [-66.09899902, 18.22051811],
              [-66.09576416, 18.22433662],
              [-66.08514404, 18.23579407],
              [-66.08422089, 18.23770142],
              [-66.0772934, 18.24962616],
              [-66.07684326, 18.25915909],
              [-66.07499695, 18.26774216],
              [-66.07084656, 18.28300285],
              [-66.06761932, 18.29635239],
              [-66.06761932, 18.29826164],
              [-66.06623077, 18.3030262],
              [-66.0528183, 18.30303574],
              [-66.04911041, 18.29826927],
              [-66.04865265, 18.29779434],
              [-66.0472641, 18.29827118],
              [-66.04541016, 18.29922485],
              [-66.0472641, 18.30446815],
              [-66.04772949, 18.30685234],
              [-66.04727173, 18.30923653],
              [-66.04541779, 18.3116188],
              [-66.04264069, 18.31257057],
              [-66.04171753, 18.31257057],
              [-66.04125214, 18.31209755],
              [-66.03662872, 18.31019402],
              [-66.03430939, 18.30924225],
              [-66.03292847, 18.30924225],
              [-66.02829742, 18.31019974],
              [-66.02182007, 18.30495453],
              [-66.02367401, 18.30352211],
              [-66.02552032, 18.30352211],
              [-66.02968597, 18.30304527],
              [-66.03060913, 18.30113792],
              [-66.03060913, 18.29970741],
              [-66.03014374, 18.29065323],
              [-66.02922058, 18.28922272],
              [-66.02828979, 18.28827095],
              [-66.02597809, 18.2863636],
              [-66.01718903, 18.28112221],
              [-66.00793457, 18.27778625],
              [-66.00793457, 18.27730942],
              [-66.00886536, 18.27206612],
              [-66.00701141, 18.26682091],
              [-66.01441193, 18.26443672],
              [-66.01441193, 18.26395988],
              [-66.01394653, 18.26109886],
              [-66.01071167, 18.25061226],
              [-66.01071167, 18.25013733],
              [-66.0144043, 18.2472744],
              [-66.01486969, 18.24536705],
              [-66.01441193, 18.24441338],
              [-66.01255798, 18.24250603],
              [-66.00839233, 18.23773766],
              [-66.00839996, 18.23344994],
              [-66.01163483, 18.22963142],
              [-66.01348114, 18.22772598],
              [-66.0139389, 18.2267704],
              [-66.0139389, 18.22533798],
              [-66.01255035, 18.2229557],
              [-66.01070404, 18.22104836],
              [-66.00192261, 18.21914291],
              [-66.00053406, 18.21914101],
              [-65.9996109, 18.21818924],
              [-65.9996109, 18.2177124],
              [-65.99776459, 18.20722008],
              [-66.00006866, 18.19768143],
              [-65.9996109, 18.19625282],
              [-65.99822235, 18.19243813],
              [-65.99915314, 18.19100761],
              [-66.00099945, 18.18909836],
              [-66.00284576, 18.18671417],
              [-66.00376892, 18.1857605],
              [-66.00423431, 18.18576241],
              [-66.01300812, 18.18242073],
              [-66.01347351, 18.18146515],
              [-66.020401, 18.16858673],
              [-66.02224731, 18.16525269],
              [-66.02224731, 18.16334343],
              [-66.02317047, 18.15332603],
              [-66.020401, 18.1452179],
              [-66.020401, 18.14378738],
              [-66.02409363, 18.13663101],
              [-66.02409363, 18.13567543],
              [-66.02639771, 18.13376808],
              [-66.0328598, 18.12947273],
              [-66.03240204, 18.12708855],
              [-66.0328598, 18.12517929],
              [-66.03517151, 18.11945343],
              [-66.03839874, 18.11754417],
              [-66.04025269, 18.11611176],
              [-66.04255676, 18.11420059],
              [-66.05271149, 18.11371994],
              [-66.05502319, 18.11371994],
              [-66.05641174, 18.11419296],
              [-66.05825806, 18.11610222],
              [-66.06010437, 18.1184864],
              [-66.06103516, 18.12325478],
              [-66.06195831, 18.1246891],
              [-66.06380463, 18.12802505],
              [-66.06565857, 18.13136292],
              [-66.06889343, 18.13994408],
              [-66.06935883, 18.14232826],
              [-66.06889343, 18.14281082],
              [-66.06613159, 18.14615059],
              [-66.06613159, 18.14710236],
              [-66.0656662, 18.14805984],
              [-66.06705475, 18.14853668],
              [-66.07121277, 18.14996147],
              [-66.08045197, 18.15233994],
              [-66.08831787, 18.16044044],
              [-66.09525299, 18.16901779],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 13,
        name: 'Caguas',
        hasc_1: 'PR.CG',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.11794281, 18.11371994, -65.99776459, 18.31257057],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.14',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.82397461, 18.34321976],
              [-66.82861328, 18.3446064],
              [-66.84626007, 18.35111809],
              [-66.86297607, 18.35620499],
              [-66.8768692, 18.35702705],
              [-66.88062286, 18.36271095],
              [-66.88295746, 18.36507034],
              [-66.88435364, 18.36601257],
              [-66.88529205, 18.3669548],
              [-66.89316559, 18.36735725],
              [-66.89871216, 18.36635017],
              [-66.90070343, 18.38205719],
              [-66.90119934, 18.38538551],
              [-66.89898682, 18.39636612],
              [-66.90045166, 18.40492821],
              [-66.90047455, 18.40683556],
              [-66.90150452, 18.41778374],
              [-66.90119171, 18.43493652],
              [-66.90083313, 18.44589806],
              [-66.90142822, 18.45922852],
              [-66.90150452, 18.46780205],
              [-66.90164948, 18.4835186],
              [-66.90164185, 18.48374939],
              [-66.9015274, 18.48374939],
              [-66.9015274, 18.48347282],
              [-66.90097046, 18.48347282],
              [-66.90097046, 18.48374939],
              [-66.9006958, 18.48374939],
              [-66.9006958, 18.48402977],
              [-66.8998642, 18.48402977],
              [-66.8998642, 18.48430634],
              [-66.89875031, 18.48430634],
              [-66.89875031, 18.4845829],
              [-66.89847565, 18.4845829],
              [-66.89847565, 18.48485947],
              [-66.89763641, 18.48485947],
              [-66.89763641, 18.48513794],
              [-66.89541626, 18.48513794],
              [-66.89541626, 18.48485947],
              [-66.89430237, 18.48485947],
              [-66.89430237, 18.48513794],
              [-66.89375305, 18.48513794],
              [-66.89375305, 18.48541641],
              [-66.89180756, 18.48541641],
              [-66.89180756, 18.48569489],
              [-66.89069366, 18.48569489],
              [-66.89069366, 18.48596954],
              [-66.89041901, 18.48596954],
              [-66.89041901, 18.48625183],
              [-66.89013672, 18.48625183],
              [-66.89013672, 18.4865284],
              [-66.88986206, 18.4865284],
              [-66.88986206, 18.48680496],
              [-66.88957977, 18.48680496],
              [-66.88957977, 18.48708344],
              [-66.88930511, 18.48708344],
              [-66.88930511, 18.48736382],
              [-66.88847351, 18.48736382],
              [-66.88847351, 18.48763847],
              [-66.88569641, 18.48763847],
              [-66.88569641, 18.48736382],
              [-66.88458252, 18.48736382],
              [-66.88458252, 18.48763847],
              [-66.88430786, 18.48763847],
              [-66.88430786, 18.48791695],
              [-66.88402557, 18.48791695],
              [-66.88402557, 18.48819351],
              [-66.88319397, 18.48819351],
              [-66.88319397, 18.48847389],
              [-66.88236237, 18.48847389],
              [-66.88236237, 18.48875046],
              [-66.88014221, 18.48875046],
              [-66.88014221, 18.48902702],
              [-66.87708282, 18.48902702],
              [-66.87708282, 18.4893055],
              [-66.87541962, 18.4893055],
              [-66.87541962, 18.48958588],
              [-66.87486267, 18.48958588],
              [-66.87486267, 18.4893055],
              [-66.87403107, 18.4893055],
              [-66.87403107, 18.48902702],
              [-66.87347412, 18.48902702],
              [-66.87347412, 18.48875046],
              [-66.87208557, 18.48875046],
              [-66.87208557, 18.48902702],
              [-66.87069702, 18.48902702],
              [-66.87069702, 18.48958588],
              [-66.87041473, 18.48958588],
              [-66.87041473, 18.49013901],
              [-66.87014008, 18.49013901],
              [-66.87014008, 18.49041748],
              [-66.86930847, 18.49041748],
              [-66.86930847, 18.49013901],
              [-66.86791992, 18.49013901],
              [-66.86791992, 18.49041748],
              [-66.86736298, 18.49041748],
              [-66.86736298, 18.49069595],
              [-66.86708069, 18.49069595],
              [-66.86708069, 18.49180412],
              [-66.86680603, 18.49180412],
              [-66.86680603, 18.4920826],
              [-66.86653137, 18.4920826],
              [-66.86653137, 18.49236107],
              [-66.86597443, 18.49236107],
              [-66.86597443, 18.49152756],
              [-66.86569214, 18.49152756],
              [-66.86569214, 18.49124908],
              [-66.86541748, 18.49124908],
              [-66.86541748, 18.49097252],
              [-66.86486053, 18.49097252],
              [-66.86486053, 18.49069595],
              [-66.86319733, 18.49069595],
              [-66.86319733, 18.49041748],
              [-66.86180878, 18.49041748],
              [-66.86180878, 18.49069595],
              [-66.86096954, 18.49069595],
              [-66.86096954, 18.49097252],
              [-66.86042023, 18.49097252],
              [-66.86042023, 18.49069595],
              [-66.85986328, 18.49069595],
              [-66.85986328, 18.49041748],
              [-66.85736084, 18.49041748],
              [-66.85736084, 18.49069595],
              [-66.85652924, 18.49069595],
              [-66.85652924, 18.49097252],
              [-66.85597229, 18.49097252],
              [-66.85597229, 18.49069595],
              [-66.85541534, 18.49069595],
              [-66.85541534, 18.49041748],
              [-66.85236359, 18.49041748],
              [-66.85236359, 18.49069595],
              [-66.8520813, 18.49069595],
              [-66.8520813, 18.49097252],
              [-66.85124969, 18.49097252],
              [-66.85124969, 18.49069595],
              [-66.85097504, 18.49069595],
              [-66.85097504, 18.49041748],
              [-66.85069275, 18.49041748],
              [-66.85069275, 18.49013901],
              [-66.85041809, 18.49013901],
              [-66.85041809, 18.48986053],
              [-66.84902954, 18.48986053],
              [-66.84902954, 18.49013901],
              [-66.84764099, 18.49013901],
              [-66.84764099, 18.48986053],
              [-66.84680176, 18.48986053],
              [-66.84680176, 18.48958588],
              [-66.8465271, 18.48958588],
              [-66.8465271, 18.4893055],
              [-66.84625244, 18.4893055],
              [-66.84625244, 18.48875046],
              [-66.8456955, 18.48875046],
              [-66.8456955, 18.48847389],
              [-66.84430695, 18.48847389],
              [-66.84430695, 18.48875046],
              [-66.84402466, 18.48875046],
              [-66.84402466, 18.48902702],
              [-66.84375, 18.48902702],
              [-66.84375, 18.4893055],
              [-66.8429184, 18.4893055],
              [-66.8429184, 18.48847389],
              [-66.84263611, 18.48847389],
              [-66.84263611, 18.48819351],
              [-66.84069824, 18.48819351],
              [-66.84069824, 18.48791695],
              [-66.83930206, 18.48791695],
              [-66.83930206, 18.48763847],
              [-66.83791351, 18.48763847],
              [-66.83791351, 18.48791695],
              [-66.83708191, 18.48791695],
              [-66.83708191, 18.48763847],
              [-66.83680725, 18.48763847],
              [-66.83680725, 18.48742294],
              [-66.83860779, 18.48411369],
              [-66.83860016, 18.48363686],
              [-66.83764648, 18.48031044],
              [-66.83480835, 18.47366905],
              [-66.82633209, 18.45755196],
              [-66.82632446, 18.45659828],
              [-66.82531738, 18.44755554],
              [-66.82569885, 18.43802452],
              [-66.82363129, 18.41231728],
              [-66.82125854, 18.40566635],
              [-66.81793213, 18.39569283],
              [-66.81652069, 18.39236832],
              [-66.81045532, 18.38718033],
              [-66.81581879, 18.36425972],
              [-66.81896973, 18.35327148],
              [-66.82397461, 18.34321976],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 14,
        name: 'Camuy',
        hasc_1: 'PR.CA',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.90164948, 18.34321976, -66.81045532, 18.49236107],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.12',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-67.2140274, 17.95125008],
              [-67.21485901, 17.95125008],
              [-67.21485901, 17.95152855],
              [-67.2151413, 17.95152855],
              [-67.2151413, 17.95263863],
              [-67.21485901, 17.95263863],
              [-67.21485901, 17.9529171],
              [-67.21458435, 17.9529171],
              [-67.21458435, 17.95319366],
              [-67.2140274, 17.95319366],
              [-67.2140274, 17.95347214],
              [-67.2131958, 17.95347214],
              [-67.2131958, 17.95375061],
              [-67.21291351, 17.95375061],
              [-67.21291351, 17.95402718],
              [-67.2123642, 17.95402718],
              [-67.2123642, 17.95513916],
              [-67.21208191, 17.95513916],
              [-67.21208191, 17.95652771],
              [-67.2123642, 17.95652771],
              [-67.2123642, 17.95819473],
              [-67.21263885, 17.95819473],
              [-67.21263885, 17.95874977],
              [-67.21291351, 17.95874977],
              [-67.21291351, 17.95902634],
              [-67.2131958, 17.95902634],
              [-67.2131958, 17.95986176],
              [-67.21347046, 17.95986176],
              [-67.21347046, 17.96013641],
              [-67.21375275, 17.96013641],
              [-67.21375275, 17.96125031],
              [-67.2140274, 17.96125031],
              [-67.2140274, 17.96208382],
              [-67.21430206, 17.96208382],
              [-67.21430206, 17.96319389],
              [-67.21458435, 17.96319389],
              [-67.21458435, 17.96430588],
              [-67.21430206, 17.96430588],
              [-67.21430206, 17.96486282],
              [-67.2140274, 17.96486282],
              [-67.2140274, 17.9659729],
              [-67.21375275, 17.9659729],
              [-67.21375275, 17.96680641],
              [-67.21347046, 17.96680641],
              [-67.21347046, 17.96736145],
              [-67.2131958, 17.96736145],
              [-67.2131958, 17.96819305],
              [-67.21291351, 17.96819305],
              [-67.21291351, 17.96930313],
              [-67.21263885, 17.96930313],
              [-67.21263885, 17.96986198],
              [-67.2123642, 17.96986198],
              [-67.2123642, 17.97125053],
              [-67.21263885, 17.97125053],
              [-67.21263885, 17.97208595],
              [-67.21291351, 17.97208595],
              [-67.21291351, 17.9734726],
              [-67.2131958, 17.9734726],
              [-67.2131958, 17.97541618],
              [-67.21291351, 17.97541618],
              [-67.21291351, 17.97708321],
              [-67.2131958, 17.97708321],
              [-67.2131958, 17.97791672],
              [-67.21347046, 17.97791672],
              [-67.21347046, 17.97847176],
              [-67.21375275, 17.97847176],
              [-67.21375275, 17.97930527],
              [-67.2140274, 17.97930527],
              [-67.2140274, 17.97986031],
              [-67.21430206, 17.97986031],
              [-67.21430206, 17.98097229],
              [-67.21458435, 17.98097229],
              [-67.21458435, 17.9818058],
              [-67.21485901, 17.9818058],
              [-67.21485901, 17.98208427],
              [-67.2151413, 17.98208427],
              [-67.2151413, 17.98291588],
              [-67.21541595, 17.98291588],
              [-67.21541595, 17.98597145],
              [-67.2151413, 17.98597145],
              [-67.2151413, 17.98652649],
              [-67.21485901, 17.98652649],
              [-67.21485901, 17.98763657],
              [-67.21458435, 17.98763657],
              [-67.21458435, 17.98791885],
              [-67.21430206, 17.98791885],
              [-67.21430206, 17.98902893],
              [-67.2140274, 17.98902893],
              [-67.2140274, 17.9893055],
              [-67.21375275, 17.9893055],
              [-67.21375275, 17.98958397],
              [-67.21347046, 17.98958397],
              [-67.21347046, 17.98986053],
              [-67.2131958, 17.98986053],
              [-67.2131958, 17.99014091],
              [-67.21291351, 17.99014091],
              [-67.21291351, 17.99124908],
              [-67.2131958, 17.99124908],
              [-67.2131958, 17.9920826],
              [-67.21347046, 17.9920826],
              [-67.21347046, 17.99291611],
              [-67.21291351, 17.99291611],
              [-67.21291351, 17.99319458],
              [-67.2123642, 17.99319458],
              [-67.2123642, 17.99347115],
              [-67.21180725, 17.99347115],
              [-67.21180725, 17.99402809],
              [-67.21152496, 17.99402809],
              [-67.21152496, 17.99458313],
              [-67.21125031, 17.99458313],
              [-67.21125031, 17.9948616],
              [-67.21097565, 17.9948616],
              [-67.21097565, 17.99513817],
              [-67.2104187, 17.99513817],
              [-67.2104187, 17.99541664],
              [-67.21013641, 17.99541664],
              [-67.21013641, 17.99597168],
              [-67.20986176, 17.99597168],
              [-67.20986176, 17.99625015],
              [-67.2095871, 17.99625015],
              [-67.2095871, 17.99680519],
              [-67.20903015, 17.99680519],
              [-67.20903015, 17.99736023],
              [-67.20847321, 17.99736023],
              [-67.20847321, 17.9976387],
              [-67.20819092, 17.9976387],
              [-67.20819092, 17.99791527],
              [-67.20791626, 17.99791527],
              [-67.20791626, 17.99819374],
              [-67.2076416, 17.99819374],
              [-67.2076416, 17.99847221],
              [-67.20708466, 17.99847221],
              [-67.20708466, 17.99875069],
              [-67.20680237, 17.99875069],
              [-67.20680237, 17.99902534],
              [-67.20597076, 17.99902534],
              [-67.20597076, 17.99930763],
              [-67.20569611, 17.99930763],
              [-67.20569611, 17.9995842],
              [-67.20513916, 17.9995842],
              [-67.20513916, 17.99986076],
              [-67.20236206, 17.99986076],
              [-67.20236206, 18.00013733],
              [-67.20236206, 18.0004158],
              [-67.20180511, 18.0004158],
              [-67.20180511, 18.00069427],
              [-67.20153046, 18.00069427],
              [-67.20153046, 18.00097275],
              [-67.20097351, 18.00097275],
              [-67.20097351, 18.00124741],
              [-67.20069122, 18.00124741],
              [-67.20069122, 18.00152969],
              [-67.20014191, 18.00152969],
              [-67.20014191, 18.00180626],
              [-67.19958496, 18.00180626],
              [-67.19958496, 18.00208282],
              [-67.19902802, 18.00208282],
              [-67.19902802, 18.0023613],
              [-67.19763947, 18.0023613],
              [-67.19763947, 18.00264168],
              [-67.19569397, 18.00264168],
              [-67.19569397, 18.00291634],
              [-67.19430542, 18.00291634],
              [-67.19430542, 18.00319481],
              [-67.19403076, 18.00319481],
              [-67.19403076, 18.00347137],
              [-67.19374847, 18.00347137],
              [-67.19374847, 18.00375175],
              [-67.19319153, 18.00375175],
              [-67.19319153, 18.00402832],
              [-67.19264221, 18.00402832],
              [-67.19264221, 18.00430489],
              [-67.19208527, 18.00430489],
              [-67.19208527, 18.00458336],
              [-67.19125366, 18.00458336],
              [-67.19125366, 18.00486183],
              [-67.19069672, 18.00486183],
              [-67.19069672, 18.0051384],
              [-67.19013977, 18.0051384],
              [-67.19013977, 18.00541687],
              [-67.18958282, 18.00541687],
              [-67.18958282, 18.00569534],
              [-67.18930817, 18.00569534],
              [-67.18930817, 18.00597382],
              [-67.18875122, 18.00597382],
              [-67.18875122, 18.00625038],
              [-67.18791962, 18.00625038],
              [-67.18791962, 18.00652695],
              [-67.18736267, 18.00652695],
              [-67.18736267, 18.00680542],
              [-67.18680573, 18.00680542],
              [-67.18680573, 18.00708389],
              [-67.18597412, 18.00708389],
              [-67.18597412, 18.00736046],
              [-67.18541718, 18.00736046],
              [-67.18541718, 18.00763893],
              [-67.18430328, 18.00763893],
              [-67.18430328, 18.0079174],
              [-67.18374634, 18.0079174],
              [-67.18374634, 18.00819206],
              [-67.18291473, 18.00819206],
              [-67.18291473, 18.00847244],
              [-67.18208313, 18.00847244],
              [-67.18208313, 18.00875092],
              [-67.18125153, 18.00875092],
              [-67.18125153, 18.00902748],
              [-67.17903137, 18.00902748],
              [-67.17903137, 18.00875092],
              [-67.17791748, 18.00875092],
              [-67.17791748, 18.00847244],
              [-67.17736053, 18.00847244],
              [-67.17736053, 18.00875092],
              [-67.17680359, 18.00875092],
              [-67.17680359, 18.00902748],
              [-67.17652893, 18.00902748],
              [-67.17652893, 18.00930405],
              [-67.17624664, 18.00930405],
              [-67.17624664, 18.00958252],
              [-67.17597198, 18.00958252],
              [-67.17597198, 18.00986099],
              [-67.17569733, 18.00986099],
              [-67.17569733, 18.01013947],
              [-67.17541504, 18.01013947],
              [-67.17541504, 18.01041412],
              [-67.17514038, 18.01041412],
              [-67.17514038, 18.0106945],
              [-67.17485809, 18.0106945],
              [-67.17485809, 18.01097298],
              [-67.17458344, 18.01097298],
              [-67.17458344, 18.01124954],
              [-67.17430878, 18.01124954],
              [-67.17430878, 18.01152611],
              [-67.17402649, 18.01152611],
              [-67.17402649, 18.01208305],
              [-67.17375183, 18.01208305],
              [-67.17375183, 18.01236153],
              [-67.17346954, 18.01236153],
              [-67.17346954, 18.01374817],
              [-67.17319489, 18.01374817],
              [-67.17319489, 18.01514053],
              [-67.17292023, 18.01514053],
              [-67.17292023, 18.01625061],
              [-67.17263794, 18.01625061],
              [-67.17263794, 18.01736069],
              [-67.17236328, 18.01736069],
              [-67.17236328, 18.01874924],
              [-67.17208099, 18.01874924],
              [-67.17208099, 18.02180481],
              [-67.17236328, 18.02180481],
              [-67.17236328, 18.02263832],
              [-67.17263794, 18.02263832],
              [-67.17263794, 18.02291679],
              [-67.17292023, 18.02291679],
              [-67.17292023, 18.02319336],
              [-67.17319489, 18.02319336],
              [-67.17319489, 18.02347183],
              [-67.17346954, 18.02347183],
              [-67.17346954, 18.02375031],
              [-67.17375183, 18.02375031],
              [-67.17375183, 18.02402687],
              [-67.17402649, 18.02402687],
              [-67.17402649, 18.02430534],
              [-67.17430878, 18.02430534],
              [-67.17430878, 18.02458382],
              [-67.17458344, 18.02458382],
              [-67.17458344, 18.02486038],
              [-67.17514038, 18.02486038],
              [-67.17514038, 18.02513885],
              [-67.17541504, 18.02513885],
              [-67.17541504, 18.02541542],
              [-67.17597198, 18.02541542],
              [-67.17597198, 18.02569389],
              [-67.17680359, 18.02569389],
              [-67.17680359, 18.02708244],
              [-67.17763519, 18.02708244],
              [-67.17763519, 18.02652931],
              [-67.17791748, 18.02652931],
              [-67.17791748, 18.02625084],
              [-67.17819214, 18.02625084],
              [-67.17819214, 18.02597237],
              [-67.18013763, 18.02597237],
              [-67.18013763, 18.02625084],
              [-67.18041992, 18.02625084],
              [-67.18041992, 18.02736092],
              [-67.18013763, 18.02736092],
              [-67.18013763, 18.0284729],
              [-67.17986298, 18.0284729],
              [-67.17986298, 18.02902985],
              [-67.18013763, 18.02902985],
              [-67.18013763, 18.02930641],
              [-67.18096924, 18.02930641],
              [-67.18096924, 18.02958298],
              [-67.18152618, 18.02958298],
              [-67.18152618, 18.02819443],
              [-67.18180847, 18.02819443],
              [-67.18180847, 18.02791786],
              [-67.18208313, 18.02791786],
              [-67.18208313, 18.02763748],
              [-67.18235779, 18.02763748],
              [-67.18235779, 18.02708244],
              [-67.18430328, 18.02708244],
              [-67.18430328, 18.02763748],
              [-67.18514252, 18.02763748],
              [-67.18514252, 18.02791786],
              [-67.18541718, 18.02791786],
              [-67.18541718, 18.02763748],
              [-67.18569183, 18.02763748],
              [-67.18569183, 18.02819443],
              [-67.18597412, 18.02819443],
              [-67.18597412, 18.02874756],
              [-67.18569183, 18.02874756],
              [-67.18569183, 18.03013992],
              [-67.18624878, 18.03013992],
              [-67.18624878, 18.03041649],
              [-67.18680573, 18.03041649],
              [-67.18680573, 18.03013992],
              [-67.18708038, 18.03013992],
              [-67.18708038, 18.02986145],
              [-67.18736267, 18.02986145],
              [-67.18736267, 18.02958298],
              [-67.18846893, 18.02958298],
              [-67.18846893, 18.02986145],
              [-67.18902588, 18.02986145],
              [-67.18902588, 18.03013992],
              [-67.18958282, 18.03013992],
              [-67.18958282, 18.03041649],
              [-67.19125366, 18.03041649],
              [-67.19125366, 18.03069496],
              [-67.19180298, 18.03069496],
              [-67.19180298, 18.03097153],
              [-67.19264221, 18.03097153],
              [-67.19264221, 18.03125191],
              [-67.19319153, 18.03125191],
              [-67.19319153, 18.03152847],
              [-67.19374847, 18.03152847],
              [-67.19374847, 18.03180504],
              [-67.19596863, 18.03180504],
              [-67.19596863, 18.03236389],
              [-67.19652557, 18.03236389],
              [-67.19652557, 18.03208351],
              [-67.19680786, 18.03208351],
              [-67.19680786, 18.03236389],
              [-67.19763947, 18.03236389],
              [-67.19763947, 18.03208351],
              [-67.19819641, 18.03208351],
              [-67.19819641, 18.03236389],
              [-67.19875336, 18.03236389],
              [-67.19875336, 18.03263855],
              [-67.19930267, 18.03263855],
              [-67.19930267, 18.03291702],
              [-67.20041656, 18.03291702],
              [-67.20041656, 18.03319359],
              [-67.20069122, 18.03319359],
              [-67.20069122, 18.03291702],
              [-67.20124817, 18.03291702],
              [-67.20124817, 18.03319359],
              [-67.20153046, 18.03319359],
              [-67.20153046, 18.03347206],
              [-67.20207977, 18.03347206],
              [-67.20207977, 18.03375053],
              [-67.20236206, 18.03375053],
              [-67.20236206, 18.0340271],
              [-67.20263672, 18.0340271],
              [-67.20263672, 18.03430557],
              [-67.20291901, 18.03430557],
              [-67.20291901, 18.03458214],
              [-67.20347595, 18.03458214],
              [-67.20347595, 18.03486061],
              [-67.20375061, 18.03486061],
              [-67.20375061, 18.0359726],
              [-67.20597076, 18.0359726],
              [-67.20597076, 18.03569412],
              [-67.20708466, 18.03569412],
              [-67.20708466, 18.03541756],
              [-67.2076416, 18.03541756],
              [-67.2076416, 18.03513908],
              [-67.20986176, 18.03513908],
              [-67.20986176, 18.0368042],
              [-67.2095871, 18.0368042],
              [-67.2095871, 18.03708267],
              [-67.20930481, 18.03708267],
              [-67.20930481, 18.03763962],
              [-67.20903015, 18.03763962],
              [-67.20903015, 18.03791428],
              [-67.20874786, 18.03791428],
              [-67.20874786, 18.03819466],
              [-67.20847321, 18.03819466],
              [-67.20847321, 18.03847313],
              [-67.20819092, 18.03847313],
              [-67.20819092, 18.03874969],
              [-67.20791626, 18.03874969],
              [-67.20791626, 18.03902626],
              [-67.2076416, 18.03902626],
              [-67.2076416, 18.03930473],
              [-67.20735931, 18.03930473],
              [-67.20735931, 18.03986168],
              [-67.20708466, 18.03986168],
              [-67.20708466, 18.04013634],
              [-67.20680237, 18.04013634],
              [-67.20680237, 18.04041862],
              [-67.20625305, 18.04041862],
              [-67.20625305, 18.04069519],
              [-67.20569611, 18.04069519],
              [-67.20569611, 18.04124832],
              [-67.20513916, 18.04124832],
              [-67.20513916, 18.04153061],
              [-67.2048645, 18.04153061],
              [-67.2048645, 18.04180527],
              [-67.20458221, 18.04180527],
              [-67.20458221, 18.04208374],
              [-67.20430756, 18.04208374],
              [-67.20430756, 18.0423584],
              [-67.20402527, 18.0423584],
              [-67.20402527, 18.04291725],
              [-67.20375061, 18.04291725],
              [-67.20375061, 18.04319382],
              [-67.20347595, 18.04319382],
              [-67.20347595, 18.04347229],
              [-67.20319366, 18.04347229],
              [-67.20319366, 18.04375076],
              [-67.20291901, 18.04375076],
              [-67.20291901, 18.04402733],
              [-67.20263672, 18.04402733],
              [-67.20263672, 18.0443058],
              [-67.20236206, 18.0443058],
              [-67.20236206, 18.04458427],
              [-67.20180511, 18.04458427],
              [-67.20180511, 18.04513931],
              [-67.20153046, 18.04513931],
              [-67.20153046, 18.04541588],
              [-67.20124817, 18.04541588],
              [-67.20124817, 18.04569435],
              [-67.20097351, 18.04569435],
              [-67.20097351, 18.04597282],
              [-67.20069122, 18.04597282],
              [-67.20069122, 18.04624939],
              [-67.20041656, 18.04624939],
              [-67.20041656, 18.04652786],
              [-67.20014191, 18.04652786],
              [-67.20014191, 18.04680634],
              [-67.19958496, 18.04680634],
              [-67.19958496, 18.04763794],
              [-67.19930267, 18.04763794],
              [-67.19930267, 18.04874992],
              [-67.19902802, 18.04874992],
              [-67.19902802, 18.04986191],
              [-67.19930267, 18.04986191],
              [-67.19930267, 18.05069351],
              [-67.19958496, 18.05069351],
              [-67.19958496, 18.05125046],
              [-67.19985962, 18.05125046],
              [-67.19985962, 18.05208397],
              [-67.20041656, 18.05208397],
              [-67.20041656, 18.05236053],
              [-67.20069122, 18.05236053],
              [-67.20069122, 18.05319405],
              [-67.20097351, 18.05319405],
              [-67.20097351, 18.05347252],
              [-67.20124817, 18.05347252],
              [-67.20124817, 18.05402946],
              [-67.20153046, 18.05402946],
              [-67.20153046, 18.05569458],
              [-67.20180511, 18.05569458],
              [-67.20180511, 18.0573616],
              [-67.20153046, 18.0573616],
              [-67.20153046, 18.05847168],
              [-67.20124817, 18.05847168],
              [-67.20124817, 18.05875015],
              [-67.20097351, 18.05875015],
              [-67.20097351, 18.05902863],
              [-67.20069122, 18.05902863],
              [-67.20069122, 18.05958366],
              [-67.20041656, 18.05958366],
              [-67.20041656, 18.05986023],
              [-67.19985962, 18.05986023],
              [-67.19985962, 18.0601387],
              [-67.19958496, 18.0601387],
              [-67.19958496, 18.06069374],
              [-67.19930267, 18.06069374],
              [-67.19930267, 18.06097221],
              [-67.19902802, 18.06097221],
              [-67.19902802, 18.06152725],
              [-67.19875336, 18.06152725],
              [-67.19875336, 18.06180573],
              [-67.19847107, 18.06180573],
              [-67.19847107, 18.0620842],
              [-67.19819641, 18.0620842],
              [-67.19819641, 18.06236076],
              [-67.19791412, 18.06236076],
              [-67.19791412, 18.06347275],
              [-67.19763947, 18.06347275],
              [-67.19763947, 18.06458282],
              [-67.19736481, 18.06458282],
              [-67.19736481, 18.0648613],
              [-67.19708252, 18.0648613],
              [-67.19708252, 18.06569481],
              [-67.19680786, 18.06569481],
              [-67.19680786, 18.06652641],
              [-67.19596863, 18.06652641],
              [-67.19596863, 18.06624985],
              [-67.19541931, 18.06624985],
              [-67.19541931, 18.06597137],
              [-67.19486237, 18.06597137],
              [-67.19486237, 18.06569481],
              [-67.19403076, 18.06569481],
              [-67.19403076, 18.06541634],
              [-67.19291687, 18.06541634],
              [-67.19291687, 18.06513977],
              [-67.19235992, 18.06513977],
              [-67.19235992, 18.0648613],
              [-67.19041443, 18.0648613],
              [-67.19041443, 18.06513977],
              [-67.18985748, 18.06513977],
              [-67.18985748, 18.06541634],
              [-67.18958282, 18.06541634],
              [-67.18958282, 18.06569481],
              [-67.18875122, 18.06569481],
              [-67.18875122, 18.06597137],
              [-67.18819427, 18.06597137],
              [-67.18819427, 18.06624985],
              [-67.18791962, 18.06624985],
              [-67.18791962, 18.06652641],
              [-67.18736267, 18.06652641],
              [-67.18736267, 18.06708336],
              [-67.18680573, 18.06708336],
              [-67.18680573, 18.06736183],
              [-67.18624878, 18.06736183],
              [-67.18624878, 18.06763649],
              [-67.18541718, 18.06763649],
              [-67.18541718, 18.06819534],
              [-67.18486023, 18.06819534],
              [-67.18486023, 18.06930542],
              [-67.18458557, 18.06930542],
              [-67.18458557, 18.07097244],
              [-67.18486023, 18.07097244],
              [-67.18486023, 18.07152748],
              [-67.18514252, 18.07152748],
              [-67.18514252, 18.07208252],
              [-67.18541718, 18.07208252],
              [-67.18541718, 18.0723629],
              [-67.18569183, 18.0723629],
              [-67.18569183, 18.07263947],
              [-67.18597412, 18.07263947],
              [-67.18597412, 18.0731945],
              [-67.18708038, 18.0731945],
              [-67.18708038, 18.07347107],
              [-67.18819427, 18.07347107],
              [-67.18819427, 18.0731945],
              [-67.18875122, 18.0731945],
              [-67.18875122, 18.07347107],
              [-67.18930817, 18.07347107],
              [-67.18930817, 18.07402802],
              [-67.18958282, 18.07402802],
              [-67.18958282, 18.07430649],
              [-67.18985748, 18.07430649],
              [-67.18985748, 18.07486153],
              [-67.19013977, 18.07486153],
              [-67.19013977, 18.07569313],
              [-67.19069672, 18.07569313],
              [-67.19069672, 18.07625008],
              [-67.19180298, 18.07625008],
              [-67.19180298, 18.07652855],
              [-67.19235992, 18.07652855],
              [-67.19235992, 18.0759716],
              [-67.19264221, 18.0759716],
              [-67.19264221, 18.07513809],
              [-67.19291687, 18.07513809],
              [-67.19291687, 18.07402802],
              [-67.19319153, 18.07402802],
              [-67.19319153, 18.07374954],
              [-67.19347382, 18.07374954],
              [-67.19347382, 18.07347107],
              [-67.19374847, 18.07347107],
              [-67.19374847, 18.0731945],
              [-67.19430542, 18.0731945],
              [-67.19430542, 18.07291603],
              [-67.19486237, 18.07291603],
              [-67.19486237, 18.07263947],
              [-67.19513702, 18.07263947],
              [-67.19513702, 18.07208252],
              [-67.19541931, 18.07208252],
              [-67.19541931, 18.07180595],
              [-67.19569397, 18.07180595],
              [-67.19569397, 18.07125282],
              [-67.19596863, 18.07125282],
              [-67.19596863, 18.0704174],
              [-67.19680786, 18.0704174],
              [-67.19680786, 18.07069397],
              [-67.19736481, 18.07069397],
              [-67.19736481, 18.07208252],
              [-67.19708252, 18.07208252],
              [-67.19708252, 18.07263947],
              [-67.19680786, 18.07263947],
              [-67.19680786, 18.07291603],
              [-67.19625092, 18.07291603],
              [-67.19625092, 18.07347107],
              [-67.19652557, 18.07347107],
              [-67.19652557, 18.07374954],
              [-67.19708252, 18.07374954],
              [-67.19708252, 18.07347107],
              [-67.19819641, 18.07347107],
              [-67.19819641, 18.07374954],
              [-67.19902802, 18.07374954],
              [-67.19902802, 18.07430649],
              [-67.19958496, 18.07430649],
              [-67.19958496, 18.07486153],
              [-67.19985962, 18.07486153],
              [-67.19985962, 18.0759716],
              [-67.19958496, 18.0759716],
              [-67.19958496, 18.07625008],
              [-67.19791412, 18.07625008],
              [-67.19791412, 18.07652855],
              [-67.19819641, 18.07652855],
              [-67.19819641, 18.07708359],
              [-67.19847107, 18.07708359],
              [-67.19847107, 18.07736206],
              [-67.19902802, 18.07736206],
              [-67.19902802, 18.07791519],
              [-67.19930267, 18.07791519],
              [-67.19930267, 18.07819366],
              [-67.19958496, 18.07819366],
              [-67.19958496, 18.07902527],
              [-67.19985962, 18.07902527],
              [-67.19985962, 18.07930565],
              [-67.20097351, 18.07930565],
              [-67.20097351, 18.07986069],
              [-67.20124817, 18.07986069],
              [-67.20124817, 18.08013725],
              [-67.20153046, 18.08013725],
              [-67.20153046, 18.0806942],
              [-67.20180511, 18.0806942],
              [-67.20180511, 18.08124733],
              [-67.20207977, 18.08124733],
              [-67.20207977, 18.08152962],
              [-67.20180511, 18.08152962],
              [-67.20180511, 18.08208275],
              [-67.20153046, 18.08208275],
              [-67.20153046, 18.08291626],
              [-67.20069122, 18.08291626],
              [-67.20069122, 18.08319473],
              [-67.19958496, 18.08319473],
              [-67.19958496, 18.08346939],
              [-67.19930267, 18.08346939],
              [-67.19930267, 18.08375168],
              [-67.19902802, 18.08375168],
              [-67.19902802, 18.08458328],
              [-67.19930267, 18.08458328],
              [-67.19930267, 18.08486176],
              [-67.19985962, 18.08486176],
              [-67.19985962, 18.08708382],
              [-67.19958496, 18.08708382],
              [-67.19958496, 18.08736038],
              [-67.19847107, 18.08736038],
              [-67.19847107, 18.08708382],
              [-67.19708252, 18.08708382],
              [-67.19708252, 18.08736038],
              [-67.19680786, 18.08736038],
              [-67.19680786, 18.0890274],
              [-67.19736481, 18.0890274],
              [-67.19736481, 18.08930588],
              [-67.19819641, 18.08930588],
              [-67.19819641, 18.08958244],
              [-67.19875336, 18.08958244],
              [-67.19875336, 18.08986092],
              [-67.19930267, 18.08986092],
              [-67.19930267, 18.09124947],
              [-67.19902802, 18.09124947],
              [-67.19902802, 18.09152794],
              [-67.19875336, 18.09152794],
              [-67.19875336, 18.09208298],
              [-67.19847107, 18.09208298],
              [-67.19847107, 18.09236145],
              [-67.19819641, 18.09236145],
              [-67.19819641, 18.09263611],
              [-67.19763947, 18.09263611],
              [-67.19763947, 18.09291649],
              [-67.19736481, 18.09291649],
              [-67.19736481, 18.09319687],
              [-67.19680786, 18.09319687],
              [-67.19680786, 18.09374809],
              [-67.19596863, 18.09374809],
              [-67.19596863, 18.09430504],
              [-67.19569397, 18.09430504],
              [-67.19569397, 18.09486198],
              [-67.19541931, 18.09486198],
              [-67.19541931, 18.09625053],
              [-67.19513702, 18.09625053],
              [-67.19513702, 18.09708405],
              [-67.19486237, 18.09708405],
              [-67.19486237, 18.0973587],
              [-67.19430542, 18.0973587],
              [-67.19430542, 18.09791756],
              [-67.19291687, 18.09791756],
              [-67.19291687, 18.09819412],
              [-67.19235992, 18.09819412],
              [-67.19235992, 18.0984726],
              [-67.19180298, 18.0984726],
              [-67.19180298, 18.09874916],
              [-67.19125366, 18.09874916],
              [-67.19125366, 18.09930611],
              [-67.19069672, 18.09930611],
              [-67.19069672, 18.09986115],
              [-67.19097137, 18.09986115],
              [-67.19097137, 18.10013962],
              [-67.19069672, 18.10013962],
              [-67.19069672, 18.10041618],
              [-67.18985748, 18.10041618],
              [-67.18985748, 18.10069466],
              [-67.18930817, 18.10069466],
              [-67.18930817, 18.10097313],
              [-67.18902588, 18.10097313],
              [-67.18902588, 18.10152817],
              [-67.18708038, 18.10152817],
              [-67.18708038, 18.10180473],
              [-67.18653107, 18.10180473],
              [-67.18653107, 18.10208321],
              [-67.18624878, 18.10208321],
              [-67.18624878, 18.10236168],
              [-67.18569183, 18.10236168],
              [-67.18569183, 18.10263824],
              [-67.18486023, 18.10263824],
              [-67.18486023, 18.10319328],
              [-67.18458557, 18.10319328],
              [-67.18458557, 18.10375023],
              [-67.18430328, 18.10375023],
              [-67.18430328, 18.10430527],
              [-67.18402863, 18.10430527],
              [-67.18402863, 18.10486031],
              [-67.18374634, 18.10486031],
              [-67.18374634, 18.10513878],
              [-67.18347168, 18.10513878],
              [-67.18347168, 18.10625076],
              [-67.18319702, 18.10625076],
              [-67.18319702, 18.10736084],
              [-67.18291473, 18.10736084],
              [-67.18291473, 18.10819435],
              [-67.18264008, 18.10819435],
              [-67.18264008, 18.10847282],
              [-67.18235779, 18.10847282],
              [-67.18235779, 18.11013985],
              [-67.18208313, 18.11013985],
              [-67.18208313, 18.11069489],
              [-67.18180847, 18.11069489],
              [-67.18180847, 18.11263847],
              [-67.18208313, 18.11263847],
              [-67.18208313, 18.11291695],
              [-67.18235779, 18.11291695],
              [-67.18235779, 18.11347389],
              [-67.18264008, 18.11347389],
              [-67.18264008, 18.11375046],
              [-67.18291473, 18.11375046],
              [-67.18291473, 18.11458206],
              [-67.18319702, 18.11458206],
              [-67.18319702, 18.11513901],
              [-67.18347168, 18.11513901],
              [-67.18347168, 18.11569595],
              [-67.18374634, 18.11569595],
              [-67.18374634, 18.11680412],
              [-67.18402863, 18.11680412],
              [-67.18402863, 18.11763954],
              [-67.18430328, 18.11763954],
              [-67.18430328, 18.11819458],
              [-67.18458557, 18.11819458],
              [-67.18458557, 18.11930466],
              [-67.18486023, 18.11930466],
              [-67.18486023, 18.12069511],
              [-67.18430328, 18.12069511],
              [-67.18430328, 18.12180519],
              [-67.18402863, 18.12180519],
              [-67.18402863, 18.12235832],
              [-67.18374634, 18.12235832],
              [-67.18374634, 18.12319374],
              [-67.18347168, 18.12319374],
              [-67.18347168, 18.12430573],
              [-67.18319702, 18.12430573],
              [-67.18319702, 18.1245842],
              [-67.18291473, 18.1245842],
              [-67.18291473, 18.12652779],
              [-67.18319702, 18.12652779],
              [-67.18319702, 18.1273613],
              [-67.18347168, 18.1273613],
              [-67.18347168, 18.12791634],
              [-67.18374634, 18.12791634],
              [-67.18374634, 18.12819481],
              [-67.18402863, 18.12819481],
              [-67.18402863, 18.12874985],
              [-67.18430328, 18.12874985],
              [-67.18430328, 18.12930489],
              [-67.18514252, 18.12930489],
              [-67.18514252, 18.12986183],
              [-67.18541718, 18.12986183],
              [-67.18541718, 18.13041687],
              [-67.18569183, 18.13041687],
              [-67.18569183, 18.13069534],
              [-67.18597412, 18.13069534],
              [-67.18597412, 18.13152695],
              [-67.18624878, 18.13152695],
              [-67.18624878, 18.13180542],
              [-67.18653107, 18.13180542],
              [-67.18653107, 18.13208389],
              [-67.18680573, 18.13208389],
              [-67.18680573, 18.13513756],
              [-67.18653107, 18.13513756],
              [-67.18653107, 18.13597298],
              [-67.18624878, 18.13597298],
              [-67.18624878, 18.13680649],
              [-67.18597412, 18.13680649],
              [-67.18597412, 18.13708305],
              [-67.18514252, 18.13708305],
              [-67.18514252, 18.13736153],
              [-67.18374634, 18.13736153],
              [-67.18374634, 18.13763809],
              [-67.18319702, 18.13763809],
              [-67.18319702, 18.13791656],
              [-67.18291473, 18.13791656],
              [-67.18291473, 18.13819504],
              [-67.18235779, 18.13819504],
              [-67.18235779, 18.1384716],
              [-67.18180847, 18.1384716],
              [-67.18180847, 18.13875008],
              [-67.18152618, 18.13875008],
              [-67.18152618, 18.13902855],
              [-67.18125153, 18.13902855],
              [-67.18125153, 18.13930511],
              [-67.18096924, 18.13930511],
              [-67.18096924, 18.13958359],
              [-67.18069458, 18.13958359],
              [-67.18069458, 18.13986206],
              [-67.18041992, 18.13986206],
              [-67.18041992, 18.14013863],
              [-67.18013763, 18.14013863],
              [-67.18013763, 18.14069366],
              [-67.17986298, 18.14069366],
              [-67.17986298, 18.14097214],
              [-67.17958069, 18.14097214],
              [-67.17958069, 18.14152718],
              [-67.17930603, 18.14152718],
              [-67.17930603, 18.14180565],
              [-67.17958069, 18.14180565],
              [-67.17958069, 18.14208412],
              [-67.17930603, 18.14208412],
              [-67.17930603, 18.14263916],
              [-67.17903137, 18.14263916],
              [-67.17903137, 18.14347267],
              [-67.17874908, 18.14347267],
              [-67.17874908, 18.14402771],
              [-67.17847443, 18.14402771],
              [-67.17847443, 18.14513969],
              [-67.17819214, 18.14513969],
              [-67.17819214, 18.14569473],
              [-67.17847443, 18.14569473],
              [-67.17847443, 18.14624977],
              [-67.17791748, 18.14624977],
              [-67.17791748, 18.14652634],
              [-67.17763519, 18.14652634],
              [-67.17763519, 18.14763641],
              [-67.17736053, 18.14763641],
              [-67.17736053, 18.14819527],
              [-67.17708588, 18.14819527],
              [-67.17708588, 18.15041733],
              [-67.17680359, 18.15041733],
              [-67.17680359, 18.15097237],
              [-67.17708588, 18.15097237],
              [-67.17708588, 18.15263939],
              [-67.17763519, 18.15263939],
              [-67.17763519, 18.15374947],
              [-67.17791748, 18.15374947],
              [-67.17791748, 18.15486145],
              [-67.17819214, 18.15486145],
              [-67.17819214, 18.15597153],
              [-67.17791748, 18.15597153],
              [-67.17791748, 18.15708351],
              [-67.17819214, 18.15708351],
              [-67.17819214, 18.15819359],
              [-67.17847443, 18.15819359],
              [-67.17847443, 18.15875053],
              [-67.17903137, 18.15875053],
              [-67.17903137, 18.15902519],
              [-67.17930603, 18.15902519],
              [-67.17930603, 18.15930557],
              [-67.17958069, 18.15930557],
              [-67.17958069, 18.15958405],
              [-67.18041992, 18.15958405],
              [-67.18041992, 18.15986061],
              [-67.18125153, 18.15986061],
              [-67.18125153, 18.16013718],
              [-67.18208313, 18.16013718],
              [-67.18208313, 18.16041946],
              [-67.18264008, 18.16041946],
              [-67.18264008, 18.16069412],
              [-67.18291473, 18.16069412],
              [-67.18291473, 18.16124725],
              [-67.18347168, 18.16124725],
              [-67.18347168, 18.16263771],
              [-67.18374634, 18.16263771],
              [-67.18374634, 18.16291618],
              [-67.18402863, 18.16291618],
              [-67.18402863, 18.16319466],
              [-67.18458557, 18.16319466],
              [-67.18458557, 18.16347313],
              [-67.18486023, 18.16347313],
              [-67.18486023, 18.1637516],
              [-67.18514252, 18.1637516],
              [-67.18514252, 18.16430473],
              [-67.18541718, 18.16430473],
              [-67.18541718, 18.16458321],
              [-67.18514252, 18.16458321],
              [-67.18514252, 18.16486168],
              [-67.18541718, 18.16486168],
              [-67.18541718, 18.16541672],
              [-67.18569183, 18.16541672],
              [-67.18569183, 18.16569519],
              [-67.18541718, 18.16569519],
              [-67.18541718, 18.16597366],
              [-67.18514252, 18.16597366],
              [-67.18514252, 18.16625023],
              [-67.18486023, 18.16625023],
              [-67.18486023, 18.1665287],
              [-67.18458557, 18.1665287],
              [-67.18458557, 18.16680527],
              [-67.18430328, 18.16680527],
              [-67.18430328, 18.16708374],
              [-67.18402863, 18.16708374],
              [-67.18402863, 18.16736031],
              [-67.18374634, 18.16736031],
              [-67.18374634, 18.16763878],
              [-67.18347168, 18.16763878],
              [-67.18347168, 18.16791725],
              [-67.18319702, 18.16791725],
              [-67.18319702, 18.16819382],
              [-67.18264008, 18.16819382],
              [-67.18264008, 18.16847229],
              [-67.18235779, 18.16847229],
              [-67.18235779, 18.16875076],
              [-67.18152618, 18.16875076],
              [-67.18152618, 18.16847229],
              [-67.1809082, 18.16847229],
              [-67.17733002, 18.16487122],
              [-67.17501068, 18.16394806],
              [-67.17314148, 18.1620636],
              [-67.17125702, 18.15922737],
              [-67.16705322, 18.15545845],
              [-67.16381073, 18.15502739],
              [-67.15963745, 18.15317154],
              [-67.1545105, 18.14989662],
              [-67.15166473, 18.14325333],
              [-67.14331055, 18.14002037],
              [-67.1414032, 18.13479424],
              [-67.13861084, 18.13291931],
              [-67.13624573, 18.12817955],
              [-67.13527679, 18.12437439],
              [-67.13480377, 18.12342644],
              [-67.13339996, 18.12153435],
              [-67.13061523, 18.12061691],
              [-67.12969208, 18.12062645],
              [-67.12830353, 18.12016678],
              [-67.12736511, 18.11922646],
              [-67.12640381, 18.11541748],
              [-67.12407684, 18.11401749],
              [-67.10043335, 18.10619164],
              [-67.10487366, 18.08991623],
              [-67.10529327, 18.08656883],
              [-67.10614014, 18.07988167],
              [-67.1060791, 18.07463074],
              [-67.10330963, 18.07466507],
              [-67.10192871, 18.07468033],
              [-67.10146332, 18.07420921],
              [-67.09866333, 18.07138062],
              [-67.0986557, 18.07090187],
              [-67.09864044, 18.06947136],
              [-67.09908295, 18.06755829],
              [-67.09999847, 18.06706429],
              [-67.10275269, 18.06608391],
              [-67.10686493, 18.0622139],
              [-67.10910797, 18.05645943],
              [-67.11406708, 18.04637909],
              [-67.11357117, 18.04304314],
              [-67.11076355, 18.0397377],
              [-67.11575317, 18.03203964],
              [-67.11574554, 18.0315609],
              [-67.11335754, 18.02395439],
              [-67.11145782, 18.01967621],
              [-67.11045837, 18.01253128],
              [-67.10936737, 17.99774933],
              [-67.10936737, 17.9972744],
              [-67.1100769, 17.97912216],
              [-67.10914612, 17.97769928],
              [-67.10868073, 17.97770691],
              [-67.10775757, 17.97723961],
              [-67.10359192, 17.97633553],
              [-67.10219574, 17.97492027],
              [-67.10398865, 17.97060394],
              [-67.10580444, 17.96771431],
              [-67.10625458, 17.96723175],
              [-67.10713959, 17.96388054],
              [-67.10661316, 17.95767784],
              [-67.1065979, 17.95672226],
              [-67.10792542, 17.95145607],
              [-67.10972595, 17.94809341],
              [-67.10970306, 17.94569397],
              [-67.10986328, 17.94569397],
              [-67.10986328, 17.9454155],
              [-67.11042023, 17.9454155],
              [-67.11042023, 17.94569397],
              [-67.11208344, 17.94569397],
              [-67.11208344, 17.94597244],
              [-67.11235809, 17.94597244],
              [-67.11235809, 17.94625092],
              [-67.11264038, 17.94625092],
              [-67.11264038, 17.94652939],
              [-67.11291504, 17.94652939],
              [-67.11291504, 17.94763947],
              [-67.11374664, 17.94763947],
              [-67.11374664, 17.94680786],
              [-67.11486053, 17.94680786],
              [-67.11486053, 17.94708252],
              [-67.11513519, 17.94708252],
              [-67.11513519, 17.94874763],
              [-67.11541748, 17.94874763],
              [-67.11541748, 17.94958305],
              [-67.11569214, 17.94958305],
              [-67.11569214, 17.95013809],
              [-67.11736298, 17.95013809],
              [-67.11736298, 17.94986153],
              [-67.11763763, 17.94986153],
              [-67.11763763, 17.94958305],
              [-67.11791992, 17.94958305],
              [-67.11791992, 17.94902992],
              [-67.11763763, 17.94902992],
              [-67.11763763, 17.9481945],
              [-67.11736298, 17.9481945],
              [-67.11736298, 17.94791794],
              [-67.11680603, 17.94791794],
              [-67.11680603, 17.94763947],
              [-67.11624908, 17.94763947],
              [-67.11624908, 17.94708252],
              [-67.11680603, 17.94708252],
              [-67.11680603, 17.94680786],
              [-67.11708069, 17.94680786],
              [-67.11708069, 17.94652939],
              [-67.11763763, 17.94652939],
              [-67.11763763, 17.94680786],
              [-67.11791992, 17.94680786],
              [-67.11791992, 17.94652939],
              [-67.11819458, 17.94652939],
              [-67.11819458, 17.94680786],
              [-67.11846924, 17.94680786],
              [-67.11846924, 17.94652939],
              [-67.11875153, 17.94652939],
              [-67.11875153, 17.94680786],
              [-67.11958313, 17.94680786],
              [-67.11958313, 17.94708252],
              [-67.11985779, 17.94708252],
              [-67.11985779, 17.94735909],
              [-67.12041473, 17.94735909],
              [-67.12041473, 17.94680786],
              [-67.12069702, 17.94680786],
              [-67.12069702, 17.94652939],
              [-67.12124634, 17.94652939],
              [-67.12124634, 17.94625092],
              [-67.12180328, 17.94625092],
              [-67.12180328, 17.94597244],
              [-67.12208557, 17.94597244],
              [-67.12208557, 17.94625092],
              [-67.12236023, 17.94625092],
              [-67.12236023, 17.94597244],
              [-67.12264252, 17.94597244],
              [-67.12264252, 17.94625092],
              [-67.12291718, 17.94625092],
              [-67.12291718, 17.94597244],
              [-67.12319183, 17.94597244],
              [-67.12319183, 17.94625092],
              [-67.12486267, 17.94625092],
              [-67.12486267, 17.94597244],
              [-67.12541962, 17.94597244],
              [-67.12541962, 17.94625092],
              [-67.12625122, 17.94625092],
              [-67.12625122, 17.94680786],
              [-67.12735748, 17.94680786],
              [-67.12735748, 17.94652939],
              [-67.12791443, 17.94652939],
              [-67.12791443, 17.94680786],
              [-67.12847137, 17.94680786],
              [-67.12847137, 17.94708252],
              [-67.12875366, 17.94708252],
              [-67.12875366, 17.94791794],
              [-67.12847137, 17.94791794],
              [-67.12847137, 17.94847298],
              [-67.12791443, 17.94847298],
              [-67.12791443, 17.94874763],
              [-67.12763977, 17.94874763],
              [-67.12763977, 17.94902992],
              [-67.12708282, 17.94902992],
              [-67.12708282, 17.94958305],
              [-67.12680817, 17.94958305],
              [-67.12680817, 17.95013809],
              [-67.12708282, 17.95013809],
              [-67.12708282, 17.95069504],
              [-67.12791443, 17.95069504],
              [-67.12791443, 17.95041656],
              [-67.12819672, 17.95041656],
              [-67.12819672, 17.95013809],
              [-67.12847137, 17.95013809],
              [-67.12847137, 17.94958305],
              [-67.12875366, 17.94958305],
              [-67.12875366, 17.94930649],
              [-67.12902832, 17.94930649],
              [-67.12902832, 17.94902992],
              [-67.12930298, 17.94902992],
              [-67.12930298, 17.94874763],
              [-67.13069153, 17.94874763],
              [-67.13069153, 17.94847298],
              [-67.13097382, 17.94847298],
              [-67.13097382, 17.94874763],
              [-67.13124847, 17.94874763],
              [-67.13124847, 17.94902992],
              [-67.13208008, 17.94902992],
              [-67.13208008, 17.94930649],
              [-67.13236237, 17.94930649],
              [-67.13236237, 17.94958305],
              [-67.13291931, 17.94958305],
              [-67.13291931, 17.94986153],
              [-67.13346863, 17.94986153],
              [-67.13346863, 17.95013809],
              [-67.13458252, 17.95013809],
              [-67.13458252, 17.95041656],
              [-67.13513947, 17.95041656],
              [-67.13513947, 17.95125008],
              [-67.13541412, 17.95125008],
              [-67.13541412, 17.95180511],
              [-67.13569641, 17.95180511],
              [-67.13569641, 17.95236206],
              [-67.13625336, 17.95236206],
              [-67.13625336, 17.95263863],
              [-67.13874817, 17.95263863],
              [-67.13874817, 17.9529171],
              [-67.13930511, 17.9529171],
              [-67.13930511, 17.95319366],
              [-67.14041901, 17.95319366],
              [-67.14041901, 17.95347214],
              [-67.14208221, 17.95347214],
              [-67.14208221, 17.95375061],
              [-67.14347076, 17.95375061],
              [-67.14347076, 17.95402718],
              [-67.14569092, 17.95402718],
              [-67.14569092, 17.95375061],
              [-67.14680481, 17.95375061],
              [-67.14680481, 17.95347214],
              [-67.1479187, 17.95347214],
              [-67.1479187, 17.95375061],
              [-67.14847565, 17.95375061],
              [-67.14847565, 17.95430565],
              [-67.14875031, 17.95430565],
              [-67.14875031, 17.95458221],
              [-67.14930725, 17.95458221],
              [-67.14930725, 17.95486069],
              [-67.1498642, 17.95486069],
              [-67.1498642, 17.95513916],
              [-67.15097046, 17.95513916],
              [-67.15097046, 17.95486069],
              [-67.15125275, 17.95486069],
              [-67.15125275, 17.95458221],
              [-67.1526413, 17.95458221],
              [-67.1526413, 17.95430565],
              [-67.1543045, 17.95430565],
              [-67.1543045, 17.95402718],
              [-67.15736389, 17.95402718],
              [-67.15736389, 17.95347214],
              [-67.1590271, 17.95347214],
              [-67.1590271, 17.95680428],
              [-67.15930176, 17.95680428],
              [-67.15930176, 17.95708275],
              [-67.15958405, 17.95708275],
              [-67.15958405, 17.95736122],
              [-67.1598587, 17.95736122],
              [-67.1598587, 17.95791435],
              [-67.16014099, 17.95791435],
              [-67.16014099, 17.95819473],
              [-67.16069794, 17.95819473],
              [-67.16069794, 17.9584713],
              [-67.1609726, 17.9584713],
              [-67.1609726, 17.95902634],
              [-67.16152954, 17.95902634],
              [-67.16152954, 17.95930481],
              [-67.1618042, 17.95930481],
              [-67.1618042, 17.95958328],
              [-67.16208649, 17.95958328],
              [-67.16208649, 17.95986176],
              [-67.16236115, 17.95986176],
              [-67.16236115, 17.9604187],
              [-67.1626358, 17.9604187],
              [-67.1626358, 17.96097183],
              [-67.16291809, 17.96097183],
              [-67.16291809, 17.96125031],
              [-67.16347504, 17.96125031],
              [-67.16347504, 17.96152878],
              [-67.16402435, 17.96152878],
              [-67.16402435, 17.96180534],
              [-67.16486359, 17.96180534],
              [-67.16486359, 17.96208382],
              [-67.16513824, 17.96208382],
              [-67.16513824, 17.96235847],
              [-67.16652679, 17.96235847],
              [-67.16652679, 17.96264076],
              [-67.1673584, 17.96264076],
              [-67.1673584, 17.96291733],
              [-67.16791534, 17.96291733],
              [-67.16791534, 17.96319389],
              [-67.16847229, 17.96319389],
              [-67.16847229, 17.96347237],
              [-67.16902924, 17.96347237],
              [-67.16902924, 17.96375084],
              [-67.16930389, 17.96375084],
              [-67.16930389, 17.9640274],
              [-67.17069244, 17.9640274],
              [-67.17069244, 17.96375084],
              [-67.17152405, 17.96375084],
              [-67.17152405, 17.9640274],
              [-67.17180634, 17.9640274],
              [-67.17180634, 17.96430588],
              [-67.17236328, 17.96430588],
              [-67.17236328, 17.96458244],
              [-67.17292023, 17.96458244],
              [-67.17292023, 17.96430588],
              [-67.17319489, 17.96430588],
              [-67.17319489, 17.96458244],
              [-67.17402649, 17.96458244],
              [-67.17402649, 17.96486282],
              [-67.17430878, 17.96486282],
              [-67.17430878, 17.96458244],
              [-67.17569733, 17.96458244],
              [-67.17569733, 17.9640274],
              [-67.17597198, 17.9640274],
              [-67.17597198, 17.96375084],
              [-67.17652893, 17.96375084],
              [-67.17652893, 17.96430588],
              [-67.17763519, 17.96430588],
              [-67.17763519, 17.96458244],
              [-67.17958069, 17.96458244],
              [-67.17958069, 17.96430588],
              [-67.17986298, 17.96430588],
              [-67.17986298, 17.9640274],
              [-67.18013763, 17.9640274],
              [-67.18013763, 17.96375084],
              [-67.18152618, 17.96375084],
              [-67.18152618, 17.96319389],
              [-67.18208313, 17.96319389],
              [-67.18208313, 17.96291733],
              [-67.18235779, 17.96291733],
              [-67.18235779, 17.96319389],
              [-67.18291473, 17.96319389],
              [-67.18291473, 17.96291733],
              [-67.18319702, 17.96291733],
              [-67.18319702, 17.96264076],
              [-67.18402863, 17.96264076],
              [-67.18402863, 17.96235847],
              [-67.18430328, 17.96235847],
              [-67.18430328, 17.96180534],
              [-67.18458557, 17.96180534],
              [-67.18458557, 17.96152878],
              [-67.18486023, 17.96152878],
              [-67.18486023, 17.96125031],
              [-67.18514252, 17.96125031],
              [-67.18514252, 17.96097183],
              [-67.18569183, 17.96097183],
              [-67.18569183, 17.96069527],
              [-67.18597412, 17.96069527],
              [-67.18597412, 17.96013641],
              [-67.18653107, 17.96013641],
              [-67.18653107, 17.95986176],
              [-67.18680573, 17.95986176],
              [-67.18680573, 17.95930481],
              [-67.18736267, 17.95930481],
              [-67.18736267, 17.95902634],
              [-67.18763733, 17.95902634],
              [-67.18763733, 17.9584713],
              [-67.18791962, 17.9584713],
              [-67.18791962, 17.95819473],
              [-67.18846893, 17.95819473],
              [-67.18846893, 17.95791435],
              [-67.18875122, 17.95791435],
              [-67.18875122, 17.95763969],
              [-67.18902588, 17.95763969],
              [-67.18902588, 17.95736122],
              [-67.18930817, 17.95736122],
              [-67.18930817, 17.95708275],
              [-67.18958282, 17.95708275],
              [-67.18958282, 17.95680428],
              [-67.18985748, 17.95680428],
              [-67.18985748, 17.95652771],
              [-67.19013977, 17.95652771],
              [-67.19013977, 17.95541573],
              [-67.19041443, 17.95541573],
              [-67.19041443, 17.95513916],
              [-67.19069672, 17.95513916],
              [-67.19069672, 17.95486069],
              [-67.19097137, 17.95486069],
              [-67.19097137, 17.95430565],
              [-67.19152832, 17.95430565],
              [-67.19152832, 17.95375061],
              [-67.19180298, 17.95375061],
              [-67.19180298, 17.95236206],
              [-67.19208527, 17.95236206],
              [-67.19208527, 17.95208359],
              [-67.19180298, 17.95208359],
              [-67.19180298, 17.95069504],
              [-67.19152832, 17.95069504],
              [-67.19152832, 17.95013809],
              [-67.19097137, 17.95013809],
              [-67.19097137, 17.94986153],
              [-67.19069672, 17.94986153],
              [-67.19069672, 17.94958305],
              [-67.19041443, 17.94958305],
              [-67.19041443, 17.94930649],
              [-67.19013977, 17.94930649],
              [-67.19013977, 17.94847298],
              [-67.18985748, 17.94847298],
              [-67.18985748, 17.94652939],
              [-67.19013977, 17.94652939],
              [-67.19013977, 17.94430542],
              [-67.18985748, 17.94430542],
              [-67.18985748, 17.94375038],
              [-67.18958282, 17.94375038],
              [-67.18958282, 17.94347191],
              [-67.18930817, 17.94347191],
              [-67.18930817, 17.94291687],
              [-67.18902588, 17.94291687],
              [-67.18902588, 17.9426384],
              [-67.18875122, 17.9426384],
              [-67.18875122, 17.94208336],
              [-67.18846893, 17.94208336],
              [-67.18846893, 17.94180489],
              [-67.18819427, 17.94180489],
              [-67.18819427, 17.94124985],
              [-67.18736267, 17.94124985],
              [-67.18736267, 17.94097137],
              [-67.18708038, 17.94097137],
              [-67.18708038, 17.94069481],
              [-67.18653107, 17.94069481],
              [-67.18653107, 17.94041634],
              [-67.18624878, 17.94041634],
              [-67.18624878, 17.94013977],
              [-67.18541718, 17.94013977],
              [-67.18541718, 17.9398613],
              [-67.18486023, 17.9398613],
              [-67.18486023, 17.93958282],
              [-67.18430328, 17.93958282],
              [-67.18430328, 17.93930626],
              [-67.18374634, 17.93930626],
              [-67.18374634, 17.93902779],
              [-67.18347168, 17.93902779],
              [-67.18347168, 17.9379158],
              [-67.18374634, 17.9379158],
              [-67.18374634, 17.93736267],
              [-67.18291473, 17.93736267],
              [-67.18291473, 17.93569374],
              [-67.18264008, 17.93569374],
              [-67.18264008, 17.93514061],
              [-67.18291473, 17.93514061],
              [-67.18291473, 17.93291855],
              [-67.18264008, 17.93291855],
              [-67.18264008, 17.93263626],
              [-67.18291473, 17.93263626],
              [-67.18291473, 17.93180466],
              [-67.18319702, 17.93180466],
              [-67.18319702, 17.93152618],
              [-67.18347168, 17.93152618],
              [-67.18347168, 17.93124962],
              [-67.18402863, 17.93124962],
              [-67.18402863, 17.93069458],
              [-67.18653107, 17.93069458],
              [-67.18653107, 17.93097305],
              [-67.18736267, 17.93097305],
              [-67.18736267, 17.93124962],
              [-67.18791962, 17.93124962],
              [-67.18791962, 17.93208313],
              [-67.18819427, 17.93208313],
              [-67.18819427, 17.93402863],
              [-67.18791962, 17.93402863],
              [-67.18791962, 17.93458366],
              [-67.18763733, 17.93458366],
              [-67.18763733, 17.93485832],
              [-67.18791962, 17.93485832],
              [-67.18791962, 17.93541718],
              [-67.18819427, 17.93541718],
              [-67.18819427, 17.93569374],
              [-67.18985748, 17.93569374],
              [-67.18985748, 17.93485832],
              [-67.18958282, 17.93485832],
              [-67.18958282, 17.93402863],
              [-67.18985748, 17.93402863],
              [-67.18985748, 17.93347168],
              [-67.19013977, 17.93347168],
              [-67.19013977, 17.93319511],
              [-67.19041443, 17.93319511],
              [-67.19041443, 17.93291855],
              [-67.19097137, 17.93291855],
              [-67.19097137, 17.93263626],
              [-67.19180298, 17.93263626],
              [-67.19180298, 17.9323616],
              [-67.19208527, 17.9323616],
              [-67.19208527, 17.93208313],
              [-67.19458008, 17.93208313],
              [-67.19458008, 17.9323616],
              [-67.19486237, 17.9323616],
              [-67.19486237, 17.93263626],
              [-67.19541931, 17.93263626],
              [-67.19541931, 17.93291855],
              [-67.19569397, 17.93291855],
              [-67.19569397, 17.93319511],
              [-67.19625092, 17.93319511],
              [-67.19625092, 17.93458366],
              [-67.19680786, 17.93458366],
              [-67.19680786, 17.93541718],
              [-67.19708252, 17.93541718],
              [-67.19708252, 17.93763924],
              [-67.19680786, 17.93763924],
              [-67.19680786, 17.93819427],
              [-67.19652557, 17.93819427],
              [-67.19652557, 17.93847275],
              [-67.19569397, 17.93847275],
              [-67.19569397, 17.93874931],
              [-67.19486237, 17.93874931],
              [-67.19486237, 17.93930626],
              [-67.19430542, 17.93930626],
              [-67.19430542, 17.93958282],
              [-67.19374847, 17.93958282],
              [-67.19374847, 17.9398613],
              [-67.19347382, 17.9398613],
              [-67.19347382, 17.94013977],
              [-67.19319153, 17.94013977],
              [-67.19319153, 17.94097137],
              [-67.19347382, 17.94097137],
              [-67.19347382, 17.94319534],
              [-67.19430542, 17.94319534],
              [-67.19430542, 17.94347191],
              [-67.19458008, 17.94347191],
              [-67.19458008, 17.94375038],
              [-67.19486237, 17.94375038],
              [-67.19486237, 17.94569397],
              [-67.19458008, 17.94569397],
              [-67.19458008, 17.94625092],
              [-67.19430542, 17.94625092],
              [-67.19430542, 17.94680786],
              [-67.19403076, 17.94680786],
              [-67.19403076, 17.94791794],
              [-67.19430542, 17.94791794],
              [-67.19430542, 17.94930649],
              [-67.19458008, 17.94930649],
              [-67.19458008, 17.94986153],
              [-67.19486237, 17.94986153],
              [-67.19486237, 17.95013809],
              [-67.19513702, 17.95013809],
              [-67.19513702, 17.95041656],
              [-67.19541931, 17.95041656],
              [-67.19541931, 17.95069504],
              [-67.19596863, 17.95069504],
              [-67.19596863, 17.9509716],
              [-67.19652557, 17.9509716],
              [-67.19652557, 17.95125008],
              [-67.19680786, 17.95125008],
              [-67.19680786, 17.95152855],
              [-67.19708252, 17.95152855],
              [-67.19708252, 17.95180511],
              [-67.19736481, 17.95180511],
              [-67.19736481, 17.95208359],
              [-67.19819641, 17.95208359],
              [-67.19819641, 17.95236206],
              [-67.19875336, 17.95236206],
              [-67.19875336, 17.95263863],
              [-67.19958496, 17.95263863],
              [-67.19958496, 17.9529171],
              [-67.20014191, 17.9529171],
              [-67.20014191, 17.95319366],
              [-67.20263672, 17.95319366],
              [-67.20263672, 17.95347214],
              [-67.20347595, 17.95347214],
              [-67.20347595, 17.95375061],
              [-67.20458221, 17.95375061],
              [-67.20458221, 17.95402718],
              [-67.20513916, 17.95402718],
              [-67.20513916, 17.95430565],
              [-67.20791626, 17.95430565],
              [-67.20791626, 17.95402718],
              [-67.20903015, 17.95402718],
              [-67.20903015, 17.95375061],
              [-67.2104187, 17.95375061],
              [-67.2104187, 17.95347214],
              [-67.21152496, 17.95347214],
              [-67.21152496, 17.95319366],
              [-67.21180725, 17.95319366],
              [-67.21180725, 17.95236206],
              [-67.21208191, 17.95236206],
              [-67.21208191, 17.95180511],
              [-67.21291351, 17.95180511],
              [-67.21291351, 17.95263863],
              [-67.2131958, 17.95263863],
              [-67.2131958, 17.95236206],
              [-67.21347046, 17.95236206],
              [-67.21347046, 17.95208359],
              [-67.21375275, 17.95208359],
              [-67.21375275, 17.95180511],
              [-67.2140274, 17.95180511],
              [-67.2140274, 17.95125008],
            ],
          ],
          [
            [
              [-67.0973587, 17.92319489],
              [-67.0973587, 17.92291641],
              [-67.09708405, 17.92291641],
              [-67.09708405, 17.9220829],
              [-67.09764099, 17.9220829],
              [-67.09764099, 17.92124748],
              [-67.09791565, 17.92124748],
              [-67.09791565, 17.92069435],
              [-67.09819794, 17.92069435],
              [-67.09819794, 17.92013741],
              [-67.0984726, 17.92013741],
              [-67.0984726, 17.91986084],
              [-67.09874725, 17.91986084],
              [-67.09874725, 17.91958427],
              [-67.09902954, 17.91958427],
              [-67.09902954, 17.91930771],
              [-67.09958649, 17.91930771],
              [-67.09958649, 17.91902542],
              [-67.10069275, 17.91902542],
              [-67.10069275, 17.91930771],
              [-67.1020813, 17.91930771],
              [-67.1020813, 17.91875076],
              [-67.1029129, 17.91875076],
              [-67.1029129, 17.91847229],
              [-67.10319519, 17.91847229],
              [-67.10319519, 17.91819382],
              [-67.10346985, 17.91819382],
              [-67.10346985, 17.91791534],
              [-67.10402679, 17.91791534],
              [-67.10402679, 17.91763878],
              [-67.1048584, 17.91763878],
              [-67.1048584, 17.91708374],
              [-67.10569763, 17.91708374],
              [-67.10569763, 17.91680527],
              [-67.10624695, 17.91680527],
              [-67.10624695, 17.9165287],
              [-67.10652924, 17.9165287],
              [-67.10652924, 17.91625023],
              [-67.10958099, 17.91625023],
              [-67.10958099, 17.91597176],
              [-67.11013794, 17.91597176],
              [-67.11013794, 17.91569328],
              [-67.11042023, 17.91569328],
              [-67.11042023, 17.91541672],
              [-67.11096954, 17.91541672],
              [-67.11096954, 17.91597176],
              [-67.11235809, 17.91597176],
              [-67.11235809, 17.91569328],
              [-67.11264038, 17.91569328],
              [-67.11264038, 17.91513824],
              [-67.11513519, 17.91513824],
              [-67.11513519, 17.91569328],
              [-67.11458588, 17.91569328],
              [-67.11458588, 17.91625023],
              [-67.11653137, 17.91625023],
              [-67.11653137, 17.9165287],
              [-67.11902618, 17.9165287],
              [-67.11902618, 17.91680527],
              [-67.12097168, 17.91680527],
              [-67.12097168, 17.91708374],
              [-67.12124634, 17.91708374],
              [-67.12124634, 17.91680527],
              [-67.12208557, 17.91680527],
              [-67.12208557, 17.9165287],
              [-67.12291718, 17.9165287],
              [-67.12291718, 17.91597176],
              [-67.12347412, 17.91597176],
              [-67.12347412, 17.91625023],
              [-67.12374878, 17.91625023],
              [-67.12374878, 17.91680527],
              [-67.12403107, 17.91680527],
              [-67.12403107, 17.91708374],
              [-67.12458038, 17.91708374],
              [-67.12458038, 17.91680527],
              [-67.12541962, 17.91680527],
              [-67.12541962, 17.91736031],
              [-67.12569427, 17.91736031],
              [-67.12569427, 17.91763878],
              [-67.12596893, 17.91763878],
              [-67.12596893, 17.91819382],
              [-67.12625122, 17.91819382],
              [-67.12625122, 17.91875076],
              [-67.12652588, 17.91875076],
              [-67.12652588, 17.91930771],
              [-67.12625122, 17.91930771],
              [-67.12625122, 17.91958427],
              [-67.12596893, 17.91958427],
              [-67.12596893, 17.91986084],
              [-67.12569427, 17.91986084],
              [-67.12569427, 17.92013741],
              [-67.12458038, 17.92013741],
              [-67.12458038, 17.91847229],
              [-67.12430573, 17.91847229],
              [-67.12430573, 17.91791534],
              [-67.12374878, 17.91791534],
              [-67.12374878, 17.91958427],
              [-67.12319183, 17.91958427],
              [-67.12319183, 17.91930771],
              [-67.12264252, 17.91930771],
              [-67.12264252, 17.91875076],
              [-67.12152863, 17.91875076],
              [-67.12152863, 17.91847229],
              [-67.12097168, 17.91847229],
              [-67.12097168, 17.91791534],
              [-67.12069702, 17.91791534],
              [-67.12069702, 17.91763878],
              [-67.11763763, 17.91763878],
              [-67.11763763, 17.91791534],
              [-67.11708069, 17.91791534],
              [-67.11708069, 17.91819382],
              [-67.11486053, 17.91819382],
              [-67.11486053, 17.91791534],
              [-67.11458588, 17.91791534],
              [-67.11458588, 17.91763878],
              [-67.11374664, 17.91763878],
              [-67.11374664, 17.91736031],
              [-67.11347198, 17.91736031],
              [-67.11347198, 17.91708374],
              [-67.11319733, 17.91708374],
              [-67.11319733, 17.91763878],
              [-67.10708618, 17.91763878],
              [-67.10708618, 17.91791534],
              [-67.10597229, 17.91791534],
              [-67.10597229, 17.91819382],
              [-67.10569763, 17.91819382],
              [-67.10569763, 17.91847229],
              [-67.1048584, 17.91847229],
              [-67.1048584, 17.91875076],
              [-67.10458374, 17.91875076],
              [-67.10458374, 17.91902542],
              [-67.10402679, 17.91902542],
              [-67.10402679, 17.91930771],
              [-67.10375214, 17.91930771],
              [-67.10375214, 17.91958427],
              [-67.10319519, 17.91958427],
              [-67.10319519, 17.91986084],
              [-67.1029129, 17.91986084],
              [-67.1029129, 17.92013741],
              [-67.10263824, 17.92013741],
              [-67.10263824, 17.92069435],
              [-67.10124969, 17.92069435],
              [-67.10124969, 17.92097282],
              [-67.09958649, 17.92097282],
              [-67.09958649, 17.92124748],
              [-67.09902954, 17.92124748],
              [-67.09902954, 17.92152977],
              [-67.09874725, 17.92152977],
              [-67.09874725, 17.92236137],
              [-67.0984726, 17.92236137],
              [-67.0984726, 17.92291641],
              [-67.09791565, 17.92291641],
              [-67.09791565, 17.92319489],
              [-67.0973587, 17.92319489],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 12,
        name: 'Cabo Rojo',
        hasc_1: 'PR.CR',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-67.21541595, 17.91513824, -67.09708405, 18.16875076],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.15',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-65.83633423, 18.27526283],
              [-65.83911133, 18.27335739],
              [-65.83957672, 18.27097511],
              [-65.84004974, 18.26525307],
              [-65.84051514, 18.26478004],
              [-65.84653473, 18.25811386],
              [-65.85255432, 18.25478935],
              [-65.86180115, 18.25718307],
              [-65.86642456, 18.2557621],
              [-65.86782074, 18.25338173],
              [-65.86920166, 18.25290489],
              [-65.8738327, 18.25243568],
              [-65.88030243, 18.25673294],
              [-65.885849, 18.25960159],
              [-65.88677216, 18.26008224],
              [-65.88908386, 18.26151276],
              [-65.89231873, 18.26151848],
              [-65.89648438, 18.26104546],
              [-65.9029541, 18.26343727],
              [-65.91127777, 18.26534843],
              [-65.91635895, 18.26869011],
              [-65.91635895, 18.27059937],
              [-65.90849304, 18.27106857],
              [-65.90756989, 18.27392578],
              [-65.90987396, 18.28108025],
              [-65.90986633, 18.28536797],
              [-65.9154129, 18.29204941],
              [-65.9158783, 18.2930069],
              [-65.9154129, 18.29443359],
              [-65.9126358, 18.29538345],
              [-65.90847015, 18.29537964],
              [-65.90615845, 18.29776382],
              [-65.90615845, 18.29823875],
              [-65.91031647, 18.30443954],
              [-65.91077423, 18.30681992],
              [-65.90614319, 18.30824661],
              [-65.90475464, 18.30967522],
              [-65.90197754, 18.31586838],
              [-65.90197754, 18.31634521],
              [-65.9029007, 18.31968117],
              [-65.90335846, 18.32063675],
              [-65.90798187, 18.32254791],
              [-65.90891266, 18.32350159],
              [-65.90937042, 18.32636261],
              [-65.90937042, 18.32779312],
              [-65.90843964, 18.32922363],
              [-65.9061203, 18.33255577],
              [-65.90796661, 18.34351921],
              [-65.91305542, 18.34305],
              [-65.91583252, 18.34257317],
              [-65.91675568, 18.34305382],
              [-65.91953278, 18.34448242],
              [-65.91953278, 18.34495926],
              [-65.91906738, 18.34686661],
              [-65.91582489, 18.3535347],
              [-65.9176712, 18.35830116],
              [-65.91859436, 18.36163712],
              [-65.91859436, 18.36258888],
              [-65.91905212, 18.37212372],
              [-65.91905212, 18.37307549],
              [-65.9162674, 18.37545586],
              [-65.9134903, 18.37830925],
              [-65.9130249, 18.37878609],
              [-65.9130249, 18.37926674],
              [-65.91487122, 18.38260269],
              [-65.92181396, 18.38689613],
              [-65.92320251, 18.38689995],
              [-65.92876434, 18.38690376],
              [-65.93107605, 18.39357185],
              [-65.93061066, 18.39500046],
              [-65.92967987, 18.39595413],
              [-65.91902924, 18.40023232],
              [-65.91909027, 18.40096855],
              [-65.90964508, 18.40085602],
              [-65.89984131, 18.41406631],
              [-65.86991882, 18.40891266],
              [-65.87703705, 18.3782692],
              [-65.86696625, 18.37859726],
              [-65.8671875, 18.37825584],
              [-65.86811829, 18.3763504],
              [-65.86950684, 18.37540054],
              [-65.87367249, 18.37397766],
              [-65.87413788, 18.37302589],
              [-65.87321472, 18.37064171],
              [-65.87322235, 18.36920929],
              [-65.87368011, 18.36683083],
              [-65.87368774, 18.36587524],
              [-65.8746109, 18.36492538],
              [-65.87507629, 18.36444664],
              [-65.87646484, 18.36302185],
              [-65.87693024, 18.36206436],
              [-65.87693024, 18.36159325],
              [-65.87601471, 18.35539436],
              [-65.87509155, 18.35301208],
              [-65.87462616, 18.35158348],
              [-65.87463379, 18.34633827],
              [-65.87371826, 18.34061813],
              [-65.8732605, 18.33680725],
              [-65.8732605, 18.33585358],
              [-65.8727951, 18.3348999],
              [-65.86817169, 18.33298302],
              [-65.86632538, 18.33155441],
              [-65.86263275, 18.32106018],
              [-65.86402893, 18.31820297],
              [-65.86541748, 18.31725311],
              [-65.86958313, 18.31725693],
              [-65.87004852, 18.31249619],
              [-65.869133, 18.31058884],
              [-65.8686676, 18.30963326],
              [-65.86404419, 18.30962753],
              [-65.86404419, 18.30771828],
              [-65.86312103, 18.30438042],
              [-65.86266327, 18.30295181],
              [-65.865448, 18.29771042],
              [-65.865448, 18.29723549],
              [-65.86682892, 18.29723549],
              [-65.8682251, 18.29390335],
              [-65.86591339, 18.29390144],
              [-65.86360168, 18.29389572],
              [-65.86082458, 18.29150772],
              [-65.85713196, 18.28816414],
              [-65.85575104, 18.28625488],
              [-65.85436249, 18.2852993],
              [-65.84603882, 18.28146935],
              [-65.84234619, 18.27908134],
              [-65.83911133, 18.277174],
              [-65.83633423, 18.27526283],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 15,
        name: 'Canóvanas',
        hasc_1: 'PR.CV',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-65.93107605, 18.25243568, -65.83633423, 18.41406631],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.16',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-65.91867065, 18.27012634],
              [-65.92144775, 18.27155685],
              [-65.92838287, 18.27680588],
              [-65.93300629, 18.28014755],
              [-65.93947601, 18.28777885],
              [-65.94224548, 18.29350281],
              [-65.93899536, 18.30874825],
              [-65.94038391, 18.31113243],
              [-65.94177246, 18.31209183],
              [-65.94223785, 18.31209183],
              [-65.95657349, 18.31829453],
              [-65.96166229, 18.3230648],
              [-65.96305084, 18.32496834],
              [-65.96721649, 18.33021355],
              [-65.96906281, 18.3373642],
              [-65.97554016, 18.35261536],
              [-65.98942566, 18.36595917],
              [-65.99173737, 18.37692261],
              [-65.99914551, 18.38025856],
              [-65.9996109, 18.38025856],
              [-66.00285339, 18.38549614],
              [-66.002388, 18.39502716],
              [-65.99220276, 18.39836311],
              [-65.9912796, 18.39836311],
              [-65.99173737, 18.39883995],
              [-65.99173737, 18.39979362],
              [-65.99312592, 18.40741539],
              [-66.00099945, 18.40932274],
              [-66.00887299, 18.41313362],
              [-66.02045441, 18.42027855],
              [-66.02880096, 18.4264698],
              [-66.02972412, 18.4278965],
              [-66.03157806, 18.43218422],
              [-66.03250122, 18.43647003],
              [-66.03620911, 18.43932724],
              [-66.03899384, 18.44075775],
              [-66.04316711, 18.44408989],
              [-66.03668213, 18.45028305],
              [-66.03652954, 18.45041656],
              [-66.03624725, 18.45041656],
              [-66.03624725, 18.45013809],
              [-66.0359726, 18.45013809],
              [-66.0359726, 18.44986153],
              [-66.03541565, 18.44986153],
              [-66.03541565, 18.44958305],
              [-66.03514099, 18.44958305],
              [-66.03514099, 18.44902802],
              [-66.0348587, 18.44902802],
              [-66.0348587, 18.44874954],
              [-66.03430176, 18.44874954],
              [-66.03430176, 18.44847488],
              [-66.0340271, 18.44847488],
              [-66.0340271, 18.4481945],
              [-66.03375244, 18.4481945],
              [-66.03375244, 18.44791603],
              [-66.03347015, 18.44791603],
              [-66.03347015, 18.44763947],
              [-66.03291321, 18.44763947],
              [-66.03291321, 18.4473629],
              [-66.03263855, 18.4473629],
              [-66.03263855, 18.44708061],
              [-66.03236389, 18.44708061],
              [-66.03236389, 18.44652748],
              [-66.03069305, 18.44652748],
              [-66.03069305, 18.44625282],
              [-66.0304184, 18.44625282],
              [-66.0304184, 18.44597244],
              [-66.02986145, 18.44597244],
              [-66.02986145, 18.44569206],
              [-66.02902985, 18.44569206],
              [-66.02902985, 18.4454174],
              [-66.0284729, 18.4454174],
              [-66.0284729, 18.44514084],
              [-66.0276413, 18.44514084],
              [-66.0276413, 18.44486237],
              [-66.02680206, 18.44486237],
              [-66.02680206, 18.44458389],
              [-66.02597046, 18.44458389],
              [-66.02597046, 18.44430542],
              [-66.02458191, 18.44430542],
              [-66.02458191, 18.44402695],
              [-66.02153015, 18.44402695],
              [-66.02153015, 18.44430542],
              [-66.01985931, 18.44430542],
              [-66.01985931, 18.44458389],
              [-66.01930237, 18.44458389],
              [-66.01930237, 18.44486237],
              [-66.01902771, 18.44486237],
              [-66.01902771, 18.44514084],
              [-66.01819611, 18.44514084],
              [-66.01819611, 18.4454174],
              [-66.0173645, 18.4454174],
              [-66.0173645, 18.44597244],
              [-66.01708221, 18.44597244],
              [-66.01708221, 18.44652748],
              [-66.01680756, 18.44652748],
              [-66.01680756, 18.44680595],
              [-66.01569366, 18.44680595],
              [-66.01569366, 18.44652748],
              [-66.01541901, 18.44652748],
              [-66.01541901, 18.44625282],
              [-66.01513672, 18.44625282],
              [-66.01513672, 18.44597244],
              [-66.01486206, 18.44597244],
              [-66.01486206, 18.4454174],
              [-66.01457977, 18.4454174],
              [-66.01457977, 18.44514084],
              [-66.01403046, 18.44514084],
              [-66.01403046, 18.44486237],
              [-66.01319122, 18.44486237],
              [-66.01319122, 18.44458389],
              [-66.01264191, 18.44458389],
              [-66.01264191, 18.44430542],
              [-66.01208496, 18.44430542],
              [-66.01208496, 18.44402695],
              [-66.01041412, 18.44402695],
              [-66.01041412, 18.44430542],
              [-66.00958252, 18.44430542],
              [-66.00958252, 18.44458389],
              [-66.00875092, 18.44458389],
              [-66.00875092, 18.44430542],
              [-66.00846863, 18.44430542],
              [-66.00846863, 18.44402695],
              [-66.00680542, 18.44402695],
              [-66.00680542, 18.44430542],
              [-66.00514221, 18.44430542],
              [-66.00514221, 18.44458389],
              [-66.00485992, 18.44458389],
              [-66.00485992, 18.44486237],
              [-66.00375366, 18.44486237],
              [-66.00375366, 18.44514084],
              [-66.00319672, 18.44514084],
              [-66.00319672, 18.4454174],
              [-66.00263977, 18.4454174],
              [-66.00263977, 18.44569206],
              [-66.00180817, 18.44569206],
              [-66.00180817, 18.4454174],
              [-66.00152588, 18.4454174],
              [-66.00152588, 18.4473629],
              [-66.00125122, 18.4473629],
              [-66.00125122, 18.44763947],
              [-66.00069427, 18.44763947],
              [-66.00069427, 18.44791603],
              [-66.00013733, 18.44791603],
              [-65.99986267, 18.44791603],
              [-65.99986267, 18.44847488],
              [-65.99958038, 18.44847488],
              [-65.99958038, 18.44874954],
              [-65.99874878, 18.44874954],
              [-65.99874878, 18.44902802],
              [-65.99847412, 18.44902802],
              [-65.99847412, 18.44930649],
              [-65.99791718, 18.44930649],
              [-65.99791718, 18.44958305],
              [-65.99736023, 18.44958305],
              [-65.99736023, 18.44986153],
              [-65.99680328, 18.44986153],
              [-65.99680328, 18.45069695],
              [-65.99652863, 18.45069695],
              [-65.99652863, 18.4509716],
              [-65.99624634, 18.4509716],
              [-65.99624634, 18.45152855],
              [-65.99597168, 18.45152855],
              [-65.99597168, 18.45208359],
              [-65.99569702, 18.45208359],
              [-65.99569702, 18.45236206],
              [-65.99541473, 18.45236206],
              [-65.99541473, 18.45263863],
              [-65.99514008, 18.45263863],
              [-65.99514008, 18.45347214],
              [-65.99485779, 18.45347214],
              [-65.99485779, 18.45430565],
              [-65.99458313, 18.45430565],
              [-65.99458313, 18.45513725],
              [-65.99430847, 18.45513725],
              [-65.99430847, 18.45597267],
              [-65.99402618, 18.45597267],
              [-65.99402618, 18.45791626],
              [-65.99291992, 18.45791626],
              [-65.99291992, 18.4576416],
              [-65.99236298, 18.4576416],
              [-65.99236298, 18.45735931],
              [-65.99212646, 18.45735931],
              [-65.99182892, 18.45705795],
              [-65.9901886, 18.45536613],
              [-65.98478699, 18.44981384],
              [-65.98571014, 18.44600296],
              [-65.98571014, 18.44457436],
              [-65.98524475, 18.44362259],
              [-65.98432159, 18.44266701],
              [-65.98061371, 18.44219017],
              [-65.97135162, 18.44028282],
              [-65.96995544, 18.43742561],
              [-65.96718597, 18.43265915],
              [-65.96625519, 18.43218231],
              [-65.96533203, 18.43265724],
              [-65.96115875, 18.43313217],
              [-65.95698547, 18.43360901],
              [-65.95004272, 18.4350338],
              [-65.93707275, 18.43026161],
              [-65.93105316, 18.42263412],
              [-65.91994476, 18.41119194],
              [-65.91909027, 18.40096855],
              [-65.91902924, 18.40023232],
              [-65.92967987, 18.39595413],
              [-65.93061066, 18.39500046],
              [-65.93107605, 18.39357185],
              [-65.92876434, 18.38690376],
              [-65.92320251, 18.38689995],
              [-65.92181396, 18.38689613],
              [-65.91487122, 18.38260269],
              [-65.9130249, 18.37926674],
              [-65.9130249, 18.37878609],
              [-65.9134903, 18.37830925],
              [-65.9162674, 18.37545586],
              [-65.91905212, 18.37307549],
              [-65.91905212, 18.37212372],
              [-65.91859436, 18.36258888],
              [-65.91859436, 18.36163712],
              [-65.9176712, 18.35830116],
              [-65.91582489, 18.3535347],
              [-65.91906738, 18.34686661],
              [-65.91953278, 18.34495926],
              [-65.91953278, 18.34448242],
              [-65.91675568, 18.34305382],
              [-65.91583252, 18.34257317],
              [-65.91305542, 18.34305],
              [-65.90796661, 18.34351921],
              [-65.9061203, 18.33255577],
              [-65.90843964, 18.32922363],
              [-65.90937042, 18.32779312],
              [-65.90937042, 18.32636261],
              [-65.90891266, 18.32350159],
              [-65.90798187, 18.32254791],
              [-65.90335846, 18.32063675],
              [-65.9029007, 18.31968117],
              [-65.90197754, 18.31634521],
              [-65.90197754, 18.31586838],
              [-65.90475464, 18.30967522],
              [-65.90614319, 18.30824661],
              [-65.91077423, 18.30681992],
              [-65.91031647, 18.30443954],
              [-65.90615845, 18.29823875],
              [-65.90615845, 18.29776382],
              [-65.90847015, 18.29537964],
              [-65.9126358, 18.29538345],
              [-65.9154129, 18.29443359],
              [-65.9158783, 18.2930069],
              [-65.9154129, 18.29204941],
              [-65.90986633, 18.28536797],
              [-65.90987396, 18.28108025],
              [-65.90756989, 18.27392578],
              [-65.90849304, 18.27106857],
              [-65.91635895, 18.27059937],
              [-65.91635895, 18.26869011],
              [-65.91867065, 18.27012634],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 16,
        name: 'Carolina',
        hasc_1: 'PR.CN',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.04316711, 18.26869011, -65.90197754, 18.45791626],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.17',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.11208344, 18.44011307],
              [-66.11405182, 18.44069099],
              [-66.11544037, 18.44021416],
              [-66.11821747, 18.43782806],
              [-66.12284088, 18.43306351],
              [-66.13163757, 18.42447472],
              [-66.13580322, 18.42208672],
              [-66.14043427, 18.42303085],
              [-66.14877319, 18.4230175],
              [-66.15201569, 18.42253685],
              [-66.15293884, 18.42206192],
              [-66.15756989, 18.4210968],
              [-66.15850067, 18.42157173],
              [-66.15989685, 18.42538261],
              [-66.16221619, 18.4282341],
              [-66.16592407, 18.43013382],
              [-66.16916656, 18.43108177],
              [-66.17009735, 18.43346024],
              [-66.17009735, 18.43441963],
              [-66.16963959, 18.43536949],
              [-66.16917419, 18.43536758],
              [-66.16593933, 18.43632507],
              [-66.16501617, 18.43823433],
              [-66.16362762, 18.44157219],
              [-66.16362762, 18.44395447],
              [-66.16316986, 18.44586182],
              [-66.15992737, 18.44586754],
              [-66.15853882, 18.44586754],
              [-66.15483093, 18.44587326],
              [-66.15390778, 18.44587326],
              [-66.15158844, 18.44826126],
              [-66.14927673, 18.45064545],
              [-66.14835358, 18.45160103],
              [-66.14650726, 18.45541382],
              [-66.13791656, 18.45958328],
              [-66.13764191, 18.45958328],
              [-66.13764191, 18.45902824],
              [-66.13735962, 18.45902824],
              [-66.13735962, 18.45846939],
              [-66.13708496, 18.45846939],
              [-66.13708496, 18.45791626],
              [-66.13680267, 18.45791626],
              [-66.13680267, 18.4576416],
              [-66.13652802, 18.4576416],
              [-66.13652802, 18.45735931],
              [-66.13625336, 18.45735931],
              [-66.13625336, 18.45708275],
              [-66.13597107, 18.45708275],
              [-66.13597107, 18.45652771],
              [-66.13569641, 18.45652771],
              [-66.13569641, 18.45624733],
              [-66.13541412, 18.45624733],
              [-66.13541412, 18.45597267],
              [-66.13513947, 18.45597267],
              [-66.13513947, 18.4556942],
              [-66.13486481, 18.4556942],
              [-66.13486481, 18.45541573],
              [-66.13319397, 18.45541573],
              [-66.13319397, 18.45513725],
              [-66.13263702, 18.45513725],
              [-66.13263702, 18.45486069],
              [-66.13180542, 18.45486069],
              [-66.13180542, 18.45458412],
              [-66.13069153, 18.45458412],
              [-66.13069153, 18.45430565],
              [-66.13014221, 18.45430565],
              [-66.13014221, 18.45402718],
              [-66.12985992, 18.45402718],
              [-66.12985992, 18.45347214],
              [-66.12958527, 18.45347214],
              [-66.12958527, 18.45291519],
              [-66.12930298, 18.45291519],
              [-66.12930298, 18.45236206],
              [-66.12902832, 18.45236206],
              [-66.12902832, 18.45180511],
              [-66.12875366, 18.45180511],
              [-66.12875366, 18.45013809],
              [-66.12902832, 18.45013809],
              [-66.12902832, 18.44986153],
              [-66.12930298, 18.44986153],
              [-66.12930298, 18.44958305],
              [-66.12958527, 18.44958305],
              [-66.12958527, 18.44930649],
              [-66.12985992, 18.44930649],
              [-66.12985992, 18.44902802],
              [-66.13041687, 18.44902802],
              [-66.13041687, 18.44874954],
              [-66.13062286, 18.44874954],
              [-66.13124847, 18.44874954],
              [-66.13124847, 18.44902802],
              [-66.13153076, 18.44902802],
              [-66.13153076, 18.4494648],
              [-66.13153076, 18.44958305],
              [-66.13124847, 18.44958305],
              [-66.13124847, 18.44986153],
              [-66.13069153, 18.44986153],
              [-66.13069153, 18.45013809],
              [-66.13014221, 18.45013809],
              [-66.13014221, 18.45041656],
              [-66.12985992, 18.45041656],
              [-66.12985992, 18.45152855],
              [-66.13014221, 18.45152855],
              [-66.13014221, 18.45208359],
              [-66.13041687, 18.45208359],
              [-66.13041687, 18.45263863],
              [-66.13069153, 18.45263863],
              [-66.13069153, 18.45319366],
              [-66.13097382, 18.45319366],
              [-66.13097382, 18.45347214],
              [-66.13208008, 18.45347214],
              [-66.13208008, 18.45375061],
              [-66.13291931, 18.45375061],
              [-66.13291931, 18.45402718],
              [-66.13346863, 18.45402718],
              [-66.13346863, 18.45430565],
              [-66.13513947, 18.45430565],
              [-66.13513947, 18.45458412],
              [-66.13541412, 18.45458412],
              [-66.13541412, 18.45513725],
              [-66.13597107, 18.45513725],
              [-66.13597107, 18.45541573],
              [-66.13625336, 18.45541573],
              [-66.13625336, 18.4556942],
              [-66.13652802, 18.4556942],
              [-66.13652802, 18.45597267],
              [-66.13680267, 18.45597267],
              [-66.13680267, 18.45624733],
              [-66.13708496, 18.45624733],
              [-66.13708496, 18.45680618],
              [-66.13735962, 18.45680618],
              [-66.13735962, 18.45708275],
              [-66.13764191, 18.45708275],
              [-66.13764191, 18.45735931],
              [-66.13819122, 18.45735931],
              [-66.13819122, 18.45708275],
              [-66.13847351, 18.45708275],
              [-66.13847351, 18.45680618],
              [-66.13903046, 18.45680618],
              [-66.13903046, 18.45652771],
              [-66.13930511, 18.45652771],
              [-66.13930511, 18.45624733],
              [-66.13957977, 18.45624733],
              [-66.13957977, 18.45597267],
              [-66.13986206, 18.45597267],
              [-66.13986206, 18.45513725],
              [-66.13957977, 18.45513725],
              [-66.13957977, 18.45486069],
              [-66.13930511, 18.45486069],
              [-66.13930511, 18.45458412],
              [-66.13903046, 18.45458412],
              [-66.13903046, 18.45430565],
              [-66.13874817, 18.45430565],
              [-66.13874817, 18.45402718],
              [-66.13847351, 18.45402718],
              [-66.13847351, 18.45347214],
              [-66.13819122, 18.45347214],
              [-66.13819122, 18.45263863],
              [-66.13791656, 18.45263863],
              [-66.13791656, 18.45208359],
              [-66.13764191, 18.45208359],
              [-66.13764191, 18.4509716],
              [-66.13735962, 18.4509716],
              [-66.13735962, 18.45069695],
              [-66.13708496, 18.45069695],
              [-66.13708496, 18.45041656],
              [-66.13680267, 18.45041656],
              [-66.13680267, 18.45013809],
              [-66.13625336, 18.45013809],
              [-66.13625336, 18.44986153],
              [-66.13597107, 18.44986153],
              [-66.13597107, 18.44958305],
              [-66.13541412, 18.44958305],
              [-66.13541412, 18.44874954],
              [-66.13486481, 18.44874954],
              [-66.13486481, 18.44847488],
              [-66.13458252, 18.44847488],
              [-66.13458252, 18.44791603],
              [-66.13430786, 18.44791603],
              [-66.13430786, 18.44763947],
              [-66.13402557, 18.44763947],
              [-66.13402557, 18.44680595],
              [-66.13375092, 18.44680595],
              [-66.13375092, 18.44652748],
              [-66.13319397, 18.44652748],
              [-66.13319397, 18.44597244],
              [-66.13291931, 18.44597244],
              [-66.13291931, 18.44569206],
              [-66.13263702, 18.44569206],
              [-66.13263702, 18.4454174],
              [-66.13236237, 18.4454174],
              [-66.13236237, 18.44514084],
              [-66.13153076, 18.44514084],
              [-66.13153076, 18.44458389],
              [-66.13097382, 18.44458389],
              [-66.13097382, 18.44430542],
              [-66.13069153, 18.44430542],
              [-66.13069153, 18.44402695],
              [-66.13014221, 18.44402695],
              [-66.13014221, 18.44374847],
              [-66.12958527, 18.44374847],
              [-66.12958527, 18.44347191],
              [-66.12819672, 18.44347191],
              [-66.12819672, 18.44319534],
              [-66.12680817, 18.44319534],
              [-66.12680817, 18.44291687],
              [-66.12652588, 18.44291687],
              [-66.12652588, 18.4426384],
              [-66.12596893, 18.4426384],
              [-66.12596893, 18.44236183],
              [-66.12486267, 18.44236183],
              [-66.12486267, 18.44208336],
              [-66.12319183, 18.44208336],
              [-66.12319183, 18.44180489],
              [-66.12097168, 18.44180489],
              [-66.12097168, 18.44208336],
              [-66.11985779, 18.44208336],
              [-66.11985779, 18.44236183],
              [-66.11958313, 18.44236183],
              [-66.11958313, 18.4426384],
              [-66.11846924, 18.4426384],
              [-66.11846924, 18.44291687],
              [-66.11819458, 18.44291687],
              [-66.11819458, 18.44319534],
              [-66.11791992, 18.44319534],
              [-66.11791992, 18.44430542],
              [-66.11763763, 18.44430542],
              [-66.11763763, 18.44458389],
              [-66.11653137, 18.44458389],
              [-66.11653137, 18.44430542],
              [-66.11541748, 18.44430542],
              [-66.11541748, 18.44458389],
              [-66.11513519, 18.44458389],
              [-66.11513519, 18.44486237],
              [-66.11486053, 18.44486237],
              [-66.11486053, 18.44514084],
              [-66.11180878, 18.44514084],
              [-66.11180878, 18.44486237],
              [-66.11125183, 18.44486237],
              [-66.11125183, 18.44458389],
              [-66.11069489, 18.44458389],
              [-66.11069489, 18.44430542],
              [-66.11013794, 18.44430542],
              [-66.11013794, 18.44319534],
              [-66.11042023, 18.44319534],
              [-66.11042023, 18.4426384],
              [-66.11069489, 18.4426384],
              [-66.11069489, 18.44236183],
              [-66.11096954, 18.44236183],
              [-66.11096954, 18.44208336],
              [-66.11125183, 18.44208336],
              [-66.11125183, 18.44124985],
              [-66.11180878, 18.44124985],
              [-66.11180878, 18.44097137],
              [-66.11208344, 18.44097137],
              [-66.11208344, 18.44011307],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 17,
        name: 'Cataño',
        hasc_1: 'PR.CT',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.17009735, 18.4210968, -66.11013794, 18.45958328],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.18',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.21670532, 18.14305305],
              [-66.20931244, 18.13973236],
              [-66.20285034, 18.14118004],
              [-66.19498444, 18.13642311],
              [-66.18160248, 18.13978958],
              [-66.17281342, 18.13265038],
              [-66.1621933, 18.1369648],
              [-66.16313171, 18.14507484],
              [-66.13539886, 18.13557816],
              [-66.12802124, 18.14035797],
              [-66.11601257, 18.13989639],
              [-66.10816193, 18.14658737],
              [-66.0980072, 18.15327454],
              [-66.09525299, 18.16901779],
              [-66.08831787, 18.16044044],
              [-66.08045197, 18.15233994],
              [-66.07121277, 18.14996147],
              [-66.06705475, 18.14853668],
              [-66.0656662, 18.14805984],
              [-66.06613159, 18.14710236],
              [-66.06613159, 18.14615059],
              [-66.06889343, 18.14281082],
              [-66.06935883, 18.14232826],
              [-66.06889343, 18.13994408],
              [-66.06565857, 18.13136292],
              [-66.06380463, 18.12802505],
              [-66.06195831, 18.1246891],
              [-66.06103516, 18.12325478],
              [-66.06010437, 18.1184864],
              [-66.05825806, 18.11610222],
              [-66.05641174, 18.11419296],
              [-66.05502319, 18.11371994],
              [-66.05271149, 18.11371994],
              [-66.05132294, 18.11038017],
              [-66.05316925, 18.10751534],
              [-66.05963898, 18.10369492],
              [-66.06286621, 18.10321617],
              [-66.06378937, 18.10273743],
              [-66.06840515, 18.10178185],
              [-66.07025146, 18.10178185],
              [-66.07117462, 18.10225677],
              [-66.0785675, 18.10320091],
              [-66.0827179, 18.10415649],
              [-66.08826447, 18.10749054],
              [-66.09380341, 18.10700607],
              [-66.09796143, 18.10700226],
              [-66.1007309, 18.10604477],
              [-66.10118866, 18.10556984],
              [-66.10257721, 18.10508919],
              [-66.10903168, 18.10030937],
              [-66.10903931, 18.09983253],
              [-66.11271667, 18.09028435],
              [-66.12657928, 18.09694862],
              [-66.12703705, 18.09647179],
              [-66.12841797, 18.09360695],
              [-66.12887573, 18.08930779],
              [-66.12609863, 18.08263588],
              [-66.12654877, 18.07739067],
              [-66.12562561, 18.0754776],
              [-66.12516022, 18.07452583],
              [-66.12146759, 18.07309723],
              [-66.12054443, 18.07023621],
              [-66.12099457, 18.06355476],
              [-66.12329102, 18.05639648],
              [-66.12374878, 18.05400658],
              [-66.12467194, 18.05305099],
              [-66.12789917, 18.0482769],
              [-66.1288147, 18.04064178],
              [-66.12880707, 18.04015923],
              [-66.12880707, 18.03920746],
              [-66.13249969, 18.03824615],
              [-66.13526154, 18.03728867],
              [-66.13895416, 18.0368042],
              [-66.14357758, 18.04061508],
              [-66.14404297, 18.04157066],
              [-66.15049744, 18.04203224],
              [-66.15465546, 18.04298401],
              [-66.15511322, 18.04346275],
              [-66.15603638, 18.04345894],
              [-66.15743256, 18.04775047],
              [-66.16620636, 18.05012131],
              [-66.17359161, 18.05344963],
              [-66.1782074, 18.05344009],
              [-66.18005371, 18.0524807],
              [-66.19297028, 18.04959106],
              [-66.19895935, 18.04719353],
              [-66.20172882, 18.04623222],
              [-66.20451355, 18.0548172],
              [-66.20451355, 18.05529213],
              [-66.20405579, 18.05529213],
              [-66.20220947, 18.05625534],
              [-66.20129395, 18.05768967],
              [-66.20129395, 18.05912018],
              [-66.20130157, 18.06341553],
              [-66.20084381, 18.0643692],
              [-66.2017746, 18.06770706],
              [-66.20870209, 18.07103348],
              [-66.21564484, 18.07960701],
              [-66.21565247, 18.08246803],
              [-66.21520233, 18.08628654],
              [-66.21659088, 18.0896225],
              [-66.21844482, 18.09247971],
              [-66.22029114, 18.09390831],
              [-66.22399139, 18.09532738],
              [-66.22444916, 18.09532928],
              [-66.22540283, 18.10534668],
              [-66.22540283, 18.10630035],
              [-66.22681427, 18.11679459],
              [-66.22174072, 18.11919022],
              [-66.22035217, 18.12062645],
              [-66.22083282, 18.1287365],
              [-66.22084045, 18.13159752],
              [-66.21945953, 18.1325531],
              [-66.21853638, 18.13350868],
              [-66.21715546, 18.1359005],
              [-66.21669769, 18.13780785],
              [-66.21670532, 18.14305305],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 18,
        name: 'Cayey',
        hasc_1: 'PR.CY',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.22681427, 18.0368042, -66.05132294, 18.16901779],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.21',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.09525299, 18.16901779],
              [-66.0980072, 18.15327454],
              [-66.10816193, 18.14658737],
              [-66.11601257, 18.13989639],
              [-66.12802124, 18.14035797],
              [-66.13539886, 18.13557816],
              [-66.16313171, 18.14507484],
              [-66.1621933, 18.1369648],
              [-66.17281342, 18.13265038],
              [-66.18160248, 18.13978958],
              [-66.19498444, 18.13642311],
              [-66.20285034, 18.14118004],
              [-66.20931244, 18.13973236],
              [-66.21670532, 18.14305305],
              [-66.22041321, 18.14495468],
              [-66.21995544, 18.14972496],
              [-66.22781372, 18.15065956],
              [-66.22367096, 18.15543938],
              [-66.22875214, 18.15829086],
              [-66.22091675, 18.16498756],
              [-66.24266815, 18.18019295],
              [-66.24359131, 18.18066978],
              [-66.2440567, 18.18114471],
              [-66.24452209, 18.18304825],
              [-66.24359894, 18.18400764],
              [-66.24082947, 18.1844902],
              [-66.23066711, 18.18451691],
              [-66.2297287, 18.18070221],
              [-66.22787476, 18.17784691],
              [-66.22371674, 18.17547607],
              [-66.21400452, 18.17311287],
              [-66.21169281, 18.17311668],
              [-66.21123505, 18.17407417],
              [-66.21356964, 18.18360329],
              [-66.21310425, 18.18599129],
              [-66.20896149, 18.19363213],
              [-66.20666504, 18.19697571],
              [-66.20018768, 18.19698715],
              [-66.19649506, 18.19795036],
              [-66.18587494, 18.20083427],
              [-66.18495178, 18.20131302],
              [-66.18449402, 18.20703506],
              [-66.17849731, 18.21277046],
              [-66.17203522, 18.21564484],
              [-66.17018127, 18.21660233],
              [-66.16972351, 18.21755791],
              [-66.16880798, 18.22423363],
              [-66.16279602, 18.22090721],
              [-66.16233063, 18.22090721],
              [-66.16187286, 18.22043228],
              [-66.16048431, 18.21995544],
              [-66.15909576, 18.2204361],
              [-66.15770721, 18.22091675],
              [-66.15539551, 18.22139549],
              [-66.14985657, 18.22521782],
              [-66.14894104, 18.22712898],
              [-66.14616394, 18.23047066],
              [-66.14385223, 18.22808838],
              [-66.14385223, 18.22761154],
              [-66.1438446, 18.22427559],
              [-66.1438446, 18.22284508],
              [-66.14289856, 18.20997238],
              [-66.14149475, 18.19757462],
              [-66.13733673, 18.19758034],
              [-66.12625122, 18.20618057],
              [-66.12532806, 18.20665932],
              [-66.11886597, 18.20952606],
              [-66.11794281, 18.21000671],
              [-66.10915375, 18.20095825],
              [-66.10173798, 18.18284225],
              [-66.10495758, 18.17139435],
              [-66.10264587, 18.16949081],
              [-66.10033417, 18.16996765],
              [-66.09525299, 18.16901779],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 21,
        name: 'Cidra',
        hasc_1: 'PR.CD',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.24452209, 18.13265038, -66.09525299, 18.23047066],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.19',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-65.61152649, 18.21180534],
              [-65.61180878, 18.21180534],
              [-65.61180878, 18.21236038],
              [-65.61208344, 18.21236038],
              [-65.61208344, 18.21430779],
              [-65.61235809, 18.21430779],
              [-65.61235809, 18.21513748],
              [-65.61264038, 18.21513748],
              [-65.61264038, 18.21652985],
              [-65.61235809, 18.21652985],
              [-65.61235809, 18.21846962],
              [-65.61264038, 18.21846962],
              [-65.61264038, 18.21902847],
              [-65.61291504, 18.21902847],
              [-65.61291504, 18.21958351],
              [-65.61319733, 18.21958351],
              [-65.61319733, 18.21986389],
              [-65.61347198, 18.21986389],
              [-65.61347198, 18.22013855],
              [-65.61374664, 18.22013855],
              [-65.61374664, 18.22069359],
              [-65.61430359, 18.22069359],
              [-65.61430359, 18.22097206],
              [-65.61458588, 18.22097206],
              [-65.61458588, 18.22125053],
              [-65.61513519, 18.22125053],
              [-65.61513519, 18.2215271],
              [-65.61541748, 18.2215271],
              [-65.61541748, 18.22180557],
              [-65.61569214, 18.22180557],
              [-65.61569214, 18.22208214],
              [-65.61597443, 18.22208214],
              [-65.61597443, 18.22236061],
              [-65.61624908, 18.22236061],
              [-65.61624908, 18.22263908],
              [-65.61653137, 18.22263908],
              [-65.61653137, 18.22291756],
              [-65.61763763, 18.22291756],
              [-65.61763763, 18.22319412],
              [-65.61819458, 18.22319412],
              [-65.61819458, 18.22291756],
              [-65.61846924, 18.22291756],
              [-65.61846924, 18.22263908],
              [-65.61875153, 18.22263908],
              [-65.61875153, 18.22236061],
              [-65.61902618, 18.22236061],
              [-65.61902618, 18.22208214],
              [-65.61958313, 18.22208214],
              [-65.61958313, 18.22236061],
              [-65.61985779, 18.22236061],
              [-65.61985779, 18.22319412],
              [-65.61958313, 18.22319412],
              [-65.61958313, 18.2234726],
              [-65.61902618, 18.2234726],
              [-65.61902618, 18.22374916],
              [-65.61819458, 18.22374916],
              [-65.61819458, 18.22458267],
              [-65.61791992, 18.22458267],
              [-65.61791992, 18.22597313],
              [-65.61763763, 18.22597313],
              [-65.61763763, 18.22652626],
              [-65.61736298, 18.22652626],
              [-65.61736298, 18.22680473],
              [-65.61708069, 18.22680473],
              [-65.61708069, 18.22736168],
              [-65.61763763, 18.22736168],
              [-65.61763763, 18.22763634],
              [-65.61791992, 18.22763634],
              [-65.61791992, 18.22791862],
              [-65.61819458, 18.22791862],
              [-65.61819458, 18.22819519],
              [-65.61846924, 18.22819519],
              [-65.61846924, 18.22791862],
              [-65.61902618, 18.22791862],
              [-65.61902618, 18.22763634],
              [-65.61930847, 18.22763634],
              [-65.61930847, 18.22736168],
              [-65.61958313, 18.22736168],
              [-65.61958313, 18.22763634],
              [-65.62014008, 18.22763634],
              [-65.62014008, 18.22847176],
              [-65.62041473, 18.22847176],
              [-65.62041473, 18.22903061],
              [-65.62124634, 18.22903061],
              [-65.62124634, 18.22874832],
              [-65.62152863, 18.22874832],
              [-65.62152863, 18.22819519],
              [-65.62180328, 18.22819519],
              [-65.62180328, 18.22791862],
              [-65.62208557, 18.22791862],
              [-65.62208557, 18.22763634],
              [-65.62236023, 18.22763634],
              [-65.62236023, 18.22708321],
              [-65.62264252, 18.22708321],
              [-65.62264252, 18.22680473],
              [-65.62291718, 18.22680473],
              [-65.62291718, 18.22652626],
              [-65.62319183, 18.22652626],
              [-65.62319183, 18.22597313],
              [-65.62347412, 18.22597313],
              [-65.62347412, 18.22569466],
              [-65.62374878, 18.22569466],
              [-65.62374878, 18.22541618],
              [-65.62403107, 18.22541618],
              [-65.62403107, 18.22486115],
              [-65.62430573, 18.22486115],
              [-65.62430573, 18.22458267],
              [-65.62458038, 18.22458267],
              [-65.62458038, 18.2243042],
              [-65.62486267, 18.2243042],
              [-65.62486267, 18.22374916],
              [-65.62569427, 18.22374916],
              [-65.62569427, 18.22402763],
              [-65.62596893, 18.22402763],
              [-65.62596893, 18.2243042],
              [-65.62569427, 18.2243042],
              [-65.62569427, 18.22458267],
              [-65.62541962, 18.22458267],
              [-65.62541962, 18.22513962],
              [-65.62513733, 18.22513962],
              [-65.62513733, 18.22541618],
              [-65.62486267, 18.22541618],
              [-65.62486267, 18.22569466],
              [-65.62458038, 18.22569466],
              [-65.62458038, 18.22624969],
              [-65.62430573, 18.22624969],
              [-65.62430573, 18.22652626],
              [-65.62403107, 18.22652626],
              [-65.62403107, 18.22680473],
              [-65.62374878, 18.22680473],
              [-65.62374878, 18.22736168],
              [-65.62347412, 18.22736168],
              [-65.62347412, 18.22763634],
              [-65.62319183, 18.22763634],
              [-65.62319183, 18.22791862],
              [-65.62291718, 18.22791862],
              [-65.62291718, 18.22847176],
              [-65.62264252, 18.22847176],
              [-65.62264252, 18.22874832],
              [-65.62236023, 18.22874832],
              [-65.62236023, 18.22958374],
              [-65.62208557, 18.22958374],
              [-65.62208557, 18.23097229],
              [-65.62236023, 18.23097229],
              [-65.62236023, 18.2318058],
              [-65.62264252, 18.2318058],
              [-65.62264252, 18.23208427],
              [-65.62319183, 18.23208427],
              [-65.62319183, 18.23236275],
              [-65.62403107, 18.23236275],
              [-65.62403107, 18.23263931],
              [-65.62513733, 18.23263931],
              [-65.62513733, 18.23236275],
              [-65.62625122, 18.23236275],
              [-65.62625122, 18.23208427],
              [-65.62680817, 18.23208427],
              [-65.62680817, 18.23236275],
              [-65.62708282, 18.23236275],
              [-65.62708282, 18.23263931],
              [-65.62791443, 18.23263931],
              [-65.62791443, 18.23291588],
              [-65.62819672, 18.23291588],
              [-65.62819672, 18.23319435],
              [-65.62847137, 18.23319435],
              [-65.62847137, 18.23347282],
              [-65.63069153, 18.23347282],
              [-65.63069153, 18.23319435],
              [-65.63153076, 18.23319435],
              [-65.63153076, 18.23291588],
              [-65.63180542, 18.23291588],
              [-65.63180542, 18.23263931],
              [-65.63208008, 18.23263931],
              [-65.63208008, 18.23236275],
              [-65.63236237, 18.23236275],
              [-65.63236237, 18.23208427],
              [-65.63263702, 18.23208427],
              [-65.63263702, 18.2318058],
              [-65.63291931, 18.2318058],
              [-65.63291931, 18.23125267],
              [-65.63402557, 18.23125267],
              [-65.63402557, 18.23097229],
              [-65.63430786, 18.23097229],
              [-65.63430786, 18.23041725],
              [-65.63513947, 18.23041725],
              [-65.63513947, 18.23014069],
              [-65.63541412, 18.23014069],
              [-65.63541412, 18.2298584],
              [-65.63569641, 18.2298584],
              [-65.63569641, 18.22958374],
              [-65.63597107, 18.22958374],
              [-65.63597107, 18.22930527],
              [-65.63625336, 18.22930527],
              [-65.63625336, 18.22903061],
              [-65.63652802, 18.22903061],
              [-65.63652802, 18.22874832],
              [-65.63708496, 18.22874832],
              [-65.63708496, 18.22847176],
              [-65.63735962, 18.22847176],
              [-65.63735962, 18.22513962],
              [-65.63708496, 18.22513962],
              [-65.63708496, 18.22486115],
              [-65.63680267, 18.22486115],
              [-65.63680267, 18.22458267],
              [-65.63652802, 18.22458267],
              [-65.63652802, 18.2243042],
              [-65.63569641, 18.2243042],
              [-65.63569641, 18.2234726],
              [-65.63625336, 18.2234726],
              [-65.63625336, 18.22319412],
              [-65.63652802, 18.22319412],
              [-65.63652802, 18.22291756],
              [-65.63680267, 18.22291756],
              [-65.63680267, 18.22236061],
              [-65.63708496, 18.22236061],
              [-65.63708496, 18.22208214],
              [-65.63735962, 18.22208214],
              [-65.63735962, 18.22180557],
              [-65.63819122, 18.22180557],
              [-65.63819122, 18.2215271],
              [-65.63847351, 18.2215271],
              [-65.63847351, 18.22097206],
              [-65.63874817, 18.22097206],
              [-65.63874817, 18.22041702],
              [-65.63957977, 18.22041702],
              [-65.63957977, 18.22013855],
              [-65.63930511, 18.22013855],
              [-65.63930511, 18.21986389],
              [-65.63903046, 18.21986389],
              [-65.63903046, 18.21958351],
              [-65.63874817, 18.21958351],
              [-65.63874817, 18.21930504],
              [-65.63847351, 18.21930504],
              [-65.63847351, 18.21875191],
              [-65.63708496, 18.21875191],
              [-65.63708496, 18.21846962],
              [-65.63680267, 18.21846962],
              [-65.63680267, 18.21819496],
              [-65.63513947, 18.21819496],
              [-65.63513947, 18.21791649],
              [-65.63263702, 18.21791649],
              [-65.63263702, 18.21736145],
              [-65.63236237, 18.21736145],
              [-65.63236237, 18.21513748],
              [-65.63263702, 18.21513748],
              [-65.63263702, 18.2140274],
              [-65.63236237, 18.2140274],
              [-65.63236237, 18.21375084],
              [-65.63208008, 18.21375084],
              [-65.63208008, 18.21347237],
              [-65.63180542, 18.21347237],
              [-65.63180542, 18.21291542],
              [-65.63124847, 18.21291542],
              [-65.63124847, 18.21263885],
              [-65.63097382, 18.21263885],
              [-65.63097382, 18.21180534],
              [-65.63069153, 18.21180534],
              [-65.63069153, 18.21097183],
              [-65.63097382, 18.21097183],
              [-65.63097382, 18.21069527],
              [-65.63124847, 18.21069527],
              [-65.63124847, 18.21041679],
              [-65.63180542, 18.21041679],
              [-65.63180542, 18.20986176],
              [-65.63430786, 18.20986176],
              [-65.63430786, 18.21041679],
              [-65.63458252, 18.21041679],
              [-65.63458252, 18.21013832],
              [-65.63486481, 18.21013832],
              [-65.63486481, 18.20986176],
              [-65.63513947, 18.20986176],
              [-65.63513947, 18.20902824],
              [-65.63541412, 18.20902824],
              [-65.63541412, 18.2056942],
              [-65.63486481, 18.2056942],
              [-65.63486481, 18.20541573],
              [-65.63402557, 18.20541573],
              [-65.63402557, 18.2056942],
              [-65.63375092, 18.2056942],
              [-65.63375092, 18.20624924],
              [-65.63346863, 18.20624924],
              [-65.63346863, 18.20652771],
              [-65.63263702, 18.20652771],
              [-65.63263702, 18.20680618],
              [-65.63208008, 18.20680618],
              [-65.63208008, 18.20763969],
              [-65.63180542, 18.20763969],
              [-65.63180542, 18.20819473],
              [-65.63153076, 18.20819473],
              [-65.63153076, 18.2084713],
              [-65.63097382, 18.2084713],
              [-65.63097382, 18.20874977],
              [-65.63069153, 18.20874977],
              [-65.63069153, 18.20930481],
              [-65.63041687, 18.20930481],
              [-65.63041687, 18.20958328],
              [-65.63014221, 18.20958328],
              [-65.63014221, 18.20986176],
              [-65.62958527, 18.20986176],
              [-65.62958527, 18.20958328],
              [-65.62930298, 18.20958328],
              [-65.62930298, 18.20902824],
              [-65.62902832, 18.20902824],
              [-65.62902832, 18.2084713],
              [-65.62875366, 18.2084713],
              [-65.62875366, 18.20819473],
              [-65.62763977, 18.20819473],
              [-65.62763977, 18.20597267],
              [-65.62791443, 18.20597267],
              [-65.62791443, 18.2056942],
              [-65.62819672, 18.2056942],
              [-65.62819672, 18.20458412],
              [-65.62875366, 18.20458412],
              [-65.62875366, 18.20430565],
              [-65.62902832, 18.20430565],
              [-65.62902832, 18.20375061],
              [-65.62930298, 18.20375061],
              [-65.62930298, 18.20319366],
              [-65.62958527, 18.20319366],
              [-65.62958527, 18.2029171],
              [-65.62985992, 18.2029171],
              [-65.62985992, 18.20264053],
              [-65.63041687, 18.20264053],
              [-65.63041687, 18.20208359],
              [-65.63153076, 18.20208359],
              [-65.63153076, 18.20153046],
              [-65.63124847, 18.20153046],
              [-65.63124847, 18.20069695],
              [-65.63097382, 18.20069695],
              [-65.63097382, 18.20013618],
              [-65.63263702, 18.20013618],
              [-65.63263702, 18.19986153],
              [-65.63319397, 18.19986153],
              [-65.63319397, 18.19958305],
              [-65.63569641, 18.19958305],
              [-65.63569641, 18.20180511],
              [-65.63597107, 18.20180511],
              [-65.63597107, 18.20319366],
              [-65.63625336, 18.20319366],
              [-65.63625336, 18.20375061],
              [-65.63680267, 18.20375061],
              [-65.63680267, 18.20402718],
              [-65.63708496, 18.20402718],
              [-65.63708496, 18.20458412],
              [-65.63735962, 18.20458412],
              [-65.63735962, 18.20541573],
              [-65.63764191, 18.20541573],
              [-65.63764191, 18.2056942],
              [-65.63791656, 18.2056942],
              [-65.63791656, 18.20652771],
              [-65.63903046, 18.20652771],
              [-65.63903046, 18.20597267],
              [-65.63930511, 18.20597267],
              [-65.63930511, 18.20513916],
              [-65.63957977, 18.20513916],
              [-65.63957977, 18.20458412],
              [-65.63986206, 18.20458412],
              [-65.63986206, 18.20375061],
              [-65.64013672, 18.20375061],
              [-65.64013672, 18.20347023],
              [-65.64069366, 18.20347023],
              [-65.64069366, 18.2029171],
              [-65.64097595, 18.2029171],
              [-65.64097595, 18.20208359],
              [-65.64125061, 18.20208359],
              [-65.64125061, 18.20153046],
              [-65.64097595, 18.20153046],
              [-65.64097595, 18.2009716],
              [-65.64069366, 18.2009716],
              [-65.64069366, 18.20069695],
              [-65.64013672, 18.20069695],
              [-65.64013672, 18.20041656],
              [-65.63986206, 18.20041656],
              [-65.63986206, 18.20013618],
              [-65.64013672, 18.20013618],
              [-65.64013672, 18.19986153],
              [-65.64069366, 18.19986153],
              [-65.64069366, 18.19958305],
              [-65.64152527, 18.19958305],
              [-65.64152527, 18.20013618],
              [-65.6423645, 18.20013618],
              [-65.6423645, 18.20041656],
              [-65.64263916, 18.20041656],
              [-65.64263916, 18.20013618],
              [-65.64291382, 18.20013618],
              [-65.64291382, 18.19958305],
              [-65.64319611, 18.19958305],
              [-65.64319611, 18.1993084],
              [-65.64347076, 18.1993084],
              [-65.64347076, 18.1981945],
              [-65.64375305, 18.1981945],
              [-65.64375305, 18.19791412],
              [-65.64485931, 18.19791412],
              [-65.64485931, 18.1981945],
              [-65.64541626, 18.1981945],
              [-65.64541626, 18.19847298],
              [-65.64569092, 18.19847298],
              [-65.64569092, 18.19874954],
              [-65.64597321, 18.19874954],
              [-65.64597321, 18.19902611],
              [-65.64653015, 18.19902611],
              [-65.64653015, 18.1993084],
              [-65.64736176, 18.1993084],
              [-65.64736176, 18.19958305],
              [-65.64847565, 18.19958305],
              [-65.64847565, 18.20013618],
              [-65.64875031, 18.20013618],
              [-65.64875031, 18.20041656],
              [-65.6498642, 18.20041656],
              [-65.6498642, 18.20013618],
              [-65.65112305, 18.20013618],
              [-65.65180206, 18.20013618],
              [-65.65180206, 18.20041656],
              [-65.65208435, 18.20041656],
              [-65.65208435, 18.20069695],
              [-65.65235901, 18.20069695],
              [-65.65235901, 18.20143318],
              [-65.65235901, 18.20180511],
              [-65.6526413, 18.20180511],
              [-65.6526413, 18.20236206],
              [-65.65291595, 18.20236206],
              [-65.65291595, 18.2029171],
              [-65.65319824, 18.2029171],
              [-65.65319824, 18.20319366],
              [-65.6534729, 18.20319366],
              [-65.6534729, 18.20347023],
              [-65.65402985, 18.20347023],
              [-65.65402985, 18.20375061],
              [-65.6543045, 18.20375061],
              [-65.6543045, 18.20402718],
              [-65.65458679, 18.20402718],
              [-65.65458679, 18.20458412],
              [-65.65486145, 18.20458412],
              [-65.65486145, 18.20486259],
              [-65.65513611, 18.20486259],
              [-65.65513611, 18.20513916],
              [-65.65569305, 18.20513916],
              [-65.65569305, 18.20541573],
              [-65.65597534, 18.20541573],
              [-65.65597534, 18.2056942],
              [-65.65643311, 18.2056942],
              [-65.65684509, 18.2056942],
              [-65.6570816, 18.2056942],
              [-65.6570816, 18.20597267],
              [-65.6581955, 18.20597267],
              [-65.6581955, 18.20624924],
              [-65.6590271, 18.20624924],
              [-65.6590271, 18.20652771],
              [-65.66004944, 18.20652771],
              [-65.66125488, 18.20946312],
              [-65.66447449, 18.21376991],
              [-65.66447449, 18.21472359],
              [-65.66168976, 18.21614075],
              [-65.66030121, 18.21756744],
              [-65.65797424, 18.22184944],
              [-65.65797424, 18.22232437],
              [-65.65843201, 18.22471237],
              [-65.66212463, 18.22520256],
              [-65.66397095, 18.22473145],
              [-65.66536713, 18.22330666],
              [-65.66721344, 18.22331047],
              [-65.66953278, 18.22284317],
              [-65.67183685, 18.22380638],
              [-65.67599487, 18.22525215],
              [-65.68015289, 18.22669601],
              [-65.68061066, 18.22717667],
              [-65.6856842, 18.23005867],
              [-65.68845367, 18.23149681],
              [-65.68890381, 18.23626137],
              [-65.69167328, 18.23722458],
              [-65.6930542, 18.23818588],
              [-65.69166565, 18.24008942],
              [-65.68981171, 18.24246407],
              [-65.68656158, 18.24626923],
              [-65.68378448, 18.24721336],
              [-65.6828537, 18.24959373],
              [-65.68283844, 18.25245476],
              [-65.68422699, 18.25341225],
              [-65.68515015, 18.2538929],
              [-65.6888504, 18.25152588],
              [-65.69348145, 18.25106049],
              [-65.69485474, 18.25488091],
              [-65.69577789, 18.25536156],
              [-65.69762421, 18.25679588],
              [-65.70040131, 18.25680351],
              [-65.70223999, 18.25919533],
              [-65.70269775, 18.25967026],
              [-65.70549011, 18.25682259],
              [-65.70594788, 18.25634575],
              [-65.70780182, 18.25587654],
              [-65.71196747, 18.25541115],
              [-65.71611786, 18.25780869],
              [-65.71658325, 18.25781059],
              [-65.71981812, 18.25781441],
              [-65.72167206, 18.25734711],
              [-65.72167206, 18.25639343],
              [-65.72167969, 18.25401115],
              [-65.72122192, 18.25305367],
              [-65.72122192, 18.25257874],
              [-65.72214508, 18.25210381],
              [-65.73000336, 18.25308037],
              [-65.73231506, 18.25404167],
              [-65.73554993, 18.25643349],
              [-65.737854, 18.25882339],
              [-65.73923492, 18.26073456],
              [-65.74154663, 18.26121712],
              [-65.74940491, 18.2626667],
              [-65.75217438, 18.26363182],
              [-65.75402069, 18.26506424],
              [-65.75632477, 18.26745605],
              [-65.7623291, 18.27128983],
              [-65.76324463, 18.27557755],
              [-65.76463318, 18.27796745],
              [-65.76509094, 18.27891922],
              [-65.76694489, 18.27988052],
              [-65.76554108, 18.28368759],
              [-65.7641449, 18.28606987],
              [-65.76229095, 18.28892326],
              [-65.76043701, 18.28939629],
              [-65.75904846, 18.29082489],
              [-65.75765991, 18.29177284],
              [-65.75486755, 18.295578],
              [-65.753479, 18.2970047],
              [-65.74978638, 18.29413605],
              [-65.74332428, 18.28839684],
              [-65.74008942, 18.28600693],
              [-65.73732758, 18.28265953],
              [-65.74057007, 18.27980995],
              [-65.73827362, 18.27503395],
              [-65.73735046, 18.27359962],
              [-65.73503113, 18.27502441],
              [-65.72810364, 18.27166748],
              [-65.71886444, 18.26877975],
              [-65.71608734, 18.26733971],
              [-65.70359802, 18.27111626],
              [-65.70127869, 18.2720623],
              [-65.7003479, 18.27301216],
              [-65.69989014, 18.27300835],
              [-65.70219421, 18.27492714],
              [-65.69522095, 18.2858696],
              [-65.69337463, 18.28538513],
              [-65.69060516, 18.28299141],
              [-65.68737793, 18.27916527],
              [-65.68599701, 18.27821159],
              [-65.68276978, 18.27533722],
              [-65.68138123, 18.27485657],
              [-65.67906952, 18.2743721],
              [-65.6758194, 18.27817535],
              [-65.67488861, 18.27960014],
              [-65.67487335, 18.28341484],
              [-65.67302704, 18.28340912],
              [-65.66978455, 18.28434944],
              [-65.668396, 18.28482246],
              [-65.66606903, 18.28815269],
              [-65.6623764, 18.28670883],
              [-65.65406036, 18.28286171],
              [-65.64620209, 18.2814045],
              [-65.64481354, 18.2818737],
              [-65.6374054, 18.28422928],
              [-65.63647461, 18.28470039],
              [-65.63417053, 18.28373718],
              [-65.63375092, 18.28368568],
              [-65.63375092, 18.28153038],
              [-65.63346863, 18.28153038],
              [-65.63346863, 18.28124809],
              [-65.63319397, 18.28124809],
              [-65.63319397, 18.28097153],
              [-65.63263702, 18.28097153],
              [-65.63263702, 18.28069496],
              [-65.63208008, 18.28069496],
              [-65.63208008, 18.28041649],
              [-65.63014221, 18.28041649],
              [-65.63014221, 18.28069496],
              [-65.62985992, 18.28069496],
              [-65.62985992, 18.28097153],
              [-65.62958527, 18.28097153],
              [-65.62958527, 18.28124809],
              [-65.62847137, 18.28124809],
              [-65.62847137, 18.28097153],
              [-65.62735748, 18.28097153],
              [-65.62735748, 18.28124809],
              [-65.62708282, 18.28124809],
              [-65.62708282, 18.28180504],
              [-65.62680817, 18.28180504],
              [-65.62680817, 18.28208351],
              [-65.62596893, 18.28208351],
              [-65.62596893, 18.28180504],
              [-65.62541962, 18.28180504],
              [-65.62541962, 18.28153038],
              [-65.62486267, 18.28153038],
              [-65.62486267, 18.28041649],
              [-65.62458038, 18.28041649],
              [-65.62458038, 18.27958298],
              [-65.62430573, 18.27958298],
              [-65.62430573, 18.2784729],
              [-65.62486267, 18.2784729],
              [-65.62486267, 18.27819443],
              [-65.62708282, 18.27819443],
              [-65.62708282, 18.27791405],
              [-65.62763977, 18.27791405],
              [-65.62763977, 18.27763939],
              [-65.62791443, 18.27763939],
              [-65.62791443, 18.27736092],
              [-65.62819672, 18.27736092],
              [-65.62819672, 18.2765274],
              [-65.62847137, 18.2765274],
              [-65.62847137, 18.27486038],
              [-65.62819672, 18.27486038],
              [-65.62819672, 18.27458382],
              [-65.62763977, 18.27458382],
              [-65.62763977, 18.27430534],
              [-65.62708282, 18.27430534],
              [-65.62708282, 18.27402687],
              [-65.62680817, 18.27402687],
              [-65.62680817, 18.27375031],
              [-65.62541962, 18.27375031],
              [-65.62541962, 18.27319527],
              [-65.62569427, 18.27319527],
              [-65.62569427, 18.27291679],
              [-65.62596893, 18.27291679],
              [-65.62596893, 18.27263832],
              [-65.62625122, 18.27263832],
              [-65.62625122, 18.27236176],
              [-65.62652588, 18.27236176],
              [-65.62652588, 18.27208328],
              [-65.62680817, 18.27208328],
              [-65.62680817, 18.27180481],
              [-65.62763977, 18.27180481],
              [-65.62763977, 18.27152824],
              [-65.62847137, 18.27152824],
              [-65.62847137, 18.27069473],
              [-65.62958527, 18.27069473],
              [-65.62958527, 18.2701416],
              [-65.63014221, 18.2701416],
              [-65.63014221, 18.26985931],
              [-65.63041687, 18.26985931],
              [-65.63041687, 18.26958275],
              [-65.63069153, 18.26958275],
              [-65.63069153, 18.26902962],
              [-65.63097382, 18.26902962],
              [-65.63097382, 18.26708412],
              [-65.63069153, 18.26708412],
              [-65.63069153, 18.26652527],
              [-65.63041687, 18.26652527],
              [-65.63041687, 18.26375008],
              [-65.63014221, 18.26375008],
              [-65.63014221, 18.26319313],
              [-65.62985992, 18.26319313],
              [-65.62985992, 18.26291656],
              [-65.62958527, 18.26291656],
              [-65.62958527, 18.26263809],
              [-65.62930298, 18.26263809],
              [-65.62930298, 18.26236153],
              [-65.62902832, 18.26236153],
              [-65.62902832, 18.26208305],
              [-65.62875366, 18.26208305],
              [-65.62875366, 18.26152802],
              [-65.62819672, 18.26152802],
              [-65.62819672, 18.26097488],
              [-65.62763977, 18.26097488],
              [-65.62763977, 18.25930595],
              [-65.62708282, 18.25930595],
              [-65.62708282, 18.25902748],
              [-65.62680817, 18.25902748],
              [-65.62680817, 18.25847244],
              [-65.62652588, 18.25847244],
              [-65.62652588, 18.25819397],
              [-65.62625122, 18.25819397],
              [-65.62625122, 18.2579174],
              [-65.62596893, 18.2579174],
              [-65.62596893, 18.25764084],
              [-65.62569427, 18.25764084],
              [-65.62569427, 18.25735855],
              [-65.62541962, 18.25735855],
              [-65.62541962, 18.25708389],
              [-65.62513733, 18.25708389],
              [-65.62513733, 18.25680542],
              [-65.62458038, 18.25680542],
              [-65.62458038, 18.25652695],
              [-65.62430573, 18.25652695],
              [-65.62430573, 18.25624847],
              [-65.62403107, 18.25624847],
              [-65.62403107, 18.25597191],
              [-65.62374878, 18.25597191],
              [-65.62374878, 18.25569534],
              [-65.62319183, 18.25569534],
              [-65.62319183, 18.25541878],
              [-65.62236023, 18.25541878],
              [-65.62236023, 18.2551384],
              [-65.62180328, 18.2551384],
              [-65.62180328, 18.25486183],
              [-65.62097168, 18.25486183],
              [-65.62097168, 18.25458336],
              [-65.62041473, 18.25458336],
              [-65.62041473, 18.25486183],
              [-65.61958313, 18.25486183],
              [-65.61958313, 18.2551384],
              [-65.61930847, 18.2551384],
              [-65.61930847, 18.25486183],
              [-65.61846924, 18.25486183],
              [-65.61846924, 18.25458336],
              [-65.61569214, 18.25458336],
              [-65.61569214, 18.25486183],
              [-65.61541748, 18.25486183],
              [-65.61541748, 18.2551384],
              [-65.61486053, 18.2551384],
              [-65.61486053, 18.25541878],
              [-65.61430359, 18.25541878],
              [-65.61430359, 18.25569534],
              [-65.61374664, 18.25569534],
              [-65.61374664, 18.25597191],
              [-65.61319733, 18.25597191],
              [-65.61319733, 18.25624847],
              [-65.61291504, 18.25624847],
              [-65.61291504, 18.25652695],
              [-65.61264038, 18.25652695],
              [-65.61264038, 18.25680542],
              [-65.61180878, 18.25680542],
              [-65.61180878, 18.25708389],
              [-65.61152649, 18.25708389],
              [-65.61152649, 18.25930595],
              [-65.61125183, 18.25930595],
              [-65.61125183, 18.2598629],
              [-65.61096954, 18.2598629],
              [-65.61096954, 18.26013947],
              [-65.61069489, 18.26013947],
              [-65.61069489, 18.26041603],
              [-65.60958099, 18.26041603],
              [-65.60958099, 18.26013947],
              [-65.60847473, 18.26013947],
              [-65.60847473, 18.26041603],
              [-65.60819244, 18.26041603],
              [-65.60819244, 18.2606945],
              [-65.60791779, 18.2606945],
              [-65.60791779, 18.26097488],
              [-65.60736084, 18.26097488],
              [-65.60736084, 18.26124954],
              [-65.6048584, 18.26124954],
              [-65.6048584, 18.26097488],
              [-65.60430908, 18.26097488],
              [-65.60430908, 18.25930595],
              [-65.60458374, 18.25930595],
              [-65.60458374, 18.25902748],
              [-65.60541534, 18.25902748],
              [-65.60541534, 18.25875282],
              [-65.60569763, 18.25875282],
              [-65.60569763, 18.25847244],
              [-65.60597229, 18.25847244],
              [-65.60597229, 18.25708389],
              [-65.60708618, 18.25708389],
              [-65.60708618, 18.25680542],
              [-65.60874939, 18.25680542],
              [-65.60874939, 18.25652695],
              [-65.60902405, 18.25652695],
              [-65.60902405, 18.25624847],
              [-65.60930634, 18.25624847],
              [-65.60930634, 18.25263977],
              [-65.60902405, 18.25263977],
              [-65.60902405, 18.2523613],
              [-65.60874939, 18.2523613],
              [-65.60874939, 18.25124931],
              [-65.60847473, 18.25124931],
              [-65.60847473, 18.25069427],
              [-65.60819244, 18.25069427],
              [-65.60819244, 18.2504158],
              [-65.60791779, 18.2504158],
              [-65.60791779, 18.25013924],
              [-65.6076355, 18.25013924],
              [-65.6076355, 18.2495842],
              [-65.60708618, 18.2495842],
              [-65.60708618, 18.24930573],
              [-65.60680389, 18.24930573],
              [-65.60680389, 18.24902725],
              [-65.60624695, 18.24902725],
              [-65.60624695, 18.2476387],
              [-65.60597229, 18.2476387],
              [-65.60597229, 18.24736023],
              [-65.60569763, 18.24736023],
              [-65.60569763, 18.24680519],
              [-65.60541534, 18.24680519],
              [-65.60541534, 18.24597359],
              [-65.60514069, 18.24597359],
              [-65.60514069, 18.24569511],
              [-65.6048584, 18.24569511],
              [-65.6048584, 18.24541664],
              [-65.60458374, 18.24541664],
              [-65.60458374, 18.2448616],
              [-65.60430908, 18.2448616],
              [-65.60430908, 18.24402809],
              [-65.60402679, 18.24402809],
              [-65.60402679, 18.24375153],
              [-65.60375214, 18.24375153],
              [-65.60375214, 18.24347305],
              [-65.60346985, 18.24347305],
              [-65.60346985, 18.24319458],
              [-65.60319519, 18.24319458],
              [-65.60319519, 18.24263763],
              [-65.6029129, 18.24263763],
              [-65.6029129, 18.24124718],
              [-65.60319519, 18.24124718],
              [-65.60319519, 18.2401371],
              [-65.6029129, 18.2401371],
              [-65.6029129, 18.23958397],
              [-65.60263824, 18.23958397],
              [-65.60263824, 18.23763847],
              [-65.60236359, 18.23763847],
              [-65.60236359, 18.23736191],
              [-65.60180664, 18.23736191],
              [-65.60180664, 18.23708344],
              [-65.60152435, 18.23708344],
              [-65.60152435, 18.2365284],
              [-65.60124969, 18.2365284],
              [-65.60124969, 18.23624992],
              [-65.60069275, 18.23624992],
              [-65.60069275, 18.23597145],
              [-65.59986115, 18.23597145],
              [-65.59986115, 18.23569489],
              [-65.5993042, 18.23569489],
              [-65.5993042, 18.23541641],
              [-65.59902954, 18.23541641],
              [-65.59902954, 18.23513794],
              [-65.59874725, 18.23513794],
              [-65.59874725, 18.23486137],
              [-65.59819794, 18.23486137],
              [-65.59819794, 18.23458481],
              [-65.59791565, 18.23458481],
              [-65.59791565, 18.23430634],
              [-65.59708405, 18.23430634],
              [-65.59708405, 18.23402786],
              [-65.59680176, 18.23402786],
              [-65.59680176, 18.23374939],
              [-65.5965271, 18.23374939],
              [-65.5965271, 18.23347282],
              [-65.59625244, 18.23347282],
              [-65.59625244, 18.23263931],
              [-65.59597015, 18.23263931],
              [-65.59597015, 18.23236275],
              [-65.5956955, 18.23236275],
              [-65.5956955, 18.23208427],
              [-65.59541321, 18.23208427],
              [-65.59541321, 18.2318058],
              [-65.59513855, 18.2318058],
              [-65.59513855, 18.23152733],
              [-65.59486389, 18.23152733],
              [-65.59486389, 18.23125267],
              [-65.5945816, 18.23125267],
              [-65.5945816, 18.23097229],
              [-65.5929184, 18.23097229],
              [-65.5929184, 18.23125267],
              [-65.59208679, 18.23125267],
              [-65.59208679, 18.23041725],
              [-65.5918045, 18.23041725],
              [-65.5918045, 18.23014069],
              [-65.59152985, 18.23014069],
              [-65.59152985, 18.2298584],
              [-65.59041595, 18.2298584],
              [-65.59041595, 18.22958374],
              [-65.5901413, 18.22958374],
              [-65.5901413, 18.22930527],
              [-65.58985901, 18.22930527],
              [-65.58985901, 18.22903061],
              [-65.58958435, 18.22903061],
              [-65.58958435, 18.22874832],
              [-65.58930206, 18.22874832],
              [-65.58930206, 18.22763634],
              [-65.59124756, 18.22763634],
              [-65.59124756, 18.22736168],
              [-65.59152985, 18.22736168],
              [-65.59152985, 18.22708321],
              [-65.5918045, 18.22708321],
              [-65.5918045, 18.2243042],
              [-65.59208679, 18.2243042],
              [-65.59208679, 18.22374916],
              [-65.59402466, 18.22374916],
              [-65.59402466, 18.2243042],
              [-65.5945816, 18.2243042],
              [-65.5945816, 18.22458267],
              [-65.59486389, 18.22458267],
              [-65.59486389, 18.22486115],
              [-65.59513855, 18.22486115],
              [-65.59513855, 18.22541618],
              [-65.59597015, 18.22541618],
              [-65.59597015, 18.22569466],
              [-65.59625244, 18.22569466],
              [-65.59625244, 18.22597313],
              [-65.5965271, 18.22597313],
              [-65.5965271, 18.22624969],
              [-65.59680176, 18.22624969],
              [-65.59680176, 18.22652626],
              [-65.59708405, 18.22652626],
              [-65.59708405, 18.22680473],
              [-65.5973587, 18.22680473],
              [-65.5973587, 18.22708321],
              [-65.59764099, 18.22708321],
              [-65.59764099, 18.22736168],
              [-65.59874725, 18.22736168],
              [-65.59874725, 18.22763634],
              [-65.59958649, 18.22763634],
              [-65.59958649, 18.22791862],
              [-65.59986115, 18.22791862],
              [-65.59986115, 18.22819519],
              [-65.60041809, 18.22819519],
              [-65.60041809, 18.22847176],
              [-65.60069275, 18.22847176],
              [-65.60069275, 18.22874832],
              [-65.60097504, 18.22874832],
              [-65.60097504, 18.22958374],
              [-65.60152435, 18.22958374],
              [-65.60152435, 18.2298584],
              [-65.60180664, 18.2298584],
              [-65.60180664, 18.23014069],
              [-65.6020813, 18.23014069],
              [-65.6020813, 18.2298584],
              [-65.6029129, 18.2298584],
              [-65.6029129, 18.22958374],
              [-65.60319519, 18.22958374],
              [-65.60319519, 18.22930527],
              [-65.60346985, 18.22930527],
              [-65.60346985, 18.22874832],
              [-65.60375214, 18.22874832],
              [-65.60375214, 18.22819519],
              [-65.60430908, 18.22819519],
              [-65.60430908, 18.22791862],
              [-65.60458374, 18.22791862],
              [-65.60458374, 18.22763634],
              [-65.6048584, 18.22763634],
              [-65.6048584, 18.22736168],
              [-65.60514069, 18.22736168],
              [-65.60514069, 18.22652626],
              [-65.60541534, 18.22652626],
              [-65.60541534, 18.22597313],
              [-65.60569763, 18.22597313],
              [-65.60569763, 18.22569466],
              [-65.60597229, 18.22569466],
              [-65.60597229, 18.22513962],
              [-65.60569763, 18.22513962],
              [-65.60569763, 18.2243042],
              [-65.60597229, 18.2243042],
              [-65.60597229, 18.22402763],
              [-65.60569763, 18.22402763],
              [-65.60569763, 18.22319412],
              [-65.60541534, 18.22319412],
              [-65.60541534, 18.22208214],
              [-65.60514069, 18.22208214],
              [-65.60514069, 18.2215271],
              [-65.60430908, 18.2215271],
              [-65.60430908, 18.22125053],
              [-65.60402679, 18.22125053],
              [-65.60402679, 18.22097206],
              [-65.60375214, 18.22097206],
              [-65.60375214, 18.21958351],
              [-65.60402679, 18.21958351],
              [-65.60402679, 18.21736145],
              [-65.60458374, 18.21736145],
              [-65.60458374, 18.21708107],
              [-65.60514069, 18.21708107],
              [-65.60514069, 18.21680641],
              [-65.60569763, 18.21680641],
              [-65.60569763, 18.21736145],
              [-65.60652924, 18.21736145],
              [-65.60652924, 18.21680641],
              [-65.60680389, 18.21680641],
              [-65.60680389, 18.21652985],
              [-65.60708618, 18.21652985],
              [-65.60708618, 18.21569443],
              [-65.60680389, 18.21569443],
              [-65.60680389, 18.21513748],
              [-65.60652924, 18.21513748],
              [-65.60652924, 18.21486092],
              [-65.60624695, 18.21486092],
              [-65.60624695, 18.21430779],
              [-65.60569763, 18.21430779],
              [-65.60569763, 18.2140274],
              [-65.6048584, 18.2140274],
              [-65.6048584, 18.21375084],
              [-65.60180664, 18.21375084],
              [-65.60180664, 18.21347237],
              [-65.59902954, 18.21347237],
              [-65.59902954, 18.21319389],
              [-65.5984726, 18.21319389],
              [-65.5984726, 18.21291542],
              [-65.59819794, 18.21291542],
              [-65.59819794, 18.21097183],
              [-65.5984726, 18.21097183],
              [-65.5984726, 18.21041679],
              [-65.60041809, 18.21041679],
              [-65.60041809, 18.21097183],
              [-65.60069275, 18.21097183],
              [-65.60069275, 18.21125031],
              [-65.60152435, 18.21125031],
              [-65.60152435, 18.21152687],
              [-65.6020813, 18.21152687],
              [-65.6020813, 18.21041679],
              [-65.60458374, 18.21041679],
              [-65.60458374, 18.21097183],
              [-65.6048584, 18.21097183],
              [-65.6048584, 18.21125031],
              [-65.60541534, 18.21125031],
              [-65.60541534, 18.21208382],
              [-65.60514069, 18.21208382],
              [-65.60514069, 18.21291542],
              [-65.60541534, 18.21291542],
              [-65.60541534, 18.21319389],
              [-65.60597229, 18.21319389],
              [-65.60597229, 18.21347237],
              [-65.60624695, 18.21347237],
              [-65.60624695, 18.21375084],
              [-65.60652924, 18.21375084],
              [-65.60652924, 18.2140274],
              [-65.60708618, 18.2140274],
              [-65.60708618, 18.21430779],
              [-65.60736084, 18.21430779],
              [-65.60736084, 18.21458244],
              [-65.60791779, 18.21458244],
              [-65.60791779, 18.21486092],
              [-65.60874939, 18.21486092],
              [-65.60874939, 18.21458244],
              [-65.60958099, 18.21458244],
              [-65.60958099, 18.21430779],
              [-65.60986328, 18.21430779],
              [-65.60986328, 18.21180534],
              [-65.61013794, 18.21180534],
              [-65.61013794, 18.21097183],
              [-65.61125183, 18.21097183],
              [-65.61125183, 18.21125031],
              [-65.61152649, 18.21125031],
              [-65.61152649, 18.21180534],
            ],
          ],
          [
            [
              [-65.58569336, 18.25486183],
              [-65.58569336, 18.25347137],
              [-65.5854187, 18.25347137],
              [-65.5854187, 18.25291634],
              [-65.58513641, 18.25291634],
              [-65.58513641, 18.2523613],
              [-65.58486176, 18.2523613],
              [-65.58486176, 18.25208282],
              [-65.5845871, 18.25208282],
              [-65.5845871, 18.25180435],
              [-65.58430481, 18.25180435],
              [-65.58430481, 18.25097275],
              [-65.58403015, 18.25097275],
              [-65.58403015, 18.2504158],
              [-65.58374786, 18.2504158],
              [-65.58374786, 18.25013924],
              [-65.58347321, 18.25013924],
              [-65.58347321, 18.2495842],
              [-65.58319092, 18.2495842],
              [-65.58319092, 18.24902725],
              [-65.58291626, 18.24902725],
              [-65.58291626, 18.24791718],
              [-65.58347321, 18.24791718],
              [-65.58347321, 18.24819374],
              [-65.58374786, 18.24819374],
              [-65.58374786, 18.24847221],
              [-65.58403015, 18.24847221],
              [-65.58403015, 18.24819374],
              [-65.58486176, 18.24819374],
              [-65.58486176, 18.24791718],
              [-65.58513641, 18.24791718],
              [-65.58513641, 18.2476387],
              [-65.58708191, 18.2476387],
              [-65.58708191, 18.24736023],
              [-65.5881958, 18.24736023],
              [-65.5881958, 18.2476387],
              [-65.58875275, 18.2476387],
              [-65.58875275, 18.24791718],
              [-65.58985901, 18.24791718],
              [-65.58985901, 18.24819374],
              [-65.5901413, 18.24819374],
              [-65.5901413, 18.24847221],
              [-65.59069824, 18.24847221],
              [-65.59069824, 18.24875069],
              [-65.59124756, 18.24875069],
              [-65.59124756, 18.24902725],
              [-65.59152985, 18.24902725],
              [-65.59152985, 18.24930573],
              [-65.5929184, 18.24930573],
              [-65.5929184, 18.24902725],
              [-65.59513855, 18.24902725],
              [-65.59513855, 18.2495842],
              [-65.5956955, 18.2495842],
              [-65.5956955, 18.24986076],
              [-65.59708405, 18.24986076],
              [-65.59708405, 18.24902725],
              [-65.59680176, 18.24902725],
              [-65.59680176, 18.24875069],
              [-65.5965271, 18.24875069],
              [-65.5965271, 18.24847221],
              [-65.59597015, 18.24847221],
              [-65.59597015, 18.2476387],
              [-65.59708405, 18.2476387],
              [-65.59708405, 18.24736023],
              [-65.59764099, 18.24736023],
              [-65.59764099, 18.24708366],
              [-65.5984726, 18.24708366],
              [-65.5984726, 18.2476387],
              [-65.59874725, 18.2476387],
              [-65.59874725, 18.24819374],
              [-65.59902954, 18.24819374],
              [-65.59902954, 18.24902725],
              [-65.59874725, 18.24902725],
              [-65.59874725, 18.2495842],
              [-65.5984726, 18.2495842],
              [-65.5984726, 18.25180435],
              [-65.59819794, 18.25180435],
              [-65.59819794, 18.25208282],
              [-65.59874725, 18.25208282],
              [-65.59874725, 18.2523613],
              [-65.59902954, 18.2523613],
              [-65.59902954, 18.25263977],
              [-65.5993042, 18.25263977],
              [-65.5993042, 18.25291634],
              [-65.59958649, 18.25291634],
              [-65.59958649, 18.25374985],
              [-65.59986115, 18.25374985],
              [-65.59986115, 18.2551384],
              [-65.5984726, 18.2551384],
              [-65.5984726, 18.25486183],
              [-65.5965271, 18.25486183],
              [-65.5965271, 18.2551384],
              [-65.5945816, 18.2551384],
              [-65.5945816, 18.25541878],
              [-65.59402466, 18.25541878],
              [-65.59402466, 18.25569534],
              [-65.5918045, 18.25569534],
              [-65.5918045, 18.25541878],
              [-65.59124756, 18.25541878],
              [-65.59124756, 18.2551384],
              [-65.5909729, 18.2551384],
              [-65.5909729, 18.25458336],
              [-65.59069824, 18.25458336],
              [-65.59069824, 18.25430489],
              [-65.59041595, 18.25430489],
              [-65.59041595, 18.25402641],
              [-65.58985901, 18.25402641],
              [-65.58985901, 18.25374985],
              [-65.58930206, 18.25374985],
              [-65.58930206, 18.25347137],
              [-65.5881958, 18.25347137],
              [-65.5881958, 18.25319481],
              [-65.58791351, 18.25319481],
              [-65.58791351, 18.25291634],
              [-65.58763885, 18.25291634],
              [-65.58763885, 18.25263977],
              [-65.5873642, 18.25263977],
              [-65.5873642, 18.25291634],
              [-65.58708191, 18.25291634],
              [-65.58708191, 18.25319481],
              [-65.5873642, 18.25319481],
              [-65.5873642, 18.25541878],
              [-65.58708191, 18.25541878],
              [-65.58708191, 18.25569534],
              [-65.58680725, 18.25569534],
              [-65.58680725, 18.25597191],
              [-65.58625031, 18.25597191],
              [-65.58625031, 18.25652695],
              [-65.58597565, 18.25652695],
              [-65.58597565, 18.25680542],
              [-65.58513641, 18.25680542],
              [-65.58513641, 18.25541878],
              [-65.5854187, 18.25541878],
              [-65.5854187, 18.25486183],
              [-65.58569336, 18.25486183],
            ],
          ],
          [
            [
              [-65.57569122, 18.25013924],
              [-65.57569122, 18.24986076],
              [-65.57541656, 18.24986076],
              [-65.57541656, 18.24819374],
              [-65.57569122, 18.24819374],
              [-65.57569122, 18.2476387],
              [-65.57597351, 18.2476387],
              [-65.57597351, 18.24736023],
              [-65.57653046, 18.24736023],
              [-65.57653046, 18.24708366],
              [-65.57680511, 18.24708366],
              [-65.57680511, 18.24680519],
              [-65.57707977, 18.24680519],
              [-65.57707977, 18.24625015],
              [-65.58013916, 18.24625015],
              [-65.58013916, 18.24652863],
              [-65.58041382, 18.24652863],
              [-65.58041382, 18.24680519],
              [-65.58069611, 18.24680519],
              [-65.58069611, 18.2476387],
              [-65.58013916, 18.2476387],
              [-65.58013916, 18.24791718],
              [-65.57958221, 18.24791718],
              [-65.57958221, 18.24819374],
              [-65.57902527, 18.24819374],
              [-65.57902527, 18.24847221],
              [-65.57875061, 18.24847221],
              [-65.57875061, 18.24902725],
              [-65.57847595, 18.24902725],
              [-65.57847595, 18.2495842],
              [-65.57791901, 18.2495842],
              [-65.57791901, 18.24986076],
              [-65.57736206, 18.24986076],
              [-65.57736206, 18.2495842],
              [-65.57653046, 18.2495842],
              [-65.57653046, 18.24986076],
              [-65.57597351, 18.24986076],
              [-65.57597351, 18.25013924],
              [-65.57569122, 18.25013924],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 19,
        name: 'Ceiba',
        hasc_1: 'PR.CB',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-65.76694489, 18.19791412, -65.57541656, 18.2970047],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.20',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.45559692, 18.25759125],
              [-66.45465088, 18.25378036],
              [-66.45464325, 18.25187683],
              [-66.45417786, 18.25092316],
              [-66.46387482, 18.24706078],
              [-66.46525574, 18.24657631],
              [-66.46664429, 18.24609184],
              [-66.46941376, 18.24607849],
              [-66.4740448, 18.24796295],
              [-66.47776031, 18.25080109],
              [-66.48285675, 18.25268745],
              [-66.48609924, 18.25314522],
              [-66.49210358, 18.25263405],
              [-66.49256897, 18.25263214],
              [-66.49719238, 18.25213051],
              [-66.49765778, 18.25308037],
              [-66.50091553, 18.25688171],
              [-66.50137329, 18.25639915],
              [-66.50827026, 18.24873161],
              [-66.51239777, 18.24251366],
              [-66.51194, 18.24251556],
              [-66.50960541, 18.2387104],
              [-66.50727844, 18.23490906],
              [-66.50955963, 18.2296524],
              [-66.51325989, 18.23010826],
              [-66.5178833, 18.23008347],
              [-66.5178833, 18.22960663],
              [-66.51740265, 18.22722435],
              [-66.51692963, 18.2238884],
              [-66.51503754, 18.21627235],
              [-66.51223755, 18.2110405],
              [-66.51124573, 18.19912529],
              [-66.51124573, 18.19816971],
              [-66.51445007, 18.19195366],
              [-66.51113892, 18.17814064],
              [-66.51067352, 18.17718887],
              [-66.51200867, 18.16716576],
              [-66.51200867, 18.16669273],
              [-66.51292419, 18.16573143],
              [-66.51984406, 18.16378593],
              [-66.52306366, 18.16138077],
              [-66.5290451, 18.15704918],
              [-66.53830719, 18.16033745],
              [-66.54247284, 18.16269875],
              [-66.54294586, 18.16317368],
              [-66.54158783, 18.16890335],
              [-66.53839111, 18.1760788],
              [-66.53285217, 18.17658806],
              [-66.53239441, 18.17706871],
              [-66.53102112, 18.18041039],
              [-66.53661346, 18.18800926],
              [-66.53295898, 18.19709396],
              [-66.53766632, 18.21280289],
              [-66.54927063, 18.22084045],
              [-66.55302429, 18.23035622],
              [-66.55537415, 18.23749352],
              [-66.56232452, 18.24031067],
              [-66.56278992, 18.2407856],
              [-66.56465149, 18.2426815],
              [-66.56420135, 18.24411583],
              [-66.56284332, 18.2498455],
              [-66.56240082, 18.25366211],
              [-66.56147766, 18.25414658],
              [-66.55593109, 18.25370026],
              [-66.55593109, 18.25418091],
              [-66.55593872, 18.2556076],
              [-66.55555725, 18.2694397],
              [-66.55836487, 18.2756176],
              [-66.5579071, 18.27657127],
              [-66.55469513, 18.28136063],
              [-66.56078339, 18.29371834],
              [-66.56682587, 18.29844856],
              [-66.57289886, 18.3088932],
              [-66.58172607, 18.31408501],
              [-66.58312225, 18.31645966],
              [-66.58407593, 18.32026672],
              [-66.58501434, 18.32264709],
              [-66.59473419, 18.32353401],
              [-66.610466, 18.32247925],
              [-66.61138916, 18.32247162],
              [-66.60679626, 18.32822227],
              [-66.60404205, 18.3315773],
              [-66.60084534, 18.33874702],
              [-66.60038757, 18.33922768],
              [-66.59945679, 18.33922958],
              [-66.59343719, 18.33831978],
              [-66.59204865, 18.33785248],
              [-66.59065247, 18.33786011],
              [-66.57448578, 18.34225082],
              [-66.57309723, 18.3427372],
              [-66.55966187, 18.34090996],
              [-66.55179596, 18.34143829],
              [-66.53609467, 18.34819984],
              [-66.53380585, 18.35154915],
              [-66.53102112, 18.35014153],
              [-66.52870178, 18.35015106],
              [-66.52317047, 18.35494423],
              [-66.51625824, 18.35927391],
              [-66.51163483, 18.36215973],
              [-66.49638367, 18.36605644],
              [-66.49036407, 18.36560822],
              [-66.47740173, 18.36663246],
              [-66.47323608, 18.36760712],
              [-66.4713974, 18.36904526],
              [-66.46678162, 18.37192917],
              [-66.46307373, 18.37147331],
              [-66.46440887, 18.36097717],
              [-66.46483612, 18.35240173],
              [-66.46389771, 18.34954834],
              [-66.46342468, 18.34811974],
              [-66.46109009, 18.34431839],
              [-66.45455933, 18.33291245],
              [-66.45407104, 18.32814789],
              [-66.45446777, 18.31289482],
              [-66.45487976, 18.30192947],
              [-66.45487213, 18.30049896],
              [-66.45487213, 18.30002213],
              [-66.45625305, 18.3000164],
              [-66.45718384, 18.30001068],
              [-66.46134949, 18.29951286],
              [-66.46496582, 18.28328705],
              [-66.46450043, 18.28281593],
              [-66.46125793, 18.2804451],
              [-66.46031952, 18.27854347],
              [-66.46031952, 18.2775898],
              [-66.46123505, 18.27663231],
              [-66.45935059, 18.26948929],
              [-66.45792389, 18.26091957],
              [-66.45884705, 18.25995445],
              [-66.45790863, 18.25805473],
              [-66.45559692, 18.25759125],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 20,
        name: 'Ciales',
        hasc_1: 'PR.CL',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.61138916, 18.15704918, -66.45407104, 18.37192917],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.22',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.4269104, 18.04259872],
              [-66.42645264, 18.04355431],
              [-66.42556, 18.05024147],
              [-66.42418671, 18.05263329],
              [-66.43161774, 18.06357384],
              [-66.42978668, 18.06596756],
              [-66.42749786, 18.07170677],
              [-66.43031311, 18.08219147],
              [-66.430336, 18.08695984],
              [-66.42897034, 18.09126091],
              [-66.42758942, 18.09269905],
              [-66.42160797, 18.09750175],
              [-66.43045807, 18.11510849],
              [-66.42453003, 18.13231087],
              [-66.42131805, 18.13614464],
              [-66.41950989, 18.14569283],
              [-66.41997528, 18.14711952],
              [-66.42925262, 18.15661621],
              [-66.42928314, 18.16329575],
              [-66.42883301, 18.16473007],
              [-66.43903351, 18.17279053],
              [-66.43995667, 18.17326164],
              [-66.44319916, 18.17563248],
              [-66.44459534, 18.17658234],
              [-66.43997192, 18.17612267],
              [-66.4358139, 18.17566872],
              [-66.42378235, 18.17190742],
              [-66.42285156, 18.17143631],
              [-66.42192078, 18.17000961],
              [-66.42191315, 18.16810226],
              [-66.42098236, 18.16619682],
              [-66.41820526, 18.16477966],
              [-66.41358185, 18.16337013],
              [-66.40988159, 18.16290665],
              [-66.40573883, 18.16578865],
              [-66.40110779, 18.16485596],
              [-66.39556885, 18.16535759],
              [-66.39187622, 18.16632652],
              [-66.38912964, 18.17206383],
              [-66.37802887, 18.17258453],
              [-66.37480927, 18.17498207],
              [-66.37296295, 18.17546463],
              [-66.36786652, 18.17214966],
              [-66.36461639, 18.16977692],
              [-66.35120392, 18.16553879],
              [-66.3493576, 18.16601753],
              [-66.34659576, 18.16745949],
              [-66.34243774, 18.16795349],
              [-66.34197235, 18.1679554],
              [-66.33088684, 18.16847229],
              [-66.32672119, 18.16610336],
              [-66.32209778, 18.16421127],
              [-66.32299805, 18.15705109],
              [-66.31604767, 18.15230751],
              [-66.31465912, 18.15183449],
              [-66.31693268, 18.13990021],
              [-66.31277466, 18.13800812],
              [-66.31277466, 18.13752937],
              [-66.31322479, 18.13609886],
              [-66.32058716, 18.12605667],
              [-66.31085968, 18.11845589],
              [-66.29512787, 18.10610199],
              [-66.27708435, 18.09566307],
              [-66.27477264, 18.0932827],
              [-66.26921082, 18.08614159],
              [-66.26274872, 18.08568573],
              [-66.26043701, 18.08330536],
              [-66.25997162, 18.08282852],
              [-66.25764465, 18.07615662],
              [-66.25810242, 18.07472229],
              [-66.25948334, 18.07376671],
              [-66.26686096, 18.06992912],
              [-66.26916504, 18.06896591],
              [-66.27147675, 18.06895828],
              [-66.278862, 18.06941414],
              [-66.28024292, 18.06797791],
              [-66.28299713, 18.06319809],
              [-66.28529358, 18.05937386],
              [-66.29774475, 18.05694771],
              [-66.29866791, 18.05646706],
              [-66.3037262, 18.05072403],
              [-66.30971527, 18.04784012],
              [-66.31293488, 18.04353523],
              [-66.31292725, 18.04257965],
              [-66.31243896, 18.03351402],
              [-66.31519318, 18.02968788],
              [-66.3188858, 18.02967644],
              [-66.3312912, 18.01483345],
              [-66.33408356, 18.02055359],
              [-66.33547211, 18.02198219],
              [-66.3359375, 18.02293205],
              [-66.34054565, 18.02291679],
              [-66.34516907, 18.02480888],
              [-66.34840393, 18.02670479],
              [-66.35164642, 18.03146362],
              [-66.3562851, 18.03669357],
              [-66.36872864, 18.03378105],
              [-66.37149811, 18.03281784],
              [-66.37657166, 18.03422737],
              [-66.37750244, 18.03470039],
              [-66.38628387, 18.03991318],
              [-66.38813019, 18.04038239],
              [-66.39504242, 18.03748894],
              [-66.4269104, 18.04259872],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 22,
        name: 'Coamo',
        hasc_1: 'PR.CO',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.44459534, 18.01483345, -66.25764465, 18.17658234],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.23',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.24082947, 18.1844902],
              [-66.24269867, 18.19164085],
              [-66.24408722, 18.19354439],
              [-66.24824524, 18.1916256],
              [-66.25054932, 18.18923187],
              [-66.25377655, 18.18779373],
              [-66.25562286, 18.18540573],
              [-66.25561523, 18.18397331],
              [-66.25653839, 18.18254089],
              [-66.26347351, 18.18538284],
              [-66.26396942, 18.19634819],
              [-66.2644577, 18.20541191],
              [-66.27002716, 18.2149334],
              [-66.27095795, 18.21731377],
              [-66.27050018, 18.21874619],
              [-66.27050018, 18.21922302],
              [-66.26545715, 18.23354149],
              [-66.26824951, 18.24116135],
              [-66.26641083, 18.2454586],
              [-66.2627182, 18.24642181],
              [-66.25531769, 18.24739647],
              [-66.2479248, 18.24836922],
              [-66.24468994, 18.24933243],
              [-66.2419281, 18.25458527],
              [-66.23499298, 18.25507927],
              [-66.22851563, 18.25366592],
              [-66.22805023, 18.25414658],
              [-66.22759247, 18.25462341],
              [-66.22344971, 18.26464272],
              [-66.21977234, 18.27275848],
              [-66.21931458, 18.274189],
              [-66.21792603, 18.27466774],
              [-66.20636749, 18.27660179],
              [-66.20313263, 18.27660942],
              [-66.20220184, 18.27661133],
              [-66.20127869, 18.2761364],
              [-66.20081329, 18.27566147],
              [-66.19988251, 18.27423286],
              [-66.20034027, 18.27137375],
              [-66.19339752, 18.26757431],
              [-66.19060516, 18.25852394],
              [-66.18828583, 18.25566483],
              [-66.17809296, 18.2451973],
              [-66.1780777, 18.23518181],
              [-66.17621613, 18.22946358],
              [-66.16880798, 18.22423363],
              [-66.16972351, 18.21755791],
              [-66.17018127, 18.21660233],
              [-66.17203522, 18.21564484],
              [-66.17849731, 18.21277046],
              [-66.18449402, 18.20703506],
              [-66.18495178, 18.20131302],
              [-66.18587494, 18.20083427],
              [-66.19649506, 18.19795036],
              [-66.20018768, 18.19698715],
              [-66.20666504, 18.19697571],
              [-66.20896149, 18.19363213],
              [-66.21310425, 18.18599129],
              [-66.21356964, 18.18360329],
              [-66.21123505, 18.17407417],
              [-66.21169281, 18.17311668],
              [-66.21400452, 18.17311287],
              [-66.22371674, 18.17547607],
              [-66.22787476, 18.17784691],
              [-66.2297287, 18.18070221],
              [-66.23066711, 18.18451691],
              [-66.24082947, 18.1844902],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 23,
        name: 'Comerío',
        hasc_1: 'PR.CM',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.27095795, 18.17311287, -66.16880798, 18.27661133],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.24',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.2782135, 18.32979965],
              [-66.28507996, 18.30404091],
              [-66.28643799, 18.29355049],
              [-66.28730011, 18.27304649],
              [-66.29373932, 18.26063347],
              [-66.30619812, 18.25248718],
              [-66.30850983, 18.25104523],
              [-66.31312561, 18.24912643],
              [-66.31867218, 18.24815178],
              [-66.32607269, 18.24765015],
              [-66.32698059, 18.24431038],
              [-66.33621216, 18.23950768],
              [-66.34037018, 18.23854065],
              [-66.35101318, 18.24136162],
              [-66.35194397, 18.24230957],
              [-66.35797119, 18.24657822],
              [-66.36216736, 18.25705147],
              [-66.36402893, 18.25942993],
              [-66.37193298, 18.26940918],
              [-66.37610626, 18.27320671],
              [-66.37702942, 18.27367783],
              [-66.38258362, 18.27413177],
              [-66.38304901, 18.27508163],
              [-66.38260651, 18.27937508],
              [-66.38076782, 18.28224373],
              [-66.38031006, 18.28367615],
              [-66.37846375, 18.28463554],
              [-66.37754822, 18.28607178],
              [-66.3780365, 18.29369736],
              [-66.3780365, 18.29417229],
              [-66.37574005, 18.29799461],
              [-66.3734436, 18.30134201],
              [-66.37297821, 18.30229568],
              [-66.37254333, 18.3089695],
              [-66.37208557, 18.31040382],
              [-66.36843109, 18.32185745],
              [-66.36569214, 18.33187675],
              [-66.36523437, 18.33330917],
              [-66.35874176, 18.33047485],
              [-66.35736847, 18.3347683],
              [-66.35785675, 18.34144402],
              [-66.35739899, 18.34382439],
              [-66.35648346, 18.34478188],
              [-66.35601807, 18.34573746],
              [-66.3532486, 18.34765434],
              [-66.35186768, 18.34861183],
              [-66.35140228, 18.34908867],
              [-66.34909058, 18.34957886],
              [-66.34863281, 18.35053062],
              [-66.34909821, 18.35148239],
              [-66.35095215, 18.35338211],
              [-66.35466766, 18.35479736],
              [-66.35513306, 18.35574913],
              [-66.35513306, 18.3567009],
              [-66.35514832, 18.35956001],
              [-66.35514832, 18.36051559],
              [-66.35469055, 18.36098862],
              [-66.353302, 18.36146927],
              [-66.35005951, 18.36100578],
              [-66.34680939, 18.36006546],
              [-66.34403229, 18.35769844],
              [-66.34078979, 18.35770607],
              [-66.3403244, 18.35818863],
              [-66.34125519, 18.36008835],
              [-66.34126282, 18.3619976],
              [-66.33943176, 18.36629105],
              [-66.33896637, 18.36724663],
              [-66.33850861, 18.36772346],
              [-66.33805084, 18.36820221],
              [-66.3361969, 18.36820793],
              [-66.3334198, 18.36821747],
              [-66.32971191, 18.36775398],
              [-66.31859589, 18.36731529],
              [-66.31535339, 18.36590195],
              [-66.31256866, 18.36447906],
              [-66.31118011, 18.36448669],
              [-66.30794525, 18.36497116],
              [-66.30702209, 18.36592674],
              [-66.304245, 18.36736488],
              [-66.30331421, 18.36641693],
              [-66.29589081, 18.35976791],
              [-66.29403687, 18.35881996],
              [-66.29124451, 18.35597229],
              [-66.28985596, 18.35502434],
              [-66.28845978, 18.3531208],
              [-66.2870636, 18.35074425],
              [-66.28473663, 18.34598351],
              [-66.28380585, 18.34407806],
              [-66.27775574, 18.33075333],
              [-66.2782135, 18.32979965],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 24,
        name: 'Corozal',
        hasc_1: 'PR.CZ',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.38304901, 18.23854065, -66.27775574, 18.36821747],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.26',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.31490326, 18.47458458],
              [-66.31263733, 18.47458458],
              [-66.31263733, 18.47486115],
              [-66.31208038, 18.47486115],
              [-66.31208038, 18.47513962],
              [-66.31180573, 18.47513962],
              [-66.31180573, 18.47541618],
              [-66.31124878, 18.47541618],
              [-66.31124878, 18.47513962],
              [-66.31069183, 18.47513962],
              [-66.31069183, 18.47430611],
              [-66.31097412, 18.47430611],
              [-66.31097412, 18.47319412],
              [-66.31069183, 18.47319412],
              [-66.31069183, 18.47291756],
              [-66.31041718, 18.47291756],
              [-66.31041718, 18.47236252],
              [-66.31014252, 18.47236252],
              [-66.31014252, 18.47124863],
              [-66.31041718, 18.47124863],
              [-66.31041718, 18.47041893],
              [-66.31014252, 18.47041893],
              [-66.31014252, 18.47013855],
              [-66.30986023, 18.47013855],
              [-66.30986023, 18.47041893],
              [-66.30958557, 18.47041893],
              [-66.30958557, 18.47069359],
              [-66.30930328, 18.47069359],
              [-66.30930328, 18.47124863],
              [-66.30902863, 18.47124863],
              [-66.30902863, 18.4715271],
              [-66.30625153, 18.4715271],
              [-66.30625153, 18.47124863],
              [-66.30541992, 18.47124863],
              [-66.30541992, 18.47263908],
              [-66.30569458, 18.47263908],
              [-66.30569458, 18.47291756],
              [-66.30596924, 18.47291756],
              [-66.30596924, 18.47319412],
              [-66.30625153, 18.47319412],
              [-66.30625153, 18.4734726],
              [-66.30652618, 18.4734726],
              [-66.30652618, 18.47374916],
              [-66.30708313, 18.47374916],
              [-66.30708313, 18.47402763],
              [-66.30764008, 18.47402763],
              [-66.30764008, 18.47430611],
              [-66.30791473, 18.47430611],
              [-66.30791473, 18.47458458],
              [-66.30847168, 18.47458458],
              [-66.30847168, 18.47486115],
              [-66.30902863, 18.47486115],
              [-66.30902863, 18.47513962],
              [-66.30930328, 18.47513962],
              [-66.30930328, 18.47597313],
              [-66.30819702, 18.47597313],
              [-66.30819702, 18.47569466],
              [-66.30764008, 18.47569466],
              [-66.30764008, 18.47541618],
              [-66.30708313, 18.47541618],
              [-66.30708313, 18.47513962],
              [-66.30513763, 18.47513962],
              [-66.30513763, 18.47486115],
              [-66.30486298, 18.47486115],
              [-66.30486298, 18.47513962],
              [-66.30430603, 18.47513962],
              [-66.30430603, 18.47541618],
              [-66.30208588, 18.47541618],
              [-66.30208588, 18.47569466],
              [-66.30152893, 18.47569466],
              [-66.30152893, 18.47597313],
              [-66.30124664, 18.47597313],
              [-66.30124664, 18.47624969],
              [-66.30069733, 18.47624969],
              [-66.30069733, 18.47652817],
              [-66.30014038, 18.47652817],
              [-66.30014038, 18.47680473],
              [-66.29958344, 18.47680473],
              [-66.29958344, 18.47708321],
              [-66.29930878, 18.47708321],
              [-66.29930878, 18.47736168],
              [-66.29902649, 18.47736168],
              [-66.29902649, 18.47708321],
              [-66.29875183, 18.47708321],
              [-66.29875183, 18.47680473],
              [-66.29819489, 18.47680473],
              [-66.29819489, 18.47652817],
              [-66.29792023, 18.47652817],
              [-66.29792023, 18.47624969],
              [-66.29736328, 18.47624969],
              [-66.29736328, 18.47597313],
              [-66.29708099, 18.47597313],
              [-66.29708099, 18.47569466],
              [-66.29680634, 18.47569466],
              [-66.29680634, 18.47513962],
              [-66.29624939, 18.47513962],
              [-66.29624939, 18.47486115],
              [-66.29597473, 18.47486115],
              [-66.29597473, 18.47458458],
              [-66.29541779, 18.47458458],
              [-66.29541779, 18.47430611],
              [-66.2951355, 18.47430611],
              [-66.2951355, 18.47402763],
              [-66.29486084, 18.47402763],
              [-66.29486084, 18.47374916],
              [-66.29458618, 18.47374916],
              [-66.29458618, 18.4734726],
              [-66.29402924, 18.4734726],
              [-66.29402924, 18.47319412],
              [-66.29319763, 18.47319412],
              [-66.29319763, 18.47291756],
              [-66.2923584, 18.47291756],
              [-66.2923584, 18.47263908],
              [-66.29152679, 18.47263908],
              [-66.29152679, 18.47236252],
              [-66.29125214, 18.47236252],
              [-66.29125214, 18.47208405],
              [-66.29013824, 18.47208405],
              [-66.29013824, 18.47180557],
              [-66.2859726, 18.47180557],
              [-66.2859726, 18.4715271],
              [-66.28541565, 18.4715271],
              [-66.28541565, 18.47124863],
              [-66.28458405, 18.47124863],
              [-66.28458405, 18.47180557],
              [-66.28430176, 18.47180557],
              [-66.28430176, 18.47208405],
              [-66.2840271, 18.47208405],
              [-66.2840271, 18.47236252],
              [-66.2831955, 18.47236252],
              [-66.2831955, 18.47263908],
              [-66.28263855, 18.47263908],
              [-66.28263855, 18.47291756],
              [-66.28236389, 18.47291756],
              [-66.28236389, 18.47374916],
              [-66.28180695, 18.47374916],
              [-66.28180695, 18.47402763],
              [-66.28152466, 18.47402763],
              [-66.28152466, 18.47430611],
              [-66.28125, 18.47430611],
              [-66.28125, 18.47458458],
              [-66.28013611, 18.47458458],
              [-66.28013611, 18.47486115],
              [-66.2793045, 18.47486115],
              [-66.2793045, 18.47513962],
              [-66.27874756, 18.47513962],
              [-66.27874756, 18.47541618],
              [-66.2784729, 18.47541618],
              [-66.2784729, 18.47569466],
              [-66.27819824, 18.47569466],
              [-66.27819824, 18.47624969],
              [-66.27791595, 18.47624969],
              [-66.27791595, 18.47652817],
              [-66.2776413, 18.47652817],
              [-66.2776413, 18.47680473],
              [-66.27708435, 18.47680473],
              [-66.27708435, 18.47708321],
              [-66.27680206, 18.47708321],
              [-66.27680206, 18.47763824],
              [-66.2765274, 18.47763824],
              [-66.2765274, 18.47819519],
              [-66.27625275, 18.47819519],
              [-66.27625275, 18.47847176],
              [-66.2756958, 18.47847176],
              [-66.2756958, 18.47875023],
              [-66.27541351, 18.47875023],
              [-66.27541351, 18.4790287],
              [-66.2748642, 18.4790287],
              [-66.2748642, 18.47930527],
              [-66.27430725, 18.47930527],
              [-66.27430725, 18.4790287],
              [-66.27347565, 18.4790287],
              [-66.27347565, 18.47875023],
              [-66.27263641, 18.47875023],
              [-66.27263641, 18.4790287],
              [-66.27180481, 18.4790287],
              [-66.27180481, 18.47930527],
              [-66.27153015, 18.47930527],
              [-66.27153015, 18.47958374],
              [-66.27124786, 18.47958374],
              [-66.27124786, 18.47986031],
              [-66.27097321, 18.47986031],
              [-66.27097321, 18.48013878],
              [-66.27069092, 18.48013878],
              [-66.27069092, 18.48041725],
              [-66.27041626, 18.48041725],
              [-66.27041626, 18.48069382],
              [-66.26985931, 18.48069382],
              [-66.26985931, 18.48041725],
              [-66.26902771, 18.48041725],
              [-66.26902771, 18.48013878],
              [-66.26819611, 18.48013878],
              [-66.26819611, 18.47986031],
              [-66.26763916, 18.47986031],
              [-66.26763916, 18.47958374],
              [-66.26625061, 18.47958374],
              [-66.26625061, 18.47930527],
              [-66.26486206, 18.47930527],
              [-66.26486206, 18.4790287],
              [-66.26430511, 18.4790287],
              [-66.26430511, 18.47875023],
              [-66.26347351, 18.47875023],
              [-66.26347351, 18.47847176],
              [-66.26319122, 18.47847176],
              [-66.26319122, 18.47819519],
              [-66.26264191, 18.47819519],
              [-66.26264191, 18.47791672],
              [-66.26208496, 18.47791672],
              [-66.26208496, 18.47763824],
              [-66.26013947, 18.47763824],
              [-66.26013947, 18.47736168],
              [-66.25875092, 18.47736168],
              [-66.25875092, 18.47708321],
              [-66.25846863, 18.47708321],
              [-66.25846863, 18.47680473],
              [-66.25819397, 18.47680473],
              [-66.25819397, 18.47624969],
              [-66.25763702, 18.47624969],
              [-66.25763702, 18.47652817],
              [-66.25736237, 18.47652817],
              [-66.25736237, 18.47680473],
              [-66.25653076, 18.47680473],
              [-66.25653076, 18.47708321],
              [-66.25597382, 18.47708321],
              [-66.25597382, 18.47736168],
              [-66.25569153, 18.47736168],
              [-66.25569153, 18.47763824],
              [-66.25485992, 18.47763824],
              [-66.25485992, 18.47736168],
              [-66.25458527, 18.47736168],
              [-66.25458527, 18.47652817],
              [-66.25541687, 18.47652817],
              [-66.25541687, 18.47624969],
              [-66.25569153, 18.47624969],
              [-66.25569153, 18.47597313],
              [-66.25597382, 18.47597313],
              [-66.25597382, 18.47541618],
              [-66.25569153, 18.47541618],
              [-66.25569153, 18.47374916],
              [-66.25597382, 18.47374916],
              [-66.25597382, 18.47319412],
              [-66.25624847, 18.47319412],
              [-66.25624847, 18.47263908],
              [-66.25653076, 18.47263908],
              [-66.25653076, 18.47208405],
              [-66.25680542, 18.47208405],
              [-66.25680542, 18.47124863],
              [-66.25653076, 18.47124863],
              [-66.25653076, 18.4715271],
              [-66.25597382, 18.4715271],
              [-66.25597382, 18.47180557],
              [-66.25569153, 18.47180557],
              [-66.25569153, 18.47208405],
              [-66.25485992, 18.47208405],
              [-66.25485992, 18.47236252],
              [-66.25430298, 18.47236252],
              [-66.25430298, 18.47263908],
              [-66.25235748, 18.47263908],
              [-66.25235748, 18.47236252],
              [-66.25180817, 18.47236252],
              [-66.25180817, 18.47208405],
              [-66.25125122, 18.47208405],
              [-66.25125122, 18.47180557],
              [-66.24847412, 18.47180557],
              [-66.24847412, 18.4715271],
              [-66.24791718, 18.4715271],
              [-66.24791718, 18.47124863],
              [-66.24764252, 18.47124863],
              [-66.24764252, 18.47097015],
              [-66.24736023, 18.47097015],
              [-66.24736023, 18.47069359],
              [-66.24680328, 18.47069359],
              [-66.24680328, 18.47041893],
              [-66.24569702, 18.47041893],
              [-66.24569702, 18.47013855],
              [-66.24485779, 18.47013855],
              [-66.24485779, 18.46986008],
              [-66.24430847, 18.46986008],
              [-66.24430847, 18.46958351],
              [-66.24402618, 18.46958351],
              [-66.24402618, 18.46930504],
              [-66.24291992, 18.46930504],
              [-66.24291992, 18.46903038],
              [-66.24180603, 18.46903038],
              [-66.24180603, 18.46874809],
              [-66.24069214, 18.46874809],
              [-66.24069214, 18.46847153],
              [-66.23958588, 18.46847153],
              [-66.23958588, 18.46819496],
              [-66.23735809, 18.46819496],
              [-66.23735809, 18.46791649],
              [-66.23347473, 18.46791649],
              [-66.23347473, 18.46763611],
              [-66.23152924, 18.46763611],
              [-66.23152924, 18.46791649],
              [-66.23097229, 18.46791649],
              [-66.23097229, 18.46763611],
              [-66.22819519, 18.46763611],
              [-66.22819519, 18.46736145],
              [-66.22736359, 18.46736145],
              [-66.22736359, 18.46708298],
              [-66.22597504, 18.46708298],
              [-66.22597504, 18.46680641],
              [-66.22541809, 18.46680641],
              [-66.22541809, 18.46652794],
              [-66.22374725, 18.46652794],
              [-66.22374725, 18.46624947],
              [-66.2223587, 18.46624947],
              [-66.2223587, 18.4659729],
              [-66.22041321, 18.4659729],
              [-66.22041321, 18.46569443],
              [-66.21902466, 18.46569443],
              [-66.21902466, 18.4659729],
              [-66.21736145, 18.4659729],
              [-66.21736145, 18.46624947],
              [-66.21708679, 18.46624947],
              [-66.21708679, 18.4659729],
              [-66.21430206, 18.4659729],
              [-66.21430206, 18.46624947],
              [-66.2123642, 18.46624947],
              [-66.2123642, 18.46652794],
              [-66.21140289, 18.46652794],
              [-66.21834564, 18.4624157],
              [-66.22436523, 18.45859528],
              [-66.23083496, 18.45428848],
              [-66.23036957, 18.45047951],
              [-66.235466, 18.45284462],
              [-66.23918152, 18.45521927],
              [-66.24150085, 18.45569038],
              [-66.24427795, 18.45472717],
              [-66.2456665, 18.45377541],
              [-66.24797821, 18.45138741],
              [-66.25400543, 18.45422745],
              [-66.25817871, 18.45516968],
              [-66.25909424, 18.45040131],
              [-66.25909424, 18.44897461],
              [-66.25536346, 18.44041061],
              [-66.25489807, 18.43898582],
              [-66.25535583, 18.43850517],
              [-66.25811768, 18.43087769],
              [-66.25949097, 18.42372704],
              [-66.26041412, 18.42276955],
              [-66.26411438, 18.42085457],
              [-66.26411438, 18.42037773],
              [-66.26364136, 18.41656876],
              [-66.26224518, 18.41466522],
              [-66.25807953, 18.41658401],
              [-66.25668335, 18.41563416],
              [-66.25621796, 18.41325569],
              [-66.25575256, 18.41182327],
              [-66.25621033, 18.41182327],
              [-66.25991058, 18.40895271],
              [-66.25617218, 18.39610481],
              [-66.25246429, 18.39420891],
              [-66.25476837, 18.38991165],
              [-66.25800323, 18.38752174],
              [-66.26031494, 18.38703728],
              [-66.26402283, 18.38798141],
              [-66.26957703, 18.38606071],
              [-66.27050018, 18.38557816],
              [-66.27142334, 18.3836689],
              [-66.28253174, 18.38315964],
              [-66.28623962, 18.38314629],
              [-66.28855896, 18.38552475],
              [-66.30383301, 18.38404465],
              [-66.30388641, 18.4007225],
              [-66.30389404, 18.4031086],
              [-66.30392456, 18.41120338],
              [-66.30208588, 18.41740417],
              [-66.30162048, 18.41788101],
              [-66.30069733, 18.41835976],
              [-66.2997818, 18.42074394],
              [-66.29978943, 18.42312431],
              [-66.30025482, 18.4255085],
              [-66.30212402, 18.42883682],
              [-66.30213928, 18.43407631],
              [-66.30862427, 18.43357849],
              [-66.31234741, 18.43928528],
              [-66.3132782, 18.441185],
              [-66.31747437, 18.45022011],
              [-66.31702423, 18.4540329],
              [-66.31703949, 18.45736694],
              [-66.31568909, 18.46880341],
              [-66.31490326, 18.47458458],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 26,
        name: 'Dorado',
        hasc_1: 'PR.DO',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.31747437, 18.38314629, -66.21140289, 18.48069382],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.25',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-65.33847046, 18.35097313],
              [-65.33847046, 18.35069466],
              [-65.33791351, 18.35069466],
              [-65.33791351, 18.35014153],
              [-65.3373642, 18.35014153],
              [-65.3373642, 18.34958267],
              [-65.33708191, 18.34958267],
              [-65.33708191, 18.34902763],
              [-65.33680725, 18.34902763],
              [-65.33680725, 18.34874725],
              [-65.33652496, 18.34874725],
              [-65.33652496, 18.34680557],
              [-65.33625031, 18.34680557],
              [-65.33625031, 18.34652519],
              [-65.33597565, 18.34652519],
              [-65.33597565, 18.34625053],
              [-65.33569336, 18.34625053],
              [-65.33569336, 18.34597206],
              [-65.3354187, 18.34597206],
              [-65.3354187, 18.34569359],
              [-65.33513641, 18.34569359],
              [-65.33513641, 18.34458351],
              [-65.33486176, 18.34458351],
              [-65.33486176, 18.34375],
              [-65.33513641, 18.34375],
              [-65.33513641, 18.34319305],
              [-65.33486176, 18.34319305],
              [-65.33486176, 18.34291649],
              [-65.3345871, 18.34291649],
              [-65.3345871, 18.34263802],
              [-65.33403015, 18.34263802],
              [-65.33403015, 18.34236145],
              [-65.33374786, 18.34236145],
              [-65.33374786, 18.34180641],
              [-65.33347321, 18.34180641],
              [-65.33347321, 18.34124947],
              [-65.33319092, 18.34124947],
              [-65.33319092, 18.34097481],
              [-65.33291626, 18.34097481],
              [-65.33291626, 18.34069443],
              [-65.3326416, 18.34069443],
              [-65.3326416, 18.34041595],
              [-65.33235931, 18.34041595],
              [-65.33235931, 18.34013939],
              [-65.33208466, 18.34013939],
              [-65.33208466, 18.33958054],
              [-65.33180237, 18.33958054],
              [-65.33180237, 18.3390274],
              [-65.33152771, 18.3390274],
              [-65.33152771, 18.33875275],
              [-65.33069611, 18.33875275],
              [-65.33069611, 18.33847237],
              [-65.33041382, 18.33847237],
              [-65.33041382, 18.33819389],
              [-65.33013916, 18.33819389],
              [-65.33013916, 18.33791733],
              [-65.32958221, 18.33791733],
              [-65.32958221, 18.33764076],
              [-65.32875061, 18.33764076],
              [-65.32875061, 18.33735847],
              [-65.32736206, 18.33735847],
              [-65.32736206, 18.33708382],
              [-65.32514191, 18.33708382],
              [-65.32514191, 18.33680534],
              [-65.32485962, 18.33680534],
              [-65.32485962, 18.33652687],
              [-65.32458496, 18.33652687],
              [-65.32458496, 18.3362484],
              [-65.32430267, 18.3362484],
              [-65.32430267, 18.33597183],
              [-65.32402802, 18.33597183],
              [-65.32402802, 18.33569527],
              [-65.32375336, 18.33569527],
              [-65.32375336, 18.33513832],
              [-65.32347107, 18.33513832],
              [-65.32347107, 18.33486176],
              [-65.32319641, 18.33486176],
              [-65.32319641, 18.33458328],
              [-65.32291412, 18.33458328],
              [-65.32291412, 18.33430481],
              [-65.32263947, 18.33430481],
              [-65.32263947, 18.33402634],
              [-65.32236481, 18.33402634],
              [-65.32236481, 18.3334713],
              [-65.32208252, 18.3334713],
              [-65.32208252, 18.33263969],
              [-65.32180786, 18.33263969],
              [-65.32180786, 18.33180428],
              [-65.32152557, 18.33180428],
              [-65.32152557, 18.33152771],
              [-65.32125092, 18.33152771],
              [-65.32125092, 18.33124924],
              [-65.32096863, 18.33124924],
              [-65.32096863, 18.3306942],
              [-65.32069397, 18.3306942],
              [-65.32069397, 18.33041573],
              [-65.32041931, 18.33041573],
              [-65.32041931, 18.32986069],
              [-65.32013702, 18.32986069],
              [-65.32013702, 18.32958412],
              [-65.31986237, 18.32958412],
              [-65.31986237, 18.32930565],
              [-65.31958008, 18.32930565],
              [-65.31958008, 18.32902718],
              [-65.31930542, 18.32902718],
              [-65.31930542, 18.32875061],
              [-65.31903076, 18.32875061],
              [-65.31903076, 18.32847214],
              [-65.31847382, 18.32847214],
              [-65.31847382, 18.32819366],
              [-65.31708527, 18.32819366],
              [-65.31708527, 18.32847214],
              [-65.31485748, 18.32847214],
              [-65.31485748, 18.32875061],
              [-65.31430817, 18.32875061],
              [-65.31430817, 18.32902718],
              [-65.31402588, 18.32902718],
              [-65.31402588, 18.32930565],
              [-65.31375122, 18.32930565],
              [-65.31375122, 18.32986069],
              [-65.31319427, 18.32986069],
              [-65.31319427, 18.33097267],
              [-65.31291962, 18.33097267],
              [-65.31291962, 18.33458328],
              [-65.31263733, 18.33458328],
              [-65.31263733, 18.33513832],
              [-65.31236267, 18.33513832],
              [-65.31236267, 18.33541679],
              [-65.31180573, 18.33541679],
              [-65.31180573, 18.3362484],
              [-65.31153107, 18.3362484],
              [-65.31153107, 18.33680534],
              [-65.31124878, 18.33680534],
              [-65.31124878, 18.33735847],
              [-65.31097412, 18.33735847],
              [-65.31097412, 18.33791733],
              [-65.31069183, 18.33791733],
              [-65.31069183, 18.33819389],
              [-65.30930328, 18.33819389],
              [-65.30930328, 18.33764076],
              [-65.30902863, 18.33764076],
              [-65.30902863, 18.33708382],
              [-65.30874634, 18.33708382],
              [-65.30874634, 18.33680534],
              [-65.30847168, 18.33680534],
              [-65.30847168, 18.33652687],
              [-65.30819702, 18.33652687],
              [-65.30819702, 18.33597183],
              [-65.30791473, 18.33597183],
              [-65.30791473, 18.33569527],
              [-65.30764008, 18.33569527],
              [-65.30764008, 18.33541679],
              [-65.30708313, 18.33541679],
              [-65.30708313, 18.33513832],
              [-65.30680847, 18.33513832],
              [-65.30680847, 18.33486176],
              [-65.30625153, 18.33486176],
              [-65.30625153, 18.33458328],
              [-65.30596924, 18.33458328],
              [-65.30596924, 18.33430481],
              [-65.30569458, 18.33430481],
              [-65.30569458, 18.33374977],
              [-65.30541992, 18.33374977],
              [-65.30541992, 18.33291626],
              [-65.30513763, 18.33291626],
              [-65.30513763, 18.33263969],
              [-65.30486298, 18.33263969],
              [-65.30486298, 18.33236122],
              [-65.30458069, 18.33236122],
              [-65.30458069, 18.33208275],
              [-65.30347443, 18.33208275],
              [-65.30347443, 18.33180428],
              [-65.30263519, 18.33180428],
              [-65.30263519, 18.33152771],
              [-65.30097198, 18.33152771],
              [-65.30097198, 18.33124924],
              [-65.29985809, 18.33124924],
              [-65.29985809, 18.33097267],
              [-65.29958344, 18.33097267],
              [-65.29958344, 18.3306942],
              [-65.29680634, 18.3306942],
              [-65.29680634, 18.33124924],
              [-65.29624939, 18.33124924],
              [-65.29624939, 18.3306942],
              [-65.29597473, 18.3306942],
              [-65.29597473, 18.33124924],
              [-65.2951355, 18.33124924],
              [-65.2951355, 18.33180428],
              [-65.29486084, 18.33180428],
              [-65.29486084, 18.33263969],
              [-65.29458618, 18.33263969],
              [-65.29458618, 18.33319473],
              [-65.29430389, 18.33319473],
              [-65.29430389, 18.3334713],
              [-65.29402924, 18.3334713],
              [-65.29402924, 18.33402634],
              [-65.29374695, 18.33402634],
              [-65.29374695, 18.33430481],
              [-65.29347229, 18.33430481],
              [-65.29347229, 18.33513832],
              [-65.29319763, 18.33513832],
              [-65.29319763, 18.33541679],
              [-65.29291534, 18.33541679],
              [-65.29291534, 18.33569527],
              [-65.29152679, 18.33569527],
              [-65.29152679, 18.33597183],
              [-65.29096985, 18.33597183],
              [-65.29096985, 18.33652687],
              [-65.29069519, 18.33652687],
              [-65.29069519, 18.33680534],
              [-65.2904129, 18.33680534],
              [-65.2904129, 18.33791733],
              [-65.28902435, 18.33791733],
              [-65.28902435, 18.33764076],
              [-65.28874969, 18.33764076],
              [-65.28874969, 18.33735847],
              [-65.28902435, 18.33735847],
              [-65.28902435, 18.33569527],
              [-65.28930664, 18.33569527],
              [-65.28930664, 18.33513832],
              [-65.2895813, 18.33513832],
              [-65.2895813, 18.33458328],
              [-65.28986359, 18.33458328],
              [-65.28986359, 18.33374977],
              [-65.2904129, 18.33374977],
              [-65.2904129, 18.3334713],
              [-65.29013824, 18.3334713],
              [-65.29013824, 18.33319473],
              [-65.28986359, 18.33319473],
              [-65.28986359, 18.33291626],
              [-65.2895813, 18.33291626],
              [-65.2895813, 18.33263969],
              [-65.28930664, 18.33263969],
              [-65.28930664, 18.33208275],
              [-65.28902435, 18.33208275],
              [-65.28902435, 18.33180428],
              [-65.28874969, 18.33180428],
              [-65.28874969, 18.33152771],
              [-65.28819275, 18.33152771],
              [-65.28819275, 18.33124924],
              [-65.2876358, 18.33124924],
              [-65.2876358, 18.33097267],
              [-65.28708649, 18.33097267],
              [-65.28708649, 18.3306942],
              [-65.28624725, 18.3306942],
              [-65.28624725, 18.33041573],
              [-65.28569794, 18.33041573],
              [-65.28569794, 18.33013916],
              [-65.28514099, 18.33013916],
              [-65.28514099, 18.32986069],
              [-65.28375244, 18.32986069],
              [-65.28375244, 18.32958412],
              [-65.28180695, 18.32958412],
              [-65.28180695, 18.32986069],
              [-65.28152466, 18.32986069],
              [-65.28152466, 18.33041573],
              [-65.28125, 18.33041573],
              [-65.28125, 18.3306942],
              [-65.28097534, 18.3306942],
              [-65.28097534, 18.33097267],
              [-65.28013611, 18.33097267],
              [-65.28013611, 18.33124924],
              [-65.2793045, 18.33124924],
              [-65.2793045, 18.33208275],
              [-65.27902985, 18.33208275],
              [-65.27902985, 18.33236122],
              [-65.27874756, 18.33236122],
              [-65.27874756, 18.33263969],
              [-65.27735901, 18.33263969],
              [-65.27735901, 18.33236122],
              [-65.27680206, 18.33236122],
              [-65.27680206, 18.33208275],
              [-65.27597046, 18.33208275],
              [-65.27597046, 18.33180428],
              [-65.27513885, 18.33180428],
              [-65.27513885, 18.33152771],
              [-65.27402496, 18.33152771],
              [-65.27402496, 18.33124924],
              [-65.27263641, 18.33124924],
              [-65.27263641, 18.33097267],
              [-65.27236176, 18.33097267],
              [-65.27236176, 18.3306942],
              [-65.27180481, 18.3306942],
              [-65.27180481, 18.33041573],
              [-65.27153015, 18.33041573],
              [-65.27153015, 18.33013916],
              [-65.27097321, 18.33013916],
              [-65.27097321, 18.32986069],
              [-65.27069092, 18.32986069],
              [-65.27069092, 18.32958412],
              [-65.2701416, 18.32958412],
              [-65.2701416, 18.32930565],
              [-65.26985931, 18.32930565],
              [-65.26985931, 18.32902718],
              [-65.26930237, 18.32902718],
              [-65.26930237, 18.32875061],
              [-65.26875305, 18.32875061],
              [-65.26875305, 18.32847214],
              [-65.26819611, 18.32847214],
              [-65.26819611, 18.32819366],
              [-65.26791382, 18.32819366],
              [-65.26791382, 18.3279171],
              [-65.2673645, 18.3279171],
              [-65.2673645, 18.32763863],
              [-65.26680756, 18.32763863],
              [-65.26680756, 18.32736206],
              [-65.26625061, 18.32736206],
              [-65.26625061, 18.32708359],
              [-65.26569366, 18.32708359],
              [-65.26569366, 18.32680511],
              [-65.26513672, 18.32680511],
              [-65.26513672, 18.32652855],
              [-65.26486206, 18.32652855],
              [-65.26486206, 18.32625008],
              [-65.26430511, 18.32625008],
              [-65.26430511, 18.3259697],
              [-65.26374817, 18.3259697],
              [-65.26374817, 18.32569504],
              [-65.26319122, 18.32569504],
              [-65.26319122, 18.32513809],
              [-65.26264191, 18.32513809],
              [-65.26264191, 18.32486153],
              [-65.26235962, 18.32486153],
              [-65.26235962, 18.32458115],
              [-65.26180267, 18.32458115],
              [-65.26180267, 18.32430649],
              [-65.26152802, 18.32430649],
              [-65.26152802, 18.32402992],
              [-65.26097107, 18.32402992],
              [-65.26097107, 18.32375145],
              [-65.26069641, 18.32375145],
              [-65.26069641, 18.32347298],
              [-65.26013947, 18.32347298],
              [-65.26013947, 18.3231945],
              [-65.25986481, 18.3231945],
              [-65.25986481, 18.32291603],
              [-65.25930786, 18.32291603],
              [-65.25930786, 18.32263756],
              [-65.25902557, 18.32263756],
              [-65.25902557, 18.32235909],
              [-65.25846863, 18.32235909],
              [-65.25846863, 18.32208252],
              [-65.25819397, 18.32208252],
              [-65.25819397, 18.32180595],
              [-65.25763702, 18.32180595],
              [-65.25763702, 18.32152748],
              [-65.25736237, 18.32152748],
              [-65.25736237, 18.32125092],
              [-65.25680542, 18.32125092],
              [-65.25680542, 18.32097244],
              [-65.25653076, 18.32097244],
              [-65.25653076, 18.32069397],
              [-65.25597382, 18.32069397],
              [-65.25597382, 18.32041931],
              [-65.25569153, 18.32041931],
              [-65.25569153, 18.32013893],
              [-65.25514221, 18.32013893],
              [-65.25514221, 18.31986046],
              [-65.25485992, 18.31986046],
              [-65.25485992, 18.31958389],
              [-65.25430298, 18.31958389],
              [-65.25430298, 18.31930542],
              [-65.25402832, 18.31930542],
              [-65.25402832, 18.31902695],
              [-65.25180817, 18.31902695],
              [-65.25180817, 18.31875038],
              [-65.25125122, 18.31875038],
              [-65.25125122, 18.31847191],
              [-65.25096893, 18.31847191],
              [-65.25096893, 18.31819534],
              [-65.25069427, 18.31819534],
              [-65.25069427, 18.3176384],
              [-65.25041962, 18.3176384],
              [-65.25041962, 18.31680489],
              [-65.25013733, 18.31680489],
              [-65.25013733, 18.31569481],
              [-65.24986267, 18.31569481],
              [-65.24986267, 18.31513977],
              [-65.24958038, 18.31513977],
              [-65.24958038, 18.31458473],
              [-65.24930573, 18.31458473],
              [-65.24930573, 18.31430626],
              [-65.24903107, 18.31430626],
              [-65.24903107, 18.31402779],
              [-65.24819183, 18.31402779],
              [-65.24819183, 18.31374931],
              [-65.24791718, 18.31374931],
              [-65.24791718, 18.31319427],
              [-65.24764252, 18.31319427],
              [-65.24764252, 18.3129158],
              [-65.24708557, 18.3129158],
              [-65.24708557, 18.31236267],
              [-65.24652863, 18.31236267],
              [-65.24652863, 18.3120842],
              [-65.24624634, 18.3120842],
              [-65.24624634, 18.31180573],
              [-65.24541473, 18.31180573],
              [-65.24541473, 18.31041718],
              [-65.24514008, 18.31041718],
              [-65.24514008, 18.30819511],
              [-65.24485779, 18.30819511],
              [-65.24485779, 18.30763626],
              [-65.24458313, 18.30763626],
              [-65.24458313, 18.3073616],
              [-65.24430847, 18.3073616],
              [-65.24430847, 18.30486107],
              [-65.24402618, 18.30486107],
              [-65.24402618, 18.3045826],
              [-65.24430847, 18.3045826],
              [-65.24430847, 18.30347252],
              [-65.24485779, 18.30347252],
              [-65.24485779, 18.30319595],
              [-65.24514008, 18.30319595],
              [-65.24514008, 18.30291748],
              [-65.24708557, 18.30291748],
              [-65.24708557, 18.30347252],
              [-65.24736023, 18.30347252],
              [-65.24736023, 18.30374908],
              [-65.24847412, 18.30374908],
              [-65.24847412, 18.30402756],
              [-65.24930573, 18.30402756],
              [-65.24930573, 18.30430412],
              [-65.25013733, 18.30430412],
              [-65.25013733, 18.3045826],
              [-65.25041962, 18.3045826],
              [-65.25041962, 18.30486107],
              [-65.25069427, 18.30486107],
              [-65.25069427, 18.30513954],
              [-65.25096893, 18.30513954],
              [-65.25096893, 18.30541611],
              [-65.25125122, 18.30541611],
              [-65.25125122, 18.30597305],
              [-65.25208282, 18.30597305],
              [-65.25208282, 18.30624962],
              [-65.25319672, 18.30624962],
              [-65.25319672, 18.30652618],
              [-65.25458527, 18.30652618],
              [-65.25458527, 18.30624962],
              [-65.25485992, 18.30624962],
              [-65.25485992, 18.30597305],
              [-65.25541687, 18.30597305],
              [-65.25541687, 18.30624962],
              [-65.25624847, 18.30624962],
              [-65.25624847, 18.30652618],
              [-65.25653076, 18.30652618],
              [-65.25653076, 18.30680466],
              [-65.25680542, 18.30680466],
              [-65.25680542, 18.3073616],
              [-65.25708008, 18.3073616],
              [-65.25708008, 18.30874825],
              [-65.25736237, 18.30874825],
              [-65.25736237, 18.30903053],
              [-65.25763702, 18.30903053],
              [-65.25763702, 18.30874825],
              [-65.25819397, 18.30874825],
              [-65.25819397, 18.30847168],
              [-65.25846863, 18.30847168],
              [-65.25846863, 18.30819511],
              [-65.25875092, 18.30819511],
              [-65.25875092, 18.30624962],
              [-65.25846863, 18.30624962],
              [-65.25846863, 18.30597305],
              [-65.25819397, 18.30597305],
              [-65.25819397, 18.30541611],
              [-65.25791931, 18.30541611],
              [-65.25791931, 18.3045826],
              [-65.25763702, 18.3045826],
              [-65.25763702, 18.30263901],
              [-65.25680542, 18.30263901],
              [-65.25680542, 18.30236053],
              [-65.25653076, 18.30236053],
              [-65.25653076, 18.3018055],
              [-65.25624847, 18.3018055],
              [-65.25624847, 18.30152702],
              [-65.25569153, 18.30152702],
              [-65.25569153, 18.30125046],
              [-65.25514221, 18.30125046],
              [-65.25514221, 18.30097389],
              [-65.25485992, 18.30097389],
              [-65.25485992, 18.29930496],
              [-65.25624847, 18.29930496],
              [-65.25624847, 18.29735947],
              [-65.25653076, 18.29735947],
              [-65.25653076, 18.2970829],
              [-65.25680542, 18.2970829],
              [-65.25680542, 18.29680634],
              [-65.25763702, 18.29680634],
              [-65.25763702, 18.29652977],
              [-65.25736237, 18.29652977],
              [-65.25736237, 18.29597282],
              [-65.25708008, 18.29597282],
              [-65.25708008, 18.29569435],
              [-65.25680542, 18.29569435],
              [-65.25680542, 18.29541588],
              [-65.25653076, 18.29541588],
              [-65.25653076, 18.29486084],
              [-65.25624847, 18.29486084],
              [-65.25624847, 18.2943058],
              [-65.25458527, 18.2943058],
              [-65.25458527, 18.29263878],
              [-65.25624847, 18.29263878],
              [-65.25624847, 18.29319382],
              [-65.25708008, 18.29319382],
              [-65.25708008, 18.29347229],
              [-65.25763702, 18.29347229],
              [-65.25763702, 18.29319382],
              [-65.25846863, 18.29319382],
              [-65.25846863, 18.29291534],
              [-65.25875092, 18.29291534],
              [-65.25875092, 18.29236031],
              [-65.25902557, 18.29236031],
              [-65.25902557, 18.29208374],
              [-65.25930786, 18.29208374],
              [-65.25930786, 18.29125023],
              [-65.25958252, 18.29125023],
              [-65.25958252, 18.29097176],
              [-65.25986481, 18.29097176],
              [-65.25986481, 18.29041672],
              [-65.26013947, 18.29041672],
              [-65.26013947, 18.29013824],
              [-65.26041412, 18.29013824],
              [-65.26041412, 18.28986168],
              [-65.26125336, 18.28986168],
              [-65.26125336, 18.28958321],
              [-65.26208496, 18.28958321],
              [-65.26208496, 18.28930473],
              [-65.26291656, 18.28930473],
              [-65.26291656, 18.28986168],
              [-65.26403046, 18.28986168],
              [-65.26403046, 18.29069519],
              [-65.26374817, 18.29069519],
              [-65.26374817, 18.29097176],
              [-65.26347351, 18.29097176],
              [-65.26347351, 18.29291534],
              [-65.26374817, 18.29291534],
              [-65.26374817, 18.29319382],
              [-65.26403046, 18.29319382],
              [-65.26403046, 18.29375076],
              [-65.26374817, 18.29375076],
              [-65.26374817, 18.29486084],
              [-65.26403046, 18.29486084],
              [-65.26403046, 18.29569435],
              [-65.26457977, 18.29569435],
              [-65.26457977, 18.29597282],
              [-65.26486206, 18.29597282],
              [-65.26486206, 18.29624748],
              [-65.26513672, 18.29624748],
              [-65.26513672, 18.29652977],
              [-65.26569366, 18.29652977],
              [-65.26569366, 18.29680634],
              [-65.26652527, 18.29680634],
              [-65.26652527, 18.2970829],
              [-65.26847076, 18.2970829],
              [-65.26847076, 18.29680634],
              [-65.26930237, 18.29680634],
              [-65.26930237, 18.29652977],
              [-65.2701416, 18.29652977],
              [-65.2701416, 18.29624748],
              [-65.27069092, 18.29624748],
              [-65.27069092, 18.29597282],
              [-65.27124786, 18.29597282],
              [-65.27124786, 18.29569435],
              [-65.27153015, 18.29569435],
              [-65.27153015, 18.29541588],
              [-65.2720871, 18.29541588],
              [-65.2720871, 18.29486084],
              [-65.27541351, 18.29486084],
              [-65.27541351, 18.29513741],
              [-65.27597046, 18.29513741],
              [-65.27597046, 18.29541588],
              [-65.27625275, 18.29541588],
              [-65.27625275, 18.29569435],
              [-65.2765274, 18.29569435],
              [-65.2765274, 18.29624748],
              [-65.27680206, 18.29624748],
              [-65.27680206, 18.29652977],
              [-65.27708435, 18.29652977],
              [-65.27708435, 18.2970829],
              [-65.27735901, 18.2970829],
              [-65.27735901, 18.29735947],
              [-65.2776413, 18.29735947],
              [-65.2776413, 18.29875183],
              [-65.27735901, 18.29875183],
              [-65.27735901, 18.29930496],
              [-65.27708435, 18.29930496],
              [-65.27708435, 18.29958344],
              [-65.27735901, 18.29958344],
              [-65.27735901, 18.29986382],
              [-65.2776413, 18.29986382],
              [-65.2776413, 18.29930496],
              [-65.2784729, 18.29930496],
              [-65.2784729, 18.29986382],
              [-65.27902985, 18.29986382],
              [-65.27902985, 18.30013847],
              [-65.27986145, 18.30013847],
              [-65.27986145, 18.30041695],
              [-65.28013611, 18.30041695],
              [-65.28013611, 18.30069351],
              [-65.2804184, 18.30069351],
              [-65.2804184, 18.30125046],
              [-65.28013611, 18.30125046],
              [-65.28013611, 18.3018055],
              [-65.27986145, 18.3018055],
              [-65.27986145, 18.30208206],
              [-65.27958679, 18.30208206],
              [-65.27958679, 18.30263901],
              [-65.27986145, 18.30263901],
              [-65.27986145, 18.30236053],
              [-65.28013611, 18.30236053],
              [-65.28013611, 18.30208206],
              [-65.28125, 18.30208206],
              [-65.28125, 18.30263901],
              [-65.28152466, 18.30263901],
              [-65.28152466, 18.30291748],
              [-65.28180695, 18.30291748],
              [-65.28180695, 18.30347252],
              [-65.28097534, 18.30347252],
              [-65.28097534, 18.30374908],
              [-65.2804184, 18.30374908],
              [-65.2804184, 18.30402756],
              [-65.27958679, 18.30402756],
              [-65.27958679, 18.30430412],
              [-65.2793045, 18.30430412],
              [-65.2793045, 18.3045826],
              [-65.27902985, 18.3045826],
              [-65.27902985, 18.30486107],
              [-65.2793045, 18.30486107],
              [-65.2793045, 18.30513954],
              [-65.27958679, 18.30513954],
              [-65.27958679, 18.30569458],
              [-65.2840271, 18.30569458],
              [-65.2840271, 18.30624962],
              [-65.2848587, 18.30624962],
              [-65.2848587, 18.30680466],
              [-65.28514099, 18.30680466],
              [-65.28514099, 18.30708313],
              [-65.28541565, 18.30708313],
              [-65.28541565, 18.30680466],
              [-65.28652954, 18.30680466],
              [-65.28652954, 18.30624962],
              [-65.2876358, 18.30624962],
              [-65.2876358, 18.30652618],
              [-65.28791809, 18.30652618],
              [-65.28791809, 18.30708313],
              [-65.28819275, 18.30708313],
              [-65.28819275, 18.3073616],
              [-65.28847504, 18.3073616],
              [-65.28847504, 18.30763626],
              [-65.28902435, 18.30763626],
              [-65.28902435, 18.30791664],
              [-65.28930664, 18.30791664],
              [-65.28930664, 18.30819511],
              [-65.2895813, 18.30819511],
              [-65.2895813, 18.30847168],
              [-65.28986359, 18.30847168],
              [-65.28986359, 18.30874825],
              [-65.29013824, 18.30874825],
              [-65.29013824, 18.30903053],
              [-65.2904129, 18.30903053],
              [-65.2904129, 18.30930519],
              [-65.29069519, 18.30930519],
              [-65.29069519, 18.30985832],
              [-65.29096985, 18.30985832],
              [-65.29096985, 18.31014061],
              [-65.29125214, 18.31014061],
              [-65.29125214, 18.31125259],
              [-65.29180908, 18.31125259],
              [-65.29180908, 18.31097031],
              [-65.2923584, 18.31097031],
              [-65.2923584, 18.31014061],
              [-65.29264069, 18.31014061],
              [-65.29264069, 18.30985832],
              [-65.2923584, 18.30985832],
              [-65.2923584, 18.30930519],
              [-65.29208374, 18.30930519],
              [-65.29208374, 18.30903053],
              [-65.29180908, 18.30903053],
              [-65.29180908, 18.30874825],
              [-65.29152679, 18.30874825],
              [-65.29152679, 18.30847168],
              [-65.29125214, 18.30847168],
              [-65.29125214, 18.30763626],
              [-65.29152679, 18.30763626],
              [-65.29152679, 18.3073616],
              [-65.2923584, 18.3073616],
              [-65.2923584, 18.30763626],
              [-65.29402924, 18.30763626],
              [-65.29402924, 18.30791664],
              [-65.29458618, 18.30791664],
              [-65.29458618, 18.30819511],
              [-65.29569244, 18.30819511],
              [-65.29569244, 18.30847168],
              [-65.29624939, 18.30847168],
              [-65.29624939, 18.30874825],
              [-65.29652405, 18.30874825],
              [-65.29652405, 18.30903053],
              [-65.29736328, 18.30903053],
              [-65.29736328, 18.30930519],
              [-65.29819489, 18.30930519],
              [-65.29819489, 18.30985832],
              [-65.30014038, 18.30985832],
              [-65.30014038, 18.30930519],
              [-65.30097198, 18.30930519],
              [-65.30097198, 18.30985832],
              [-65.30152893, 18.30985832],
              [-65.30152893, 18.30958366],
              [-65.30180359, 18.30958366],
              [-65.30180359, 18.30791664],
              [-65.30124664, 18.30791664],
              [-65.30124664, 18.30624962],
              [-65.30097198, 18.30624962],
              [-65.30097198, 18.30569458],
              [-65.30069733, 18.30569458],
              [-65.30069733, 18.30513954],
              [-65.29985809, 18.30513954],
              [-65.29985809, 18.3045826],
              [-65.29958344, 18.3045826],
              [-65.29958344, 18.30430412],
              [-65.29902649, 18.30430412],
              [-65.29902649, 18.30402756],
              [-65.29846954, 18.30402756],
              [-65.29846954, 18.30319595],
              [-65.29819489, 18.30319595],
              [-65.29819489, 18.30236053],
              [-65.29652405, 18.30236053],
              [-65.29652405, 18.30291748],
              [-65.29569244, 18.30291748],
              [-65.29569244, 18.30263901],
              [-65.2951355, 18.30263901],
              [-65.2951355, 18.30208206],
              [-65.29458618, 18.30208206],
              [-65.29458618, 18.3018055],
              [-65.29430389, 18.3018055],
              [-65.29430389, 18.30152702],
              [-65.29347229, 18.30152702],
              [-65.29347229, 18.30125046],
              [-65.29319763, 18.30125046],
              [-65.29319763, 18.30097389],
              [-65.29125214, 18.30097389],
              [-65.29125214, 18.30069351],
              [-65.28986359, 18.30069351],
              [-65.28986359, 18.30041695],
              [-65.2895813, 18.30041695],
              [-65.2895813, 18.30013847],
              [-65.28874969, 18.30013847],
              [-65.28874969, 18.29986382],
              [-65.28847504, 18.29986382],
              [-65.28847504, 18.29930496],
              [-65.28736115, 18.29930496],
              [-65.28736115, 18.2990284],
              [-65.2868042, 18.2990284],
              [-65.2868042, 18.29875183],
              [-65.28624725, 18.29875183],
              [-65.28624725, 18.29763985],
              [-65.2859726, 18.29763985],
              [-65.2859726, 18.29597282],
              [-65.28624725, 18.29597282],
              [-65.28624725, 18.29569435],
              [-65.28736115, 18.29569435],
              [-65.28736115, 18.2943058],
              [-65.28708649, 18.2943058],
              [-65.28708649, 18.29291534],
              [-65.28791809, 18.29291534],
              [-65.28791809, 18.29263878],
              [-65.28819275, 18.29263878],
              [-65.28819275, 18.29236031],
              [-65.28847504, 18.29236031],
              [-65.28847504, 18.29208374],
              [-65.28874969, 18.29208374],
              [-65.28874969, 18.29125023],
              [-65.2876358, 18.29125023],
              [-65.2876358, 18.2915287],
              [-65.28736115, 18.2915287],
              [-65.28736115, 18.29180527],
              [-65.2868042, 18.29180527],
              [-65.2868042, 18.29208374],
              [-65.2859726, 18.29208374],
              [-65.2859726, 18.29236031],
              [-65.28458405, 18.29236031],
              [-65.28458405, 18.29263878],
              [-65.28430176, 18.29263878],
              [-65.28430176, 18.29291534],
              [-65.2840271, 18.29291534],
              [-65.2840271, 18.29319382],
              [-65.2831955, 18.29319382],
              [-65.2831955, 18.29291534],
              [-65.28263855, 18.29291534],
              [-65.28263855, 18.2915287],
              [-65.28236389, 18.2915287],
              [-65.28236389, 18.29125023],
              [-65.28180695, 18.29125023],
              [-65.28180695, 18.29069519],
              [-65.28152466, 18.29069519],
              [-65.28152466, 18.29013824],
              [-65.28097534, 18.29013824],
              [-65.28097534, 18.28986168],
              [-65.28069305, 18.28986168],
              [-65.28069305, 18.28930473],
              [-65.2804184, 18.28930473],
              [-65.2804184, 18.28763962],
              [-65.28069305, 18.28763962],
              [-65.28069305, 18.28736115],
              [-65.28097534, 18.28736115],
              [-65.28097534, 18.28708267],
              [-65.28236389, 18.28708267],
              [-65.28236389, 18.28763962],
              [-65.28347015, 18.28763962],
              [-65.28347015, 18.28680611],
              [-65.2831955, 18.28680611],
              [-65.2831955, 18.28652763],
              [-65.28291321, 18.28652763],
              [-65.28291321, 18.28624916],
              [-65.28263855, 18.28624916],
              [-65.28263855, 18.2859726],
              [-65.28236389, 18.2859726],
              [-65.28236389, 18.28541756],
              [-65.2820816, 18.28541756],
              [-65.2820816, 18.28513908],
              [-65.28152466, 18.28513908],
              [-65.28152466, 18.28430557],
              [-65.28125, 18.28430557],
              [-65.28125, 18.2840271],
              [-65.28152466, 18.2840271],
              [-65.28152466, 18.28347015],
              [-65.2848587, 18.28347015],
              [-65.2848587, 18.28319359],
              [-65.28541565, 18.28319359],
              [-65.28541565, 18.28208351],
              [-65.28514099, 18.28208351],
              [-65.28514099, 18.28153038],
              [-65.2840271, 18.28153038],
              [-65.2840271, 18.28097153],
              [-65.2831955, 18.28097153],
              [-65.2831955, 18.28069496],
              [-65.28291321, 18.28069496],
              [-65.28291321, 18.28041649],
              [-65.28263855, 18.28041649],
              [-65.28263855, 18.27930641],
              [-65.2831955, 18.27930641],
              [-65.2831955, 18.27902794],
              [-65.28430176, 18.27902794],
              [-65.28430176, 18.2784729],
              [-65.28514099, 18.2784729],
              [-65.28514099, 18.2765274],
              [-65.28624725, 18.2765274],
              [-65.28624725, 18.27680588],
              [-65.28652954, 18.27680588],
              [-65.28652954, 18.27708244],
              [-65.2876358, 18.27708244],
              [-65.2876358, 18.27736092],
              [-65.28791809, 18.27736092],
              [-65.28791809, 18.27819443],
              [-65.2876358, 18.27819443],
              [-65.2876358, 18.27874947],
              [-65.28736115, 18.27874947],
              [-65.28736115, 18.27902794],
              [-65.28708649, 18.27902794],
              [-65.28708649, 18.27930641],
              [-65.2868042, 18.27930641],
              [-65.2868042, 18.27958298],
              [-65.28652954, 18.27958298],
              [-65.28652954, 18.28097153],
              [-65.2868042, 18.28097153],
              [-65.2868042, 18.28153038],
              [-65.28708649, 18.28153038],
              [-65.28708649, 18.28208351],
              [-65.28791809, 18.28208351],
              [-65.28791809, 18.28236008],
              [-65.28874969, 18.28236008],
              [-65.28874969, 18.28264046],
              [-65.28986359, 18.28264046],
              [-65.28986359, 18.28291893],
              [-65.29013824, 18.28291893],
              [-65.29013824, 18.28347015],
              [-65.2904129, 18.28347015],
              [-65.2904129, 18.2840271],
              [-65.29069519, 18.2840271],
              [-65.29069519, 18.28458405],
              [-65.29096985, 18.28458405],
              [-65.29096985, 18.28486252],
              [-65.29125214, 18.28486252],
              [-65.29125214, 18.28513908],
              [-65.29152679, 18.28513908],
              [-65.29152679, 18.28541756],
              [-65.29180908, 18.28541756],
              [-65.29180908, 18.28569412],
              [-65.29264069, 18.28569412],
              [-65.29264069, 18.2859726],
              [-65.29319763, 18.2859726],
              [-65.29319763, 18.28680611],
              [-65.29347229, 18.28680611],
              [-65.29347229, 18.28736115],
              [-65.29374695, 18.28736115],
              [-65.29374695, 18.28763962],
              [-65.29402924, 18.28763962],
              [-65.29402924, 18.28847313],
              [-65.29430389, 18.28847313],
              [-65.29430389, 18.28958321],
              [-65.29458618, 18.28958321],
              [-65.29458618, 18.29069519],
              [-65.29486084, 18.29069519],
              [-65.29486084, 18.29097176],
              [-65.2951355, 18.29097176],
              [-65.2951355, 18.29125023],
              [-65.29541779, 18.29125023],
              [-65.29541779, 18.2915287],
              [-65.29569244, 18.2915287],
              [-65.29569244, 18.29180527],
              [-65.29624939, 18.29180527],
              [-65.29624939, 18.29208374],
              [-65.29680634, 18.29208374],
              [-65.29680634, 18.29291534],
              [-65.29763794, 18.29291534],
              [-65.29763794, 18.29319382],
              [-65.29792023, 18.29319382],
              [-65.29792023, 18.29347229],
              [-65.29819489, 18.29347229],
              [-65.29819489, 18.29375076],
              [-65.29875183, 18.29375076],
              [-65.29875183, 18.29402733],
              [-65.30041504, 18.29402733],
              [-65.30041504, 18.29375076],
              [-65.30097198, 18.29375076],
              [-65.30097198, 18.29402733],
              [-65.30152893, 18.29402733],
              [-65.30152893, 18.29458427],
              [-65.30124664, 18.29458427],
              [-65.30124664, 18.29652977],
              [-65.30097198, 18.29652977],
              [-65.30097198, 18.29791641],
              [-65.30124664, 18.29791641],
              [-65.30124664, 18.29819489],
              [-65.30152893, 18.29819489],
              [-65.30152893, 18.29791641],
              [-65.30208588, 18.29791641],
              [-65.30208588, 18.29819489],
              [-65.30236053, 18.29819489],
              [-65.30236053, 18.29846954],
              [-65.30291748, 18.29846954],
              [-65.30291748, 18.29875183],
              [-65.30263519, 18.29875183],
              [-65.30263519, 18.2990284],
              [-65.30236053, 18.2990284],
              [-65.30236053, 18.29958344],
              [-65.30208588, 18.29958344],
              [-65.30208588, 18.30013847],
              [-65.30263519, 18.30013847],
              [-65.30263519, 18.30041695],
              [-65.30291748, 18.30041695],
              [-65.30291748, 18.30097389],
              [-65.30319214, 18.30097389],
              [-65.30319214, 18.3018055],
              [-65.30458069, 18.3018055],
              [-65.30458069, 18.30208206],
              [-65.30541992, 18.30208206],
              [-65.30541992, 18.3018055],
              [-65.30596924, 18.3018055],
              [-65.30596924, 18.30125046],
              [-65.30847168, 18.30125046],
              [-65.30847168, 18.3018055],
              [-65.30902863, 18.3018055],
              [-65.30902863, 18.30208206],
              [-65.30930328, 18.30208206],
              [-65.30930328, 18.30263901],
              [-65.31014252, 18.30263901],
              [-65.31014252, 18.30291748],
              [-65.31069183, 18.30291748],
              [-65.31069183, 18.30319595],
              [-65.31097412, 18.30319595],
              [-65.31097412, 18.30374908],
              [-65.31124878, 18.30374908],
              [-65.31124878, 18.30430412],
              [-65.31153107, 18.30430412],
              [-65.31153107, 18.3045826],
              [-65.31180573, 18.3045826],
              [-65.31180573, 18.30486107],
              [-65.31208038, 18.30486107],
              [-65.31208038, 18.30513954],
              [-65.31236267, 18.30513954],
              [-65.31236267, 18.30541611],
              [-65.31263733, 18.30541611],
              [-65.31263733, 18.30569458],
              [-65.31291962, 18.30569458],
              [-65.31291962, 18.30680466],
              [-65.31319427, 18.30680466],
              [-65.31319427, 18.30708313],
              [-65.31346893, 18.30708313],
              [-65.31346893, 18.30791664],
              [-65.31375122, 18.30791664],
              [-65.31375122, 18.30819511],
              [-65.31402588, 18.30819511],
              [-65.31402588, 18.30847168],
              [-65.31430817, 18.30847168],
              [-65.31430817, 18.30874825],
              [-65.31541443, 18.30874825],
              [-65.31541443, 18.30903053],
              [-65.31625366, 18.30903053],
              [-65.31625366, 18.30930519],
              [-65.31680298, 18.30930519],
              [-65.31680298, 18.3129158],
              [-65.31708527, 18.3129158],
              [-65.31708527, 18.31374931],
              [-65.31735992, 18.31374931],
              [-65.31735992, 18.31402779],
              [-65.31764221, 18.31402779],
              [-65.31764221, 18.31430626],
              [-65.31791687, 18.31430626],
              [-65.31791687, 18.3148613],
              [-65.31764221, 18.3148613],
              [-65.31764221, 18.31791687],
              [-65.31791687, 18.31791687],
              [-65.31791687, 18.31819534],
              [-65.31819153, 18.31819534],
              [-65.31819153, 18.31847191],
              [-65.31874847, 18.31847191],
              [-65.31874847, 18.31902695],
              [-65.32013702, 18.31902695],
              [-65.32013702, 18.31930542],
              [-65.32096863, 18.31930542],
              [-65.32096863, 18.31958389],
              [-65.32125092, 18.31958389],
              [-65.32125092, 18.31930542],
              [-65.32180786, 18.31930542],
              [-65.32180786, 18.31875038],
              [-65.32208252, 18.31875038],
              [-65.32208252, 18.31847191],
              [-65.32236481, 18.31847191],
              [-65.32236481, 18.3176384],
              [-65.32291412, 18.3176384],
              [-65.32291412, 18.32013893],
              [-65.32319641, 18.32013893],
              [-65.32319641, 18.32097244],
              [-65.32375336, 18.32097244],
              [-65.32375336, 18.32152748],
              [-65.32402802, 18.32152748],
              [-65.32402802, 18.32180595],
              [-65.32430267, 18.32180595],
              [-65.32430267, 18.32208252],
              [-65.32485962, 18.32208252],
              [-65.32485962, 18.32235909],
              [-65.32569122, 18.32235909],
              [-65.32569122, 18.32263756],
              [-65.32624817, 18.32263756],
              [-65.32624817, 18.32291603],
              [-65.32653046, 18.32291603],
              [-65.32653046, 18.3231945],
              [-65.32736206, 18.3231945],
              [-65.32736206, 18.32347298],
              [-65.32875061, 18.32347298],
              [-65.32875061, 18.3231945],
              [-65.32930756, 18.3231945],
              [-65.32930756, 18.32291603],
              [-65.32958221, 18.32291603],
              [-65.32958221, 18.32263756],
              [-65.3298645, 18.32263756],
              [-65.3298645, 18.32180595],
              [-65.33013916, 18.32180595],
              [-65.33013916, 18.32152748],
              [-65.33069611, 18.32152748],
              [-65.33069611, 18.32125092],
              [-65.33125305, 18.32125092],
              [-65.33125305, 18.32097244],
              [-65.33180237, 18.32097244],
              [-65.33180237, 18.32235909],
              [-65.33152771, 18.32235909],
              [-65.33152771, 18.32263756],
              [-65.33125305, 18.32263756],
              [-65.33125305, 18.32347298],
              [-65.33097076, 18.32347298],
              [-65.33097076, 18.32375145],
              [-65.33041382, 18.32375145],
              [-65.33041382, 18.32430649],
              [-65.33013916, 18.32430649],
              [-65.33013916, 18.32486153],
              [-65.3298645, 18.32486153],
              [-65.3298645, 18.32625008],
              [-65.33013916, 18.32625008],
              [-65.33013916, 18.32680511],
              [-65.33041382, 18.32680511],
              [-65.33041382, 18.32763863],
              [-65.33069611, 18.32763863],
              [-65.33069611, 18.3279171],
              [-65.33097076, 18.3279171],
              [-65.33097076, 18.32847214],
              [-65.33125305, 18.32847214],
              [-65.33125305, 18.32902718],
              [-65.33152771, 18.32902718],
              [-65.33152771, 18.32986069],
              [-65.33180237, 18.32986069],
              [-65.33180237, 18.33013916],
              [-65.33208466, 18.33013916],
              [-65.33208466, 18.3306942],
              [-65.3326416, 18.3306942],
              [-65.3326416, 18.33124924],
              [-65.33291626, 18.33124924],
              [-65.33291626, 18.33180428],
              [-65.33319092, 18.33180428],
              [-65.33319092, 18.33236122],
              [-65.33347321, 18.33236122],
              [-65.33347321, 18.33291626],
              [-65.33403015, 18.33291626],
              [-65.33403015, 18.33319473],
              [-65.33430481, 18.33319473],
              [-65.33430481, 18.3334713],
              [-65.3345871, 18.3334713],
              [-65.3345871, 18.33430481],
              [-65.33486176, 18.33430481],
              [-65.33486176, 18.33513832],
              [-65.3354187, 18.33513832],
              [-65.3354187, 18.33569527],
              [-65.33569336, 18.33569527],
              [-65.33569336, 18.33708382],
              [-65.33597565, 18.33708382],
              [-65.33597565, 18.33791733],
              [-65.33625031, 18.33791733],
              [-65.33625031, 18.33847237],
              [-65.33652496, 18.33847237],
              [-65.33652496, 18.33875275],
              [-65.33763885, 18.33875275],
              [-65.33763885, 18.3390274],
              [-65.3381958, 18.3390274],
              [-65.3381958, 18.33930588],
              [-65.33847046, 18.33930588],
              [-65.33847046, 18.33958054],
              [-65.33875275, 18.33958054],
              [-65.33875275, 18.34013939],
              [-65.3390274, 18.34013939],
              [-65.3390274, 18.34041595],
              [-65.33930206, 18.34041595],
              [-65.33930206, 18.34180641],
              [-65.33958435, 18.34180641],
              [-65.33958435, 18.34291649],
              [-65.33985901, 18.34291649],
              [-65.33985901, 18.34347153],
              [-65.3401413, 18.34347153],
              [-65.3401413, 18.34430695],
              [-65.34069824, 18.34430695],
              [-65.34069824, 18.34458351],
              [-65.3409729, 18.34458351],
              [-65.3409729, 18.34486198],
              [-65.34124756, 18.34486198],
              [-65.34124756, 18.34541512],
              [-65.3418045, 18.34541512],
              [-65.3418045, 18.34597206],
              [-65.34236145, 18.34597206],
              [-65.34236145, 18.34625053],
              [-65.3429184, 18.34625053],
              [-65.3429184, 18.34652519],
              [-65.34347534, 18.34652519],
              [-65.34347534, 18.34680557],
              [-65.34402466, 18.34680557],
              [-65.34402466, 18.34708405],
              [-65.34430695, 18.34708405],
              [-65.34430695, 18.34819412],
              [-65.34597015, 18.34819412],
              [-65.34597015, 18.3484726],
              [-65.3465271, 18.3484726],
              [-65.3465271, 18.34874725],
              [-65.34680176, 18.34874725],
              [-65.34680176, 18.34958267],
              [-65.34402466, 18.34958267],
              [-65.34402466, 18.34985924],
              [-65.34347534, 18.34985924],
              [-65.34347534, 18.34958267],
              [-65.34319305, 18.34958267],
              [-65.34319305, 18.34902763],
              [-65.34208679, 18.34902763],
              [-65.34208679, 18.3484726],
              [-65.3418045, 18.3484726],
              [-65.3418045, 18.34791946],
              [-65.3409729, 18.34791946],
              [-65.3409729, 18.3484726],
              [-65.34069824, 18.3484726],
              [-65.34069824, 18.34958267],
              [-65.34041595, 18.34958267],
              [-65.34041595, 18.34985924],
              [-65.33958435, 18.34985924],
              [-65.33958435, 18.35069466],
              [-65.33930206, 18.35069466],
              [-65.33930206, 18.35097313],
              [-65.33847046, 18.35097313],
            ],
          ],
          [
            [
              [-65.25597382, 18.34263802],
              [-65.25597382, 18.34236145],
              [-65.25514221, 18.34236145],
              [-65.25514221, 18.34208488],
              [-65.25402832, 18.34208488],
              [-65.25402832, 18.34180641],
              [-65.25347137, 18.34180641],
              [-65.25347137, 18.34152794],
              [-65.25319672, 18.34152794],
              [-65.25319672, 18.34124947],
              [-65.25291443, 18.34124947],
              [-65.25291443, 18.34097481],
              [-65.25208282, 18.34097481],
              [-65.25208282, 18.34069443],
              [-65.25152588, 18.34069443],
              [-65.25152588, 18.34041595],
              [-65.25125122, 18.34041595],
              [-65.25125122, 18.33986282],
              [-65.25096893, 18.33986282],
              [-65.25096893, 18.33875275],
              [-65.25069427, 18.33875275],
              [-65.25069427, 18.33847237],
              [-65.25013733, 18.33847237],
              [-65.25013733, 18.33819389],
              [-65.24986267, 18.33819389],
              [-65.24986267, 18.33764076],
              [-65.24930573, 18.33764076],
              [-65.24930573, 18.33735847],
              [-65.24874878, 18.33735847],
              [-65.24874878, 18.33708382],
              [-65.24764252, 18.33708382],
              [-65.24764252, 18.33680534],
              [-65.24736023, 18.33680534],
              [-65.24736023, 18.3362484],
              [-65.24624634, 18.3362484],
              [-65.24624634, 18.33708382],
              [-65.24597168, 18.33708382],
              [-65.24597168, 18.33791733],
              [-65.24485779, 18.33791733],
              [-65.24485779, 18.33764076],
              [-65.24458313, 18.33764076],
              [-65.24458313, 18.33708382],
              [-65.24485779, 18.33708382],
              [-65.24485779, 18.33652687],
              [-65.24514008, 18.33652687],
              [-65.24514008, 18.3362484],
              [-65.24541473, 18.3362484],
              [-65.24541473, 18.33597183],
              [-65.24597168, 18.33597183],
              [-65.24597168, 18.33569527],
              [-65.24624634, 18.33569527],
              [-65.24624634, 18.33513832],
              [-65.24597168, 18.33513832],
              [-65.24597168, 18.33486176],
              [-65.24569702, 18.33486176],
              [-65.24569702, 18.33402634],
              [-65.24597168, 18.33402634],
              [-65.24597168, 18.33374977],
              [-65.24680328, 18.33374977],
              [-65.24680328, 18.33319473],
              [-65.24819183, 18.33319473],
              [-65.24819183, 18.3334713],
              [-65.24874878, 18.3334713],
              [-65.24874878, 18.33374977],
              [-65.24958038, 18.33374977],
              [-65.24958038, 18.33402634],
              [-65.25041962, 18.33402634],
              [-65.25041962, 18.33430481],
              [-65.25208282, 18.33430481],
              [-65.25208282, 18.33402634],
              [-65.25263977, 18.33402634],
              [-65.25263977, 18.33374977],
              [-65.25319672, 18.33374977],
              [-65.25319672, 18.3334713],
              [-65.25347137, 18.3334713],
              [-65.25347137, 18.33319473],
              [-65.25375366, 18.33319473],
              [-65.25375366, 18.33291626],
              [-65.25791931, 18.33291626],
              [-65.25791931, 18.3334713],
              [-65.25958252, 18.3334713],
              [-65.25958252, 18.33374977],
              [-65.25986481, 18.33374977],
              [-65.25986481, 18.33402634],
              [-65.26013947, 18.33402634],
              [-65.26013947, 18.33430481],
              [-65.26041412, 18.33430481],
              [-65.26041412, 18.33458328],
              [-65.26069641, 18.33458328],
              [-65.26069641, 18.33513832],
              [-65.26125336, 18.33513832],
              [-65.26125336, 18.33541679],
              [-65.26152802, 18.33541679],
              [-65.26152802, 18.33597183],
              [-65.26180267, 18.33597183],
              [-65.26180267, 18.33652687],
              [-65.26264191, 18.33652687],
              [-65.26264191, 18.33708382],
              [-65.26291656, 18.33708382],
              [-65.26291656, 18.33735847],
              [-65.26319122, 18.33735847],
              [-65.26319122, 18.33764076],
              [-65.26347351, 18.33764076],
              [-65.26347351, 18.33791733],
              [-65.26374817, 18.33791733],
              [-65.26374817, 18.33819389],
              [-65.26430511, 18.33819389],
              [-65.26430511, 18.33847237],
              [-65.26486206, 18.33847237],
              [-65.26486206, 18.33875275],
              [-65.26597595, 18.33875275],
              [-65.26597595, 18.3390274],
              [-65.26625061, 18.3390274],
              [-65.26625061, 18.33986282],
              [-65.26652527, 18.33986282],
              [-65.26652527, 18.34041595],
              [-65.26680756, 18.34041595],
              [-65.26680756, 18.34124947],
              [-65.26625061, 18.34124947],
              [-65.26625061, 18.34152794],
              [-65.26597595, 18.34152794],
              [-65.26597595, 18.34180641],
              [-65.26541901, 18.34180641],
              [-65.26541901, 18.34208488],
              [-65.26291656, 18.34208488],
              [-65.26291656, 18.34152794],
              [-65.26152802, 18.34152794],
              [-65.26152802, 18.34208488],
              [-65.26125336, 18.34208488],
              [-65.26125336, 18.34236145],
              [-65.26097107, 18.34236145],
              [-65.26097107, 18.34263802],
              [-65.25597382, 18.34263802],
            ],
          ],
          [
            [
              [-65.23236084, 18.34041595],
              [-65.23236084, 18.34013939],
              [-65.23208618, 18.34013939],
              [-65.23208618, 18.33986282],
              [-65.23180389, 18.33986282],
              [-65.23180389, 18.33958054],
              [-65.23124695, 18.33958054],
              [-65.23124695, 18.33930588],
              [-65.23097229, 18.33930588],
              [-65.23097229, 18.33847237],
              [-65.23041534, 18.33847237],
              [-65.23041534, 18.33819389],
              [-65.22930908, 18.33819389],
              [-65.22930908, 18.33791733],
              [-65.22902679, 18.33791733],
              [-65.22902679, 18.33735847],
              [-65.22875214, 18.33735847],
              [-65.22875214, 18.33680534],
              [-65.22930908, 18.33680534],
              [-65.22930908, 18.33652687],
              [-65.23152924, 18.33652687],
              [-65.23152924, 18.33708382],
              [-65.23180389, 18.33708382],
              [-65.23180389, 18.33764076],
              [-65.23208618, 18.33764076],
              [-65.23208618, 18.33791733],
              [-65.23236084, 18.33791733],
              [-65.23236084, 18.33819389],
              [-65.23319244, 18.33819389],
              [-65.23319244, 18.33847237],
              [-65.23347473, 18.33847237],
              [-65.23347473, 18.34013939],
              [-65.23291779, 18.34013939],
              [-65.23291779, 18.34041595],
              [-65.23236084, 18.34041595],
            ],
          ],
          [
            [
              [-65.3923645, 18.33652687],
              [-65.3923645, 18.3362484],
              [-65.39125061, 18.3362484],
              [-65.39125061, 18.33513832],
              [-65.39097595, 18.33513832],
              [-65.39097595, 18.33486176],
              [-65.39041901, 18.33486176],
              [-65.39041901, 18.33458328],
              [-65.38986206, 18.33458328],
              [-65.38986206, 18.33319473],
              [-65.39069366, 18.33319473],
              [-65.39069366, 18.33291626],
              [-65.39097595, 18.33291626],
              [-65.39097595, 18.33263969],
              [-65.39125061, 18.33263969],
              [-65.39125061, 18.33236122],
              [-65.39097595, 18.33236122],
              [-65.39097595, 18.33097267],
              [-65.39152527, 18.33097267],
              [-65.39152527, 18.3306942],
              [-65.39208221, 18.3306942],
              [-65.39208221, 18.33124924],
              [-65.3923645, 18.33124924],
              [-65.3923645, 18.33263969],
              [-65.39263916, 18.33263969],
              [-65.39263916, 18.33319473],
              [-65.39319611, 18.33319473],
              [-65.39319611, 18.3334713],
              [-65.39347076, 18.3334713],
              [-65.39347076, 18.33402634],
              [-65.39291382, 18.33402634],
              [-65.39291382, 18.33430481],
              [-65.39263916, 18.33430481],
              [-65.39263916, 18.33513832],
              [-65.39291382, 18.33513832],
              [-65.39291382, 18.33541679],
              [-65.39319611, 18.33541679],
              [-65.39319611, 18.33652687],
              [-65.3923645, 18.33652687],
            ],
          ],
          [
            [
              [-65.37985992, 18.32652855],
              [-65.37985992, 18.3259697],
              [-65.37930298, 18.3259697],
              [-65.37930298, 18.32569504],
              [-65.37902832, 18.32569504],
              [-65.37902832, 18.32541656],
              [-65.37735748, 18.32541656],
              [-65.37735748, 18.32569504],
              [-65.37680817, 18.32569504],
              [-65.37680817, 18.3259697],
              [-65.37625122, 18.3259697],
              [-65.37625122, 18.32625008],
              [-65.37569427, 18.32625008],
              [-65.37569427, 18.3259697],
              [-65.37513733, 18.3259697],
              [-65.37513733, 18.32486153],
              [-65.37541962, 18.32486153],
              [-65.37541962, 18.32402992],
              [-65.37513733, 18.32402992],
              [-65.37513733, 18.32347298],
              [-65.37486267, 18.32347298],
              [-65.37486267, 18.32291603],
              [-65.37458038, 18.32291603],
              [-65.37458038, 18.32208252],
              [-65.37430573, 18.32208252],
              [-65.37430573, 18.32152748],
              [-65.37458038, 18.32152748],
              [-65.37458038, 18.32125092],
              [-65.37486267, 18.32125092],
              [-65.37486267, 18.32097244],
              [-65.37569427, 18.32097244],
              [-65.37569427, 18.32125092],
              [-65.37596893, 18.32125092],
              [-65.37596893, 18.32152748],
              [-65.37680817, 18.32152748],
              [-65.37680817, 18.32180595],
              [-65.37708282, 18.32180595],
              [-65.37708282, 18.32208252],
              [-65.37735748, 18.32208252],
              [-65.37735748, 18.32235909],
              [-65.37791443, 18.32235909],
              [-65.37791443, 18.32263756],
              [-65.37875366, 18.32263756],
              [-65.37875366, 18.32375145],
              [-65.37902832, 18.32375145],
              [-65.37902832, 18.32402992],
              [-65.37958527, 18.32402992],
              [-65.37958527, 18.32430649],
              [-65.37985992, 18.32430649],
              [-65.37985992, 18.32458115],
              [-65.38014221, 18.32458115],
              [-65.38014221, 18.32513809],
              [-65.38069153, 18.32513809],
              [-65.38069153, 18.3259697],
              [-65.38097382, 18.3259697],
              [-65.38097382, 18.32652855],
              [-65.37985992, 18.32652855],
            ],
          ],
          [
            [
              [-65.2270813, 18.32375145],
              [-65.2270813, 18.32347298],
              [-65.2251358, 18.32347298],
              [-65.2251358, 18.3231945],
              [-65.22486115, 18.3231945],
              [-65.22486115, 18.32291603],
              [-65.22458649, 18.32291603],
              [-65.22458649, 18.32263756],
              [-65.22374725, 18.32263756],
              [-65.22374725, 18.32235909],
              [-65.22291565, 18.32235909],
              [-65.22291565, 18.32208252],
              [-65.22208405, 18.32208252],
              [-65.22208405, 18.32180595],
              [-65.22180176, 18.32180595],
              [-65.22180176, 18.32152748],
              [-65.2215271, 18.32152748],
              [-65.2215271, 18.32013893],
              [-65.22180176, 18.32013893],
              [-65.22180176, 18.31986046],
              [-65.22208405, 18.31986046],
              [-65.22208405, 18.31902695],
              [-65.2223587, 18.31902695],
              [-65.2223587, 18.31791687],
              [-65.22264099, 18.31791687],
              [-65.22264099, 18.3176384],
              [-65.2251358, 18.3176384],
              [-65.2251358, 18.31736183],
              [-65.22541809, 18.31736183],
              [-65.22541809, 18.31597137],
              [-65.2251358, 18.31597137],
              [-65.2251358, 18.3148613],
              [-65.22486115, 18.3148613],
              [-65.22486115, 18.31402779],
              [-65.22402954, 18.31402779],
              [-65.22402954, 18.31458473],
              [-65.22319794, 18.31458473],
              [-65.22319794, 18.31430626],
              [-65.22291565, 18.31430626],
              [-65.22291565, 18.31374931],
              [-65.2234726, 18.31374931],
              [-65.2234726, 18.3129158],
              [-65.22374725, 18.3129158],
              [-65.22374725, 18.3120842],
              [-65.22208405, 18.3120842],
              [-65.22208405, 18.31263924],
              [-65.22097015, 18.31263924],
              [-65.22097015, 18.3120842],
              [-65.2206955, 18.3120842],
              [-65.2206955, 18.31069374],
              [-65.22180176, 18.31069374],
              [-65.22180176, 18.31041718],
              [-65.22208405, 18.31041718],
              [-65.22208405, 18.30985832],
              [-65.2251358, 18.30985832],
              [-65.2251358, 18.31014061],
              [-65.22680664, 18.31014061],
              [-65.22680664, 18.31041718],
              [-65.22902679, 18.31041718],
              [-65.22902679, 18.31069374],
              [-65.2298584, 18.31069374],
              [-65.2298584, 18.31097031],
              [-65.23014069, 18.31097031],
              [-65.23014069, 18.31125259],
              [-65.23041534, 18.31125259],
              [-65.23041534, 18.31152725],
              [-65.23069763, 18.31152725],
              [-65.23069763, 18.31263924],
              [-65.23097229, 18.31263924],
              [-65.23097229, 18.31374931],
              [-65.23124695, 18.31374931],
              [-65.23124695, 18.31430626],
              [-65.23152924, 18.31430626],
              [-65.23152924, 18.31541634],
              [-65.23208618, 18.31541634],
              [-65.23208618, 18.31569481],
              [-65.2326355, 18.31569481],
              [-65.2326355, 18.31597137],
              [-65.23347473, 18.31597137],
              [-65.23347473, 18.31624985],
              [-65.23374939, 18.31624985],
              [-65.23374939, 18.31652832],
              [-65.23402405, 18.31652832],
              [-65.23402405, 18.31680489],
              [-65.23430634, 18.31680489],
              [-65.23430634, 18.31708336],
              [-65.23569489, 18.31708336],
              [-65.23569489, 18.31736183],
              [-65.23652649, 18.31736183],
              [-65.23652649, 18.3176384],
              [-65.23680878, 18.3176384],
              [-65.23680878, 18.31791687],
              [-65.23735809, 18.31791687],
              [-65.23735809, 18.31819534],
              [-65.23791504, 18.31819534],
              [-65.23791504, 18.31847191],
              [-65.23819733, 18.31847191],
              [-65.23819733, 18.31902695],
              [-65.23847198, 18.31902695],
              [-65.23847198, 18.31986046],
              [-65.23819733, 18.31986046],
              [-65.23819733, 18.32041931],
              [-65.23791504, 18.32041931],
              [-65.23791504, 18.32097244],
              [-65.23708344, 18.32097244],
              [-65.23708344, 18.32125092],
              [-65.23402405, 18.32125092],
              [-65.23402405, 18.32097244],
              [-65.23319244, 18.32097244],
              [-65.23319244, 18.32069397],
              [-65.23097229, 18.32069397],
              [-65.23097229, 18.32041931],
              [-65.23069763, 18.32041931],
              [-65.23069763, 18.32013893],
              [-65.23041534, 18.32013893],
              [-65.23041534, 18.31930542],
              [-65.23014069, 18.31930542],
              [-65.23014069, 18.31875038],
              [-65.2298584, 18.31875038],
              [-65.2298584, 18.31847191],
              [-65.22930908, 18.31847191],
              [-65.22930908, 18.31791687],
              [-65.22819519, 18.31791687],
              [-65.22819519, 18.31847191],
              [-65.2270813, 18.31847191],
              [-65.2270813, 18.31875038],
              [-65.22680664, 18.31875038],
              [-65.22680664, 18.31930542],
              [-65.22624969, 18.31930542],
              [-65.22624969, 18.31986046],
              [-65.22652435, 18.31986046],
              [-65.22652435, 18.32013893],
              [-65.22680664, 18.32013893],
              [-65.22680664, 18.32152748],
              [-65.22736359, 18.32152748],
              [-65.22736359, 18.32180595],
              [-65.2279129, 18.32180595],
              [-65.2279129, 18.32235909],
              [-65.22902679, 18.32235909],
              [-65.22902679, 18.32347298],
              [-65.22819519, 18.32347298],
              [-65.22819519, 18.32375145],
              [-65.2270813, 18.32375145],
            ],
          ],
          [
            [
              [-65.32958221, 18.31624985],
              [-65.32958221, 18.31597137],
              [-65.32930756, 18.31597137],
              [-65.32930756, 18.31569481],
              [-65.32902527, 18.31569481],
              [-65.32902527, 18.31319427],
              [-65.32930756, 18.31319427],
              [-65.32930756, 18.3129158],
              [-65.32958221, 18.3129158],
              [-65.32958221, 18.31236267],
              [-65.3298645, 18.31236267],
              [-65.3298645, 18.31097031],
              [-65.32958221, 18.31097031],
              [-65.32958221, 18.31069374],
              [-65.32930756, 18.31069374],
              [-65.32930756, 18.31041718],
              [-65.32902527, 18.31041718],
              [-65.32902527, 18.31014061],
              [-65.32847595, 18.31014061],
              [-65.32847595, 18.30985832],
              [-65.32819366, 18.30985832],
              [-65.32819366, 18.30958366],
              [-65.32791901, 18.30958366],
              [-65.32791901, 18.30874825],
              [-65.32763672, 18.30874825],
              [-65.32763672, 18.30847168],
              [-65.32736206, 18.30847168],
              [-65.32736206, 18.30763626],
              [-65.32707977, 18.30763626],
              [-65.32707977, 18.30680466],
              [-65.32736206, 18.30680466],
              [-65.32736206, 18.30652618],
              [-65.32763672, 18.30652618],
              [-65.32763672, 18.30624962],
              [-65.32791901, 18.30624962],
              [-65.32791901, 18.30597305],
              [-65.32819366, 18.30597305],
              [-65.32819366, 18.30374908],
              [-65.32791901, 18.30374908],
              [-65.32791901, 18.30319595],
              [-65.32763672, 18.30319595],
              [-65.32763672, 18.30125046],
              [-65.32819366, 18.30125046],
              [-65.32819366, 18.30069351],
              [-65.32847595, 18.30069351],
              [-65.32847595, 18.30041695],
              [-65.32902527, 18.30041695],
              [-65.32902527, 18.29958344],
              [-65.32930756, 18.29958344],
              [-65.32930756, 18.2990284],
              [-65.32958221, 18.2990284],
              [-65.32958221, 18.29875183],
              [-65.32902527, 18.29875183],
              [-65.32902527, 18.29846954],
              [-65.32847595, 18.29846954],
              [-65.32847595, 18.29819489],
              [-65.32791901, 18.29819489],
              [-65.32791901, 18.29735947],
              [-65.32763672, 18.29735947],
              [-65.32763672, 18.29680634],
              [-65.32736206, 18.29680634],
              [-65.32736206, 18.29513741],
              [-65.32763672, 18.29513741],
              [-65.32763672, 18.29458427],
              [-65.32791901, 18.29458427],
              [-65.32791901, 18.2943058],
              [-65.3298645, 18.2943058],
              [-65.3298645, 18.29458427],
              [-65.33013916, 18.29458427],
              [-65.33013916, 18.29486084],
              [-65.33069611, 18.29486084],
              [-65.33069611, 18.29513741],
              [-65.33097076, 18.29513741],
              [-65.33097076, 18.29541588],
              [-65.33125305, 18.29541588],
              [-65.33125305, 18.29569435],
              [-65.33152771, 18.29569435],
              [-65.33152771, 18.29624748],
              [-65.33180237, 18.29624748],
              [-65.33180237, 18.2970829],
              [-65.33208466, 18.2970829],
              [-65.33208466, 18.29875183],
              [-65.33180237, 18.29875183],
              [-65.33180237, 18.30013847],
              [-65.33208466, 18.30013847],
              [-65.33208466, 18.30041695],
              [-65.33235931, 18.30041695],
              [-65.33235931, 18.30069351],
              [-65.3326416, 18.30069351],
              [-65.3326416, 18.30097389],
              [-65.33291626, 18.30097389],
              [-65.33291626, 18.30125046],
              [-65.33347321, 18.30125046],
              [-65.33347321, 18.30152702],
              [-65.33374786, 18.30152702],
              [-65.33374786, 18.3018055],
              [-65.33625031, 18.3018055],
              [-65.33625031, 18.30152702],
              [-65.33680725, 18.30152702],
              [-65.33680725, 18.30125046],
              [-65.3373642, 18.30125046],
              [-65.3373642, 18.30097389],
              [-65.33763885, 18.30097389],
              [-65.33763885, 18.30069351],
              [-65.33847046, 18.30069351],
              [-65.33847046, 18.30097389],
              [-65.33875275, 18.30097389],
              [-65.33875275, 18.30208206],
              [-65.3381958, 18.30208206],
              [-65.3381958, 18.30263901],
              [-65.33763885, 18.30263901],
              [-65.33763885, 18.30347252],
              [-65.3373642, 18.30347252],
              [-65.3373642, 18.30430412],
              [-65.33708191, 18.30430412],
              [-65.33708191, 18.30624962],
              [-65.3373642, 18.30624962],
              [-65.3373642, 18.30680466],
              [-65.33763885, 18.30680466],
              [-65.33763885, 18.30763626],
              [-65.33791351, 18.30763626],
              [-65.33791351, 18.30819511],
              [-65.3381958, 18.30819511],
              [-65.3381958, 18.30847168],
              [-65.3390274, 18.30847168],
              [-65.3390274, 18.30903053],
              [-65.33958435, 18.30903053],
              [-65.33958435, 18.30930519],
              [-65.34041595, 18.30930519],
              [-65.34041595, 18.30958366],
              [-65.34069824, 18.30958366],
              [-65.34069824, 18.30985832],
              [-65.34041595, 18.30985832],
              [-65.34041595, 18.31014061],
              [-65.3401413, 18.31014061],
              [-65.3401413, 18.31041718],
              [-65.33875275, 18.31041718],
              [-65.33875275, 18.31014061],
              [-65.3381958, 18.31014061],
              [-65.3381958, 18.30985832],
              [-65.33791351, 18.30985832],
              [-65.33791351, 18.30958366],
              [-65.3373642, 18.30958366],
              [-65.3373642, 18.30930519],
              [-65.33680725, 18.30930519],
              [-65.33680725, 18.30985832],
              [-65.33652496, 18.30985832],
              [-65.33652496, 18.31069374],
              [-65.33513641, 18.31069374],
              [-65.33513641, 18.31097031],
              [-65.33430481, 18.31097031],
              [-65.33430481, 18.31125259],
              [-65.33319092, 18.31125259],
              [-65.33319092, 18.31152725],
              [-65.33291626, 18.31152725],
              [-65.33291626, 18.31180573],
              [-65.3326416, 18.31180573],
              [-65.3326416, 18.31347275],
              [-65.33208466, 18.31347275],
              [-65.33208466, 18.31374931],
              [-65.33180237, 18.31374931],
              [-65.33180237, 18.31430626],
              [-65.33152771, 18.31430626],
              [-65.33152771, 18.31541634],
              [-65.33125305, 18.31541634],
              [-65.33125305, 18.31569481],
              [-65.33097076, 18.31569481],
              [-65.33097076, 18.31597137],
              [-65.33041382, 18.31597137],
              [-65.33041382, 18.31624985],
              [-65.32958221, 18.31624985],
            ],
          ],
          [
            [
              [-65.25013733, 18.29846954],
              [-65.25013733, 18.29819489],
              [-65.24874878, 18.29819489],
              [-65.24874878, 18.29791641],
              [-65.24847412, 18.29791641],
              [-65.24847412, 18.29680634],
              [-65.24874878, 18.29680634],
              [-65.24874878, 18.29624748],
              [-65.25041962, 18.29624748],
              [-65.25041962, 18.29652977],
              [-65.25096893, 18.29652977],
              [-65.25096893, 18.29680634],
              [-65.25125122, 18.29680634],
              [-65.25125122, 18.2970829],
              [-65.25152588, 18.2970829],
              [-65.25152588, 18.29735947],
              [-65.25180817, 18.29735947],
              [-65.25180817, 18.29763985],
              [-65.25152588, 18.29763985],
              [-65.25152588, 18.29819489],
              [-65.25125122, 18.29819489],
              [-65.25125122, 18.29846954],
              [-65.25013733, 18.29846954],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 25,
        name: 'Culebra',
        hasc_1: 'PR.CU',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-65.39347076, 18.2765274, -65.2206955, 18.35097313],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.27',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-65.59764099, 18.39847183],
              [-65.59764099, 18.39819527],
              [-65.5965271, 18.39819527],
              [-65.5965271, 18.39736176],
              [-65.59680176, 18.39736176],
              [-65.59680176, 18.39680481],
              [-65.59708405, 18.39680481],
              [-65.59708405, 18.39652824],
              [-65.5973587, 18.39652824],
              [-65.5973587, 18.39569473],
              [-65.59902954, 18.39569473],
              [-65.59902954, 18.39736176],
              [-65.59958649, 18.39736176],
              [-65.59958649, 18.39763832],
              [-65.59986115, 18.39763832],
              [-65.59986115, 18.39819527],
              [-65.5993042, 18.39819527],
              [-65.5993042, 18.39847183],
              [-65.59764099, 18.39847183],
            ],
          ],
          [
            [
              [-65.59208679, 18.39180565],
              [-65.59208679, 18.39097023],
              [-65.59069824, 18.39097023],
              [-65.59069824, 18.39069366],
              [-65.59041595, 18.39069366],
              [-65.59041595, 18.39097023],
              [-65.58985901, 18.39097023],
              [-65.58985901, 18.39069366],
              [-65.5890274, 18.39069366],
              [-65.5890274, 18.3904171],
              [-65.58847046, 18.3904171],
              [-65.58847046, 18.39014053],
              [-65.58791351, 18.39014053],
              [-65.58791351, 18.38986015],
              [-65.58763885, 18.38986015],
              [-65.58763885, 18.38958359],
              [-65.5873642, 18.38958359],
              [-65.5873642, 18.38930511],
              [-65.58625031, 18.38930511],
              [-65.58625031, 18.38903046],
              [-65.58569336, 18.38903046],
              [-65.58569336, 18.38874817],
              [-65.5845871, 18.38874817],
              [-65.5845871, 18.38819504],
              [-65.58430481, 18.38819504],
              [-65.58430481, 18.38791656],
              [-65.58403015, 18.38791656],
              [-65.58403015, 18.38763618],
              [-65.58374786, 18.38763618],
              [-65.58374786, 18.38736153],
              [-65.58347321, 18.38736153],
              [-65.58347321, 18.38708305],
              [-65.58291626, 18.38708305],
              [-65.58291626, 18.38680649],
              [-65.5826416, 18.38680649],
              [-65.5826416, 18.38652611],
              [-65.58208466, 18.38652611],
              [-65.58208466, 18.38624954],
              [-65.58152771, 18.38624954],
              [-65.58152771, 18.38597298],
              [-65.58097076, 18.38597298],
              [-65.58097076, 18.3856945],
              [-65.5798645, 18.3856945],
              [-65.5798645, 18.38513947],
              [-65.57958221, 18.38513947],
              [-65.57958221, 18.38430405],
              [-65.57930756, 18.38430405],
              [-65.57930756, 18.38402748],
              [-65.57902527, 18.38402748],
              [-65.57902527, 18.38375092],
              [-65.57847595, 18.38375092],
              [-65.57847595, 18.38319588],
              [-65.57819366, 18.38319588],
              [-65.57819366, 18.38208389],
              [-65.57847595, 18.38208389],
              [-65.57847595, 18.38097382],
              [-65.57875061, 18.38097382],
              [-65.57875061, 18.38069534],
              [-65.57902527, 18.38069534],
              [-65.57902527, 18.38041687],
              [-65.5798645, 18.38041687],
              [-65.5798645, 18.38097382],
              [-65.58041382, 18.38097382],
              [-65.58041382, 18.38125038],
              [-65.58152771, 18.38125038],
              [-65.58152771, 18.38152695],
              [-65.58180237, 18.38152695],
              [-65.58180237, 18.38180542],
              [-65.58152771, 18.38180542],
              [-65.58152771, 18.38263893],
              [-65.58013916, 18.38263893],
              [-65.58013916, 18.3829174],
              [-65.5798645, 18.3829174],
              [-65.5798645, 18.38319588],
              [-65.58013916, 18.38319588],
              [-65.58013916, 18.38347244],
              [-65.58069611, 18.38347244],
              [-65.58069611, 18.38402748],
              [-65.58097076, 18.38402748],
              [-65.58097076, 18.38486099],
              [-65.58125305, 18.38486099],
              [-65.58125305, 18.38458252],
              [-65.5826416, 18.38458252],
              [-65.5826416, 18.38513947],
              [-65.58291626, 18.38513947],
              [-65.58291626, 18.38541412],
              [-65.58347321, 18.38541412],
              [-65.58347321, 18.38513947],
              [-65.58374786, 18.38513947],
              [-65.58374786, 18.38458252],
              [-65.58430481, 18.38458252],
              [-65.58430481, 18.38430405],
              [-65.58403015, 18.38430405],
              [-65.58403015, 18.38319588],
              [-65.58430481, 18.38319588],
              [-65.58430481, 18.3829174],
              [-65.5845871, 18.3829174],
              [-65.5845871, 18.38236046],
              [-65.58513641, 18.38236046],
              [-65.58513641, 18.38180542],
              [-65.58763885, 18.38180542],
              [-65.58763885, 18.38208389],
              [-65.58791351, 18.38208389],
              [-65.58791351, 18.38236046],
              [-65.5881958, 18.38236046],
              [-65.5881958, 18.38263893],
              [-65.58930206, 18.38263893],
              [-65.58930206, 18.3829174],
              [-65.58985901, 18.3829174],
              [-65.58985901, 18.38319588],
              [-65.5901413, 18.38319588],
              [-65.5901413, 18.38347244],
              [-65.59069824, 18.38347244],
              [-65.59069824, 18.38402748],
              [-65.5909729, 18.38402748],
              [-65.5909729, 18.38430405],
              [-65.59124756, 18.38430405],
              [-65.59124756, 18.38513947],
              [-65.59152985, 18.38513947],
              [-65.59152985, 18.38680649],
              [-65.5918045, 18.38680649],
              [-65.5918045, 18.38763618],
              [-65.59208679, 18.38763618],
              [-65.59208679, 18.38791656],
              [-65.59236145, 18.38791656],
              [-65.59236145, 18.3884716],
              [-65.59263611, 18.3884716],
              [-65.59263611, 18.38903046],
              [-65.5929184, 18.38903046],
              [-65.5929184, 18.38930511],
              [-65.59319305, 18.38930511],
              [-65.59319305, 18.38986015],
              [-65.59347534, 18.38986015],
              [-65.59347534, 18.39069366],
              [-65.59402466, 18.39069366],
              [-65.59402466, 18.39097023],
              [-65.59430695, 18.39097023],
              [-65.59430695, 18.3912487],
              [-65.59402466, 18.3912487],
              [-65.59402466, 18.39152718],
              [-65.59319305, 18.39152718],
              [-65.59319305, 18.39208412],
              [-65.59263611, 18.39208412],
              [-65.59263611, 18.39236259],
              [-65.59152985, 18.39236259],
              [-65.59152985, 18.39180565],
              [-65.59208679, 18.39180565],
            ],
          ],
          [
            [
              [-65.66950226, 18.36208344],
              [-65.66874695, 18.36208344],
              [-65.66874695, 18.36180496],
              [-65.66652679, 18.36180496],
              [-65.66652679, 18.36208344],
              [-65.66596985, 18.36208344],
              [-65.66596985, 18.36236191],
              [-65.6645813, 18.36236191],
              [-65.6645813, 18.36208344],
              [-65.66374969, 18.36208344],
              [-65.66374969, 18.36236191],
              [-65.66236115, 18.36236191],
              [-65.66236115, 18.36263847],
              [-65.66208649, 18.36263847],
              [-65.66208649, 18.36291885],
              [-65.66124725, 18.36291885],
              [-65.66124725, 18.36319351],
              [-65.66014099, 18.36319351],
              [-65.66014099, 18.36347008],
              [-65.65930176, 18.36347008],
              [-65.65930176, 18.36374855],
              [-65.65847015, 18.36374855],
              [-65.65847015, 18.36402702],
              [-65.65736389, 18.36402702],
              [-65.65736389, 18.36458397],
              [-65.65680695, 18.36458397],
              [-65.65680695, 18.3643055],
              [-65.65597534, 18.3643055],
              [-65.65597534, 18.36458397],
              [-65.65513611, 18.36458397],
              [-65.65513611, 18.36486244],
              [-65.65486145, 18.36486244],
              [-65.65486145, 18.36514091],
              [-65.65458679, 18.36514091],
              [-65.65458679, 18.36541748],
              [-65.6543045, 18.36541748],
              [-65.6543045, 18.36569405],
              [-65.65402985, 18.36569405],
              [-65.65402985, 18.36597252],
              [-65.65374756, 18.36597252],
              [-65.65374756, 18.36624908],
              [-65.65319824, 18.36624908],
              [-65.65319824, 18.36652756],
              [-65.65291595, 18.36652756],
              [-65.65291595, 18.36680603],
              [-65.6526413, 18.36680603],
              [-65.6526413, 18.3670826],
              [-65.65235901, 18.3670826],
              [-65.65235901, 18.36736107],
              [-65.65208435, 18.36736107],
              [-65.65208435, 18.36791611],
              [-65.65235901, 18.36791611],
              [-65.65235901, 18.36847305],
              [-65.65208435, 18.36847305],
              [-65.65208435, 18.36902809],
              [-65.65180206, 18.36902809],
              [-65.65180206, 18.37069511],
              [-65.6515274, 18.37069511],
              [-65.6515274, 18.37097168],
              [-65.65125275, 18.37097168],
              [-65.65125275, 18.37125015],
              [-65.6506958, 18.37125015],
              [-65.6506958, 18.37097168],
              [-65.65041351, 18.37097168],
              [-65.65041351, 18.37125015],
              [-65.64958191, 18.37125015],
              [-65.64958191, 18.37180519],
              [-65.64930725, 18.37180519],
              [-65.64930725, 18.37236023],
              [-65.64902496, 18.37236023],
              [-65.64902496, 18.37291527],
              [-65.64875031, 18.37291527],
              [-65.64875031, 18.37347221],
              [-65.64847565, 18.37347221],
              [-65.64847565, 18.37402725],
              [-65.64819336, 18.37402725],
              [-65.64819336, 18.37486076],
              [-65.6479187, 18.37486076],
              [-65.6479187, 18.37513733],
              [-65.64736176, 18.37513733],
              [-65.64736176, 18.3754158],
              [-65.6470871, 18.3754158],
              [-65.6470871, 18.37569427],
              [-65.64624786, 18.37569427],
              [-65.64624786, 18.37597275],
              [-65.64569092, 18.37597275],
              [-65.64569092, 18.37624741],
              [-65.64541626, 18.37624741],
              [-65.64541626, 18.37652969],
              [-65.6451416, 18.37652969],
              [-65.6451416, 18.37680626],
              [-65.64485931, 18.37680626],
              [-65.64485931, 18.37735939],
              [-65.64458466, 18.37735939],
              [-65.64458466, 18.37791634],
              [-65.64430237, 18.37791634],
              [-65.64430237, 18.37819481],
              [-65.64402771, 18.37819481],
              [-65.64402771, 18.37846947],
              [-65.64375305, 18.37846947],
              [-65.64375305, 18.37958336],
              [-65.64402771, 18.37958336],
              [-65.64402771, 18.37986374],
              [-65.64430237, 18.37986374],
              [-65.64430237, 18.38097382],
              [-65.64319611, 18.38097382],
              [-65.64319611, 18.38069534],
              [-65.64291382, 18.38069534],
              [-65.64291382, 18.38041687],
              [-65.64263916, 18.38041687],
              [-65.64263916, 18.3801384],
              [-65.6423645, 18.3801384],
              [-65.6423645, 18.37958336],
              [-65.64208221, 18.37958336],
              [-65.64208221, 18.37819481],
              [-65.6423645, 18.37819481],
              [-65.6423645, 18.37735939],
              [-65.64208221, 18.37735939],
              [-65.64208221, 18.37708282],
              [-65.64152527, 18.37708282],
              [-65.64152527, 18.37680626],
              [-65.64041901, 18.37680626],
              [-65.64041901, 18.37708282],
              [-65.63957977, 18.37708282],
              [-65.63957977, 18.37680626],
              [-65.63930511, 18.37680626],
              [-65.63930511, 18.37652969],
              [-65.63903046, 18.37652969],
              [-65.63903046, 18.37624741],
              [-65.63930511, 18.37624741],
              [-65.63930511, 18.37513733],
              [-65.63957977, 18.37513733],
              [-65.63957977, 18.3745842],
              [-65.63986206, 18.3745842],
              [-65.63986206, 18.37402725],
              [-65.64041901, 18.37402725],
              [-65.64041901, 18.37208366],
              [-65.64013672, 18.37208366],
              [-65.64013672, 18.37152863],
              [-65.63986206, 18.37152863],
              [-65.63986206, 18.37125015],
              [-65.63957977, 18.37125015],
              [-65.63957977, 18.37069511],
              [-65.63930511, 18.37069511],
              [-65.63930511, 18.37041664],
              [-65.63903046, 18.37041664],
              [-65.63903046, 18.3698616],
              [-65.63874817, 18.3698616],
              [-65.63874817, 18.36958313],
              [-65.63847351, 18.36958313],
              [-65.63847351, 18.36930466],
              [-65.63625336, 18.36930466],
              [-65.63625336, 18.36902809],
              [-65.63597107, 18.36902809],
              [-65.63597107, 18.36874962],
              [-65.63569641, 18.36874962],
              [-65.63569641, 18.36930466],
              [-65.63486481, 18.36930466],
              [-65.63486481, 18.36958313],
              [-65.63430786, 18.36958313],
              [-65.63430786, 18.3698616],
              [-65.63402557, 18.3698616],
              [-65.63402557, 18.37013817],
              [-65.63319397, 18.37013817],
              [-65.63319397, 18.37041664],
              [-65.63263702, 18.37041664],
              [-65.63263702, 18.37069511],
              [-65.63236237, 18.37069511],
              [-65.63236237, 18.37097168],
              [-65.63208008, 18.37097168],
              [-65.63208008, 18.37125015],
              [-65.63180542, 18.37125015],
              [-65.63180542, 18.37152863],
              [-65.63153076, 18.37152863],
              [-65.63153076, 18.37180519],
              [-65.63124847, 18.37180519],
              [-65.63124847, 18.37208366],
              [-65.63069153, 18.37208366],
              [-65.63069153, 18.37236023],
              [-65.63041687, 18.37236023],
              [-65.63041687, 18.3726387],
              [-65.63014221, 18.3726387],
              [-65.63014221, 18.37291527],
              [-65.62958527, 18.37291527],
              [-65.62958527, 18.37319374],
              [-65.62930298, 18.37319374],
              [-65.62930298, 18.37375069],
              [-65.62902832, 18.37375069],
              [-65.62902832, 18.37402725],
              [-65.62875366, 18.37402725],
              [-65.62875366, 18.37430573],
              [-65.62819672, 18.37430573],
              [-65.62819672, 18.37486076],
              [-65.62791443, 18.37486076],
              [-65.62791443, 18.37513733],
              [-65.62763977, 18.37513733],
              [-65.62763977, 18.3754158],
              [-65.62735748, 18.3754158],
              [-65.62735748, 18.37597275],
              [-65.62708282, 18.37597275],
              [-65.62708282, 18.37680626],
              [-65.62680817, 18.37680626],
              [-65.62680817, 18.37708282],
              [-65.62652588, 18.37708282],
              [-65.62652588, 18.37875175],
              [-65.62680817, 18.37875175],
              [-65.62680817, 18.37930489],
              [-65.62708282, 18.37930489],
              [-65.62708282, 18.38041687],
              [-65.62680817, 18.38041687],
              [-65.62680817, 18.38125038],
              [-65.62652588, 18.38125038],
              [-65.62652588, 18.38180542],
              [-65.62596893, 18.38180542],
              [-65.62596893, 18.38208389],
              [-65.62569427, 18.38208389],
              [-65.62569427, 18.38263893],
              [-65.62513733, 18.38263893],
              [-65.62513733, 18.3829174],
              [-65.62486267, 18.3829174],
              [-65.62486267, 18.38319588],
              [-65.62347412, 18.38319588],
              [-65.62347412, 18.3856945],
              [-65.62374878, 18.3856945],
              [-65.62374878, 18.38513947],
              [-65.62430573, 18.38513947],
              [-65.62430573, 18.3856945],
              [-65.62486267, 18.3856945],
              [-65.62486267, 18.38680649],
              [-65.62458038, 18.38680649],
              [-65.62458038, 18.38736153],
              [-65.62430573, 18.38736153],
              [-65.62430573, 18.38763618],
              [-65.62208557, 18.38763618],
              [-65.62208557, 18.38736153],
              [-65.62152863, 18.38736153],
              [-65.62152863, 18.38680649],
              [-65.62124634, 18.38680649],
              [-65.62124634, 18.38652611],
              [-65.62097168, 18.38652611],
              [-65.62097168, 18.38624954],
              [-65.62069702, 18.38624954],
              [-65.62069702, 18.38652611],
              [-65.62041473, 18.38652611],
              [-65.62041473, 18.38708305],
              [-65.62014008, 18.38708305],
              [-65.62014008, 18.38736153],
              [-65.61958313, 18.38736153],
              [-65.61958313, 18.38763618],
              [-65.61930847, 18.38763618],
              [-65.61930847, 18.38791656],
              [-65.61875153, 18.38791656],
              [-65.61875153, 18.38819504],
              [-65.61763763, 18.38819504],
              [-65.61763763, 18.38791656],
              [-65.61736298, 18.38791656],
              [-65.61736298, 18.38652611],
              [-65.61708069, 18.38652611],
              [-65.61708069, 18.38597298],
              [-65.61680603, 18.38597298],
              [-65.61680603, 18.38541412],
              [-65.61653137, 18.38541412],
              [-65.61653137, 18.38486099],
              [-65.61569214, 18.38486099],
              [-65.61569214, 18.38458252],
              [-65.61541748, 18.38458252],
              [-65.61541748, 18.38430405],
              [-65.61458588, 18.38430405],
              [-65.61458588, 18.38458252],
              [-65.61374664, 18.38458252],
              [-65.61374664, 18.38430405],
              [-65.61347198, 18.38430405],
              [-65.61347198, 18.38347244],
              [-65.61374664, 18.38347244],
              [-65.61374664, 18.38319588],
              [-65.61402893, 18.38319588],
              [-65.61402893, 18.3829174],
              [-65.61430359, 18.3829174],
              [-65.61430359, 18.38208389],
              [-65.61374664, 18.38208389],
              [-65.61374664, 18.38069534],
              [-65.61430359, 18.38069534],
              [-65.61430359, 18.38041687],
              [-65.61486053, 18.38041687],
              [-65.61486053, 18.3801384],
              [-65.61513519, 18.3801384],
              [-65.61513519, 18.37986374],
              [-65.61597443, 18.37986374],
              [-65.61597443, 18.37930489],
              [-65.61653137, 18.37930489],
              [-65.61653137, 18.37846947],
              [-65.61680603, 18.37846947],
              [-65.61680603, 18.37819481],
              [-65.61708069, 18.37819481],
              [-65.61708069, 18.37402725],
              [-65.61736298, 18.37402725],
              [-65.61736298, 18.37236023],
              [-65.61763763, 18.37236023],
              [-65.61763763, 18.37013817],
              [-65.61791992, 18.37013817],
              [-65.61791992, 18.36958313],
              [-65.61819458, 18.36958313],
              [-65.61819458, 18.36874962],
              [-65.61846924, 18.36874962],
              [-65.61846924, 18.36791611],
              [-65.61875153, 18.36791611],
              [-65.61875153, 18.3670826],
              [-65.61902618, 18.3670826],
              [-65.61902618, 18.36624908],
              [-65.61930847, 18.36624908],
              [-65.61930847, 18.36597252],
              [-65.62014008, 18.36597252],
              [-65.62014008, 18.36569405],
              [-65.62097168, 18.36569405],
              [-65.62097168, 18.36541748],
              [-65.62180328, 18.36541748],
              [-65.62180328, 18.36514091],
              [-65.62347412, 18.36514091],
              [-65.62347412, 18.36486244],
              [-65.62374878, 18.36486244],
              [-65.62374878, 18.36458397],
              [-65.62458038, 18.36458397],
              [-65.62458038, 18.3643055],
              [-65.62486267, 18.3643055],
              [-65.62486267, 18.36374855],
              [-65.62458038, 18.36374855],
              [-65.62458038, 18.36319351],
              [-65.62430573, 18.36319351],
              [-65.62430573, 18.36291885],
              [-65.62403107, 18.36291885],
              [-65.62403107, 18.36069489],
              [-65.62374878, 18.36069489],
              [-65.62374878, 18.36013794],
              [-65.62347412, 18.36013794],
              [-65.62347412, 18.3595829],
              [-65.62319183, 18.3595829],
              [-65.62319183, 18.35847282],
              [-65.62347412, 18.35847282],
              [-65.62347412, 18.35819435],
              [-65.62403107, 18.35819435],
              [-65.62403107, 18.35763931],
              [-65.62430573, 18.35763931],
              [-65.62430573, 18.35708427],
              [-65.62458038, 18.35708427],
              [-65.62458038, 18.35625076],
              [-65.62486267, 18.35625076],
              [-65.62486267, 18.35569382],
              [-65.62541962, 18.35569382],
              [-65.62541962, 18.35486031],
              [-65.62596893, 18.35486031],
              [-65.62596893, 18.35458374],
              [-65.62625122, 18.35458374],
              [-65.62625122, 18.35430527],
              [-65.62680817, 18.35430527],
              [-65.62680817, 18.3540287],
              [-65.62735748, 18.3540287],
              [-65.62735748, 18.35375023],
              [-65.62763977, 18.35375023],
              [-65.62763977, 18.35347366],
              [-65.62791443, 18.35347366],
              [-65.62791443, 18.35319519],
              [-65.62819672, 18.35319519],
              [-65.62819672, 18.35291672],
              [-65.62847137, 18.35291672],
              [-65.62847137, 18.35263824],
              [-65.62958527, 18.35263824],
              [-65.62958527, 18.35236168],
              [-65.62985992, 18.35236168],
              [-65.62985992, 18.35208321],
              [-65.63041687, 18.35208321],
              [-65.63041687, 18.35152817],
              [-65.63124847, 18.35152817],
              [-65.63124847, 18.3512516],
              [-65.63180542, 18.3512516],
              [-65.63180542, 18.35069466],
              [-65.63208008, 18.35069466],
              [-65.63208008, 18.35041618],
              [-65.63236237, 18.35041618],
              [-65.63236237, 18.35014153],
              [-65.63291931, 18.35014153],
              [-65.63291931, 18.34930611],
              [-65.63319397, 18.34930611],
              [-65.63319397, 18.34680557],
              [-65.63291931, 18.34680557],
              [-65.63291931, 18.34541512],
              [-65.63346863, 18.34541512],
              [-65.63346863, 18.34569359],
              [-65.63375092, 18.34569359],
              [-65.63375092, 18.34597206],
              [-65.63625336, 18.34597206],
              [-65.63625336, 18.34513855],
              [-65.63597107, 18.34513855],
              [-65.63597107, 18.34458351],
              [-65.63569641, 18.34458351],
              [-65.63569641, 18.34430695],
              [-65.63486481, 18.34430695],
              [-65.63486481, 18.34402847],
              [-65.63458252, 18.34402847],
              [-65.63458252, 18.34375],
              [-65.63097382, 18.34375],
              [-65.63097382, 18.34347153],
              [-65.63069153, 18.34347153],
              [-65.63069153, 18.34319305],
              [-65.63041687, 18.34319305],
              [-65.63041687, 18.34236145],
              [-65.63069153, 18.34236145],
              [-65.63069153, 18.34041595],
              [-65.63097382, 18.34041595],
              [-65.63097382, 18.34013939],
              [-65.63180542, 18.34013939],
              [-65.63180542, 18.33986282],
              [-65.63236237, 18.33986282],
              [-65.63236237, 18.33958054],
              [-65.63291931, 18.33958054],
              [-65.63291931, 18.33930588],
              [-65.63346863, 18.33930588],
              [-65.63346863, 18.33819389],
              [-65.63375092, 18.33819389],
              [-65.63375092, 18.33735847],
              [-65.63319397, 18.33735847],
              [-65.63319397, 18.33708382],
              [-65.63291931, 18.33708382],
              [-65.63291931, 18.33680534],
              [-65.63263702, 18.33680534],
              [-65.63263702, 18.33652687],
              [-65.63291931, 18.33652687],
              [-65.63291931, 18.3362484],
              [-65.63263702, 18.3362484],
              [-65.63263702, 18.33597183],
              [-65.63153076, 18.33597183],
              [-65.63153076, 18.3362484],
              [-65.63014221, 18.3362484],
              [-65.63014221, 18.33597183],
              [-65.62985992, 18.33597183],
              [-65.62985992, 18.33541679],
              [-65.62958527, 18.33541679],
              [-65.62958527, 18.33458328],
              [-65.62930298, 18.33458328],
              [-65.62930298, 18.33430481],
              [-65.62875366, 18.33430481],
              [-65.62875366, 18.3334713],
              [-65.62847137, 18.3334713],
              [-65.62847137, 18.33291626],
              [-65.62763977, 18.33291626],
              [-65.62763977, 18.33263969],
              [-65.62735748, 18.33263969],
              [-65.62735748, 18.33236122],
              [-65.62708282, 18.33236122],
              [-65.62708282, 18.33208275],
              [-65.62680817, 18.33208275],
              [-65.62680817, 18.33180428],
              [-65.62652588, 18.33180428],
              [-65.62652588, 18.32930565],
              [-65.62680817, 18.32930565],
              [-65.62680817, 18.32902718],
              [-65.62708282, 18.32902718],
              [-65.62708282, 18.3279171],
              [-65.62735748, 18.3279171],
              [-65.62735748, 18.32736206],
              [-65.62708282, 18.32736206],
              [-65.62708282, 18.32708359],
              [-65.62680817, 18.32708359],
              [-65.62680817, 18.32680511],
              [-65.62652588, 18.32680511],
              [-65.62652588, 18.32569504],
              [-65.62680817, 18.32569504],
              [-65.62680817, 18.32347298],
              [-65.62708282, 18.32347298],
              [-65.62708282, 18.32208252],
              [-65.62680817, 18.32208252],
              [-65.62680817, 18.31986046],
              [-65.62708282, 18.31986046],
              [-65.62708282, 18.31902695],
              [-65.62735748, 18.31902695],
              [-65.62735748, 18.31819534],
              [-65.62763977, 18.31819534],
              [-65.62763977, 18.31624985],
              [-65.62791443, 18.31624985],
              [-65.62791443, 18.31347275],
              [-65.62763977, 18.31347275],
              [-65.62763977, 18.31152725],
              [-65.62735748, 18.31152725],
              [-65.62735748, 18.31069374],
              [-65.62763977, 18.31069374],
              [-65.62763977, 18.30985832],
              [-65.62708282, 18.30985832],
              [-65.62708282, 18.30958366],
              [-65.62541962, 18.30958366],
              [-65.62541962, 18.30903053],
              [-65.62513733, 18.30903053],
              [-65.62513733, 18.30791664],
              [-65.62541962, 18.30791664],
              [-65.62541962, 18.30708313],
              [-65.62569427, 18.30708313],
              [-65.62569427, 18.30569458],
              [-65.62596893, 18.30569458],
              [-65.62596893, 18.30541611],
              [-65.62569427, 18.30541611],
              [-65.62569427, 18.30513954],
              [-65.62513733, 18.30513954],
              [-65.62513733, 18.30430412],
              [-65.62486267, 18.30430412],
              [-65.62486267, 18.30319595],
              [-65.62541962, 18.30319595],
              [-65.62541962, 18.30291748],
              [-65.62569427, 18.30291748],
              [-65.62569427, 18.30125046],
              [-65.62625122, 18.30125046],
              [-65.62625122, 18.30069351],
              [-65.62596893, 18.30069351],
              [-65.62596893, 18.29930496],
              [-65.62625122, 18.29930496],
              [-65.62625122, 18.29846954],
              [-65.62680817, 18.29846954],
              [-65.62680817, 18.29791641],
              [-65.62735748, 18.29791641],
              [-65.62735748, 18.29763985],
              [-65.62763977, 18.29763985],
              [-65.62763977, 18.29652977],
              [-65.62791443, 18.29652977],
              [-65.62791443, 18.29624748],
              [-65.62902832, 18.29624748],
              [-65.62902832, 18.29597282],
              [-65.62930298, 18.29597282],
              [-65.62930298, 18.29569435],
              [-65.62985992, 18.29569435],
              [-65.62985992, 18.29541588],
              [-65.63097382, 18.29541588],
              [-65.63097382, 18.29513741],
              [-65.63153076, 18.29513741],
              [-65.63153076, 18.29375076],
              [-65.63180542, 18.29375076],
              [-65.63180542, 18.29347229],
              [-65.63208008, 18.29347229],
              [-65.63208008, 18.29319382],
              [-65.63263702, 18.29319382],
              [-65.63263702, 18.29291534],
              [-65.63319397, 18.29291534],
              [-65.63319397, 18.29263878],
              [-65.63402557, 18.29263878],
              [-65.63402557, 18.29236031],
              [-65.63458252, 18.29236031],
              [-65.63458252, 18.29208374],
              [-65.63486481, 18.29208374],
              [-65.63486481, 18.29180527],
              [-65.63513947, 18.29180527],
              [-65.63513947, 18.29097176],
              [-65.63541412, 18.29097176],
              [-65.63541412, 18.28958321],
              [-65.63513947, 18.28958321],
              [-65.63513947, 18.28902817],
              [-65.63486481, 18.28902817],
              [-65.63486481, 18.28874969],
              [-65.63430786, 18.28874969],
              [-65.63430786, 18.28819466],
              [-65.63346863, 18.28819466],
              [-65.63346863, 18.28874969],
              [-65.63291931, 18.28874969],
              [-65.63291931, 18.28986168],
              [-65.63208008, 18.28986168],
              [-65.63208008, 18.28958321],
              [-65.63180542, 18.28958321],
              [-65.63180542, 18.28902817],
              [-65.63153076, 18.28902817],
              [-65.63153076, 18.28874969],
              [-65.63124847, 18.28874969],
              [-65.63124847, 18.28791618],
              [-65.63097382, 18.28791618],
              [-65.63097382, 18.28736115],
              [-65.63069153, 18.28736115],
              [-65.63069153, 18.28652763],
              [-65.63041687, 18.28652763],
              [-65.63041687, 18.28513908],
              [-65.63153076, 18.28513908],
              [-65.63153076, 18.28486252],
              [-65.63180542, 18.28486252],
              [-65.63180542, 18.28458405],
              [-65.63263702, 18.28458405],
              [-65.63263702, 18.28430557],
              [-65.63319397, 18.28430557],
              [-65.63319397, 18.2840271],
              [-65.63375092, 18.2840271],
              [-65.63375092, 18.28368568],
              [-65.63417053, 18.28373718],
              [-65.63647461, 18.28470039],
              [-65.6374054, 18.28422928],
              [-65.64481354, 18.2818737],
              [-65.64620209, 18.2814045],
              [-65.65406036, 18.28286171],
              [-65.6623764, 18.28670883],
              [-65.66606903, 18.28815269],
              [-65.668396, 18.28482246],
              [-65.66978455, 18.28434944],
              [-65.67302704, 18.28340912],
              [-65.67487335, 18.28341484],
              [-65.67488861, 18.27960014],
              [-65.6758194, 18.27817535],
              [-65.67906952, 18.2743721],
              [-65.68138123, 18.27485657],
              [-65.68276978, 18.27533722],
              [-65.68599701, 18.27821159],
              [-65.68737793, 18.27916527],
              [-65.69060516, 18.28299141],
              [-65.69337463, 18.28538513],
              [-65.69522095, 18.2858696],
              [-65.70219421, 18.27492714],
              [-65.69989014, 18.27300835],
              [-65.7003479, 18.27301216],
              [-65.70127869, 18.2720623],
              [-65.70359802, 18.27111626],
              [-65.71608734, 18.26733971],
              [-65.71886444, 18.26877975],
              [-65.72810364, 18.27166748],
              [-65.73503113, 18.27502441],
              [-65.73735046, 18.27359962],
              [-65.73827362, 18.27503395],
              [-65.74057007, 18.27980995],
              [-65.73732758, 18.28265953],
              [-65.74008942, 18.28600693],
              [-65.74332428, 18.28839684],
              [-65.74978638, 18.29413605],
              [-65.753479, 18.2970047],
              [-65.75115967, 18.29985809],
              [-65.75022888, 18.30128479],
              [-65.74884033, 18.30080795],
              [-65.74375916, 18.29935837],
              [-65.73775482, 18.29648209],
              [-65.73361206, 18.2878933],
              [-65.73222351, 18.28836632],
              [-65.72898102, 18.29026222],
              [-65.72573853, 18.29215813],
              [-65.7252655, 18.29502106],
              [-65.72063446, 18.29595947],
              [-65.72109222, 18.29929733],
              [-65.72061157, 18.30405807],
              [-65.71921539, 18.30644035],
              [-65.71366882, 18.30690193],
              [-65.71273804, 18.30641937],
              [-65.71227264, 18.3068943],
              [-65.71089172, 18.30641174],
              [-65.70996094, 18.30641174],
              [-65.70903778, 18.30688667],
              [-65.7048645, 18.30877876],
              [-65.70440674, 18.30925179],
              [-65.70439148, 18.31402016],
              [-65.70484161, 18.31592941],
              [-65.70483398, 18.31878853],
              [-65.7039032, 18.32069206],
              [-65.70251465, 18.32068634],
              [-65.70159149, 18.32116127],
              [-65.70066071, 18.32211113],
              [-65.69973755, 18.3230629],
              [-65.69741821, 18.32400894],
              [-65.69509888, 18.32495308],
              [-65.69139862, 18.3244648],
              [-65.68677521, 18.32254219],
              [-65.68585205, 18.32301331],
              [-65.6844635, 18.32348824],
              [-65.6844635, 18.32395935],
              [-65.68582916, 18.33016586],
              [-65.68304443, 18.33206367],
              [-65.68257904, 18.33301353],
              [-65.6788559, 18.34015083],
              [-65.67791748, 18.3425312],
              [-65.67884064, 18.34348869],
              [-65.6792984, 18.34396744],
              [-65.67976379, 18.3449192],
              [-65.67975616, 18.34634781],
              [-65.67882538, 18.34777641],
              [-65.67417908, 18.35490608],
              [-65.66950226, 18.36208344],
            ],
          ],
          [
            [
              [-65.57013702, 18.38263893],
              [-65.57013702, 18.38236046],
              [-65.56958008, 18.38236046],
              [-65.56958008, 18.38208389],
              [-65.56903076, 18.38208389],
              [-65.56903076, 18.38180542],
              [-65.56819153, 18.38180542],
              [-65.56819153, 18.38152695],
              [-65.56791687, 18.38152695],
              [-65.56791687, 18.37958336],
              [-65.56735992, 18.37958336],
              [-65.56735992, 18.37902832],
              [-65.56680298, 18.37902832],
              [-65.56680298, 18.37875175],
              [-65.56625366, 18.37875175],
              [-65.56625366, 18.37846947],
              [-65.56597137, 18.37846947],
              [-65.56597137, 18.37819481],
              [-65.56569672, 18.37819481],
              [-65.56569672, 18.37791634],
              [-65.56513977, 18.37791634],
              [-65.56513977, 18.37764168],
              [-65.56485748, 18.37764168],
              [-65.56485748, 18.37735939],
              [-65.56513977, 18.37735939],
              [-65.56513977, 18.37708282],
              [-65.56541443, 18.37708282],
              [-65.56541443, 18.37680626],
              [-65.56597137, 18.37680626],
              [-65.56597137, 18.37652969],
              [-65.56625366, 18.37652969],
              [-65.56625366, 18.37624741],
              [-65.56680298, 18.37624741],
              [-65.56680298, 18.37680626],
              [-65.56708527, 18.37680626],
              [-65.56708527, 18.37735939],
              [-65.56764221, 18.37735939],
              [-65.56764221, 18.37708282],
              [-65.56819153, 18.37708282],
              [-65.56819153, 18.37680626],
              [-65.56903076, 18.37680626],
              [-65.56903076, 18.37652969],
              [-65.56930542, 18.37652969],
              [-65.56930542, 18.37624741],
              [-65.56958008, 18.37624741],
              [-65.56958008, 18.37597275],
              [-65.57069397, 18.37597275],
              [-65.57069397, 18.37569427],
              [-65.57125092, 18.37569427],
              [-65.57125092, 18.3754158],
              [-65.57152557, 18.3754158],
              [-65.57152557, 18.37513733],
              [-65.57208252, 18.37513733],
              [-65.57208252, 18.37569427],
              [-65.57236481, 18.37569427],
              [-65.57236481, 18.37597275],
              [-65.57208252, 18.37597275],
              [-65.57208252, 18.37680626],
              [-65.57180786, 18.37680626],
              [-65.57180786, 18.37708282],
              [-65.57152557, 18.37708282],
              [-65.57152557, 18.37764168],
              [-65.57125092, 18.37764168],
              [-65.57125092, 18.37846947],
              [-65.57152557, 18.37846947],
              [-65.57152557, 18.37875175],
              [-65.57208252, 18.37875175],
              [-65.57208252, 18.38041687],
              [-65.57096863, 18.38041687],
              [-65.57096863, 18.3801384],
              [-65.57041931, 18.3801384],
              [-65.57041931, 18.37958336],
              [-65.56986237, 18.37958336],
              [-65.56986237, 18.38069534],
              [-65.57041931, 18.38069534],
              [-65.57041931, 18.38097382],
              [-65.57125092, 18.38097382],
              [-65.57125092, 18.38125038],
              [-65.57208252, 18.38125038],
              [-65.57208252, 18.38208389],
              [-65.57180786, 18.38208389],
              [-65.57180786, 18.38236046],
              [-65.57152557, 18.38236046],
              [-65.57152557, 18.38263893],
              [-65.57013702, 18.38263893],
            ],
          ],
          [
            [
              [-65.55208588, 18.3726387],
              [-65.55208588, 18.37208366],
              [-65.55180359, 18.37208366],
              [-65.55180359, 18.37180519],
              [-65.55152893, 18.37180519],
              [-65.55152893, 18.37152863],
              [-65.55180359, 18.37152863],
              [-65.55180359, 18.37097168],
              [-65.55236053, 18.37097168],
              [-65.55236053, 18.37069511],
              [-65.55291748, 18.37069511],
              [-65.55291748, 18.37041664],
              [-65.55347443, 18.37041664],
              [-65.55347443, 18.37013817],
              [-65.55458069, 18.37013817],
              [-65.55458069, 18.37041664],
              [-65.55541992, 18.37041664],
              [-65.55541992, 18.3698616],
              [-65.55652618, 18.3698616],
              [-65.55652618, 18.37069511],
              [-65.55625153, 18.37069511],
              [-65.55625153, 18.37097168],
              [-65.55596924, 18.37097168],
              [-65.55596924, 18.37125015],
              [-65.55486298, 18.37125015],
              [-65.55486298, 18.37152863],
              [-65.55458069, 18.37152863],
              [-65.55458069, 18.37208366],
              [-65.55430603, 18.37208366],
              [-65.55430603, 18.37236023],
              [-65.55403137, 18.37236023],
              [-65.55403137, 18.37291527],
              [-65.55319214, 18.37291527],
              [-65.55319214, 18.37319374],
              [-65.55291748, 18.37319374],
              [-65.55291748, 18.37347221],
              [-65.55263519, 18.37347221],
              [-65.55263519, 18.37375069],
              [-65.55208588, 18.37375069],
              [-65.55208588, 18.37402725],
              [-65.55180359, 18.37402725],
              [-65.55180359, 18.37430573],
              [-65.55097198, 18.37430573],
              [-65.55097198, 18.3726387],
              [-65.55208588, 18.3726387],
            ],
          ],
          [
            [
              [-65.54624939, 18.37236023],
              [-65.54624939, 18.37208366],
              [-65.54597473, 18.37208366],
              [-65.54597473, 18.37152863],
              [-65.54569244, 18.37152863],
              [-65.54569244, 18.37125015],
              [-65.54541779, 18.37125015],
              [-65.54541779, 18.37041664],
              [-65.5451355, 18.37041664],
              [-65.5451355, 18.36874962],
              [-65.54541779, 18.36874962],
              [-65.54541779, 18.36819458],
              [-65.54569244, 18.36819458],
              [-65.54569244, 18.36791611],
              [-65.54597473, 18.36791611],
              [-65.54597473, 18.36763954],
              [-65.54652405, 18.36763954],
              [-65.54652405, 18.36736107],
              [-65.54792023, 18.36736107],
              [-65.54792023, 18.3670826],
              [-65.54819489, 18.3670826],
              [-65.54819489, 18.36680603],
              [-65.54846954, 18.36680603],
              [-65.54846954, 18.36652756],
              [-65.54875183, 18.36652756],
              [-65.54875183, 18.36624908],
              [-65.54930878, 18.36624908],
              [-65.54930878, 18.36652756],
              [-65.54958344, 18.36652756],
              [-65.54958344, 18.3670826],
              [-65.54902649, 18.3670826],
              [-65.54902649, 18.36847305],
              [-65.54875183, 18.36847305],
              [-65.54875183, 18.36874962],
              [-65.54819489, 18.36874962],
              [-65.54819489, 18.36902809],
              [-65.54792023, 18.36902809],
              [-65.54792023, 18.36930466],
              [-65.54763794, 18.36930466],
              [-65.54763794, 18.3698616],
              [-65.54736328, 18.3698616],
              [-65.54736328, 18.37013817],
              [-65.54708099, 18.37013817],
              [-65.54708099, 18.37041664],
              [-65.54680634, 18.37041664],
              [-65.54680634, 18.37236023],
              [-65.54624939, 18.37236023],
            ],
          ],
          [
            [
              [-65.52958679, 18.36569405],
              [-65.52958679, 18.36541748],
              [-65.5293045, 18.36541748],
              [-65.5293045, 18.36514091],
              [-65.52902985, 18.36514091],
              [-65.52902985, 18.36486244],
              [-65.52874756, 18.36486244],
              [-65.52874756, 18.36458397],
              [-65.52819824, 18.36458397],
              [-65.52819824, 18.3643055],
              [-65.52735901, 18.3643055],
              [-65.52735901, 18.36347008],
              [-65.5276413, 18.36347008],
              [-65.5276413, 18.36208344],
              [-65.52791595, 18.36208344],
              [-65.52791595, 18.36180496],
              [-65.5293045, 18.36180496],
              [-65.5293045, 18.36124992],
              [-65.5304184, 18.36124992],
              [-65.5304184, 18.36097145],
              [-65.53097534, 18.36097145],
              [-65.53097534, 18.36124992],
              [-65.53236389, 18.36124992],
              [-65.53236389, 18.36263847],
              [-65.5320816, 18.36263847],
              [-65.5320816, 18.36319351],
              [-65.53069305, 18.36319351],
              [-65.53069305, 18.36263847],
              [-65.52958679, 18.36263847],
              [-65.52958679, 18.36319351],
              [-65.52986145, 18.36319351],
              [-65.52986145, 18.36347008],
              [-65.53013611, 18.36347008],
              [-65.53013611, 18.36374855],
              [-65.5320816, 18.36374855],
              [-65.5320816, 18.36458397],
              [-65.53180695, 18.36458397],
              [-65.53180695, 18.36486244],
              [-65.53125, 18.36486244],
              [-65.53125, 18.36514091],
              [-65.5304184, 18.36514091],
              [-65.5304184, 18.36541748],
              [-65.53013611, 18.36541748],
              [-65.53013611, 18.36569405],
              [-65.52958679, 18.36569405],
            ],
          ],
          [
            [
              [-65.56819153, 18.35263824],
              [-65.56819153, 18.35236168],
              [-65.56764221, 18.35236168],
              [-65.56764221, 18.35152817],
              [-65.56735992, 18.35152817],
              [-65.56735992, 18.3512516],
              [-65.56708527, 18.3512516],
              [-65.56708527, 18.35097313],
              [-65.56680298, 18.35097313],
              [-65.56680298, 18.35069466],
              [-65.56625366, 18.35069466],
              [-65.56625366, 18.35041618],
              [-65.56597137, 18.35041618],
              [-65.56597137, 18.35014153],
              [-65.56569672, 18.35014153],
              [-65.56569672, 18.34985924],
              [-65.56541443, 18.34985924],
              [-65.56541443, 18.34958267],
              [-65.56513977, 18.34958267],
              [-65.56513977, 18.34902763],
              [-65.56485748, 18.34902763],
              [-65.56485748, 18.34791946],
              [-65.56458282, 18.34791946],
              [-65.56458282, 18.34652519],
              [-65.56485748, 18.34652519],
              [-65.56485748, 18.34597206],
              [-65.56513977, 18.34597206],
              [-65.56513977, 18.34541512],
              [-65.56597137, 18.34541512],
              [-65.56597137, 18.34513855],
              [-65.56680298, 18.34513855],
              [-65.56680298, 18.34430695],
              [-65.56708527, 18.34430695],
              [-65.56708527, 18.34375],
              [-65.56764221, 18.34375],
              [-65.56764221, 18.34347153],
              [-65.56874847, 18.34347153],
              [-65.56874847, 18.34375],
              [-65.56903076, 18.34375],
              [-65.56903076, 18.34597206],
              [-65.56930542, 18.34597206],
              [-65.56930542, 18.34736061],
              [-65.56958008, 18.34736061],
              [-65.56958008, 18.34763718],
              [-65.57041931, 18.34763718],
              [-65.57041931, 18.34791946],
              [-65.57069397, 18.34791946],
              [-65.57069397, 18.34819412],
              [-65.57096863, 18.34819412],
              [-65.57096863, 18.34902763],
              [-65.57125092, 18.34902763],
              [-65.57125092, 18.34985924],
              [-65.57152557, 18.34985924],
              [-65.57152557, 18.35041618],
              [-65.57180786, 18.35041618],
              [-65.57180786, 18.35069466],
              [-65.57208252, 18.35069466],
              [-65.57208252, 18.35097313],
              [-65.57236481, 18.35097313],
              [-65.57236481, 18.3512516],
              [-65.57263947, 18.3512516],
              [-65.57263947, 18.35152817],
              [-65.57458496, 18.35152817],
              [-65.57458496, 18.35208321],
              [-65.57430267, 18.35208321],
              [-65.57430267, 18.35236168],
              [-65.57291412, 18.35236168],
              [-65.57291412, 18.35208321],
              [-65.57152557, 18.35208321],
              [-65.57152557, 18.35180473],
              [-65.57125092, 18.35180473],
              [-65.57125092, 18.35152817],
              [-65.57096863, 18.35152817],
              [-65.57096863, 18.35208321],
              [-65.57041931, 18.35208321],
              [-65.57041931, 18.35236168],
              [-65.56986237, 18.35236168],
              [-65.56986237, 18.35263824],
              [-65.56819153, 18.35263824],
            ],
          ],
          [
            [
              [-65.62069702, 18.34208488],
              [-65.62069702, 18.34180641],
              [-65.61985779, 18.34180641],
              [-65.61985779, 18.34152794],
              [-65.61930847, 18.34152794],
              [-65.61930847, 18.34124947],
              [-65.61902618, 18.34124947],
              [-65.61902618, 18.34097481],
              [-65.61875153, 18.34097481],
              [-65.61875153, 18.34041595],
              [-65.61846924, 18.34041595],
              [-65.61846924, 18.3390274],
              [-65.61819458, 18.3390274],
              [-65.61819458, 18.33875275],
              [-65.61791992, 18.33875275],
              [-65.61791992, 18.33847237],
              [-65.61736298, 18.33847237],
              [-65.61736298, 18.33875275],
              [-65.61653137, 18.33875275],
              [-65.61653137, 18.33847237],
              [-65.61624908, 18.33847237],
              [-65.61624908, 18.33735847],
              [-65.61653137, 18.33735847],
              [-65.61653137, 18.33680534],
              [-65.61680603, 18.33680534],
              [-65.61680603, 18.33652687],
              [-65.61708069, 18.33652687],
              [-65.61708069, 18.3362484],
              [-65.61736298, 18.3362484],
              [-65.61736298, 18.33569527],
              [-65.61763763, 18.33569527],
              [-65.61763763, 18.33541679],
              [-65.61875153, 18.33541679],
              [-65.61875153, 18.33569527],
              [-65.61930847, 18.33569527],
              [-65.61930847, 18.33652687],
              [-65.61902618, 18.33652687],
              [-65.61902618, 18.33708382],
              [-65.61846924, 18.33708382],
              [-65.61846924, 18.33764076],
              [-65.61819458, 18.33764076],
              [-65.61819458, 18.33819389],
              [-65.61985779, 18.33819389],
              [-65.61985779, 18.33847237],
              [-65.62069702, 18.33847237],
              [-65.62069702, 18.33819389],
              [-65.62097168, 18.33819389],
              [-65.62097168, 18.33847237],
              [-65.62152863, 18.33847237],
              [-65.62152863, 18.3390274],
              [-65.62180328, 18.3390274],
              [-65.62180328, 18.33930588],
              [-65.62208557, 18.33930588],
              [-65.62208557, 18.33986282],
              [-65.62236023, 18.33986282],
              [-65.62236023, 18.34152794],
              [-65.62208557, 18.34152794],
              [-65.62208557, 18.34208488],
              [-65.62069702, 18.34208488],
            ],
          ],
          [
            [
              [-65.60902405, 18.3148613],
              [-65.60902405, 18.31458473],
              [-65.60847473, 18.31458473],
              [-65.60847473, 18.31402779],
              [-65.60819244, 18.31402779],
              [-65.60819244, 18.31236267],
              [-65.60791779, 18.31236267],
              [-65.60791779, 18.31152725],
              [-65.60819244, 18.31152725],
              [-65.60819244, 18.31097031],
              [-65.60930634, 18.31097031],
              [-65.60930634, 18.31152725],
              [-65.61013794, 18.31152725],
              [-65.61013794, 18.31180573],
              [-65.61069489, 18.31180573],
              [-65.61069489, 18.31236267],
              [-65.61096954, 18.31236267],
              [-65.61096954, 18.31263924],
              [-65.61125183, 18.31263924],
              [-65.61125183, 18.31347275],
              [-65.61069489, 18.31347275],
              [-65.61069489, 18.31374931],
              [-65.61042023, 18.31374931],
              [-65.61042023, 18.31430626],
              [-65.61013794, 18.31430626],
              [-65.61013794, 18.3148613],
              [-65.60902405, 18.3148613],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 27,
        name: 'Fajardo',
        hasc_1: 'PR.FJ',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-65.753479, 18.26733971, -65.52735901, 18.39847183],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.28',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.53380585, 18.35154915],
              [-66.53609467, 18.34819984],
              [-66.55179596, 18.34143829],
              [-66.55966187, 18.34090996],
              [-66.57309723, 18.3427372],
              [-66.57448578, 18.34225082],
              [-66.59065247, 18.33786011],
              [-66.59023285, 18.34453201],
              [-66.58979797, 18.34882736],
              [-66.58844757, 18.3555069],
              [-66.58293915, 18.36316681],
              [-66.58207703, 18.37365532],
              [-66.58213043, 18.3742218],
              [-66.5885849, 18.37506866],
              [-66.58798981, 18.38970757],
              [-66.58235931, 18.38928032],
              [-66.57582855, 18.40565681],
              [-66.57155609, 18.41112518],
              [-66.56542969, 18.41372108],
              [-66.55239868, 18.41107368],
              [-66.55014801, 18.40587234],
              [-66.54256439, 18.40455246],
              [-66.54008484, 18.3991642],
              [-66.53447723, 18.39014435],
              [-66.53400421, 18.38824081],
              [-66.53352356, 18.38538742],
              [-66.53302765, 18.37919426],
              [-66.53112793, 18.37062836],
              [-66.53380585, 18.35154915],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 28,
        name: 'Florida',
        hasc_1: 'PR.FL',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.59065247, 18.33786011, -66.53112793, 18.41372108],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.29',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.92847443, 17.94347191],
              [-66.92958069, 17.94347191],
              [-66.92958069, 17.94319534],
              [-66.93013763, 17.94319534],
              [-66.93013763, 17.94291687],
              [-66.93069458, 17.94291687],
              [-66.93069458, 17.9426384],
              [-66.93096924, 17.9426384],
              [-66.93096924, 17.94236183],
              [-66.93125153, 17.94236183],
              [-66.93125153, 17.94208336],
              [-66.93152618, 17.94208336],
              [-66.93152618, 17.94152832],
              [-66.93180847, 17.94152832],
              [-66.93180847, 17.94069481],
              [-66.93208313, 17.94069481],
              [-66.93208313, 17.93847275],
              [-66.93180847, 17.93847275],
              [-66.93180847, 17.9379158],
              [-66.93152618, 17.9379158],
              [-66.93152618, 17.93763924],
              [-66.93041992, 17.93763924],
              [-66.93041992, 17.93736267],
              [-66.92847443, 17.93736267],
              [-66.92847443, 17.9370842],
              [-66.92819214, 17.9370842],
              [-66.92819214, 17.93736267],
              [-66.92763519, 17.93736267],
              [-66.92763519, 17.9370842],
              [-66.92736053, 17.9370842],
              [-66.92736053, 17.93680573],
              [-66.92708588, 17.93680573],
              [-66.92708588, 17.93652725],
              [-66.92680359, 17.93652725],
              [-66.92680359, 17.93569374],
              [-66.92652893, 17.93569374],
              [-66.92652893, 17.93541718],
              [-66.92597198, 17.93541718],
              [-66.92597198, 17.93514061],
              [-66.92485809, 17.93514061],
              [-66.92485809, 17.93485832],
              [-66.92458344, 17.93485832],
              [-66.92458344, 17.93458366],
              [-66.92430878, 17.93458366],
              [-66.92430878, 17.93430519],
              [-66.92319489, 17.93430519],
              [-66.92319489, 17.93402863],
              [-66.92236328, 17.93402863],
              [-66.92236328, 17.93374825],
              [-66.91958618, 17.93374825],
              [-66.91958618, 17.93319511],
              [-66.91930389, 17.93319511],
              [-66.91930389, 17.93291855],
              [-66.91874695, 17.93291855],
              [-66.91874695, 17.93208313],
              [-66.91902924, 17.93208313],
              [-66.91902924, 17.93152618],
              [-66.91930389, 17.93152618],
              [-66.91930389, 17.93097305],
              [-66.91958618, 17.93097305],
              [-66.91958618, 17.93069458],
              [-66.9201355, 17.93069458],
              [-66.9201355, 17.9304142],
              [-66.92041779, 17.9304142],
              [-66.92041779, 17.93013954],
              [-66.92069244, 17.93013954],
              [-66.92069244, 17.92986107],
              [-66.92124939, 17.92986107],
              [-66.92124939, 17.9295826],
              [-66.92152405, 17.9295826],
              [-66.92152405, 17.92930412],
              [-66.92208099, 17.92930412],
              [-66.92208099, 17.92902756],
              [-66.92236328, 17.92902756],
              [-66.92236328, 17.92791748],
              [-66.92263794, 17.92791748],
              [-66.92263794, 17.92736053],
              [-66.92292023, 17.92736053],
              [-66.92292023, 17.92708206],
              [-66.92319489, 17.92708206],
              [-66.92319489, 17.9268055],
              [-66.92375183, 17.9268055],
              [-66.92375183, 17.92625046],
              [-66.92708588, 17.92625046],
              [-66.92708588, 17.92652702],
              [-66.92763519, 17.92652702],
              [-66.92763519, 17.92736053],
              [-66.92791748, 17.92736053],
              [-66.92791748, 17.92763901],
              [-66.92847443, 17.92763901],
              [-66.92847443, 17.92791748],
              [-66.92986298, 17.92791748],
              [-66.92986298, 17.92763901],
              [-66.93096924, 17.92763901],
              [-66.93096924, 17.92791748],
              [-66.93208313, 17.92791748],
              [-66.93208313, 17.92819214],
              [-66.93291473, 17.92819214],
              [-66.93291473, 17.92847252],
              [-66.93264008, 17.92847252],
              [-66.93264008, 17.9295826],
              [-66.93291473, 17.9295826],
              [-66.93291473, 17.93013954],
              [-66.93374634, 17.93013954],
              [-66.93374634, 17.9304142],
              [-66.93430328, 17.9304142],
              [-66.93430328, 17.93069458],
              [-66.93458557, 17.93069458],
              [-66.93458557, 17.93097305],
              [-66.93486023, 17.93097305],
              [-66.93486023, 17.93124962],
              [-66.93541718, 17.93124962],
              [-66.93541718, 17.93152618],
              [-66.93569183, 17.93152618],
              [-66.93569183, 17.93180466],
              [-66.93624878, 17.93180466],
              [-66.93624878, 17.93208313],
              [-66.93653107, 17.93208313],
              [-66.93653107, 17.9323616],
              [-66.93902588, 17.9323616],
              [-66.93902588, 17.93263626],
              [-66.94097137, 17.93263626],
              [-66.94097137, 17.93291855],
              [-66.94235992, 17.93291855],
              [-66.94235992, 17.93319511],
              [-66.94541931, 17.93319511],
              [-66.94541931, 17.93291855],
              [-66.94625092, 17.93291855],
              [-66.94625092, 17.93263626],
              [-66.94652557, 17.93263626],
              [-66.94652557, 17.93291855],
              [-66.94680786, 17.93291855],
              [-66.94680786, 17.93319511],
              [-66.94708252, 17.93319511],
              [-66.94708252, 17.93374825],
              [-66.94736481, 17.93374825],
              [-66.94736481, 17.93402863],
              [-66.94819641, 17.93402863],
              [-66.94819641, 17.93374825],
              [-66.94875336, 17.93374825],
              [-66.94875336, 17.93319511],
              [-66.94902802, 17.93319511],
              [-66.94902802, 17.93263626],
              [-66.94958496, 17.93263626],
              [-66.94958496, 17.9323616],
              [-66.95041656, 17.9323616],
              [-66.95041656, 17.93180466],
              [-66.95069122, 17.93180466],
              [-66.95069122, 17.93208313],
              [-66.95097351, 17.93208313],
              [-66.95097351, 17.9323616],
              [-66.95153046, 17.9323616],
              [-66.95153046, 17.93263626],
              [-66.95263672, 17.93263626],
              [-66.95263672, 17.9323616],
              [-66.95375061, 17.9323616],
              [-66.95375061, 17.93208313],
              [-66.95402527, 17.93208313],
              [-66.95402527, 17.93180466],
              [-66.95430756, 17.93180466],
              [-66.95430756, 17.93152618],
              [-66.95458221, 17.93152618],
              [-66.95458221, 17.93124962],
              [-66.95513916, 17.93124962],
              [-66.95513916, 17.93097305],
              [-66.95541382, 17.93097305],
              [-66.95541382, 17.93069458],
              [-66.95569611, 17.93069458],
              [-66.95569611, 17.9304142],
              [-66.95735931, 17.9304142],
              [-66.95735931, 17.9323616],
              [-66.95708466, 17.9323616],
              [-66.95708466, 17.93291855],
              [-66.95680237, 17.93291855],
              [-66.95680237, 17.93374825],
              [-66.95652771, 17.93374825],
              [-66.95652771, 17.93402863],
              [-66.95680237, 17.93402863],
              [-66.95680237, 17.93541718],
              [-66.95625305, 17.93541718],
              [-66.95625305, 17.93569374],
              [-66.95597076, 17.93569374],
              [-66.95597076, 17.93597221],
              [-66.95541382, 17.93597221],
              [-66.95541382, 17.93652725],
              [-66.9548645, 17.93652725],
              [-66.9548645, 17.9370842],
              [-66.95513916, 17.9370842],
              [-66.95513916, 17.9379158],
              [-66.9548645, 17.9379158],
              [-66.9548645, 17.93819427],
              [-66.95541382, 17.93819427],
              [-66.95541382, 17.93847275],
              [-66.95569611, 17.93847275],
              [-66.95569611, 17.93874931],
              [-66.95625305, 17.93874931],
              [-66.95625305, 17.93902779],
              [-66.95652771, 17.93902779],
              [-66.95652771, 17.93930626],
              [-66.95735931, 17.93930626],
              [-66.95735931, 17.93902779],
              [-66.9576416, 17.93902779],
              [-66.9576416, 17.93874931],
              [-66.95903015, 17.93874931],
              [-66.95903015, 17.93930626],
              [-66.95930481, 17.93930626],
              [-66.95930481, 17.93958282],
              [-66.95986176, 17.93958282],
              [-66.95986176, 17.94013977],
              [-66.96013641, 17.94013977],
              [-66.96013641, 17.94069481],
              [-66.9604187, 17.94069481],
              [-66.9604187, 17.94097137],
              [-66.96152496, 17.94097137],
              [-66.96152496, 17.94124985],
              [-66.96347046, 17.94124985],
              [-66.96347046, 17.94069481],
              [-66.96375275, 17.94069481],
              [-66.96375275, 17.94208336],
              [-66.9640274, 17.94208336],
              [-66.9640274, 17.9426384],
              [-66.96375275, 17.9426384],
              [-66.96375275, 17.94347191],
              [-66.9640274, 17.94347191],
              [-66.9640274, 17.94375038],
              [-66.9659729, 17.94375038],
              [-66.9659729, 17.94430542],
              [-66.96569824, 17.94430542],
              [-66.96569824, 17.94458389],
              [-66.96541595, 17.94458389],
              [-66.96541595, 17.9454155],
              [-66.96569824, 17.9454155],
              [-66.96569824, 17.94569397],
              [-66.9659729, 17.94569397],
              [-66.9659729, 17.94597244],
              [-66.96652985, 17.94597244],
              [-66.96652985, 17.94625092],
              [-66.96763611, 17.94625092],
              [-66.96763611, 17.94652939],
              [-66.96875, 17.94652939],
              [-66.96875, 17.94680786],
              [-66.9695816, 17.94680786],
              [-66.9695816, 17.94708252],
              [-66.97013855, 17.94708252],
              [-66.97013855, 17.94735909],
              [-66.9706955, 17.94735909],
              [-66.9706955, 17.94763947],
              [-66.97097015, 17.94763947],
              [-66.97097015, 17.9481945],
              [-66.9706955, 17.9481945],
              [-66.9706955, 17.94930649],
              [-66.97097015, 17.94930649],
              [-66.97097015, 17.94958305],
              [-66.97125244, 17.94958305],
              [-66.97125244, 17.94986153],
              [-66.97180176, 17.94986153],
              [-66.97180176, 17.94958305],
              [-66.97208405, 17.94958305],
              [-66.97208405, 17.94930649],
              [-66.9723587, 17.94930649],
              [-66.9723587, 17.94902992],
              [-66.97458649, 17.94902992],
              [-66.97458649, 17.94930649],
              [-66.9751358, 17.94930649],
              [-66.9751358, 17.94958305],
              [-66.97541809, 17.94958305],
              [-66.97541809, 17.94986153],
              [-66.97763824, 17.94986153],
              [-66.97763824, 17.95013809],
              [-66.9779129, 17.95013809],
              [-66.9779129, 17.95041656],
              [-66.97819519, 17.95041656],
              [-66.97819519, 17.95069504],
              [-66.97875214, 17.95069504],
              [-66.97875214, 17.9509716],
              [-66.97902679, 17.9509716],
              [-66.97902679, 17.95125008],
              [-66.98180389, 17.95125008],
              [-66.98180389, 17.95157242],
              [-66.98180389, 17.9517498],
              [-66.96170044, 17.99177551],
              [-66.96086884, 18.00085449],
              [-66.96047211, 18.00754547],
              [-66.96006775, 18.01327705],
              [-66.95966339, 18.01948547],
              [-66.95886993, 18.03237915],
              [-66.95334625, 18.0338707],
              [-66.94734192, 18.0334549],
              [-66.94132233, 18.03160667],
              [-66.93854523, 18.03020477],
              [-66.93622589, 18.02927589],
              [-66.93393707, 18.03072739],
              [-66.93070984, 18.03076172],
              [-66.9200592, 18.02704811],
              [-66.91079712, 18.0237999],
              [-66.90942383, 18.02429581],
              [-66.90574646, 18.0262394],
              [-66.9052887, 18.02672005],
              [-66.89881134, 18.02440071],
              [-66.89511108, 18.02347946],
              [-66.8904953, 18.02352142],
              [-66.88497925, 18.02500725],
              [-66.88450623, 18.02453232],
              [-66.87797546, 18.01648331],
              [-66.87704468, 18.01553917],
              [-66.87519073, 18.01412392],
              [-66.8686676, 18.00702477],
              [-66.86305237, 17.99801254],
              [-66.86302185, 17.99467087],
              [-66.86299896, 17.99180603],
              [-66.86388397, 17.98750114],
              [-66.86322784, 17.96506882],
              [-66.8622818, 17.96221161],
              [-66.85912323, 17.95430565],
              [-66.85930634, 17.95430565],
              [-66.85930634, 17.95402718],
              [-66.85986328, 17.95402718],
              [-66.85986328, 17.95375061],
              [-66.86042023, 17.95375061],
              [-66.86042023, 17.95347214],
              [-66.86069489, 17.95347214],
              [-66.86069489, 17.95319366],
              [-66.86096954, 17.95319366],
              [-66.86096954, 17.9529171],
              [-66.86152649, 17.9529171],
              [-66.86152649, 17.95263863],
              [-66.86180878, 17.95263863],
              [-66.86180878, 17.95208359],
              [-66.86208344, 17.95208359],
              [-66.86208344, 17.95180511],
              [-66.86235809, 17.95180511],
              [-66.86235809, 17.95125008],
              [-66.86264038, 17.95125008],
              [-66.86264038, 17.95069504],
              [-66.86291504, 17.95069504],
              [-66.86291504, 17.95013809],
              [-66.86319733, 17.95013809],
              [-66.86319733, 17.94763947],
              [-66.86291504, 17.94763947],
              [-66.86291504, 17.94735909],
              [-66.86319733, 17.94735909],
              [-66.86319733, 17.94569397],
              [-66.86347198, 17.94569397],
              [-66.86347198, 17.9454155],
              [-66.86402893, 17.9454155],
              [-66.86402893, 17.94513893],
              [-66.86541748, 17.94513893],
              [-66.86541748, 17.94486046],
              [-66.86569214, 17.94486046],
              [-66.86569214, 17.94347191],
              [-66.86624908, 17.94347191],
              [-66.86624908, 17.94319534],
              [-66.86653137, 17.94319534],
              [-66.86653137, 17.94347191],
              [-66.86736298, 17.94347191],
              [-66.86736298, 17.94375038],
              [-66.86902618, 17.94375038],
              [-66.86902618, 17.94402695],
              [-66.86930847, 17.94402695],
              [-66.86930847, 17.94458389],
              [-66.86985779, 17.94458389],
              [-66.86985779, 17.94513893],
              [-66.86902618, 17.94513893],
              [-66.86902618, 17.9454155],
              [-66.86846924, 17.9454155],
              [-66.86846924, 17.94569397],
              [-66.86819458, 17.94569397],
              [-66.86819458, 17.94597244],
              [-66.86791992, 17.94597244],
              [-66.86791992, 17.94625092],
              [-66.86763763, 17.94625092],
              [-66.86763763, 17.94652939],
              [-66.86736298, 17.94652939],
              [-66.86736298, 17.94735909],
              [-66.86819458, 17.94735909],
              [-66.86819458, 17.94763947],
              [-66.86846924, 17.94763947],
              [-66.86846924, 17.9481945],
              [-66.86875153, 17.9481945],
              [-66.86875153, 17.94902992],
              [-66.86902618, 17.94902992],
              [-66.86902618, 17.94958305],
              [-66.86930847, 17.94958305],
              [-66.86930847, 17.94986153],
              [-66.86958313, 17.94986153],
              [-66.86958313, 17.95013809],
              [-66.86985779, 17.95013809],
              [-66.86985779, 17.95041656],
              [-66.87041473, 17.95041656],
              [-66.87041473, 17.95069504],
              [-66.87097168, 17.95069504],
              [-66.87097168, 17.9509716],
              [-66.87124634, 17.9509716],
              [-66.87124634, 17.95125008],
              [-66.87152863, 17.95125008],
              [-66.87152863, 17.95152855],
              [-66.87180328, 17.95152855],
              [-66.87180328, 17.95208359],
              [-66.87236023, 17.95208359],
              [-66.87236023, 17.95180511],
              [-66.87264252, 17.95180511],
              [-66.87264252, 17.95125008],
              [-66.87291718, 17.95125008],
              [-66.87291718, 17.95041656],
              [-66.87319183, 17.95041656],
              [-66.87319183, 17.94986153],
              [-66.87347412, 17.94986153],
              [-66.87347412, 17.94958305],
              [-66.87374878, 17.94958305],
              [-66.87374878, 17.94930649],
              [-66.87430573, 17.94930649],
              [-66.87430573, 17.94902992],
              [-66.87458038, 17.94902992],
              [-66.87458038, 17.94874763],
              [-66.87513733, 17.94874763],
              [-66.87513733, 17.94847298],
              [-66.87596893, 17.94847298],
              [-66.87596893, 17.94791794],
              [-66.87680817, 17.94791794],
              [-66.87680817, 17.94763947],
              [-66.87708282, 17.94763947],
              [-66.87708282, 17.94735909],
              [-66.87735748, 17.94735909],
              [-66.87735748, 17.94708252],
              [-66.87847137, 17.94708252],
              [-66.87847137, 17.94735909],
              [-66.87875366, 17.94735909],
              [-66.87875366, 17.94763947],
              [-66.87902832, 17.94763947],
              [-66.87902832, 17.94791794],
              [-66.87930298, 17.94791794],
              [-66.87930298, 17.9481945],
              [-66.87958527, 17.9481945],
              [-66.87958527, 17.94847298],
              [-66.87930298, 17.94847298],
              [-66.87930298, 17.94874763],
              [-66.87902832, 17.94874763],
              [-66.87902832, 17.94930649],
              [-66.87875366, 17.94930649],
              [-66.87875366, 17.95013809],
              [-66.87847137, 17.95013809],
              [-66.87847137, 17.95125008],
              [-66.87875366, 17.95125008],
              [-66.87875366, 17.95152855],
              [-66.87958527, 17.95152855],
              [-66.87958527, 17.95180511],
              [-66.88014221, 17.95180511],
              [-66.88014221, 17.95208359],
              [-66.88069153, 17.95208359],
              [-66.88069153, 17.95236206],
              [-66.88153076, 17.95236206],
              [-66.88153076, 17.95263863],
              [-66.88236237, 17.95263863],
              [-66.88236237, 17.9529171],
              [-66.88263702, 17.9529171],
              [-66.88263702, 17.95263863],
              [-66.88402557, 17.95263863],
              [-66.88402557, 17.95236206],
              [-66.88430786, 17.95236206],
              [-66.88430786, 17.95208359],
              [-66.88458252, 17.95208359],
              [-66.88458252, 17.95180511],
              [-66.88513947, 17.95180511],
              [-66.88513947, 17.95152855],
              [-66.88541412, 17.95152855],
              [-66.88541412, 17.95125008],
              [-66.88625336, 17.95125008],
              [-66.88625336, 17.9509716],
              [-66.88986206, 17.9509716],
              [-66.88986206, 17.95069504],
              [-66.89152527, 17.95069504],
              [-66.89152527, 17.9509716],
              [-66.89208221, 17.9509716],
              [-66.89208221, 17.95125008],
              [-66.8923645, 17.95125008],
              [-66.8923645, 17.9509716],
              [-66.89263916, 17.9509716],
              [-66.89263916, 17.95013809],
              [-66.89291382, 17.95013809],
              [-66.89291382, 17.94986153],
              [-66.89375305, 17.94986153],
              [-66.89375305, 17.94958305],
              [-66.8951416, 17.94958305],
              [-66.8951416, 17.94930649],
              [-66.89569092, 17.94930649],
              [-66.89569092, 17.94902992],
              [-66.8970871, 17.94902992],
              [-66.8970871, 17.94930649],
              [-66.89736176, 17.94930649],
              [-66.89736176, 17.94902992],
              [-66.8979187, 17.94902992],
              [-66.8979187, 17.94874763],
              [-66.89819336, 17.94874763],
              [-66.89819336, 17.94847298],
              [-66.89847565, 17.94847298],
              [-66.89847565, 17.9481945],
              [-66.90041351, 17.9481945],
              [-66.90041351, 17.94847298],
              [-66.9006958, 17.94847298],
              [-66.9006958, 17.94874763],
              [-66.9015274, 17.94874763],
              [-66.9015274, 17.94847298],
              [-66.9026413, 17.94847298],
              [-66.9026413, 17.94874763],
              [-66.90319824, 17.94874763],
              [-66.90319824, 17.94902992],
              [-66.9034729, 17.94902992],
              [-66.9034729, 17.94930649],
              [-66.90374756, 17.94930649],
              [-66.90374756, 17.94958305],
              [-66.90402985, 17.94958305],
              [-66.90402985, 17.94986153],
              [-66.90458679, 17.94986153],
              [-66.90458679, 17.95069504],
              [-66.90486145, 17.95069504],
              [-66.90486145, 17.9509716],
              [-66.90458679, 17.9509716],
              [-66.90458679, 17.95208359],
              [-66.9043045, 17.95208359],
              [-66.9043045, 17.9529171],
              [-66.90486145, 17.9529171],
              [-66.90486145, 17.95319366],
              [-66.90569305, 17.95319366],
              [-66.90569305, 17.95375061],
              [-66.90597534, 17.95375061],
              [-66.90597534, 17.95402718],
              [-66.90625, 17.95402718],
              [-66.90625, 17.95513916],
              [-66.90652466, 17.95513916],
              [-66.90652466, 17.9556942],
              [-66.90680695, 17.9556942],
              [-66.90680695, 17.95736122],
              [-66.9070816, 17.95736122],
              [-66.9070816, 17.95819473],
              [-66.90736389, 17.95819473],
              [-66.90736389, 17.95958328],
              [-66.9070816, 17.95958328],
              [-66.9070816, 17.96180534],
              [-66.90652466, 17.96180534],
              [-66.90652466, 17.96235847],
              [-66.90625, 17.96235847],
              [-66.90625, 17.96291733],
              [-66.90597534, 17.96291733],
              [-66.90597534, 17.96486282],
              [-66.90625, 17.96486282],
              [-66.90625, 17.96458244],
              [-66.90875244, 17.96458244],
              [-66.90875244, 17.96430588],
              [-66.90958405, 17.96430588],
              [-66.90958405, 17.9640274],
              [-66.91014099, 17.9640274],
              [-66.91014099, 17.96375084],
              [-66.91069794, 17.96375084],
              [-66.91069794, 17.96347237],
              [-66.9109726, 17.96347237],
              [-66.9109726, 17.96319389],
              [-66.91124725, 17.96319389],
              [-66.91124725, 17.96347237],
              [-66.91236115, 17.96347237],
              [-66.91236115, 17.96375084],
              [-66.9126358, 17.96375084],
              [-66.9126358, 17.9640274],
              [-66.91291809, 17.9640274],
              [-66.91291809, 17.96430588],
              [-66.91319275, 17.96430588],
              [-66.91319275, 17.96458244],
              [-66.91402435, 17.96458244],
              [-66.91402435, 17.96486282],
              [-66.91513824, 17.96486282],
              [-66.91513824, 17.96513939],
              [-66.91569519, 17.96513939],
              [-66.91569519, 17.96541595],
              [-66.91625214, 17.96541595],
              [-66.91625214, 17.96569443],
              [-66.9173584, 17.96569443],
              [-66.9173584, 17.9659729],
              [-66.91764069, 17.9659729],
              [-66.91764069, 17.96652794],
              [-66.91791534, 17.96652794],
              [-66.91791534, 17.96680641],
              [-66.91902924, 17.96680641],
              [-66.91902924, 17.96652794],
              [-66.9201355, 17.96652794],
              [-66.9201355, 17.96708488],
              [-66.92041779, 17.96708488],
              [-66.92041779, 17.96819305],
              [-66.9201355, 17.96819305],
              [-66.9201355, 17.96847153],
              [-66.92041779, 17.96847153],
              [-66.92041779, 17.96902847],
              [-66.92069244, 17.96902847],
              [-66.92069244, 17.96986198],
              [-66.92097473, 17.96986198],
              [-66.92097473, 17.97013855],
              [-66.92180634, 17.97013855],
              [-66.92180634, 17.97041512],
              [-66.92208099, 17.97041512],
              [-66.92208099, 17.97069359],
              [-66.92236328, 17.97069359],
              [-66.92236328, 17.97097206],
              [-66.92292023, 17.97097206],
              [-66.92292023, 17.97125053],
              [-66.92346954, 17.97125053],
              [-66.92346954, 17.97152519],
              [-66.92375183, 17.97152519],
              [-66.92375183, 17.97180557],
              [-66.92402649, 17.97180557],
              [-66.92402649, 17.97208595],
              [-66.92485809, 17.97208595],
              [-66.92485809, 17.97236061],
              [-66.92624664, 17.97236061],
              [-66.92624664, 17.97208595],
              [-66.92652893, 17.97208595],
              [-66.92652893, 17.97180557],
              [-66.92708588, 17.97180557],
              [-66.92708588, 17.97125053],
              [-66.92736053, 17.97125053],
              [-66.92736053, 17.97069359],
              [-66.92791748, 17.97069359],
              [-66.92791748, 17.96958351],
              [-66.92819214, 17.96958351],
              [-66.92819214, 17.96875],
              [-66.92791748, 17.96875],
              [-66.92791748, 17.96791649],
              [-66.92763519, 17.96791649],
              [-66.92763519, 17.96763802],
              [-66.92736053, 17.96763802],
              [-66.92736053, 17.96708488],
              [-66.92708588, 17.96708488],
              [-66.92708588, 17.96680641],
              [-66.92652893, 17.96680641],
              [-66.92652893, 17.96652794],
              [-66.92597198, 17.96652794],
              [-66.92597198, 17.96624947],
              [-66.92514038, 17.96624947],
              [-66.92514038, 17.9659729],
              [-66.92485809, 17.9659729],
              [-66.92485809, 17.96513939],
              [-66.92514038, 17.96513939],
              [-66.92514038, 17.9640274],
              [-66.92541504, 17.9640274],
              [-66.92541504, 17.96430588],
              [-66.92597198, 17.96430588],
              [-66.92597198, 17.96458244],
              [-66.92652893, 17.96458244],
              [-66.92652893, 17.96486282],
              [-66.92791748, 17.96486282],
              [-66.92791748, 17.96430588],
              [-66.92819214, 17.96430588],
              [-66.92819214, 17.96375084],
              [-66.92903137, 17.96375084],
              [-66.92903137, 17.96347237],
              [-66.92986298, 17.96347237],
              [-66.92986298, 17.96319389],
              [-66.93041992, 17.96319389],
              [-66.93041992, 17.96264076],
              [-66.93069458, 17.96264076],
              [-66.93069458, 17.96208382],
              [-66.93041992, 17.96208382],
              [-66.93041992, 17.96180534],
              [-66.92930603, 17.96180534],
              [-66.92930603, 17.96208382],
              [-66.92708588, 17.96208382],
              [-66.92708588, 17.96264076],
              [-66.92624664, 17.96264076],
              [-66.92624664, 17.96235847],
              [-66.92263794, 17.96235847],
              [-66.92263794, 17.96180534],
              [-66.92208099, 17.96180534],
              [-66.92208099, 17.96152878],
              [-66.92097473, 17.96152878],
              [-66.92097473, 17.96125031],
              [-66.9201355, 17.96125031],
              [-66.9201355, 17.96097183],
              [-66.91986084, 17.96097183],
              [-66.91986084, 17.96069527],
              [-66.91930389, 17.96069527],
              [-66.91930389, 17.9604187],
              [-66.91874695, 17.9604187],
              [-66.91874695, 17.96013641],
              [-66.9173584, 17.96013641],
              [-66.9173584, 17.95986176],
              [-66.91680908, 17.95986176],
              [-66.91680908, 17.95958328],
              [-66.91596985, 17.95958328],
              [-66.91596985, 17.95930481],
              [-66.9154129, 17.95930481],
              [-66.9154129, 17.95902634],
              [-66.91513824, 17.95902634],
              [-66.91513824, 17.95874977],
              [-66.91486359, 17.95874977],
              [-66.91486359, 17.9584713],
              [-66.9145813, 17.9584713],
              [-66.9145813, 17.95819473],
              [-66.91430664, 17.95819473],
              [-66.91430664, 17.95791435],
              [-66.91374969, 17.95791435],
              [-66.91374969, 17.95763969],
              [-66.91347504, 17.95763969],
              [-66.91347504, 17.95736122],
              [-66.91319275, 17.95736122],
              [-66.91319275, 17.95708275],
              [-66.9126358, 17.95708275],
              [-66.9126358, 17.95652771],
              [-66.91236115, 17.95652771],
              [-66.91236115, 17.95624924],
              [-66.91208649, 17.95624924],
              [-66.91208649, 17.95597267],
              [-66.9118042, 17.95597267],
              [-66.9118042, 17.9556942],
              [-66.91152954, 17.9556942],
              [-66.91152954, 17.95541573],
              [-66.91069794, 17.95541573],
              [-66.91069794, 17.95486069],
              [-66.91014099, 17.95486069],
              [-66.91014099, 17.95430565],
              [-66.90958405, 17.95430565],
              [-66.90958405, 17.95319366],
              [-66.90930176, 17.95319366],
              [-66.90930176, 17.95263863],
              [-66.9090271, 17.95263863],
              [-66.9090271, 17.95041656],
              [-66.90875244, 17.95041656],
              [-66.90875244, 17.95013809],
              [-66.9090271, 17.95013809],
              [-66.9090271, 17.94930649],
              [-66.90930176, 17.94930649],
              [-66.90930176, 17.94902992],
              [-66.90958405, 17.94902992],
              [-66.90958405, 17.94874763],
              [-66.9098587, 17.94874763],
              [-66.9098587, 17.94847298],
              [-66.9109726, 17.94847298],
              [-66.9109726, 17.9481945],
              [-66.91152954, 17.9481945],
              [-66.91152954, 17.94791794],
              [-66.9118042, 17.94791794],
              [-66.9118042, 17.94735909],
              [-66.91152954, 17.94735909],
              [-66.91152954, 17.94708252],
              [-66.9118042, 17.94708252],
              [-66.9118042, 17.94680786],
              [-66.91347504, 17.94680786],
              [-66.91347504, 17.94652939],
              [-66.91430664, 17.94652939],
              [-66.91430664, 17.94625092],
              [-66.91625214, 17.94625092],
              [-66.91625214, 17.94597244],
              [-66.91652679, 17.94597244],
              [-66.91652679, 17.94569397],
              [-66.91680908, 17.94569397],
              [-66.91680908, 17.94513893],
              [-66.91708374, 17.94513893],
              [-66.91708374, 17.94486046],
              [-66.91791534, 17.94486046],
              [-66.91791534, 17.94458389],
              [-66.91819763, 17.94458389],
              [-66.91819763, 17.94430542],
              [-66.91874695, 17.94430542],
              [-66.91874695, 17.94402695],
              [-66.91902924, 17.94402695],
              [-66.91902924, 17.94375038],
              [-66.91930389, 17.94375038],
              [-66.91930389, 17.94347191],
              [-66.9201355, 17.94347191],
              [-66.9201355, 17.94375038],
              [-66.92152405, 17.94375038],
              [-66.92152405, 17.94402695],
              [-66.92208099, 17.94402695],
              [-66.92208099, 17.94430542],
              [-66.92236328, 17.94430542],
              [-66.92236328, 17.94458389],
              [-66.92292023, 17.94458389],
              [-66.92292023, 17.94430542],
              [-66.92319489, 17.94430542],
              [-66.92319489, 17.94402695],
              [-66.92569733, 17.94402695],
              [-66.92569733, 17.94375038],
              [-66.92624664, 17.94375038],
              [-66.92624664, 17.94347191],
              [-66.92652893, 17.94347191],
              [-66.92652893, 17.94319534],
              [-66.92708588, 17.94319534],
              [-66.92708588, 17.94291687],
              [-66.92791748, 17.94291687],
              [-66.92791748, 17.94319534],
              [-66.92847443, 17.94319534],
              [-66.92847443, 17.94347191],
            ],
          ],
          [
            [
              [-66.97180176, 17.94597244],
              [-66.97180176, 17.94569397],
              [-66.9715271, 17.94569397],
              [-66.9715271, 17.9454155],
              [-66.9706955, 17.9454155],
              [-66.9706955, 17.94513893],
              [-66.97013855, 17.94513893],
              [-66.97013855, 17.94486046],
              [-66.96986389, 17.94486046],
              [-66.96986389, 17.94458389],
              [-66.96930695, 17.94458389],
              [-66.96930695, 17.94430542],
              [-66.96875, 17.94430542],
              [-66.96875, 17.94375038],
              [-66.96847534, 17.94375038],
              [-66.96847534, 17.94347191],
              [-66.96875, 17.94347191],
              [-66.96875, 17.94319534],
              [-66.97208405, 17.94319534],
              [-66.97208405, 17.94347191],
              [-66.97264099, 17.94347191],
              [-66.97264099, 17.94375038],
              [-66.97319794, 17.94375038],
              [-66.97319794, 17.94430542],
              [-66.9734726, 17.94430542],
              [-66.9734726, 17.94458389],
              [-66.97374725, 17.94458389],
              [-66.97374725, 17.94486046],
              [-66.97402954, 17.94486046],
              [-66.97402954, 17.94569397],
              [-66.97374725, 17.94569397],
              [-66.97374725, 17.94597244],
              [-66.97180176, 17.94597244],
            ],
          ],
          [
            [
              [-66.87291718, 17.94375038],
              [-66.87291718, 17.94347191],
              [-66.87236023, 17.94347191],
              [-66.87236023, 17.94319534],
              [-66.87152863, 17.94319534],
              [-66.87152863, 17.94347191],
              [-66.87097168, 17.94347191],
              [-66.87097168, 17.94319534],
              [-66.87069702, 17.94319534],
              [-66.87069702, 17.94097137],
              [-66.87180328, 17.94097137],
              [-66.87180328, 17.94069481],
              [-66.87264252, 17.94069481],
              [-66.87264252, 17.94041634],
              [-66.87430573, 17.94041634],
              [-66.87430573, 17.94069481],
              [-66.87458038, 17.94069481],
              [-66.87458038, 17.94097137],
              [-66.87513733, 17.94097137],
              [-66.87513733, 17.94208336],
              [-66.87486267, 17.94208336],
              [-66.87486267, 17.94236183],
              [-66.87458038, 17.94236183],
              [-66.87458038, 17.9426384],
              [-66.87403107, 17.9426384],
              [-66.87403107, 17.94319534],
              [-66.87374878, 17.94319534],
              [-66.87374878, 17.94347191],
              [-66.87319183, 17.94347191],
              [-66.87319183, 17.94375038],
              [-66.87291718, 17.94375038],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 29,
        name: 'Guánica',
        hasc_1: 'PR.GC',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.98180389, 17.92625046, -66.85912323, 18.0338707],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.31',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.79773712, 18.13364792],
              [-66.79265594, 18.13369179],
              [-66.78896332, 18.13420105],
              [-66.78804779, 18.13468552],
              [-66.7866745, 18.13660622],
              [-66.78529358, 18.1370945],
              [-66.78251648, 18.13664055],
              [-66.78066254, 18.13618088],
              [-66.7783432, 18.13524246],
              [-66.77002716, 18.1348381],
              [-66.76911163, 18.13484764],
              [-66.76908112, 18.13150787],
              [-66.76858521, 18.12721634],
              [-66.76485443, 18.12247849],
              [-66.76248932, 18.11534119],
              [-66.76107788, 18.11201477],
              [-66.76199341, 18.11105347],
              [-66.76565552, 18.10625267],
              [-66.76468658, 18.10100746],
              [-66.76468658, 18.10053253],
              [-66.76559448, 18.09909439],
              [-66.76605225, 18.09861183],
              [-66.76921844, 18.08999825],
              [-66.76965332, 18.08665466],
              [-66.76686096, 18.08333588],
              [-66.76590729, 18.07952881],
              [-66.76589203, 18.0780983],
              [-66.7663269, 18.07475281],
              [-66.76816559, 18.07330513],
              [-66.76906586, 18.07091522],
              [-66.76859283, 18.06948662],
              [-66.76898956, 18.06089211],
              [-66.76894379, 18.05516624],
              [-66.76843262, 18.04896355],
              [-66.76651764, 18.03991508],
              [-66.76512909, 18.03897476],
              [-66.75649261, 18.05574608],
              [-66.75556946, 18.05623055],
              [-66.75510406, 18.05623436],
              [-66.74671173, 18.04484749],
              [-66.73980713, 18.0472908],
              [-66.7388916, 18.04729843],
              [-66.73654938, 18.04349899],
              [-66.73608398, 18.04254913],
              [-66.74198914, 18.03057289],
              [-66.73733521, 18.02488136],
              [-66.73453522, 18.02061081],
              [-66.73312378, 18.01728058],
              [-66.73448944, 18.0144043],
              [-66.73448181, 18.01392555],
              [-66.73770905, 18.0134201],
              [-66.74324036, 18.01290131],
              [-66.74554443, 18.01192856],
              [-66.74829865, 18.0109539],
              [-66.75149536, 18.00662613],
              [-66.75466919, 17.99896622],
              [-66.75485992, 17.99896812],
              [-66.75485992, 17.9995842],
              [-66.75514221, 17.9995842],
              [-66.75514984, 17.99986076],
              [-66.75515747, 18.00013733],
              [-66.75514221, 18.0004158],
              [-66.75458527, 18.0004158],
              [-66.75458527, 18.00097275],
              [-66.75514221, 18.00097275],
              [-66.75514221, 18.00124741],
              [-66.75736237, 18.00124741],
              [-66.75736237, 18.00097275],
              [-66.75763702, 18.00097275],
              [-66.75763702, 18.00069427],
              [-66.75819397, 18.00069427],
              [-66.75819397, 18.0004158],
              [-66.75958252, 18.0004158],
              [-66.75958252, 18.00029373],
              [-66.75958252, 17.99986076],
              [-66.76013947, 17.99986076],
              [-66.76013947, 17.9995842],
              [-66.76069641, 17.9995842],
              [-66.76069641, 17.99930763],
              [-66.76152802, 17.99930763],
              [-66.76152802, 17.9995842],
              [-66.76264191, 17.9995842],
              [-66.76264191, 17.99930763],
              [-66.76374817, 17.99930763],
              [-66.76374817, 17.99986076],
              [-66.76319122, 17.99986076],
              [-66.76319122, 18.00013733],
              [-66.76319122, 18.0004158],
              [-66.76347351, 18.0004158],
              [-66.76347351, 18.00319481],
              [-66.76374817, 18.00319481],
              [-66.76374817, 18.00430489],
              [-66.76403046, 18.00430489],
              [-66.76403046, 18.00458336],
              [-66.76457977, 18.00458336],
              [-66.76457977, 18.00486183],
              [-66.76486206, 18.00486183],
              [-66.76486206, 18.0051384],
              [-66.76569366, 18.0051384],
              [-66.76569366, 18.00486183],
              [-66.76652527, 18.00486183],
              [-66.76652527, 18.00375175],
              [-66.76708221, 18.00375175],
              [-66.76708221, 18.00569534],
              [-66.76680756, 18.00569534],
              [-66.76680756, 18.00597382],
              [-66.76958466, 18.00597382],
              [-66.76958466, 18.00569534],
              [-66.76985931, 18.00569534],
              [-66.76985931, 18.00597382],
              [-66.7701416, 18.00597382],
              [-66.7701416, 18.00569534],
              [-66.77069092, 18.00569534],
              [-66.77069092, 18.00541687],
              [-66.77124786, 18.00541687],
              [-66.77124786, 18.0051384],
              [-66.77153015, 18.0051384],
              [-66.77153015, 18.00486183],
              [-66.77180481, 18.00486183],
              [-66.77180481, 18.00458336],
              [-66.77236176, 18.00458336],
              [-66.77236176, 18.00402832],
              [-66.77263641, 18.00402832],
              [-66.77263641, 18.00347137],
              [-66.7729187, 18.00347137],
              [-66.7729187, 18.00319481],
              [-66.77319336, 18.00319481],
              [-66.77319336, 18.00291634],
              [-66.77347565, 18.00291634],
              [-66.77347565, 18.0023613],
              [-66.77402496, 18.0023613],
              [-66.77402496, 18.00180626],
              [-66.77430725, 18.00180626],
              [-66.77430725, 18.00152969],
              [-66.77458191, 18.00152969],
              [-66.77458191, 18.00124741],
              [-66.77541351, 18.00124741],
              [-66.77541351, 18.00097275],
              [-66.7756958, 18.00097275],
              [-66.7756958, 18.00069427],
              [-66.77597046, 18.00069427],
              [-66.77597046, 18.0004158],
              [-66.77680206, 18.0004158],
              [-66.77680206, 18.00013733],
              [-66.7776413, 18.00013733],
              [-66.7776413, 18.0004158],
              [-66.77986145, 18.0004158],
              [-66.77986145, 18.00013733],
              [-66.77986145, 17.99986076],
              [-66.78097534, 17.99986076],
              [-66.78097534, 17.99930763],
              [-66.78152466, 17.99930763],
              [-66.78152466, 17.99902534],
              [-66.78180695, 17.99902534],
              [-66.78180695, 17.99875069],
              [-66.7820816, 17.99875069],
              [-66.7820816, 17.99847221],
              [-66.78236389, 17.99847221],
              [-66.78236389, 17.99819374],
              [-66.78263855, 17.99819374],
              [-66.78263855, 17.99791527],
              [-66.78291321, 17.99791527],
              [-66.78291321, 17.99736023],
              [-66.7831955, 17.99736023],
              [-66.7831955, 17.99652863],
              [-66.78375244, 17.99652863],
              [-66.78375244, 17.99625015],
              [-66.7840271, 17.99625015],
              [-66.7840271, 17.99569321],
              [-66.78430176, 17.99569321],
              [-66.78430176, 17.99541664],
              [-66.78458405, 17.99541664],
              [-66.78458405, 17.99513817],
              [-66.78514099, 17.99513817],
              [-66.78514099, 17.9948616],
              [-66.78736115, 17.9948616],
              [-66.78736115, 17.99513817],
              [-66.78791809, 17.99513817],
              [-66.78791809, 17.9948616],
              [-66.78819275, 17.9948616],
              [-66.78819275, 17.99458313],
              [-66.78847504, 17.99458313],
              [-66.78847504, 17.99430466],
              [-66.78874969, 17.99430466],
              [-66.78874969, 17.99402809],
              [-66.78902435, 17.99402809],
              [-66.78902435, 17.99374962],
              [-66.78930664, 17.99374962],
              [-66.78930664, 17.99347115],
              [-66.7895813, 17.99347115],
              [-66.7895813, 17.99319458],
              [-66.78986359, 17.99319458],
              [-66.78986359, 17.99291611],
              [-66.79013824, 17.99291611],
              [-66.79013824, 17.99263954],
              [-66.7904129, 17.99263954],
              [-66.7904129, 17.99236107],
              [-66.79402924, 17.99236107],
              [-66.79402924, 17.99263954],
              [-66.79486084, 17.99263954],
              [-66.79486084, 17.99291611],
              [-66.7951355, 17.99291611],
              [-66.7951355, 17.99319458],
              [-66.79541779, 17.99319458],
              [-66.79541779, 17.99347115],
              [-66.79569244, 17.99347115],
              [-66.79569244, 17.99374962],
              [-66.79624939, 17.99374962],
              [-66.79624939, 17.99402809],
              [-66.79680634, 17.99402809],
              [-66.79680634, 17.99430466],
              [-66.79736328, 17.99430466],
              [-66.79736328, 17.99402809],
              [-66.79792023, 17.99402809],
              [-66.79792023, 17.99347115],
              [-66.79846954, 17.99347115],
              [-66.79846954, 17.99319458],
              [-66.79875183, 17.99319458],
              [-66.79875183, 17.99263954],
              [-66.79902649, 17.99263954],
              [-66.79902649, 17.99124908],
              [-66.79875183, 17.99124908],
              [-66.79875183, 17.99041748],
              [-66.79846954, 17.99041748],
              [-66.79846954, 17.9893055],
              [-66.79819489, 17.9893055],
              [-66.79819489, 17.98902893],
              [-66.79792023, 17.98902893],
              [-66.79792023, 17.98708344],
              [-66.79763794, 17.98708344],
              [-66.79763794, 17.98680496],
              [-66.79736328, 17.98680496],
              [-66.79736328, 17.98652649],
              [-66.79708099, 17.98652649],
              [-66.79708099, 17.98624992],
              [-66.79680634, 17.98624992],
              [-66.79680634, 17.98597145],
              [-66.79652405, 17.98597145],
              [-66.79652405, 17.98569679],
              [-66.79624939, 17.98569679],
              [-66.79624939, 17.98513794],
              [-66.79597473, 17.98513794],
              [-66.79597473, 17.98374939],
              [-66.79652405, 17.98374939],
              [-66.79652405, 17.98347282],
              [-66.79680634, 17.98347282],
              [-66.79680634, 17.98291588],
              [-66.79708099, 17.98291588],
              [-66.79708099, 17.98236084],
              [-66.79736328, 17.98236084],
              [-66.79736328, 17.9818058],
              [-66.79763794, 17.9818058],
              [-66.79763794, 17.98152733],
              [-66.79819489, 17.98152733],
              [-66.79819489, 17.98125076],
              [-66.79985809, 17.98125076],
              [-66.79985809, 17.98097229],
              [-66.80069733, 17.98097229],
              [-66.80069733, 17.98125076],
              [-66.80124664, 17.98125076],
              [-66.80124664, 17.98236084],
              [-66.80152893, 17.98236084],
              [-66.80152893, 17.98291588],
              [-66.80180359, 17.98291588],
              [-66.80180359, 17.98319435],
              [-66.80208588, 17.98319435],
              [-66.80208588, 17.98347282],
              [-66.80236053, 17.98347282],
              [-66.80236053, 17.98374939],
              [-66.80347443, 17.98374939],
              [-66.80347443, 17.98402786],
              [-66.80403137, 17.98402786],
              [-66.80403137, 17.98430443],
              [-66.80513763, 17.98430443],
              [-66.80513763, 17.98402786],
              [-66.80541992, 17.98402786],
              [-66.80541992, 17.98374939],
              [-66.80596924, 17.98374939],
              [-66.80596924, 17.98347282],
              [-66.80652618, 17.98347282],
              [-66.80652618, 17.98263931],
              [-66.80680847, 17.98263931],
              [-66.80680847, 17.98208427],
              [-66.80708313, 17.98208427],
              [-66.80708313, 17.98125076],
              [-66.80735779, 17.98125076],
              [-66.80735779, 17.98041725],
              [-66.80708313, 17.98041725],
              [-66.80708313, 17.98013878],
              [-66.80735779, 17.98013878],
              [-66.80735779, 17.97791672],
              [-66.80708313, 17.97791672],
              [-66.80708313, 17.97708321],
              [-66.80680847, 17.97708321],
              [-66.80680847, 17.97652817],
              [-66.80652618, 17.97652817],
              [-66.80652618, 17.97597313],
              [-66.80625153, 17.97597313],
              [-66.80625153, 17.97569466],
              [-66.80569458, 17.97569466],
              [-66.80569458, 17.97541618],
              [-66.80513763, 17.97541618],
              [-66.80513763, 17.97569466],
              [-66.80403137, 17.97569466],
              [-66.80403137, 17.97597313],
              [-66.80347443, 17.97597313],
              [-66.80347443, 17.9762516],
              [-66.80291748, 17.9762516],
              [-66.80291748, 17.97652817],
              [-66.80263519, 17.97652817],
              [-66.80263519, 17.97680473],
              [-66.79792023, 17.97680473],
              [-66.79792023, 17.97652817],
              [-66.79680634, 17.97652817],
              [-66.79680634, 17.97708321],
              [-66.79569244, 17.97708321],
              [-66.79569244, 17.97736168],
              [-66.79430389, 17.97736168],
              [-66.79430389, 17.97763824],
              [-66.79319763, 17.97763824],
              [-66.79319763, 17.97791672],
              [-66.79264069, 17.97791672],
              [-66.79264069, 17.97819519],
              [-66.7923584, 17.97819519],
              [-66.7923584, 17.97847176],
              [-66.79208374, 17.97847176],
              [-66.79208374, 17.97875023],
              [-66.79180908, 17.97875023],
              [-66.79180908, 17.9790287],
              [-66.79125214, 17.9790287],
              [-66.79125214, 17.97930527],
              [-66.79096985, 17.97930527],
              [-66.79096985, 17.97958374],
              [-66.79069519, 17.97958374],
              [-66.79069519, 17.97986031],
              [-66.7904129, 17.97986031],
              [-66.7904129, 17.98041725],
              [-66.79013824, 17.98041725],
              [-66.79013824, 17.98069382],
              [-66.78986359, 17.98069382],
              [-66.78986359, 17.98097229],
              [-66.7895813, 17.98097229],
              [-66.7895813, 17.98152733],
              [-66.78930664, 17.98152733],
              [-66.78930664, 17.9818058],
              [-66.78902435, 17.9818058],
              [-66.78902435, 17.98208427],
              [-66.78874969, 17.98208427],
              [-66.78874969, 17.98263931],
              [-66.78847504, 17.98263931],
              [-66.78847504, 17.98319435],
              [-66.78819275, 17.98319435],
              [-66.78819275, 17.98374939],
              [-66.78791809, 17.98374939],
              [-66.78791809, 17.98402786],
              [-66.7876358, 17.98402786],
              [-66.7876358, 17.9845829],
              [-66.78736115, 17.9845829],
              [-66.78736115, 17.98486137],
              [-66.78708649, 17.98486137],
              [-66.78708649, 17.98513794],
              [-66.7868042, 17.98513794],
              [-66.7868042, 17.98569679],
              [-66.78652954, 17.98569679],
              [-66.78652954, 17.98597145],
              [-66.7859726, 17.98597145],
              [-66.7859726, 17.98624992],
              [-66.78458405, 17.98624992],
              [-66.78458405, 17.98597145],
              [-66.7840271, 17.98597145],
              [-66.7840271, 17.98569679],
              [-66.78375244, 17.98569679],
              [-66.78375244, 17.98541641],
              [-66.78347015, 17.98541641],
              [-66.78347015, 17.98486137],
              [-66.7831955, 17.98486137],
              [-66.7831955, 17.98291588],
              [-66.78347015, 17.98291588],
              [-66.78347015, 17.98263931],
              [-66.78375244, 17.98263931],
              [-66.78375244, 17.98125076],
              [-66.7831955, 17.98125076],
              [-66.7831955, 17.97958374],
              [-66.78347015, 17.97958374],
              [-66.78347015, 17.97930527],
              [-66.78375244, 17.97930527],
              [-66.78375244, 17.97875023],
              [-66.7840271, 17.97875023],
              [-66.7840271, 17.97847176],
              [-66.78430176, 17.97847176],
              [-66.78430176, 17.97819519],
              [-66.78458405, 17.97819519],
              [-66.78458405, 17.97763824],
              [-66.7848587, 17.97763824],
              [-66.7848587, 17.97708321],
              [-66.78514099, 17.97708321],
              [-66.78514099, 17.97652817],
              [-66.78541565, 17.97652817],
              [-66.78541565, 17.9762516],
              [-66.78569794, 17.9762516],
              [-66.78569794, 17.97569466],
              [-66.7859726, 17.97569466],
              [-66.7859726, 17.97541618],
              [-66.78624725, 17.97541618],
              [-66.78624725, 17.97513962],
              [-66.78652954, 17.97513962],
              [-66.78652954, 17.97402954],
              [-66.7868042, 17.97402954],
              [-66.7868042, 17.97263718],
              [-66.78736115, 17.97263718],
              [-66.78736115, 17.97236061],
              [-66.7876358, 17.97236061],
              [-66.7876358, 17.97208595],
              [-66.78819275, 17.97208595],
              [-66.78819275, 17.97180557],
              [-66.78847504, 17.97180557],
              [-66.78847504, 17.97152519],
              [-66.78930664, 17.97152519],
              [-66.78930664, 17.97125053],
              [-66.79013824, 17.97125053],
              [-66.79013824, 17.97097206],
              [-66.79069519, 17.97097206],
              [-66.79069519, 17.97069359],
              [-66.79125214, 17.97069359],
              [-66.79125214, 17.97041512],
              [-66.79152679, 17.97041512],
              [-66.79152679, 17.97013855],
              [-66.79180908, 17.97013855],
              [-66.79180908, 17.96986198],
              [-66.79208374, 17.96986198],
              [-66.79208374, 17.96930313],
              [-66.79264069, 17.96930313],
              [-66.79264069, 17.96902847],
              [-66.79319763, 17.96902847],
              [-66.79319763, 17.96930313],
              [-66.79624939, 17.96930313],
              [-66.79624939, 17.96902847],
              [-66.79652405, 17.96902847],
              [-66.79652405, 17.96875],
              [-66.79763794, 17.96875],
              [-66.79763794, 17.96847153],
              [-66.79930878, 17.96847153],
              [-66.79930878, 17.96819305],
              [-66.79985809, 17.96819305],
              [-66.79985809, 17.96791649],
              [-66.80041504, 17.96791649],
              [-66.80041504, 17.96763802],
              [-66.80097198, 17.96763802],
              [-66.80097198, 17.96736145],
              [-66.80124664, 17.96736145],
              [-66.80124664, 17.96708488],
              [-66.80263519, 17.96708488],
              [-66.80263519, 17.96680641],
              [-66.80319214, 17.96680641],
              [-66.80319214, 17.96652794],
              [-66.80374908, 17.96652794],
              [-66.80374908, 17.96624947],
              [-66.80458069, 17.96624947],
              [-66.80458069, 17.9659729],
              [-66.80541992, 17.9659729],
              [-66.80541992, 17.96569443],
              [-66.80596924, 17.96569443],
              [-66.80596924, 17.96541595],
              [-66.80625153, 17.96541595],
              [-66.80625153, 17.96569443],
              [-66.80764008, 17.96569443],
              [-66.80764008, 17.96541595],
              [-66.80819702, 17.96541595],
              [-66.80819702, 17.96513939],
              [-66.80847168, 17.96513939],
              [-66.80847168, 17.96486282],
              [-66.80874634, 17.96486282],
              [-66.80874634, 17.96458244],
              [-66.80902863, 17.96458244],
              [-66.80902863, 17.96430588],
              [-66.80958557, 17.96430588],
              [-66.80958557, 17.9640274],
              [-66.80986023, 17.9640274],
              [-66.80986023, 17.96235847],
              [-66.81041718, 17.96235847],
              [-66.81041718, 17.96208382],
              [-66.81124878, 17.96208382],
              [-66.81124878, 17.96180534],
              [-66.81180573, 17.96180534],
              [-66.81180573, 17.96152878],
              [-66.81208038, 17.96152878],
              [-66.81208038, 17.96125031],
              [-66.81263733, 17.96125031],
              [-66.81263733, 17.96097183],
              [-66.81319427, 17.96097183],
              [-66.81319427, 17.96069527],
              [-66.81346893, 17.96069527],
              [-66.81346893, 17.96013641],
              [-66.81430817, 17.96013641],
              [-66.81430817, 17.95902634],
              [-66.81458282, 17.95902634],
              [-66.81458282, 17.95930481],
              [-66.81541443, 17.95930481],
              [-66.81541443, 17.95902634],
              [-66.81597137, 17.95902634],
              [-66.81597137, 17.95874977],
              [-66.81680298, 17.95874977],
              [-66.81680298, 17.95819473],
              [-66.81708527, 17.95819473],
              [-66.81708527, 17.95736122],
              [-66.81735992, 17.95736122],
              [-66.81735992, 17.95597267],
              [-66.81764221, 17.95597267],
              [-66.81764221, 17.95541573],
              [-66.81791687, 17.95541573],
              [-66.81791687, 17.95486069],
              [-66.81930542, 17.95486069],
              [-66.81930542, 17.95513916],
              [-66.81986237, 17.95513916],
              [-66.81986237, 17.95541573],
              [-66.82096863, 17.95541573],
              [-66.82096863, 17.95513916],
              [-66.82125092, 17.95513916],
              [-66.82125092, 17.95486069],
              [-66.82208252, 17.95486069],
              [-66.82208252, 17.95430565],
              [-66.82236481, 17.95430565],
              [-66.82236481, 17.95402718],
              [-66.82291412, 17.95402718],
              [-66.82291412, 17.95347214],
              [-66.82375336, 17.95347214],
              [-66.82375336, 17.95375061],
              [-66.82402802, 17.95375061],
              [-66.82402802, 17.95402718],
              [-66.82541656, 17.95402718],
              [-66.82541656, 17.95486069],
              [-66.82624817, 17.95486069],
              [-66.82624817, 17.95458221],
              [-66.82653046, 17.95458221],
              [-66.82653046, 17.95375061],
              [-66.82680511, 17.95375061],
              [-66.82680511, 17.95319366],
              [-66.82736206, 17.95319366],
              [-66.82736206, 17.9529171],
              [-66.82847595, 17.9529171],
              [-66.82847595, 17.95347214],
              [-66.82958221, 17.95347214],
              [-66.82958221, 17.95319366],
              [-66.8298645, 17.95319366],
              [-66.8298645, 17.9529171],
              [-66.83013916, 17.9529171],
              [-66.83013916, 17.95236206],
              [-66.83069611, 17.95236206],
              [-66.83069611, 17.95208359],
              [-66.83097076, 17.95208359],
              [-66.83097076, 17.95180511],
              [-66.83125305, 17.95180511],
              [-66.83125305, 17.95152855],
              [-66.8326416, 17.95152855],
              [-66.8326416, 17.95125008],
              [-66.83319092, 17.95125008],
              [-66.83319092, 17.9509716],
              [-66.83403015, 17.9509716],
              [-66.83403015, 17.95069504],
              [-66.8345871, 17.95069504],
              [-66.8345871, 17.95041656],
              [-66.83486176, 17.95041656],
              [-66.83486176, 17.95069504],
              [-66.8354187, 17.95069504],
              [-66.8354187, 17.95041656],
              [-66.83569336, 17.95041656],
              [-66.83569336, 17.94986153],
              [-66.83597565, 17.94986153],
              [-66.83597565, 17.94958305],
              [-66.83625031, 17.94958305],
              [-66.83625031, 17.95013809],
              [-66.83652496, 17.95013809],
              [-66.83652496, 17.95041656],
              [-66.83708191, 17.95041656],
              [-66.83708191, 17.95013809],
              [-66.83763885, 17.95013809],
              [-66.83763885, 17.94986153],
              [-66.8381958, 17.94986153],
              [-66.8381958, 17.94958305],
              [-66.83847046, 17.94958305],
              [-66.83847046, 17.94930649],
              [-66.83958435, 17.94930649],
              [-66.83958435, 17.94986153],
              [-66.84124756, 17.94986153],
              [-66.84124756, 17.95013809],
              [-66.8418045, 17.95013809],
              [-66.8418045, 17.95041656],
              [-66.84402466, 17.95041656],
              [-66.84402466, 17.94986153],
              [-66.84541321, 17.94986153],
              [-66.84541321, 17.95013809],
              [-66.84513855, 17.95013809],
              [-66.84513855, 17.95125008],
              [-66.84486389, 17.95125008],
              [-66.84486389, 17.95152855],
              [-66.84513855, 17.95152855],
              [-66.84513855, 17.95180511],
              [-66.84541321, 17.95180511],
              [-66.84541321, 17.95236206],
              [-66.8456955, 17.95236206],
              [-66.8456955, 17.95263863],
              [-66.84819794, 17.95263863],
              [-66.84819794, 17.95236206],
              [-66.84902954, 17.95236206],
              [-66.84902954, 17.95263863],
              [-66.84986115, 17.95263863],
              [-66.84986115, 17.9529171],
              [-66.8501358, 17.9529171],
              [-66.8501358, 17.95319366],
              [-66.8529129, 17.95319366],
              [-66.8529129, 17.95425415],
              [-66.83648682, 17.96579361],
              [-66.83653259, 17.9705677],
              [-66.83613586, 17.97868729],
              [-66.83529663, 17.9882431],
              [-66.83213806, 17.99638748],
              [-66.83213806, 17.99686241],
              [-66.8307724, 17.99878502],
              [-66.82846832, 17.99880409],
              [-66.82617187, 17.99978256],
              [-66.8266983, 18.0078907],
              [-66.83087158, 18.01024055],
              [-66.82955933, 18.01884651],
              [-66.81856537, 18.02801132],
              [-66.81811523, 18.02944756],
              [-66.81858063, 18.03039742],
              [-66.82423401, 18.04418755],
              [-66.8242569, 18.04657173],
              [-66.81834412, 18.05760193],
              [-66.8188324, 18.06045914],
              [-66.81839752, 18.06380463],
              [-66.81752014, 18.06906319],
              [-66.81615448, 18.07146263],
              [-66.81387329, 18.07482338],
              [-66.80246735, 18.09114456],
              [-66.8052597, 18.0939827],
              [-66.80665588, 18.09540367],
              [-66.81047821, 18.1111145],
              [-66.8105011, 18.1135006],
              [-66.81004333, 18.11398125],
              [-66.80819702, 18.11447525],
              [-66.80313873, 18.11690521],
              [-66.79624939, 18.1212616],
              [-66.79625702, 18.12221336],
              [-66.79721832, 18.12649727],
              [-66.79773712, 18.13364792],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 31,
        name: 'Guayanilla',
        hasc_1: 'PR.GL',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.8529129, 17.94930649, -66.73312378, 18.1370945],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.30',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.2061615, 17.96264076],
              [-66.20574188, 17.97844887],
              [-66.20574951, 17.98226547],
              [-66.20574951, 17.98274422],
              [-66.20578003, 17.99801826],
              [-66.20578003, 17.9984951],
              [-66.20581055, 18.01138306],
              [-66.20535278, 18.01329613],
              [-66.20028687, 18.01903152],
              [-66.19798279, 18.01999283],
              [-66.19705963, 18.02047157],
              [-66.18692017, 18.02478981],
              [-66.17494202, 18.03292465],
              [-66.16620636, 18.05012131],
              [-66.15743256, 18.04775047],
              [-66.15603638, 18.04345894],
              [-66.15511322, 18.04346275],
              [-66.15465546, 18.04298401],
              [-66.15049744, 18.04203224],
              [-66.14404297, 18.04157066],
              [-66.14357758, 18.04061508],
              [-66.13895416, 18.0368042],
              [-66.13526154, 18.03728867],
              [-66.13249969, 18.03824615],
              [-66.12880707, 18.03920746],
              [-66.12880707, 18.04015923],
              [-66.1288147, 18.04064178],
              [-66.12789917, 18.0482769],
              [-66.12467194, 18.05305099],
              [-66.12374878, 18.05400658],
              [-66.12329102, 18.05639648],
              [-66.12099457, 18.06355476],
              [-66.12054443, 18.07023621],
              [-66.12146759, 18.07309723],
              [-66.12516022, 18.07452583],
              [-66.12562561, 18.0754776],
              [-66.12654877, 18.07739067],
              [-66.12609863, 18.08263588],
              [-66.12887573, 18.08930779],
              [-66.12841797, 18.09360695],
              [-66.12703705, 18.09647179],
              [-66.12657928, 18.09694862],
              [-66.11271667, 18.09028435],
              [-66.10903931, 18.09983253],
              [-66.10903168, 18.10030937],
              [-66.10257721, 18.10508919],
              [-66.10118866, 18.10556984],
              [-66.1007309, 18.10604477],
              [-66.09796143, 18.10700226],
              [-66.09380341, 18.10700607],
              [-66.08826447, 18.10749054],
              [-66.0827179, 18.10415649],
              [-66.0785675, 18.10320091],
              [-66.07117462, 18.10225677],
              [-66.07025146, 18.10178185],
              [-66.06840515, 18.10178185],
              [-66.06378937, 18.10273743],
              [-66.06286621, 18.10321617],
              [-66.05963898, 18.10369492],
              [-66.05316925, 18.10751534],
              [-66.0605545, 18.0984478],
              [-66.06008911, 18.09558487],
              [-66.06008911, 18.09319878],
              [-66.06008911, 18.09081459],
              [-66.06054687, 18.08938408],
              [-66.06239319, 18.0870018],
              [-66.06378174, 18.08508682],
              [-66.06562042, 18.08221817],
              [-66.0679245, 18.07888031],
              [-66.07485199, 18.07553291],
              [-66.07993317, 18.07505035],
              [-66.08131409, 18.07361984],
              [-66.07946014, 18.07028008],
              [-66.07991791, 18.06598473],
              [-66.08084106, 18.06407738],
              [-66.08545685, 18.06359482],
              [-66.08914185, 18.05643463],
              [-66.08544159, 18.04832268],
              [-66.0872879, 18.04355049],
              [-66.0872879, 18.04260063],
              [-66.08589172, 18.03687096],
              [-66.08543396, 18.03591919],
              [-66.0821991, 18.03257751],
              [-66.08450317, 18.02828026],
              [-66.08957672, 18.02445984],
              [-66.09049988, 18.02445793],
              [-66.09418488, 18.02350044],
              [-66.09510803, 18.02206612],
              [-66.09510803, 18.02158928],
              [-66.09141541, 18.01777458],
              [-66.07663727, 18.00012779],
              [-66.07155609, 17.99392891],
              [-66.07201385, 17.98724365],
              [-66.07247162, 17.97864723],
              [-66.07024384, 17.96624947],
              [-66.07041931, 17.96624947],
              [-66.07041931, 17.9659729],
              [-66.07069397, 17.9659729],
              [-66.07069397, 17.96624947],
              [-66.07096863, 17.96624947],
              [-66.07096863, 17.96652794],
              [-66.07208252, 17.96652794],
              [-66.07208252, 17.96624947],
              [-66.07291412, 17.96624947],
              [-66.07291412, 17.9659729],
              [-66.07347107, 17.9659729],
              [-66.07347107, 17.96569443],
              [-66.07402802, 17.96569443],
              [-66.07402802, 17.96541595],
              [-66.07458496, 17.96541595],
              [-66.07458496, 17.96513939],
              [-66.07514191, 17.96513939],
              [-66.07514191, 17.96486282],
              [-66.07569122, 17.96486282],
              [-66.07569122, 17.96513939],
              [-66.07597351, 17.96513939],
              [-66.07597351, 17.96541595],
              [-66.07653046, 17.96541595],
              [-66.07653046, 17.96569443],
              [-66.07680511, 17.96569443],
              [-66.07680511, 17.9659729],
              [-66.07707977, 17.9659729],
              [-66.07707977, 17.96624947],
              [-66.07736206, 17.96624947],
              [-66.07736206, 17.96680641],
              [-66.07791901, 17.96680641],
              [-66.07791901, 17.96708488],
              [-66.07875061, 17.96708488],
              [-66.07875061, 17.96680641],
              [-66.07958221, 17.96680641],
              [-66.07958221, 17.96624947],
              [-66.08180237, 17.96624947],
              [-66.08180237, 17.9659729],
              [-66.08291626, 17.9659729],
              [-66.08291626, 17.96569443],
              [-66.08347321, 17.96569443],
              [-66.08347321, 17.96541595],
              [-66.08403015, 17.96541595],
              [-66.08403015, 17.96513939],
              [-66.0845871, 17.96513939],
              [-66.0845871, 17.96486282],
              [-66.08569336, 17.96486282],
              [-66.08569336, 17.96458244],
              [-66.08625031, 17.96458244],
              [-66.08625031, 17.9640274],
              [-66.08652496, 17.9640274],
              [-66.08652496, 17.96375084],
              [-66.0873642, 17.96375084],
              [-66.0873642, 17.96347237],
              [-66.08875275, 17.96347237],
              [-66.08875275, 17.96319389],
              [-66.08930206, 17.96319389],
              [-66.08930206, 17.96291733],
              [-66.08958435, 17.96291733],
              [-66.08958435, 17.96264076],
              [-66.0901413, 17.96264076],
              [-66.0901413, 17.96235847],
              [-66.09041595, 17.96235847],
              [-66.09041595, 17.96208382],
              [-66.09069824, 17.96208382],
              [-66.09069824, 17.96180534],
              [-66.09124756, 17.96180534],
              [-66.09124756, 17.96152878],
              [-66.0918045, 17.96152878],
              [-66.0918045, 17.96125031],
              [-66.09208679, 17.96125031],
              [-66.09208679, 17.96097183],
              [-66.09236145, 17.96097183],
              [-66.09236145, 17.96069527],
              [-66.0929184, 17.96069527],
              [-66.0929184, 17.9604187],
              [-66.09319305, 17.9604187],
              [-66.09319305, 17.96013641],
              [-66.09402466, 17.96013641],
              [-66.09402466, 17.95986176],
              [-66.09430695, 17.95986176],
              [-66.09430695, 17.95958328],
              [-66.0945816, 17.95958328],
              [-66.0945816, 17.95930481],
              [-66.09486389, 17.95930481],
              [-66.09486389, 17.95902634],
              [-66.0956955, 17.95902634],
              [-66.0956955, 17.95874977],
              [-66.09625244, 17.95874977],
              [-66.09625244, 17.9584713],
              [-66.09680176, 17.9584713],
              [-66.09680176, 17.95819473],
              [-66.0973587, 17.95819473],
              [-66.0973587, 17.95791435],
              [-66.09791565, 17.95791435],
              [-66.09791565, 17.95763969],
              [-66.09819794, 17.95763969],
              [-66.09819794, 17.95736122],
              [-66.0984726, 17.95736122],
              [-66.0984726, 17.95708275],
              [-66.09902954, 17.95708275],
              [-66.09902954, 17.95680428],
              [-66.09958649, 17.95680428],
              [-66.09958649, 17.95652771],
              [-66.10041809, 17.95652771],
              [-66.10041809, 17.95624924],
              [-66.10124969, 17.95624924],
              [-66.10124969, 17.95597267],
              [-66.10180664, 17.95597267],
              [-66.10180664, 17.9556942],
              [-66.10236359, 17.9556942],
              [-66.10236359, 17.95541573],
              [-66.10263824, 17.95541573],
              [-66.10263824, 17.95513916],
              [-66.1029129, 17.95513916],
              [-66.1029129, 17.95486069],
              [-66.10319519, 17.95486069],
              [-66.10319519, 17.95458221],
              [-66.10375214, 17.95458221],
              [-66.10375214, 17.95430565],
              [-66.10402679, 17.95430565],
              [-66.10402679, 17.95402718],
              [-66.10430908, 17.95402718],
              [-66.10430908, 17.95375061],
              [-66.10458374, 17.95375061],
              [-66.10458374, 17.95347214],
              [-66.10514069, 17.95347214],
              [-66.10514069, 17.95319366],
              [-66.10569763, 17.95319366],
              [-66.10569763, 17.9529171],
              [-66.10624695, 17.9529171],
              [-66.10624695, 17.95263863],
              [-66.10652924, 17.95263863],
              [-66.10652924, 17.95236206],
              [-66.10708618, 17.95236206],
              [-66.10708618, 17.95208359],
              [-66.1076355, 17.95208359],
              [-66.1076355, 17.95180511],
              [-66.10819244, 17.95180511],
              [-66.10819244, 17.95152855],
              [-66.10847473, 17.95152855],
              [-66.10847473, 17.95125008],
              [-66.10930634, 17.95125008],
              [-66.10930634, 17.9509716],
              [-66.10958099, 17.9509716],
              [-66.10958099, 17.95069504],
              [-66.10986328, 17.95069504],
              [-66.10986328, 17.95041656],
              [-66.11013794, 17.95041656],
              [-66.11013794, 17.95013809],
              [-66.11042023, 17.95013809],
              [-66.11042023, 17.94986153],
              [-66.11152649, 17.94986153],
              [-66.11152649, 17.94930649],
              [-66.11208344, 17.94930649],
              [-66.11208344, 17.94902992],
              [-66.11235809, 17.94902992],
              [-66.11235809, 17.94874763],
              [-66.11264038, 17.94874763],
              [-66.11264038, 17.94847298],
              [-66.11291504, 17.94847298],
              [-66.11291504, 17.9481945],
              [-66.11374664, 17.9481945],
              [-66.11374664, 17.94874763],
              [-66.11680603, 17.94874763],
              [-66.11680603, 17.94847298],
              [-66.11763763, 17.94847298],
              [-66.11763763, 17.9481945],
              [-66.11875153, 17.9481945],
              [-66.11875153, 17.94791794],
              [-66.12180328, 17.94791794],
              [-66.12180328, 17.94763947],
              [-66.12236023, 17.94763947],
              [-66.12236023, 17.94735909],
              [-66.12374878, 17.94735909],
              [-66.12374878, 17.94708252],
              [-66.12458038, 17.94708252],
              [-66.12458038, 17.94680786],
              [-66.12486267, 17.94680786],
              [-66.12486267, 17.94652939],
              [-66.12541962, 17.94652939],
              [-66.12541962, 17.94625092],
              [-66.12569427, 17.94625092],
              [-66.12569427, 17.94652939],
              [-66.12652588, 17.94652939],
              [-66.12652588, 17.94680786],
              [-66.12875366, 17.94680786],
              [-66.12875366, 17.94652939],
              [-66.12985992, 17.94652939],
              [-66.12985992, 17.94625092],
              [-66.13014221, 17.94625092],
              [-66.13014221, 17.94569397],
              [-66.13069153, 17.94569397],
              [-66.13069153, 17.94513893],
              [-66.13097382, 17.94513893],
              [-66.13097382, 17.94458389],
              [-66.13124847, 17.94458389],
              [-66.13124847, 17.94430542],
              [-66.13208008, 17.94430542],
              [-66.13208008, 17.94402695],
              [-66.13236237, 17.94402695],
              [-66.13236237, 17.94375038],
              [-66.13263702, 17.94375038],
              [-66.13263702, 17.94347191],
              [-66.13291931, 17.94347191],
              [-66.13291931, 17.94319534],
              [-66.13319397, 17.94319534],
              [-66.13319397, 17.94236183],
              [-66.13346863, 17.94236183],
              [-66.13346863, 17.94208336],
              [-66.13458252, 17.94208336],
              [-66.13458252, 17.94180489],
              [-66.13486481, 17.94180489],
              [-66.13486481, 17.94152832],
              [-66.13541412, 17.94152832],
              [-66.13541412, 17.94124985],
              [-66.13625336, 17.94124985],
              [-66.13625336, 17.94097137],
              [-66.13680267, 17.94097137],
              [-66.13680267, 17.94069481],
              [-66.13708496, 17.94069481],
              [-66.13708496, 17.94041634],
              [-66.13735962, 17.94041634],
              [-66.13735962, 17.94069481],
              [-66.13819122, 17.94069481],
              [-66.13819122, 17.94097137],
              [-66.13903046, 17.94097137],
              [-66.13903046, 17.94124985],
              [-66.13957977, 17.94124985],
              [-66.13957977, 17.94152832],
              [-66.14041901, 17.94152832],
              [-66.14041901, 17.94124985],
              [-66.14097595, 17.94124985],
              [-66.14097595, 17.94097137],
              [-66.14152527, 17.94097137],
              [-66.14152527, 17.94069481],
              [-66.14180756, 17.94069481],
              [-66.14180756, 17.94041634],
              [-66.14208221, 17.94041634],
              [-66.14208221, 17.93958282],
              [-66.14263916, 17.93958282],
              [-66.14263916, 17.93902779],
              [-66.14291382, 17.93902779],
              [-66.14291382, 17.93847275],
              [-66.14319611, 17.93847275],
              [-66.14319611, 17.93819427],
              [-66.14375305, 17.93819427],
              [-66.14375305, 17.9379158],
              [-66.14402771, 17.9379158],
              [-66.14402771, 17.93736267],
              [-66.14430237, 17.93736267],
              [-66.14430237, 17.9370842],
              [-66.14458466, 17.9370842],
              [-66.14458466, 17.93652725],
              [-66.14485931, 17.93652725],
              [-66.14485931, 17.93625069],
              [-66.1451416, 17.93625069],
              [-66.1451416, 17.93569374],
              [-66.14541626, 17.93569374],
              [-66.14541626, 17.93541718],
              [-66.14597321, 17.93541718],
              [-66.14597321, 17.93514061],
              [-66.14624786, 17.93514061],
              [-66.14624786, 17.93485832],
              [-66.14653015, 17.93485832],
              [-66.14653015, 17.93458366],
              [-66.14680481, 17.93458366],
              [-66.14680481, 17.93430519],
              [-66.1470871, 17.93430519],
              [-66.1470871, 17.93402863],
              [-66.14736176, 17.93402863],
              [-66.14736176, 17.93374825],
              [-66.14875031, 17.93374825],
              [-66.14875031, 17.93347168],
              [-66.14902496, 17.93347168],
              [-66.14902496, 17.93319511],
              [-66.14930725, 17.93319511],
              [-66.14930725, 17.93291855],
              [-66.14958191, 17.93291855],
              [-66.14958191, 17.93263626],
              [-66.15041351, 17.93263626],
              [-66.15041351, 17.9323616],
              [-66.1506958, 17.9323616],
              [-66.1506958, 17.93208313],
              [-66.15125275, 17.93208313],
              [-66.15125275, 17.93180466],
              [-66.1515274, 17.93180466],
              [-66.1515274, 17.93152618],
              [-66.15180206, 17.93152618],
              [-66.15180206, 17.93124962],
              [-66.1526413, 17.93124962],
              [-66.1526413, 17.93097305],
              [-66.15291595, 17.93097305],
              [-66.15291595, 17.93069458],
              [-66.15319824, 17.93069458],
              [-66.15319824, 17.9304142],
              [-66.15374756, 17.9304142],
              [-66.15374756, 17.93013954],
              [-66.15402985, 17.93013954],
              [-66.15402985, 17.92986107],
              [-66.1543045, 17.92986107],
              [-66.1543045, 17.9295826],
              [-66.15486145, 17.9295826],
              [-66.15486145, 17.92930412],
              [-66.1554184, 17.92930412],
              [-66.1554184, 17.9295826],
              [-66.15847015, 17.9295826],
              [-66.15847015, 17.92902756],
              [-66.15875244, 17.92902756],
              [-66.15875244, 17.92847252],
              [-66.1590271, 17.92847252],
              [-66.1590271, 17.92819214],
              [-66.15930176, 17.92819214],
              [-66.15930176, 17.92791748],
              [-66.15958405, 17.92791748],
              [-66.15958405, 17.92763901],
              [-66.16014099, 17.92763901],
              [-66.16014099, 17.92819214],
              [-66.1598587, 17.92819214],
              [-66.1598587, 17.92847252],
              [-66.15958405, 17.92847252],
              [-66.15958405, 17.92874908],
              [-66.15930176, 17.92874908],
              [-66.15930176, 17.92902756],
              [-66.1590271, 17.92902756],
              [-66.1590271, 17.9295826],
              [-66.15875244, 17.9295826],
              [-66.15875244, 17.92986107],
              [-66.15847015, 17.92986107],
              [-66.15847015, 17.93013954],
              [-66.1581955, 17.93013954],
              [-66.1581955, 17.9304142],
              [-66.15847015, 17.9304142],
              [-66.15847015, 17.93152618],
              [-66.1581955, 17.93152618],
              [-66.1581955, 17.93180466],
              [-66.15791321, 17.93180466],
              [-66.15791321, 17.93208313],
              [-66.15763855, 17.93208313],
              [-66.15763855, 17.9323616],
              [-66.15736389, 17.9323616],
              [-66.15736389, 17.93263626],
              [-66.1570816, 17.93263626],
              [-66.1570816, 17.93291855],
              [-66.15680695, 17.93291855],
              [-66.15680695, 17.93347168],
              [-66.15652466, 17.93347168],
              [-66.15652466, 17.93430519],
              [-66.15625, 17.93430519],
              [-66.15625, 17.93485832],
              [-66.15597534, 17.93485832],
              [-66.15597534, 17.93625069],
              [-66.15625, 17.93625069],
              [-66.15625, 17.93680573],
              [-66.15652466, 17.93680573],
              [-66.15652466, 17.9370842],
              [-66.1570816, 17.9370842],
              [-66.1570816, 17.93736267],
              [-66.15875244, 17.93736267],
              [-66.15875244, 17.9370842],
              [-66.15930176, 17.9370842],
              [-66.15930176, 17.93680573],
              [-66.15958405, 17.93680573],
              [-66.15958405, 17.93652725],
              [-66.1598587, 17.93652725],
              [-66.1598587, 17.93597221],
              [-66.16014099, 17.93597221],
              [-66.16014099, 17.93514061],
              [-66.16041565, 17.93514061],
              [-66.16041565, 17.93180466],
              [-66.16152954, 17.93180466],
              [-66.16152954, 17.93208313],
              [-66.1618042, 17.93208313],
              [-66.1618042, 17.93180466],
              [-66.16319275, 17.93180466],
              [-66.16319275, 17.93208313],
              [-66.16430664, 17.93208313],
              [-66.16430664, 17.9323616],
              [-66.1645813, 17.9323616],
              [-66.1645813, 17.93263626],
              [-66.16708374, 17.93263626],
              [-66.16708374, 17.93291855],
              [-66.16791534, 17.93291855],
              [-66.16791534, 17.93319511],
              [-66.16819763, 17.93319511],
              [-66.16819763, 17.93347168],
              [-66.16874695, 17.93347168],
              [-66.16874695, 17.93374825],
              [-66.16930389, 17.93374825],
              [-66.16930389, 17.93402863],
              [-66.16986084, 17.93402863],
              [-66.16986084, 17.93430519],
              [-66.1701355, 17.93430519],
              [-66.1701355, 17.93458366],
              [-66.17069244, 17.93458366],
              [-66.17069244, 17.93485832],
              [-66.17097473, 17.93485832],
              [-66.17097473, 17.93514061],
              [-66.17152405, 17.93514061],
              [-66.17152405, 17.93541718],
              [-66.17208099, 17.93541718],
              [-66.17208099, 17.93569374],
              [-66.17263794, 17.93569374],
              [-66.17263794, 17.93597221],
              [-66.17292023, 17.93597221],
              [-66.17292023, 17.93652725],
              [-66.17375183, 17.93652725],
              [-66.17375183, 17.93680573],
              [-66.17485809, 17.93680573],
              [-66.17485809, 17.93652725],
              [-66.17624664, 17.93652725],
              [-66.17624664, 17.93625069],
              [-66.17736053, 17.93625069],
              [-66.17736053, 17.93597221],
              [-66.17763519, 17.93597221],
              [-66.17763519, 17.93569374],
              [-66.17791748, 17.93569374],
              [-66.17791748, 17.93541718],
              [-66.17819214, 17.93541718],
              [-66.17819214, 17.93569374],
              [-66.17903137, 17.93569374],
              [-66.17903137, 17.93541718],
              [-66.18013763, 17.93541718],
              [-66.18013763, 17.93569374],
              [-66.18347168, 17.93569374],
              [-66.18347168, 17.93541718],
              [-66.18374634, 17.93541718],
              [-66.18374634, 17.93514061],
              [-66.18458557, 17.93514061],
              [-66.18458557, 17.93541718],
              [-66.18486023, 17.93541718],
              [-66.18486023, 17.93514061],
              [-66.18514252, 17.93514061],
              [-66.18514252, 17.93541718],
              [-66.18541718, 17.93541718],
              [-66.18541718, 17.93569374],
              [-66.18624878, 17.93569374],
              [-66.18624878, 17.93597221],
              [-66.18680573, 17.93597221],
              [-66.18680573, 17.93625069],
              [-66.18736267, 17.93625069],
              [-66.18736267, 17.93652725],
              [-66.18791962, 17.93652725],
              [-66.18791962, 17.93680573],
              [-66.18846893, 17.93680573],
              [-66.18846893, 17.9370842],
              [-66.18930817, 17.9370842],
              [-66.18930817, 17.93736267],
              [-66.19013977, 17.93736267],
              [-66.19013977, 17.93763924],
              [-66.19069672, 17.93763924],
              [-66.19069672, 17.9379158],
              [-66.19208527, 17.9379158],
              [-66.19208527, 17.93763924],
              [-66.19264221, 17.93763924],
              [-66.19264221, 17.93736267],
              [-66.19319153, 17.93736267],
              [-66.19319153, 17.9370842],
              [-66.19374847, 17.9370842],
              [-66.19374847, 17.93680573],
              [-66.19486237, 17.93680573],
              [-66.19486237, 17.93652725],
              [-66.19513702, 17.93652725],
              [-66.19513702, 17.93625069],
              [-66.19541931, 17.93625069],
              [-66.19541931, 17.93597221],
              [-66.19596863, 17.93597221],
              [-66.19596863, 17.93569374],
              [-66.19652557, 17.93569374],
              [-66.19652557, 17.93514061],
              [-66.19680786, 17.93514061],
              [-66.19680786, 17.93291855],
              [-66.19708252, 17.93291855],
              [-66.19708252, 17.93263626],
              [-66.19736481, 17.93263626],
              [-66.19736481, 17.93208313],
              [-66.19763947, 17.93208313],
              [-66.19763947, 17.93180466],
              [-66.19791412, 17.93180466],
              [-66.19791412, 17.93152618],
              [-66.19819641, 17.93152618],
              [-66.19819641, 17.93069458],
              [-66.19847107, 17.93069458],
              [-66.19847107, 17.9304142],
              [-66.19875336, 17.9304142],
              [-66.19875336, 17.93013954],
              [-66.19902802, 17.93013954],
              [-66.19902802, 17.92986107],
              [-66.19930267, 17.92986107],
              [-66.19930267, 17.9295826],
              [-66.19985962, 17.9295826],
              [-66.19985962, 17.92930412],
              [-66.20124817, 17.92930412],
              [-66.20124817, 17.92902756],
              [-66.20153046, 17.92902756],
              [-66.20153046, 17.92819214],
              [-66.20180511, 17.92819214],
              [-66.20180511, 17.92791748],
              [-66.20402527, 17.92791748],
              [-66.20402527, 17.92763901],
              [-66.20430756, 17.92763901],
              [-66.20430756, 17.9268055],
              [-66.20458221, 17.9268055],
              [-66.20458221, 17.92652702],
              [-66.20597076, 17.92652702],
              [-66.20597076, 17.9268055],
              [-66.20625305, 17.9268055],
              [-66.20625305, 17.92652702],
              [-66.20652771, 17.92652702],
              [-66.20652771, 17.92625046],
              [-66.20680237, 17.92625046],
              [-66.20680237, 17.92597389],
              [-66.20708466, 17.92597389],
              [-66.20708466, 17.92541695],
              [-66.20735931, 17.92541695],
              [-66.20735931, 17.92513847],
              [-66.20819092, 17.92513847],
              [-66.20819092, 17.92486191],
              [-66.20874786, 17.92486191],
              [-66.20874786, 17.92458344],
              [-66.2104187, 17.92458344],
              [-66.2104187, 17.92319489],
              [-66.21069336, 17.92319489],
              [-66.21069336, 17.92291641],
              [-66.21152496, 17.92291641],
              [-66.21152496, 17.92263985],
              [-66.21208191, 17.92263985],
              [-66.21208191, 17.92236137],
              [-66.2123642, 17.92236137],
              [-66.2123642, 17.9220829],
              [-66.21263885, 17.9220829],
              [-66.21263885, 17.92180634],
              [-66.21291351, 17.92180634],
              [-66.21291351, 17.92152977],
              [-66.2131958, 17.92152977],
              [-66.2131958, 17.92124748],
              [-66.21347046, 17.92124748],
              [-66.21347046, 17.92097282],
              [-66.21541595, 17.92097282],
              [-66.21541595, 17.92124748],
              [-66.21652985, 17.92124748],
              [-66.21652985, 17.92152977],
              [-66.21736145, 17.92152977],
              [-66.21736145, 17.92180634],
              [-66.21763611, 17.92180634],
              [-66.21763611, 17.9220829],
              [-66.21736145, 17.9220829],
              [-66.21736145, 17.92236137],
              [-66.21708679, 17.92236137],
              [-66.21708679, 17.92263985],
              [-66.2168045, 17.92263985],
              [-66.2168045, 17.92319489],
              [-66.21652985, 17.92319489],
              [-66.21652985, 17.92347145],
              [-66.21624756, 17.92347145],
              [-66.21624756, 17.92319489],
              [-66.21569824, 17.92319489],
              [-66.21569824, 17.92375183],
              [-66.21541595, 17.92375183],
              [-66.21541595, 17.92430496],
              [-66.2151413, 17.92430496],
              [-66.2151413, 17.92458344],
              [-66.21458435, 17.92458344],
              [-66.21458435, 17.9240284],
              [-66.21430206, 17.9240284],
              [-66.21430206, 17.92375183],
              [-66.21375275, 17.92375183],
              [-66.21375275, 17.92458344],
              [-66.21347046, 17.92458344],
              [-66.21347046, 17.92486191],
              [-66.2131958, 17.92486191],
              [-66.2131958, 17.92513847],
              [-66.21263885, 17.92513847],
              [-66.21263885, 17.92541695],
              [-66.2123642, 17.92541695],
              [-66.2123642, 17.92625046],
              [-66.21263885, 17.92625046],
              [-66.21263885, 17.92708206],
              [-66.2123642, 17.92708206],
              [-66.2123642, 17.9268055],
              [-66.21180725, 17.9268055],
              [-66.21180725, 17.92652702],
              [-66.21125031, 17.92652702],
              [-66.21125031, 17.92708206],
              [-66.21097565, 17.92708206],
              [-66.21097565, 17.92819214],
              [-66.21069336, 17.92819214],
              [-66.21069336, 17.92874908],
              [-66.2104187, 17.92874908],
              [-66.2104187, 17.9295826],
              [-66.2095871, 17.9295826],
              [-66.2095871, 17.92986107],
              [-66.20930481, 17.92986107],
              [-66.20930481, 17.93013954],
              [-66.2095871, 17.93013954],
              [-66.2095871, 17.93069458],
              [-66.20986176, 17.93069458],
              [-66.20986176, 17.93124962],
              [-66.2095871, 17.93124962],
              [-66.2095871, 17.93152618],
              [-66.20847321, 17.93152618],
              [-66.20847321, 17.93180466],
              [-66.20791626, 17.93180466],
              [-66.20791626, 17.93208313],
              [-66.2076416, 17.93208313],
              [-66.2076416, 17.9323616],
              [-66.20735931, 17.9323616],
              [-66.20735931, 17.93263626],
              [-66.20847321, 17.93263626],
              [-66.20847321, 17.9323616],
              [-66.20930481, 17.9323616],
              [-66.20930481, 17.93180466],
              [-66.21013641, 17.93180466],
              [-66.21013641, 17.9323616],
              [-66.2104187, 17.9323616],
              [-66.2104187, 17.93319511],
              [-66.20986176, 17.93319511],
              [-66.20986176, 17.93347168],
              [-66.2095871, 17.93347168],
              [-66.2095871, 17.93374825],
              [-66.20874786, 17.93374825],
              [-66.20874786, 17.93402863],
              [-66.20791626, 17.93402863],
              [-66.20791626, 17.93430519],
              [-66.2076416, 17.93430519],
              [-66.2076416, 17.93514061],
              [-66.20791626, 17.93514061],
              [-66.20791626, 17.93541718],
              [-66.20819092, 17.93541718],
              [-66.20819092, 17.93597221],
              [-66.20847321, 17.93597221],
              [-66.20847321, 17.93652725],
              [-66.20874786, 17.93652725],
              [-66.20874786, 17.9370842],
              [-66.20903015, 17.9370842],
              [-66.20903015, 17.93736267],
              [-66.2095871, 17.93736267],
              [-66.2095871, 17.9379158],
              [-66.21013641, 17.9379158],
              [-66.21013641, 17.93819427],
              [-66.2104187, 17.93819427],
              [-66.2104187, 17.93847275],
              [-66.21069336, 17.93847275],
              [-66.21069336, 17.93902779],
              [-66.21097565, 17.93902779],
              [-66.21097565, 17.93930626],
              [-66.21125031, 17.93930626],
              [-66.21125031, 17.93958282],
              [-66.21152496, 17.93958282],
              [-66.21152496, 17.9398613],
              [-66.21180725, 17.9398613],
              [-66.21180725, 17.94013977],
              [-66.21208191, 17.94013977],
              [-66.21208191, 17.94041634],
              [-66.21263885, 17.94041634],
              [-66.21263885, 17.94069481],
              [-66.21291351, 17.94069481],
              [-66.21291351, 17.94097137],
              [-66.2131958, 17.94097137],
              [-66.2131958, 17.94375038],
              [-66.21347046, 17.94375038],
              [-66.21347046, 17.94486046],
              [-66.21291351, 17.94486046],
              [-66.21291351, 17.94513893],
              [-66.21263885, 17.94513893],
              [-66.21263885, 17.94486046],
              [-66.21097565, 17.94486046],
              [-66.21097565, 17.94458389],
              [-66.21013641, 17.94458389],
              [-66.21013641, 17.94402695],
              [-66.20847321, 17.94402695],
              [-66.20847321, 17.94458389],
              [-66.20680237, 17.94458389],
              [-66.20680237, 17.94486046],
              [-66.20597076, 17.94486046],
              [-66.20597076, 17.94513893],
              [-66.20291901, 17.94513893],
              [-66.20291901, 17.9454155],
              [-66.20263672, 17.9454155],
              [-66.20263672, 17.94569397],
              [-66.20236206, 17.94569397],
              [-66.20236206, 17.9454155],
              [-66.20207977, 17.9454155],
              [-66.20207977, 17.94513893],
              [-66.20180511, 17.94513893],
              [-66.20180511, 17.94375038],
              [-66.20097351, 17.94375038],
              [-66.20097351, 17.94430542],
              [-66.20014191, 17.94430542],
              [-66.20014191, 17.94402695],
              [-66.19958496, 17.94402695],
              [-66.19958496, 17.94375038],
              [-66.19902802, 17.94375038],
              [-66.19902802, 17.94347191],
              [-66.19875336, 17.94347191],
              [-66.19875336, 17.94319534],
              [-66.19819641, 17.94319534],
              [-66.19819641, 17.94291687],
              [-66.19708252, 17.94291687],
              [-66.19708252, 17.9426384],
              [-66.19569397, 17.9426384],
              [-66.19569397, 17.94236183],
              [-66.19486237, 17.94236183],
              [-66.19486237, 17.94208336],
              [-66.19430542, 17.94208336],
              [-66.19430542, 17.94180489],
              [-66.19347382, 17.94180489],
              [-66.19347382, 17.94152832],
              [-66.19235992, 17.94152832],
              [-66.19235992, 17.94124985],
              [-66.19152832, 17.94124985],
              [-66.19152832, 17.94097137],
              [-66.18958282, 17.94097137],
              [-66.18958282, 17.94069481],
              [-66.18875122, 17.94069481],
              [-66.18875122, 17.94041634],
              [-66.18846893, 17.94041634],
              [-66.18846893, 17.93958282],
              [-66.18819427, 17.93958282],
              [-66.18819427, 17.93930626],
              [-66.18763733, 17.93930626],
              [-66.18763733, 17.93902779],
              [-66.18541718, 17.93902779],
              [-66.18541718, 17.93874931],
              [-66.18514252, 17.93874931],
              [-66.18514252, 17.93847275],
              [-66.18458557, 17.93847275],
              [-66.18458557, 17.93819427],
              [-66.18430328, 17.93819427],
              [-66.18430328, 17.9379158],
              [-66.18402863, 17.9379158],
              [-66.18402863, 17.93763924],
              [-66.18347168, 17.93763924],
              [-66.18347168, 17.93819427],
              [-66.18402863, 17.93819427],
              [-66.18402863, 17.93902779],
              [-66.18374634, 17.93902779],
              [-66.18374634, 17.93958282],
              [-66.18319702, 17.93958282],
              [-66.18319702, 17.9398613],
              [-66.18235779, 17.9398613],
              [-66.18235779, 17.94013977],
              [-66.18152618, 17.94013977],
              [-66.18152618, 17.94180489],
              [-66.18208313, 17.94180489],
              [-66.18208313, 17.94208336],
              [-66.18235779, 17.94208336],
              [-66.18235779, 17.94319534],
              [-66.18208313, 17.94319534],
              [-66.18208313, 17.94375038],
              [-66.18180847, 17.94375038],
              [-66.18180847, 17.94402695],
              [-66.18152618, 17.94402695],
              [-66.18152618, 17.94430542],
              [-66.18125153, 17.94430542],
              [-66.18125153, 17.94458389],
              [-66.18096924, 17.94458389],
              [-66.18096924, 17.94486046],
              [-66.18069458, 17.94486046],
              [-66.18069458, 17.94513893],
              [-66.17930603, 17.94513893],
              [-66.17930603, 17.9454155],
              [-66.17874908, 17.9454155],
              [-66.17874908, 17.94513893],
              [-66.17736053, 17.94513893],
              [-66.17736053, 17.9454155],
              [-66.17680359, 17.9454155],
              [-66.17680359, 17.94569397],
              [-66.17514038, 17.94569397],
              [-66.17514038, 17.94597244],
              [-66.17485809, 17.94597244],
              [-66.17485809, 17.94708252],
              [-66.17514038, 17.94708252],
              [-66.17514038, 17.9481945],
              [-66.17541504, 17.9481945],
              [-66.17541504, 17.94847298],
              [-66.17569733, 17.94847298],
              [-66.17569733, 17.94874763],
              [-66.17597198, 17.94874763],
              [-66.17597198, 17.94930649],
              [-66.17624664, 17.94930649],
              [-66.17624664, 17.94958305],
              [-66.17652893, 17.94958305],
              [-66.17652893, 17.94986153],
              [-66.17680359, 17.94986153],
              [-66.17680359, 17.95013809],
              [-66.17736053, 17.95013809],
              [-66.17736053, 17.95041656],
              [-66.18013763, 17.95041656],
              [-66.18013763, 17.95013809],
              [-66.18041992, 17.95013809],
              [-66.18041992, 17.94986153],
              [-66.18125153, 17.94986153],
              [-66.18125153, 17.95013809],
              [-66.18152618, 17.95013809],
              [-66.18152618, 17.95041656],
              [-66.18180847, 17.95041656],
              [-66.18180847, 17.95069504],
              [-66.18235779, 17.95069504],
              [-66.18235779, 17.9509716],
              [-66.18264008, 17.9509716],
              [-66.18264008, 17.95125008],
              [-66.18374634, 17.95125008],
              [-66.18374634, 17.9509716],
              [-66.18402863, 17.9509716],
              [-66.18402863, 17.95069504],
              [-66.18486023, 17.95069504],
              [-66.18486023, 17.9509716],
              [-66.18514252, 17.9509716],
              [-66.18514252, 17.95125008],
              [-66.18541718, 17.95125008],
              [-66.18541718, 17.95152855],
              [-66.18569183, 17.95152855],
              [-66.18569183, 17.95180511],
              [-66.18624878, 17.95180511],
              [-66.18624878, 17.95208359],
              [-66.18680573, 17.95208359],
              [-66.18680573, 17.95236206],
              [-66.18708038, 17.95236206],
              [-66.18708038, 17.95263863],
              [-66.18819427, 17.95263863],
              [-66.18819427, 17.9529171],
              [-66.18930817, 17.9529171],
              [-66.18930817, 17.95319366],
              [-66.18985748, 17.95319366],
              [-66.18985748, 17.95347214],
              [-66.19041443, 17.95347214],
              [-66.19041443, 17.95375061],
              [-66.19069672, 17.95375061],
              [-66.19069672, 17.95402718],
              [-66.19125366, 17.95402718],
              [-66.19125366, 17.95430565],
              [-66.19152832, 17.95430565],
              [-66.19152832, 17.95458221],
              [-66.19180298, 17.95458221],
              [-66.19180298, 17.95486069],
              [-66.19208527, 17.95486069],
              [-66.19208527, 17.95513916],
              [-66.19264221, 17.95513916],
              [-66.19264221, 17.95541573],
              [-66.19291687, 17.95541573],
              [-66.19291687, 17.9556942],
              [-66.19347382, 17.9556942],
              [-66.19347382, 17.95597267],
              [-66.19374847, 17.95597267],
              [-66.19374847, 17.95624924],
              [-66.19430542, 17.95624924],
              [-66.19430542, 17.95652771],
              [-66.19458008, 17.95652771],
              [-66.19458008, 17.95680428],
              [-66.19513702, 17.95680428],
              [-66.19513702, 17.95708275],
              [-66.19541931, 17.95708275],
              [-66.19541931, 17.95736122],
              [-66.19596863, 17.95736122],
              [-66.19596863, 17.95763969],
              [-66.19652557, 17.95763969],
              [-66.19652557, 17.95791435],
              [-66.19680786, 17.95791435],
              [-66.19680786, 17.95819473],
              [-66.19708252, 17.95819473],
              [-66.19708252, 17.9584713],
              [-66.19736481, 17.9584713],
              [-66.19736481, 17.95874977],
              [-66.19763947, 17.95874977],
              [-66.19763947, 17.95930481],
              [-66.19791412, 17.95930481],
              [-66.19791412, 17.95958328],
              [-66.19819641, 17.95958328],
              [-66.19819641, 17.95986176],
              [-66.19875336, 17.95986176],
              [-66.19875336, 17.96013641],
              [-66.19902802, 17.96013641],
              [-66.19902802, 17.9604187],
              [-66.19985962, 17.9604187],
              [-66.19985962, 17.96069527],
              [-66.20014191, 17.96069527],
              [-66.20014191, 17.96097183],
              [-66.20153046, 17.96097183],
              [-66.20153046, 17.96125031],
              [-66.20207977, 17.96125031],
              [-66.20207977, 17.96152878],
              [-66.20375061, 17.96152878],
              [-66.20375061, 17.96180534],
              [-66.20430756, 17.96180534],
              [-66.20430756, 17.96208382],
              [-66.20569611, 17.96208382],
              [-66.20569611, 17.96235847],
              [-66.20597076, 17.96235847],
              [-66.20597076, 17.96264076],
              [-66.2061615, 17.96264076],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 30,
        name: 'Guayama',
        hasc_1: 'PR.GM',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.21763611, 17.92097282, -66.05316925, 18.10751534],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.32',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.14392853, 18.27958107],
              [-66.13793945, 18.29818153],
              [-66.14118195, 18.30103874],
              [-66.14165497, 18.30913925],
              [-66.13287354, 18.31296539],
              [-66.13240814, 18.31296921],
              [-66.13240814, 18.31344223],
              [-66.13982391, 18.31819534],
              [-66.14121246, 18.32057762],
              [-66.14167786, 18.32439041],
              [-66.14168549, 18.32915688],
              [-66.13382721, 18.33488846],
              [-66.13336182, 18.3353653],
              [-66.13476563, 18.34108353],
              [-66.13522339, 18.34203529],
              [-66.13569641, 18.34679985],
              [-66.1361618, 18.35013771],
              [-66.13986969, 18.35585022],
              [-66.14173126, 18.35775185],
              [-66.14173126, 18.36156464],
              [-66.1412735, 18.36251831],
              [-66.13526917, 18.37253571],
              [-66.1375885, 18.37729454],
              [-66.13759613, 18.3792038],
              [-66.13666534, 18.3806324],
              [-66.13296509, 18.3815918],
              [-66.12230682, 18.37636757],
              [-66.11860657, 18.37875175],
              [-66.11860657, 18.37922668],
              [-66.12650299, 18.39589691],
              [-66.1251297, 18.40590286],
              [-66.12466431, 18.40685463],
              [-66.12420654, 18.40971375],
              [-66.12189484, 18.41305161],
              [-66.12142944, 18.41400337],
              [-66.12097168, 18.41448212],
              [-66.12282562, 18.41638756],
              [-66.12514496, 18.41876411],
              [-66.12885284, 18.41971397],
              [-66.13209534, 18.42066193],
              [-66.13163757, 18.42447472],
              [-66.12284088, 18.43306351],
              [-66.11821747, 18.43782806],
              [-66.11544037, 18.44021416],
              [-66.11405182, 18.44069099],
              [-66.11208344, 18.44011307],
              [-66.11208344, 18.4398613],
              [-66.11180878, 18.4398613],
              [-66.11180878, 18.43874931],
              [-66.11152649, 18.43874931],
              [-66.11152649, 18.43847275],
              [-66.11096954, 18.43847275],
              [-66.11096954, 18.4379158],
              [-66.11069489, 18.4379158],
              [-66.11069489, 18.43763924],
              [-66.11013794, 18.43763924],
              [-66.11013794, 18.43736076],
              [-66.10958099, 18.43736076],
              [-66.10958099, 18.43652725],
              [-66.10930634, 18.43652725],
              [-66.10930634, 18.43569374],
              [-66.10958099, 18.43569374],
              [-66.10958099, 18.43180466],
              [-66.11013794, 18.43180466],
              [-66.11013794, 18.42930603],
              [-66.10958099, 18.42930603],
              [-66.10958099, 18.42874718],
              [-66.108078, 18.42874718],
              [-66.10755157, 18.42783928],
              [-66.10662079, 18.42688751],
              [-66.09920502, 18.41927528],
              [-66.09827423, 18.41736794],
              [-66.09501648, 18.4035511],
              [-66.09548187, 18.40212631],
              [-66.09687042, 18.40021706],
              [-66.1065979, 18.40211105],
              [-66.10705566, 18.40163803],
              [-66.10797882, 18.39735031],
              [-66.10797119, 18.38877106],
              [-66.10610962, 18.3825798],
              [-66.10240173, 18.3797245],
              [-66.09499359, 18.3778286],
              [-66.09406281, 18.37592125],
              [-66.09267426, 18.37210846],
              [-66.08849335, 18.35734367],
              [-66.0898819, 18.35638809],
              [-66.09450531, 18.35495186],
              [-66.09635925, 18.35352516],
              [-66.09820557, 18.35018539],
              [-66.09958649, 18.34255791],
              [-66.09634399, 18.33779526],
              [-66.08708191, 18.33303833],
              [-66.08661652, 18.33208466],
              [-66.08846283, 18.32541275],
              [-66.08846283, 18.32350349],
              [-66.0884552, 18.3196907],
              [-66.08798981, 18.31826019],
              [-66.08243561, 18.30778122],
              [-66.08335114, 18.3049221],
              [-66.08520508, 18.30015564],
              [-66.08473969, 18.2996769],
              [-66.08057404, 18.29729652],
              [-66.0916748, 18.29299545],
              [-66.09398651, 18.29204178],
              [-66.09952545, 18.28154755],
              [-66.10553741, 18.27867889],
              [-66.10830688, 18.27772141],
              [-66.11201477, 18.27962494],
              [-66.12080383, 18.28152084],
              [-66.14392853, 18.27958107],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 32,
        name: 'Guaynabo',
        hasc_1: 'PR.GB',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.14392853, 18.27772141, -66.08057404, 18.44069099],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.33',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-65.95106506, 18.22914314],
              [-65.96170044, 18.2296257],
              [-65.97141266, 18.22915459],
              [-65.98158264, 18.22104836],
              [-65.9825058, 18.22057152],
              [-65.9848175, 18.22009277],
              [-65.98620605, 18.22057343],
              [-65.98851776, 18.22009659],
              [-65.98990631, 18.21961975],
              [-65.99036407, 18.21818733],
              [-65.99175262, 18.21199036],
              [-65.99267578, 18.21055984],
              [-65.99776459, 18.20722008],
              [-65.9996109, 18.2177124],
              [-65.9996109, 18.21818924],
              [-66.00053406, 18.21914101],
              [-66.00192261, 18.21914291],
              [-66.01070404, 18.22104836],
              [-66.01255035, 18.2229557],
              [-66.0139389, 18.22533798],
              [-66.0139389, 18.2267704],
              [-66.01348114, 18.22772598],
              [-66.01163483, 18.22963142],
              [-66.00839996, 18.23344994],
              [-66.00839233, 18.23773766],
              [-66.01255798, 18.24250603],
              [-66.01441193, 18.24441338],
              [-66.01486969, 18.24536705],
              [-66.0144043, 18.2472744],
              [-66.01071167, 18.25013733],
              [-66.01071167, 18.25061226],
              [-66.01394653, 18.26109886],
              [-66.01441193, 18.26395988],
              [-66.01441193, 18.26443672],
              [-66.00701141, 18.26682091],
              [-66.00886536, 18.27206612],
              [-66.00793457, 18.27730942],
              [-66.00793457, 18.27778625],
              [-66.01718903, 18.28112221],
              [-66.02597809, 18.2863636],
              [-66.02828979, 18.28827095],
              [-66.02922058, 18.28922272],
              [-66.03014374, 18.29065323],
              [-66.03060913, 18.29970741],
              [-66.03060913, 18.30113792],
              [-66.02968597, 18.30304527],
              [-66.02552032, 18.30352211],
              [-66.02367401, 18.30352211],
              [-66.02182007, 18.30495453],
              [-66.01673126, 18.30876923],
              [-66.01580811, 18.30829239],
              [-66.00839996, 18.30448151],
              [-66.00747681, 18.30448151],
              [-66.00655365, 18.30448151],
              [-65.99775696, 18.30591202],
              [-65.99497986, 18.30686378],
              [-65.9861908, 18.3111515],
              [-65.97370148, 18.31019974],
              [-65.96814728, 18.31019783],
              [-65.96537018, 18.30399704],
              [-65.96352386, 18.30066109],
              [-65.95288849, 18.2944603],
              [-65.94224548, 18.29350281],
              [-65.93947601, 18.28777885],
              [-65.93300629, 18.28014755],
              [-65.92838287, 18.27680588],
              [-65.92144775, 18.27155685],
              [-65.91867065, 18.27012634],
              [-65.92422485, 18.26917458],
              [-65.92745972, 18.26822281],
              [-65.93254852, 18.2639389],
              [-65.93625641, 18.25917435],
              [-65.94643402, 18.25250626],
              [-65.94921112, 18.24392319],
              [-65.94782257, 18.24154282],
              [-65.94274139, 18.23867416],
              [-65.93950653, 18.23390579],
              [-65.94135284, 18.23199844],
              [-65.95106506, 18.22914314],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 33,
        name: 'Gurabo',
        hasc_1: 'PR.GR',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.03060913, 18.20722008, -65.91867065, 18.3111515],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.34',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.82611847, 18.32318115],
              [-66.82482147, 18.33320236],
              [-66.82485199, 18.33749199],
              [-66.82397461, 18.34321976],
              [-66.81896973, 18.35327148],
              [-66.81581879, 18.36425972],
              [-66.81045532, 18.38718033],
              [-66.81652069, 18.39236832],
              [-66.81793213, 18.39569283],
              [-66.82125854, 18.40566635],
              [-66.82363129, 18.41231728],
              [-66.82569885, 18.43802452],
              [-66.82531738, 18.44755554],
              [-66.82632446, 18.45659828],
              [-66.82633209, 18.45755196],
              [-66.83480835, 18.47366905],
              [-66.83764648, 18.48031044],
              [-66.83860016, 18.48363686],
              [-66.83860779, 18.48411369],
              [-66.83680725, 18.48742294],
              [-66.83625031, 18.48736382],
              [-66.83625031, 18.48708344],
              [-66.8354187, 18.48708344],
              [-66.8354187, 18.48736382],
              [-66.83486176, 18.48736382],
              [-66.83486176, 18.48763847],
              [-66.83291626, 18.48763847],
              [-66.83291626, 18.48736382],
              [-66.83235931, 18.48736382],
              [-66.83235931, 18.48763847],
              [-66.83180237, 18.48763847],
              [-66.83180237, 18.48736382],
              [-66.83069611, 18.48736382],
              [-66.83069611, 18.48708344],
              [-66.82847595, 18.48708344],
              [-66.82847595, 18.48736382],
              [-66.82736206, 18.48736382],
              [-66.82736206, 18.48763847],
              [-66.82680511, 18.48763847],
              [-66.82680511, 18.48791695],
              [-66.82653046, 18.48791695],
              [-66.82653046, 18.48819351],
              [-66.82624817, 18.48819351],
              [-66.82624817, 18.48847389],
              [-66.82597351, 18.48847389],
              [-66.82597351, 18.48875046],
              [-66.82569122, 18.48875046],
              [-66.82569122, 18.4893055],
              [-66.82514191, 18.4893055],
              [-66.82514191, 18.48986053],
              [-66.82485962, 18.48986053],
              [-66.82485962, 18.49013901],
              [-66.82458496, 18.49013901],
              [-66.82458496, 18.49041748],
              [-66.82430267, 18.49041748],
              [-66.82430267, 18.49069595],
              [-66.82291412, 18.49069595],
              [-66.82291412, 18.49041748],
              [-66.82096863, 18.49041748],
              [-66.82096863, 18.49069595],
              [-66.81764221, 18.49069595],
              [-66.81764221, 18.49041748],
              [-66.81652832, 18.49041748],
              [-66.81652832, 18.49069595],
              [-66.81513977, 18.49069595],
              [-66.81513977, 18.49097252],
              [-66.81319427, 18.49097252],
              [-66.81319427, 18.49069595],
              [-66.81291962, 18.49069595],
              [-66.81291962, 18.49013901],
              [-66.81180573, 18.49013901],
              [-66.81180573, 18.49041748],
              [-66.80986023, 18.49041748],
              [-66.80986023, 18.49069595],
              [-66.80902863, 18.49069595],
              [-66.80902863, 18.49097252],
              [-66.80791473, 18.49097252],
              [-66.80791473, 18.49124908],
              [-66.80458069, 18.49124908],
              [-66.80458069, 18.49152756],
              [-66.80347443, 18.49152756],
              [-66.80347443, 18.49180412],
              [-66.80236053, 18.49180412],
              [-66.80236053, 18.4920826],
              [-66.80180359, 18.4920826],
              [-66.80180359, 18.49236107],
              [-66.79902649, 18.49236107],
              [-66.79902649, 18.49263954],
              [-66.79819489, 18.49263954],
              [-66.79819489, 18.49291611],
              [-66.79763794, 18.49291611],
              [-66.79763794, 18.49263954],
              [-66.79708099, 18.49263954],
              [-66.79708099, 18.49291611],
              [-66.79624939, 18.49291611],
              [-66.79624939, 18.49319458],
              [-66.79597473, 18.49319458],
              [-66.79597473, 18.49347305],
              [-66.79541779, 18.49347305],
              [-66.79541779, 18.49319458],
              [-66.7951355, 18.49319458],
              [-66.7951355, 18.49291611],
              [-66.79486084, 18.49291611],
              [-66.79486084, 18.49263954],
              [-66.79430389, 18.49263954],
              [-66.79430389, 18.49236107],
              [-66.79125214, 18.49236107],
              [-66.79125214, 18.49263954],
              [-66.79096985, 18.49263954],
              [-66.79096985, 18.49236107],
              [-66.79013824, 18.49236107],
              [-66.79013824, 18.4920826],
              [-66.78986359, 18.4920826],
              [-66.78986359, 18.49236107],
              [-66.78930664, 18.49236107],
              [-66.78930664, 18.49263954],
              [-66.78902435, 18.49263954],
              [-66.78902435, 18.49291611],
              [-66.78819275, 18.49291611],
              [-66.78819275, 18.49263954],
              [-66.78791809, 18.49263954],
              [-66.78791809, 18.49236107],
              [-66.78569794, 18.49236107],
              [-66.78569794, 18.4920826],
              [-66.78458405, 18.4920826],
              [-66.78458405, 18.49180412],
              [-66.7840271, 18.49180412],
              [-66.7840271, 18.49152756],
              [-66.78347015, 18.49152756],
              [-66.78347015, 18.49180412],
              [-66.78180695, 18.49180412],
              [-66.78180695, 18.49152756],
              [-66.78125, 18.49152756],
              [-66.78125, 18.49124908],
              [-66.78069305, 18.49124908],
              [-66.78069305, 18.49097252],
              [-66.77986145, 18.49097252],
              [-66.77986145, 18.49069595],
              [-66.77958679, 18.49069595],
              [-66.77958679, 18.49041748],
              [-66.77902985, 18.49041748],
              [-66.77902985, 18.49013901],
              [-66.7784729, 18.49013901],
              [-66.7784729, 18.48986053],
              [-66.77819824, 18.48986053],
              [-66.77819824, 18.48958588],
              [-66.77708435, 18.48958588],
              [-66.77708435, 18.4893055],
              [-66.7765274, 18.4893055],
              [-66.7765274, 18.48902702],
              [-66.7756958, 18.48902702],
              [-66.7756958, 18.48875046],
              [-66.77541351, 18.48875046],
              [-66.77541351, 18.48847389],
              [-66.77430725, 18.48847389],
              [-66.77430725, 18.48819351],
              [-66.77347565, 18.48819351],
              [-66.77347565, 18.48791695],
              [-66.7729187, 18.48791695],
              [-66.7729187, 18.48763847],
              [-66.77263641, 18.48763847],
              [-66.77263641, 18.48736382],
              [-66.77236176, 18.48736382],
              [-66.77236176, 18.48708344],
              [-66.77153015, 18.48708344],
              [-66.77153015, 18.48680496],
              [-66.77069092, 18.48680496],
              [-66.77069092, 18.4865284],
              [-66.76985931, 18.4865284],
              [-66.76985931, 18.48625183],
              [-66.76902771, 18.48625183],
              [-66.76902771, 18.48596954],
              [-66.76875305, 18.48596954],
              [-66.76875305, 18.48569489],
              [-66.76847076, 18.48569489],
              [-66.76847076, 18.48513794],
              [-66.76819611, 18.48513794],
              [-66.76819611, 18.48485947],
              [-66.76791382, 18.48485947],
              [-66.76791382, 18.4845829],
              [-66.7673645, 18.4845829],
              [-66.7673645, 18.48430634],
              [-66.76708221, 18.48430634],
              [-66.76708221, 18.48402977],
              [-66.76625061, 18.48402977],
              [-66.76625061, 18.48374939],
              [-66.76541901, 18.48374939],
              [-66.76541901, 18.48362923],
              [-66.76581573, 18.48236465],
              [-66.76750946, 18.46187019],
              [-66.76747131, 18.4566288],
              [-66.76831818, 18.44661903],
              [-66.76830292, 18.44423485],
              [-66.77056122, 18.43707466],
              [-66.77055359, 18.43612099],
              [-66.77007294, 18.43374443],
              [-66.76818085, 18.42899323],
              [-66.76770782, 18.42757034],
              [-66.76767731, 18.42423439],
              [-66.76760864, 18.41566086],
              [-66.76986694, 18.4080162],
              [-66.76984406, 18.40515709],
              [-66.76790619, 18.39469147],
              [-66.76696014, 18.39183617],
              [-66.76913452, 18.37371635],
              [-66.7701416, 18.32509422],
              [-66.77010345, 18.3208065],
              [-66.78538513, 18.32163239],
              [-66.79418182, 18.32203293],
              [-66.82611847, 18.32318115],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 34,
        name: 'Hatillo',
        hasc_1: 'PR.HA',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.83860779, 18.3208065, -66.76541901, 18.49347305],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.35',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-67.10043335, 18.10619164],
              [-67.12407684, 18.11401749],
              [-67.12640381, 18.11541748],
              [-67.12736511, 18.11922646],
              [-67.12830353, 18.12016678],
              [-67.12969208, 18.12062645],
              [-67.13061523, 18.12061691],
              [-67.13339996, 18.12153435],
              [-67.13480377, 18.12342644],
              [-67.13527679, 18.12437439],
              [-67.13624573, 18.12817955],
              [-67.13861084, 18.13291931],
              [-67.1414032, 18.13479424],
              [-67.14331055, 18.14002037],
              [-67.15166473, 18.14325333],
              [-67.1545105, 18.14989662],
              [-67.15963745, 18.15317154],
              [-67.15782166, 18.15605545],
              [-67.14303589, 18.15576553],
              [-67.13885498, 18.15390778],
              [-67.13698578, 18.15202141],
              [-67.13286591, 18.15541077],
              [-67.13149261, 18.15638542],
              [-67.1292038, 18.15831947],
              [-67.12596893, 18.15836143],
              [-67.12365723, 18.1579113],
              [-67.12086487, 18.15603828],
              [-67.11856842, 18.15797234],
              [-67.11489105, 18.15944862],
              [-67.11119843, 18.15949249],
              [-67.10889435, 18.15999794],
              [-67.1075058, 18.15953827],
              [-67.10379028, 18.15815353],
              [-67.10147095, 18.15770149],
              [-67.09778595, 18.15870094],
              [-67.08985901, 18.15163612],
              [-67.08892822, 18.15117264],
              [-67.08614349, 18.14977455],
              [-67.08427429, 18.14836693],
              [-67.08562469, 18.1450119],
              [-67.08654022, 18.14452171],
              [-67.09391785, 18.14252472],
              [-67.09390259, 18.14157486],
              [-67.09429169, 18.13489151],
              [-67.09328461, 18.12774658],
              [-67.07931519, 18.11693764],
              [-67.07745361, 18.11601067],
              [-67.07789612, 18.11409378],
              [-67.07788086, 18.11313629],
              [-67.07878113, 18.11074066],
              [-67.07968903, 18.10882378],
              [-67.08104706, 18.10689735],
              [-67.0870285, 18.10444069],
              [-67.09072113, 18.10487366],
              [-67.10043335, 18.10619164],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 35,
        name: 'Hormigueros',
        hasc_1: 'PR.HO',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-67.15963745, 18.10444069, -67.07745361, 18.15999794],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.36',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-65.73915863, 18.17430496],
              [-65.73930359, 18.17430496],
              [-65.73930359, 18.17347145],
              [-65.73958588, 18.17347145],
              [-65.73958588, 18.17291641],
              [-65.73986053, 18.17291641],
              [-65.73986053, 18.17236137],
              [-65.74013519, 18.17236137],
              [-65.74013519, 18.17152786],
              [-65.74041748, 18.17152786],
              [-65.74041748, 18.17069435],
              [-65.74069214, 18.17069435],
              [-65.74069214, 18.16680527],
              [-65.74097443, 18.16680527],
              [-65.74097443, 18.16597366],
              [-65.74124908, 18.16597366],
              [-65.74124908, 18.16541672],
              [-65.74153137, 18.16541672],
              [-65.74153137, 18.16513824],
              [-65.74180603, 18.16513824],
              [-65.74180603, 18.16486168],
              [-65.74208069, 18.16486168],
              [-65.74208069, 18.16458321],
              [-65.74236298, 18.16458321],
              [-65.74236298, 18.16430473],
              [-65.74263763, 18.16430473],
              [-65.74263763, 18.16402817],
              [-65.74319458, 18.16402817],
              [-65.74319458, 18.1637516],
              [-65.74375153, 18.1637516],
              [-65.74375153, 18.16347313],
              [-65.74430847, 18.16347313],
              [-65.74430847, 18.16319466],
              [-65.74458313, 18.16319466],
              [-65.74458313, 18.16291618],
              [-65.74514008, 18.16291618],
              [-65.74514008, 18.16263771],
              [-65.74541473, 18.16263771],
              [-65.74541473, 18.16235924],
              [-65.74652863, 18.16235924],
              [-65.74652863, 18.16208267],
              [-65.74736023, 18.16208267],
              [-65.74736023, 18.16180611],
              [-65.74764252, 18.16180611],
              [-65.74764252, 18.16152954],
              [-65.74847412, 18.16152954],
              [-65.74847412, 18.16124725],
              [-65.74930573, 18.16124725],
              [-65.74930573, 18.16069412],
              [-65.75041962, 18.16069412],
              [-65.75041962, 18.16041946],
              [-65.75096893, 18.16041946],
              [-65.75096893, 18.16013718],
              [-65.75152588, 18.16013718],
              [-65.75152588, 18.15986061],
              [-65.75235748, 18.15986061],
              [-65.75235748, 18.15930557],
              [-65.75291443, 18.15930557],
              [-65.75291443, 18.15902519],
              [-65.75375366, 18.15902519],
              [-65.75375366, 18.15875053],
              [-65.75430298, 18.15875053],
              [-65.75430298, 18.15847206],
              [-65.75485992, 18.15847206],
              [-65.75485992, 18.15819359],
              [-65.75514221, 18.15819359],
              [-65.75514221, 18.15791512],
              [-65.75569153, 18.15791512],
              [-65.75569153, 18.15763855],
              [-65.75653076, 18.15763855],
              [-65.75653076, 18.15736198],
              [-65.75736237, 18.15736198],
              [-65.75736237, 18.15708351],
              [-65.75763702, 18.15708351],
              [-65.75763702, 18.15680313],
              [-65.75819397, 18.15680313],
              [-65.75819397, 18.15652847],
              [-65.75846863, 18.15652847],
              [-65.75846863, 18.15597153],
              [-65.75930786, 18.15597153],
              [-65.75930786, 18.15569305],
              [-65.75958252, 18.15569305],
              [-65.75958252, 18.15541649],
              [-65.76013947, 18.15541649],
              [-65.76013947, 18.15513802],
              [-65.76041412, 18.15513802],
              [-65.76041412, 18.15486145],
              [-65.76097107, 18.15486145],
              [-65.76097107, 18.15458488],
              [-65.76125336, 18.15458488],
              [-65.76125336, 18.15402794],
              [-65.76180267, 18.15402794],
              [-65.76180267, 18.1534729],
              [-65.76208496, 18.1534729],
              [-65.76208496, 18.15263939],
              [-65.76291656, 18.15263939],
              [-65.76291656, 18.15236282],
              [-65.76319122, 18.15236282],
              [-65.76319122, 18.15208244],
              [-65.76347351, 18.15208244],
              [-65.76347351, 18.1515274],
              [-65.76374817, 18.1515274],
              [-65.76374817, 18.15125275],
              [-65.76486206, 18.15125275],
              [-65.76486206, 18.15069389],
              [-65.76513672, 18.15069389],
              [-65.76513672, 18.15041733],
              [-65.76541901, 18.15041733],
              [-65.76541901, 18.15014076],
              [-65.76569366, 18.15014076],
              [-65.76569366, 18.14985847],
              [-65.76597595, 18.14985847],
              [-65.76597595, 18.14958382],
              [-65.76652527, 18.14958382],
              [-65.76652527, 18.14930534],
              [-65.76680756, 18.14930534],
              [-65.76680756, 18.14902878],
              [-65.76708221, 18.14902878],
              [-65.76708221, 18.14847183],
              [-65.7673645, 18.14847183],
              [-65.7673645, 18.14763641],
              [-65.76763916, 18.14763641],
              [-65.76763916, 18.1459713],
              [-65.7673645, 18.1459713],
              [-65.7673645, 18.14569473],
              [-65.76708221, 18.14569473],
              [-65.76708221, 18.14541626],
              [-65.76680756, 18.14541626],
              [-65.76680756, 18.14513969],
              [-65.76652527, 18.14513969],
              [-65.76652527, 18.14458275],
              [-65.76680756, 18.14458275],
              [-65.76680756, 18.14402771],
              [-65.7673645, 18.14402771],
              [-65.7673645, 18.14374924],
              [-65.76763916, 18.14374924],
              [-65.76763916, 18.14347267],
              [-65.76791382, 18.14347267],
              [-65.76791382, 18.1431942],
              [-65.76819611, 18.1431942],
              [-65.76819611, 18.14291573],
              [-65.76847076, 18.14291573],
              [-65.76847076, 18.14263916],
              [-65.76875305, 18.14263916],
              [-65.76875305, 18.14236069],
              [-65.76902771, 18.14236069],
              [-65.76902771, 18.14208412],
              [-65.76930237, 18.14208412],
              [-65.76930237, 18.14180565],
              [-65.76958466, 18.14180565],
              [-65.76958466, 18.14152718],
              [-65.76985931, 18.14152718],
              [-65.76985931, 18.14125061],
              [-65.7701416, 18.14125061],
              [-65.7701416, 18.14069366],
              [-65.77041626, 18.14069366],
              [-65.77041626, 18.1404171],
              [-65.77069092, 18.1404171],
              [-65.77069092, 18.13986206],
              [-65.77097321, 18.13986206],
              [-65.77097321, 18.13930511],
              [-65.77124786, 18.13930511],
              [-65.77124786, 18.13902855],
              [-65.77153015, 18.13902855],
              [-65.77153015, 18.13875008],
              [-65.77180481, 18.13875008],
              [-65.77180481, 18.1384716],
              [-65.7720871, 18.1384716],
              [-65.7720871, 18.13819504],
              [-65.77236176, 18.13819504],
              [-65.77236176, 18.13791656],
              [-65.77263641, 18.13791656],
              [-65.77263641, 18.13763809],
              [-65.7729187, 18.13763809],
              [-65.7729187, 18.13708305],
              [-65.77319336, 18.13708305],
              [-65.77319336, 18.13652992],
              [-65.77347565, 18.13652992],
              [-65.77347565, 18.13625145],
              [-65.77375031, 18.13625145],
              [-65.77375031, 18.13597298],
              [-65.77402496, 18.13597298],
              [-65.77402496, 18.13541794],
              [-65.77430725, 18.13541794],
              [-65.77430725, 18.13513756],
              [-65.77458191, 18.13513756],
              [-65.77458191, 18.13458252],
              [-65.7748642, 18.13458252],
              [-65.7748642, 18.13402939],
              [-65.77513885, 18.13402939],
              [-65.77513885, 18.13347244],
              [-65.77541351, 18.13347244],
              [-65.77541351, 18.13319397],
              [-65.7756958, 18.13319397],
              [-65.7756958, 18.13263893],
              [-65.77597046, 18.13263893],
              [-65.77597046, 18.13208389],
              [-65.77625275, 18.13208389],
              [-65.77625275, 18.13180542],
              [-65.7765274, 18.13180542],
              [-65.7765274, 18.13125038],
              [-65.77680206, 18.13125038],
              [-65.77680206, 18.13097191],
              [-65.77708435, 18.13097191],
              [-65.77708435, 18.1301384],
              [-65.77735901, 18.1301384],
              [-65.77735901, 18.12986183],
              [-65.7776413, 18.12986183],
              [-65.7776413, 18.12958336],
              [-65.77791595, 18.12958336],
              [-65.77791595, 18.12819481],
              [-65.77819824, 18.12819481],
              [-65.77819824, 18.12791634],
              [-65.7784729, 18.12791634],
              [-65.7784729, 18.12763977],
              [-65.77874756, 18.12763977],
              [-65.77874756, 18.12624931],
              [-65.77902985, 18.12624931],
              [-65.77902985, 18.12569427],
              [-65.7793045, 18.12569427],
              [-65.7793045, 18.1245842],
              [-65.77958679, 18.1245842],
              [-65.77958679, 18.12347221],
              [-65.77986145, 18.12347221],
              [-65.77986145, 18.11874962],
              [-65.77958679, 18.11874962],
              [-65.77958679, 18.11847305],
              [-65.7793045, 18.11847305],
              [-65.7793045, 18.11819458],
              [-65.77902985, 18.11819458],
              [-65.77902985, 18.1179142],
              [-65.77819824, 18.1179142],
              [-65.77819824, 18.11736107],
              [-65.77791595, 18.11736107],
              [-65.77791595, 18.1170826],
              [-65.7776413, 18.1170826],
              [-65.7776413, 18.11680412],
              [-65.77708435, 18.11680412],
              [-65.77708435, 18.11347389],
              [-65.77735901, 18.11347389],
              [-65.77735901, 18.11291695],
              [-65.77874756, 18.11291695],
              [-65.77874756, 18.11263847],
              [-65.77986145, 18.11263847],
              [-65.77986145, 18.11236382],
              [-65.7804184, 18.11236382],
              [-65.7804184, 18.11208344],
              [-65.78125, 18.11208344],
              [-65.78125, 18.11125183],
              [-65.78152466, 18.11125183],
              [-65.78152466, 18.11096954],
              [-65.78180695, 18.11096954],
              [-65.78180695, 18.11069489],
              [-65.7820816, 18.11069489],
              [-65.7820816, 18.11013985],
              [-65.78236389, 18.11013985],
              [-65.78236389, 18.10930634],
              [-65.78263855, 18.10930634],
              [-65.78263855, 18.10902977],
              [-65.78291321, 18.10902977],
              [-65.78291321, 18.10847282],
              [-65.7831955, 18.10847282],
              [-65.7831955, 18.10819435],
              [-65.78375244, 18.10819435],
              [-65.78375244, 18.10763741],
              [-65.7840271, 18.10763741],
              [-65.7840271, 18.10708427],
              [-65.78430176, 18.10708427],
              [-65.78430176, 18.10625076],
              [-65.78458405, 18.10625076],
              [-65.78458405, 18.10597229],
              [-65.7848587, 18.10597229],
              [-65.7848587, 18.10569382],
              [-65.78514099, 18.10569382],
              [-65.78514099, 18.10541534],
              [-65.78541565, 18.10541534],
              [-65.78541565, 18.10513878],
              [-65.78569794, 18.10513878],
              [-65.78569794, 18.10458374],
              [-65.7859726, 18.10458374],
              [-65.7859726, 18.10375023],
              [-65.78624725, 18.10375023],
              [-65.78624725, 18.10319328],
              [-65.78652954, 18.10319328],
              [-65.78652954, 18.10291672],
              [-65.7868042, 18.10291672],
              [-65.7868042, 18.10152817],
              [-65.78708649, 18.10152817],
              [-65.78708649, 18.10124969],
              [-65.78736115, 18.10124969],
              [-65.78736115, 18.10013962],
              [-65.7876358, 18.10013962],
              [-65.7876358, 18.09958267],
              [-65.78791809, 18.09958267],
              [-65.78791809, 18.09930611],
              [-65.78819275, 18.09930611],
              [-65.78819275, 18.09874916],
              [-65.78874969, 18.09874916],
              [-65.78874969, 18.09791756],
              [-65.78902435, 18.09791756],
              [-65.78902435, 18.09763908],
              [-65.78930664, 18.09763908],
              [-65.78930664, 18.09597206],
              [-65.7895813, 18.09597206],
              [-65.7895813, 18.09347153],
              [-65.78930664, 18.09347153],
              [-65.78930664, 18.09263611],
              [-65.78902435, 18.09263611],
              [-65.78902435, 18.09236145],
              [-65.78874969, 18.09236145],
              [-65.78874969, 18.09180832],
              [-65.78847504, 18.09180832],
              [-65.78847504, 18.09124947],
              [-65.78819275, 18.09124947],
              [-65.78819275, 18.09069443],
              [-65.78791809, 18.09069443],
              [-65.78791809, 18.09013939],
              [-65.78986359, 18.09013939],
              [-65.78986359, 18.08986092],
              [-65.79125214, 18.08986092],
              [-65.79125214, 18.08958244],
              [-65.79180908, 18.08958244],
              [-65.79180908, 18.08930588],
              [-65.79208374, 18.08930588],
              [-65.79208374, 18.0890274],
              [-65.79264069, 18.0890274],
              [-65.79264069, 18.08875084],
              [-65.79291534, 18.08875084],
              [-65.79291534, 18.08847237],
              [-65.79319763, 18.08847237],
              [-65.79319763, 18.08791733],
              [-65.79347229, 18.08791733],
              [-65.79347229, 18.08736038],
              [-65.79374695, 18.08736038],
              [-65.79374695, 18.08708382],
              [-65.79402924, 18.08708382],
              [-65.79402924, 18.08680534],
              [-65.79430389, 18.08680534],
              [-65.79430389, 18.08652687],
              [-65.79458618, 18.08652687],
              [-65.79458618, 18.08625031],
              [-65.79486084, 18.08625031],
              [-65.79486084, 18.08569527],
              [-65.7951355, 18.08569527],
              [-65.7951355, 18.08541679],
              [-65.79541779, 18.08541679],
              [-65.79541779, 18.08458328],
              [-65.79569244, 18.08458328],
              [-65.79569244, 18.08402824],
              [-65.79597473, 18.08402824],
              [-65.79597473, 18.0806942],
              [-65.79569244, 18.0806942],
              [-65.79569244, 18.07986069],
              [-65.79541779, 18.07986069],
              [-65.79541779, 18.07958412],
              [-65.7951355, 18.07958412],
              [-65.7951355, 18.07930565],
              [-65.79486084, 18.07930565],
              [-65.79486084, 18.07875061],
              [-65.79458618, 18.07875061],
              [-65.79458618, 18.07791519],
              [-65.79486084, 18.07791519],
              [-65.79486084, 18.07736206],
              [-65.7951355, 18.07736206],
              [-65.7951355, 18.07680511],
              [-65.79541779, 18.07680511],
              [-65.79541779, 18.07652855],
              [-65.79569244, 18.07652855],
              [-65.79569244, 18.07625008],
              [-65.79597473, 18.07625008],
              [-65.79597473, 18.07569313],
              [-65.79624939, 18.07569313],
              [-65.79624939, 18.07513809],
              [-65.79597473, 18.07513809],
              [-65.79597473, 18.07486153],
              [-65.79569244, 18.07486153],
              [-65.79569244, 18.07430649],
              [-65.79541779, 18.07430649],
              [-65.79541779, 18.07402802],
              [-65.79486084, 18.07402802],
              [-65.79486084, 18.07374954],
              [-65.79458618, 18.07374954],
              [-65.79458618, 18.07291603],
              [-65.79486084, 18.07291603],
              [-65.79486084, 18.0723629],
              [-65.7951355, 18.0723629],
              [-65.7951355, 18.07208252],
              [-65.79541779, 18.07208252],
              [-65.79541779, 18.07152748],
              [-65.79569244, 18.07152748],
              [-65.79569244, 18.07125282],
              [-65.79624939, 18.07125282],
              [-65.79624939, 18.07097244],
              [-65.79680634, 18.07097244],
              [-65.79680634, 18.06985855],
              [-65.79652405, 18.06985855],
              [-65.79652405, 18.06958389],
              [-65.79688263, 18.0696125],
              [-65.80158997, 18.07010651],
              [-65.80758667, 18.07107162],
              [-65.80897522, 18.07155228],
              [-65.81035614, 18.07442093],
              [-65.81172943, 18.0777626],
              [-65.81402588, 18.08158302],
              [-65.82187653, 18.08159828],
              [-65.83018494, 18.08590508],
              [-65.83802032, 18.09021759],
              [-65.8403244, 18.09308243],
              [-65.84078217, 18.09451294],
              [-65.84262848, 18.0964241],
              [-65.84355164, 18.09786224],
              [-65.84815979, 18.09929657],
              [-65.84353638, 18.10740089],
              [-65.84214783, 18.1102581],
              [-65.84260559, 18.11217117],
              [-65.84536743, 18.11503983],
              [-65.85090637, 18.11838722],
              [-65.85137177, 18.11790848],
              [-65.85506439, 18.11743546],
              [-65.85644531, 18.11696243],
              [-65.86014557, 18.11553383],
              [-65.86338043, 18.11268044],
              [-65.86384583, 18.11125183],
              [-65.86338806, 18.10886574],
              [-65.86523438, 18.10791397],
              [-65.86985016, 18.10839653],
              [-65.87031555, 18.10839653],
              [-65.87123108, 18.11269379],
              [-65.87769318, 18.11794853],
              [-65.87722015, 18.12080956],
              [-65.87399292, 18.12844086],
              [-65.86843872, 18.13606071],
              [-65.86750793, 18.13797188],
              [-65.86473846, 18.13939667],
              [-65.86289215, 18.14034843],
              [-65.86149597, 18.14559364],
              [-65.86194611, 18.15560913],
              [-65.85684967, 18.16466522],
              [-65.85546112, 18.16704941],
              [-65.85683441, 18.1732502],
              [-65.85405731, 18.17706299],
              [-65.84666443, 18.17800331],
              [-65.84574127, 18.17800331],
              [-65.83650208, 18.17512894],
              [-65.8351059, 18.17989159],
              [-65.83325195, 18.18370438],
              [-65.8323288, 18.18465614],
              [-65.82816315, 18.1870327],
              [-65.82584381, 18.19179726],
              [-65.82582855, 18.20038223],
              [-65.8193512, 18.205616],
              [-65.80686951, 18.20511436],
              [-65.79993439, 18.20271683],
              [-65.79253387, 18.20698929],
              [-65.76020813, 18.19546509],
              [-65.76252747, 18.1902256],
              [-65.76624298, 18.182127],
              [-65.75469971, 18.17733002],
              [-65.75424194, 18.1773243],
              [-65.75147247, 18.17636681],
              [-65.74822998, 18.17969513],
              [-65.74082947, 18.18206024],
              [-65.7403717, 18.18110657],
              [-65.73915863, 18.17430496],
            ],
          ],
          [
            [
              [-65.73014069, 18.15930557],
              [-65.73014069, 18.15902519],
              [-65.72958374, 18.15902519],
              [-65.72958374, 18.15847206],
              [-65.72930908, 18.15847206],
              [-65.72930908, 18.15819359],
              [-65.72902679, 18.15819359],
              [-65.72902679, 18.15680313],
              [-65.7298584, 18.15680313],
              [-65.7298584, 18.15652847],
              [-65.73014069, 18.15652847],
              [-65.73014069, 18.15625],
              [-65.73180389, 18.15625],
              [-65.73180389, 18.15680313],
              [-65.73208618, 18.15680313],
              [-65.73208618, 18.15736198],
              [-65.73236084, 18.15736198],
              [-65.73236084, 18.15791512],
              [-65.73291779, 18.15791512],
              [-65.73291779, 18.15625],
              [-65.7326355, 18.15625],
              [-65.7326355, 18.15430641],
              [-65.73236084, 18.15430641],
              [-65.73236084, 18.15374947],
              [-65.7326355, 18.15374947],
              [-65.7326355, 18.1534729],
              [-65.73291779, 18.1534729],
              [-65.73291779, 18.15319443],
              [-65.73486328, 18.15319443],
              [-65.73486328, 18.15875053],
              [-65.73458099, 18.15875053],
              [-65.73458099, 18.15902519],
              [-65.73319244, 18.15902519],
              [-65.73319244, 18.15875053],
              [-65.73236084, 18.15875053],
              [-65.73236084, 18.15847206],
              [-65.73208618, 18.15847206],
              [-65.73208618, 18.15819359],
              [-65.73152924, 18.15819359],
              [-65.73152924, 18.15847206],
              [-65.73124695, 18.15847206],
              [-65.73124695, 18.15902519],
              [-65.73097229, 18.15902519],
              [-65.73097229, 18.15930557],
              [-65.73014069, 18.15930557],
            ],
          ],
          [
            [
              [-65.7951355, 18.0694294],
              [-65.79628754, 18.06958389],
              [-65.7951355, 18.06958389],
              [-65.7951355, 18.0694294],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 36,
        name: 'Humacao',
        hasc_1: 'PR.HU',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-65.87769318, 18.0694294, -65.72902679, 18.20698929],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.51',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-67.0272522, 18.39359856],
              [-67.0327301, 18.38591385],
              [-67.03727722, 18.37776184],
              [-67.03814697, 18.37298775],
              [-67.03814697, 18.37251091],
              [-67.03904724, 18.3705883],
              [-67.03901672, 18.36773109],
              [-67.03901672, 18.36725616],
              [-67.03945923, 18.36534691],
              [-67.04170227, 18.35865021],
              [-67.04214478, 18.35721588],
              [-67.04260254, 18.35625839],
              [-67.04813385, 18.35381126],
              [-67.04766083, 18.35286331],
              [-67.04716492, 18.35001183],
              [-67.04290009, 18.34100342],
              [-67.03961945, 18.33722687],
              [-67.0368042, 18.3334465],
              [-67.03629303, 18.32916069],
              [-67.0371933, 18.32629204],
              [-67.04130554, 18.32147789],
              [-67.05271149, 18.30657578],
              [-67.05364227, 18.30703926],
              [-67.06341553, 18.31169319],
              [-67.06943512, 18.3125782],
              [-67.07777405, 18.31295967],
              [-67.08659363, 18.31571388],
              [-67.1014328, 18.31839561],
              [-67.11209869, 18.32017708],
              [-67.12139893, 18.32387543],
              [-67.1264267, 18.31904602],
              [-67.12966156, 18.31853104],
              [-67.13011932, 18.31804848],
              [-67.13013458, 18.31900215],
              [-67.13442993, 18.33038712],
              [-67.13126373, 18.33662224],
              [-67.13365173, 18.34326935],
              [-67.13412476, 18.3442173],
              [-67.13461304, 18.34659386],
              [-67.13415527, 18.34707451],
              [-67.13140106, 18.34901619],
              [-67.12822723, 18.35477257],
              [-67.12736511, 18.36002731],
              [-67.12833405, 18.36382484],
              [-67.12702942, 18.37146759],
              [-67.13078308, 18.37570953],
              [-67.13233185, 18.3895092],
              [-67.1268692, 18.39719963],
              [-67.12369537, 18.40343475],
              [-67.12371063, 18.40486336],
              [-67.12467194, 18.40771294],
              [-67.12561798, 18.40960693],
              [-67.11966705, 18.41539574],
              [-67.11188507, 18.4235878],
              [-67.09860992, 18.43756485],
              [-67.09581757, 18.43664551],
              [-67.09303284, 18.43667793],
              [-67.090271, 18.43813705],
              [-67.08889008, 18.4391098],
              [-67.08339691, 18.44489098],
              [-67.07691956, 18.44591713],
              [-67.07647705, 18.44735336],
              [-67.07420349, 18.45166588],
              [-67.0742569, 18.45642853],
              [-67.05622864, 18.46092415],
              [-67.05382538, 18.45285225],
              [-67.05238342, 18.44763184],
              [-67.0509491, 18.4438343],
              [-67.0272522, 18.39359856],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 51,
        name: 'Moca',
        hasc_1: 'PR.MC',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-67.13461304, 18.30657578, -67.0272522, 18.46092415],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.37',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.95708466, 18.48875046],
              [-66.95257568, 18.47729492],
              [-66.95304108, 18.47681236],
              [-66.96273804, 18.47337723],
              [-66.96365356, 18.47193718],
              [-66.95895386, 18.46531677],
              [-66.96349335, 18.4557476],
              [-66.96668243, 18.44999313],
              [-66.95824432, 18.44055939],
              [-66.94941711, 18.43826675],
              [-66.94756317, 18.4378109],
              [-66.94709015, 18.43685913],
              [-66.94326019, 18.42403412],
              [-66.94138336, 18.42167091],
              [-66.93533325, 18.41840172],
              [-66.93438721, 18.41745758],
              [-66.93437958, 18.41650581],
              [-66.93482971, 18.41554451],
              [-66.93942261, 18.41073608],
              [-66.94173431, 18.40976143],
              [-66.94404602, 18.40973663],
              [-66.9458847, 18.40828514],
              [-66.947258, 18.40636826],
              [-66.94815826, 18.4034996],
              [-66.94721222, 18.40160179],
              [-66.94255066, 18.3992691],
              [-66.94023132, 18.3992939],
              [-66.931427, 18.39842987],
              [-66.92679596, 18.39847374],
              [-66.92448425, 18.39849663],
              [-66.92355347, 18.39850616],
              [-66.92262268, 18.39803886],
              [-66.92214203, 18.39661407],
              [-66.92211914, 18.39328003],
              [-66.92905426, 18.39273453],
              [-66.93090057, 18.39176559],
              [-66.93503571, 18.38838768],
              [-66.93548584, 18.38742638],
              [-66.93731689, 18.38454819],
              [-66.93728638, 18.38169098],
              [-66.93727875, 18.38121414],
              [-66.9367981, 18.37931442],
              [-66.93677521, 18.37693024],
              [-66.94556427, 18.37541199],
              [-66.94881439, 18.37680817],
              [-66.95952606, 18.38193893],
              [-66.97392273, 18.38607788],
              [-66.97948456, 18.38649368],
              [-66.98455811, 18.38501167],
              [-66.9850235, 18.38548279],
              [-66.99200439, 18.38874435],
              [-66.99758911, 18.3910656],
              [-67.00224304, 18.39292145],
              [-67.00595856, 18.39431],
              [-67.0272522, 18.39359856],
              [-67.0509491, 18.4438343],
              [-67.05238342, 18.44763184],
              [-67.05382538, 18.45285225],
              [-67.05622864, 18.46092415],
              [-67.05631256, 18.46854401],
              [-67.05770111, 18.46852875],
              [-67.0586319, 18.4685173],
              [-67.05918121, 18.47613335],
              [-67.06297302, 18.48418617],
              [-67.07321167, 18.4878788],
              [-67.07923889, 18.48781013],
              [-67.07969666, 18.48780251],
              [-67.08249664, 18.48920059],
              [-67.08298492, 18.49110031],
              [-67.08256531, 18.49539185],
              [-67.08538818, 18.49916649],
              [-67.09192657, 18.50337601],
              [-67.09570313, 18.50904846],
              [-67.09759521, 18.51235771],
              [-67.10263824, 18.51319504],
              [-67.10263824, 18.51347351],
              [-67.10236359, 18.51347351],
              [-67.10236359, 18.51375008],
              [-67.10180664, 18.51375008],
              [-67.10180664, 18.51430511],
              [-67.10152435, 18.51430511],
              [-67.10152435, 18.51458359],
              [-67.10097504, 18.51458359],
              [-67.10097504, 18.51486206],
              [-67.10041809, 18.51486206],
              [-67.10041809, 18.51513863],
              [-67.1001358, 18.51513863],
              [-67.1001358, 18.5154171],
              [-67.09957886, 18.5154171],
              [-67.09375, 18.5154171],
              [-67.09375, 18.51569366],
              [-67.09236145, 18.51569366],
              [-67.09236145, 18.51597214],
              [-67.09069824, 18.51597214],
              [-67.09069824, 18.51569366],
              [-67.08958435, 18.51569366],
              [-67.08958435, 18.5154171],
              [-67.08847046, 18.5154171],
              [-67.08847046, 18.51513863],
              [-67.0881958, 18.51513863],
              [-67.0881958, 18.51486206],
              [-67.0873642, 18.51486206],
              [-67.0873642, 18.51458359],
              [-67.08430481, 18.51458359],
              [-67.08430481, 18.51430511],
              [-67.08403015, 18.51430511],
              [-67.08403015, 18.51402855],
              [-67.08125305, 18.51402855],
              [-67.08125305, 18.51375008],
              [-67.08069611, 18.51375008],
              [-67.08069611, 18.51347351],
              [-67.08041382, 18.51347351],
              [-67.08041382, 18.51319504],
              [-67.07624817, 18.51319504],
              [-67.07624817, 18.51347351],
              [-67.07569122, 18.51347351],
              [-67.07569122, 18.51375008],
              [-67.07541656, 18.51375008],
              [-67.07541656, 18.51402855],
              [-67.07514191, 18.51402855],
              [-67.07514191, 18.51430511],
              [-67.07485962, 18.51430511],
              [-67.07485962, 18.51458359],
              [-67.07291412, 18.51458359],
              [-67.07291412, 18.51402855],
              [-67.07236481, 18.51402855],
              [-67.07236481, 18.51319504],
              [-67.06903076, 18.51319504],
              [-67.06903076, 18.51291656],
              [-67.06541443, 18.51291656],
              [-67.06541443, 18.51347351],
              [-67.06430817, 18.51347351],
              [-67.06430817, 18.51375008],
              [-67.06319427, 18.51375008],
              [-67.06319427, 18.51402855],
              [-67.06236267, 18.51402855],
              [-67.06236267, 18.51375008],
              [-67.06208038, 18.51375008],
              [-67.06208038, 18.51347351],
              [-67.06041718, 18.51347351],
              [-67.06041718, 18.51375008],
              [-67.06014252, 18.51375008],
              [-67.06014252, 18.51402855],
              [-67.05874634, 18.51402855],
              [-67.05874634, 18.51430511],
              [-67.05791473, 18.51430511],
              [-67.05791473, 18.51402855],
              [-67.05708313, 18.51402855],
              [-67.05708313, 18.51375008],
              [-67.05680847, 18.51375008],
              [-67.05680847, 18.51347351],
              [-67.05625153, 18.51347351],
              [-67.05625153, 18.51375008],
              [-67.05596924, 18.51375008],
              [-67.05596924, 18.51402855],
              [-67.05403137, 18.51402855],
              [-67.05403137, 18.51486206],
              [-67.05374908, 18.51486206],
              [-67.05374908, 18.5154171],
              [-67.05097198, 18.5154171],
              [-67.05097198, 18.51513863],
              [-67.05041504, 18.51513863],
              [-67.05041504, 18.51486206],
              [-67.04958344, 18.51486206],
              [-67.04958344, 18.51430511],
              [-67.04930878, 18.51430511],
              [-67.04930878, 18.51375008],
              [-67.04902649, 18.51375008],
              [-67.04902649, 18.51291656],
              [-67.04569244, 18.51291656],
              [-67.04569244, 18.51235962],
              [-67.04486084, 18.51235962],
              [-67.04486084, 18.51263809],
              [-67.04374695, 18.51263809],
              [-67.04374695, 18.51291656],
              [-67.03930664, 18.51291656],
              [-67.03930664, 18.51235962],
              [-67.0376358, 18.51235962],
              [-67.0376358, 18.51208115],
              [-67.03569794, 18.51208115],
              [-67.03569794, 18.51180649],
              [-67.03541565, 18.51180649],
              [-67.03541565, 18.51152992],
              [-67.03458405, 18.51152992],
              [-67.03458405, 18.51125145],
              [-67.0331955, 18.51125145],
              [-67.0331955, 18.51097107],
              [-67.03347015, 18.51097107],
              [-67.03347015, 18.51041603],
              [-67.03180695, 18.51041603],
              [-67.03180695, 18.51014137],
              [-67.03013611, 18.51014137],
              [-67.03013611, 18.50985909],
              [-67.0293045, 18.50985909],
              [-67.0293045, 18.50930595],
              [-67.02874756, 18.50930595],
              [-67.02874756, 18.50902748],
              [-67.0284729, 18.50902748],
              [-67.0284729, 18.50875092],
              [-67.02819824, 18.50875092],
              [-67.02819824, 18.50847244],
              [-67.02735901, 18.50847244],
              [-67.02735901, 18.50875092],
              [-67.02402496, 18.50875092],
              [-67.02402496, 18.50819397],
              [-67.0220871, 18.50819397],
              [-67.0220871, 18.50875092],
              [-67.02153015, 18.50875092],
              [-67.02153015, 18.50902748],
              [-67.02124786, 18.50902748],
              [-67.02124786, 18.50930595],
              [-67.02097321, 18.50930595],
              [-67.02097321, 18.50958252],
              [-67.02041626, 18.50958252],
              [-67.02041626, 18.50985909],
              [-67.0201416, 18.50985909],
              [-67.0201416, 18.51041603],
              [-67.02153015, 18.51041603],
              [-67.02153015, 18.51152992],
              [-67.01985931, 18.51152992],
              [-67.01985931, 18.51125145],
              [-67.01791382, 18.51125145],
              [-67.01791382, 18.51097107],
              [-67.01680756, 18.51097107],
              [-67.01680756, 18.5106945],
              [-67.01652527, 18.5106945],
              [-67.01652527, 18.51041603],
              [-67.01597595, 18.51041603],
              [-67.01597595, 18.51014137],
              [-67.01569366, 18.51014137],
              [-67.01569366, 18.50985909],
              [-67.01541901, 18.50985909],
              [-67.01541901, 18.50847244],
              [-67.01486206, 18.50847244],
              [-67.01486206, 18.50819397],
              [-67.01457977, 18.50819397],
              [-67.01457977, 18.5079174],
              [-67.01347351, 18.5079174],
              [-67.01347351, 18.50819397],
              [-67.01264191, 18.50819397],
              [-67.01264191, 18.50736046],
              [-67.01208496, 18.50736046],
              [-67.01208496, 18.50708389],
              [-67.01180267, 18.50708389],
              [-67.01180267, 18.50680542],
              [-67.01152802, 18.50680542],
              [-67.01152802, 18.50652695],
              [-67.01125336, 18.50652695],
              [-67.01125336, 18.50625038],
              [-67.01069641, 18.50625038],
              [-67.01069641, 18.50680542],
              [-67.01041412, 18.50680542],
              [-67.01041412, 18.50708389],
              [-67.00986481, 18.50708389],
              [-67.00986481, 18.50652695],
              [-67.00958252, 18.50652695],
              [-67.00958252, 18.50597191],
              [-67.00875092, 18.50597191],
              [-67.00875092, 18.50625038],
              [-67.00791931, 18.50625038],
              [-67.00791931, 18.50597191],
              [-67.00680542, 18.50597191],
              [-67.00680542, 18.50569534],
              [-67.00624847, 18.50569534],
              [-67.00624847, 18.5051384],
              [-67.00597382, 18.5051384],
              [-67.00597382, 18.50486183],
              [-67.00569153, 18.50486183],
              [-67.00569153, 18.50458336],
              [-67.00402832, 18.50458336],
              [-67.00402832, 18.50402832],
              [-67.00375366, 18.50402832],
              [-67.00375366, 18.50347137],
              [-67.00235748, 18.50347137],
              [-67.00235748, 18.50291634],
              [-67.00013733, 18.50291634],
              [-66.99986267, 18.50291634],
              [-66.99986267, 18.5023613],
              [-66.99958038, 18.5023613],
              [-66.99958038, 18.50263977],
              [-66.99847412, 18.50263977],
              [-66.99847412, 18.5023613],
              [-66.99791718, 18.5023613],
              [-66.99791718, 18.50208473],
              [-66.99764252, 18.50208473],
              [-66.99764252, 18.50180626],
              [-66.99736023, 18.50180626],
              [-66.99736023, 18.50152779],
              [-66.99708557, 18.50152779],
              [-66.99708557, 18.50124931],
              [-66.99652863, 18.50124931],
              [-66.99652863, 18.50152779],
              [-66.99597168, 18.50152779],
              [-66.99597168, 18.50180626],
              [-66.99514008, 18.50180626],
              [-66.99514008, 18.50152779],
              [-66.99485779, 18.50152779],
              [-66.99485779, 18.50124931],
              [-66.99430847, 18.50124931],
              [-66.99430847, 18.50013924],
              [-66.99402618, 18.50013924],
              [-66.99402618, 18.49986267],
              [-66.99291992, 18.49986267],
              [-66.99291992, 18.50013924],
              [-66.99153137, 18.50013924],
              [-66.99153137, 18.49986267],
              [-66.99124908, 18.49986267],
              [-66.99124908, 18.49902725],
              [-66.99097443, 18.49902725],
              [-66.99097443, 18.49875259],
              [-66.99041748, 18.49875259],
              [-66.99041748, 18.49902725],
              [-66.98986053, 18.49902725],
              [-66.98986053, 18.49847031],
              [-66.98958588, 18.49847031],
              [-66.98958588, 18.49791718],
              [-66.98902893, 18.49791718],
              [-66.98902893, 18.49764061],
              [-66.98847198, 18.49764061],
              [-66.98847198, 18.49791718],
              [-66.98735809, 18.49791718],
              [-66.98735809, 18.49708366],
              [-66.98708344, 18.49708366],
              [-66.98708344, 18.49680519],
              [-66.98680878, 18.49680519],
              [-66.98680878, 18.49653053],
              [-66.98652649, 18.49653053],
              [-66.98652649, 18.49597168],
              [-66.98625183, 18.49597168],
              [-66.98625183, 18.49569511],
              [-66.98596954, 18.49569511],
              [-66.98596954, 18.49541664],
              [-66.98569489, 18.49541664],
              [-66.98569489, 18.49513626],
              [-66.98542023, 18.49513626],
              [-66.98542023, 18.4948616],
              [-66.98513794, 18.4948616],
              [-66.98513794, 18.49458313],
              [-66.98402405, 18.49458313],
              [-66.98402405, 18.49430466],
              [-66.98374939, 18.49430466],
              [-66.98374939, 18.49402618],
              [-66.98319244, 18.49402618],
              [-66.98319244, 18.49374962],
              [-66.98291779, 18.49374962],
              [-66.98291779, 18.49347305],
              [-66.9826355, 18.49347305],
              [-66.9826355, 18.49319458],
              [-66.98236084, 18.49319458],
              [-66.98236084, 18.49291611],
              [-66.98041534, 18.49291611],
              [-66.98041534, 18.49263954],
              [-66.98014069, 18.49263954],
              [-66.98014069, 18.49236107],
              [-66.9798584, 18.49236107],
              [-66.9798584, 18.4920826],
              [-66.97958374, 18.4920826],
              [-66.97958374, 18.49180412],
              [-66.97902679, 18.49180412],
              [-66.97902679, 18.49152756],
              [-66.97875214, 18.49152756],
              [-66.97875214, 18.49124908],
              [-66.9779129, 18.49124908],
              [-66.9779129, 18.49152756],
              [-66.97541809, 18.49152756],
              [-66.97541809, 18.49180412],
              [-66.97486115, 18.49180412],
              [-66.97486115, 18.4920826],
              [-66.97374725, 18.4920826],
              [-66.97374725, 18.49236107],
              [-66.97319794, 18.49236107],
              [-66.97319794, 18.49263954],
              [-66.97291565, 18.49263954],
              [-66.97291565, 18.49319458],
              [-66.97264099, 18.49319458],
              [-66.97264099, 18.49347305],
              [-66.97208405, 18.49347305],
              [-66.97208405, 18.49402618],
              [-66.97097015, 18.49402618],
              [-66.97097015, 18.49374962],
              [-66.97125244, 18.49374962],
              [-66.97125244, 18.49319458],
              [-66.97097015, 18.49319458],
              [-66.97097015, 18.49291611],
              [-66.9695816, 18.49291611],
              [-66.9695816, 18.49263954],
              [-66.96902466, 18.49263954],
              [-66.96902466, 18.49236107],
              [-66.9679184, 18.49236107],
              [-66.9679184, 18.4920826],
              [-66.9651413, 18.4920826],
              [-66.9651413, 18.49180412],
              [-66.96458435, 18.49180412],
              [-66.96458435, 18.49152756],
              [-66.9640274, 18.49152756],
              [-66.9640274, 18.49124908],
              [-66.96291351, 18.49124908],
              [-66.96291351, 18.49097252],
              [-66.9623642, 18.49097252],
              [-66.9623642, 18.49124908],
              [-66.96208191, 18.49124908],
              [-66.96208191, 18.49152756],
              [-66.96152496, 18.49152756],
              [-66.96152496, 18.49180412],
              [-66.96097565, 18.49180412],
              [-66.96097565, 18.49097252],
              [-66.96069336, 18.49097252],
              [-66.96069336, 18.49069595],
              [-66.96013641, 18.49069595],
              [-66.96013641, 18.49097252],
              [-66.9595871, 18.49097252],
              [-66.9595871, 18.49069595],
              [-66.95923615, 18.49069595],
              [-66.95903015, 18.49069595],
              [-66.95903015, 18.49043465],
              [-66.95903015, 18.4893055],
              [-66.95874786, 18.4893055],
              [-66.95874786, 18.48902702],
              [-66.95847321, 18.48902702],
              [-66.95847321, 18.48875046],
              [-66.95708466, 18.48875046],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 37,
        name: 'Isabela',
        hasc_1: 'PR.IS',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-67.10263824, 18.37541199, -66.92211914, 18.51597214],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.38',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.54704285, 18.15313148],
              [-66.55258179, 18.15214539],
              [-66.55905914, 18.15353775],
              [-66.5674057, 18.15968513],
              [-66.57485199, 18.16822433],
              [-66.59104156, 18.17193985],
              [-66.59612274, 18.17143059],
              [-66.60533905, 18.16659927],
              [-66.60946655, 18.16275787],
              [-66.61591339, 18.15889549],
              [-66.62515259, 18.15836143],
              [-66.62976837, 18.15784836],
              [-66.63156891, 18.15068054],
              [-66.63942719, 18.15205956],
              [-66.64963531, 18.1586647],
              [-66.65057373, 18.16056824],
              [-66.65155792, 18.16962242],
              [-66.65210724, 18.18249321],
              [-66.64286804, 18.1830368],
              [-66.64194489, 18.18351936],
              [-66.64386749, 18.19447708],
              [-66.63978577, 18.20690346],
              [-66.639328, 18.20738792],
              [-66.63380432, 18.21123886],
              [-66.62970734, 18.22080421],
              [-66.62835693, 18.22605705],
              [-66.62653351, 18.22988129],
              [-66.6256485, 18.23608971],
              [-66.62525177, 18.24706078],
              [-66.62479401, 18.24754143],
              [-66.62248993, 18.24898338],
              [-66.61972046, 18.24947929],
              [-66.61878967, 18.24901009],
              [-66.61833191, 18.2490139],
              [-66.61739349, 18.2475853],
              [-66.59985352, 18.25199318],
              [-66.59938812, 18.25247574],
              [-66.59901428, 18.26678085],
              [-66.59628296, 18.27299309],
              [-66.59583282, 18.27633858],
              [-66.59816742, 18.27918243],
              [-66.59864807, 18.28203964],
              [-66.59726715, 18.28395462],
              [-66.59680939, 18.28443527],
              [-66.59496307, 18.28492355],
              [-66.58986664, 18.28305054],
              [-66.58385468, 18.28404045],
              [-66.57923126, 18.28406906],
              [-66.57830811, 18.2840786],
              [-66.57784271, 18.28455544],
              [-66.57692719, 18.28598785],
              [-66.57328033, 18.29459572],
              [-66.56682587, 18.29844856],
              [-66.56078339, 18.29371834],
              [-66.55469513, 18.28136063],
              [-66.5579071, 18.27657127],
              [-66.55836487, 18.2756176],
              [-66.55555725, 18.2694397],
              [-66.55593872, 18.2556076],
              [-66.55593109, 18.25418091],
              [-66.55593109, 18.25370026],
              [-66.56147766, 18.25414658],
              [-66.56240082, 18.25366211],
              [-66.56284332, 18.2498455],
              [-66.56420135, 18.24411583],
              [-66.56465149, 18.2426815],
              [-66.56278992, 18.2407856],
              [-66.56232452, 18.24031067],
              [-66.55537415, 18.23749352],
              [-66.55302429, 18.23035622],
              [-66.54927063, 18.22084045],
              [-66.53766632, 18.21280289],
              [-66.53295898, 18.19709396],
              [-66.53661346, 18.18800926],
              [-66.53102112, 18.18041039],
              [-66.53239441, 18.17706871],
              [-66.53285217, 18.17658806],
              [-66.53839111, 18.1760788],
              [-66.54158783, 18.16890335],
              [-66.54294586, 18.16317368],
              [-66.54705048, 18.15408516],
              [-66.54704285, 18.15313148],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 38,
        name: 'Jayuya',
        hasc_1: 'PR.JY',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.65210724, 18.15068054, -66.53102112, 18.29844856],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.39',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.4269104, 18.04259872],
              [-66.42688751, 18.03734779],
              [-66.42594147, 18.03115082],
              [-66.42127228, 18.0187645],
              [-66.42539978, 18.01206017],
              [-66.42815399, 18.00966263],
              [-66.43092346, 18.01012611],
              [-66.43460083, 18.00724411],
              [-66.43550873, 18.00294495],
              [-66.43455505, 17.99674034],
              [-66.43684387, 17.99243355],
              [-66.4377594, 17.99147224],
              [-66.44696808, 17.98761559],
              [-66.44971466, 17.98425674],
              [-66.45212555, 17.98152733],
              [-66.45225525, 17.98152733],
              [-66.45263672, 17.98152733],
              [-66.45263672, 17.9818058],
              [-66.45319366, 17.9818058],
              [-66.45319366, 17.98208427],
              [-66.45347595, 17.98208427],
              [-66.45347595, 17.98236084],
              [-66.45375061, 17.98236084],
              [-66.45375061, 17.9845829],
              [-66.45402527, 17.9845829],
              [-66.45402527, 17.98541641],
              [-66.45430756, 17.98541641],
              [-66.45430756, 17.98680496],
              [-66.4548645, 17.98680496],
              [-66.4548645, 17.98708344],
              [-66.45569611, 17.98708344],
              [-66.45569611, 17.98736191],
              [-66.45597076, 17.98736191],
              [-66.45597076, 17.98763657],
              [-66.45625305, 17.98763657],
              [-66.45625305, 17.98791885],
              [-66.45680237, 17.98791885],
              [-66.45680237, 17.98819351],
              [-66.45735931, 17.98819351],
              [-66.45735931, 17.98847198],
              [-66.4576416, 17.98847198],
              [-66.4576416, 17.98875046],
              [-66.45819092, 17.98875046],
              [-66.45819092, 17.98902893],
              [-66.45874786, 17.98902893],
              [-66.45874786, 17.9893055],
              [-66.45930481, 17.9893055],
              [-66.45930481, 17.98958397],
              [-66.46013641, 17.98958397],
              [-66.46013641, 17.98986053],
              [-66.46097565, 17.98986053],
              [-66.46097565, 17.99014091],
              [-66.46263885, 17.99014091],
              [-66.46263885, 17.99041748],
              [-66.46485901, 17.99041748],
              [-66.46485901, 17.99069405],
              [-66.46902466, 17.99069405],
              [-66.46902466, 17.99041748],
              [-66.4706955, 17.99041748],
              [-66.4706955, 17.99014091],
              [-66.47097015, 17.99014091],
              [-66.47097015, 17.98986053],
              [-66.4715271, 17.98986053],
              [-66.4715271, 17.98958397],
              [-66.47180176, 17.98958397],
              [-66.47180176, 17.9893055],
              [-66.4734726, 17.9893055],
              [-66.4734726, 17.98902893],
              [-66.47569275, 17.98902893],
              [-66.47569275, 17.98847198],
              [-66.47597504, 17.98847198],
              [-66.47597504, 17.98819351],
              [-66.47624969, 17.98819351],
              [-66.47624969, 17.98791885],
              [-66.47680664, 17.98791885],
              [-66.47680664, 17.98763657],
              [-66.47846985, 17.98763657],
              [-66.47846985, 17.98708344],
              [-66.47930908, 17.98708344],
              [-66.47930908, 17.98736191],
              [-66.4798584, 17.98736191],
              [-66.4798584, 17.98819351],
              [-66.48014069, 17.98819351],
              [-66.48014069, 17.98847198],
              [-66.48041534, 17.98847198],
              [-66.48041534, 17.98875046],
              [-66.48069763, 17.98875046],
              [-66.48069763, 17.98902893],
              [-66.48124695, 17.98902893],
              [-66.48124695, 17.9893055],
              [-66.48180389, 17.9893055],
              [-66.48180389, 17.98958397],
              [-66.48208618, 17.98958397],
              [-66.48208618, 17.98986053],
              [-66.4826355, 17.98986053],
              [-66.4826355, 17.99014091],
              [-66.48374939, 17.99014091],
              [-66.48374939, 17.99041748],
              [-66.48513794, 17.99041748],
              [-66.48513794, 17.99014091],
              [-66.48569489, 17.99014091],
              [-66.48569489, 17.99041748],
              [-66.49013519, 17.99041748],
              [-66.49013519, 17.99014091],
              [-66.49041748, 17.99014091],
              [-66.49041748, 17.98986053],
              [-66.49069214, 17.98986053],
              [-66.49069214, 17.99014091],
              [-66.49124908, 17.99014091],
              [-66.49124908, 17.98986053],
              [-66.49541473, 17.98986053],
              [-66.49541473, 17.98958397],
              [-66.49624634, 17.98958397],
              [-66.49624634, 17.9893055],
              [-66.49764252, 17.9893055],
              [-66.49764252, 17.98902893],
              [-66.49903107, 17.98902893],
              [-66.49903107, 17.98875046],
              [-66.49958038, 17.98875046],
              [-66.49958038, 17.98847198],
              [-66.50013733, 17.98847198],
              [-66.50013733, 17.98819351],
              [-66.50125122, 17.98819351],
              [-66.50125122, 17.98791885],
              [-66.50263977, 17.98791885],
              [-66.50263977, 17.98763657],
              [-66.50485992, 17.98763657],
              [-66.50485992, 17.98736191],
              [-66.50514221, 17.98736191],
              [-66.50514221, 17.98708344],
              [-66.50569153, 17.98708344],
              [-66.50569153, 17.98680496],
              [-66.50597382, 17.98680496],
              [-66.50597382, 17.98652649],
              [-66.50680542, 17.98652649],
              [-66.50680542, 17.98624992],
              [-66.50736237, 17.98624992],
              [-66.50736237, 17.98652649],
              [-66.50763702, 17.98652649],
              [-66.50763702, 17.98624992],
              [-66.50846863, 17.98624992],
              [-66.50846863, 17.98597145],
              [-66.50902557, 17.98597145],
              [-66.50902557, 17.98569679],
              [-66.50986481, 17.98569679],
              [-66.50986481, 17.98541641],
              [-66.51180267, 17.98541641],
              [-66.51180267, 17.98513794],
              [-66.51264191, 17.98513794],
              [-66.51264191, 17.98486137],
              [-66.51291656, 17.98486137],
              [-66.51291656, 17.9845829],
              [-66.51374817, 17.9845829],
              [-66.51374817, 17.98430443],
              [-66.51430511, 17.98430443],
              [-66.51430511, 17.98402786],
              [-66.51486206, 17.98402786],
              [-66.51486206, 17.98374939],
              [-66.51569366, 17.98374939],
              [-66.51569366, 17.98347282],
              [-66.51625061, 17.98347282],
              [-66.51625061, 17.98319435],
              [-66.51680756, 17.98319435],
              [-66.51680756, 17.98291588],
              [-66.51791382, 17.98291588],
              [-66.51791382, 17.98263931],
              [-66.51847076, 17.98263931],
              [-66.51847076, 17.98236084],
              [-66.51930237, 17.98236084],
              [-66.51930237, 17.98208427],
              [-66.52069092, 17.98208427],
              [-66.52069092, 17.9818058],
              [-66.52097321, 17.9818058],
              [-66.52097321, 17.98152733],
              [-66.52236176, 17.98152733],
              [-66.52236176, 17.98097229],
              [-66.5229187, 17.98097229],
              [-66.5229187, 17.98069382],
              [-66.52375031, 17.98069382],
              [-66.52375031, 17.98041725],
              [-66.52402496, 17.98041725],
              [-66.52402496, 17.98013878],
              [-66.52513885, 17.98013878],
              [-66.52513885, 17.97986031],
              [-66.52541351, 17.97986031],
              [-66.52541351, 17.97958374],
              [-66.52625275, 17.97958374],
              [-66.52625275, 17.97930527],
              [-66.52680206, 17.97930527],
              [-66.52680206, 17.9790287],
              [-66.52735901, 17.9790287],
              [-66.52735901, 17.97875023],
              [-66.52791595, 17.97875023],
              [-66.52791595, 17.97847176],
              [-66.52902985, 17.97847176],
              [-66.52902985, 17.97819519],
              [-66.52958679, 17.97819519],
              [-66.52958679, 17.97791672],
              [-66.52986145, 17.97791672],
              [-66.52986145, 17.97763824],
              [-66.53069305, 17.97763824],
              [-66.53069305, 17.97736168],
              [-66.53152466, 17.97736168],
              [-66.53152466, 17.97708321],
              [-66.5320816, 17.97708321],
              [-66.5320816, 17.97680473],
              [-66.53263855, 17.97680473],
              [-66.53263855, 17.97652817],
              [-66.53375244, 17.97652817],
              [-66.53375244, 17.97597313],
              [-66.53458405, 17.97597313],
              [-66.53458405, 17.97569466],
              [-66.53569794, 17.97569466],
              [-66.53569794, 17.97541618],
              [-66.5359726, 17.97541618],
              [-66.5359726, 17.97486115],
              [-66.53652954, 17.97486115],
              [-66.53652954, 17.97458267],
              [-66.53708649, 17.97458267],
              [-66.53708649, 17.97430611],
              [-66.5376358, 17.97430611],
              [-66.5376358, 17.97402954],
              [-66.53819275, 17.97402954],
              [-66.53819275, 17.97374725],
              [-66.53866577, 17.97374725],
              [-66.53930664, 17.97374725],
              [-66.54152679, 17.99044418],
              [-66.53600311, 17.99238586],
              [-66.53050995, 18.00101089],
              [-66.53567505, 18.01625633],
              [-66.53567505, 18.01673317],
              [-66.53754425, 18.02149582],
              [-66.53895569, 18.02578354],
              [-66.53941345, 18.02626038],
              [-66.54267883, 18.03244209],
              [-66.54222107, 18.03340149],
              [-66.53946686, 18.03580475],
              [-66.53855133, 18.03723717],
              [-66.5371933, 18.04106522],
              [-66.53765869, 18.04201508],
              [-66.5451889, 18.06869507],
              [-66.54986572, 18.07964516],
              [-66.55172729, 18.08202171],
              [-66.55543518, 18.0843811],
              [-66.55543518, 18.08486176],
              [-66.54902649, 18.09539413],
              [-66.55091095, 18.1015873],
              [-66.55091858, 18.10253906],
              [-66.55561066, 18.11587143],
              [-66.56160736, 18.11535645],
              [-66.56443787, 18.12535667],
              [-66.56539154, 18.13012314],
              [-66.56451416, 18.13871384],
              [-66.56452179, 18.13966751],
              [-66.56315613, 18.14349365],
              [-66.5622406, 18.14445305],
              [-66.55947876, 18.14590073],
              [-66.5585556, 18.14590645],
              [-66.5562439, 18.14687538],
              [-66.55258179, 18.15214539],
              [-66.54704285, 18.15313148],
              [-66.54146576, 18.14743805],
              [-66.53686523, 18.15032959],
              [-66.53041077, 18.15275192],
              [-66.52902222, 18.15275764],
              [-66.5220871, 18.15184784],
              [-66.5234375, 18.14516068],
              [-66.52664948, 18.14084816],
              [-66.52709961, 18.1389389],
              [-66.52800751, 18.13511658],
              [-66.52799988, 18.13415718],
              [-66.52845001, 18.13177109],
              [-66.52331543, 18.12226105],
              [-66.52694702, 18.10983467],
              [-66.530159, 18.10695648],
              [-66.52222443, 18.08982277],
              [-66.51804352, 18.08507919],
              [-66.51063538, 18.08177948],
              [-66.50740051, 18.08084488],
              [-66.50509644, 18.08229065],
              [-66.50234985, 18.08564186],
              [-66.50189972, 18.08755112],
              [-66.50236511, 18.08850288],
              [-66.5056076, 18.09182549],
              [-66.50515747, 18.09373856],
              [-66.50193787, 18.09566307],
              [-66.49317169, 18.09618759],
              [-66.49178314, 18.09619713],
              [-66.48947144, 18.09477425],
              [-66.48529816, 18.0919342],
              [-66.47927856, 18.08767319],
              [-66.45986938, 18.08348083],
              [-66.4524765, 18.08303642],
              [-66.4464798, 18.08402061],
              [-66.44371033, 18.0845108],
              [-66.43910217, 18.08501244],
              [-66.43724823, 18.08454132],
              [-66.43401337, 18.08360481],
              [-66.43031311, 18.08219147],
              [-66.42749786, 18.07170677],
              [-66.42978668, 18.06596756],
              [-66.43161774, 18.06357384],
              [-66.42418671, 18.05263329],
              [-66.42556, 18.05024147],
              [-66.42645264, 18.04355431],
              [-66.4269104, 18.04259872],
            ],
          ],
          [
            [
              [-66.45680237, 17.93319511],
              [-66.45680237, 17.93291855],
              [-66.45625305, 17.93291855],
              [-66.45625305, 17.93263626],
              [-66.45597076, 17.93263626],
              [-66.45597076, 17.9323616],
              [-66.45569611, 17.9323616],
              [-66.45569611, 17.93208313],
              [-66.45541382, 17.93208313],
              [-66.45541382, 17.93180466],
              [-66.45513916, 17.93180466],
              [-66.45513916, 17.93152618],
              [-66.4548645, 17.93152618],
              [-66.4548645, 17.92986107],
              [-66.45458221, 17.92986107],
              [-66.45458221, 17.92930412],
              [-66.45430756, 17.92930412],
              [-66.45430756, 17.92902756],
              [-66.45402527, 17.92902756],
              [-66.45402527, 17.92847252],
              [-66.45375061, 17.92847252],
              [-66.45375061, 17.92736053],
              [-66.45402527, 17.92736053],
              [-66.45402527, 17.92625046],
              [-66.45375061, 17.92625046],
              [-66.45375061, 17.92597389],
              [-66.45319366, 17.92597389],
              [-66.45319366, 17.92569351],
              [-66.45291901, 17.92569351],
              [-66.45291901, 17.92541695],
              [-66.45319366, 17.92541695],
              [-66.45319366, 17.92486191],
              [-66.45291901, 17.92486191],
              [-66.45291901, 17.92347145],
              [-66.45319366, 17.92347145],
              [-66.45319366, 17.92319489],
              [-66.45375061, 17.92319489],
              [-66.45375061, 17.92291641],
              [-66.45541382, 17.92291641],
              [-66.45541382, 17.92319489],
              [-66.45735931, 17.92319489],
              [-66.45735931, 17.92430496],
              [-66.45708466, 17.92430496],
              [-66.45708466, 17.92597389],
              [-66.45735931, 17.92597389],
              [-66.45735931, 17.92736053],
              [-66.4576416, 17.92736053],
              [-66.4576416, 17.92791748],
              [-66.45791626, 17.92791748],
              [-66.45791626, 17.92819214],
              [-66.45819092, 17.92819214],
              [-66.45819092, 17.9295826],
              [-66.45847321, 17.9295826],
              [-66.45847321, 17.93013954],
              [-66.45874786, 17.93013954],
              [-66.45874786, 17.93180466],
              [-66.45903015, 17.93180466],
              [-66.45903015, 17.93208313],
              [-66.45930481, 17.93208313],
              [-66.45930481, 17.93319511],
              [-66.45680237, 17.93319511],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 39,
        name: 'Juana Díaz',
        hasc_1: 'PR.JD',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.56539154, 17.92291641, -66.42127228, 18.15313148],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.40',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-65.92847443, 18.14281082],
              [-65.92985535, 18.15568542],
              [-65.93215942, 18.16141129],
              [-65.93122864, 18.16809082],
              [-65.92752838, 18.17715073],
              [-65.92797852, 18.18907166],
              [-65.92935944, 18.19717979],
              [-65.93582916, 18.19813728],
              [-65.93721771, 18.1995697],
              [-65.93860626, 18.20100212],
              [-65.94691467, 18.20911407],
              [-65.94784546, 18.21006966],
              [-65.95199585, 18.21579361],
              [-65.95106506, 18.22914314],
              [-65.94135284, 18.23199844],
              [-65.93950653, 18.23390579],
              [-65.94274139, 18.23867416],
              [-65.94782257, 18.24154282],
              [-65.94921112, 18.24392319],
              [-65.94643402, 18.25250626],
              [-65.93625641, 18.25917435],
              [-65.93254852, 18.2639389],
              [-65.92745972, 18.26822281],
              [-65.92422485, 18.26917458],
              [-65.91867065, 18.27012634],
              [-65.91635895, 18.26869011],
              [-65.91127777, 18.26534843],
              [-65.9029541, 18.26343727],
              [-65.89648438, 18.26104546],
              [-65.89231873, 18.26151848],
              [-65.88908386, 18.26151276],
              [-65.88677216, 18.26008224],
              [-65.885849, 18.25960159],
              [-65.88030243, 18.25673294],
              [-65.8738327, 18.25243568],
              [-65.86920166, 18.25290489],
              [-65.86782074, 18.25338173],
              [-65.86642456, 18.2557621],
              [-65.86180115, 18.25718307],
              [-65.85255432, 18.25478935],
              [-65.85348511, 18.2528801],
              [-65.85302734, 18.25049782],
              [-65.85210419, 18.24811172],
              [-65.85812378, 18.24192238],
              [-65.86553955, 18.22810745],
              [-65.86739349, 18.22381783],
              [-65.86878967, 18.2195282],
              [-65.87480164, 18.21572113],
              [-65.88266754, 18.20810318],
              [-65.88776398, 18.19857025],
              [-65.89608002, 18.20239258],
              [-65.89885712, 18.19572067],
              [-65.90209961, 18.19143295],
              [-65.902565, 18.18857384],
              [-65.9030304, 18.18475914],
              [-65.90673065, 18.17999268],
              [-65.91043091, 18.17474937],
              [-65.91413879, 18.16903114],
              [-65.91459656, 18.16807747],
              [-65.9164505, 18.16235542],
              [-65.91876221, 18.15710831],
              [-65.92061615, 18.15377045],
              [-65.92847443, 18.14281082],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 40,
        name: 'Juncos',
        hasc_1: 'PR.JC',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-65.95199585, 18.14281082, -65.85210419, 18.27012634],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.41',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.98180389, 17.9517498],
              [-66.98180389, 17.95319366],
              [-66.98208618, 17.95319366],
              [-66.98208618, 17.95347214],
              [-66.9826355, 17.95347214],
              [-66.9826355, 17.95375061],
              [-66.98236084, 17.95375061],
              [-66.98236084, 17.95402718],
              [-66.9826355, 17.95402718],
              [-66.9826355, 17.95486069],
              [-66.98236084, 17.95486069],
              [-66.98236084, 17.95541573],
              [-66.9826355, 17.95541573],
              [-66.9826355, 17.95680428],
              [-66.98291779, 17.95680428],
              [-66.98291779, 17.95819473],
              [-66.9826355, 17.95819473],
              [-66.9826355, 17.95874977],
              [-66.98236084, 17.95874977],
              [-66.98236084, 17.95902634],
              [-66.98208618, 17.95902634],
              [-66.98208618, 17.95930481],
              [-66.98152924, 17.95930481],
              [-66.98152924, 17.95958328],
              [-66.98180389, 17.95958328],
              [-66.98180389, 17.96013641],
              [-66.98208618, 17.96013641],
              [-66.98208618, 17.96069527],
              [-66.98236084, 17.96069527],
              [-66.98236084, 17.96097183],
              [-66.98291779, 17.96097183],
              [-66.98291779, 17.96125031],
              [-66.98402405, 17.96125031],
              [-66.98402405, 17.96097183],
              [-66.98458099, 17.96097183],
              [-66.98458099, 17.96069527],
              [-66.98513794, 17.96069527],
              [-66.98513794, 17.96097183],
              [-66.98542023, 17.96097183],
              [-66.98542023, 17.96152878],
              [-66.98513794, 17.96152878],
              [-66.98513794, 17.96180534],
              [-66.98458099, 17.96180534],
              [-66.98458099, 17.96208382],
              [-66.98430634, 17.96208382],
              [-66.98430634, 17.96235847],
              [-66.98458099, 17.96235847],
              [-66.98458099, 17.96319389],
              [-66.98430634, 17.96319389],
              [-66.98430634, 17.9640274],
              [-66.98458099, 17.9640274],
              [-66.98458099, 17.96430588],
              [-66.98513794, 17.96430588],
              [-66.98513794, 17.9640274],
              [-66.98542023, 17.9640274],
              [-66.98542023, 17.96375084],
              [-66.98513794, 17.96375084],
              [-66.98513794, 17.96347237],
              [-66.98542023, 17.96347237],
              [-66.98542023, 17.96319389],
              [-66.98596954, 17.96319389],
              [-66.98596954, 17.96291733],
              [-66.98652649, 17.96291733],
              [-66.98652649, 17.96235847],
              [-66.98708344, 17.96235847],
              [-66.98708344, 17.96291733],
              [-66.98735809, 17.96291733],
              [-66.98735809, 17.9640274],
              [-66.98680878, 17.9640274],
              [-66.98680878, 17.96430588],
              [-66.98652649, 17.96430588],
              [-66.98652649, 17.96486282],
              [-66.98596954, 17.96486282],
              [-66.98596954, 17.96513939],
              [-66.98569489, 17.96513939],
              [-66.98569489, 17.96541595],
              [-66.98542023, 17.96541595],
              [-66.98542023, 17.96569443],
              [-66.98513794, 17.96569443],
              [-66.98513794, 17.96708488],
              [-66.98542023, 17.96708488],
              [-66.98542023, 17.96736145],
              [-66.98569489, 17.96736145],
              [-66.98569489, 17.96791649],
              [-66.98596954, 17.96791649],
              [-66.98596954, 17.96819305],
              [-66.98625183, 17.96819305],
              [-66.98625183, 17.96847153],
              [-66.98652649, 17.96847153],
              [-66.98652649, 17.96902847],
              [-66.98680878, 17.96902847],
              [-66.98680878, 17.96930313],
              [-66.98708344, 17.96930313],
              [-66.98708344, 17.96958351],
              [-66.98735809, 17.96958351],
              [-66.98735809, 17.96986198],
              [-66.98764038, 17.96986198],
              [-66.98764038, 17.97013855],
              [-66.98791504, 17.97013855],
              [-66.98791504, 17.97041512],
              [-66.98847198, 17.97041512],
              [-66.98847198, 17.97069359],
              [-66.98930359, 17.97069359],
              [-66.98930359, 17.97097206],
              [-66.99153137, 17.97097206],
              [-66.99153137, 17.97041512],
              [-66.99180603, 17.97041512],
              [-66.99180603, 17.97013855],
              [-66.99208069, 17.97013855],
              [-66.99208069, 17.96986198],
              [-66.99236298, 17.96986198],
              [-66.99236298, 17.96958351],
              [-66.99263763, 17.96958351],
              [-66.99263763, 17.96986198],
              [-66.99291992, 17.96986198],
              [-66.99291992, 17.97013855],
              [-66.99319458, 17.97013855],
              [-66.99319458, 17.97152519],
              [-66.99346924, 17.97152519],
              [-66.99346924, 17.97180557],
              [-66.99402618, 17.97180557],
              [-66.99402618, 17.97152519],
              [-66.99430847, 17.97152519],
              [-66.99430847, 17.97125053],
              [-66.99458313, 17.97125053],
              [-66.99458313, 17.97013855],
              [-66.99597168, 17.97013855],
              [-66.99597168, 17.97041512],
              [-66.99624634, 17.97041512],
              [-66.99624634, 17.97097206],
              [-66.99597168, 17.97097206],
              [-66.99597168, 17.97236061],
              [-66.99652863, 17.97236061],
              [-66.99652863, 17.97208595],
              [-66.99680328, 17.97208595],
              [-66.99680328, 17.97180557],
              [-66.99708557, 17.97180557],
              [-66.99708557, 17.97041512],
              [-66.99847412, 17.97041512],
              [-66.99847412, 17.97013855],
              [-66.99874878, 17.97013855],
              [-66.99874878, 17.96986198],
              [-66.99930573, 17.96986198],
              [-66.99930573, 17.96958351],
              [-66.99958038, 17.96958351],
              [-66.99958038, 17.97097206],
              [-66.99986267, 17.97097206],
              [-66.99986267, 17.97180557],
              [-67.00013733, 17.97180557],
              [-67.00041962, 17.97180557],
              [-67.00041962, 17.97152519],
              [-67.00069427, 17.97152519],
              [-67.00069427, 17.97125053],
              [-67.00125122, 17.97125053],
              [-67.00125122, 17.97097206],
              [-67.00180817, 17.97097206],
              [-67.00180817, 17.97069359],
              [-67.00235748, 17.97069359],
              [-67.00235748, 17.97041512],
              [-67.00263977, 17.97041512],
              [-67.00263977, 17.97069359],
              [-67.00347137, 17.97069359],
              [-67.00347137, 17.97097206],
              [-67.00375366, 17.97097206],
              [-67.00375366, 17.96986198],
              [-67.00319672, 17.96986198],
              [-67.00319672, 17.96902847],
              [-67.00263977, 17.96902847],
              [-67.00263977, 17.96930313],
              [-67.00125122, 17.96930313],
              [-67.00125122, 17.96902847],
              [-67.00069427, 17.96902847],
              [-67.00069427, 17.96763802],
              [-67.00096893, 17.96763802],
              [-67.00096893, 17.96708488],
              [-67.00180817, 17.96708488],
              [-67.00180817, 17.96652794],
              [-67.00235748, 17.96652794],
              [-67.00235748, 17.96708488],
              [-67.00263977, 17.96708488],
              [-67.00263977, 17.96736145],
              [-67.00319672, 17.96736145],
              [-67.00319672, 17.96763802],
              [-67.00347137, 17.96763802],
              [-67.00347137, 17.96708488],
              [-67.00375366, 17.96708488],
              [-67.00375366, 17.96680641],
              [-67.00347137, 17.96680641],
              [-67.00347137, 17.96652794],
              [-67.00319672, 17.96652794],
              [-67.00319672, 17.96624947],
              [-67.00291443, 17.96624947],
              [-67.00291443, 17.9659729],
              [-67.00235748, 17.9659729],
              [-67.00235748, 17.96569443],
              [-67.00208282, 17.96569443],
              [-67.00208282, 17.96541595],
              [-67.00152588, 17.96541595],
              [-67.00152588, 17.96486282],
              [-67.00125122, 17.96486282],
              [-67.00125122, 17.96430588],
              [-67.00096893, 17.96430588],
              [-67.00096893, 17.9640274],
              [-67.00069427, 17.9640274],
              [-67.00069427, 17.96319389],
              [-67.00041962, 17.96319389],
              [-67.00041962, 17.96208382],
              [-67.00069427, 17.96208382],
              [-67.00069427, 17.96125031],
              [-67.00096893, 17.96125031],
              [-67.00096893, 17.96097183],
              [-67.00152588, 17.96097183],
              [-67.00152588, 17.96069527],
              [-67.00180817, 17.96069527],
              [-67.00180817, 17.9604187],
              [-67.00208282, 17.9604187],
              [-67.00208282, 17.96013641],
              [-67.00319672, 17.96013641],
              [-67.00319672, 17.9604187],
              [-67.00375366, 17.9604187],
              [-67.00375366, 17.96069527],
              [-67.00402832, 17.96069527],
              [-67.00402832, 17.96097183],
              [-67.00458527, 17.96097183],
              [-67.00458527, 17.96125031],
              [-67.00541687, 17.96125031],
              [-67.00541687, 17.96152878],
              [-67.00569153, 17.96152878],
              [-67.00569153, 17.96180534],
              [-67.00624847, 17.96180534],
              [-67.00624847, 17.96319389],
              [-67.00680542, 17.96319389],
              [-67.00680542, 17.96347237],
              [-67.00708008, 17.96347237],
              [-67.00708008, 17.96375084],
              [-67.00736237, 17.96375084],
              [-67.00736237, 17.9640274],
              [-67.00763702, 17.9640274],
              [-67.00763702, 17.96430588],
              [-67.00819397, 17.96430588],
              [-67.00819397, 17.96458244],
              [-67.00875092, 17.96458244],
              [-67.00875092, 17.96430588],
              [-67.00902557, 17.96430588],
              [-67.00902557, 17.96347237],
              [-67.00875092, 17.96347237],
              [-67.00875092, 17.96097183],
              [-67.00902557, 17.96097183],
              [-67.00902557, 17.9604187],
              [-67.00930786, 17.9604187],
              [-67.00930786, 17.96069527],
              [-67.00986481, 17.96069527],
              [-67.00986481, 17.96097183],
              [-67.01013947, 17.96097183],
              [-67.01013947, 17.96235847],
              [-67.00986481, 17.96235847],
              [-67.00986481, 17.96264076],
              [-67.01013947, 17.96264076],
              [-67.01013947, 17.96319389],
              [-67.01041412, 17.96319389],
              [-67.01041412, 17.96430588],
              [-67.01069641, 17.96430588],
              [-67.01069641, 17.96458244],
              [-67.01041412, 17.96458244],
              [-67.01041412, 17.96486282],
              [-67.01069641, 17.96486282],
              [-67.01069641, 17.96513939],
              [-67.01097107, 17.96513939],
              [-67.01097107, 17.96541595],
              [-67.01125336, 17.96541595],
              [-67.01125336, 17.9659729],
              [-67.01180267, 17.9659729],
              [-67.01180267, 17.96624947],
              [-67.01235962, 17.96624947],
              [-67.01235962, 17.96680641],
              [-67.01319122, 17.96680641],
              [-67.01319122, 17.96708488],
              [-67.01374817, 17.96708488],
              [-67.01374817, 17.96736145],
              [-67.01430511, 17.96736145],
              [-67.01430511, 17.96791649],
              [-67.01486206, 17.96791649],
              [-67.01486206, 17.96847153],
              [-67.01541901, 17.96847153],
              [-67.01541901, 17.96930313],
              [-67.01513672, 17.96930313],
              [-67.01513672, 17.96986198],
              [-67.01486206, 17.96986198],
              [-67.01486206, 17.97013855],
              [-67.01457977, 17.97013855],
              [-67.01457977, 17.97069359],
              [-67.01374817, 17.97069359],
              [-67.01374817, 17.97097206],
              [-67.01291656, 17.97097206],
              [-67.01291656, 17.97125053],
              [-67.01180267, 17.97125053],
              [-67.01180267, 17.97152519],
              [-67.01125336, 17.97152519],
              [-67.01125336, 17.97263718],
              [-67.01152802, 17.97263718],
              [-67.01152802, 17.97291946],
              [-67.01235962, 17.97291946],
              [-67.01235962, 17.97319412],
              [-67.01264191, 17.97319412],
              [-67.01264191, 17.97291946],
              [-67.01319122, 17.97291946],
              [-67.01319122, 17.97319412],
              [-67.01430511, 17.97319412],
              [-67.01430511, 17.9734726],
              [-67.01541901, 17.9734726],
              [-67.01541901, 17.97319412],
              [-67.01597595, 17.97319412],
              [-67.01597595, 17.97291946],
              [-67.01625061, 17.97291946],
              [-67.01625061, 17.97236061],
              [-67.01597595, 17.97236061],
              [-67.01597595, 17.97208595],
              [-67.01625061, 17.97208595],
              [-67.01625061, 17.97069359],
              [-67.01597595, 17.97069359],
              [-67.01597595, 17.96986198],
              [-67.01625061, 17.96986198],
              [-67.01625061, 17.96930313],
              [-67.01652527, 17.96930313],
              [-67.01652527, 17.96902847],
              [-67.01680756, 17.96902847],
              [-67.01680756, 17.96875],
              [-67.01847076, 17.96875],
              [-67.01847076, 17.96819305],
              [-67.01875305, 17.96819305],
              [-67.01875305, 17.96791649],
              [-67.01902771, 17.96791649],
              [-67.01902771, 17.96736145],
              [-67.02124786, 17.96736145],
              [-67.02124786, 17.96763802],
              [-67.0229187, 17.96763802],
              [-67.0229187, 17.96819305],
              [-67.02319336, 17.96819305],
              [-67.02319336, 17.96875],
              [-67.02347565, 17.96875],
              [-67.02347565, 17.96958351],
              [-67.02375031, 17.96958351],
              [-67.02375031, 17.97013855],
              [-67.02402496, 17.97013855],
              [-67.02402496, 17.97041512],
              [-67.02458191, 17.97041512],
              [-67.02458191, 17.97069359],
              [-67.02513885, 17.97069359],
              [-67.02513885, 17.97125053],
              [-67.02625275, 17.97125053],
              [-67.02625275, 17.97152519],
              [-67.0265274, 17.97152519],
              [-67.0265274, 17.97180557],
              [-67.02735901, 17.97180557],
              [-67.02735901, 17.97152519],
              [-67.02874756, 17.97152519],
              [-67.02874756, 17.97097206],
              [-67.02902985, 17.97097206],
              [-67.02902985, 17.97125053],
              [-67.03097534, 17.97125053],
              [-67.03097534, 17.97152519],
              [-67.03125, 17.97152519],
              [-67.03125, 17.97125053],
              [-67.0320816, 17.97125053],
              [-67.0320816, 17.97097206],
              [-67.03263855, 17.97097206],
              [-67.03263855, 17.97069359],
              [-67.03375244, 17.97069359],
              [-67.03375244, 17.97125053],
              [-67.03430176, 17.97125053],
              [-67.03430176, 17.97152519],
              [-67.0348587, 17.97152519],
              [-67.0348587, 17.97263718],
              [-67.03514099, 17.97263718],
              [-67.03514099, 17.97291946],
              [-67.03541565, 17.97291946],
              [-67.03541565, 17.97319412],
              [-67.03624725, 17.97319412],
              [-67.03624725, 17.97291946],
              [-67.03652954, 17.97291946],
              [-67.03652954, 17.97263718],
              [-67.03736115, 17.97263718],
              [-67.03736115, 17.97236061],
              [-67.03791809, 17.97236061],
              [-67.03791809, 17.97208595],
              [-67.03847504, 17.97208595],
              [-67.03847504, 17.97180557],
              [-67.03902435, 17.97180557],
              [-67.03902435, 17.97152519],
              [-67.03930664, 17.97152519],
              [-67.03930664, 17.97097206],
              [-67.0395813, 17.97097206],
              [-67.0395813, 17.97069359],
              [-67.04069519, 17.97069359],
              [-67.04069519, 17.97041512],
              [-67.04096985, 17.97041512],
              [-67.04096985, 17.96986198],
              [-67.04125214, 17.96986198],
              [-67.04125214, 17.96958351],
              [-67.04152679, 17.96958351],
              [-67.04152679, 17.96902847],
              [-67.04180908, 17.96902847],
              [-67.04180908, 17.96875],
              [-67.04208374, 17.96875],
              [-67.04208374, 17.96847153],
              [-67.04264069, 17.96847153],
              [-67.04264069, 17.96819305],
              [-67.04291534, 17.96819305],
              [-67.04291534, 17.96791649],
              [-67.04319763, 17.96791649],
              [-67.04319763, 17.96763802],
              [-67.04430389, 17.96763802],
              [-67.04430389, 17.96791649],
              [-67.0451355, 17.96791649],
              [-67.0451355, 17.96819305],
              [-67.04569244, 17.96819305],
              [-67.04569244, 17.96847153],
              [-67.04597473, 17.96847153],
              [-67.04597473, 17.96875],
              [-67.04624939, 17.96875],
              [-67.04624939, 17.96902847],
              [-67.04652405, 17.96902847],
              [-67.04652405, 17.96986198],
              [-67.04708099, 17.96986198],
              [-67.04708099, 17.97013855],
              [-67.04792023, 17.97013855],
              [-67.04792023, 17.97041512],
              [-67.04819489, 17.97041512],
              [-67.04819489, 17.97069359],
              [-67.04875183, 17.97069359],
              [-67.04875183, 17.97097206],
              [-67.04985809, 17.97097206],
              [-67.04985809, 17.97069359],
              [-67.05041504, 17.97069359],
              [-67.05041504, 17.97041512],
              [-67.05124664, 17.97041512],
              [-67.05124664, 17.97097206],
              [-67.05152893, 17.97097206],
              [-67.05152893, 17.97125053],
              [-67.05180359, 17.97125053],
              [-67.05180359, 17.97152519],
              [-67.05208588, 17.97152519],
              [-67.05208588, 17.97180557],
              [-67.05263519, 17.97180557],
              [-67.05263519, 17.97208595],
              [-67.05291748, 17.97208595],
              [-67.05291748, 17.97236061],
              [-67.05430603, 17.97236061],
              [-67.05430603, 17.97208595],
              [-67.05458069, 17.97208595],
              [-67.05458069, 17.97180557],
              [-67.05486298, 17.97180557],
              [-67.05486298, 17.97208595],
              [-67.05513763, 17.97208595],
              [-67.05513763, 17.97180557],
              [-67.05596924, 17.97180557],
              [-67.05596924, 17.97125053],
              [-67.05708313, 17.97125053],
              [-67.05708313, 17.97180557],
              [-67.05764008, 17.97180557],
              [-67.05764008, 17.97208595],
              [-67.05791473, 17.97208595],
              [-67.05791473, 17.97236061],
              [-67.05819702, 17.97236061],
              [-67.05819702, 17.97263718],
              [-67.05874634, 17.97263718],
              [-67.05874634, 17.97291946],
              [-67.05902863, 17.97291946],
              [-67.05902863, 17.97319412],
              [-67.05958557, 17.97319412],
              [-67.05958557, 17.9734726],
              [-67.06014252, 17.9734726],
              [-67.06014252, 17.97402954],
              [-67.06041718, 17.97402954],
              [-67.06041718, 17.97430611],
              [-67.06069183, 17.97430611],
              [-67.06069183, 17.97458267],
              [-67.06097412, 17.97458267],
              [-67.06097412, 17.97486115],
              [-67.06153107, 17.97486115],
              [-67.06153107, 17.97458267],
              [-67.06180573, 17.97458267],
              [-67.06180573, 17.97430611],
              [-67.06208038, 17.97430611],
              [-67.06208038, 17.97402954],
              [-67.06236267, 17.97402954],
              [-67.06236267, 17.97374725],
              [-67.06291962, 17.97374725],
              [-67.06291962, 17.9734726],
              [-67.06346893, 17.9734726],
              [-67.06346893, 17.97319412],
              [-67.06430817, 17.97319412],
              [-67.06430817, 17.97291946],
              [-67.06541443, 17.97291946],
              [-67.06541443, 17.97236061],
              [-67.06569672, 17.97236061],
              [-67.06569672, 17.97208595],
              [-67.06625366, 17.97208595],
              [-67.06625366, 17.97180557],
              [-67.06735992, 17.97180557],
              [-67.06735992, 17.97152519],
              [-67.06791687, 17.97152519],
              [-67.06791687, 17.97125053],
              [-67.06847382, 17.97125053],
              [-67.06847382, 17.97097206],
              [-67.06986237, 17.97097206],
              [-67.06986237, 17.97152519],
              [-67.07013702, 17.97152519],
              [-67.07013702, 17.97236061],
              [-67.07041931, 17.97236061],
              [-67.07041931, 17.97263718],
              [-67.07208252, 17.97263718],
              [-67.07208252, 17.97180557],
              [-67.07236481, 17.97180557],
              [-67.07236481, 17.97125053],
              [-67.07291412, 17.97125053],
              [-67.07291412, 17.97097206],
              [-67.07319641, 17.97097206],
              [-67.07319641, 17.96986198],
              [-67.07375336, 17.96986198],
              [-67.07375336, 17.96902847],
              [-67.07402802, 17.96902847],
              [-67.07402802, 17.96875],
              [-67.07458496, 17.96875],
              [-67.07458496, 17.96847153],
              [-67.07485962, 17.96847153],
              [-67.07485962, 17.96819305],
              [-67.07514191, 17.96819305],
              [-67.07514191, 17.96791649],
              [-67.07569122, 17.96791649],
              [-67.07569122, 17.96763802],
              [-67.07597351, 17.96763802],
              [-67.07597351, 17.96736145],
              [-67.07680511, 17.96736145],
              [-67.07680511, 17.96708488],
              [-67.07707977, 17.96708488],
              [-67.07707977, 17.96680641],
              [-67.07763672, 17.96680641],
              [-67.07763672, 17.96652794],
              [-67.07791901, 17.96652794],
              [-67.07791901, 17.9659729],
              [-67.07819366, 17.9659729],
              [-67.07819366, 17.96569443],
              [-67.07847595, 17.96569443],
              [-67.07847595, 17.96430588],
              [-67.07819366, 17.96430588],
              [-67.07819366, 17.9640274],
              [-67.07791901, 17.9640274],
              [-67.07791901, 17.96375084],
              [-67.07707977, 17.96375084],
              [-67.07707977, 17.96347237],
              [-67.07653046, 17.96347237],
              [-67.07653046, 17.96264076],
              [-67.07624817, 17.96264076],
              [-67.07624817, 17.96180534],
              [-67.07653046, 17.96180534],
              [-67.07653046, 17.96069527],
              [-67.07680511, 17.96069527],
              [-67.07680511, 17.95930481],
              [-67.07707977, 17.95930481],
              [-67.07707977, 17.95874977],
              [-67.07736206, 17.95874977],
              [-67.07736206, 17.9584713],
              [-67.07763672, 17.9584713],
              [-67.07763672, 17.95819473],
              [-67.07819366, 17.95819473],
              [-67.07819366, 17.95791435],
              [-67.07902527, 17.95791435],
              [-67.07902527, 17.95819473],
              [-67.07958221, 17.95819473],
              [-67.07958221, 17.9584713],
              [-67.07930756, 17.9584713],
              [-67.07930756, 17.95958328],
              [-67.08041382, 17.95958328],
              [-67.08041382, 17.95930481],
              [-67.08069611, 17.95930481],
              [-67.08069611, 17.95902634],
              [-67.08152771, 17.95902634],
              [-67.08152771, 17.95930481],
              [-67.08208466, 17.95930481],
              [-67.08208466, 17.95958328],
              [-67.08235931, 17.95958328],
              [-67.08235931, 17.96013641],
              [-67.08319092, 17.96013641],
              [-67.08319092, 17.9604187],
              [-67.08374786, 17.9604187],
              [-67.08374786, 17.96069527],
              [-67.08403015, 17.96069527],
              [-67.08403015, 17.9604187],
              [-67.08680725, 17.9604187],
              [-67.08680725, 17.96069527],
              [-67.08708191, 17.96069527],
              [-67.08708191, 17.96125031],
              [-67.08680725, 17.96125031],
              [-67.08680725, 17.96152878],
              [-67.08569336, 17.96152878],
              [-67.08569336, 17.96125031],
              [-67.08486176, 17.96125031],
              [-67.08486176, 17.96097183],
              [-67.08403015, 17.96097183],
              [-67.08403015, 17.96235847],
              [-67.08430481, 17.96235847],
              [-67.08430481, 17.96319389],
              [-67.08403015, 17.96319389],
              [-67.08403015, 17.96375084],
              [-67.08374786, 17.96375084],
              [-67.08374786, 17.9640274],
              [-67.08291626, 17.9640274],
              [-67.08291626, 17.96430588],
              [-67.08235931, 17.96430588],
              [-67.08235931, 17.96513939],
              [-67.0826416, 17.96513939],
              [-67.0826416, 17.96569443],
              [-67.08291626, 17.96569443],
              [-67.08291626, 17.96624947],
              [-67.08374786, 17.96624947],
              [-67.08374786, 17.9659729],
              [-67.08403015, 17.9659729],
              [-67.08403015, 17.96569443],
              [-67.0854187, 17.96569443],
              [-67.0854187, 17.96541595],
              [-67.08569336, 17.96541595],
              [-67.08569336, 17.96513939],
              [-67.08625031, 17.96513939],
              [-67.08625031, 17.96486282],
              [-67.08680725, 17.96486282],
              [-67.08680725, 17.96513939],
              [-67.0873642, 17.96513939],
              [-67.0873642, 17.96486282],
              [-67.08763885, 17.96486282],
              [-67.08763885, 17.96430588],
              [-67.0873642, 17.96430588],
              [-67.0873642, 17.9640274],
              [-67.08708191, 17.9640274],
              [-67.08708191, 17.96375084],
              [-67.08625031, 17.96375084],
              [-67.08625031, 17.96347237],
              [-67.0854187, 17.96347237],
              [-67.0854187, 17.96319389],
              [-67.08569336, 17.96319389],
              [-67.08569336, 17.96264076],
              [-67.08597565, 17.96264076],
              [-67.08597565, 17.96235847],
              [-67.08680725, 17.96235847],
              [-67.08680725, 17.96264076],
              [-67.08791351, 17.96264076],
              [-67.08791351, 17.96235847],
              [-67.0881958, 17.96235847],
              [-67.0881958, 17.96180534],
              [-67.08791351, 17.96180534],
              [-67.08791351, 17.96125031],
              [-67.08763885, 17.96125031],
              [-67.08763885, 17.96069527],
              [-67.0873642, 17.96069527],
              [-67.0873642, 17.95930481],
              [-67.08708191, 17.95930481],
              [-67.08708191, 17.9584713],
              [-67.0873642, 17.9584713],
              [-67.0873642, 17.95791435],
              [-67.08763885, 17.95791435],
              [-67.08763885, 17.95763969],
              [-67.08791351, 17.95763969],
              [-67.08791351, 17.95680428],
              [-67.0881958, 17.95680428],
              [-67.0881958, 17.95541573],
              [-67.08847046, 17.95541573],
              [-67.08847046, 17.95375061],
              [-67.08875275, 17.95375061],
              [-67.08875275, 17.95263863],
              [-67.0890274, 17.95263863],
              [-67.0890274, 17.95208359],
              [-67.08930206, 17.95208359],
              [-67.08930206, 17.95180511],
              [-67.08958435, 17.95180511],
              [-67.08958435, 17.95152855],
              [-67.0901413, 17.95152855],
              [-67.0901413, 17.9509716],
              [-67.09069824, 17.9509716],
              [-67.09069824, 17.95125008],
              [-67.09124756, 17.95125008],
              [-67.09124756, 17.95152855],
              [-67.09152985, 17.95152855],
              [-67.09152985, 17.95180511],
              [-67.0918045, 17.95180511],
              [-67.0918045, 17.95236206],
              [-67.09236145, 17.95236206],
              [-67.09236145, 17.95263863],
              [-67.09263611, 17.95263863],
              [-67.09263611, 17.95236206],
              [-67.0929184, 17.95236206],
              [-67.0929184, 17.95125008],
              [-67.09319305, 17.95125008],
              [-67.09319305, 17.9509716],
              [-67.09347534, 17.9509716],
              [-67.09347534, 17.95069504],
              [-67.09375, 17.95069504],
              [-67.09375, 17.95041656],
              [-67.09402466, 17.95041656],
              [-67.09402466, 17.95013809],
              [-67.0945816, 17.95013809],
              [-67.0945816, 17.95041656],
              [-67.09513855, 17.95041656],
              [-67.09513855, 17.95069504],
              [-67.0956955, 17.95069504],
              [-67.0956955, 17.95041656],
              [-67.09597015, 17.95041656],
              [-67.09597015, 17.94958305],
              [-67.09625244, 17.94958305],
              [-67.09625244, 17.94930649],
              [-67.0965271, 17.94930649],
              [-67.0965271, 17.94902992],
              [-67.09680176, 17.94902992],
              [-67.09680176, 17.94874763],
              [-67.09708405, 17.94874763],
              [-67.09708405, 17.94847298],
              [-67.0973587, 17.94847298],
              [-67.0973587, 17.9481945],
              [-67.09791565, 17.9481945],
              [-67.09791565, 17.94791794],
              [-67.0984726, 17.94791794],
              [-67.0984726, 17.94763947],
              [-67.09874725, 17.94763947],
              [-67.09874725, 17.94735909],
              [-67.09902954, 17.94735909],
              [-67.09902954, 17.94708252],
              [-67.0993042, 17.94708252],
              [-67.0993042, 17.94680786],
              [-67.09986115, 17.94680786],
              [-67.09986115, 17.94652939],
              [-67.10069275, 17.94652939],
              [-67.10069275, 17.94625092],
              [-67.10152435, 17.94625092],
              [-67.10152435, 17.94597244],
              [-67.10263824, 17.94597244],
              [-67.10263824, 17.94569397],
              [-67.10402679, 17.94569397],
              [-67.10402679, 17.9454155],
              [-67.1048584, 17.9454155],
              [-67.1048584, 17.94569397],
              [-67.10569763, 17.94569397],
              [-67.10569763, 17.9454155],
              [-67.10902405, 17.9454155],
              [-67.10902405, 17.94569397],
              [-67.10970306, 17.94569397],
              [-67.10972595, 17.94809341],
              [-67.10792542, 17.95145607],
              [-67.1065979, 17.95672226],
              [-67.10661316, 17.95767784],
              [-67.10713959, 17.96388054],
              [-67.10625458, 17.96723175],
              [-67.10580444, 17.96771431],
              [-67.10398865, 17.97060394],
              [-67.10219574, 17.97492027],
              [-67.10359192, 17.97633553],
              [-67.10775757, 17.97723961],
              [-67.10868073, 17.97770691],
              [-67.10914612, 17.97769928],
              [-67.1100769, 17.97912216],
              [-67.10936737, 17.9972744],
              [-67.10936737, 17.99774933],
              [-67.11045837, 18.01253128],
              [-67.11145782, 18.01967621],
              [-67.11335754, 18.02395439],
              [-67.11574554, 18.0315609],
              [-67.11575317, 18.03203964],
              [-67.11076355, 18.0397377],
              [-67.11357117, 18.04304314],
              [-67.11406708, 18.04637909],
              [-67.10910797, 18.05645943],
              [-67.09622955, 18.06090927],
              [-67.08977509, 18.0614624],
              [-67.0879364, 18.06148529],
              [-67.07688141, 18.06352615],
              [-67.06997681, 18.06551361],
              [-67.06119537, 18.06466103],
              [-67.05518341, 18.06329918],
              [-67.04084778, 18.06059837],
              [-67.02700806, 18.06123161],
              [-67.01821136, 18.05894661],
              [-67.00806427, 18.06048965],
              [-67.00201416, 18.05530548],
              [-66.99229431, 18.05254745],
              [-66.98352814, 18.05264282],
              [-66.98352051, 18.05216217],
              [-66.98249054, 18.04119682],
              [-66.96723175, 18.03849602],
              [-66.96029663, 18.03665924],
              [-66.95886993, 18.03237915],
              [-66.95966339, 18.01948547],
              [-66.96006775, 18.01327705],
              [-66.96047211, 18.00754547],
              [-66.96086884, 18.00085449],
              [-66.96170044, 17.99177551],
              [-66.98180389, 17.9517498],
            ],
          ],
          [
            [
              [-67.03569794, 17.96235847],
              [-67.03569794, 17.96208382],
              [-67.03541565, 17.96208382],
              [-67.03541565, 17.96125031],
              [-67.03624725, 17.96125031],
              [-67.03624725, 17.96097183],
              [-67.03652954, 17.96097183],
              [-67.03652954, 17.9604187],
              [-67.0368042, 17.9604187],
              [-67.0368042, 17.96013641],
              [-67.03708649, 17.96013641],
              [-67.03708649, 17.95986176],
              [-67.03930664, 17.95986176],
              [-67.03930664, 17.95958328],
              [-67.0395813, 17.95958328],
              [-67.0395813, 17.95930481],
              [-67.04069519, 17.95930481],
              [-67.04069519, 17.95986176],
              [-67.04096985, 17.95986176],
              [-67.04096985, 17.9604187],
              [-67.0395813, 17.9604187],
              [-67.0395813, 17.96069527],
              [-67.0376358, 17.96069527],
              [-67.0376358, 17.96097183],
              [-67.03736115, 17.96097183],
              [-67.03736115, 17.96208382],
              [-67.0368042, 17.96208382],
              [-67.0368042, 17.96235847],
              [-67.03652954, 17.96235847],
              [-67.03652954, 17.96319389],
              [-67.03624725, 17.96319389],
              [-67.03624725, 17.96347237],
              [-67.03458405, 17.96347237],
              [-67.03458405, 17.96375084],
              [-67.03347015, 17.96375084],
              [-67.03347015, 17.9640274],
              [-67.0320816, 17.9640274],
              [-67.0320816, 17.96375084],
              [-67.03236389, 17.96375084],
              [-67.03236389, 17.96347237],
              [-67.0331955, 17.96347237],
              [-67.0331955, 17.96291733],
              [-67.03347015, 17.96291733],
              [-67.03347015, 17.96264076],
              [-67.03514099, 17.96264076],
              [-67.03514099, 17.96235847],
              [-67.03569794, 17.96235847],
            ],
          ],
          [
            [
              [-66.98569489, 17.95069504],
              [-66.98569489, 17.95041656],
              [-66.98486328, 17.95041656],
              [-66.98486328, 17.95013809],
              [-66.98402405, 17.95013809],
              [-66.98402405, 17.94986153],
              [-66.98347473, 17.94986153],
              [-66.98347473, 17.94958305],
              [-66.98319244, 17.94958305],
              [-66.98319244, 17.94930649],
              [-66.9826355, 17.94930649],
              [-66.9826355, 17.94902992],
              [-66.98208618, 17.94902992],
              [-66.98208618, 17.94874763],
              [-66.98097229, 17.94874763],
              [-66.98097229, 17.94902992],
              [-66.98069763, 17.94902992],
              [-66.98069763, 17.94874763],
              [-66.98014069, 17.94874763],
              [-66.98014069, 17.94847298],
              [-66.9798584, 17.94847298],
              [-66.9798584, 17.9481945],
              [-66.97958374, 17.9481945],
              [-66.97958374, 17.94791794],
              [-66.97875214, 17.94791794],
              [-66.97875214, 17.94763947],
              [-66.97846985, 17.94763947],
              [-66.97846985, 17.94791794],
              [-66.97819519, 17.94791794],
              [-66.97819519, 17.94763947],
              [-66.97736359, 17.94763947],
              [-66.97736359, 17.94735909],
              [-66.9770813, 17.94735909],
              [-66.9770813, 17.94708252],
              [-66.97680664, 17.94708252],
              [-66.97680664, 17.94680786],
              [-66.97652435, 17.94680786],
              [-66.97652435, 17.94625092],
              [-66.97763824, 17.94625092],
              [-66.97763824, 17.94597244],
              [-66.97958374, 17.94597244],
              [-66.97958374, 17.94625092],
              [-66.98014069, 17.94625092],
              [-66.98014069, 17.94652939],
              [-66.98069763, 17.94652939],
              [-66.98069763, 17.94680786],
              [-66.98180389, 17.94680786],
              [-66.98180389, 17.94708252],
              [-66.98208618, 17.94708252],
              [-66.98208618, 17.94735909],
              [-66.98236084, 17.94735909],
              [-66.98236084, 17.94763947],
              [-66.98347473, 17.94763947],
              [-66.98347473, 17.94791794],
              [-66.98430634, 17.94791794],
              [-66.98430634, 17.9481945],
              [-66.98486328, 17.9481945],
              [-66.98486328, 17.94847298],
              [-66.98542023, 17.94847298],
              [-66.98542023, 17.94874763],
              [-66.98625183, 17.94874763],
              [-66.98625183, 17.94902992],
              [-66.98847198, 17.94902992],
              [-66.98847198, 17.94874763],
              [-66.98986053, 17.94874763],
              [-66.98986053, 17.94847298],
              [-66.99069214, 17.94847298],
              [-66.99069214, 17.9481945],
              [-66.99097443, 17.9481945],
              [-66.99097443, 17.94791794],
              [-66.99153137, 17.94791794],
              [-66.99153137, 17.94763947],
              [-66.99208069, 17.94763947],
              [-66.99208069, 17.94735909],
              [-66.99263763, 17.94735909],
              [-66.99263763, 17.94708252],
              [-66.99375153, 17.94708252],
              [-66.99375153, 17.94735909],
              [-66.99430847, 17.94735909],
              [-66.99430847, 17.94763947],
              [-66.99458313, 17.94763947],
              [-66.99458313, 17.94847298],
              [-66.99402618, 17.94847298],
              [-66.99402618, 17.94874763],
              [-66.99346924, 17.94874763],
              [-66.99346924, 17.94847298],
              [-66.99319458, 17.94847298],
              [-66.99319458, 17.9481945],
              [-66.99236298, 17.9481945],
              [-66.99236298, 17.94847298],
              [-66.99208069, 17.94847298],
              [-66.99208069, 17.94874763],
              [-66.99153137, 17.94874763],
              [-66.99153137, 17.94902992],
              [-66.99124908, 17.94902992],
              [-66.99124908, 17.94930649],
              [-66.99069214, 17.94930649],
              [-66.99069214, 17.94958305],
              [-66.99041748, 17.94958305],
              [-66.99041748, 17.94986153],
              [-66.99013519, 17.94986153],
              [-66.99013519, 17.95013809],
              [-66.98986053, 17.95013809],
              [-66.98986053, 17.95041656],
              [-66.98958588, 17.95041656],
              [-66.98958588, 17.95069504],
              [-66.98902893, 17.95069504],
              [-66.98902893, 17.95041656],
              [-66.98596954, 17.95041656],
              [-66.98596954, 17.95069504],
              [-66.98569489, 17.95069504],
            ],
          ],
          [
            [
              [-67.00180817, 17.94625092],
              [-67.00180817, 17.94569397],
              [-67.00152588, 17.94569397],
              [-67.00152588, 17.9454155],
              [-67.00125122, 17.9454155],
              [-67.00125122, 17.94430542],
              [-67.00096893, 17.94430542],
              [-67.00096893, 17.94375038],
              [-67.00069427, 17.94375038],
              [-67.00069427, 17.9426384],
              [-67.00125122, 17.9426384],
              [-67.00125122, 17.94319534],
              [-67.00319672, 17.94319534],
              [-67.00319672, 17.94291687],
              [-67.00458527, 17.94291687],
              [-67.00458527, 17.9426384],
              [-67.00485992, 17.9426384],
              [-67.00485992, 17.94236183],
              [-67.00653076, 17.94236183],
              [-67.00653076, 17.9426384],
              [-67.00680542, 17.9426384],
              [-67.00680542, 17.94291687],
              [-67.00902557, 17.94291687],
              [-67.00902557, 17.94319534],
              [-67.00930786, 17.94319534],
              [-67.00930786, 17.94291687],
              [-67.01041412, 17.94291687],
              [-67.01041412, 17.94319534],
              [-67.01069641, 17.94319534],
              [-67.01069641, 17.94347191],
              [-67.01125336, 17.94347191],
              [-67.01125336, 17.94375038],
              [-67.01152802, 17.94375038],
              [-67.01152802, 17.94402695],
              [-67.01208496, 17.94402695],
              [-67.01208496, 17.94347191],
              [-67.01347351, 17.94347191],
              [-67.01347351, 17.94458389],
              [-67.01291656, 17.94458389],
              [-67.01291656, 17.94486046],
              [-67.01235962, 17.94486046],
              [-67.01235962, 17.94458389],
              [-67.01125336, 17.94458389],
              [-67.01125336, 17.94430542],
              [-67.00875092, 17.94430542],
              [-67.00875092, 17.94402695],
              [-67.00846863, 17.94402695],
              [-67.00846863, 17.94375038],
              [-67.00791931, 17.94375038],
              [-67.00791931, 17.94347191],
              [-67.00763702, 17.94347191],
              [-67.00763702, 17.94375038],
              [-67.00736237, 17.94375038],
              [-67.00736237, 17.94402695],
              [-67.00458527, 17.94402695],
              [-67.00458527, 17.94430542],
              [-67.00430298, 17.94430542],
              [-67.00430298, 17.94458389],
              [-67.00347137, 17.94458389],
              [-67.00347137, 17.94402695],
              [-67.00319672, 17.94402695],
              [-67.00319672, 17.94430542],
              [-67.00291443, 17.94430542],
              [-67.00291443, 17.94458389],
              [-67.00235748, 17.94458389],
              [-67.00235748, 17.94486046],
              [-67.00263977, 17.94486046],
              [-67.00263977, 17.94625092],
              [-67.00180817, 17.94625092],
            ],
          ],
          [
            [
              [-67.03986359, 17.94041634],
              [-67.03986359, 17.94013977],
              [-67.0395813, 17.94013977],
              [-67.0395813, 17.9398613],
              [-67.03986359, 17.9398613],
              [-67.03986359, 17.93902779],
              [-67.0404129, 17.93902779],
              [-67.0404129, 17.93874931],
              [-67.0423584, 17.93874931],
              [-67.0423584, 17.93902779],
              [-67.04291534, 17.93902779],
              [-67.04291534, 17.94013977],
              [-67.04152679, 17.94013977],
              [-67.04152679, 17.9398613],
              [-67.04125214, 17.9398613],
              [-67.04125214, 17.94013977],
              [-67.04096985, 17.94013977],
              [-67.04096985, 17.94041634],
              [-67.03986359, 17.94041634],
            ],
          ],
          [
            [
              [-67.05041504, 17.93847275],
              [-67.05041504, 17.93819427],
              [-67.04958344, 17.93819427],
              [-67.04958344, 17.9379158],
              [-67.04902649, 17.9379158],
              [-67.04902649, 17.93763924],
              [-67.04875183, 17.93763924],
              [-67.04875183, 17.9370842],
              [-67.04985809, 17.9370842],
              [-67.04985809, 17.93736267],
              [-67.05069733, 17.93736267],
              [-67.05069733, 17.9370842],
              [-67.05152893, 17.9370842],
              [-67.05152893, 17.93736267],
              [-67.05180359, 17.93736267],
              [-67.05180359, 17.93819427],
              [-67.05152893, 17.93819427],
              [-67.05152893, 17.93847275],
              [-67.05041504, 17.93847275],
            ],
          ],
          [
            [
              [-67.01791382, 17.93736267],
              [-67.01791382, 17.9370842],
              [-67.01708221, 17.9370842],
              [-67.01708221, 17.93680573],
              [-67.01680756, 17.93680573],
              [-67.01680756, 17.93569374],
              [-67.01958466, 17.93569374],
              [-67.01958466, 17.93597221],
              [-67.0201416, 17.93597221],
              [-67.0201416, 17.93625069],
              [-67.02041626, 17.93625069],
              [-67.02041626, 17.9370842],
              [-67.01930237, 17.9370842],
              [-67.01930237, 17.93736267],
              [-67.01791382, 17.93736267],
            ],
          ],
          [
            [
              [-67.09708405, 17.93319511],
              [-67.09708405, 17.93291855],
              [-67.09513855, 17.93291855],
              [-67.09513855, 17.93263626],
              [-67.0945816, 17.93263626],
              [-67.0945816, 17.9323616],
              [-67.0890274, 17.9323616],
              [-67.0890274, 17.93152618],
              [-67.09041595, 17.93152618],
              [-67.09041595, 17.93124962],
              [-67.09069824, 17.93124962],
              [-67.09069824, 17.93097305],
              [-67.09236145, 17.93097305],
              [-67.09236145, 17.93152618],
              [-67.09541321, 17.93152618],
              [-67.09541321, 17.93180466],
              [-67.09597015, 17.93180466],
              [-67.09597015, 17.93208313],
              [-67.09708405, 17.93208313],
              [-67.09708405, 17.9323616],
              [-67.0973587, 17.9323616],
              [-67.0973587, 17.93263626],
              [-67.09764099, 17.93263626],
              [-67.09764099, 17.93319511],
              [-67.09708405, 17.93319511],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 41,
        name: 'Lajas',
        hasc_1: 'PR.LJ',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-67.11575317, 17.93097305, -66.95886993, 18.06551361],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.42',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.8170166, 18.230299],
              [-66.82304382, 18.23215294],
              [-66.82489777, 18.23261452],
              [-66.82766724, 18.23211288],
              [-66.83042908, 18.23113251],
              [-66.83088684, 18.23065186],
              [-66.8327179, 18.22872734],
              [-66.83318329, 18.22824669],
              [-66.83494568, 18.21821594],
              [-66.83626556, 18.2105732],
              [-66.83625793, 18.20961761],
              [-66.83612061, 18.19340897],
              [-66.83605194, 18.18530083],
              [-66.83509827, 18.18244553],
              [-66.83184814, 18.18009186],
              [-66.82720947, 18.17822647],
              [-66.82485962, 18.17347717],
              [-66.82714844, 18.1710701],
              [-66.8368454, 18.17050743],
              [-66.84425354, 18.17186928],
              [-66.84655762, 18.17137146],
              [-66.84793854, 18.17088127],
              [-66.85024261, 18.17038155],
              [-66.85858917, 18.1736412],
              [-66.86045837, 18.1755352],
              [-66.8609314, 18.17696381],
              [-66.86330414, 18.18409348],
              [-66.86377716, 18.18504524],
              [-66.86379242, 18.18695259],
              [-66.86749268, 18.18739319],
              [-66.87071991, 18.18688965],
              [-66.87488556, 18.18732452],
              [-66.87627411, 18.18730927],
              [-66.87904358, 18.18728447],
              [-66.88781738, 18.1867218],
              [-66.8952179, 18.18713188],
              [-66.897995, 18.1875782],
              [-66.90465546, 18.20849609],
              [-66.91275024, 18.23321342],
              [-66.90912628, 18.24183464],
              [-66.90731812, 18.24614334],
              [-66.90738678, 18.25329208],
              [-66.90888977, 18.26615143],
              [-66.91085815, 18.27852821],
              [-66.91001892, 18.28807259],
              [-66.91010284, 18.29664993],
              [-66.90969849, 18.30332756],
              [-66.90476227, 18.31958199],
              [-66.90477753, 18.32149124],
              [-66.90432739, 18.32292175],
              [-66.89801788, 18.34157181],
              [-66.89316559, 18.36735725],
              [-66.88529205, 18.3669548],
              [-66.88435364, 18.36601257],
              [-66.88295746, 18.36507034],
              [-66.88062286, 18.36271095],
              [-66.8768692, 18.35702705],
              [-66.86297607, 18.35620499],
              [-66.84626007, 18.35111809],
              [-66.82861328, 18.3446064],
              [-66.82397461, 18.34321976],
              [-66.82485199, 18.33749199],
              [-66.82482147, 18.33320236],
              [-66.82611847, 18.32318115],
              [-66.82743835, 18.31506729],
              [-66.82743835, 18.31459045],
              [-66.82697296, 18.31411743],
              [-66.82461548, 18.30937195],
              [-66.82952118, 18.2874012],
              [-66.83036804, 18.27786064],
              [-66.82662201, 18.27312469],
              [-66.82563782, 18.26598167],
              [-66.82606506, 18.26216316],
              [-66.82696533, 18.25929451],
              [-66.82730103, 18.24403572],
              [-66.82683563, 18.24356079],
              [-66.8170166, 18.230299],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 42,
        name: 'Lares',
        hasc_1: 'PR.LR',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.91275024, 18.17038155, -66.8170166, 18.36735725],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.43',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.897995, 18.1875782],
              [-66.91513824, 18.19218063],
              [-66.95819855, 18.19984818],
              [-66.9679718, 18.20689964],
              [-66.96889496, 18.20689011],
              [-66.97722626, 18.20775604],
              [-66.97769165, 18.20822716],
              [-66.97907257, 18.20773506],
              [-66.98922729, 18.20524216],
              [-66.9905777, 18.20189095],
              [-66.99102783, 18.20045471],
              [-66.99194336, 18.19996643],
              [-66.99239349, 18.19900894],
              [-67.00389862, 18.19363785],
              [-67.0200882, 18.19536781],
              [-67.0187912, 18.2034893],
              [-67.03316498, 18.20809937],
              [-67.0328064, 18.21811676],
              [-67.03606415, 18.21951294],
              [-67.0369873, 18.21997833],
              [-67.04582214, 18.22464752],
              [-67.05001068, 18.22746277],
              [-67.05095673, 18.22935677],
              [-67.05146027, 18.23316383],
              [-67.05147552, 18.23459435],
              [-67.05622101, 18.24550819],
              [-67.05760956, 18.24596786],
              [-67.06222534, 18.24496269],
              [-67.07381439, 18.24768829],
              [-67.07567596, 18.24861908],
              [-67.07988739, 18.25333977],
              [-67.08175659, 18.2547493],
              [-67.07810211, 18.25908279],
              [-67.06746674, 18.25920486],
              [-67.06517792, 18.26114082],
              [-67.06472015, 18.26162338],
              [-67.06204224, 18.27023506],
              [-67.06017303, 18.26882744],
              [-67.05645752, 18.2674408],
              [-67.0532074, 18.26604652],
              [-67.05181122, 18.26605988],
              [-67.04446411, 18.27091217],
              [-67.04031372, 18.27191353],
              [-67.04088593, 18.28191757],
              [-67.04090118, 18.28334808],
              [-67.03772736, 18.2895813],
              [-67.03214264, 18.28630638],
              [-67.02882385, 18.27871704],
              [-67.0279007, 18.27872658],
              [-67.0177002, 18.27693367],
              [-67.01539612, 18.2774353],
              [-67.00708008, 18.27895546],
              [-67.00569916, 18.27897453],
              [-66.99271393, 18.27577591],
              [-66.9871521, 18.27488136],
              [-66.98069, 18.27590561],
              [-66.97930145, 18.27592087],
              [-66.9783783, 18.2759304],
              [-66.97325897, 18.27407646],
              [-66.97137451, 18.27028275],
              [-66.97040558, 18.26600266],
              [-66.96992493, 18.26410103],
              [-66.96945953, 18.2636261],
              [-66.9671402, 18.26365089],
              [-66.96531677, 18.26605606],
              [-66.96165466, 18.26990891],
              [-66.95981598, 18.27135658],
              [-66.9575119, 18.27185822],
              [-66.94688416, 18.27292442],
              [-66.945961, 18.27340698],
              [-66.94549561, 18.27293777],
              [-66.94354248, 18.26198769],
              [-66.94306183, 18.2605648],
              [-66.93842316, 18.25870323],
              [-66.93749237, 18.25823784],
              [-66.92729187, 18.25547791],
              [-66.91804504, 18.25556946],
              [-66.90738678, 18.25329208],
              [-66.90731812, 18.24614334],
              [-66.90912628, 18.24183464],
              [-66.91275024, 18.23321342],
              [-66.90465546, 18.20849609],
              [-66.897995, 18.1875782],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 43,
        name: 'Las Marías',
        hasc_1: 'PR.LM',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-67.08175659, 18.1875782, -66.897995, 18.2895813],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.44',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-65.92618561, 18.11991119],
              [-65.92756653, 18.12181664],
              [-65.92571259, 18.13135338],
              [-65.92524719, 18.13422012],
              [-65.92524719, 18.13469315],
              [-65.92617035, 18.13898849],
              [-65.92847443, 18.14281082],
              [-65.92061615, 18.15377045],
              [-65.91876221, 18.15710831],
              [-65.9164505, 18.16235542],
              [-65.91459656, 18.16807747],
              [-65.91413879, 18.16903114],
              [-65.91043091, 18.17474937],
              [-65.90673065, 18.17999268],
              [-65.9030304, 18.18475914],
              [-65.902565, 18.18857384],
              [-65.90209961, 18.19143295],
              [-65.89885712, 18.19572067],
              [-65.89608002, 18.20239258],
              [-65.88776398, 18.19857025],
              [-65.88266754, 18.20810318],
              [-65.87480164, 18.21572113],
              [-65.86878967, 18.2195282],
              [-65.86739349, 18.22381783],
              [-65.86553955, 18.22810745],
              [-65.85812378, 18.24192238],
              [-65.85210419, 18.24811172],
              [-65.85302734, 18.25049782],
              [-65.85348511, 18.2528801],
              [-65.85255432, 18.25478935],
              [-65.84653473, 18.25811386],
              [-65.84051514, 18.26478004],
              [-65.84004974, 18.26525307],
              [-65.83957672, 18.27097511],
              [-65.83911133, 18.27335739],
              [-65.83633423, 18.27526283],
              [-65.83403015, 18.27287674],
              [-65.83171844, 18.27191544],
              [-65.8270874, 18.27334023],
              [-65.8243103, 18.27285576],
              [-65.82386017, 18.26903915],
              [-65.81969452, 18.26760292],
              [-65.81739044, 18.26473618],
              [-65.81554413, 18.26234818],
              [-65.81741333, 18.25234032],
              [-65.82112885, 18.24662781],
              [-65.82808685, 18.23138237],
              [-65.82901764, 18.22804642],
              [-65.83042145, 18.21851349],
              [-65.83135223, 18.21422195],
              [-65.82582855, 18.20038223],
              [-65.82584381, 18.19179726],
              [-65.82816315, 18.1870327],
              [-65.8323288, 18.18465614],
              [-65.83325195, 18.18370438],
              [-65.8351059, 18.17989159],
              [-65.83650208, 18.17512894],
              [-65.84574127, 18.17800331],
              [-65.84666443, 18.17800331],
              [-65.85405731, 18.17706299],
              [-65.85683441, 18.1732502],
              [-65.85546112, 18.16704941],
              [-65.85684967, 18.16466522],
              [-65.86194611, 18.15560913],
              [-65.86149597, 18.14559364],
              [-65.86289215, 18.14034843],
              [-65.86473846, 18.13939667],
              [-65.86750793, 18.13797188],
              [-65.86843872, 18.13606071],
              [-65.87399292, 18.12844086],
              [-65.87722015, 18.12080956],
              [-65.87769318, 18.11794853],
              [-65.88693237, 18.12177849],
              [-65.89477539, 18.1241703],
              [-65.90077972, 18.12560844],
              [-65.90631866, 18.12561607],
              [-65.90816498, 18.12561607],
              [-65.91278839, 18.12371254],
              [-65.91416931, 18.12323761],
              [-65.91694641, 18.12228394],
              [-65.91786957, 18.12228584],
              [-65.92479706, 18.12038231],
              [-65.92618561, 18.11991119],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 44,
        name: 'Las Piedras',
        hasc_1: 'PR.LP',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-65.92847443, 18.11794853, -65.81554413, 18.27526283],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.45',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-65.91909027, 18.40096855],
              [-65.91994476, 18.41119194],
              [-65.93105316, 18.42263412],
              [-65.93707275, 18.43026161],
              [-65.95004272, 18.4350338],
              [-65.95698547, 18.43360901],
              [-65.96115875, 18.43313217],
              [-65.96533203, 18.43265724],
              [-65.96625519, 18.43218231],
              [-65.96718597, 18.43265915],
              [-65.96995544, 18.43742561],
              [-65.97135162, 18.44028282],
              [-65.98061371, 18.44219017],
              [-65.98432159, 18.44266701],
              [-65.98524475, 18.44362259],
              [-65.98571014, 18.44457436],
              [-65.98571014, 18.44600296],
              [-65.98478699, 18.44981384],
              [-65.9901886, 18.45536613],
              [-65.98958588, 18.45541573],
              [-65.98958588, 18.45513725],
              [-65.98902893, 18.45513725],
              [-65.98902893, 18.45486069],
              [-65.98819733, 18.45486069],
              [-65.98819733, 18.45458412],
              [-65.98764038, 18.45458412],
              [-65.98764038, 18.45430565],
              [-65.98652649, 18.45430565],
              [-65.98652649, 18.45458412],
              [-65.98625183, 18.45458412],
              [-65.98625183, 18.45513725],
              [-65.98596954, 18.45513725],
              [-65.98596954, 18.4556942],
              [-65.98569489, 18.4556942],
              [-65.98569489, 18.45652771],
              [-65.98596954, 18.45652771],
              [-65.98596954, 18.45680618],
              [-65.98708344, 18.45680618],
              [-65.98708344, 18.45652771],
              [-65.98764038, 18.45652771],
              [-65.98764038, 18.45624733],
              [-65.98874664, 18.45624733],
              [-65.98874664, 18.45652771],
              [-65.98902893, 18.45652771],
              [-65.98902893, 18.45680618],
              [-65.98930359, 18.45680618],
              [-65.98930359, 18.45708275],
              [-65.98958588, 18.45708275],
              [-65.98958588, 18.4576416],
              [-65.98986053, 18.4576416],
              [-65.98986053, 18.45791626],
              [-65.99097443, 18.45791626],
              [-65.99097443, 18.45819473],
              [-65.99153137, 18.45819473],
              [-65.99153137, 18.45846939],
              [-65.99236298, 18.45846939],
              [-65.99236298, 18.45875168],
              [-65.99291992, 18.45875168],
              [-65.99291992, 18.45930481],
              [-65.99263763, 18.45930481],
              [-65.99263763, 18.46013832],
              [-65.99180603, 18.46013832],
              [-65.99180603, 18.46041679],
              [-65.99041748, 18.46041679],
              [-65.99041748, 18.46013832],
              [-65.99013519, 18.46013832],
              [-65.99013519, 18.45986366],
              [-65.98986053, 18.45986366],
              [-65.98986053, 18.45958328],
              [-65.98930359, 18.45958328],
              [-65.98930359, 18.45930481],
              [-65.98902893, 18.45930481],
              [-65.98902893, 18.45902824],
              [-65.98708344, 18.45902824],
              [-65.98708344, 18.45875168],
              [-65.98625183, 18.45875168],
              [-65.98625183, 18.45846939],
              [-65.98569489, 18.45846939],
              [-65.98569489, 18.45819473],
              [-65.98542023, 18.45819473],
              [-65.98542023, 18.45791626],
              [-65.98458099, 18.45791626],
              [-65.98458099, 18.4576416],
              [-65.98402405, 18.4576416],
              [-65.98402405, 18.45735931],
              [-65.98374939, 18.45735931],
              [-65.98374939, 18.45680618],
              [-65.98319244, 18.45680618],
              [-65.98319244, 18.45652771],
              [-65.98124695, 18.45652771],
              [-65.98124695, 18.45680618],
              [-65.98041534, 18.45680618],
              [-65.98041534, 18.45708275],
              [-65.98014069, 18.45708275],
              [-65.98014069, 18.45735931],
              [-65.9798584, 18.45735931],
              [-65.9798584, 18.45791626],
              [-65.97958374, 18.45791626],
              [-65.97958374, 18.45846939],
              [-65.9798584, 18.45846939],
              [-65.9798584, 18.45902824],
              [-65.98014069, 18.45902824],
              [-65.98014069, 18.45958328],
              [-65.9798584, 18.45958328],
              [-65.9798584, 18.45986366],
              [-65.9779129, 18.45986366],
              [-65.9779129, 18.45958328],
              [-65.97763824, 18.45958328],
              [-65.97763824, 18.45930481],
              [-65.97736359, 18.45930481],
              [-65.97736359, 18.45902824],
              [-65.9770813, 18.45902824],
              [-65.9770813, 18.45846939],
              [-65.97680664, 18.45846939],
              [-65.97680664, 18.45819473],
              [-65.97624969, 18.45819473],
              [-65.97624969, 18.45791626],
              [-65.97597504, 18.45791626],
              [-65.97597504, 18.4576416],
              [-65.97541809, 18.4576416],
              [-65.97541809, 18.45708275],
              [-65.9751358, 18.45708275],
              [-65.9751358, 18.45680618],
              [-65.97486115, 18.45680618],
              [-65.97486115, 18.45652771],
              [-65.9743042, 18.45652771],
              [-65.9743042, 18.45624733],
              [-65.97374725, 18.45624733],
              [-65.97374725, 18.45597267],
              [-65.9723587, 18.45597267],
              [-65.9723587, 18.4556942],
              [-65.9706955, 18.4556942],
              [-65.9706955, 18.45541573],
              [-65.96930695, 18.45541573],
              [-65.96930695, 18.45513725],
              [-65.96875, 18.45513725],
              [-65.96875, 18.45486069],
              [-65.9679184, 18.45486069],
              [-65.9679184, 18.45458412],
              [-65.96736145, 18.45458412],
              [-65.96736145, 18.45430565],
              [-65.96624756, 18.45430565],
              [-65.96624756, 18.45402718],
              [-65.96569824, 18.45402718],
              [-65.96569824, 18.45375061],
              [-65.96485901, 18.45375061],
              [-65.96485901, 18.45347214],
              [-65.9640274, 18.45347214],
              [-65.9640274, 18.45319366],
              [-65.9631958, 18.45319366],
              [-65.9631958, 18.45291519],
              [-65.96263885, 18.45291519],
              [-65.96263885, 18.45263863],
              [-65.96152496, 18.45263863],
              [-65.96152496, 18.45236206],
              [-65.96097565, 18.45236206],
              [-65.96097565, 18.45208359],
              [-65.95986176, 18.45208359],
              [-65.95986176, 18.45180511],
              [-65.9595871, 18.45180511],
              [-65.9595871, 18.45152855],
              [-65.95874786, 18.45152855],
              [-65.95874786, 18.45125008],
              [-65.9576416, 18.45125008],
              [-65.9576416, 18.4509716],
              [-65.95652771, 18.4509716],
              [-65.95652771, 18.45069695],
              [-65.95458221, 18.45069695],
              [-65.95458221, 18.45041656],
              [-65.95347595, 18.45041656],
              [-65.95347595, 18.45013809],
              [-65.95014191, 18.45013809],
              [-65.95014191, 18.44986153],
              [-65.94930267, 18.44986153],
              [-65.94930267, 18.44958305],
              [-65.94708252, 18.44958305],
              [-65.94708252, 18.44930649],
              [-65.94680786, 18.44930649],
              [-65.94680786, 18.44902802],
              [-65.94652557, 18.44902802],
              [-65.94652557, 18.44874954],
              [-65.94569397, 18.44874954],
              [-65.94569397, 18.44847488],
              [-65.94486237, 18.44847488],
              [-65.94486237, 18.4481945],
              [-65.94319153, 18.4481945],
              [-65.94319153, 18.44791603],
              [-65.94291687, 18.44791603],
              [-65.94291687, 18.44763947],
              [-65.94208527, 18.44763947],
              [-65.94208527, 18.4473629],
              [-65.94125366, 18.4473629],
              [-65.94125366, 18.44708061],
              [-65.94013977, 18.44708061],
              [-65.94013977, 18.44680595],
              [-65.93653107, 18.44680595],
              [-65.93653107, 18.44652748],
              [-65.93541718, 18.44652748],
              [-65.93541718, 18.44625282],
              [-65.93374634, 18.44625282],
              [-65.93374634, 18.44597244],
              [-65.92930603, 18.44597244],
              [-65.92930603, 18.44569206],
              [-65.92847443, 18.44569206],
              [-65.92847443, 18.4454174],
              [-65.92819214, 18.4454174],
              [-65.92819214, 18.44514084],
              [-65.92791748, 18.44514084],
              [-65.92791748, 18.4454174],
              [-65.92736053, 18.4454174],
              [-65.92736053, 18.44514084],
              [-65.92652893, 18.44514084],
              [-65.92652893, 18.44486237],
              [-65.92541504, 18.44486237],
              [-65.92541504, 18.44458389],
              [-65.92346954, 18.44458389],
              [-65.92346954, 18.44486237],
              [-65.92292023, 18.44486237],
              [-65.92292023, 18.44514084],
              [-65.92041779, 18.44514084],
              [-65.92041779, 18.44486237],
              [-65.9201355, 18.44486237],
              [-65.9201355, 18.44514084],
              [-65.91930389, 18.44514084],
              [-65.91930389, 18.44486237],
              [-65.91847229, 18.44486237],
              [-65.91847229, 18.44458389],
              [-65.91680908, 18.44458389],
              [-65.91680908, 18.44430542],
              [-65.91625214, 18.44430542],
              [-65.91625214, 18.44458389],
              [-65.9154129, 18.44458389],
              [-65.9154129, 18.44486237],
              [-65.91486359, 18.44486237],
              [-65.91486359, 18.44514084],
              [-65.91347504, 18.44514084],
              [-65.91347504, 18.4454174],
              [-65.91291809, 18.4454174],
              [-65.91291809, 18.44569206],
              [-65.91236115, 18.44569206],
              [-65.91236115, 18.44597244],
              [-65.91208649, 18.44597244],
              [-65.91208649, 18.44625282],
              [-65.9118042, 18.44625282],
              [-65.9118042, 18.44652748],
              [-65.91124725, 18.44652748],
              [-65.91124725, 18.44680595],
              [-65.90736389, 18.44680595],
              [-65.90736389, 18.44708061],
              [-65.90680695, 18.44708061],
              [-65.90680695, 18.4473629],
              [-65.90625, 18.4473629],
              [-65.90625, 18.44763947],
              [-65.90597534, 18.44763947],
              [-65.90597534, 18.44791603],
              [-65.90569305, 18.44791603],
              [-65.90569305, 18.4481945],
              [-65.9054184, 18.4481945],
              [-65.9054184, 18.44986153],
              [-65.90513611, 18.44986153],
              [-65.90513611, 18.45013809],
              [-65.90486145, 18.45013809],
              [-65.90486145, 18.45041656],
              [-65.90458679, 18.45041656],
              [-65.90458679, 18.4509716],
              [-65.90652466, 18.4509716],
              [-65.90652466, 18.45125008],
              [-65.9070816, 18.45125008],
              [-65.9070816, 18.45152855],
              [-65.90736389, 18.45152855],
              [-65.90736389, 18.45208359],
              [-65.9070816, 18.45208359],
              [-65.9070816, 18.45263863],
              [-65.90597534, 18.45263863],
              [-65.90597534, 18.45291519],
              [-65.90486145, 18.45291519],
              [-65.90486145, 18.45319366],
              [-65.9034729, 18.45319366],
              [-65.9034729, 18.45291519],
              [-65.90291595, 18.45291519],
              [-65.90291595, 18.45263863],
              [-65.90097046, 18.45263863],
              [-65.90097046, 18.45236206],
              [-65.90041351, 18.45236206],
              [-65.90041351, 18.45208359],
              [-65.90013885, 18.45208359],
              [-65.90013885, 18.45180511],
              [-65.8979187, 18.45180511],
              [-65.8979187, 18.45152855],
              [-65.89763641, 18.45152855],
              [-65.89763641, 18.45125008],
              [-65.89736176, 18.45125008],
              [-65.89736176, 18.4509716],
              [-65.8970871, 18.4509716],
              [-65.8970871, 18.45041656],
              [-65.89680481, 18.45041656],
              [-65.89680481, 18.45013809],
              [-65.89653015, 18.45013809],
              [-65.89653015, 18.44986153],
              [-65.89597321, 18.44986153],
              [-65.89597321, 18.44958305],
              [-65.89541626, 18.44958305],
              [-65.89541626, 18.44874954],
              [-65.89569092, 18.44874954],
              [-65.89569092, 18.44763947],
              [-65.89541626, 18.44763947],
              [-65.89541626, 18.4473629],
              [-65.8951416, 18.4473629],
              [-65.8951416, 18.44708061],
              [-65.89458466, 18.44708061],
              [-65.89458466, 18.44680595],
              [-65.89402771, 18.44680595],
              [-65.89402771, 18.44652748],
              [-65.89375305, 18.44652748],
              [-65.89375305, 18.44625282],
              [-65.89319611, 18.44625282],
              [-65.89319611, 18.44597244],
              [-65.89291382, 18.44597244],
              [-65.89291382, 18.44569206],
              [-65.89180756, 18.44569206],
              [-65.89180756, 18.4454174],
              [-65.89125061, 18.4454174],
              [-65.89125061, 18.44514084],
              [-65.89097595, 18.44514084],
              [-65.89097595, 18.44486237],
              [-65.89069366, 18.44486237],
              [-65.89069366, 18.44458389],
              [-65.89013672, 18.44458389],
              [-65.89013672, 18.44430542],
              [-65.88874817, 18.44430542],
              [-65.88874817, 18.44402695],
              [-65.88847351, 18.44402695],
              [-65.88847351, 18.44374847],
              [-65.88764191, 18.44374847],
              [-65.88764191, 18.44347191],
              [-65.88680267, 18.44347191],
              [-65.88680267, 18.44319534],
              [-65.88652802, 18.44319534],
              [-65.88652802, 18.44291687],
              [-65.88625336, 18.44291687],
              [-65.88625336, 18.4426384],
              [-65.88597107, 18.4426384],
              [-65.88597107, 18.44236183],
              [-65.88569641, 18.44236183],
              [-65.88569641, 18.44208336],
              [-65.88513947, 18.44208336],
              [-65.88513947, 18.44180489],
              [-65.88486481, 18.44180489],
              [-65.88486481, 18.44152641],
              [-65.88458252, 18.44152641],
              [-65.88458252, 18.44124985],
              [-65.88402557, 18.44124985],
              [-65.88402557, 18.44097137],
              [-65.88346863, 18.44097137],
              [-65.88346863, 18.44069481],
              [-65.88236237, 18.44069481],
              [-65.88236237, 18.44041634],
              [-65.88180542, 18.44041634],
              [-65.88180542, 18.44013977],
              [-65.88124847, 18.44013977],
              [-65.88124847, 18.4398613],
              [-65.88069153, 18.4398613],
              [-65.88069153, 18.43958282],
              [-65.88041687, 18.43958282],
              [-65.88041687, 18.43930626],
              [-65.87985992, 18.43930626],
              [-65.87985992, 18.43902779],
              [-65.87930298, 18.43902779],
              [-65.87930298, 18.4379158],
              [-65.87985992, 18.4379158],
              [-65.87985992, 18.43763924],
              [-65.88014221, 18.43763924],
              [-65.88014221, 18.43736076],
              [-65.88041687, 18.43736076],
              [-65.88041687, 18.43680573],
              [-65.88069153, 18.43680573],
              [-65.88069153, 18.43625069],
              [-65.88124847, 18.43625069],
              [-65.88124847, 18.43597221],
              [-65.88153076, 18.43597221],
              [-65.88153076, 18.43569374],
              [-65.88208008, 18.43569374],
              [-65.88208008, 18.4351387],
              [-65.88263702, 18.4351387],
              [-65.88263702, 18.43486023],
              [-65.88291931, 18.43486023],
              [-65.88291931, 18.43458366],
              [-65.88319397, 18.43458366],
              [-65.88319397, 18.43430519],
              [-65.88346863, 18.43430519],
              [-65.88346863, 18.43375015],
              [-65.88375092, 18.43375015],
              [-65.88375092, 18.43347359],
              [-65.88402557, 18.43347359],
              [-65.88402557, 18.43319511],
              [-65.88430786, 18.43319511],
              [-65.88430786, 18.43291664],
              [-65.88458252, 18.43291664],
              [-65.88458252, 18.43263817],
              [-65.88513947, 18.43263817],
              [-65.88513947, 18.4323616],
              [-65.88541412, 18.4323616],
              [-65.88541412, 18.43208122],
              [-65.88569641, 18.43208122],
              [-65.88569641, 18.43180466],
              [-65.88597107, 18.43180466],
              [-65.88597107, 18.43152809],
              [-65.88625336, 18.43152809],
              [-65.88625336, 18.43125153],
              [-65.88652802, 18.43125153],
              [-65.88652802, 18.43097305],
              [-65.88708496, 18.43097305],
              [-65.88708496, 18.43069458],
              [-65.88735962, 18.43069458],
              [-65.88735962, 18.43041611],
              [-65.88764191, 18.43041611],
              [-65.88764191, 18.43014145],
              [-65.88791656, 18.43014145],
              [-65.88791656, 18.42985916],
              [-65.88819122, 18.42985916],
              [-65.88819122, 18.42930603],
              [-65.88847351, 18.42930603],
              [-65.88847351, 18.42902756],
              [-65.88874817, 18.42902756],
              [-65.88874817, 18.42874718],
              [-65.88903046, 18.42874718],
              [-65.88903046, 18.42819405],
              [-65.88930511, 18.42819405],
              [-65.88930511, 18.42791748],
              [-65.88957977, 18.42791748],
              [-65.88957977, 18.4276371],
              [-65.88986206, 18.4276371],
              [-65.88986206, 18.42708397],
              [-65.89013672, 18.42708397],
              [-65.89013672, 18.4268055],
              [-65.89041901, 18.4268055],
              [-65.89041901, 18.42652512],
              [-65.89069366, 18.42652512],
              [-65.89069366, 18.42597198],
              [-65.89097595, 18.42597198],
              [-65.89097595, 18.42569351],
              [-65.89125061, 18.42569351],
              [-65.89125061, 18.42541695],
              [-65.89152527, 18.42541695],
              [-65.89152527, 18.42486191],
              [-65.89180756, 18.42486191],
              [-65.89180756, 18.42458344],
              [-65.89208221, 18.42458344],
              [-65.89208221, 18.42430687],
              [-65.8923645, 18.42430687],
              [-65.8923645, 18.4240284],
              [-65.89263916, 18.4240284],
              [-65.89263916, 18.42374992],
              [-65.89291382, 18.42374992],
              [-65.89291382, 18.42319489],
              [-65.89319611, 18.42319489],
              [-65.89319611, 18.42291641],
              [-65.89347076, 18.42291641],
              [-65.89347076, 18.42263794],
              [-65.89375305, 18.42263794],
              [-65.89375305, 18.42236137],
              [-65.89402771, 18.42236137],
              [-65.89402771, 18.42208481],
              [-65.89430237, 18.42208481],
              [-65.89430237, 18.42180634],
              [-65.89458466, 18.42180634],
              [-65.89458466, 18.42124939],
              [-65.89485931, 18.42124939],
              [-65.89485931, 18.42097282],
              [-65.8951416, 18.42097282],
              [-65.8951416, 18.42069435],
              [-65.89541626, 18.42069435],
              [-65.89541626, 18.42041588],
              [-65.89569092, 18.42041588],
              [-65.89569092, 18.42013931],
              [-65.89597321, 18.42013931],
              [-65.89597321, 18.41986275],
              [-65.89624786, 18.41986275],
              [-65.89624786, 18.4193058],
              [-65.89653015, 18.4193058],
              [-65.89653015, 18.41902733],
              [-65.89680481, 18.41902733],
              [-65.89680481, 18.41875267],
              [-65.8970871, 18.41875267],
              [-65.8970871, 18.41847038],
              [-65.89736176, 18.41847038],
              [-65.89736176, 18.41791725],
              [-65.89763641, 18.41791725],
              [-65.89763641, 18.4173584],
              [-65.8979187, 18.4173584],
              [-65.8979187, 18.41680527],
              [-65.89819336, 18.41680527],
              [-65.89819336, 18.41653061],
              [-65.89847565, 18.41653061],
              [-65.89847565, 18.41597176],
              [-65.89875031, 18.41597176],
              [-65.89875031, 18.41541672],
              [-65.89902496, 18.41541672],
              [-65.89902496, 18.41513824],
              [-65.89819336, 18.41513824],
              [-65.89819336, 18.41541672],
              [-65.8979187, 18.41541672],
              [-65.8979187, 18.41569519],
              [-65.89763641, 18.41569519],
              [-65.89763641, 18.41624832],
              [-65.89736176, 18.41624832],
              [-65.89736176, 18.41653061],
              [-65.8970871, 18.41653061],
              [-65.8970871, 18.41680527],
              [-65.89680481, 18.41680527],
              [-65.89680481, 18.4173584],
              [-65.89653015, 18.4173584],
              [-65.89653015, 18.41764069],
              [-65.89624786, 18.41764069],
              [-65.89624786, 18.41791725],
              [-65.89597321, 18.41791725],
              [-65.89597321, 18.41847038],
              [-65.89569092, 18.41847038],
              [-65.89569092, 18.41875267],
              [-65.89541626, 18.41875267],
              [-65.89541626, 18.41902733],
              [-65.8951416, 18.41902733],
              [-65.8951416, 18.4193058],
              [-65.89485931, 18.4193058],
              [-65.89485931, 18.41958427],
              [-65.89458466, 18.41958427],
              [-65.89458466, 18.42013931],
              [-65.89430237, 18.42013931],
              [-65.89430237, 18.42041588],
              [-65.89402771, 18.42041588],
              [-65.89402771, 18.42069435],
              [-65.89375305, 18.42069435],
              [-65.89375305, 18.42097282],
              [-65.89347076, 18.42097282],
              [-65.89347076, 18.42124939],
              [-65.89319611, 18.42124939],
              [-65.89319611, 18.42180634],
              [-65.89291382, 18.42180634],
              [-65.89291382, 18.42208481],
              [-65.89263916, 18.42208481],
              [-65.89263916, 18.42236137],
              [-65.8923645, 18.42236137],
              [-65.8923645, 18.42263794],
              [-65.89208221, 18.42263794],
              [-65.89208221, 18.42291641],
              [-65.89180756, 18.42291641],
              [-65.89180756, 18.42347145],
              [-65.89152527, 18.42347145],
              [-65.89152527, 18.42374992],
              [-65.89125061, 18.42374992],
              [-65.89125061, 18.4240284],
              [-65.89097595, 18.4240284],
              [-65.89097595, 18.42430687],
              [-65.89069366, 18.42430687],
              [-65.89069366, 18.42458344],
              [-65.89041901, 18.42458344],
              [-65.89041901, 18.42513847],
              [-65.89013672, 18.42513847],
              [-65.89013672, 18.42541695],
              [-65.88986206, 18.42541695],
              [-65.88986206, 18.42569351],
              [-65.88957977, 18.42569351],
              [-65.88957977, 18.42597198],
              [-65.88930511, 18.42597198],
              [-65.88930511, 18.42625046],
              [-65.88903046, 18.42625046],
              [-65.88903046, 18.4268055],
              [-65.88874817, 18.4268055],
              [-65.88874817, 18.42708397],
              [-65.88847351, 18.42708397],
              [-65.88847351, 18.42736053],
              [-65.88819122, 18.42736053],
              [-65.88819122, 18.4276371],
              [-65.88791656, 18.4276371],
              [-65.88791656, 18.42819405],
              [-65.88764191, 18.42819405],
              [-65.88764191, 18.42847252],
              [-65.88735962, 18.42847252],
              [-65.88735962, 18.42874718],
              [-65.88708496, 18.42874718],
              [-65.88708496, 18.42930603],
              [-65.88680267, 18.42930603],
              [-65.88680267, 18.4295826],
              [-65.88652802, 18.4295826],
              [-65.88652802, 18.42985916],
              [-65.88625336, 18.42985916],
              [-65.88625336, 18.43014145],
              [-65.88597107, 18.43014145],
              [-65.88597107, 18.43041611],
              [-65.88569641, 18.43041611],
              [-65.88569641, 18.43069458],
              [-65.88541412, 18.43069458],
              [-65.88541412, 18.43097305],
              [-65.88513947, 18.43097305],
              [-65.88513947, 18.43125153],
              [-65.88486481, 18.43125153],
              [-65.88486481, 18.43152809],
              [-65.88458252, 18.43152809],
              [-65.88458252, 18.43180466],
              [-65.88430786, 18.43180466],
              [-65.88430786, 18.43208122],
              [-65.88402557, 18.43208122],
              [-65.88402557, 18.4323616],
              [-65.88375092, 18.4323616],
              [-65.88375092, 18.43263817],
              [-65.88346863, 18.43263817],
              [-65.88346863, 18.43291664],
              [-65.88319397, 18.43291664],
              [-65.88319397, 18.43319511],
              [-65.88291931, 18.43319511],
              [-65.88291931, 18.43347359],
              [-65.88263702, 18.43347359],
              [-65.88263702, 18.43402863],
              [-65.88180542, 18.43402863],
              [-65.88180542, 18.43458366],
              [-65.88124847, 18.43458366],
              [-65.88124847, 18.43486023],
              [-65.88097382, 18.43486023],
              [-65.88097382, 18.4351387],
              [-65.88041687, 18.4351387],
              [-65.88041687, 18.43541718],
              [-65.88014221, 18.43541718],
              [-65.88014221, 18.43569374],
              [-65.87958527, 18.43569374],
              [-65.87958527, 18.43597221],
              [-65.87930298, 18.43597221],
              [-65.87930298, 18.43625069],
              [-65.87875366, 18.43625069],
              [-65.87875366, 18.43652725],
              [-65.87847137, 18.43652725],
              [-65.87847137, 18.43680573],
              [-65.87819672, 18.43680573],
              [-65.87819672, 18.4370842],
              [-65.87735748, 18.4370842],
              [-65.87735748, 18.43736076],
              [-65.87319183, 18.43736076],
              [-65.87319183, 18.4370842],
              [-65.87291718, 18.4370842],
              [-65.87291718, 18.43680573],
              [-65.87124634, 18.43680573],
              [-65.87124634, 18.43652725],
              [-65.87097168, 18.43652725],
              [-65.87097168, 18.43625069],
              [-65.87069702, 18.43625069],
              [-65.87069702, 18.43597221],
              [-65.86958313, 18.43597221],
              [-65.86958313, 18.43625069],
              [-65.86902618, 18.43625069],
              [-65.86902618, 18.43597221],
              [-65.86680603, 18.43597221],
              [-65.86680603, 18.43569374],
              [-65.86597443, 18.43569374],
              [-65.86597443, 18.43597221],
              [-65.86541748, 18.43597221],
              [-65.86541748, 18.43569374],
              [-65.86374664, 18.43569374],
              [-65.86374664, 18.43541718],
              [-65.86291504, 18.43541718],
              [-65.86291504, 18.4351387],
              [-65.86235809, 18.4351387],
              [-65.86235809, 18.43486023],
              [-65.86096954, 18.43486023],
              [-65.86096954, 18.43458366],
              [-65.86042023, 18.43458366],
              [-65.86042023, 18.43486023],
              [-65.85819244, 18.43486023],
              [-65.85819244, 18.43458366],
              [-65.85652924, 18.43458366],
              [-65.85652924, 18.43486023],
              [-65.85597229, 18.43486023],
              [-65.85597229, 18.43458366],
              [-65.85569763, 18.43458366],
              [-65.85569763, 18.43486023],
              [-65.85430908, 18.43486023],
              [-65.85430908, 18.4351387],
              [-65.85319519, 18.4351387],
              [-65.85319519, 18.43486023],
              [-65.85263824, 18.43486023],
              [-65.85263824, 18.43458366],
              [-65.85236359, 18.43458366],
              [-65.85236359, 18.43430519],
              [-65.85180664, 18.43430519],
              [-65.85180664, 18.43402863],
              [-65.85124969, 18.43402863],
              [-65.85124969, 18.43375015],
              [-65.85097504, 18.43375015],
              [-65.85097504, 18.43347359],
              [-65.84986115, 18.43347359],
              [-65.84986115, 18.43375015],
              [-65.84819794, 18.43375015],
              [-65.84819794, 18.43347359],
              [-65.84764099, 18.43347359],
              [-65.84764099, 18.43319511],
              [-65.84708405, 18.43319511],
              [-65.84708405, 18.43291664],
              [-65.8465271, 18.43291664],
              [-65.8465271, 18.43263817],
              [-65.84625244, 18.43263817],
              [-65.84625244, 18.4323616],
              [-65.84597015, 18.4323616],
              [-65.84597015, 18.43208122],
              [-65.8456955, 18.43208122],
              [-65.8456955, 18.43180466],
              [-65.8445816, 18.43180466],
              [-65.8445816, 18.43152809],
              [-65.84375, 18.43152809],
              [-65.84375, 18.43125153],
              [-65.83958435, 18.43125153],
              [-65.83958435, 18.43152809],
              [-65.8390274, 18.43152809],
              [-65.8390274, 18.43180466],
              [-65.83875275, 18.43180466],
              [-65.83875275, 18.43208122],
              [-65.83680725, 18.43208122],
              [-65.83680725, 18.43180466],
              [-65.83625031, 18.43180466],
              [-65.83625031, 18.43152809],
              [-65.83597565, 18.43152809],
              [-65.83597565, 18.43125153],
              [-65.8354187, 18.43125153],
              [-65.8354187, 18.43097305],
              [-65.8345871, 18.43097305],
              [-65.8345871, 18.43069458],
              [-65.83403015, 18.43069458],
              [-65.83403015, 18.43041611],
              [-65.83347321, 18.43041611],
              [-65.83347321, 18.43014145],
              [-65.83319092, 18.43014145],
              [-65.83319092, 18.42985916],
              [-65.83291626, 18.42985916],
              [-65.83291626, 18.42930603],
              [-65.8326416, 18.42930603],
              [-65.8326416, 18.42902756],
              [-65.83235931, 18.42902756],
              [-65.83235931, 18.42736053],
              [-65.83208466, 18.42736053],
              [-65.83208466, 18.4268055],
              [-65.83180237, 18.4268055],
              [-65.83180237, 18.42652512],
              [-65.83152771, 18.42652512],
              [-65.83152771, 18.42625046],
              [-65.83125305, 18.42625046],
              [-65.83125305, 18.42597198],
              [-65.83097076, 18.42597198],
              [-65.83097076, 18.42569351],
              [-65.83069611, 18.42569351],
              [-65.83069611, 18.42486191],
              [-65.83041382, 18.42486191],
              [-65.83041382, 18.42458344],
              [-65.83013916, 18.42458344],
              [-65.83013916, 18.4240284],
              [-65.8298645, 18.4240284],
              [-65.8298645, 18.42263794],
              [-65.8328476, 18.42060471],
              [-65.83378601, 18.41822433],
              [-65.83517456, 18.4148922],
              [-65.83842468, 18.41204071],
              [-65.84259796, 18.40871239],
              [-65.84398651, 18.40585327],
              [-65.84631348, 18.40442657],
              [-65.85001373, 18.40348244],
              [-65.85279846, 18.40158081],
              [-65.85419464, 18.39920044],
              [-65.85604858, 18.3944397],
              [-65.85651398, 18.39253426],
              [-65.85652161, 18.39015579],
              [-65.85977173, 18.38158035],
              [-65.86070251, 18.38158417],
              [-65.86301422, 18.38158607],
              [-65.86533356, 18.38063622],
              [-65.86625671, 18.37968445],
              [-65.86696625, 18.37859726],
              [-65.87703705, 18.3782692],
              [-65.86991882, 18.40891266],
              [-65.89984131, 18.41406631],
              [-65.90964508, 18.40085602],
              [-65.91909027, 18.40096855],
            ],
          ],
          [
            [
              [-65.99182892, 18.45705795],
              [-65.99212646, 18.45735931],
              [-65.99180603, 18.45735931],
              [-65.99182892, 18.45705795],
            ],
          ],
          [
            [
              [-65.9901886, 18.45536613],
              [-65.99182892, 18.45705795],
              [-65.99124908, 18.45708275],
              [-65.99124908, 18.45680618],
              [-65.99069214, 18.45680618],
              [-65.99069214, 18.45652771],
              [-65.99013519, 18.45652771],
              [-65.9901886, 18.45536613],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 45,
        name: 'Loíza',
        hasc_1: 'PR.LZ',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-65.99291992, 18.3782692, -65.8298645, 18.46041679],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.46',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-65.74974823, 18.38541412],
              [-65.74958038, 18.38541412],
              [-65.74958038, 18.38513947],
              [-65.74903107, 18.38513947],
              [-65.74903107, 18.38458252],
              [-65.74874878, 18.38458252],
              [-65.74874878, 18.38430405],
              [-65.74847412, 18.38430405],
              [-65.74847412, 18.38402748],
              [-65.74819183, 18.38402748],
              [-65.74819183, 18.38375092],
              [-65.74791718, 18.38375092],
              [-65.74791718, 18.38347244],
              [-65.74736023, 18.38347244],
              [-65.74736023, 18.38319588],
              [-65.74680328, 18.38319588],
              [-65.74680328, 18.3829174],
              [-65.74652863, 18.3829174],
              [-65.74652863, 18.38263893],
              [-65.74597168, 18.38263893],
              [-65.74597168, 18.38236046],
              [-65.74514008, 18.38236046],
              [-65.74514008, 18.38208389],
              [-65.74485779, 18.38208389],
              [-65.74485779, 18.38180542],
              [-65.74458313, 18.38180542],
              [-65.74458313, 18.38152695],
              [-65.74430847, 18.38152695],
              [-65.74430847, 18.38125038],
              [-65.74375153, 18.38125038],
              [-65.74375153, 18.38097382],
              [-65.74319458, 18.38097382],
              [-65.74319458, 18.38069534],
              [-65.74291992, 18.38069534],
              [-65.74291992, 18.38041687],
              [-65.74180603, 18.38041687],
              [-65.74180603, 18.3801384],
              [-65.74097443, 18.3801384],
              [-65.74097443, 18.37986374],
              [-65.74069214, 18.37986374],
              [-65.74069214, 18.37958336],
              [-65.74013519, 18.37958336],
              [-65.74013519, 18.37986374],
              [-65.73986053, 18.37986374],
              [-65.73986053, 18.3801384],
              [-65.73847198, 18.3801384],
              [-65.73847198, 18.38041687],
              [-65.73791504, 18.38041687],
              [-65.73791504, 18.38069534],
              [-65.73764038, 18.38069534],
              [-65.73764038, 18.38097382],
              [-65.73680878, 18.38097382],
              [-65.73680878, 18.38125038],
              [-65.73569489, 18.38125038],
              [-65.73569489, 18.38152695],
              [-65.73430634, 18.38152695],
              [-65.73430634, 18.38180542],
              [-65.73319244, 18.38180542],
              [-65.73319244, 18.38208389],
              [-65.73291779, 18.38208389],
              [-65.73291779, 18.38236046],
              [-65.7326355, 18.38236046],
              [-65.7326355, 18.38263893],
              [-65.73208618, 18.38263893],
              [-65.73208618, 18.3829174],
              [-65.73124695, 18.3829174],
              [-65.73124695, 18.38319588],
              [-65.73069763, 18.38319588],
              [-65.73069763, 18.38347244],
              [-65.73041534, 18.38347244],
              [-65.73041534, 18.38402748],
              [-65.73014069, 18.38402748],
              [-65.73014069, 18.38458252],
              [-65.7298584, 18.38458252],
              [-65.7298584, 18.38486099],
              [-65.72958374, 18.38486099],
              [-65.72958374, 18.38513947],
              [-65.72930908, 18.38513947],
              [-65.72930908, 18.3856945],
              [-65.72902679, 18.3856945],
              [-65.72902679, 18.38597298],
              [-65.72875214, 18.38597298],
              [-65.72875214, 18.38652611],
              [-65.72819519, 18.38652611],
              [-65.72819519, 18.38736153],
              [-65.72846985, 18.38736153],
              [-65.72846985, 18.38819504],
              [-65.72819519, 18.38819504],
              [-65.72819519, 18.3884716],
              [-65.72652435, 18.3884716],
              [-65.72652435, 18.38819504],
              [-65.72541809, 18.38819504],
              [-65.72541809, 18.3884716],
              [-65.7251358, 18.3884716],
              [-65.7251358, 18.38874817],
              [-65.72486115, 18.38874817],
              [-65.72486115, 18.38903046],
              [-65.7243042, 18.38903046],
              [-65.7243042, 18.38930511],
              [-65.7234726, 18.38930511],
              [-65.7234726, 18.38958359],
              [-65.72097015, 18.38958359],
              [-65.72097015, 18.38930511],
              [-65.72041321, 18.38930511],
              [-65.72041321, 18.38874817],
              [-65.72013855, 18.38874817],
              [-65.72013855, 18.38624954],
              [-65.72041321, 18.38624954],
              [-65.72041321, 18.3856945],
              [-65.7206955, 18.3856945],
              [-65.7206955, 18.38513947],
              [-65.72097015, 18.38513947],
              [-65.72097015, 18.38402748],
              [-65.7206955, 18.38402748],
              [-65.7206955, 18.38375092],
              [-65.72041321, 18.38375092],
              [-65.72041321, 18.38319588],
              [-65.72013855, 18.38319588],
              [-65.72013855, 18.38263893],
              [-65.71986389, 18.38263893],
              [-65.71986389, 18.38236046],
              [-65.71930695, 18.38236046],
              [-65.71930695, 18.38208389],
              [-65.71875, 18.38208389],
              [-65.71875, 18.38180542],
              [-65.71819305, 18.38180542],
              [-65.71819305, 18.38152695],
              [-65.7179184, 18.38152695],
              [-65.7179184, 18.38180542],
              [-65.71763611, 18.38180542],
              [-65.71763611, 18.38152695],
              [-65.7168045, 18.38152695],
              [-65.7168045, 18.38125038],
              [-65.71652985, 18.38125038],
              [-65.71652985, 18.38069534],
              [-65.71624756, 18.38069534],
              [-65.71624756, 18.38041687],
              [-65.7159729, 18.38041687],
              [-65.7159729, 18.37902832],
              [-65.71569824, 18.37902832],
              [-65.71569824, 18.37875175],
              [-65.7159729, 18.37875175],
              [-65.7159729, 18.37791634],
              [-65.71569824, 18.37791634],
              [-65.71569824, 18.37764168],
              [-65.7151413, 18.37764168],
              [-65.7151413, 18.37735939],
              [-65.71458435, 18.37735939],
              [-65.71458435, 18.37708282],
              [-65.71430206, 18.37708282],
              [-65.71430206, 18.37652969],
              [-65.7140274, 18.37652969],
              [-65.7140274, 18.37597275],
              [-65.7131958, 18.37597275],
              [-65.7131958, 18.37569427],
              [-65.71263885, 18.37569427],
              [-65.71263885, 18.3754158],
              [-65.7123642, 18.3754158],
              [-65.7123642, 18.37486076],
              [-65.71208191, 18.37486076],
              [-65.71208191, 18.37402725],
              [-65.71180725, 18.37402725],
              [-65.71180725, 18.37347221],
              [-65.71125031, 18.37347221],
              [-65.71125031, 18.37319374],
              [-65.71097565, 18.37319374],
              [-65.71097565, 18.37291527],
              [-65.7104187, 18.37291527],
              [-65.7104187, 18.3726387],
              [-65.71013641, 18.3726387],
              [-65.71013641, 18.37236023],
              [-65.7095871, 18.37236023],
              [-65.7095871, 18.37208366],
              [-65.70819092, 18.37208366],
              [-65.70819092, 18.37180519],
              [-65.70735931, 18.37180519],
              [-65.70735931, 18.37152863],
              [-65.70708466, 18.37152863],
              [-65.70708466, 18.37125015],
              [-65.70652771, 18.37125015],
              [-65.70652771, 18.37097168],
              [-65.70597076, 18.37097168],
              [-65.70597076, 18.37069511],
              [-65.70541382, 18.37069511],
              [-65.70541382, 18.37041664],
              [-65.7048645, 18.37041664],
              [-65.7048645, 18.37013817],
              [-65.70347595, 18.37013817],
              [-65.70347595, 18.3698616],
              [-65.70319366, 18.3698616],
              [-65.70319366, 18.36958313],
              [-65.70291901, 18.36958313],
              [-65.70291901, 18.36930466],
              [-65.70263672, 18.36930466],
              [-65.70263672, 18.36902809],
              [-65.70236206, 18.36902809],
              [-65.70236206, 18.36874962],
              [-65.70207977, 18.36874962],
              [-65.70207977, 18.36847305],
              [-65.70097351, 18.36847305],
              [-65.70097351, 18.36819458],
              [-65.69985962, 18.36819458],
              [-65.69985962, 18.36791611],
              [-65.69875336, 18.36791611],
              [-65.69875336, 18.36763954],
              [-65.69736481, 18.36763954],
              [-65.69736481, 18.36791611],
              [-65.69652557, 18.36791611],
              [-65.69652557, 18.36763954],
              [-65.69569397, 18.36763954],
              [-65.69569397, 18.3670826],
              [-65.69486237, 18.3670826],
              [-65.69486237, 18.36736107],
              [-65.69458008, 18.36736107],
              [-65.69458008, 18.3670826],
              [-65.69403076, 18.3670826],
              [-65.69403076, 18.36680603],
              [-65.69319153, 18.36680603],
              [-65.69319153, 18.36652756],
              [-65.69097137, 18.36652756],
              [-65.69097137, 18.36597252],
              [-65.68902588, 18.36597252],
              [-65.68902588, 18.36569405],
              [-65.68736267, 18.36569405],
              [-65.68736267, 18.36597252],
              [-65.68680573, 18.36597252],
              [-65.68680573, 18.36624908],
              [-65.68653107, 18.36624908],
              [-65.68653107, 18.36680603],
              [-65.68624878, 18.36680603],
              [-65.68624878, 18.3670826],
              [-65.68569183, 18.3670826],
              [-65.68569183, 18.36736107],
              [-65.68486023, 18.36736107],
              [-65.68486023, 18.36763954],
              [-65.68430328, 18.36763954],
              [-65.68430328, 18.36791611],
              [-65.68402863, 18.36791611],
              [-65.68402863, 18.36763954],
              [-65.68347168, 18.36763954],
              [-65.68347168, 18.36736107],
              [-65.68180847, 18.36736107],
              [-65.68180847, 18.36763954],
              [-65.68152618, 18.36763954],
              [-65.68152618, 18.36791611],
              [-65.68125153, 18.36791611],
              [-65.68125153, 18.36819458],
              [-65.67903137, 18.36819458],
              [-65.67903137, 18.36791611],
              [-65.67874908, 18.36791611],
              [-65.67874908, 18.36652756],
              [-65.67847443, 18.36652756],
              [-65.67847443, 18.36597252],
              [-65.67819214, 18.36597252],
              [-65.67819214, 18.36569405],
              [-65.67791748, 18.36569405],
              [-65.67791748, 18.36541748],
              [-65.67736053, 18.36541748],
              [-65.67736053, 18.36514091],
              [-65.67680359, 18.36514091],
              [-65.67680359, 18.36458397],
              [-65.67652893, 18.36458397],
              [-65.67652893, 18.36402702],
              [-65.67624664, 18.36402702],
              [-65.67624664, 18.36374855],
              [-65.67569733, 18.36374855],
              [-65.67569733, 18.36347008],
              [-65.67541504, 18.36347008],
              [-65.67541504, 18.36319351],
              [-65.67485809, 18.36319351],
              [-65.67485809, 18.36291885],
              [-65.67430878, 18.36291885],
              [-65.67430878, 18.36263847],
              [-65.67346954, 18.36263847],
              [-65.67346954, 18.36236191],
              [-65.67124939, 18.36236191],
              [-65.67124939, 18.36208344],
              [-65.66950226, 18.36208344],
              [-65.67417908, 18.35490608],
              [-65.67882538, 18.34777641],
              [-65.67975616, 18.34634781],
              [-65.67976379, 18.3449192],
              [-65.6792984, 18.34396744],
              [-65.67884064, 18.34348869],
              [-65.67791748, 18.3425312],
              [-65.6788559, 18.34015083],
              [-65.68257904, 18.33301353],
              [-65.68304443, 18.33206367],
              [-65.68582916, 18.33016586],
              [-65.6844635, 18.32395935],
              [-65.6844635, 18.32348824],
              [-65.68585205, 18.32301331],
              [-65.68677521, 18.32254219],
              [-65.69139862, 18.3244648],
              [-65.69509888, 18.32495308],
              [-65.69741821, 18.32400894],
              [-65.69973755, 18.3230629],
              [-65.70066071, 18.32211113],
              [-65.70159149, 18.32116127],
              [-65.70251465, 18.32068634],
              [-65.7039032, 18.32069206],
              [-65.70483398, 18.31878853],
              [-65.70484161, 18.31592941],
              [-65.70439148, 18.31402016],
              [-65.70440674, 18.30925179],
              [-65.7048645, 18.30877876],
              [-65.70903778, 18.30688667],
              [-65.70996094, 18.30641174],
              [-65.71089172, 18.30641174],
              [-65.71227264, 18.3068943],
              [-65.71273804, 18.30641937],
              [-65.71366882, 18.30690193],
              [-65.71921539, 18.30644035],
              [-65.72061157, 18.30405807],
              [-65.72109222, 18.29929733],
              [-65.72063446, 18.29595947],
              [-65.7252655, 18.29502106],
              [-65.72573853, 18.29215813],
              [-65.72898102, 18.29026222],
              [-65.73222351, 18.28836632],
              [-65.73361206, 18.2878933],
              [-65.73775482, 18.29648209],
              [-65.74375916, 18.29935837],
              [-65.74884033, 18.30080795],
              [-65.75022888, 18.30128479],
              [-65.75115967, 18.29985809],
              [-65.753479, 18.2970047],
              [-65.75486755, 18.295578],
              [-65.75765991, 18.29177284],
              [-65.75904846, 18.29082489],
              [-65.75904083, 18.29416084],
              [-65.75811005, 18.29606056],
              [-65.75810242, 18.29749298],
              [-65.76132965, 18.30226707],
              [-65.76271057, 18.30608559],
              [-65.76131439, 18.30941772],
              [-65.75944519, 18.31561089],
              [-65.75804901, 18.31846428],
              [-65.75759125, 18.31893921],
              [-65.75481415, 18.31941032],
              [-65.75202942, 18.32035446],
              [-65.74971008, 18.32321358],
              [-65.7487793, 18.32559204],
              [-65.75154877, 18.32798386],
              [-65.75571442, 18.32847023],
              [-65.75617218, 18.32942009],
              [-65.75616455, 18.33371544],
              [-65.75384521, 18.33513451],
              [-65.75336456, 18.34132957],
              [-65.75428772, 18.34228516],
              [-65.75614929, 18.34324455],
              [-65.75891113, 18.34801674],
              [-65.76122284, 18.34992981],
              [-65.76353455, 18.35231781],
              [-65.76491547, 18.35470581],
              [-65.76815033, 18.35566521],
              [-65.76861572, 18.35662079],
              [-65.76907349, 18.35852814],
              [-65.77044678, 18.36568069],
              [-65.77043915, 18.36615753],
              [-65.76626587, 18.36948013],
              [-65.76394653, 18.37281036],
              [-65.7616272, 18.37471008],
              [-65.7537384, 18.38040733],
              [-65.75002289, 18.38516235],
              [-65.74974823, 18.38541412],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 46,
        name: 'Luquillo',
        hasc_1: 'PR.LQ',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-65.77044678, 18.2878933, -65.66950226, 18.38958359],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.47',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.4440918, 18.37108803],
              [-66.46307373, 18.37147331],
              [-66.46678162, 18.37192917],
              [-66.4713974, 18.36904526],
              [-66.47323608, 18.36760712],
              [-66.47740173, 18.36663246],
              [-66.49036407, 18.36560822],
              [-66.49638367, 18.36605644],
              [-66.51163483, 18.36215973],
              [-66.51625824, 18.35927391],
              [-66.52317047, 18.35494423],
              [-66.52870178, 18.35015106],
              [-66.53102112, 18.35014153],
              [-66.53380585, 18.35154915],
              [-66.53112793, 18.37062836],
              [-66.53302765, 18.37919426],
              [-66.53352356, 18.38538742],
              [-66.53400421, 18.38824081],
              [-66.53447723, 18.39014435],
              [-66.54008484, 18.3991642],
              [-66.54256439, 18.40455246],
              [-66.54336548, 18.40629196],
              [-66.54337311, 18.40772247],
              [-66.5429306, 18.41105652],
              [-66.5424881, 18.41487503],
              [-66.54342651, 18.41820335],
              [-66.54343414, 18.41868019],
              [-66.53557587, 18.42158508],
              [-66.52957153, 18.42447662],
              [-66.52680206, 18.42639732],
              [-66.5254364, 18.43069267],
              [-66.53101349, 18.43447304],
              [-66.53518677, 18.43445015],
              [-66.53657532, 18.43491745],
              [-66.53704071, 18.43538857],
              [-66.5375061, 18.4358654],
              [-66.53986359, 18.44347191],
              [-66.53941345, 18.44490433],
              [-66.53803253, 18.44681931],
              [-66.53710937, 18.44729805],
              [-66.53202057, 18.4492321],
              [-66.53017426, 18.45019913],
              [-66.52602386, 18.4540329],
              [-66.52649689, 18.45545769],
              [-66.52788544, 18.45640373],
              [-66.53020477, 18.45686722],
              [-66.5366745, 18.45349312],
              [-66.53760529, 18.45396614],
              [-66.53761292, 18.45491791],
              [-66.53716278, 18.45682144],
              [-66.532547, 18.46066475],
              [-66.53162384, 18.46209526],
              [-66.53025818, 18.46639061],
              [-66.5302887, 18.47115326],
              [-66.53076172, 18.4730587],
              [-66.53356934, 18.47780609],
              [-66.53418732, 18.47875023],
              [-66.53375244, 18.47875023],
              [-66.53375244, 18.47930527],
              [-66.5331955, 18.47930527],
              [-66.5331955, 18.47958374],
              [-66.53291321, 18.47958374],
              [-66.53291321, 18.48041725],
              [-66.53263855, 18.48041725],
              [-66.53263855, 18.48069382],
              [-66.53291321, 18.48069382],
              [-66.53291321, 18.48208427],
              [-66.53180695, 18.48208427],
              [-66.53180695, 18.48236084],
              [-66.5304184, 18.48236084],
              [-66.5304184, 18.48263741],
              [-66.52986145, 18.48263741],
              [-66.52986145, 18.48291588],
              [-66.5293045, 18.48291588],
              [-66.5293045, 18.48319435],
              [-66.52874756, 18.48319435],
              [-66.52874756, 18.48347282],
              [-66.52819824, 18.48347282],
              [-66.52819824, 18.48374939],
              [-66.5276413, 18.48374939],
              [-66.5276413, 18.48402977],
              [-66.52708435, 18.48402977],
              [-66.52708435, 18.4845829],
              [-66.5248642, 18.4845829],
              [-66.5248642, 18.48430634],
              [-66.52430725, 18.48430634],
              [-66.52430725, 18.48402977],
              [-66.52347565, 18.48402977],
              [-66.52347565, 18.48347282],
              [-66.52319336, 18.48347282],
              [-66.52319336, 18.48319435],
              [-66.52347565, 18.48319435],
              [-66.52347565, 18.48236084],
              [-66.5229187, 18.48236084],
              [-66.5229187, 18.4818058],
              [-66.52236176, 18.4818058],
              [-66.52236176, 18.48152733],
              [-66.52180481, 18.48152733],
              [-66.52180481, 18.48125076],
              [-66.52124786, 18.48125076],
              [-66.52124786, 18.48097229],
              [-66.51902771, 18.48097229],
              [-66.51902771, 18.48125076],
              [-66.51819611, 18.48125076],
              [-66.51819611, 18.48041725],
              [-66.51791382, 18.48041725],
              [-66.51791382, 18.48013878],
              [-66.5173645, 18.48013878],
              [-66.5173645, 18.47986031],
              [-66.51680756, 18.47986031],
              [-66.51680756, 18.47958374],
              [-66.51652527, 18.47958374],
              [-66.51652527, 18.47930527],
              [-66.51457977, 18.47930527],
              [-66.51457977, 18.4790287],
              [-66.51374817, 18.4790287],
              [-66.51374817, 18.47875023],
              [-66.51347351, 18.47875023],
              [-66.51347351, 18.47819519],
              [-66.51319122, 18.47819519],
              [-66.51319122, 18.47791672],
              [-66.51291656, 18.47791672],
              [-66.51291656, 18.47763824],
              [-66.51264191, 18.47763824],
              [-66.51264191, 18.47736168],
              [-66.51235962, 18.47736168],
              [-66.51235962, 18.47708321],
              [-66.51041412, 18.47708321],
              [-66.51041412, 18.47736168],
              [-66.50958252, 18.47736168],
              [-66.50958252, 18.47791672],
              [-66.50791931, 18.47791672],
              [-66.50791931, 18.47763824],
              [-66.50763702, 18.47763824],
              [-66.50763702, 18.47736168],
              [-66.50597382, 18.47736168],
              [-66.50597382, 18.47652817],
              [-66.50569153, 18.47652817],
              [-66.50569153, 18.47624969],
              [-66.50458527, 18.47624969],
              [-66.50458527, 18.47597313],
              [-66.50402832, 18.47597313],
              [-66.50402832, 18.47569466],
              [-66.50375366, 18.47569466],
              [-66.50375366, 18.47541618],
              [-66.50347137, 18.47541618],
              [-66.50347137, 18.47513962],
              [-66.50235748, 18.47513962],
              [-66.50235748, 18.47486115],
              [-66.50096893, 18.47486115],
              [-66.50096893, 18.47513962],
              [-66.50041962, 18.47513962],
              [-66.50041962, 18.47541618],
              [-66.50013733, 18.47541618],
              [-66.50013733, 18.47597313],
              [-66.49986267, 18.47597313],
              [-66.49986267, 18.47624969],
              [-66.50013733, 18.47624969],
              [-66.50013733, 18.47708321],
              [-66.49986267, 18.47708321],
              [-66.49986267, 18.47736168],
              [-66.49736023, 18.47736168],
              [-66.49736023, 18.47708321],
              [-66.49680328, 18.47708321],
              [-66.49680328, 18.47680473],
              [-66.49541473, 18.47680473],
              [-66.49541473, 18.47652817],
              [-66.49402618, 18.47652817],
              [-66.49402618, 18.47624969],
              [-66.49319458, 18.47624969],
              [-66.49319458, 18.47597313],
              [-66.49263763, 18.47597313],
              [-66.49263763, 18.47569466],
              [-66.49180603, 18.47569466],
              [-66.49180603, 18.47541618],
              [-66.49041748, 18.47541618],
              [-66.49041748, 18.47513962],
              [-66.48958588, 18.47513962],
              [-66.48958588, 18.47486115],
              [-66.48930359, 18.47486115],
              [-66.48930359, 18.47458458],
              [-66.48847198, 18.47458458],
              [-66.48847198, 18.47430611],
              [-66.48819733, 18.47430611],
              [-66.48819733, 18.47458458],
              [-66.48764038, 18.47458458],
              [-66.48764038, 18.47430611],
              [-66.48625183, 18.47430611],
              [-66.48625183, 18.47402763],
              [-66.48569489, 18.47402763],
              [-66.48569489, 18.47430611],
              [-66.48542023, 18.47430611],
              [-66.48542023, 18.47402763],
              [-66.48513794, 18.47402763],
              [-66.48513794, 18.4734726],
              [-66.48486328, 18.4734726],
              [-66.48486328, 18.47374916],
              [-66.48347473, 18.47374916],
              [-66.48347473, 18.4734726],
              [-66.48319244, 18.4734726],
              [-66.48319244, 18.47291756],
              [-66.48347473, 18.47291756],
              [-66.48347473, 18.47208405],
              [-66.48319244, 18.47208405],
              [-66.48319244, 18.47180557],
              [-66.48291779, 18.47180557],
              [-66.48291779, 18.4715271],
              [-66.48236084, 18.4715271],
              [-66.48236084, 18.47124863],
              [-66.48208618, 18.47124863],
              [-66.48208618, 18.47097015],
              [-66.47930908, 18.47097015],
              [-66.47930908, 18.47069359],
              [-66.47819519, 18.47069359],
              [-66.47819519, 18.47041893],
              [-66.47736359, 18.47041893],
              [-66.47736359, 18.47013855],
              [-66.47541809, 18.47013855],
              [-66.47541809, 18.46986008],
              [-66.47125244, 18.46986008],
              [-66.47125244, 18.46958351],
              [-66.47041321, 18.46958351],
              [-66.47041321, 18.46930504],
              [-66.46986389, 18.46930504],
              [-66.46986389, 18.46903038],
              [-66.46875, 18.46903038],
              [-66.46875, 18.46874809],
              [-66.46819305, 18.46874809],
              [-66.46819305, 18.46903038],
              [-66.46652985, 18.46903038],
              [-66.46652985, 18.46930504],
              [-66.4659729, 18.46930504],
              [-66.4659729, 18.46958351],
              [-66.46485901, 18.46958351],
              [-66.46485901, 18.46986008],
              [-66.46458435, 18.46986008],
              [-66.46458435, 18.46958351],
              [-66.46375275, 18.46958351],
              [-66.46375275, 18.46930504],
              [-66.46208191, 18.46930504],
              [-66.46208191, 18.46903038],
              [-66.45986176, 18.46903038],
              [-66.45986176, 18.46874809],
              [-66.45847321, 18.46874809],
              [-66.45847321, 18.46847153],
              [-66.45791626, 18.46847153],
              [-66.45791626, 18.46874809],
              [-66.45708466, 18.46874809],
              [-66.45708466, 18.46903038],
              [-66.45541382, 18.46903038],
              [-66.45541382, 18.46930504],
              [-66.45430756, 18.46930504],
              [-66.45430756, 18.46958351],
              [-66.45375061, 18.46958351],
              [-66.45375061, 18.46930504],
              [-66.45291901, 18.46930504],
              [-66.45291901, 18.46958351],
              [-66.45236206, 18.46958351],
              [-66.45236206, 18.46986008],
              [-66.45180511, 18.46986008],
              [-66.45180511, 18.47013855],
              [-66.45069122, 18.47013855],
              [-66.45069122, 18.47041893],
              [-66.44985962, 18.47041893],
              [-66.44985962, 18.47069359],
              [-66.44930267, 18.47069359],
              [-66.44930267, 18.47097015],
              [-66.44902802, 18.47097015],
              [-66.44902802, 18.47124863],
              [-66.44875336, 18.47124863],
              [-66.44875336, 18.4715271],
              [-66.44847107, 18.4715271],
              [-66.44847107, 18.47180557],
              [-66.44819641, 18.47180557],
              [-66.44819641, 18.47208405],
              [-66.44791412, 18.47208405],
              [-66.44791412, 18.47236252],
              [-66.44763947, 18.47236252],
              [-66.44763947, 18.47319412],
              [-66.44736481, 18.47319412],
              [-66.44736481, 18.47402763],
              [-66.44708252, 18.47402763],
              [-66.44708252, 18.47430611],
              [-66.44680786, 18.47430611],
              [-66.44680786, 18.47458458],
              [-66.44652557, 18.47458458],
              [-66.44652557, 18.47486115],
              [-66.44625092, 18.47486115],
              [-66.44625092, 18.47513962],
              [-66.44596863, 18.47513962],
              [-66.44596863, 18.47541618],
              [-66.44569397, 18.47541618],
              [-66.44569397, 18.47569466],
              [-66.44513702, 18.47569466],
              [-66.44513702, 18.47652817],
              [-66.44486237, 18.47652817],
              [-66.44486237, 18.47708321],
              [-66.44458008, 18.47708321],
              [-66.44458008, 18.47736168],
              [-66.44319153, 18.47736168],
              [-66.44319153, 18.47791672],
              [-66.44291687, 18.47791672],
              [-66.44291687, 18.47847176],
              [-66.44264221, 18.47847176],
              [-66.44264221, 18.48041725],
              [-66.44235992, 18.48041725],
              [-66.44235992, 18.48152733],
              [-66.44208527, 18.48152733],
              [-66.44208527, 18.4818058],
              [-66.44180298, 18.4818058],
              [-66.44180298, 18.48374939],
              [-66.44152832, 18.48374939],
              [-66.44152832, 18.48430634],
              [-66.44125366, 18.48430634],
              [-66.44125366, 18.48485947],
              [-66.44069672, 18.48485947],
              [-66.44069672, 18.48513794],
              [-66.44013977, 18.48513794],
              [-66.44013977, 18.48541641],
              [-66.43985748, 18.48541641],
              [-66.43985748, 18.48569489],
              [-66.43930817, 18.48569489],
              [-66.43930817, 18.48596954],
              [-66.43875122, 18.48596954],
              [-66.43875122, 18.48625183],
              [-66.43858337, 18.48592567],
              [-66.43940735, 18.46210861],
              [-66.43981934, 18.45115089],
              [-66.43980408, 18.44781494],
              [-66.44023132, 18.43971443],
              [-66.44018555, 18.42971039],
              [-66.4396286, 18.40970421],
              [-66.44008636, 18.40684319],
              [-66.44191742, 18.40206528],
              [-66.448349, 18.39155769],
              [-66.44879913, 18.3891716],
              [-66.4487915, 18.38726616],
              [-66.4469223, 18.38346481],
              [-66.44551849, 18.38013458],
              [-66.44504547, 18.37727737],
              [-66.4440918, 18.37108803],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 47,
        name: 'Manatí',
        hasc_1: 'PR.MT',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.54343414, 18.35014153, -66.43858337, 18.48625183],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.48',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.97797394, 18.14383316],
              [-66.98957825, 18.14848137],
              [-66.99282837, 18.15035248],
              [-66.99330139, 18.15130424],
              [-66.99376678, 18.15177536],
              [-66.99377441, 18.1522522],
              [-66.99378204, 18.15320587],
              [-66.99472809, 18.15510178],
              [-66.99658966, 18.15651131],
              [-66.99751282, 18.15650177],
              [-66.99890137, 18.15696716],
              [-67.00213623, 18.15692711],
              [-67.00444794, 18.15738106],
              [-67.0072403, 18.15925598],
              [-67.01281738, 18.16253662],
              [-67.02163696, 18.16625214],
              [-67.02626038, 18.1666832],
              [-67.02672577, 18.16667747],
              [-67.03505707, 18.16801262],
              [-67.04384613, 18.16934395],
              [-67.04756165, 18.17120934],
              [-67.04896545, 18.17214966],
              [-67.05082703, 18.17403603],
              [-67.05175018, 18.17402458],
              [-67.04669952, 18.1764679],
              [-67.04576874, 18.17647743],
              [-67.04347229, 18.17745781],
              [-67.04071045, 18.17844391],
              [-67.03659821, 18.18278313],
              [-67.03477478, 18.18566513],
              [-67.02368164, 18.18483543],
              [-67.01953888, 18.18679047],
              [-67.01816559, 18.18823433],
              [-67.0200882, 18.19536781],
              [-67.00389862, 18.19363785],
              [-66.99239349, 18.19900894],
              [-66.99194336, 18.19996643],
              [-66.99102783, 18.20045471],
              [-66.9905777, 18.20189095],
              [-66.98922729, 18.20524216],
              [-66.97907257, 18.20773506],
              [-66.97769165, 18.20822716],
              [-66.97722626, 18.20775604],
              [-66.96889496, 18.20689011],
              [-66.9679718, 18.20689964],
              [-66.95819855, 18.19984818],
              [-66.91513824, 18.19218063],
              [-66.897995, 18.1875782],
              [-66.8952179, 18.18713188],
              [-66.88781738, 18.1867218],
              [-66.87904358, 18.18728447],
              [-66.87627411, 18.18730927],
              [-66.87488556, 18.18732452],
              [-66.87071991, 18.18688965],
              [-66.86749268, 18.18739319],
              [-66.86379242, 18.18695259],
              [-66.86377716, 18.18504524],
              [-66.86330414, 18.18409348],
              [-66.8609314, 18.17696381],
              [-66.86045837, 18.1755352],
              [-66.85858917, 18.1736412],
              [-66.85024261, 18.17038155],
              [-66.84793854, 18.17088127],
              [-66.84655762, 18.17137146],
              [-66.84425354, 18.17186928],
              [-66.8368454, 18.17050743],
              [-66.8377533, 18.16811371],
              [-66.83866882, 18.1671524],
              [-66.84369659, 18.16090584],
              [-66.84786224, 18.1622982],
              [-66.85018158, 18.16275406],
              [-66.85419464, 18.14602089],
              [-66.85597992, 18.13932419],
              [-66.85689545, 18.13836288],
              [-66.85827637, 18.1378727],
              [-66.85967255, 18.13833618],
              [-66.86151886, 18.13832092],
              [-66.86289978, 18.13830566],
              [-66.87030029, 18.13871384],
              [-66.87541199, 18.14248276],
              [-66.878685, 18.14674377],
              [-66.88612366, 18.15239525],
              [-66.88751984, 18.15333748],
              [-66.88890839, 18.15332794],
              [-66.88981628, 18.15236282],
              [-66.89025116, 18.14901733],
              [-66.89116669, 18.14805412],
              [-66.90131378, 18.14652634],
              [-66.9031601, 18.14603043],
              [-66.90408325, 18.14649773],
              [-66.91152954, 18.15167046],
              [-66.91524506, 18.15402031],
              [-66.92538452, 18.15153503],
              [-66.92630005, 18.15104675],
              [-66.93460083, 18.14905739],
              [-66.93505859, 18.14857483],
              [-66.93782806, 18.14806747],
              [-66.93874359, 18.14805984],
              [-66.9498291, 18.14699554],
              [-66.95120239, 18.14649963],
              [-66.95812988, 18.14595032],
              [-66.96089935, 18.14592171],
              [-66.9641571, 18.14827538],
              [-66.96828461, 18.14489174],
              [-66.9705658, 18.14200211],
              [-66.97797394, 18.14383316],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 48,
        name: 'Maricao',
        hasc_1: 'PR.MR',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-67.05175018, 18.1378727, -66.8368454, 18.20822716],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.49',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-65.85034943, 18.01124954],
              [-65.85041809, 18.01097298],
              [-65.85069275, 18.01097298],
              [-65.85069275, 18.0106945],
              [-65.85097504, 18.0106945],
              [-65.85097504, 18.01013947],
              [-65.85124969, 18.01013947],
              [-65.85124969, 18.00986099],
              [-65.85180664, 18.00986099],
              [-65.85180664, 18.00958252],
              [-65.8520813, 18.00958252],
              [-65.8520813, 18.00875092],
              [-65.85236359, 18.00875092],
              [-65.85236359, 18.0079174],
              [-65.85263824, 18.0079174],
              [-65.85263824, 18.00763893],
              [-65.8529129, 18.00763893],
              [-65.8529129, 18.00736046],
              [-65.85346985, 18.00736046],
              [-65.85346985, 18.00708389],
              [-65.85402679, 18.00708389],
              [-65.85402679, 18.00680542],
              [-65.85514069, 18.00680542],
              [-65.85514069, 18.00652695],
              [-65.85569763, 18.00652695],
              [-65.85569763, 18.00569534],
              [-65.85597229, 18.00569534],
              [-65.85597229, 18.00541687],
              [-65.85624695, 18.00541687],
              [-65.85624695, 18.0051384],
              [-65.85652924, 18.0051384],
              [-65.85652924, 18.00486183],
              [-65.85680389, 18.00486183],
              [-65.85680389, 18.00458336],
              [-65.85902405, 18.00458336],
              [-65.85902405, 18.00486183],
              [-65.85930634, 18.00486183],
              [-65.85930634, 18.0051384],
              [-65.85986328, 18.0051384],
              [-65.85986328, 18.00541687],
              [-65.86069489, 18.00541687],
              [-65.86069489, 18.00569534],
              [-65.86180878, 18.00569534],
              [-65.86180878, 18.00597382],
              [-65.86208344, 18.00597382],
              [-65.86208344, 18.00625038],
              [-65.86402893, 18.00625038],
              [-65.86402893, 18.00652695],
              [-65.86430359, 18.00652695],
              [-65.86430359, 18.00680542],
              [-65.86486053, 18.00680542],
              [-65.86486053, 18.00708389],
              [-65.86513519, 18.00708389],
              [-65.86513519, 18.00680542],
              [-65.86569214, 18.00680542],
              [-65.86569214, 18.00625038],
              [-65.86597443, 18.00625038],
              [-65.86597443, 18.00569534],
              [-65.86708069, 18.00569534],
              [-65.86708069, 18.00597382],
              [-65.86763763, 18.00597382],
              [-65.86763763, 18.00625038],
              [-65.86819458, 18.00625038],
              [-65.86819458, 18.00652695],
              [-65.86875153, 18.00652695],
              [-65.86875153, 18.00680542],
              [-65.86985779, 18.00680542],
              [-65.86985779, 18.00652695],
              [-65.87041473, 18.00652695],
              [-65.87041473, 18.00625038],
              [-65.87069702, 18.00625038],
              [-65.87069702, 18.00597382],
              [-65.87097168, 18.00597382],
              [-65.87097168, 18.00569534],
              [-65.87124634, 18.00569534],
              [-65.87124634, 18.0051384],
              [-65.87152863, 18.0051384],
              [-65.87152863, 18.00486183],
              [-65.87180328, 18.00486183],
              [-65.87180328, 18.00430489],
              [-65.87208557, 18.00430489],
              [-65.87208557, 18.00402832],
              [-65.87264252, 18.00402832],
              [-65.87264252, 18.00375175],
              [-65.87291718, 18.00375175],
              [-65.87291718, 18.00347137],
              [-65.87319183, 18.00347137],
              [-65.87319183, 18.00319481],
              [-65.87374878, 18.00319481],
              [-65.87374878, 18.00291634],
              [-65.87430573, 18.00291634],
              [-65.87430573, 18.00264168],
              [-65.87458038, 18.00264168],
              [-65.87458038, 18.0023613],
              [-65.87486267, 18.0023613],
              [-65.87486267, 18.00208282],
              [-65.87541962, 18.00208282],
              [-65.87541962, 18.00152969],
              [-65.87569427, 18.00152969],
              [-65.87569427, 18.00097275],
              [-65.87596893, 18.00097275],
              [-65.87596893, 18.0004158],
              [-65.87625122, 18.0004158],
              [-65.87625122, 18.00013733],
              [-65.87625122, 17.99986076],
              [-65.87625122, 17.9995842],
              [-65.87708282, 17.9995842],
              [-65.87708282, 17.99930763],
              [-65.87763977, 17.99930763],
              [-65.87763977, 17.99902534],
              [-65.87791443, 17.99902534],
              [-65.87791443, 17.99875069],
              [-65.87819672, 17.99875069],
              [-65.87819672, 17.99847221],
              [-65.87847137, 17.99847221],
              [-65.87847137, 17.99791527],
              [-65.87819672, 17.99791527],
              [-65.87819672, 17.99736023],
              [-65.87847137, 17.99736023],
              [-65.87847137, 17.99708366],
              [-65.87875366, 17.99708366],
              [-65.87875366, 17.99625015],
              [-65.87902832, 17.99625015],
              [-65.87902832, 17.99597168],
              [-65.87930298, 17.99597168],
              [-65.87930298, 17.99541664],
              [-65.87958527, 17.99541664],
              [-65.87958527, 17.99513817],
              [-65.87985992, 17.99513817],
              [-65.87985992, 17.99458313],
              [-65.88014221, 17.99458313],
              [-65.88014221, 17.99402809],
              [-65.88041687, 17.99402809],
              [-65.88041687, 17.99347115],
              [-65.88069153, 17.99347115],
              [-65.88069153, 17.99319458],
              [-65.88097382, 17.99319458],
              [-65.88097382, 17.99291611],
              [-65.88124847, 17.99291611],
              [-65.88124847, 17.99263954],
              [-65.88153076, 17.99263954],
              [-65.88153076, 17.99236107],
              [-65.88208008, 17.99236107],
              [-65.88208008, 17.9920826],
              [-65.88236237, 17.9920826],
              [-65.88236237, 17.99180603],
              [-65.88263702, 17.99180603],
              [-65.88263702, 17.99152756],
              [-65.88291931, 17.99152756],
              [-65.88291931, 17.99097252],
              [-65.88319397, 17.99097252],
              [-65.88319397, 17.99041748],
              [-65.88375092, 17.99041748],
              [-65.88375092, 17.99014091],
              [-65.88402557, 17.99014091],
              [-65.88402557, 17.98958397],
              [-65.88430786, 17.98958397],
              [-65.88430786, 17.98791885],
              [-65.88402557, 17.98791885],
              [-65.88402557, 17.98763657],
              [-65.88430786, 17.98763657],
              [-65.88430786, 17.98736191],
              [-65.88458252, 17.98736191],
              [-65.88458252, 17.98680496],
              [-65.88513947, 17.98680496],
              [-65.88513947, 17.98736191],
              [-65.88569641, 17.98736191],
              [-65.88569641, 17.98763657],
              [-65.88597107, 17.98763657],
              [-65.88597107, 17.98791885],
              [-65.88625336, 17.98791885],
              [-65.88625336, 17.98819351],
              [-65.88680267, 17.98819351],
              [-65.88680267, 17.98875046],
              [-65.88708496, 17.98875046],
              [-65.88708496, 17.98902893],
              [-65.88735962, 17.98902893],
              [-65.88735962, 17.98958397],
              [-65.88764191, 17.98958397],
              [-65.88764191, 17.9893055],
              [-65.88791656, 17.9893055],
              [-65.88791656, 17.98986053],
              [-65.88847351, 17.98986053],
              [-65.88847351, 17.99014091],
              [-65.88874817, 17.99014091],
              [-65.88874817, 17.99069405],
              [-65.88903046, 17.99069405],
              [-65.88903046, 17.99097252],
              [-65.88930511, 17.99097252],
              [-65.88930511, 17.99152756],
              [-65.88957977, 17.99152756],
              [-65.88957977, 17.9920826],
              [-65.89041901, 17.9920826],
              [-65.89041901, 17.99180603],
              [-65.89097595, 17.99180603],
              [-65.89097595, 17.9920826],
              [-65.8923645, 17.9920826],
              [-65.8923645, 17.99180603],
              [-65.89291382, 17.99180603],
              [-65.89291382, 17.99152756],
              [-65.89347076, 17.99152756],
              [-65.89347076, 17.99124908],
              [-65.89402771, 17.99124908],
              [-65.89402771, 17.99097252],
              [-65.89485931, 17.99097252],
              [-65.89485931, 17.99069405],
              [-65.89569092, 17.99069405],
              [-65.89569092, 17.99014091],
              [-65.89653015, 17.99014091],
              [-65.89653015, 17.99069405],
              [-65.89680481, 17.99069405],
              [-65.89680481, 17.99097252],
              [-65.8970871, 17.99097252],
              [-65.8970871, 17.98986053],
              [-65.89736176, 17.98986053],
              [-65.89736176, 17.98958397],
              [-65.8979187, 17.98958397],
              [-65.8979187, 17.9893055],
              [-65.89875031, 17.9893055],
              [-65.89875031, 17.98902893],
              [-65.89930725, 17.98902893],
              [-65.89930725, 17.98875046],
              [-65.89958191, 17.98875046],
              [-65.89958191, 17.98847198],
              [-65.8998642, 17.98847198],
              [-65.8998642, 17.98819351],
              [-65.90097046, 17.98819351],
              [-65.90097046, 17.98791885],
              [-65.9015274, 17.98791885],
              [-65.9015274, 17.98763657],
              [-65.90180206, 17.98763657],
              [-65.90180206, 17.98736191],
              [-65.90208435, 17.98736191],
              [-65.90208435, 17.98708344],
              [-65.90235901, 17.98708344],
              [-65.90235901, 17.98680496],
              [-65.9026413, 17.98680496],
              [-65.9026413, 17.98652649],
              [-65.90319824, 17.98652649],
              [-65.90319824, 17.98624992],
              [-65.90374756, 17.98624992],
              [-65.90374756, 17.98597145],
              [-65.90402985, 17.98597145],
              [-65.90402985, 17.98541641],
              [-65.9043045, 17.98541641],
              [-65.9043045, 17.98513794],
              [-65.90486145, 17.98513794],
              [-65.90486145, 17.98486137],
              [-65.9054184, 17.98486137],
              [-65.9054184, 17.9845829],
              [-65.90569305, 17.9845829],
              [-65.90569305, 17.98430443],
              [-65.90597534, 17.98430443],
              [-65.90597534, 17.98402786],
              [-65.90625, 17.98402786],
              [-65.90625, 17.98374939],
              [-65.90652466, 17.98374939],
              [-65.90652466, 17.98347282],
              [-65.90680695, 17.98347282],
              [-65.90680695, 17.98319435],
              [-65.9070816, 17.98319435],
              [-65.9070816, 17.98291588],
              [-65.90736389, 17.98291588],
              [-65.90736389, 17.98263931],
              [-65.90763855, 17.98263931],
              [-65.90763855, 17.98236084],
              [-65.90791321, 17.98236084],
              [-65.90791321, 17.98208427],
              [-65.9081955, 17.98208427],
              [-65.9081955, 17.9818058],
              [-65.90847015, 17.9818058],
              [-65.90847015, 17.98125076],
              [-65.90875244, 17.98125076],
              [-65.90875244, 17.98084641],
              [-65.90875244, 17.98069382],
              [-65.9090271, 17.98069382],
              [-65.9090271, 17.98041725],
              [-65.90920258, 17.98041725],
              [-65.90921783, 17.98054504],
              [-65.91290283, 17.9848423],
              [-65.91474915, 17.98484421],
              [-65.91751862, 17.9858036],
              [-65.92028046, 17.98484993],
              [-65.9258194, 17.98581123],
              [-65.92765808, 17.98724365],
              [-65.93457794, 17.99011421],
              [-65.94195557, 17.99202538],
              [-65.94334412, 17.99250793],
              [-65.95256042, 18.00635338],
              [-65.95348358, 18.00683212],
              [-65.9548645, 18.01351929],
              [-65.9548645, 18.0163765],
              [-65.9548645, 18.01781082],
              [-65.9525528, 18.02305794],
              [-65.95993042, 18.02831268],
              [-65.96316528, 18.02878761],
              [-65.96546936, 18.02831459],
              [-65.97008514, 18.02449608],
              [-65.97146606, 18.02449799],
              [-65.97193146, 18.02449799],
              [-65.97238922, 18.02545357],
              [-65.98161316, 18.03500175],
              [-65.98576355, 18.04550171],
              [-65.98760986, 18.05409241],
              [-65.98853302, 18.05886459],
              [-65.9871521, 18.05933952],
              [-65.97792053, 18.06124687],
              [-65.97377014, 18.05456543],
              [-65.97238159, 18.0526619],
              [-65.96915436, 18.05313301],
              [-65.96638489, 18.05408859],
              [-65.96453857, 18.05408669],
              [-65.95807648, 18.05217552],
              [-65.95761108, 18.05122185],
              [-65.95207977, 18.04453659],
              [-65.95162201, 18.04501534],
              [-65.94100952, 18.04023361],
              [-65.93408966, 18.03688622],
              [-65.9336319, 18.03688622],
              [-65.92993927, 18.03688622],
              [-65.92301941, 18.03496933],
              [-65.9119339, 18.03305054],
              [-65.90732574, 18.03591156],
              [-65.90640259, 18.03543282],
              [-65.89763641, 18.03399086],
              [-65.88794708, 18.03016472],
              [-65.88195801, 18.02252007],
              [-65.88104248, 18.02061081],
              [-65.87458038, 18.0234623],
              [-65.87181091, 18.02441597],
              [-65.86765289, 18.02584267],
              [-65.86442566, 18.02583885],
              [-65.86304474, 18.02344894],
              [-65.86212158, 18.02201653],
              [-65.85751343, 18.01675797],
              [-65.85382843, 18.01484299],
              [-65.85152435, 18.01244926],
              [-65.8506012, 18.0114975],
              [-65.85034943, 18.01124954],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 49,
        name: 'Maunabo',
        hasc_1: 'PR.MB',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-65.98853302, 17.98041725, -65.85034943, 18.06124687],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.52',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.36523437, 18.33330917],
              [-66.36569214, 18.33187675],
              [-66.36843109, 18.32185745],
              [-66.37208557, 18.31040382],
              [-66.37254333, 18.3089695],
              [-66.37297821, 18.30229568],
              [-66.3734436, 18.30134201],
              [-66.37574005, 18.29799461],
              [-66.3780365, 18.29417229],
              [-66.38172913, 18.29225349],
              [-66.38404083, 18.2903347],
              [-66.38632202, 18.28412819],
              [-66.38861084, 18.27696609],
              [-66.39091492, 18.2736187],
              [-66.39875793, 18.26882172],
              [-66.40522003, 18.26593018],
              [-66.40891266, 18.26353073],
              [-66.41214752, 18.26303864],
              [-66.42601776, 18.26106834],
              [-66.42832947, 18.26058197],
              [-66.43017578, 18.2600975],
              [-66.43663025, 18.25672722],
              [-66.43755341, 18.25624847],
              [-66.44450378, 18.25764656],
              [-66.45513153, 18.25759315],
              [-66.45559692, 18.25759125],
              [-66.45790863, 18.25805473],
              [-66.45884705, 18.25995445],
              [-66.45792389, 18.26091957],
              [-66.45935059, 18.26948929],
              [-66.46123505, 18.27663231],
              [-66.46031952, 18.2775898],
              [-66.46031952, 18.27854347],
              [-66.46125793, 18.2804451],
              [-66.46450043, 18.28281593],
              [-66.46496582, 18.28328705],
              [-66.46134949, 18.29951286],
              [-66.45718384, 18.30001068],
              [-66.45625305, 18.3000164],
              [-66.45487213, 18.30002213],
              [-66.45487213, 18.30049896],
              [-66.45487976, 18.30192947],
              [-66.45446777, 18.31289482],
              [-66.45407104, 18.32814789],
              [-66.45455933, 18.33291245],
              [-66.46109009, 18.34431839],
              [-66.46342468, 18.34811974],
              [-66.46389771, 18.34954834],
              [-66.46483612, 18.35240173],
              [-66.46440887, 18.36097717],
              [-66.46307373, 18.37147331],
              [-66.4440918, 18.37108803],
              [-66.42880249, 18.37068367],
              [-66.42787933, 18.37116241],
              [-66.42420197, 18.37594795],
              [-66.4214325, 18.37786293],
              [-66.41819, 18.3778801],
              [-66.41679382, 18.37741089],
              [-66.41725159, 18.37454796],
              [-66.41585541, 18.37312508],
              [-66.41492462, 18.37217712],
              [-66.40516663, 18.36602211],
              [-66.40099335, 18.36365891],
              [-66.39546967, 18.3717823],
              [-66.39128113, 18.36655998],
              [-66.39081573, 18.36608505],
              [-66.38895416, 18.36275864],
              [-66.38894653, 18.36180496],
              [-66.38891602, 18.35322762],
              [-66.38703918, 18.34751701],
              [-66.38610077, 18.34466171],
              [-66.3814621, 18.34229851],
              [-66.37915039, 18.34278679],
              [-66.37730408, 18.34422493],
              [-66.37127686, 18.34043694],
              [-66.37126923, 18.33805275],
              [-66.37033844, 18.33710289],
              [-66.36569214, 18.3337822],
              [-66.36523437, 18.33330917],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 52,
        name: 'Morovis',
        hasc_1: 'PR.MV',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.46496582, 18.25624847, -66.36523437, 18.3778801],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.50',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-67.48236084, 18.38986015],
              [-67.48236084, 18.38958359],
              [-67.47958374, 18.38958359],
              [-67.47958374, 18.38930511],
              [-67.47930908, 18.38930511],
              [-67.47930908, 18.38903046],
              [-67.47846985, 18.38903046],
              [-67.47846985, 18.38874817],
              [-67.47819519, 18.38874817],
              [-67.47819519, 18.38819504],
              [-67.47763824, 18.38819504],
              [-67.47763824, 18.38763618],
              [-67.4770813, 18.38763618],
              [-67.4770813, 18.38736153],
              [-67.47680664, 18.38736153],
              [-67.47680664, 18.38708305],
              [-67.47652435, 18.38708305],
              [-67.47652435, 18.38680649],
              [-67.47624969, 18.38680649],
              [-67.47624969, 18.38624954],
              [-67.47597504, 18.38624954],
              [-67.47597504, 18.38597298],
              [-67.47569275, 18.38597298],
              [-67.47569275, 18.3856945],
              [-67.47541809, 18.3856945],
              [-67.47541809, 18.38541412],
              [-67.4751358, 18.38541412],
              [-67.4751358, 18.38513947],
              [-67.47486115, 18.38513947],
              [-67.47486115, 18.38458252],
              [-67.47402954, 18.38458252],
              [-67.47402954, 18.38430405],
              [-67.47291565, 18.38430405],
              [-67.47291565, 18.38402748],
              [-67.47208405, 18.38402748],
              [-67.47208405, 18.38319588],
              [-67.4723587, 18.38319588],
              [-67.4723587, 18.38236046],
              [-67.47319794, 18.38236046],
              [-67.47319794, 18.38208389],
              [-67.4734726, 18.38208389],
              [-67.4734726, 18.38180542],
              [-67.47374725, 18.38180542],
              [-67.47374725, 18.38152695],
              [-67.47402954, 18.38152695],
              [-67.47402954, 18.38125038],
              [-67.47458649, 18.38125038],
              [-67.47458649, 18.38097382],
              [-67.47486115, 18.38097382],
              [-67.47486115, 18.38069534],
              [-67.4751358, 18.38069534],
              [-67.4751358, 18.38041687],
              [-67.47541809, 18.38041687],
              [-67.47541809, 18.3801384],
              [-67.47624969, 18.3801384],
              [-67.47624969, 18.37846947],
              [-67.47652435, 18.37846947],
              [-67.47652435, 18.37819481],
              [-67.47680664, 18.37819481],
              [-67.47680664, 18.37791634],
              [-67.4779129, 18.37791634],
              [-67.4779129, 18.37735939],
              [-67.4798584, 18.37735939],
              [-67.4798584, 18.37791634],
              [-67.48097229, 18.37791634],
              [-67.48097229, 18.37846947],
              [-67.48152924, 18.37846947],
              [-67.48152924, 18.37875175],
              [-67.48208618, 18.37875175],
              [-67.48208618, 18.37902832],
              [-67.48236084, 18.37902832],
              [-67.48236084, 18.37930489],
              [-67.48319244, 18.37930489],
              [-67.48319244, 18.37958336],
              [-67.48374939, 18.37958336],
              [-67.48374939, 18.37986374],
              [-67.48458099, 18.37986374],
              [-67.48458099, 18.3801384],
              [-67.48569489, 18.3801384],
              [-67.48569489, 18.38041687],
              [-67.48596954, 18.38041687],
              [-67.48596954, 18.38069534],
              [-67.48625183, 18.38069534],
              [-67.48625183, 18.38125038],
              [-67.48652649, 18.38125038],
              [-67.48652649, 18.38180542],
              [-67.48680878, 18.38180542],
              [-67.48680878, 18.38208389],
              [-67.48708344, 18.38208389],
              [-67.48708344, 18.38236046],
              [-67.48764038, 18.38236046],
              [-67.48764038, 18.3829174],
              [-67.48791504, 18.3829174],
              [-67.48791504, 18.38347244],
              [-67.48847198, 18.38347244],
              [-67.48847198, 18.38541412],
              [-67.48819733, 18.38541412],
              [-67.48819733, 18.3856945],
              [-67.48764038, 18.3856945],
              [-67.48764038, 18.38597298],
              [-67.48708344, 18.38597298],
              [-67.48708344, 18.38624954],
              [-67.48625183, 18.38624954],
              [-67.48625183, 18.38652611],
              [-67.48596954, 18.38652611],
              [-67.48596954, 18.38680649],
              [-67.48569489, 18.38680649],
              [-67.48569489, 18.38930511],
              [-67.48513794, 18.38930511],
              [-67.48513794, 18.38958359],
              [-67.48486328, 18.38958359],
              [-67.48486328, 18.38986015],
              [-67.48236084, 18.38986015],
            ],
          ],
          [
            [
              [-67.08614349, 18.14977455],
              [-67.08892822, 18.15117264],
              [-67.08985901, 18.15163612],
              [-67.09778595, 18.15870094],
              [-67.10147095, 18.15770149],
              [-67.10379028, 18.15815353],
              [-67.1075058, 18.15953827],
              [-67.10889435, 18.15999794],
              [-67.11119843, 18.15949249],
              [-67.11489105, 18.15944862],
              [-67.11856842, 18.15797234],
              [-67.12086487, 18.15603828],
              [-67.12365723, 18.1579113],
              [-67.12596893, 18.15836143],
              [-67.1292038, 18.15831947],
              [-67.13149261, 18.15638542],
              [-67.13286591, 18.15541077],
              [-67.13698578, 18.15202141],
              [-67.13885498, 18.15390778],
              [-67.14303589, 18.15576553],
              [-67.15782166, 18.15605545],
              [-67.15963745, 18.15317154],
              [-67.16381073, 18.15502739],
              [-67.16705322, 18.15545845],
              [-67.17125702, 18.15922737],
              [-67.17314148, 18.1620636],
              [-67.17501068, 18.16394806],
              [-67.17733002, 18.16487122],
              [-67.1809082, 18.16847229],
              [-67.18041992, 18.16847229],
              [-67.18041992, 18.16875076],
              [-67.18013763, 18.16875076],
              [-67.18013763, 18.16902733],
              [-67.17986298, 18.16902733],
              [-67.17986298, 18.16958427],
              [-67.17958069, 18.16958427],
              [-67.17958069, 18.16986084],
              [-67.17930603, 18.16986084],
              [-67.17930603, 18.17013931],
              [-67.17903137, 18.17013931],
              [-67.17903137, 18.17097282],
              [-67.17847443, 18.17097282],
              [-67.17847443, 18.17124939],
              [-67.17763519, 18.17124939],
              [-67.17763519, 18.17180634],
              [-67.17736053, 18.17180634],
              [-67.17736053, 18.17236137],
              [-67.17652893, 18.17236137],
              [-67.17652893, 18.17319489],
              [-67.17624664, 18.17319489],
              [-67.17624664, 18.17402649],
              [-67.17569733, 18.17402649],
              [-67.17569733, 18.17430496],
              [-67.17541504, 18.17430496],
              [-67.17541504, 18.17458344],
              [-67.17569733, 18.17458344],
              [-67.17569733, 18.17486191],
              [-67.17541504, 18.17486191],
              [-67.17541504, 18.17514038],
              [-67.17514038, 18.17514038],
              [-67.17514038, 18.17541885],
              [-67.17485809, 18.17541885],
              [-67.17485809, 18.17569351],
              [-67.17458344, 18.17569351],
              [-67.17458344, 18.17624855],
              [-67.17430878, 18.17624855],
              [-67.17430878, 18.1768055],
              [-67.17402649, 18.1768055],
              [-67.17402649, 18.17708397],
              [-67.17375183, 18.17708397],
              [-67.17375183, 18.17735863],
              [-67.17319489, 18.17735863],
              [-67.17319489, 18.17764091],
              [-67.17292023, 18.17764091],
              [-67.17292023, 18.17819405],
              [-67.17263794, 18.17819405],
              [-67.17263794, 18.17874908],
              [-67.17236328, 18.17874908],
              [-67.17236328, 18.17902756],
              [-67.17208099, 18.17902756],
              [-67.17208099, 18.1795826],
              [-67.17180634, 18.1795826],
              [-67.17180634, 18.17986107],
              [-67.17152405, 18.17986107],
              [-67.17152405, 18.18013954],
              [-67.17124939, 18.18013954],
              [-67.17124939, 18.18041611],
              [-67.17097473, 18.18041611],
              [-67.17097473, 18.18124962],
              [-67.17069244, 18.18124962],
              [-67.17069244, 18.18152809],
              [-67.17041779, 18.18152809],
              [-67.17041779, 18.18180466],
              [-67.1701355, 18.18180466],
              [-67.1701355, 18.1823616],
              [-67.16986084, 18.1823616],
              [-67.16986084, 18.18263817],
              [-67.16958618, 18.18263817],
              [-67.16958618, 18.18291664],
              [-67.16930389, 18.18291664],
              [-67.16930389, 18.18319321],
              [-67.16902924, 18.18319321],
              [-67.16902924, 18.18347168],
              [-67.16874695, 18.18347168],
              [-67.16874695, 18.18375015],
              [-67.16847229, 18.18375015],
              [-67.16847229, 18.18402863],
              [-67.16819763, 18.18402863],
              [-67.16819763, 18.18430519],
              [-67.16791534, 18.18430519],
              [-67.16791534, 18.18458366],
              [-67.16764069, 18.18458366],
              [-67.16764069, 18.18486023],
              [-67.16708374, 18.18486023],
              [-67.16708374, 18.18541527],
              [-67.16680908, 18.18541527],
              [-67.16680908, 18.18569374],
              [-67.16652679, 18.18569374],
              [-67.16652679, 18.18625069],
              [-67.16625214, 18.18625069],
              [-67.16625214, 18.18652534],
              [-67.1654129, 18.18652534],
              [-67.1654129, 18.18680573],
              [-67.16513824, 18.18680573],
              [-67.16513824, 18.1870842],
              [-67.1645813, 18.1870842],
              [-67.1645813, 18.18736076],
              [-67.16402435, 18.18736076],
              [-67.16402435, 18.18763733],
              [-67.16347504, 18.18763733],
              [-67.16347504, 18.1879158],
              [-67.16319275, 18.1879158],
              [-67.16319275, 18.18819427],
              [-67.1626358, 18.18819427],
              [-67.1626358, 18.18847275],
              [-67.16208649, 18.18847275],
              [-67.16208649, 18.18874741],
              [-67.1618042, 18.18874741],
              [-67.1618042, 18.18902969],
              [-67.16124725, 18.18902969],
              [-67.16124725, 18.18930626],
              [-67.16069794, 18.18930626],
              [-67.16069794, 18.18958282],
              [-67.16041565, 18.18958282],
              [-67.16041565, 18.18985939],
              [-67.16014099, 18.18985939],
              [-67.16014099, 18.19014168],
              [-67.15958405, 18.19014168],
              [-67.15958405, 18.19041634],
              [-67.1590271, 18.19041634],
              [-67.1590271, 18.19096947],
              [-67.15847015, 18.19096947],
              [-67.15847015, 18.19125175],
              [-67.1581955, 18.19125175],
              [-67.1581955, 18.19180489],
              [-67.15791321, 18.19180489],
              [-67.15791321, 18.19208336],
              [-67.15763855, 18.19208336],
              [-67.15763855, 18.1926384],
              [-67.1570816, 18.1926384],
              [-67.1570816, 18.19291687],
              [-67.15680695, 18.19291687],
              [-67.15680695, 18.19347382],
              [-67.15652466, 18.19347382],
              [-67.15652466, 18.19375038],
              [-67.15625, 18.19375038],
              [-67.15625, 18.19430542],
              [-67.15597534, 18.19430542],
              [-67.15597534, 18.19486046],
              [-67.15569305, 18.19486046],
              [-67.15569305, 18.19513893],
              [-67.1554184, 18.19513893],
              [-67.1554184, 18.19569588],
              [-67.15513611, 18.19569588],
              [-67.15513611, 18.19597244],
              [-67.15486145, 18.19597244],
              [-67.15486145, 18.19652748],
              [-67.15458679, 18.19652748],
              [-67.15458679, 18.19708252],
              [-67.1543045, 18.19708252],
              [-67.1543045, 18.19736099],
              [-67.15458679, 18.19736099],
              [-67.15458679, 18.19763947],
              [-67.1543045, 18.19763947],
              [-67.1543045, 18.19847298],
              [-67.15402985, 18.19847298],
              [-67.15402985, 18.1993084],
              [-67.15374756, 18.1993084],
              [-67.15374756, 18.20153046],
              [-67.1534729, 18.20153046],
              [-67.1534729, 18.20236206],
              [-67.15319824, 18.20236206],
              [-67.15319824, 18.20264053],
              [-67.1534729, 18.20264053],
              [-67.1534729, 18.2029171],
              [-67.15319824, 18.2029171],
              [-67.15319824, 18.20375061],
              [-67.1534729, 18.20375061],
              [-67.1534729, 18.20402718],
              [-67.15319824, 18.20402718],
              [-67.15319824, 18.20430565],
              [-67.1534729, 18.20430565],
              [-67.1534729, 18.20541573],
              [-67.15374756, 18.20541573],
              [-67.15374756, 18.20680618],
              [-67.15402985, 18.20680618],
              [-67.15402985, 18.20736122],
              [-67.1543045, 18.20736122],
              [-67.1543045, 18.20763969],
              [-67.15458679, 18.20763969],
              [-67.15458679, 18.20819473],
              [-67.15486145, 18.20819473],
              [-67.15486145, 18.20874977],
              [-67.15513611, 18.20874977],
              [-67.15513611, 18.21013832],
              [-67.1554184, 18.21013832],
              [-67.1554184, 18.21069527],
              [-67.15597534, 18.21069527],
              [-67.15597534, 18.21097183],
              [-67.1570816, 18.21097183],
              [-67.1570816, 18.21125031],
              [-67.15791321, 18.21125031],
              [-67.15791321, 18.21152687],
              [-67.1581955, 18.21152687],
              [-67.1581955, 18.21125031],
              [-67.15875244, 18.21125031],
              [-67.15875244, 18.21152687],
              [-67.15930176, 18.21152687],
              [-67.15930176, 18.21208382],
              [-67.15958405, 18.21208382],
              [-67.15958405, 18.21291542],
              [-67.15930176, 18.21291542],
              [-67.15930176, 18.21347237],
              [-67.1590271, 18.21347237],
              [-67.1590271, 18.21375084],
              [-67.15875244, 18.21375084],
              [-67.15875244, 18.2140274],
              [-67.15847015, 18.2140274],
              [-67.15847015, 18.21458244],
              [-67.1581955, 18.21458244],
              [-67.1581955, 18.21652985],
              [-67.15847015, 18.21652985],
              [-67.15847015, 18.21708107],
              [-67.15958405, 18.21708107],
              [-67.15958405, 18.21680641],
              [-67.16041565, 18.21680641],
              [-67.16041565, 18.21736145],
              [-67.16069794, 18.21736145],
              [-67.16069794, 18.21763992],
              [-67.16124725, 18.21763992],
              [-67.16124725, 18.21791649],
              [-67.16291809, 18.21791649],
              [-67.16291809, 18.21819496],
              [-67.16319275, 18.21819496],
              [-67.16319275, 18.21846962],
              [-67.1645813, 18.21846962],
              [-67.1645813, 18.21819496],
              [-67.16486359, 18.21819496],
              [-67.16486359, 18.21846962],
              [-67.16513824, 18.21846962],
              [-67.16513824, 18.21875191],
              [-67.16569519, 18.21875191],
              [-67.16569519, 18.21902847],
              [-67.16625214, 18.21902847],
              [-67.16625214, 18.21930504],
              [-67.1673584, 18.21930504],
              [-67.1673584, 18.21958351],
              [-67.16764069, 18.21958351],
              [-67.16764069, 18.21986389],
              [-67.16819763, 18.21986389],
              [-67.16819763, 18.22013855],
              [-67.16847229, 18.22013855],
              [-67.16847229, 18.22069359],
              [-67.16874695, 18.22069359],
              [-67.16874695, 18.22097206],
              [-67.16958618, 18.22097206],
              [-67.16958618, 18.22125053],
              [-67.1701355, 18.22125053],
              [-67.1701355, 18.22291756],
              [-67.17069244, 18.22291756],
              [-67.17069244, 18.22319412],
              [-67.17097473, 18.22319412],
              [-67.17097473, 18.22374916],
              [-67.17124939, 18.22374916],
              [-67.17124939, 18.22402763],
              [-67.17180634, 18.22402763],
              [-67.17180634, 18.2243042],
              [-67.17236328, 18.2243042],
              [-67.17236328, 18.22486115],
              [-67.17263794, 18.22486115],
              [-67.17263794, 18.22541618],
              [-67.17292023, 18.22541618],
              [-67.17292023, 18.22569466],
              [-67.17346954, 18.22569466],
              [-67.17346954, 18.22597313],
              [-67.17375183, 18.22597313],
              [-67.17375183, 18.22652626],
              [-67.17402649, 18.22652626],
              [-67.17402649, 18.22791862],
              [-67.17346954, 18.22791862],
              [-67.17346954, 18.23069382],
              [-67.17375183, 18.23069382],
              [-67.17375183, 18.23125267],
              [-67.17346954, 18.23125267],
              [-67.17346954, 18.23569489],
              [-67.17375183, 18.23569489],
              [-67.17375183, 18.23763847],
              [-67.17346954, 18.23763847],
              [-67.17346954, 18.23958397],
              [-67.17375183, 18.23958397],
              [-67.17375183, 18.24180794],
              [-67.17402649, 18.24180794],
              [-67.17402649, 18.24291611],
              [-67.17430878, 18.24291611],
              [-67.17430878, 18.24375153],
              [-67.17458344, 18.24375153],
              [-67.17458344, 18.2448616],
              [-67.17485809, 18.2448616],
              [-67.17485809, 18.24513817],
              [-67.17514038, 18.24513817],
              [-67.17514038, 18.24569511],
              [-67.17541504, 18.24569511],
              [-67.17541504, 18.24680519],
              [-67.17569733, 18.24680519],
              [-67.17569733, 18.24791718],
              [-67.17597198, 18.24791718],
              [-67.17597198, 18.24875069],
              [-67.17624664, 18.24875069],
              [-67.17624664, 18.24902725],
              [-67.17652893, 18.24902725],
              [-67.17652893, 18.2495842],
              [-67.17680359, 18.2495842],
              [-67.17680359, 18.24986076],
              [-67.17708588, 18.24986076],
              [-67.17708588, 18.2504158],
              [-67.17736053, 18.2504158],
              [-67.17736053, 18.25069427],
              [-67.17763519, 18.25069427],
              [-67.17763519, 18.25097275],
              [-67.17791748, 18.25097275],
              [-67.17791748, 18.25152779],
              [-67.17847443, 18.25152779],
              [-67.17847443, 18.25180435],
              [-67.17874908, 18.25180435],
              [-67.17874908, 18.2523613],
              [-67.17903137, 18.2523613],
              [-67.17903137, 18.25319481],
              [-67.17930603, 18.25319481],
              [-67.17930603, 18.25374985],
              [-67.17958069, 18.25374985],
              [-67.17958069, 18.25430489],
              [-67.17986298, 18.25430489],
              [-67.17986298, 18.2551384],
              [-67.18041992, 18.2551384],
              [-67.18041992, 18.25569534],
              [-67.18069458, 18.25569534],
              [-67.18069458, 18.25597191],
              [-67.18096924, 18.25597191],
              [-67.18096924, 18.25652695],
              [-67.18152618, 18.25652695],
              [-67.18152618, 18.25708389],
              [-67.18180847, 18.25708389],
              [-67.18180847, 18.25735855],
              [-67.18208313, 18.25735855],
              [-67.18208313, 18.2579174],
              [-67.18235779, 18.2579174],
              [-67.18235779, 18.25819397],
              [-67.18264008, 18.25819397],
              [-67.18264008, 18.25847244],
              [-67.18291473, 18.25847244],
              [-67.18291473, 18.25875282],
              [-67.18319702, 18.25875282],
              [-67.18319702, 18.25902748],
              [-67.18347168, 18.25902748],
              [-67.18347168, 18.25930595],
              [-67.18374634, 18.25930595],
              [-67.18374634, 18.26013947],
              [-67.18402863, 18.26013947],
              [-67.18402863, 18.2606945],
              [-67.18430328, 18.2606945],
              [-67.18430328, 18.26097488],
              [-67.18486023, 18.26097488],
              [-67.18486023, 18.26124954],
              [-67.18514252, 18.26124954],
              [-67.18514252, 18.26180649],
              [-67.18541718, 18.26180649],
              [-67.18541718, 18.26236153],
              [-67.18569183, 18.26236153],
              [-67.18569183, 18.26263809],
              [-67.18597412, 18.26263809],
              [-67.18597412, 18.26291656],
              [-67.18624878, 18.26291656],
              [-67.18624878, 18.26319313],
              [-67.18653107, 18.26319313],
              [-67.18653107, 18.26375008],
              [-67.18680573, 18.26375008],
              [-67.18680573, 18.26402855],
              [-67.18708038, 18.26402855],
              [-67.18708038, 18.26430511],
              [-67.18736267, 18.26430511],
              [-67.18736267, 18.26458359],
              [-67.18763733, 18.26458359],
              [-67.18763733, 18.26486206],
              [-67.18819427, 18.26486206],
              [-67.18819427, 18.26513863],
              [-67.18846893, 18.26513863],
              [-67.18846893, 18.26541519],
              [-67.18875122, 18.26541519],
              [-67.18875122, 18.26569366],
              [-67.18902588, 18.26569366],
              [-67.18902588, 18.26621819],
              [-67.18180084, 18.26734734],
              [-67.17394257, 18.26792336],
              [-67.16610718, 18.26992989],
              [-67.1638031, 18.27091026],
              [-67.16197205, 18.27236557],
              [-67.16105652, 18.27332878],
              [-67.1494751, 18.2715683],
              [-67.13459015, 18.26460075],
              [-67.13547516, 18.26125336],
              [-67.13669586, 18.24693489],
              [-67.12323761, 18.24280739],
              [-67.11174011, 18.24819374],
              [-67.10624695, 18.25302696],
              [-67.10022736, 18.2526226],
              [-67.09558105, 18.25076866],
              [-67.09186554, 18.24938202],
              [-67.08863068, 18.24942017],
              [-67.08677673, 18.24896812],
              [-67.08175659, 18.2547493],
              [-67.07988739, 18.25333977],
              [-67.07567596, 18.24861908],
              [-67.07381439, 18.24768829],
              [-67.06222534, 18.24496269],
              [-67.05760956, 18.24596786],
              [-67.05622101, 18.24550819],
              [-67.05147552, 18.23459435],
              [-67.05146027, 18.23316383],
              [-67.05095673, 18.22935677],
              [-67.05001068, 18.22746277],
              [-67.04582214, 18.22464752],
              [-67.0369873, 18.21997833],
              [-67.03606415, 18.21951294],
              [-67.0328064, 18.21811676],
              [-67.03316498, 18.20809937],
              [-67.0187912, 18.2034893],
              [-67.0200882, 18.19536781],
              [-67.01816559, 18.18823433],
              [-67.01953888, 18.18679047],
              [-67.02368164, 18.18483543],
              [-67.03477478, 18.18566513],
              [-67.03659821, 18.18278313],
              [-67.04071045, 18.17844391],
              [-67.04347229, 18.17745781],
              [-67.04576874, 18.17647743],
              [-67.04669952, 18.1764679],
              [-67.05175018, 18.17402458],
              [-67.06053162, 18.17392159],
              [-67.06099701, 18.17391968],
              [-67.07206726, 18.17283821],
              [-67.07299805, 18.17282486],
              [-67.08171844, 18.16795349],
              [-67.08218384, 18.16794968],
              [-67.0844574, 18.16457939],
              [-67.08581543, 18.16170311],
              [-67.08712769, 18.15549088],
              [-67.0871048, 18.15357971],
              [-67.08614349, 18.14977455],
            ],
          ],
          [
            [
              [-67.94902802, 18.16180611],
              [-67.94902802, 18.16152954],
              [-67.94873047, 18.16152954],
              [-67.94819641, 18.16152954],
              [-67.94819641, 18.16124725],
              [-67.94763947, 18.16124725],
              [-67.94763947, 18.1609726],
              [-67.94736481, 18.1609726],
              [-67.94736481, 18.16069412],
              [-67.94708252, 18.16069412],
              [-67.94708252, 18.16013718],
              [-67.94680786, 18.16013718],
              [-67.94680786, 18.15930557],
              [-67.94652557, 18.15930557],
              [-67.94652557, 18.15791512],
              [-67.94680786, 18.15791512],
              [-67.94680786, 18.15763855],
              [-67.94708252, 18.15763855],
              [-67.94708252, 18.15736198],
              [-67.94791412, 18.15736198],
              [-67.94791412, 18.15763855],
              [-67.95124817, 18.15763855],
              [-67.95124817, 18.15791512],
              [-67.95153046, 18.15791512],
              [-67.95153046, 18.15819359],
              [-67.95180511, 18.15819359],
              [-67.95180511, 18.15859795],
              [-67.95180511, 18.16013718],
              [-67.95153046, 18.16013718],
              [-67.95153046, 18.16069412],
              [-67.95097351, 18.16069412],
              [-67.95097351, 18.16124725],
              [-67.95069122, 18.16124725],
              [-67.95069122, 18.16152954],
              [-67.95014191, 18.16152954],
              [-67.95014191, 18.16180611],
              [-67.94911957, 18.16180611],
              [-67.94902802, 18.16180611],
            ],
          ],
          [
            [
              [-67.86096954, 18.12264061],
              [-67.86096954, 18.12235832],
              [-67.86069489, 18.12235832],
              [-67.86069489, 18.12208366],
              [-67.86042023, 18.12208366],
              [-67.86042023, 18.12153053],
              [-67.86013794, 18.12153053],
              [-67.86013794, 18.12124825],
              [-67.85986328, 18.12124825],
              [-67.85986328, 18.12097168],
              [-67.85958099, 18.12097168],
              [-67.85958099, 18.12069511],
              [-67.85930634, 18.12069511],
              [-67.85930634, 18.12041855],
              [-67.85902405, 18.12041855],
              [-67.85902405, 18.12013626],
              [-67.85874939, 18.12013626],
              [-67.85874939, 18.11958313],
              [-67.85847473, 18.11958313],
              [-67.85847473, 18.11930466],
              [-67.85819244, 18.11930466],
              [-67.85819244, 18.11902618],
              [-67.85791779, 18.11902618],
              [-67.85791779, 18.11874962],
              [-67.8576355, 18.11874962],
              [-67.8576355, 18.11847305],
              [-67.85736084, 18.11847305],
              [-67.85736084, 18.11819458],
              [-67.85708618, 18.11819458],
              [-67.85708618, 18.1179142],
              [-67.85680389, 18.1179142],
              [-67.85680389, 18.11736107],
              [-67.85652924, 18.11736107],
              [-67.85652924, 18.1170826],
              [-67.85624695, 18.1170826],
              [-67.85624695, 18.11597252],
              [-67.85597229, 18.11597252],
              [-67.85597229, 18.11541748],
              [-67.85569763, 18.11541748],
              [-67.85569763, 18.11513901],
              [-67.85541534, 18.11513901],
              [-67.85541534, 18.11486053],
              [-67.85514069, 18.11486053],
              [-67.85514069, 18.11458206],
              [-67.8548584, 18.11458206],
              [-67.8548584, 18.1143055],
              [-67.85458374, 18.1143055],
              [-67.85458374, 18.11402702],
              [-67.85430908, 18.11402702],
              [-67.85430908, 18.11375046],
              [-67.85402679, 18.11375046],
              [-67.85402679, 18.11347389],
              [-67.85375214, 18.11347389],
              [-67.85375214, 18.11319351],
              [-67.8520813, 18.11319351],
              [-67.8520813, 18.11291695],
              [-67.85152435, 18.11291695],
              [-67.85152435, 18.11263847],
              [-67.85097504, 18.11263847],
              [-67.85097504, 18.11236382],
              [-67.8501358, 18.11236382],
              [-67.8501358, 18.11208344],
              [-67.84986115, 18.11208344],
              [-67.84986115, 18.11180496],
              [-67.84958649, 18.11180496],
              [-67.84958649, 18.1115284],
              [-67.8493042, 18.1115284],
              [-67.8493042, 18.11125183],
              [-67.84902954, 18.11125183],
              [-67.84902954, 18.11096954],
              [-67.84874725, 18.11096954],
              [-67.84874725, 18.11069489],
              [-67.8484726, 18.11069489],
              [-67.8484726, 18.11013985],
              [-67.84819794, 18.11013985],
              [-67.84819794, 18.1095829],
              [-67.84791565, 18.1095829],
              [-67.84791565, 18.10847282],
              [-67.84764099, 18.10847282],
              [-67.84764099, 18.10736084],
              [-67.8473587, 18.10736084],
              [-67.8473587, 18.10652733],
              [-67.84708405, 18.10652733],
              [-67.84708405, 18.10597229],
              [-67.84680176, 18.10597229],
              [-67.84680176, 18.10513878],
              [-67.8465271, 18.10513878],
              [-67.8465271, 18.10430527],
              [-67.84625244, 18.10430527],
              [-67.84625244, 18.10375023],
              [-67.84597015, 18.10375023],
              [-67.84597015, 18.10347176],
              [-67.8456955, 18.10347176],
              [-67.8456955, 18.10319328],
              [-67.84541321, 18.10319328],
              [-67.84541321, 18.10236168],
              [-67.84513855, 18.10236168],
              [-67.84513855, 18.10208321],
              [-67.84486389, 18.10208321],
              [-67.84486389, 18.10180473],
              [-67.8445816, 18.10180473],
              [-67.8445816, 18.10124969],
              [-67.84486389, 18.10124969],
              [-67.84486389, 18.09902763],
              [-67.8445816, 18.09902763],
              [-67.8445816, 18.0984726],
              [-67.84430695, 18.0984726],
              [-67.84430695, 18.09791756],
              [-67.84402466, 18.09791756],
              [-67.84402466, 18.09680557],
              [-67.84375, 18.09680557],
              [-67.84375, 18.09625053],
              [-67.84347534, 18.09625053],
              [-67.84347534, 18.09486198],
              [-67.84319305, 18.09486198],
              [-67.84319305, 18.09347153],
              [-67.8429184, 18.09347153],
              [-67.8429184, 18.09041405],
              [-67.84263611, 18.09041405],
              [-67.84263611, 18.09013939],
              [-67.8429184, 18.09013939],
              [-67.8429184, 18.0890274],
              [-67.84319305, 18.0890274],
              [-67.84319305, 18.08819389],
              [-67.84347534, 18.08819389],
              [-67.84347534, 18.08513832],
              [-67.84375, 18.08513832],
              [-67.84375, 18.08458328],
              [-67.84402466, 18.08458328],
              [-67.84402466, 18.08319473],
              [-67.84430695, 18.08319473],
              [-67.84430695, 18.08291626],
              [-67.84486389, 18.08291626],
              [-67.84486389, 18.08236122],
              [-67.84513855, 18.08236122],
              [-67.84513855, 18.08124733],
              [-67.84764099, 18.08124733],
              [-67.84764099, 18.08097267],
              [-67.84874725, 18.08097267],
              [-67.84874725, 18.0806942],
              [-67.84986115, 18.0806942],
              [-67.84986115, 18.08041573],
              [-67.8501358, 18.08041573],
              [-67.8501358, 18.07986069],
              [-67.85069275, 18.07986069],
              [-67.85069275, 18.07958412],
              [-67.85124969, 18.07958412],
              [-67.85124969, 18.07902527],
              [-67.8520813, 18.07902527],
              [-67.8520813, 18.07875061],
              [-67.8529129, 18.07875061],
              [-67.8529129, 18.07847214],
              [-67.85319519, 18.07847214],
              [-67.85319519, 18.07819366],
              [-67.85346985, 18.07819366],
              [-67.85346985, 18.07791519],
              [-67.85402679, 18.07791519],
              [-67.85402679, 18.07763863],
              [-67.85430908, 18.07763863],
              [-67.85430908, 18.07736206],
              [-67.8548584, 18.07736206],
              [-67.8548584, 18.07708359],
              [-67.85514069, 18.07708359],
              [-67.85514069, 18.07680511],
              [-67.85541534, 18.07680511],
              [-67.85541534, 18.07652855],
              [-67.85569763, 18.07652855],
              [-67.85569763, 18.07625008],
              [-67.85597229, 18.07625008],
              [-67.85597229, 18.0759716],
              [-67.85652924, 18.0759716],
              [-67.85652924, 18.07569313],
              [-67.85680389, 18.07569313],
              [-67.85680389, 18.07513809],
              [-67.85708618, 18.07513809],
              [-67.85708618, 18.07458305],
              [-67.85736084, 18.07458305],
              [-67.85736084, 18.07430649],
              [-67.8576355, 18.07430649],
              [-67.8576355, 18.07402802],
              [-67.85791779, 18.07402802],
              [-67.85791779, 18.07347107],
              [-67.85819244, 18.07347107],
              [-67.85819244, 18.07291603],
              [-67.85874939, 18.07291603],
              [-67.85874939, 18.07263947],
              [-67.85902405, 18.07263947],
              [-67.85902405, 18.0723629],
              [-67.85930634, 18.0723629],
              [-67.85930634, 18.07180595],
              [-67.85958099, 18.07180595],
              [-67.85958099, 18.07152748],
              [-67.86013794, 18.07152748],
              [-67.86013794, 18.07125282],
              [-67.86042023, 18.07125282],
              [-67.86042023, 18.07097244],
              [-67.86069489, 18.07097244],
              [-67.86069489, 18.0704174],
              [-67.86096954, 18.0704174],
              [-67.86096954, 18.07014084],
              [-67.86152649, 18.07014084],
              [-67.86152649, 18.06958389],
              [-67.86180878, 18.06958389],
              [-67.86180878, 18.06902885],
              [-67.86208344, 18.06902885],
              [-67.86208344, 18.06819534],
              [-67.86235809, 18.06819534],
              [-67.86235809, 18.06847191],
              [-67.86291504, 18.06847191],
              [-67.86291504, 18.06819534],
              [-67.86319733, 18.06819534],
              [-67.86319733, 18.06791878],
              [-67.86347198, 18.06791878],
              [-67.86347198, 18.06763649],
              [-67.86402893, 18.06763649],
              [-67.86402893, 18.06736183],
              [-67.86430359, 18.06736183],
              [-67.86430359, 18.06708336],
              [-67.86458588, 18.06708336],
              [-67.86458588, 18.06680489],
              [-67.86486053, 18.06680489],
              [-67.86486053, 18.06624985],
              [-67.86541748, 18.06624985],
              [-67.86541748, 18.06569481],
              [-67.86569214, 18.06569481],
              [-67.86569214, 18.06541634],
              [-67.86597443, 18.06541634],
              [-67.86597443, 18.06513977],
              [-67.86624908, 18.06513977],
              [-67.86624908, 18.06458282],
              [-67.86653137, 18.06458282],
              [-67.86653137, 18.06402779],
              [-67.86680603, 18.06402779],
              [-67.86680603, 18.06374931],
              [-67.86708069, 18.06374931],
              [-67.86708069, 18.06347275],
              [-67.86680603, 18.06347275],
              [-67.86680603, 18.06319427],
              [-67.86736298, 18.06319427],
              [-67.86736298, 18.0629158],
              [-67.86763763, 18.0629158],
              [-67.86763763, 18.06236076],
              [-67.86791992, 18.06236076],
              [-67.86791992, 18.0620842],
              [-67.86819458, 18.0620842],
              [-67.86819458, 18.06152725],
              [-67.86846924, 18.06152725],
              [-67.86846924, 18.06097221],
              [-67.86875153, 18.06097221],
              [-67.86875153, 18.06041718],
              [-67.86902618, 18.06041718],
              [-67.86902618, 18.0601387],
              [-67.86930847, 18.0601387],
              [-67.86930847, 18.05958366],
              [-67.86985779, 18.05958366],
              [-67.86985779, 18.05902863],
              [-67.87014008, 18.05902863],
              [-67.87014008, 18.05930519],
              [-67.87041473, 18.05930519],
              [-67.87041473, 18.05902863],
              [-67.87069702, 18.05902863],
              [-67.87069702, 18.05875015],
              [-67.87097168, 18.05875015],
              [-67.87097168, 18.05847168],
              [-67.87124634, 18.05847168],
              [-67.87124634, 18.05819511],
              [-67.87152863, 18.05819511],
              [-67.87152863, 18.05791664],
              [-67.87323761, 18.05791664],
              [-67.87374878, 18.05791664],
              [-67.87374878, 18.05771828],
              [-67.87374878, 18.05763817],
              [-67.87401581, 18.05760193],
              [-67.87403107, 18.0573616],
              [-67.87458038, 18.0573616],
              [-67.87458038, 18.05708313],
              [-67.87522125, 18.05708313],
              [-67.87569427, 18.05708313],
              [-67.87569427, 18.05687523],
              [-67.87680817, 18.05680466],
              [-67.87680817, 18.05639076],
              [-67.87680817, 18.05625153],
              [-67.87702942, 18.05622673],
              [-67.87734222, 18.05619621],
              [-67.87735748, 18.05652809],
              [-67.87791443, 18.05652809],
              [-67.87791443, 18.05625153],
              [-67.87847137, 18.05625153],
              [-67.87847137, 18.05597305],
              [-67.87930298, 18.05597305],
              [-67.87930298, 18.05569458],
              [-67.87985992, 18.05569458],
              [-67.87985992, 18.05543518],
              [-67.88014221, 18.05541611],
              [-67.88014221, 18.05529976],
              [-67.88014221, 18.05513954],
              [-67.88043213, 18.05518532],
              [-67.88041687, 18.05485916],
              [-67.88069153, 18.05485916],
              [-67.88069153, 18.0545826],
              [-67.88124847, 18.0545826],
              [-67.88124847, 18.05430794],
              [-67.88153076, 18.05430794],
              [-67.88153076, 18.05402946],
              [-67.88291931, 18.05402946],
              [-67.88293457, 18.05437279],
              [-67.88330078, 18.05430794],
              [-67.88597107, 18.05430794],
              [-67.88597107, 18.05402946],
              [-67.88652802, 18.05402946],
              [-67.88652039, 18.05371475],
              [-67.88735962, 18.05374718],
              [-67.88735962, 18.05359077],
              [-67.88735962, 18.05347252],
              [-67.88791656, 18.05354118],
              [-67.88791656, 18.05374718],
              [-67.88874817, 18.05374718],
              [-67.88874817, 18.0534668],
              [-67.88957977, 18.05347252],
              [-67.88957977, 18.0533905],
              [-67.88957977, 18.05319405],
              [-67.88986206, 18.05319405],
              [-67.88986206, 18.05291557],
              [-67.89237213, 18.05296135],
              [-67.8923645, 18.0526371],
              [-67.89347076, 18.0526371],
              [-67.89347076, 18.05236053],
              [-67.89375305, 18.05236053],
              [-67.89375305, 18.0526371],
              [-67.89485931, 18.0526371],
              [-67.89485931, 18.05236053],
              [-67.8951416, 18.05236053],
              [-67.8951416, 18.0526371],
              [-67.89597321, 18.0526371],
              [-67.89597321, 18.05236053],
              [-67.89653015, 18.05236053],
              [-67.89653015, 18.05208397],
              [-67.89680481, 18.05208397],
              [-67.89680481, 18.05236053],
              [-67.89736176, 18.05236053],
              [-67.89736176, 18.0526371],
              [-67.89763641, 18.0526371],
              [-67.89763641, 18.05277634],
              [-67.89763641, 18.05319405],
              [-67.89819336, 18.05319405],
              [-67.89819336, 18.05347252],
              [-67.89847565, 18.05347252],
              [-67.89847565, 18.05402946],
              [-67.89902496, 18.05402946],
              [-67.89902496, 18.05430794],
              [-67.89958191, 18.05430794],
              [-67.89958191, 18.0545826],
              [-67.8998642, 18.0545826],
              [-67.8998642, 18.05513954],
              [-67.90013885, 18.05513954],
              [-67.90013885, 18.05541611],
              [-67.90041351, 18.05541611],
              [-67.90041351, 18.05569458],
              [-67.90097046, 18.05569458],
              [-67.90097046, 18.05597305],
              [-67.90125275, 18.05597305],
              [-67.90125275, 18.05625153],
              [-67.9015274, 18.05625153],
              [-67.9015274, 18.05652809],
              [-67.90208435, 18.05652809],
              [-67.90208435, 18.05680466],
              [-67.90235901, 18.05680466],
              [-67.90235901, 18.05708313],
              [-67.9026413, 18.05708313],
              [-67.9026413, 18.0573616],
              [-67.9034729, 18.0573616],
              [-67.9034729, 18.05763817],
              [-67.9043045, 18.05763817],
              [-67.9043045, 18.05819511],
              [-67.90458679, 18.05819511],
              [-67.90458679, 18.05902863],
              [-67.90486145, 18.05902863],
              [-67.90486145, 18.05930519],
              [-67.90680695, 18.05930519],
              [-67.90680695, 18.05902863],
              [-67.90847015, 18.05902863],
              [-67.90847015, 18.05930519],
              [-67.90930176, 18.05930519],
              [-67.90930176, 18.05958366],
              [-67.90958405, 18.05958366],
              [-67.90958405, 18.05986023],
              [-67.91014099, 18.05986023],
              [-67.9101181, 18.0601368],
              [-67.91069794, 18.0601387],
              [-67.91069794, 18.0604496],
              [-67.91069794, 18.06069374],
              [-67.91125488, 18.06067085],
              [-67.91124725, 18.06097221],
              [-67.91152954, 18.06097221],
              [-67.91152954, 18.06125069],
              [-67.91236115, 18.06125069],
              [-67.91236115, 18.06152725],
              [-67.91291809, 18.06152725],
              [-67.91291809, 18.06131363],
              [-67.91319275, 18.06125069],
              [-67.91319275, 18.06141853],
              [-67.91319275, 18.06152725],
              [-67.91347504, 18.06152725],
              [-67.91347504, 18.06125069],
              [-67.91402435, 18.06125069],
              [-67.91400146, 18.06152534],
              [-67.91513824, 18.06152725],
              [-67.91513824, 18.06180573],
              [-67.91625214, 18.06180573],
              [-67.91625214, 18.0620842],
              [-67.91708374, 18.0620842],
              [-67.91708374, 18.06236076],
              [-67.91791534, 18.06236076],
              [-67.91791534, 18.06263924],
              [-67.91874695, 18.06263924],
              [-67.91872406, 18.0629158],
              [-67.91930389, 18.0629158],
              [-67.9193573, 18.06319427],
              [-67.91986084, 18.06319427],
              [-67.91986084, 18.06334114],
              [-67.91986084, 18.06347275],
              [-67.92041779, 18.06350327],
              [-67.92041779, 18.06402779],
              [-67.92124939, 18.06402779],
              [-67.92124939, 18.06430435],
              [-67.92180634, 18.06430435],
              [-67.92180634, 18.06458282],
              [-67.92236328, 18.06458282],
              [-67.92236328, 18.0648613],
              [-67.92292023, 18.0648613],
              [-67.92292023, 18.06513977],
              [-67.92346954, 18.06513977],
              [-67.92346954, 18.06541634],
              [-67.92375183, 18.06541634],
              [-67.92375183, 18.06569481],
              [-67.92430878, 18.06569481],
              [-67.92430878, 18.06597137],
              [-67.92485809, 18.06597137],
              [-67.92485809, 18.06624985],
              [-67.92514038, 18.06624985],
              [-67.92514038, 18.06680489],
              [-67.92541504, 18.06680489],
              [-67.92541504, 18.06736183],
              [-67.92569733, 18.06736183],
              [-67.92569733, 18.06763649],
              [-67.92597198, 18.06763649],
              [-67.92597198, 18.06791878],
              [-67.92652893, 18.06791878],
              [-67.92652893, 18.06819534],
              [-67.92680359, 18.06819534],
              [-67.92680359, 18.06847191],
              [-67.92793274, 18.06845093],
              [-67.92791748, 18.06902885],
              [-67.92819214, 18.06902885],
              [-67.92819214, 18.06958389],
              [-67.92874908, 18.06958389],
              [-67.92874908, 18.06985855],
              [-67.92930603, 18.06985855],
              [-67.92930603, 18.07014084],
              [-67.92986298, 18.07014084],
              [-67.92986298, 18.07069397],
              [-67.93013763, 18.07069397],
              [-67.93013763, 18.07097244],
              [-67.93069458, 18.07097244],
              [-67.93069458, 18.07152748],
              [-67.93096924, 18.07152748],
              [-67.93096924, 18.07180595],
              [-67.93125153, 18.07180595],
              [-67.93125153, 18.0723629],
              [-67.93180847, 18.0723629],
              [-67.93180847, 18.0731945],
              [-67.93208313, 18.0731945],
              [-67.93208313, 18.07374954],
              [-67.93235779, 18.07374954],
              [-67.93235779, 18.07430649],
              [-67.93264008, 18.07430649],
              [-67.93264008, 18.07458305],
              [-67.93291473, 18.07458305],
              [-67.93291473, 18.07486153],
              [-67.93319702, 18.07486153],
              [-67.93319702, 18.07541656],
              [-67.93374634, 18.07541656],
              [-67.93374634, 18.0759716],
              [-67.93430328, 18.0759716],
              [-67.93430328, 18.07652855],
              [-67.93458557, 18.07652855],
              [-67.93458557, 18.07708359],
              [-67.93486023, 18.07708359],
              [-67.93486023, 18.07736206],
              [-67.93514252, 18.07736206],
              [-67.93514252, 18.07791519],
              [-67.93569183, 18.07791519],
              [-67.93569183, 18.07819366],
              [-67.93597412, 18.07819366],
              [-67.93597412, 18.07847214],
              [-67.93653107, 18.07847214],
              [-67.93653107, 18.07875061],
              [-67.93680573, 18.07875061],
              [-67.93680573, 18.07902527],
              [-67.93736267, 18.07902527],
              [-67.93736267, 18.07930565],
              [-67.93791962, 18.07930565],
              [-67.93791962, 18.07902527],
              [-67.93849182, 18.07896614],
              [-67.93846893, 18.07930565],
              [-67.9393158, 18.07930565],
              [-67.93958282, 18.07930565],
              [-67.93958282, 18.07941437],
              [-67.93958282, 18.07958412],
              [-67.9401474, 18.07958412],
              [-67.94042206, 18.07958412],
              [-67.94041443, 18.07930565],
              [-67.94152832, 18.07930565],
              [-67.94152832, 18.07958412],
              [-67.94235992, 18.07958412],
              [-67.94235992, 18.07930565],
              [-67.94291687, 18.07930565],
              [-67.94291687, 18.07947731],
              [-67.94291687, 18.07958412],
              [-67.94347382, 18.07963181],
              [-67.94347382, 18.07986069],
              [-67.94403076, 18.07991219],
              [-67.94403076, 18.08013725],
              [-67.94434357, 18.08013725],
              [-67.94430542, 18.08180618],
              [-67.94486237, 18.08180618],
              [-67.94486237, 18.08208275],
              [-67.94541931, 18.08208275],
              [-67.94541931, 18.08236122],
              [-67.94569397, 18.08236122],
              [-67.94569397, 18.08263969],
              [-67.94596863, 18.08263969],
              [-67.94596863, 18.08344078],
              [-67.94596863, 18.08402824],
              [-67.94569397, 18.08402824],
              [-67.94569397, 18.08430481],
              [-67.94541931, 18.08430481],
              [-67.94541931, 18.08458328],
              [-67.94513702, 18.08458328],
              [-67.94513702, 18.08486176],
              [-67.94486237, 18.08486176],
              [-67.94486237, 18.08513832],
              [-67.94458008, 18.08513832],
              [-67.94458008, 18.08541679],
              [-67.94430542, 18.08541679],
              [-67.94430542, 18.08597374],
              [-67.94403076, 18.08597374],
              [-67.94403076, 18.08652687],
              [-67.94374847, 18.08652687],
              [-67.94374847, 18.08680534],
              [-67.94347382, 18.08680534],
              [-67.94347382, 18.08708382],
              [-67.94319153, 18.08708382],
              [-67.94319153, 18.08736038],
              [-67.94291687, 18.08736038],
              [-67.94291687, 18.08763885],
              [-67.94264221, 18.08763885],
              [-67.94264221, 18.08791733],
              [-67.94123077, 18.08791733],
              [-67.94041443, 18.08791733],
              [-67.94041443, 18.08819389],
              [-67.93958282, 18.08819389],
              [-67.93958282, 18.08847237],
              [-67.93930817, 18.08847237],
              [-67.93930817, 18.08875084],
              [-67.93902588, 18.08875084],
              [-67.93902588, 18.08930588],
              [-67.93875122, 18.08930588],
              [-67.93875122, 18.08986092],
              [-67.93846893, 18.08986092],
              [-67.93846893, 18.09152794],
              [-67.93875122, 18.09152794],
              [-67.93875122, 18.09263611],
              [-67.93846893, 18.09263611],
              [-67.93846893, 18.09402657],
              [-67.93819427, 18.09402657],
              [-67.93819427, 18.09486198],
              [-67.93791962, 18.09486198],
              [-67.93791962, 18.09569359],
              [-67.93763733, 18.09569359],
              [-67.93763733, 18.09680557],
              [-67.93736267, 18.09680557],
              [-67.93736267, 18.09763908],
              [-67.93708038, 18.09763908],
              [-67.93708038, 18.09902763],
              [-67.93680573, 18.09902763],
              [-67.93680573, 18.09930611],
              [-67.93653107, 18.09930611],
              [-67.93653107, 18.09958267],
              [-67.93624878, 18.09958267],
              [-67.93624878, 18.10041618],
              [-67.93597412, 18.10041618],
              [-67.93597412, 18.10263824],
              [-67.93569183, 18.10263824],
              [-67.93569183, 18.10375023],
              [-67.93541718, 18.10375023],
              [-67.93541718, 18.1040287],
              [-67.93569183, 18.1040287],
              [-67.93569183, 18.10458374],
              [-67.93514252, 18.10458374],
              [-67.93514252, 18.10513878],
              [-67.93486023, 18.10513878],
              [-67.93486023, 18.10569382],
              [-67.93541718, 18.10569382],
              [-67.93541718, 18.10652733],
              [-67.93569183, 18.10652733],
              [-67.93569183, 18.10736084],
              [-67.93597412, 18.10736465],
              [-67.93597412, 18.10847855],
              [-67.93597412, 18.1086216],
              [-67.93597412, 18.10902977],
              [-67.93569183, 18.10906029],
              [-67.93569183, 18.1091938],
              [-67.93569183, 18.10986137],
              [-67.9353714, 18.10984039],
              [-67.93541718, 18.11013985],
              [-67.93514252, 18.11017227],
              [-67.93514252, 18.1103096],
              [-67.93514252, 18.11125183],
              [-67.93486023, 18.11125183],
              [-67.93486023, 18.11180496],
              [-67.93458557, 18.11180496],
              [-67.93458557, 18.11263847],
              [-67.93430328, 18.11263847],
              [-67.93430328, 18.11319351],
              [-67.93402863, 18.11319351],
              [-67.93402863, 18.11347389],
              [-67.93374634, 18.11347389],
              [-67.93374634, 18.11375046],
              [-67.93347168, 18.11375046],
              [-67.93347168, 18.11402702],
              [-67.93319702, 18.11402702],
              [-67.93319702, 18.1143055],
              [-67.93013763, 18.1143055],
              [-67.93013763, 18.11458206],
              [-67.92958069, 18.11458206],
              [-67.92958069, 18.11486053],
              [-67.92930603, 18.11486053],
              [-67.92930603, 18.11513901],
              [-67.92736053, 18.11513901],
              [-67.92736053, 18.11541748],
              [-67.92680359, 18.11541748],
              [-67.92680359, 18.11569595],
              [-67.92514038, 18.11569595],
              [-67.92514038, 18.11597252],
              [-67.92458344, 18.11597252],
              [-67.92458344, 18.11624908],
              [-67.92319489, 18.11624908],
              [-67.92319489, 18.11680412],
              [-67.92292023, 18.11680412],
              [-67.92292023, 18.1170826],
              [-67.92180634, 18.1170826],
              [-67.92180634, 18.11736107],
              [-67.92097473, 18.11736107],
              [-67.92097473, 18.11763954],
              [-67.91986084, 18.11763954],
              [-67.91986084, 18.1179142],
              [-67.91847229, 18.1179142],
              [-67.91847229, 18.11819458],
              [-67.91819763, 18.11819458],
              [-67.91819763, 18.11847305],
              [-67.9173584, 18.11847305],
              [-67.9173584, 18.11874962],
              [-67.91652679, 18.11874962],
              [-67.91652679, 18.11902618],
              [-67.91596985, 18.11902618],
              [-67.91596985, 18.11930466],
              [-67.91569519, 18.11930466],
              [-67.91569519, 18.11958313],
              [-67.9145813, 18.11958313],
              [-67.9145813, 18.1198616],
              [-67.91291809, 18.1198616],
              [-67.91291809, 18.12013626],
              [-67.91208649, 18.12013626],
              [-67.91208649, 18.12041855],
              [-67.91069794, 18.12041855],
              [-67.91069794, 18.12069511],
              [-67.91041565, 18.12069511],
              [-67.91041565, 18.12041855],
              [-67.90763855, 18.12041855],
              [-67.90763855, 18.12013626],
              [-67.9070816, 18.12013626],
              [-67.9070816, 18.1198616],
              [-67.90680695, 18.1198616],
              [-67.90680695, 18.11958313],
              [-67.90569305, 18.11958313],
              [-67.90569305, 18.11930466],
              [-67.9054184, 18.11930466],
              [-67.9054184, 18.11902618],
              [-67.90458679, 18.11902618],
              [-67.90458679, 18.11874962],
              [-67.90402985, 18.11874962],
              [-67.90402985, 18.11847305],
              [-67.90374756, 18.11847305],
              [-67.90374756, 18.11819458],
              [-67.9034729, 18.11819458],
              [-67.9034729, 18.1179142],
              [-67.90319824, 18.1179142],
              [-67.90319824, 18.11763954],
              [-67.9026413, 18.11763954],
              [-67.9026413, 18.11736107],
              [-67.90235901, 18.11736107],
              [-67.90235901, 18.1170826],
              [-67.90208435, 18.1170826],
              [-67.90208435, 18.11680412],
              [-67.90180206, 18.11680412],
              [-67.90180206, 18.11652756],
              [-67.9015274, 18.11652756],
              [-67.9015274, 18.11624908],
              [-67.90097046, 18.11624908],
              [-67.90097046, 18.11597252],
              [-67.8998642, 18.11597252],
              [-67.8998642, 18.11569595],
              [-67.89875031, 18.11569595],
              [-67.89875031, 18.11597252],
              [-67.89624786, 18.11597252],
              [-67.89624786, 18.11569595],
              [-67.89347076, 18.11569595],
              [-67.89347076, 18.11541748],
              [-67.89263916, 18.11541748],
              [-67.89263916, 18.11513901],
              [-67.89208221, 18.11513901],
              [-67.89208221, 18.11486053],
              [-67.89180756, 18.11486053],
              [-67.89180756, 18.11458206],
              [-67.89069366, 18.11458206],
              [-67.89069366, 18.1143055],
              [-67.88986206, 18.1143055],
              [-67.88986206, 18.11402702],
              [-67.88957977, 18.11402702],
              [-67.88957977, 18.11375046],
              [-67.88402557, 18.11375046],
              [-67.88402557, 18.1143055],
              [-67.88153076, 18.1143055],
              [-67.88153076, 18.11458206],
              [-67.88041687, 18.11458206],
              [-67.88041687, 18.11486053],
              [-67.87902832, 18.11486053],
              [-67.87902832, 18.11513901],
              [-67.87875366, 18.11513901],
              [-67.87875366, 18.11486053],
              [-67.87791443, 18.11486053],
              [-67.87791443, 18.11513901],
              [-67.87735748, 18.11513901],
              [-67.87735748, 18.11486053],
              [-67.87652588, 18.11486053],
              [-67.87652588, 18.11513901],
              [-67.87596893, 18.11513901],
              [-67.87596893, 18.11541748],
              [-67.87569427, 18.11541748],
              [-67.87569427, 18.11569595],
              [-67.87541962, 18.11569595],
              [-67.87541962, 18.11597252],
              [-67.87513733, 18.11597252],
              [-67.87513733, 18.11569595],
              [-67.87486267, 18.11569595],
              [-67.87486267, 18.11597252],
              [-67.87403107, 18.11597252],
              [-67.87403107, 18.11624908],
              [-67.87374878, 18.11624908],
              [-67.87374878, 18.11652756],
              [-67.87347412, 18.11652756],
              [-67.87347412, 18.11680412],
              [-67.87319183, 18.11680412],
              [-67.87319183, 18.1170826],
              [-67.87264252, 18.1170826],
              [-67.87264252, 18.11736107],
              [-67.87180328, 18.11736107],
              [-67.87180328, 18.1179142],
              [-67.87124634, 18.1179142],
              [-67.87124634, 18.11847305],
              [-67.87069702, 18.11847305],
              [-67.87069702, 18.11874962],
              [-67.87041473, 18.11874962],
              [-67.87041473, 18.11902618],
              [-67.87014008, 18.11902618],
              [-67.87014008, 18.11930466],
              [-67.86985779, 18.11930466],
              [-67.86985779, 18.11958313],
              [-67.86930847, 18.11958313],
              [-67.86930847, 18.1198616],
              [-67.86902618, 18.1198616],
              [-67.86902618, 18.12013626],
              [-67.86819458, 18.12013626],
              [-67.86819458, 18.12041855],
              [-67.86791992, 18.12041855],
              [-67.86791992, 18.12069511],
              [-67.86736298, 18.12069511],
              [-67.86736298, 18.12097168],
              [-67.86653137, 18.12097168],
              [-67.86653137, 18.12124825],
              [-67.86513519, 18.12124825],
              [-67.86513519, 18.12153053],
              [-67.86402893, 18.12153053],
              [-67.86402893, 18.12180519],
              [-67.86347198, 18.12180519],
              [-67.86347198, 18.12208366],
              [-67.86291504, 18.12208366],
              [-67.86291504, 18.12235832],
              [-67.86235809, 18.12235832],
              [-67.86235809, 18.12264061],
              [-67.86096954, 18.12264061],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 50,
        name: 'Mayagüez',
        hasc_1: 'PR.MG',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-67.95180511, 18.05208397, -67.01816559, 18.38986015],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.53',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-65.73915863, 18.17430496],
              [-65.7403717, 18.18110657],
              [-65.74082947, 18.18206024],
              [-65.74822998, 18.17969513],
              [-65.75147247, 18.17636681],
              [-65.75424194, 18.1773243],
              [-65.75469971, 18.17733002],
              [-65.76624298, 18.182127],
              [-65.76252747, 18.1902256],
              [-65.76020813, 18.19546509],
              [-65.79253387, 18.20698929],
              [-65.79993439, 18.20271683],
              [-65.80686951, 18.20511436],
              [-65.8193512, 18.205616],
              [-65.82582855, 18.20038223],
              [-65.83135223, 18.21422195],
              [-65.83042145, 18.21851349],
              [-65.82901764, 18.22804642],
              [-65.82808685, 18.23138237],
              [-65.82112885, 18.24662781],
              [-65.81741333, 18.25234032],
              [-65.81554413, 18.26234818],
              [-65.81739044, 18.26473618],
              [-65.81969452, 18.26760292],
              [-65.82386017, 18.26903915],
              [-65.8243103, 18.27285576],
              [-65.81921387, 18.27951622],
              [-65.81597137, 18.2833252],
              [-65.81365204, 18.28665924],
              [-65.81179047, 18.28999138],
              [-65.8113327, 18.28998947],
              [-65.80716705, 18.28902245],
              [-65.80532074, 18.28807068],
              [-65.8002243, 18.28996658],
              [-65.79467773, 18.29138565],
              [-65.79327393, 18.29662323],
              [-65.78865051, 18.29422951],
              [-65.78495026, 18.29422379],
              [-65.78264618, 18.2908802],
              [-65.78173065, 18.28849792],
              [-65.77941895, 18.28610802],
              [-65.7743454, 18.28180504],
              [-65.77111053, 18.28084373],
              [-65.76694489, 18.27988052],
              [-65.76509094, 18.27891922],
              [-65.76463318, 18.27796745],
              [-65.76324463, 18.27557755],
              [-65.7623291, 18.27128983],
              [-65.75632477, 18.26745605],
              [-65.75402069, 18.26506424],
              [-65.75217438, 18.26363182],
              [-65.74940491, 18.2626667],
              [-65.74154663, 18.26121712],
              [-65.73923492, 18.26073456],
              [-65.737854, 18.25882339],
              [-65.73554993, 18.25643349],
              [-65.73231506, 18.25404167],
              [-65.73000336, 18.25308037],
              [-65.72214508, 18.25210381],
              [-65.72122192, 18.25257874],
              [-65.72122192, 18.25305367],
              [-65.72167969, 18.25401115],
              [-65.72167206, 18.25639343],
              [-65.72167206, 18.25734711],
              [-65.71981812, 18.25781441],
              [-65.71658325, 18.25781059],
              [-65.71611786, 18.25780869],
              [-65.71196747, 18.25541115],
              [-65.70780182, 18.25587654],
              [-65.70594788, 18.25634575],
              [-65.70549011, 18.25682259],
              [-65.70269775, 18.25967026],
              [-65.70223999, 18.25919533],
              [-65.70040131, 18.25680351],
              [-65.69762421, 18.25679588],
              [-65.69577789, 18.25536156],
              [-65.69485474, 18.25488091],
              [-65.69348145, 18.25106049],
              [-65.6888504, 18.25152588],
              [-65.68515015, 18.2538929],
              [-65.68422699, 18.25341225],
              [-65.68283844, 18.25245476],
              [-65.6828537, 18.24959373],
              [-65.68378448, 18.24721336],
              [-65.68656158, 18.24626923],
              [-65.68981171, 18.24246407],
              [-65.69166565, 18.24008942],
              [-65.6930542, 18.23818588],
              [-65.69167328, 18.23722458],
              [-65.68890381, 18.23626137],
              [-65.68845367, 18.23149681],
              [-65.6856842, 18.23005867],
              [-65.68061066, 18.22717667],
              [-65.68015289, 18.22669601],
              [-65.67599487, 18.22525215],
              [-65.67183685, 18.22380638],
              [-65.66953278, 18.22284317],
              [-65.66721344, 18.22331047],
              [-65.66536713, 18.22330666],
              [-65.66397095, 18.22473145],
              [-65.66212463, 18.22520256],
              [-65.65843201, 18.22471237],
              [-65.65797424, 18.22232437],
              [-65.65797424, 18.22184944],
              [-65.66030121, 18.21756744],
              [-65.66168976, 18.21614075],
              [-65.66447449, 18.21472359],
              [-65.66447449, 18.21376991],
              [-65.66125488, 18.20946312],
              [-65.66004944, 18.20652771],
              [-65.66041565, 18.20652771],
              [-65.66041565, 18.20624924],
              [-65.66152954, 18.20624924],
              [-65.66152954, 18.20680618],
              [-65.66236115, 18.20680618],
              [-65.66236115, 18.20708275],
              [-65.66347504, 18.20708275],
              [-65.66347504, 18.20680618],
              [-65.66430664, 18.20680618],
              [-65.66430664, 18.20652771],
              [-65.6645813, 18.20652771],
              [-65.6645813, 18.20597267],
              [-65.66486359, 18.20597267],
              [-65.66486359, 18.2056942],
              [-65.66513824, 18.2056942],
              [-65.66513824, 18.20541573],
              [-65.66569519, 18.20541573],
              [-65.66569519, 18.20513916],
              [-65.66596985, 18.20513916],
              [-65.66596985, 18.20458412],
              [-65.66625214, 18.20458412],
              [-65.66625214, 18.20430565],
              [-65.66680908, 18.20430565],
              [-65.66680908, 18.20402718],
              [-65.66708374, 18.20402718],
              [-65.66708374, 18.20375061],
              [-65.6673584, 18.20375061],
              [-65.6673584, 18.20347023],
              [-65.66819763, 18.20347023],
              [-65.66819763, 18.20208359],
              [-65.66847229, 18.20208359],
              [-65.66847229, 18.20153046],
              [-65.66874695, 18.20153046],
              [-65.66874695, 18.20124817],
              [-65.66930389, 18.20124817],
              [-65.66930389, 18.2009716],
              [-65.6701355, 18.2009716],
              [-65.6701355, 18.20041656],
              [-65.67069244, 18.20041656],
              [-65.67069244, 18.20013618],
              [-65.67097473, 18.20013618],
              [-65.67097473, 18.19958305],
              [-65.67208099, 18.19958305],
              [-65.67208099, 18.19986153],
              [-65.67292023, 18.19986153],
              [-65.67292023, 18.20013618],
              [-65.67319489, 18.20013618],
              [-65.67319489, 18.20041656],
              [-65.67375183, 18.20041656],
              [-65.67375183, 18.20069695],
              [-65.67485809, 18.20069695],
              [-65.67485809, 18.20041656],
              [-65.67514038, 18.20041656],
              [-65.67514038, 18.19986153],
              [-65.67652893, 18.19986153],
              [-65.67652893, 18.19958305],
              [-65.67680359, 18.19958305],
              [-65.67680359, 18.1993084],
              [-65.67708588, 18.1993084],
              [-65.67708588, 18.19902611],
              [-65.67736053, 18.19902611],
              [-65.67736053, 18.19847298],
              [-65.67791748, 18.19847298],
              [-65.67791748, 18.1981945],
              [-65.67819214, 18.1981945],
              [-65.67819214, 18.19763947],
              [-65.67847443, 18.19763947],
              [-65.67847443, 18.19708252],
              [-65.67930603, 18.19708252],
              [-65.67930603, 18.19763947],
              [-65.67958069, 18.19763947],
              [-65.67958069, 18.19847298],
              [-65.68069458, 18.19847298],
              [-65.68069458, 18.19874954],
              [-65.68235779, 18.19874954],
              [-65.68235779, 18.19847298],
              [-65.68347168, 18.19847298],
              [-65.68347168, 18.1981945],
              [-65.68402863, 18.1981945],
              [-65.68402863, 18.19791412],
              [-65.68486023, 18.19791412],
              [-65.68486023, 18.19763947],
              [-65.68597412, 18.19763947],
              [-65.68597412, 18.19736099],
              [-65.68708038, 18.19736099],
              [-65.68708038, 18.19680405],
              [-65.68763733, 18.19680405],
              [-65.68763733, 18.19652748],
              [-65.68819427, 18.19652748],
              [-65.68819427, 18.19625092],
              [-65.68902588, 18.19625092],
              [-65.68902588, 18.19597244],
              [-65.68958282, 18.19597244],
              [-65.68958282, 18.19569588],
              [-65.68985748, 18.19569588],
              [-65.68985748, 18.19513893],
              [-65.69013977, 18.19513893],
              [-65.69013977, 18.19486046],
              [-65.69041443, 18.19486046],
              [-65.69041443, 18.19458389],
              [-65.69069672, 18.19458389],
              [-65.69069672, 18.19375038],
              [-65.69097137, 18.19375038],
              [-65.69097137, 18.19347382],
              [-65.69125366, 18.19347382],
              [-65.69125366, 18.1926384],
              [-65.69152832, 18.1926384],
              [-65.69152832, 18.19208336],
              [-65.69180298, 18.19208336],
              [-65.69180298, 18.19180489],
              [-65.69208527, 18.19180489],
              [-65.69208527, 18.19152832],
              [-65.69235992, 18.19152832],
              [-65.69235992, 18.19125175],
              [-65.69264221, 18.19125175],
              [-65.69264221, 18.19096947],
              [-65.69291687, 18.19096947],
              [-65.69291687, 18.19069481],
              [-65.69319153, 18.19069481],
              [-65.69319153, 18.19014168],
              [-65.69347382, 18.19014168],
              [-65.69347382, 18.18985939],
              [-65.69291687, 18.18985939],
              [-65.69291687, 18.18874741],
              [-65.69319153, 18.18874741],
              [-65.69319153, 18.18847275],
              [-65.69374847, 18.18847275],
              [-65.69374847, 18.18819427],
              [-65.69430542, 18.18819427],
              [-65.69430542, 18.1879158],
              [-65.69486237, 18.1879158],
              [-65.69486237, 18.18736076],
              [-65.69458008, 18.18736076],
              [-65.69458008, 18.18680573],
              [-65.69430542, 18.18680573],
              [-65.69430542, 18.18652534],
              [-65.69374847, 18.18652534],
              [-65.69374847, 18.18625069],
              [-65.69347382, 18.18625069],
              [-65.69347382, 18.18541527],
              [-65.69374847, 18.18541527],
              [-65.69374847, 18.18430519],
              [-65.69347382, 18.18430519],
              [-65.69347382, 18.18402863],
              [-65.69291687, 18.18402863],
              [-65.69291687, 18.18375015],
              [-65.69264221, 18.18375015],
              [-65.69264221, 18.18347168],
              [-65.69235992, 18.18347168],
              [-65.69235992, 18.18319321],
              [-65.69208527, 18.18319321],
              [-65.69208527, 18.18291664],
              [-65.69180298, 18.18291664],
              [-65.69180298, 18.18263817],
              [-65.69152832, 18.18263817],
              [-65.69152832, 18.18097305],
              [-65.69125366, 18.18097305],
              [-65.69125366, 18.18041611],
              [-65.69097137, 18.18041611],
              [-65.69097137, 18.1795826],
              [-65.69069672, 18.1795826],
              [-65.69069672, 18.17847252],
              [-65.69125366, 18.17847252],
              [-65.69125366, 18.17819405],
              [-65.69458008, 18.17819405],
              [-65.69458008, 18.17847252],
              [-65.69486237, 18.17847252],
              [-65.69486237, 18.17874908],
              [-65.69513702, 18.17874908],
              [-65.69513702, 18.17902756],
              [-65.69541931, 18.17902756],
              [-65.69541931, 18.17930603],
              [-65.69569397, 18.17930603],
              [-65.69569397, 18.1795826],
              [-65.69596863, 18.1795826],
              [-65.69596863, 18.17986107],
              [-65.69625092, 18.17986107],
              [-65.69625092, 18.18013954],
              [-65.69652557, 18.18013954],
              [-65.69652557, 18.18041611],
              [-65.69680786, 18.18041611],
              [-65.69680786, 18.18319321],
              [-65.69708252, 18.18319321],
              [-65.69708252, 18.18347168],
              [-65.69763947, 18.18347168],
              [-65.69763947, 18.18375015],
              [-65.69791412, 18.18375015],
              [-65.69791412, 18.18402863],
              [-65.69847107, 18.18402863],
              [-65.69847107, 18.18375015],
              [-65.69875336, 18.18375015],
              [-65.69875336, 18.18319321],
              [-65.69902802, 18.18319321],
              [-65.69902802, 18.1823616],
              [-65.69930267, 18.1823616],
              [-65.69930267, 18.18180466],
              [-65.69958496, 18.18180466],
              [-65.69958496, 18.18124962],
              [-65.70153046, 18.18124962],
              [-65.70153046, 18.18208313],
              [-65.70207977, 18.18208313],
              [-65.70207977, 18.18319321],
              [-65.70236206, 18.18319321],
              [-65.70236206, 18.18375015],
              [-65.70291901, 18.18375015],
              [-65.70291901, 18.18319321],
              [-65.7048645, 18.18319321],
              [-65.7048645, 18.18375015],
              [-65.70541382, 18.18375015],
              [-65.70541382, 18.18402863],
              [-65.70597076, 18.18402863],
              [-65.70597076, 18.18430519],
              [-65.70680237, 18.18430519],
              [-65.70680237, 18.18402863],
              [-65.70735931, 18.18402863],
              [-65.70735931, 18.18375015],
              [-65.7076416, 18.18375015],
              [-65.7076416, 18.18291664],
              [-65.70735931, 18.18291664],
              [-65.70735931, 18.18208313],
              [-65.70819092, 18.18208313],
              [-65.70819092, 18.1823616],
              [-65.70874786, 18.1823616],
              [-65.70874786, 18.18263817],
              [-65.70903015, 18.18263817],
              [-65.70903015, 18.18347168],
              [-65.70930481, 18.18347168],
              [-65.70930481, 18.18402863],
              [-65.7095871, 18.18402863],
              [-65.7095871, 18.18430519],
              [-65.70986176, 18.18430519],
              [-65.70986176, 18.18458366],
              [-65.7104187, 18.18458366],
              [-65.7104187, 18.18486023],
              [-65.71069336, 18.18486023],
              [-65.71069336, 18.1851387],
              [-65.71097565, 18.1851387],
              [-65.71097565, 18.18597221],
              [-65.71125031, 18.18597221],
              [-65.71125031, 18.18625069],
              [-65.71152496, 18.18625069],
              [-65.71152496, 18.18763733],
              [-65.71180725, 18.18763733],
              [-65.71180725, 18.18847275],
              [-65.71208191, 18.18847275],
              [-65.71208191, 18.18874741],
              [-65.7123642, 18.18874741],
              [-65.7123642, 18.18902969],
              [-65.71263885, 18.18902969],
              [-65.71263885, 18.18930626],
              [-65.71430206, 18.18930626],
              [-65.71430206, 18.18902969],
              [-65.71458435, 18.18902969],
              [-65.71458435, 18.18874741],
              [-65.71652985, 18.18874741],
              [-65.71652985, 18.18902969],
              [-65.7168045, 18.18902969],
              [-65.7168045, 18.18930626],
              [-65.71708679, 18.18930626],
              [-65.71708679, 18.18958282],
              [-65.72013855, 18.18958282],
              [-65.72013855, 18.18930626],
              [-65.72180176, 18.18930626],
              [-65.72180176, 18.18902969],
              [-65.72319794, 18.18902969],
              [-65.72319794, 18.18874741],
              [-65.7234726, 18.18874741],
              [-65.7234726, 18.18847275],
              [-65.72458649, 18.18847275],
              [-65.72458649, 18.18819427],
              [-65.72541809, 18.18819427],
              [-65.72541809, 18.1879158],
              [-65.72569275, 18.1879158],
              [-65.72569275, 18.18736076],
              [-65.72597504, 18.18736076],
              [-65.72597504, 18.1870842],
              [-65.72652435, 18.1870842],
              [-65.72652435, 18.18680573],
              [-65.7270813, 18.18680573],
              [-65.7270813, 18.18652534],
              [-65.72763824, 18.18652534],
              [-65.72763824, 18.18625069],
              [-65.72846985, 18.18625069],
              [-65.72846985, 18.18597221],
              [-65.72875214, 18.18597221],
              [-65.72875214, 18.18569374],
              [-65.72902679, 18.18569374],
              [-65.72902679, 18.18541527],
              [-65.72930908, 18.18541527],
              [-65.72930908, 18.1851387],
              [-65.72958374, 18.1851387],
              [-65.72958374, 18.18486023],
              [-65.7298584, 18.18486023],
              [-65.7298584, 18.18430519],
              [-65.73014069, 18.18430519],
              [-65.73014069, 18.18402863],
              [-65.73041534, 18.18402863],
              [-65.73041534, 18.18375015],
              [-65.73069763, 18.18375015],
              [-65.73069763, 18.18347168],
              [-65.73097229, 18.18347168],
              [-65.73097229, 18.18319321],
              [-65.73152924, 18.18319321],
              [-65.73152924, 18.18291664],
              [-65.73180389, 18.18291664],
              [-65.73180389, 18.18263817],
              [-65.73236084, 18.18263817],
              [-65.73236084, 18.1823616],
              [-65.7326355, 18.1823616],
              [-65.7326355, 18.18208313],
              [-65.73291779, 18.18208313],
              [-65.73291779, 18.18180466],
              [-65.73347473, 18.18180466],
              [-65.73347473, 18.18152809],
              [-65.73402405, 18.18152809],
              [-65.73402405, 18.18124962],
              [-65.73430634, 18.18124962],
              [-65.73430634, 18.18097305],
              [-65.73458099, 18.18097305],
              [-65.73458099, 18.18041611],
              [-65.73486328, 18.18041611],
              [-65.73486328, 18.18013954],
              [-65.73513794, 18.18013954],
              [-65.73513794, 18.17986107],
              [-65.73542023, 18.17986107],
              [-65.73542023, 18.17930603],
              [-65.73569489, 18.17930603],
              [-65.73569489, 18.17902756],
              [-65.73596954, 18.17902756],
              [-65.73596954, 18.17847252],
              [-65.73625183, 18.17847252],
              [-65.73625183, 18.17791748],
              [-65.73652649, 18.17791748],
              [-65.73652649, 18.17764091],
              [-65.73680878, 18.17764091],
              [-65.73680878, 18.17735863],
              [-65.73708344, 18.17735863],
              [-65.73708344, 18.17708397],
              [-65.73791504, 18.17708397],
              [-65.73791504, 18.17652893],
              [-65.73819733, 18.17652893],
              [-65.73819733, 18.17597008],
              [-65.73847198, 18.17597008],
              [-65.73847198, 18.17514038],
              [-65.73874664, 18.17514038],
              [-65.73874664, 18.17458344],
              [-65.73902893, 18.17458344],
              [-65.73902893, 18.17430496],
              [-65.73915863, 18.17430496],
            ],
          ],
          [
            [
              [-65.67652893, 18.19486046],
              [-65.67652893, 18.19402695],
              [-65.67624664, 18.19402695],
              [-65.67624664, 18.19347382],
              [-65.67485809, 18.19347382],
              [-65.67485809, 18.19180489],
              [-65.67708588, 18.19180489],
              [-65.67708588, 18.19208336],
              [-65.67736053, 18.19208336],
              [-65.67736053, 18.19236183],
              [-65.67763519, 18.19236183],
              [-65.67763519, 18.19291687],
              [-65.67791748, 18.19291687],
              [-65.67791748, 18.19402695],
              [-65.67763519, 18.19402695],
              [-65.67763519, 18.19458389],
              [-65.67736053, 18.19458389],
              [-65.67736053, 18.19486046],
              [-65.67652893, 18.19486046],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 53,
        name: 'Naguabo',
        hasc_1: 'PR.NG',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-65.83135223, 18.17430496, -65.65797424, 18.29662323],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.54',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.20636749, 18.27660179],
              [-66.21792603, 18.27466774],
              [-66.21931458, 18.274189],
              [-66.21977234, 18.27275848],
              [-66.22344971, 18.26464272],
              [-66.22759247, 18.25462341],
              [-66.22805023, 18.25414658],
              [-66.22851563, 18.25366592],
              [-66.23499298, 18.25507927],
              [-66.2419281, 18.25458527],
              [-66.24468994, 18.24933243],
              [-66.2479248, 18.24836922],
              [-66.25531769, 18.24739647],
              [-66.2627182, 18.24642181],
              [-66.26641083, 18.2454586],
              [-66.27981567, 18.24351311],
              [-66.29138184, 18.24538231],
              [-66.29184723, 18.24538231],
              [-66.29600525, 18.24584389],
              [-66.30662537, 18.24104309],
              [-66.30709076, 18.24151611],
              [-66.31312561, 18.24912643],
              [-66.30850983, 18.25104523],
              [-66.30619812, 18.25248718],
              [-66.29373932, 18.26063347],
              [-66.28730011, 18.27304649],
              [-66.28643799, 18.29355049],
              [-66.28507996, 18.30404091],
              [-66.2782135, 18.32979965],
              [-66.27729034, 18.32980156],
              [-66.27311707, 18.32933807],
              [-66.27127075, 18.32886696],
              [-66.26293945, 18.32984543],
              [-66.25878143, 18.33223724],
              [-66.25275421, 18.32844353],
              [-66.24858093, 18.32607079],
              [-66.24628448, 18.33084106],
              [-66.24628448, 18.33131981],
              [-66.24536133, 18.33179855],
              [-66.23934174, 18.33276939],
              [-66.23841858, 18.3327713],
              [-66.23795319, 18.33181572],
              [-66.23377228, 18.3242054],
              [-66.23377228, 18.32372665],
              [-66.23284149, 18.32373047],
              [-66.23052979, 18.32468987],
              [-66.22545624, 18.3299427],
              [-66.22129822, 18.33281136],
              [-66.21713257, 18.33377838],
              [-66.21620178, 18.33282661],
              [-66.21387482, 18.32473183],
              [-66.21294403, 18.32377815],
              [-66.20738983, 18.3237915],
              [-66.20552826, 18.31664467],
              [-66.20596313, 18.3066349],
              [-66.20642853, 18.3066349],
              [-66.20874786, 18.30662918],
              [-66.21290588, 18.30709648],
              [-66.21337128, 18.30662155],
              [-66.21336365, 18.30566597],
              [-66.21337128, 18.30518913],
              [-66.21148682, 18.29232025],
              [-66.21148682, 18.29184532],
              [-66.20915985, 18.28469849],
              [-66.20822906, 18.28184128],
              [-66.20636749, 18.27660179],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 54,
        name: 'Naranjito',
        hasc_1: 'PR.NR',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.31312561, 18.24104309, -66.20552826, 18.33377838],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.55',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.54294586, 18.16317368],
              [-66.54247284, 18.16269875],
              [-66.53830719, 18.16033745],
              [-66.5290451, 18.15704918],
              [-66.52306366, 18.16138077],
              [-66.51984406, 18.16378593],
              [-66.51292419, 18.16573143],
              [-66.51200867, 18.16669273],
              [-66.51200867, 18.16716576],
              [-66.51067352, 18.17718887],
              [-66.51113892, 18.17814064],
              [-66.51445007, 18.19195366],
              [-66.51124573, 18.19816971],
              [-66.51124573, 18.19912529],
              [-66.51223755, 18.2110405],
              [-66.51503754, 18.21627235],
              [-66.51692963, 18.2238884],
              [-66.51740265, 18.22722435],
              [-66.5178833, 18.22960663],
              [-66.5178833, 18.23008347],
              [-66.51325989, 18.23010826],
              [-66.50955963, 18.2296524],
              [-66.50727844, 18.23490906],
              [-66.50960541, 18.2387104],
              [-66.51194, 18.24251556],
              [-66.51239777, 18.24251366],
              [-66.50827026, 18.24873161],
              [-66.50137329, 18.25639915],
              [-66.50091553, 18.25688171],
              [-66.49765778, 18.25308037],
              [-66.49719238, 18.25213051],
              [-66.49256897, 18.25263214],
              [-66.49210358, 18.25263405],
              [-66.48609924, 18.25314522],
              [-66.48285675, 18.25268745],
              [-66.47776031, 18.25080109],
              [-66.4740448, 18.24796295],
              [-66.46941376, 18.24607849],
              [-66.46664429, 18.24609184],
              [-66.46525574, 18.24657631],
              [-66.46387482, 18.24706078],
              [-66.45417786, 18.25092316],
              [-66.45464325, 18.25187683],
              [-66.45465088, 18.25378036],
              [-66.45559692, 18.25759125],
              [-66.45513153, 18.25759315],
              [-66.44450378, 18.25764656],
              [-66.43755341, 18.25624847],
              [-66.43663025, 18.25672722],
              [-66.43017578, 18.2600975],
              [-66.42832947, 18.26058197],
              [-66.42601776, 18.26106834],
              [-66.41214752, 18.26303864],
              [-66.40891266, 18.26353073],
              [-66.40522003, 18.26593018],
              [-66.39875793, 18.26882172],
              [-66.39091492, 18.2736187],
              [-66.38861084, 18.27696609],
              [-66.38632202, 18.28412819],
              [-66.38404083, 18.2903347],
              [-66.38172913, 18.29225349],
              [-66.3780365, 18.29417229],
              [-66.3780365, 18.29369736],
              [-66.37754822, 18.28607178],
              [-66.37846375, 18.28463554],
              [-66.38031006, 18.28367615],
              [-66.38076782, 18.28224373],
              [-66.38260651, 18.27937508],
              [-66.38304901, 18.27508163],
              [-66.38258362, 18.27413177],
              [-66.37702942, 18.27367783],
              [-66.37610626, 18.27320671],
              [-66.37193298, 18.26940918],
              [-66.36402893, 18.25942993],
              [-66.36216736, 18.25705147],
              [-66.35797119, 18.24657822],
              [-66.35194397, 18.24230957],
              [-66.35101318, 18.24136162],
              [-66.35144806, 18.23182487],
              [-66.35050964, 18.22801018],
              [-66.35095978, 18.2256279],
              [-66.35142517, 18.22514725],
              [-66.35139465, 18.21847343],
              [-66.35139465, 18.21751976],
              [-66.35042572, 18.20560074],
              [-66.35177612, 18.19415092],
              [-66.35223389, 18.19414711],
              [-66.35592651, 18.1922245],
              [-66.35592651, 18.19269943],
              [-66.35871887, 18.19746017],
              [-66.36010742, 18.19888687],
              [-66.36010742, 18.1993618],
              [-66.36381531, 18.20078087],
              [-66.36749268, 18.19694901],
              [-66.36748505, 18.19408798],
              [-66.36745453, 18.18550301],
              [-66.37296295, 18.17546463],
              [-66.37480927, 18.17498207],
              [-66.37802887, 18.17258453],
              [-66.38912964, 18.17206383],
              [-66.39187622, 18.16632652],
              [-66.39556885, 18.16535759],
              [-66.40110779, 18.16485596],
              [-66.40573883, 18.16578865],
              [-66.40988159, 18.16290665],
              [-66.41358185, 18.16337013],
              [-66.41820526, 18.16477966],
              [-66.42098236, 18.16619682],
              [-66.42191315, 18.16810226],
              [-66.42192078, 18.17000961],
              [-66.42285156, 18.17143631],
              [-66.42378235, 18.17190742],
              [-66.4358139, 18.17566872],
              [-66.43997192, 18.17612267],
              [-66.44459534, 18.17658234],
              [-66.45245361, 18.17844772],
              [-66.45384216, 18.17844009],
              [-66.45845795, 18.17698669],
              [-66.46122742, 18.17697144],
              [-66.46908569, 18.17788506],
              [-66.47047424, 18.17788124],
              [-66.47093201, 18.1774025],
              [-66.47322083, 18.17214584],
              [-66.47736359, 18.16973877],
              [-66.48844147, 18.16633797],
              [-66.49675751, 18.16677284],
              [-66.49951935, 18.16484833],
              [-66.50136566, 18.16436195],
              [-66.50460052, 18.16434669],
              [-66.50598145, 18.16386032],
              [-66.51058197, 18.16002083],
              [-66.51103973, 18.15906334],
              [-66.51379395, 18.1557045],
              [-66.5220871, 18.15184784],
              [-66.52902222, 18.15275764],
              [-66.53041077, 18.15275192],
              [-66.53686523, 18.15032959],
              [-66.54146576, 18.14743805],
              [-66.54704285, 18.15313148],
              [-66.54705048, 18.15408516],
              [-66.54294586, 18.16317368],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 55,
        name: 'Orocovis',
        hasc_1: 'PR.OR',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.54705048, 18.14743805, -66.35042572, 18.29417229],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.56',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-65.98853302, 18.05886459],
              [-65.98760986, 18.05409241],
              [-65.98576355, 18.04550171],
              [-65.98161316, 18.03500175],
              [-65.97238922, 18.02545357],
              [-65.97193146, 18.02449799],
              [-65.97146606, 18.02449799],
              [-65.97008514, 18.02449608],
              [-65.96546936, 18.02831459],
              [-65.96316528, 18.02878761],
              [-65.95993042, 18.02831268],
              [-65.9525528, 18.02305794],
              [-65.9548645, 18.01781082],
              [-65.9548645, 18.0163765],
              [-65.9548645, 18.01351929],
              [-65.95348358, 18.00683212],
              [-65.95256042, 18.00635338],
              [-65.94334412, 17.99250793],
              [-65.94195557, 17.99202538],
              [-65.93457794, 17.99011421],
              [-65.92765808, 17.98724365],
              [-65.9258194, 17.98581123],
              [-65.92028046, 17.98484993],
              [-65.91751862, 17.9858036],
              [-65.91474915, 17.98484421],
              [-65.91290283, 17.9848423],
              [-65.90921783, 17.98054504],
              [-65.90920258, 17.98041725],
              [-65.90930176, 17.98041725],
              [-65.90930176, 17.98013878],
              [-65.9098587, 17.98013878],
              [-65.9098587, 17.97986031],
              [-65.91014099, 17.97986031],
              [-65.91014099, 17.97958374],
              [-65.91041565, 17.97958374],
              [-65.91041565, 17.97930527],
              [-65.9109726, 17.97930527],
              [-65.9109726, 17.9790287],
              [-65.91124725, 17.9790287],
              [-65.91124725, 17.97875023],
              [-65.91152954, 17.97875023],
              [-65.91152954, 17.97819519],
              [-65.9118042, 17.97819519],
              [-65.9118042, 17.97791672],
              [-65.91208649, 17.97791672],
              [-65.91208649, 17.97763824],
              [-65.9126358, 17.97763824],
              [-65.9126358, 17.97736168],
              [-65.91291809, 17.97736168],
              [-65.91291809, 17.97708321],
              [-65.91319275, 17.97708321],
              [-65.91319275, 17.97652817],
              [-65.91347504, 17.97652817],
              [-65.91347504, 17.9762516],
              [-65.91402435, 17.9762516],
              [-65.91402435, 17.97597313],
              [-65.91652679, 17.97597313],
              [-65.91652679, 17.97569466],
              [-65.91708374, 17.97569466],
              [-65.91708374, 17.97541618],
              [-65.91764069, 17.97541618],
              [-65.91764069, 17.97486115],
              [-65.92263794, 17.97486115],
              [-65.92263794, 17.97513962],
              [-65.92292023, 17.97513962],
              [-65.92292023, 17.97541618],
              [-65.92402649, 17.97541618],
              [-65.92402649, 17.97569466],
              [-65.92430878, 17.97569466],
              [-65.92430878, 17.97597313],
              [-65.92458344, 17.97597313],
              [-65.92458344, 17.9762516],
              [-65.92736053, 17.9762516],
              [-65.92736053, 17.97652817],
              [-65.92791748, 17.97652817],
              [-65.92791748, 17.97680473],
              [-65.93125153, 17.97680473],
              [-65.93125153, 17.97652817],
              [-65.93208313, 17.97652817],
              [-65.93208313, 17.9762516],
              [-65.93264008, 17.9762516],
              [-65.93264008, 17.97597313],
              [-65.93319702, 17.97597313],
              [-65.93319702, 17.97569466],
              [-65.93374634, 17.97569466],
              [-65.93374634, 17.97541618],
              [-65.93402863, 17.97541618],
              [-65.93402863, 17.97513962],
              [-65.93430328, 17.97513962],
              [-65.93430328, 17.97486115],
              [-65.93486023, 17.97486115],
              [-65.93486023, 17.97458267],
              [-65.93514252, 17.97458267],
              [-65.93514252, 17.97430611],
              [-65.93541718, 17.97430611],
              [-65.93541718, 17.97402954],
              [-65.93597412, 17.97402954],
              [-65.93597412, 17.9734726],
              [-65.93624878, 17.9734726],
              [-65.93624878, 17.97291946],
              [-65.93653107, 17.97291946],
              [-65.93653107, 17.97263718],
              [-65.93680573, 17.97263718],
              [-65.93680573, 17.97236061],
              [-65.93791962, 17.97236061],
              [-65.93791962, 17.97208595],
              [-65.93819427, 17.97208595],
              [-65.93819427, 17.97180557],
              [-65.94180298, 17.97180557],
              [-65.94180298, 17.97208595],
              [-65.94208527, 17.97208595],
              [-65.94208527, 17.97236061],
              [-65.94264221, 17.97236061],
              [-65.94264221, 17.97263718],
              [-65.94291687, 17.97263718],
              [-65.94291687, 17.97291946],
              [-65.94541931, 17.97291946],
              [-65.94541931, 17.97263718],
              [-65.94652557, 17.97263718],
              [-65.94652557, 17.97236061],
              [-65.94819641, 17.97236061],
              [-65.94819641, 17.97208595],
              [-65.94930267, 17.97208595],
              [-65.94930267, 17.97180557],
              [-65.94985962, 17.97180557],
              [-65.94985962, 17.97152519],
              [-65.95236206, 17.97152519],
              [-65.95236206, 17.97125053],
              [-65.95291901, 17.97125053],
              [-65.95291901, 17.97097206],
              [-65.95375061, 17.97097206],
              [-65.95375061, 17.97069359],
              [-65.95402527, 17.97069359],
              [-65.95402527, 17.97041512],
              [-65.9576416, 17.97041512],
              [-65.9576416, 17.97069359],
              [-65.95791626, 17.97069359],
              [-65.95791626, 17.97097206],
              [-65.95930481, 17.97097206],
              [-65.95930481, 17.97125053],
              [-65.95986176, 17.97125053],
              [-65.95986176, 17.97152519],
              [-65.9604187, 17.97152519],
              [-65.9604187, 17.97180557],
              [-65.96097565, 17.97180557],
              [-65.96097565, 17.97208595],
              [-65.96152496, 17.97208595],
              [-65.96152496, 17.97236061],
              [-65.96180725, 17.97236061],
              [-65.96180725, 17.97263718],
              [-65.96208191, 17.97263718],
              [-65.96208191, 17.97291946],
              [-65.9623642, 17.97291946],
              [-65.9623642, 17.97319412],
              [-65.96263885, 17.97319412],
              [-65.96263885, 17.9734726],
              [-65.96430206, 17.9734726],
              [-65.96430206, 17.97319412],
              [-65.96708679, 17.97319412],
              [-65.96708679, 17.9734726],
              [-65.96736145, 17.9734726],
              [-65.96736145, 17.97402954],
              [-65.9679184, 17.97402954],
              [-65.9679184, 17.97430611],
              [-65.96819305, 17.97430611],
              [-65.96819305, 17.97458267],
              [-65.96930695, 17.97458267],
              [-65.96930695, 17.97402954],
              [-65.97013855, 17.97402954],
              [-65.97013855, 17.97374725],
              [-65.9706955, 17.97374725],
              [-65.9706955, 17.9734726],
              [-65.97125244, 17.9734726],
              [-65.97125244, 17.97319412],
              [-65.97180176, 17.97319412],
              [-65.97180176, 17.97291946],
              [-65.97208405, 17.97291946],
              [-65.97208405, 17.97263718],
              [-65.9723587, 17.97263718],
              [-65.9723587, 17.97236061],
              [-65.97264099, 17.97236061],
              [-65.97264099, 17.97208595],
              [-65.97291565, 17.97208595],
              [-65.97291565, 17.97180557],
              [-65.97319794, 17.97180557],
              [-65.97319794, 17.97152519],
              [-65.97402954, 17.97152519],
              [-65.97402954, 17.97125053],
              [-65.9743042, 17.97125053],
              [-65.9743042, 17.97097206],
              [-65.97458649, 17.97097206],
              [-65.97458649, 17.97069359],
              [-65.97486115, 17.97069359],
              [-65.97486115, 17.97041512],
              [-65.9751358, 17.97041512],
              [-65.9751358, 17.96986198],
              [-65.97541809, 17.96986198],
              [-65.97541809, 17.96958351],
              [-65.97569275, 17.96958351],
              [-65.97569275, 17.96902847],
              [-65.97597504, 17.96902847],
              [-65.97597504, 17.96875],
              [-65.97624969, 17.96875],
              [-65.97624969, 17.96847153],
              [-65.97652435, 17.96847153],
              [-65.97652435, 17.96819305],
              [-65.97680664, 17.96819305],
              [-65.97680664, 17.96791649],
              [-65.9770813, 17.96791649],
              [-65.9770813, 17.96763802],
              [-65.97763824, 17.96763802],
              [-65.97763824, 17.96791649],
              [-65.97930908, 17.96791649],
              [-65.97930908, 17.96763802],
              [-65.98069763, 17.96763802],
              [-65.98069763, 17.96791649],
              [-65.98124695, 17.96791649],
              [-65.98124695, 17.96819305],
              [-65.98180389, 17.96819305],
              [-65.98180389, 17.96847153],
              [-65.98291779, 17.96847153],
              [-65.98291779, 17.96875],
              [-65.98319244, 17.96875],
              [-65.98319244, 17.96902847],
              [-65.98374939, 17.96902847],
              [-65.98374939, 17.96958351],
              [-65.98402405, 17.96958351],
              [-65.98402405, 17.96986198],
              [-65.98430634, 17.96986198],
              [-65.98430634, 17.97013855],
              [-65.98486328, 17.97013855],
              [-65.98486328, 17.97097206],
              [-65.98513794, 17.97097206],
              [-65.98513794, 17.97152519],
              [-65.98542023, 17.97152519],
              [-65.98542023, 17.97208595],
              [-65.98596954, 17.97208595],
              [-65.98596954, 17.97236061],
              [-65.98625183, 17.97236061],
              [-65.98625183, 17.97263718],
              [-65.98680878, 17.97263718],
              [-65.98680878, 17.97291946],
              [-65.98708344, 17.97291946],
              [-65.98708344, 17.97319412],
              [-65.98735809, 17.97319412],
              [-65.98735809, 17.9734726],
              [-65.98764038, 17.9734726],
              [-65.98764038, 17.97374725],
              [-65.98791504, 17.97374725],
              [-65.98791504, 17.97402954],
              [-65.98819733, 17.97402954],
              [-65.98819733, 17.97430611],
              [-65.98874664, 17.97430611],
              [-65.98874664, 17.97458267],
              [-65.98930359, 17.97458267],
              [-65.98930359, 17.97486115],
              [-65.98958588, 17.97486115],
              [-65.98958588, 17.97513962],
              [-65.98986053, 17.97513962],
              [-65.98986053, 17.97541618],
              [-65.99013519, 17.97541618],
              [-65.99013519, 17.97569466],
              [-65.99041748, 17.97569466],
              [-65.99041748, 17.97597313],
              [-65.99097443, 17.97597313],
              [-65.99097443, 17.9762516],
              [-65.99124908, 17.9762516],
              [-65.99124908, 17.97680473],
              [-65.99153137, 17.97680473],
              [-65.99153137, 17.97708321],
              [-65.99180603, 17.97708321],
              [-65.99180603, 17.97763824],
              [-65.99236298, 17.97763824],
              [-65.99236298, 17.97791672],
              [-65.99319458, 17.97791672],
              [-65.99319458, 17.97819519],
              [-65.99375153, 17.97819519],
              [-65.99375153, 17.97847176],
              [-65.99430847, 17.97847176],
              [-65.99430847, 17.97875023],
              [-65.99541473, 17.97875023],
              [-65.99541473, 17.97847176],
              [-65.99652863, 17.97847176],
              [-65.99652863, 17.97875023],
              [-65.99903107, 17.97875023],
              [-65.99903107, 17.9790287],
              [-65.99930573, 17.9790287],
              [-65.99930573, 17.97875023],
              [-65.99986267, 17.97875023],
              [-65.99986267, 17.97847176],
              [-66.00013733, 17.97847176],
              [-66.00152588, 17.97847176],
              [-66.00152588, 17.97819519],
              [-66.00319672, 17.97819519],
              [-66.00319672, 17.97791672],
              [-66.00375366, 17.97791672],
              [-66.00375366, 17.97763824],
              [-66.00430298, 17.97763824],
              [-66.00430298, 17.97791672],
              [-66.00485992, 17.97791672],
              [-66.00485992, 17.97819519],
              [-66.00541687, 17.97819519],
              [-66.00541687, 17.97847176],
              [-66.00569153, 17.97847176],
              [-66.00569153, 17.97875023],
              [-66.00597382, 17.97875023],
              [-66.00597382, 17.9790287],
              [-66.00653076, 17.9790287],
              [-66.00653076, 17.98013878],
              [-66.00680542, 17.98013878],
              [-66.00680542, 17.98041725],
              [-66.00708008, 17.98041725],
              [-66.00708008, 17.98069382],
              [-66.00875092, 17.98069382],
              [-66.00875092, 17.98041725],
              [-66.00902557, 17.98041725],
              [-66.00902557, 17.98013878],
              [-66.01041412, 17.98013878],
              [-66.01041412, 17.97986031],
              [-66.01069641, 17.97986031],
              [-66.01069641, 17.97958374],
              [-66.01264191, 17.97958374],
              [-66.01264191, 17.97930527],
              [-66.01319122, 17.97930527],
              [-66.01319122, 17.9790287],
              [-66.01347351, 17.9790287],
              [-66.01347351, 17.97930527],
              [-66.01457977, 17.97930527],
              [-66.01457977, 17.9790287],
              [-66.01791382, 17.9790287],
              [-66.01791382, 17.97875023],
              [-66.01875305, 17.97875023],
              [-66.01875305, 17.97847176],
              [-66.01958466, 17.97847176],
              [-66.01958466, 17.97819519],
              [-66.01982117, 17.97819519],
              [-66.01990509, 17.97867584],
              [-66.02220917, 17.98249245],
              [-66.02266693, 17.98297119],
              [-66.02359009, 17.98535728],
              [-66.02405548, 17.99013138],
              [-66.02635956, 17.99299622],
              [-66.02774811, 17.99442863],
              [-66.03559113, 17.99824333],
              [-66.03927612, 18.00349045],
              [-66.0443573, 18.00635338],
              [-66.04712677, 18.00921631],
              [-66.04159546, 18.01733398],
              [-66.04251862, 18.02019691],
              [-66.04251862, 18.02210617],
              [-66.04297638, 18.02592278],
              [-66.04389954, 18.02974129],
              [-66.04805756, 18.03546906],
              [-66.05683136, 18.04023552],
              [-66.05775452, 18.04071236],
              [-66.07574463, 18.03735924],
              [-66.07943726, 18.03496742],
              [-66.0821991, 18.03257751],
              [-66.08543396, 18.03591919],
              [-66.08589172, 18.03687096],
              [-66.0872879, 18.04260063],
              [-66.0872879, 18.04355049],
              [-66.08544159, 18.04832268],
              [-66.08914185, 18.05643463],
              [-66.08545685, 18.06359482],
              [-66.08084106, 18.06407738],
              [-66.07991791, 18.06598473],
              [-66.07946014, 18.07028008],
              [-66.08131409, 18.07361984],
              [-66.07993317, 18.07505035],
              [-66.07485199, 18.07553291],
              [-66.0679245, 18.07888031],
              [-66.06562042, 18.08221817],
              [-66.06378174, 18.08508682],
              [-66.06239319, 18.0870018],
              [-66.06054687, 18.08938408],
              [-66.06008911, 18.09081459],
              [-66.06008911, 18.09319878],
              [-66.06008911, 18.09558487],
              [-66.0605545, 18.0984478],
              [-66.05316925, 18.10751534],
              [-66.05132294, 18.11038017],
              [-66.05086517, 18.10942841],
              [-66.04855347, 18.10751915],
              [-66.04439545, 18.10513687],
              [-66.04254913, 18.10418129],
              [-66.03746796, 18.10323143],
              [-66.03331757, 18.10227776],
              [-66.03100586, 18.10227966],
              [-66.02731323, 18.10180473],
              [-66.02223206, 18.10275841],
              [-66.01946259, 18.10323524],
              [-66.0125351, 18.09655762],
              [-66.0125351, 18.0936985],
              [-66.01392365, 18.09274292],
              [-66.01899719, 18.08510971],
              [-66.01946259, 18.08367348],
              [-66.01807404, 18.08176613],
              [-66.01023102, 18.07556534],
              [-66.01022339, 18.07174873],
              [-66.01068878, 18.06888199],
              [-66.01068878, 18.06793022],
              [-65.99868774, 18.0602951],
              [-65.98853302, 18.05886459],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 56,
        name: 'Patillas',
        hasc_1: 'PR.PT',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.08914185, 17.96763802, -65.90920258, 18.11038017],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.57',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.69869232, 17.97708321],
              [-66.70041656, 17.97708321],
              [-66.70041656, 17.97736168],
              [-66.70124817, 17.97736168],
              [-66.70124817, 17.97763824],
              [-66.70180511, 17.97763824],
              [-66.70180511, 17.97847176],
              [-66.70207977, 17.97847176],
              [-66.70207977, 17.97875023],
              [-66.70319366, 17.97875023],
              [-66.70319366, 17.9790287],
              [-66.70347595, 17.9790287],
              [-66.70347595, 17.97986031],
              [-66.70375061, 17.97986031],
              [-66.70375061, 17.98013878],
              [-66.70402527, 17.98013878],
              [-66.70402527, 17.98041725],
              [-66.70430756, 17.98041725],
              [-66.70430756, 17.98069382],
              [-66.70458221, 17.98069382],
              [-66.70458221, 17.98097229],
              [-66.70597076, 17.98097229],
              [-66.70597076, 17.98013878],
              [-66.70625305, 17.98013878],
              [-66.70625305, 17.98041725],
              [-66.70735931, 17.98041725],
              [-66.70735931, 17.98013878],
              [-66.70819092, 17.98013878],
              [-66.70819092, 17.97986031],
              [-66.70847321, 17.97986031],
              [-66.70847321, 17.97958374],
              [-66.70874786, 17.97958374],
              [-66.70874786, 17.97986031],
              [-66.70930481, 17.97986031],
              [-66.70930481, 17.98013878],
              [-66.71013641, 17.98013878],
              [-66.71013641, 17.98041725],
              [-66.71069336, 17.98041725],
              [-66.71069336, 17.98069382],
              [-66.71125031, 17.98069382],
              [-66.71125031, 17.98097229],
              [-66.71152496, 17.98097229],
              [-66.71152496, 17.98125076],
              [-66.71180725, 17.98125076],
              [-66.71180725, 17.9818058],
              [-66.71208191, 17.9818058],
              [-66.71208191, 17.98291588],
              [-66.71180725, 17.98291588],
              [-66.71180725, 17.98319435],
              [-66.71208191, 17.98319435],
              [-66.71208191, 17.98347282],
              [-66.71180725, 17.98347282],
              [-66.71180725, 17.98374939],
              [-66.71208191, 17.98374939],
              [-66.71208191, 17.98486137],
              [-66.71347046, 17.98486137],
              [-66.71347046, 17.98513794],
              [-66.71375275, 17.98513794],
              [-66.71375275, 17.98541641],
              [-66.7140274, 17.98541641],
              [-66.7140274, 17.98624992],
              [-66.71458435, 17.98624992],
              [-66.71458435, 17.98652649],
              [-66.71485901, 17.98652649],
              [-66.71485901, 17.98763657],
              [-66.71458435, 17.98763657],
              [-66.71458435, 17.98791885],
              [-66.71430206, 17.98791885],
              [-66.71430206, 17.98819351],
              [-66.71485901, 17.98819351],
              [-66.71485901, 17.98847198],
              [-66.71541595, 17.98847198],
              [-66.71541595, 17.98819351],
              [-66.71569824, 17.98819351],
              [-66.71569824, 17.98847198],
              [-66.7159729, 17.98847198],
              [-66.7159729, 17.98875046],
              [-66.71624756, 17.98875046],
              [-66.71624756, 17.98958397],
              [-66.71652985, 17.98958397],
              [-66.71652985, 17.98986053],
              [-66.71847534, 17.98986053],
              [-66.71847534, 17.98958397],
              [-66.71875, 17.98958397],
              [-66.71875, 17.9893055],
              [-66.71902466, 17.9893055],
              [-66.71902466, 17.98902893],
              [-66.71930695, 17.98902893],
              [-66.71930695, 17.9893055],
              [-66.7195816, 17.9893055],
              [-66.7195816, 17.98958397],
              [-66.72013855, 17.98958397],
              [-66.72013855, 17.98986053],
              [-66.7215271, 17.98986053],
              [-66.7215271, 17.99041748],
              [-66.72208405, 17.99041748],
              [-66.72208405, 17.99069405],
              [-66.72264099, 17.99069405],
              [-66.72264099, 17.99097252],
              [-66.72291565, 17.99097252],
              [-66.72291565, 17.99124908],
              [-66.72319794, 17.99124908],
              [-66.72319794, 17.99152756],
              [-66.7251358, 17.99152756],
              [-66.7251358, 17.99180603],
              [-66.72569275, 17.99180603],
              [-66.72569275, 17.99152756],
              [-66.72597504, 17.99152756],
              [-66.72597504, 17.99097252],
              [-66.72736359, 17.99097252],
              [-66.72736359, 17.99152756],
              [-66.7279129, 17.99152756],
              [-66.7279129, 17.99180603],
              [-66.73041534, 17.99180603],
              [-66.73041534, 17.99152756],
              [-66.73069763, 17.99152756],
              [-66.73069763, 17.99124908],
              [-66.73097229, 17.99124908],
              [-66.73097229, 17.98680496],
              [-66.73152924, 17.98680496],
              [-66.73152924, 17.98847198],
              [-66.73180389, 17.98847198],
              [-66.73180389, 17.99097252],
              [-66.7326355, 17.99097252],
              [-66.7326355, 17.99124908],
              [-66.73319244, 17.99124908],
              [-66.73319244, 17.99097252],
              [-66.73374939, 17.99097252],
              [-66.73374939, 17.99069405],
              [-66.73402405, 17.99069405],
              [-66.73402405, 17.99041748],
              [-66.73430634, 17.99041748],
              [-66.73430634, 17.98986053],
              [-66.73458099, 17.98986053],
              [-66.73458099, 17.98958397],
              [-66.73513794, 17.98958397],
              [-66.73513794, 17.9893055],
              [-66.73542023, 17.9893055],
              [-66.73542023, 17.98902893],
              [-66.73569489, 17.98902893],
              [-66.73569489, 17.98875046],
              [-66.73708344, 17.98875046],
              [-66.73708344, 17.98902893],
              [-66.73764038, 17.98902893],
              [-66.73764038, 17.9893055],
              [-66.73819733, 17.9893055],
              [-66.73819733, 17.98958397],
              [-66.73847198, 17.98958397],
              [-66.73847198, 17.98986053],
              [-66.73874664, 17.98986053],
              [-66.73874664, 17.99014091],
              [-66.73902893, 17.99014091],
              [-66.73902893, 17.99041748],
              [-66.74069214, 17.99041748],
              [-66.74069214, 17.99014091],
              [-66.74124908, 17.99014091],
              [-66.74124908, 17.98986053],
              [-66.74180603, 17.98986053],
              [-66.74180603, 17.99014091],
              [-66.74569702, 17.99014091],
              [-66.74569702, 17.98986053],
              [-66.74652863, 17.98986053],
              [-66.74652863, 17.98958397],
              [-66.74708557, 17.98958397],
              [-66.74708557, 17.9893055],
              [-66.74764252, 17.9893055],
              [-66.74764252, 17.98902893],
              [-66.74874878, 17.98902893],
              [-66.74874878, 17.98847198],
              [-66.74903107, 17.98847198],
              [-66.74903107, 17.98819351],
              [-66.74986267, 17.98819351],
              [-66.74986267, 17.98791885],
              [-66.75041962, 17.98791885],
              [-66.75041962, 17.98763657],
              [-66.75096893, 17.98763657],
              [-66.75096893, 17.98736191],
              [-66.75125122, 17.98736191],
              [-66.75125122, 17.98708344],
              [-66.75180817, 17.98708344],
              [-66.75180817, 17.98624992],
              [-66.75263977, 17.98624992],
              [-66.75263977, 17.98652649],
              [-66.75319672, 17.98652649],
              [-66.75319672, 17.98624992],
              [-66.75291443, 17.98624992],
              [-66.75291443, 17.98430443],
              [-66.75319672, 17.98430443],
              [-66.75319672, 17.98374939],
              [-66.75347137, 17.98374939],
              [-66.75347137, 17.98097229],
              [-66.75375366, 17.98097229],
              [-66.75375366, 17.98069382],
              [-66.75402832, 17.98069382],
              [-66.75402832, 17.98013878],
              [-66.75430298, 17.98013878],
              [-66.75430298, 17.97958374],
              [-66.75458527, 17.97958374],
              [-66.75458527, 17.9790287],
              [-66.75430298, 17.9790287],
              [-66.75430298, 17.97819519],
              [-66.75458527, 17.97819519],
              [-66.75458527, 17.97680473],
              [-66.75485992, 17.97680473],
              [-66.75485992, 17.97652817],
              [-66.75514221, 17.97652817],
              [-66.75514221, 17.9762516],
              [-66.75597382, 17.9762516],
              [-66.75597382, 17.97680473],
              [-66.75624847, 17.97680473],
              [-66.75624847, 17.97708321],
              [-66.75680542, 17.97708321],
              [-66.75680542, 17.9762516],
              [-66.75708008, 17.9762516],
              [-66.75708008, 17.97569466],
              [-66.75763702, 17.97569466],
              [-66.75763702, 17.9762516],
              [-66.75819397, 17.9762516],
              [-66.75819397, 17.97597313],
              [-66.75846863, 17.97597313],
              [-66.75846863, 17.97569466],
              [-66.75875092, 17.97569466],
              [-66.75875092, 17.97541618],
              [-66.75902557, 17.97541618],
              [-66.75902557, 17.97513962],
              [-66.75930786, 17.97513962],
              [-66.75930786, 17.97486115],
              [-66.75958252, 17.97486115],
              [-66.75958252, 17.97458267],
              [-66.75986481, 17.97458267],
              [-66.75986481, 17.97402954],
              [-66.76013947, 17.97402954],
              [-66.76013947, 17.97374725],
              [-66.76041412, 17.97374725],
              [-66.76041412, 17.9734726],
              [-66.76069641, 17.9734726],
              [-66.76069641, 17.97319412],
              [-66.76125336, 17.97319412],
              [-66.76125336, 17.97180557],
              [-66.76180267, 17.97180557],
              [-66.76180267, 17.97236061],
              [-66.76208496, 17.97236061],
              [-66.76208496, 17.97402954],
              [-66.76180267, 17.97402954],
              [-66.76180267, 17.97458267],
              [-66.76152802, 17.97458267],
              [-66.76152802, 17.97486115],
              [-66.76125336, 17.97486115],
              [-66.76125336, 17.97402954],
              [-66.76069641, 17.97402954],
              [-66.76069641, 17.97430611],
              [-66.76041412, 17.97430611],
              [-66.76041412, 17.97458267],
              [-66.76013947, 17.97458267],
              [-66.76013947, 17.97486115],
              [-66.75986481, 17.97486115],
              [-66.75986481, 17.97541618],
              [-66.75958252, 17.97541618],
              [-66.75958252, 17.97569466],
              [-66.75930786, 17.97569466],
              [-66.75930786, 17.97597313],
              [-66.75902557, 17.97597313],
              [-66.75902557, 17.9762516],
              [-66.75875092, 17.9762516],
              [-66.75875092, 17.97652817],
              [-66.75902557, 17.97652817],
              [-66.75902557, 17.97708321],
              [-66.75930786, 17.97708321],
              [-66.75930786, 17.97819519],
              [-66.75958252, 17.97819519],
              [-66.75958252, 17.97875023],
              [-66.75986481, 17.97875023],
              [-66.75986481, 17.97930527],
              [-66.76041412, 17.97930527],
              [-66.76041412, 17.97958374],
              [-66.76069641, 17.97958374],
              [-66.76069641, 17.97986031],
              [-66.76125336, 17.97986031],
              [-66.76125336, 17.98013878],
              [-66.76291656, 17.98013878],
              [-66.76291656, 17.97986031],
              [-66.76319122, 17.97986031],
              [-66.76319122, 17.98013878],
              [-66.76347351, 17.98013878],
              [-66.76347351, 17.98041725],
              [-66.76374817, 17.98041725],
              [-66.76374817, 17.98097229],
              [-66.76347351, 17.98097229],
              [-66.76347351, 17.98208427],
              [-66.76374817, 17.98208427],
              [-66.76374817, 17.98347282],
              [-66.76319122, 17.98347282],
              [-66.76319122, 17.98430443],
              [-66.76264191, 17.98430443],
              [-66.76264191, 17.9845829],
              [-66.76208496, 17.9845829],
              [-66.76208496, 17.98486137],
              [-66.76180267, 17.98486137],
              [-66.76180267, 17.9845829],
              [-66.76125336, 17.9845829],
              [-66.76125336, 17.98430443],
              [-66.76152802, 17.98430443],
              [-66.76152802, 17.98319435],
              [-66.76125336, 17.98319435],
              [-66.76125336, 17.98291588],
              [-66.76097107, 17.98291588],
              [-66.76097107, 17.98263931],
              [-66.76013947, 17.98263931],
              [-66.76013947, 17.98236084],
              [-66.75986481, 17.98236084],
              [-66.75986481, 17.98208427],
              [-66.75958252, 17.98208427],
              [-66.75958252, 17.9818058],
              [-66.75875092, 17.9818058],
              [-66.75875092, 17.98208427],
              [-66.75846863, 17.98208427],
              [-66.75846863, 17.98263931],
              [-66.75819397, 17.98263931],
              [-66.75819397, 17.98291588],
              [-66.75791931, 17.98291588],
              [-66.75791931, 17.98347282],
              [-66.75763702, 17.98347282],
              [-66.75763702, 17.98374939],
              [-66.75736237, 17.98374939],
              [-66.75736237, 17.98402786],
              [-66.75708008, 17.98402786],
              [-66.75708008, 17.9845829],
              [-66.75680542, 17.9845829],
              [-66.75680542, 17.98541641],
              [-66.75653076, 17.98541641],
              [-66.75653076, 17.98569679],
              [-66.75624847, 17.98569679],
              [-66.75624847, 17.98624992],
              [-66.75597382, 17.98624992],
              [-66.75597382, 17.98680496],
              [-66.75569153, 17.98680496],
              [-66.75569153, 17.98763657],
              [-66.75597382, 17.98763657],
              [-66.75597382, 17.98819351],
              [-66.75624847, 17.98819351],
              [-66.75624847, 17.99014091],
              [-66.75680542, 17.99014091],
              [-66.75680542, 17.99124908],
              [-66.75708008, 17.99124908],
              [-66.75708008, 17.99180603],
              [-66.75736237, 17.99180603],
              [-66.75736237, 17.99347115],
              [-66.75763702, 17.99347115],
              [-66.75763702, 17.99374962],
              [-66.75791931, 17.99374962],
              [-66.75791931, 17.99402809],
              [-66.75846863, 17.99402809],
              [-66.75846863, 17.99430466],
              [-66.75930786, 17.99430466],
              [-66.75930786, 17.99402809],
              [-66.76069641, 17.99402809],
              [-66.76069641, 17.99347115],
              [-66.76152802, 17.99347115],
              [-66.76152802, 17.99402809],
              [-66.76374817, 17.99402809],
              [-66.76374817, 17.99458313],
              [-66.76430511, 17.99458313],
              [-66.76430511, 17.99541664],
              [-66.76457977, 17.99541664],
              [-66.76457977, 17.99569321],
              [-66.76486206, 17.99569321],
              [-66.76486206, 17.99652863],
              [-66.76403046, 17.99652863],
              [-66.76403046, 17.99625015],
              [-66.76374817, 17.99625015],
              [-66.76374817, 17.99597168],
              [-66.76291656, 17.99597168],
              [-66.76291656, 17.99541664],
              [-66.76180267, 17.99541664],
              [-66.76180267, 17.99513817],
              [-66.76125336, 17.99513817],
              [-66.76125336, 17.9948616],
              [-66.76069641, 17.9948616],
              [-66.76069641, 17.99513817],
              [-66.76041412, 17.99513817],
              [-66.76041412, 17.9948616],
              [-66.75902557, 17.9948616],
              [-66.75902557, 17.99541664],
              [-66.75846863, 17.99541664],
              [-66.75846863, 17.99569321],
              [-66.75763702, 17.99569321],
              [-66.75763702, 17.99597168],
              [-66.75680542, 17.99597168],
              [-66.75680542, 17.99625015],
              [-66.75653076, 17.99625015],
              [-66.75653076, 17.99652863],
              [-66.75597382, 17.99652863],
              [-66.75597382, 17.99680519],
              [-66.75569153, 17.99680519],
              [-66.75569153, 17.99708366],
              [-66.75541687, 17.99708366],
              [-66.75541687, 17.9976387],
              [-66.75514221, 17.9976387],
              [-66.75514221, 17.99875069],
              [-66.75485992, 17.99875069],
              [-66.75485992, 17.99896812],
              [-66.75466919, 17.99896622],
              [-66.75149536, 18.00662613],
              [-66.74829865, 18.0109539],
              [-66.74554443, 18.01192856],
              [-66.74324036, 18.01290131],
              [-66.73770905, 18.0134201],
              [-66.73448181, 18.01392555],
              [-66.73448944, 18.0144043],
              [-66.73312378, 18.01728058],
              [-66.73453522, 18.02061081],
              [-66.73733521, 18.02488136],
              [-66.74198914, 18.03057289],
              [-66.73608398, 18.04254913],
              [-66.73654938, 18.04349899],
              [-66.7388916, 18.04729843],
              [-66.73980713, 18.0472908],
              [-66.74671173, 18.04484749],
              [-66.75510406, 18.05623436],
              [-66.75556946, 18.05623055],
              [-66.75649261, 18.05574608],
              [-66.76512909, 18.03897476],
              [-66.76651764, 18.03991508],
              [-66.76843262, 18.04896355],
              [-66.76894379, 18.05516624],
              [-66.76898956, 18.06089211],
              [-66.76859283, 18.06948662],
              [-66.76906586, 18.07091522],
              [-66.76816559, 18.07330513],
              [-66.7663269, 18.07475281],
              [-66.76589203, 18.0780983],
              [-66.76590729, 18.07952881],
              [-66.76686096, 18.08333588],
              [-66.76965332, 18.08665466],
              [-66.76921844, 18.08999825],
              [-66.76605225, 18.09861183],
              [-66.76559448, 18.09909439],
              [-66.76468658, 18.10053253],
              [-66.76468658, 18.10100746],
              [-66.76565552, 18.10625267],
              [-66.76199341, 18.11105347],
              [-66.76107788, 18.11201477],
              [-66.76248932, 18.11534119],
              [-66.76485443, 18.12247849],
              [-66.76858521, 18.12721634],
              [-66.76908112, 18.13150787],
              [-66.76911163, 18.13484764],
              [-66.76725769, 18.13485718],
              [-66.76633453, 18.13487053],
              [-66.76168823, 18.13109207],
              [-66.7593689, 18.1292057],
              [-66.75656128, 18.12541008],
              [-66.756073, 18.12207794],
              [-66.75514221, 18.12017059],
              [-66.7542038, 18.11922836],
              [-66.75048065, 18.1144886],
              [-66.75047302, 18.11401176],
              [-66.74448395, 18.1159668],
              [-66.74402618, 18.1159687],
              [-66.7394104, 18.11696243],
              [-66.73245239, 18.11224747],
              [-66.72737885, 18.1132431],
              [-66.72692871, 18.11467552],
              [-66.72601318, 18.11563873],
              [-66.72509766, 18.11707306],
              [-66.7260437, 18.11993027],
              [-66.72512817, 18.12137032],
              [-66.72375488, 18.12281227],
              [-66.7210083, 18.12569618],
              [-66.71871185, 18.1276226],
              [-66.71734619, 18.13144684],
              [-66.71366119, 18.13195419],
              [-66.71039581, 18.12816429],
              [-66.70301819, 18.12870026],
              [-66.7025528, 18.12917519],
              [-66.69425201, 18.13067055],
              [-66.69143677, 18.12496758],
              [-66.68115234, 18.10691643],
              [-66.67882538, 18.10359383],
              [-66.67881012, 18.10168457],
              [-66.67825317, 18.08832741],
              [-66.67824554, 18.08642006],
              [-66.67953491, 18.07257271],
              [-66.67952728, 18.07161903],
              [-66.67998505, 18.07066345],
              [-66.68497467, 18.05917358],
              [-66.68724823, 18.05390358],
              [-66.68946075, 18.04005051],
              [-66.68942261, 18.03480148],
              [-66.68938446, 18.03002548],
              [-66.68701172, 18.02049828],
              [-66.6865387, 18.01763725],
              [-66.68651581, 18.015728],
              [-66.69012451, 18.00377083],
              [-66.68778992, 17.99901581],
              [-66.68727875, 17.9928112],
              [-66.68680573, 17.99090576],
              [-66.69184113, 17.98513794],
              [-66.69321442, 17.9836998],
              [-66.69504547, 17.98224831],
              [-66.69827271, 17.98127174],
              [-66.69869232, 17.97708321],
            ],
          ],
          [
            [
              [-66.73652649, 17.98513794],
              [-66.73652649, 17.98486137],
              [-66.73596954, 17.98486137],
              [-66.73596954, 17.9845829],
              [-66.73569489, 17.9845829],
              [-66.73569489, 17.98402786],
              [-66.73542023, 17.98402786],
              [-66.73542023, 17.98374939],
              [-66.73513794, 17.98374939],
              [-66.73513794, 17.98291588],
              [-66.73542023, 17.98291588],
              [-66.73542023, 17.98236084],
              [-66.73569489, 17.98236084],
              [-66.73569489, 17.98208427],
              [-66.73596954, 17.98208427],
              [-66.73596954, 17.98152733],
              [-66.73625183, 17.98152733],
              [-66.73625183, 17.98125076],
              [-66.73652649, 17.98125076],
              [-66.73652649, 17.98097229],
              [-66.73735809, 17.98097229],
              [-66.73735809, 17.98069382],
              [-66.73847198, 17.98069382],
              [-66.73847198, 17.98041725],
              [-66.74041748, 17.98041725],
              [-66.74041748, 17.98013878],
              [-66.74097443, 17.98013878],
              [-66.74097443, 17.97986031],
              [-66.74180603, 17.97986031],
              [-66.74180603, 17.98013878],
              [-66.74236298, 17.98013878],
              [-66.74236298, 17.98069382],
              [-66.74263763, 17.98069382],
              [-66.74263763, 17.98097229],
              [-66.74236298, 17.98097229],
              [-66.74236298, 17.98125076],
              [-66.74208069, 17.98125076],
              [-66.74208069, 17.98152733],
              [-66.74153137, 17.98152733],
              [-66.74153137, 17.98125076],
              [-66.74124908, 17.98125076],
              [-66.74124908, 17.98152733],
              [-66.74097443, 17.98152733],
              [-66.74097443, 17.9818058],
              [-66.74069214, 17.9818058],
              [-66.74069214, 17.98236084],
              [-66.74041748, 17.98236084],
              [-66.74041748, 17.98291588],
              [-66.73986053, 17.98291588],
              [-66.73986053, 17.98319435],
              [-66.73930359, 17.98319435],
              [-66.73930359, 17.98347282],
              [-66.73902893, 17.98347282],
              [-66.73902893, 17.98374939],
              [-66.73874664, 17.98374939],
              [-66.73874664, 17.98402786],
              [-66.73847198, 17.98402786],
              [-66.73847198, 17.98430443],
              [-66.73819733, 17.98430443],
              [-66.73819733, 17.9845829],
              [-66.73791504, 17.9845829],
              [-66.73791504, 17.98486137],
              [-66.73764038, 17.98486137],
              [-66.73764038, 17.98513794],
              [-66.73652649, 17.98513794],
            ],
          ],
          [
            [
              [-66.71624756, 17.98041725],
              [-66.71624756, 17.98013878],
              [-66.71569824, 17.98013878],
              [-66.71569824, 17.97847176],
              [-66.7159729, 17.97847176],
              [-66.7159729, 17.97791672],
              [-66.7168045, 17.97791672],
              [-66.7168045, 17.97763824],
              [-66.71736145, 17.97763824],
              [-66.71736145, 17.97736168],
              [-66.71847534, 17.97736168],
              [-66.71847534, 17.97763824],
              [-66.71875, 17.97763824],
              [-66.71875, 17.97819519],
              [-66.71847534, 17.97819519],
              [-66.71847534, 17.97986031],
              [-66.71819305, 17.97986031],
              [-66.71819305, 17.98013878],
              [-66.7179184, 17.98013878],
              [-66.7179184, 17.98041725],
              [-66.71624756, 17.98041725],
            ],
          ],
          [
            [
              [-66.74930573, 17.97652817],
              [-66.74930573, 17.9762516],
              [-66.74874878, 17.9762516],
              [-66.74874878, 17.97569466],
              [-66.74847412, 17.97569466],
              [-66.74847412, 17.97541618],
              [-66.74819183, 17.97541618],
              [-66.74819183, 17.97513962],
              [-66.74597168, 17.97513962],
              [-66.74597168, 17.97486115],
              [-66.74569702, 17.97486115],
              [-66.74569702, 17.97458267],
              [-66.74514008, 17.97458267],
              [-66.74514008, 17.97430611],
              [-66.74485779, 17.97430611],
              [-66.74485779, 17.97402954],
              [-66.74458313, 17.97402954],
              [-66.74458313, 17.97374725],
              [-66.74402618, 17.97374725],
              [-66.74402618, 17.9734726],
              [-66.74375153, 17.9734726],
              [-66.74375153, 17.97319412],
              [-66.74346924, 17.97319412],
              [-66.74346924, 17.97291946],
              [-66.74375153, 17.97291946],
              [-66.74375153, 17.97263718],
              [-66.74402618, 17.97263718],
              [-66.74402618, 17.97236061],
              [-66.74430847, 17.97236061],
              [-66.74430847, 17.97208595],
              [-66.74541473, 17.97208595],
              [-66.74541473, 17.97236061],
              [-66.74569702, 17.97236061],
              [-66.74569702, 17.97263718],
              [-66.74680328, 17.97263718],
              [-66.74680328, 17.97291946],
              [-66.74819183, 17.97291946],
              [-66.74819183, 17.97319412],
              [-66.74847412, 17.97319412],
              [-66.74847412, 17.9734726],
              [-66.74903107, 17.9734726],
              [-66.74903107, 17.97374725],
              [-66.75041962, 17.97374725],
              [-66.75041962, 17.97402954],
              [-66.75096893, 17.97402954],
              [-66.75096893, 17.97541618],
              [-66.75069427, 17.97541618],
              [-66.75069427, 17.97597313],
              [-66.75013733, 17.97597313],
              [-66.75013733, 17.9762516],
              [-66.74958038, 17.9762516],
              [-66.74958038, 17.97652817],
              [-66.74930573, 17.97652817],
            ],
          ],
          [
            [
              [-66.73152924, 17.97236061],
              [-66.73152924, 17.97208595],
              [-66.73097229, 17.97208595],
              [-66.73097229, 17.97152519],
              [-66.73069763, 17.97152519],
              [-66.73069763, 17.97125053],
              [-66.73097229, 17.97125053],
              [-66.73097229, 17.96958351],
              [-66.73124695, 17.96958351],
              [-66.73124695, 17.96930313],
              [-66.73152924, 17.96930313],
              [-66.73152924, 17.96847153],
              [-66.73124695, 17.96847153],
              [-66.73124695, 17.96736145],
              [-66.73097229, 17.96736145],
              [-66.73097229, 17.96708488],
              [-66.73124695, 17.96708488],
              [-66.73124695, 17.96680641],
              [-66.73152924, 17.96680641],
              [-66.73152924, 17.96652794],
              [-66.73180389, 17.96652794],
              [-66.73180389, 17.96680641],
              [-66.73291779, 17.96680641],
              [-66.73291779, 17.96708488],
              [-66.73374939, 17.96708488],
              [-66.73374939, 17.96736145],
              [-66.73402405, 17.96736145],
              [-66.73402405, 17.96763802],
              [-66.73430634, 17.96763802],
              [-66.73430634, 17.96875],
              [-66.73402405, 17.96875],
              [-66.73402405, 17.96930313],
              [-66.73374939, 17.96930313],
              [-66.73374939, 17.97097206],
              [-66.73402405, 17.97097206],
              [-66.73402405, 17.97152519],
              [-66.73374939, 17.97152519],
              [-66.73374939, 17.97180557],
              [-66.73347473, 17.97180557],
              [-66.73347473, 17.97208595],
              [-66.73291779, 17.97208595],
              [-66.73291779, 17.97236061],
              [-66.73152924, 17.97236061],
            ],
          ],
          [
            [
              [-66.75235748, 17.96736145],
              [-66.75235748, 17.96708488],
              [-66.75069427, 17.96708488],
              [-66.75069427, 17.96680641],
              [-66.74958038, 17.96680641],
              [-66.74958038, 17.96652794],
              [-66.74930573, 17.96652794],
              [-66.74930573, 17.96624947],
              [-66.74903107, 17.96624947],
              [-66.74903107, 17.96513939],
              [-66.74930573, 17.96513939],
              [-66.74930573, 17.96486282],
              [-66.74986267, 17.96486282],
              [-66.74986267, 17.96458244],
              [-66.75235748, 17.96458244],
              [-66.75235748, 17.96486282],
              [-66.75263977, 17.96486282],
              [-66.75263977, 17.96513939],
              [-66.75291443, 17.96513939],
              [-66.75291443, 17.96541595],
              [-66.75347137, 17.96541595],
              [-66.75347137, 17.96569443],
              [-66.75430298, 17.96569443],
              [-66.75430298, 17.9659729],
              [-66.75485992, 17.9659729],
              [-66.75485992, 17.96680641],
              [-66.75430298, 17.96680641],
              [-66.75430298, 17.96708488],
              [-66.75347137, 17.96708488],
              [-66.75347137, 17.96736145],
              [-66.75235748, 17.96736145],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 57,
        name: 'Peñuelas',
        hasc_1: 'PR.PN',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.76965332, 17.96458244, -66.67824554, 18.13487053],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.60',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-65.83633423, 18.27526283],
              [-65.83911133, 18.277174],
              [-65.84234619, 18.27908134],
              [-65.84603882, 18.28146935],
              [-65.85436249, 18.2852993],
              [-65.85575104, 18.28625488],
              [-65.85713196, 18.28816414],
              [-65.86082458, 18.29150772],
              [-65.86360168, 18.29389572],
              [-65.86591339, 18.29390144],
              [-65.8682251, 18.29390335],
              [-65.86682892, 18.29723549],
              [-65.865448, 18.29723549],
              [-65.865448, 18.29771042],
              [-65.86266327, 18.30295181],
              [-65.86312103, 18.30438042],
              [-65.86404419, 18.30771828],
              [-65.86404419, 18.30962753],
              [-65.8686676, 18.30963326],
              [-65.869133, 18.31058884],
              [-65.87004852, 18.31249619],
              [-65.86958313, 18.31725693],
              [-65.86541748, 18.31725311],
              [-65.86402893, 18.31820297],
              [-65.86263275, 18.32106018],
              [-65.86632538, 18.33155441],
              [-65.86817169, 18.33298302],
              [-65.8727951, 18.3348999],
              [-65.8732605, 18.33585358],
              [-65.8732605, 18.33680725],
              [-65.87371826, 18.34061813],
              [-65.87463379, 18.34633827],
              [-65.87462616, 18.35158348],
              [-65.87509155, 18.35301208],
              [-65.87601471, 18.35539436],
              [-65.87693024, 18.36159325],
              [-65.87693024, 18.36206436],
              [-65.87646484, 18.36302185],
              [-65.87507629, 18.36444664],
              [-65.8746109, 18.36492538],
              [-65.87368774, 18.36587524],
              [-65.87368011, 18.36683083],
              [-65.87322235, 18.36920929],
              [-65.87321472, 18.37064171],
              [-65.87413788, 18.37302589],
              [-65.87367249, 18.37397766],
              [-65.86950684, 18.37540054],
              [-65.86811829, 18.3763504],
              [-65.8671875, 18.37825584],
              [-65.86696625, 18.37859726],
              [-65.86625671, 18.37968445],
              [-65.86533356, 18.38063622],
              [-65.86301422, 18.38158607],
              [-65.86070251, 18.38158417],
              [-65.85977173, 18.38158035],
              [-65.85652161, 18.39015579],
              [-65.85651398, 18.39253426],
              [-65.85604858, 18.3944397],
              [-65.85419464, 18.39920044],
              [-65.85279846, 18.40158081],
              [-65.85001373, 18.40348244],
              [-65.84631348, 18.40442657],
              [-65.84398651, 18.40585327],
              [-65.84259796, 18.40871239],
              [-65.83842468, 18.41204071],
              [-65.83517456, 18.4148922],
              [-65.83378601, 18.41822433],
              [-65.8328476, 18.42060471],
              [-65.8298645, 18.42263794],
              [-65.82958221, 18.42263794],
              [-65.82958221, 18.42236137],
              [-65.82930756, 18.42236137],
              [-65.82930756, 18.42208481],
              [-65.82875061, 18.42208481],
              [-65.82875061, 18.42236137],
              [-65.82791901, 18.42236137],
              [-65.82791901, 18.42208481],
              [-65.82736206, 18.42208481],
              [-65.82736206, 18.42180634],
              [-65.82707977, 18.42180634],
              [-65.82707977, 18.42152786],
              [-65.82653046, 18.42152786],
              [-65.82653046, 18.42124939],
              [-65.82597351, 18.42124939],
              [-65.82597351, 18.42097282],
              [-65.82569122, 18.42097282],
              [-65.82569122, 18.42069435],
              [-65.82514191, 18.42069435],
              [-65.82514191, 18.42041588],
              [-65.82485962, 18.42041588],
              [-65.82485962, 18.42013931],
              [-65.82458496, 18.42013931],
              [-65.82458496, 18.41986275],
              [-65.82263947, 18.41986275],
              [-65.82263947, 18.41958427],
              [-65.82208252, 18.41958427],
              [-65.82208252, 18.4193058],
              [-65.82180786, 18.4193058],
              [-65.82180786, 18.41875267],
              [-65.82152557, 18.41875267],
              [-65.82152557, 18.41819382],
              [-65.82125092, 18.41819382],
              [-65.82125092, 18.41764069],
              [-65.82096863, 18.41764069],
              [-65.82096863, 18.4173584],
              [-65.82069397, 18.4173584],
              [-65.82069397, 18.41680527],
              [-65.82041931, 18.41680527],
              [-65.82041931, 18.41541672],
              [-65.82013702, 18.41541672],
              [-65.82013702, 18.41458321],
              [-65.81986237, 18.41458321],
              [-65.81986237, 18.41430473],
              [-65.81958008, 18.41430473],
              [-65.81958008, 18.41402626],
              [-65.81930542, 18.41402626],
              [-65.81930542, 18.41374969],
              [-65.81903076, 18.41374969],
              [-65.81903076, 18.41319466],
              [-65.81874847, 18.41319466],
              [-65.81874847, 18.41263962],
              [-65.81847382, 18.41263962],
              [-65.81847382, 18.41208267],
              [-65.81819153, 18.41208267],
              [-65.81819153, 18.4118042],
              [-65.81791687, 18.4118042],
              [-65.81791687, 18.41152763],
              [-65.81764221, 18.41152763],
              [-65.81764221, 18.41124916],
              [-65.81708527, 18.41124916],
              [-65.81708527, 18.4109726],
              [-65.81680298, 18.4109726],
              [-65.81680298, 18.41069412],
              [-65.81652832, 18.41069412],
              [-65.81652832, 18.41041756],
              [-65.81597137, 18.41041756],
              [-65.81597137, 18.41013908],
              [-65.81541443, 18.41013908],
              [-65.81541443, 18.40986061],
              [-65.81485748, 18.40986061],
              [-65.81485748, 18.40930557],
              [-65.81124878, 18.40930557],
              [-65.81124878, 18.4090271],
              [-65.80708313, 18.4090271],
              [-65.80708313, 18.40930557],
              [-65.80652618, 18.40930557],
              [-65.80652618, 18.40958595],
              [-65.80625153, 18.40958595],
              [-65.80625153, 18.41013908],
              [-65.80596924, 18.41013908],
              [-65.80596924, 18.41041756],
              [-65.80486298, 18.41041756],
              [-65.80486298, 18.41013908],
              [-65.80458069, 18.41013908],
              [-65.80458069, 18.40958595],
              [-65.80347443, 18.40958595],
              [-65.80347443, 18.40986061],
              [-65.80319214, 18.40986061],
              [-65.80319214, 18.41041756],
              [-65.80291748, 18.41041756],
              [-65.80291748, 18.41069412],
              [-65.80263519, 18.41069412],
              [-65.80263519, 18.4109726],
              [-65.80236053, 18.4109726],
              [-65.80236053, 18.41124916],
              [-65.80208588, 18.41124916],
              [-65.80208588, 18.41152763],
              [-65.80180359, 18.41152763],
              [-65.80180359, 18.41208267],
              [-65.80152893, 18.41208267],
              [-65.80152893, 18.41236115],
              [-65.80124664, 18.41236115],
              [-65.80124664, 18.41263962],
              [-65.80069733, 18.41263962],
              [-65.80069733, 18.41291618],
              [-65.80041504, 18.41291618],
              [-65.80041504, 18.41319466],
              [-65.80014038, 18.41319466],
              [-65.80014038, 18.41347313],
              [-65.79930878, 18.41347313],
              [-65.79930878, 18.41374969],
              [-65.79875183, 18.41374969],
              [-65.79875183, 18.41402626],
              [-65.79846954, 18.41402626],
              [-65.79846954, 18.41430473],
              [-65.79819489, 18.41430473],
              [-65.79819489, 18.41458321],
              [-65.79792023, 18.41458321],
              [-65.79792023, 18.41486168],
              [-65.79763794, 18.41486168],
              [-65.79763794, 18.41541672],
              [-65.79736328, 18.41541672],
              [-65.79736328, 18.41597176],
              [-65.79708099, 18.41597176],
              [-65.79708099, 18.41624832],
              [-65.79680634, 18.41624832],
              [-65.79680634, 18.41653061],
              [-65.79652405, 18.41653061],
              [-65.79652405, 18.41708374],
              [-65.79624939, 18.41708374],
              [-65.79624939, 18.4173584],
              [-65.79597473, 18.4173584],
              [-65.79597473, 18.41791725],
              [-65.79569244, 18.41791725],
              [-65.79569244, 18.41819382],
              [-65.79541779, 18.41819382],
              [-65.79541779, 18.41875267],
              [-65.7951355, 18.41875267],
              [-65.7951355, 18.41902733],
              [-65.79486084, 18.41902733],
              [-65.79486084, 18.4193058],
              [-65.79430389, 18.4193058],
              [-65.79430389, 18.41958427],
              [-65.79402924, 18.41958427],
              [-65.79402924, 18.41986275],
              [-65.79347229, 18.41986275],
              [-65.79347229, 18.42041588],
              [-65.79319763, 18.42041588],
              [-65.79319763, 18.42069435],
              [-65.79291534, 18.42069435],
              [-65.79291534, 18.42097282],
              [-65.7923584, 18.42097282],
              [-65.7923584, 18.42124939],
              [-65.79152679, 18.42124939],
              [-65.79152679, 18.42152786],
              [-65.79096985, 18.42152786],
              [-65.79096985, 18.42180634],
              [-65.79013824, 18.42180634],
              [-65.79013824, 18.42152786],
              [-65.7895813, 18.42152786],
              [-65.7895813, 18.42124939],
              [-65.78930664, 18.42124939],
              [-65.78930664, 18.42069435],
              [-65.78902435, 18.42069435],
              [-65.78902435, 18.41986275],
              [-65.78930664, 18.41986275],
              [-65.78930664, 18.41958427],
              [-65.78902435, 18.41958427],
              [-65.78902435, 18.41875267],
              [-65.78930664, 18.41875267],
              [-65.78930664, 18.41819382],
              [-65.7895813, 18.41819382],
              [-65.7895813, 18.41791725],
              [-65.78986359, 18.41791725],
              [-65.78986359, 18.41764069],
              [-65.79013824, 18.41764069],
              [-65.79013824, 18.41708374],
              [-65.7904129, 18.41708374],
              [-65.7904129, 18.41680527],
              [-65.79069519, 18.41680527],
              [-65.79069519, 18.41653061],
              [-65.79096985, 18.41653061],
              [-65.79096985, 18.41624832],
              [-65.79125214, 18.41624832],
              [-65.79125214, 18.41569519],
              [-65.79152679, 18.41569519],
              [-65.79152679, 18.41541672],
              [-65.79180908, 18.41541672],
              [-65.79180908, 18.41513824],
              [-65.7923584, 18.41513824],
              [-65.7923584, 18.41486168],
              [-65.79264069, 18.41486168],
              [-65.79264069, 18.41458321],
              [-65.79291534, 18.41458321],
              [-65.79291534, 18.41430473],
              [-65.79319763, 18.41430473],
              [-65.79319763, 18.41402626],
              [-65.79374695, 18.41402626],
              [-65.79374695, 18.41374969],
              [-65.79402924, 18.41374969],
              [-65.79402924, 18.41347313],
              [-65.79430389, 18.41347313],
              [-65.79430389, 18.41319466],
              [-65.79458618, 18.41319466],
              [-65.79458618, 18.41291618],
              [-65.79486084, 18.41291618],
              [-65.79486084, 18.41263962],
              [-65.7951355, 18.41263962],
              [-65.7951355, 18.41208267],
              [-65.79541779, 18.41208267],
              [-65.79541779, 18.4118042],
              [-65.79569244, 18.4118042],
              [-65.79569244, 18.41124916],
              [-65.79597473, 18.41124916],
              [-65.79597473, 18.41041756],
              [-65.79624939, 18.41041756],
              [-65.79624939, 18.41013908],
              [-65.79652405, 18.41013908],
              [-65.79652405, 18.40986061],
              [-65.79680634, 18.40986061],
              [-65.79680634, 18.40930557],
              [-65.79708099, 18.40930557],
              [-65.79708099, 18.40875053],
              [-65.79792023, 18.40875053],
              [-65.79792023, 18.40847206],
              [-65.79819489, 18.40847206],
              [-65.79819489, 18.40736389],
              [-65.79792023, 18.40736389],
              [-65.79792023, 18.40680504],
              [-65.79763794, 18.40680504],
              [-65.79763794, 18.40652847],
              [-65.79736328, 18.40652847],
              [-65.79736328, 18.40625191],
              [-65.79708099, 18.40625191],
              [-65.79708099, 18.40596962],
              [-65.79680634, 18.40596962],
              [-65.79680634, 18.40569496],
              [-65.79652405, 18.40569496],
              [-65.79652405, 18.40541649],
              [-65.79597473, 18.40541649],
              [-65.79597473, 18.40513992],
              [-65.79458618, 18.40513992],
              [-65.79458618, 18.40541649],
              [-65.79374695, 18.40541649],
              [-65.79374695, 18.40569496],
              [-65.7923584, 18.40569496],
              [-65.7923584, 18.40596962],
              [-65.79013824, 18.40596962],
              [-65.79013824, 18.40625191],
              [-65.78819275, 18.40625191],
              [-65.78819275, 18.40652847],
              [-65.78708649, 18.40652847],
              [-65.78708649, 18.40680504],
              [-65.78652954, 18.40680504],
              [-65.78652954, 18.40708351],
              [-65.7859726, 18.40708351],
              [-65.7859726, 18.40736389],
              [-65.78541565, 18.40736389],
              [-65.78541565, 18.40763855],
              [-65.7848587, 18.40763855],
              [-65.7848587, 18.40791702],
              [-65.78458405, 18.40791702],
              [-65.78458405, 18.40819359],
              [-65.78430176, 18.40819359],
              [-65.78430176, 18.40847206],
              [-65.7840271, 18.40847206],
              [-65.7840271, 18.40875053],
              [-65.78347015, 18.40875053],
              [-65.78347015, 18.4090271],
              [-65.78180695, 18.4090271],
              [-65.78180695, 18.40930557],
              [-65.78152466, 18.40930557],
              [-65.78152466, 18.40958595],
              [-65.78097534, 18.40958595],
              [-65.78097534, 18.40986061],
              [-65.78013611, 18.40986061],
              [-65.78013611, 18.41013908],
              [-65.77958679, 18.41013908],
              [-65.77958679, 18.41041756],
              [-65.77902985, 18.41041756],
              [-65.77902985, 18.41069412],
              [-65.7784729, 18.41069412],
              [-65.7784729, 18.4109726],
              [-65.77819824, 18.4109726],
              [-65.77819824, 18.41124916],
              [-65.77735901, 18.41124916],
              [-65.77735901, 18.41152763],
              [-65.7765274, 18.41152763],
              [-65.7765274, 18.4118042],
              [-65.77625275, 18.4118042],
              [-65.77625275, 18.41208267],
              [-65.77597046, 18.41208267],
              [-65.77597046, 18.41236115],
              [-65.7756958, 18.41236115],
              [-65.7756958, 18.41263962],
              [-65.77541351, 18.41263962],
              [-65.77541351, 18.41291618],
              [-65.77513885, 18.41291618],
              [-65.77513885, 18.41347313],
              [-65.7748642, 18.41347313],
              [-65.7748642, 18.41374969],
              [-65.77458191, 18.41374969],
              [-65.77458191, 18.41402626],
              [-65.77402496, 18.41402626],
              [-65.77402496, 18.41374969],
              [-65.7720871, 18.41374969],
              [-65.7720871, 18.41347313],
              [-65.77124786, 18.41347313],
              [-65.77124786, 18.41319466],
              [-65.77097321, 18.41319466],
              [-65.77097321, 18.41291618],
              [-65.77069092, 18.41291618],
              [-65.77069092, 18.41263962],
              [-65.77041626, 18.41263962],
              [-65.77041626, 18.41208267],
              [-65.7701416, 18.41208267],
              [-65.7701416, 18.41152763],
              [-65.76985931, 18.41152763],
              [-65.76985931, 18.40930557],
              [-65.7701416, 18.40930557],
              [-65.7701416, 18.4090271],
              [-65.77041626, 18.4090271],
              [-65.77041626, 18.40819359],
              [-65.77069092, 18.40819359],
              [-65.77069092, 18.40736389],
              [-65.77097321, 18.40736389],
              [-65.77097321, 18.40708351],
              [-65.77124786, 18.40708351],
              [-65.77124786, 18.40680504],
              [-65.77153015, 18.40680504],
              [-65.77153015, 18.40625191],
              [-65.77180481, 18.40625191],
              [-65.77180481, 18.40485954],
              [-65.7720871, 18.40485954],
              [-65.7720871, 18.40375137],
              [-65.77236176, 18.40375137],
              [-65.77236176, 18.4015274],
              [-65.7720871, 18.4015274],
              [-65.7720871, 18.40125084],
              [-65.77180481, 18.40125084],
              [-65.77180481, 18.40097237],
              [-65.77153015, 18.40097237],
              [-65.77153015, 18.40069389],
              [-65.77124786, 18.40069389],
              [-65.77124786, 18.40013885],
              [-65.77041626, 18.40013885],
              [-65.77041626, 18.39986038],
              [-65.76985931, 18.39986038],
              [-65.76985931, 18.39958382],
              [-65.76958466, 18.39958382],
              [-65.76958466, 18.39930534],
              [-65.76902771, 18.39930534],
              [-65.76902771, 18.39902687],
              [-65.76875305, 18.39902687],
              [-65.76875305, 18.39875031],
              [-65.76847076, 18.39875031],
              [-65.76847076, 18.39847183],
              [-65.76819611, 18.39847183],
              [-65.76819611, 18.39819527],
              [-65.76791382, 18.39819527],
              [-65.76791382, 18.39791679],
              [-65.76597595, 18.39791679],
              [-65.76597595, 18.39763832],
              [-65.76291656, 18.39763832],
              [-65.76291656, 18.39736176],
              [-65.76208496, 18.39736176],
              [-65.76208496, 18.39708328],
              [-65.76180267, 18.39708328],
              [-65.76180267, 18.39652824],
              [-65.76152802, 18.39652824],
              [-65.76152802, 18.3959713],
              [-65.76125336, 18.3959713],
              [-65.76125336, 18.39458466],
              [-65.76097107, 18.39458466],
              [-65.76097107, 18.39430618],
              [-65.76069641, 18.39430618],
              [-65.76069641, 18.39374924],
              [-65.76041412, 18.39374924],
              [-65.76041412, 18.39347267],
              [-65.76013947, 18.39347267],
              [-65.76013947, 18.39263916],
              [-65.75986481, 18.39263916],
              [-65.75986481, 18.39180565],
              [-65.75958252, 18.39180565],
              [-65.75958252, 18.39152718],
              [-65.75846863, 18.39152718],
              [-65.75846863, 18.3912487],
              [-65.75819397, 18.3912487],
              [-65.75819397, 18.39097023],
              [-65.75791931, 18.39097023],
              [-65.75791931, 18.39069366],
              [-65.75736237, 18.39069366],
              [-65.75736237, 18.3904171],
              [-65.75680542, 18.3904171],
              [-65.75680542, 18.39014053],
              [-65.75653076, 18.39014053],
              [-65.75653076, 18.38986015],
              [-65.75569153, 18.38986015],
              [-65.75569153, 18.38958359],
              [-65.75514221, 18.38958359],
              [-65.75514221, 18.38930511],
              [-65.75458527, 18.38930511],
              [-65.75458527, 18.38903046],
              [-65.75430298, 18.38903046],
              [-65.75430298, 18.38874817],
              [-65.75402832, 18.38874817],
              [-65.75402832, 18.3884716],
              [-65.75235748, 18.3884716],
              [-65.75235748, 18.38819504],
              [-65.75208282, 18.38819504],
              [-65.75208282, 18.38791656],
              [-65.75152588, 18.38791656],
              [-65.75152588, 18.38763618],
              [-65.75125122, 18.38763618],
              [-65.75125122, 18.38680649],
              [-65.75096893, 18.38680649],
              [-65.75096893, 18.38541412],
              [-65.74974823, 18.38541412],
              [-65.75002289, 18.38516235],
              [-65.7537384, 18.38040733],
              [-65.7616272, 18.37471008],
              [-65.76394653, 18.37281036],
              [-65.76626587, 18.36948013],
              [-65.77043915, 18.36615753],
              [-65.77044678, 18.36568069],
              [-65.76907349, 18.35852814],
              [-65.76861572, 18.35662079],
              [-65.76815033, 18.35566521],
              [-65.76491547, 18.35470581],
              [-65.76353455, 18.35231781],
              [-65.76122284, 18.34992981],
              [-65.75891113, 18.34801674],
              [-65.75614929, 18.34324455],
              [-65.75428772, 18.34228516],
              [-65.75336456, 18.34132957],
              [-65.75384521, 18.33513451],
              [-65.75616455, 18.33371544],
              [-65.75617218, 18.32942009],
              [-65.75571442, 18.32847023],
              [-65.75154877, 18.32798386],
              [-65.7487793, 18.32559204],
              [-65.74971008, 18.32321358],
              [-65.75202942, 18.32035446],
              [-65.75481415, 18.31941032],
              [-65.75759125, 18.31893921],
              [-65.75804901, 18.31846428],
              [-65.75944519, 18.31561089],
              [-65.76131439, 18.30941772],
              [-65.76271057, 18.30608559],
              [-65.76132965, 18.30226707],
              [-65.75810242, 18.29749298],
              [-65.75811005, 18.29606056],
              [-65.75904083, 18.29416084],
              [-65.75904846, 18.29082489],
              [-65.76043701, 18.28939629],
              [-65.76229095, 18.28892326],
              [-65.7641449, 18.28606987],
              [-65.76554108, 18.28368759],
              [-65.76694489, 18.27988052],
              [-65.77111053, 18.28084373],
              [-65.7743454, 18.28180504],
              [-65.77941895, 18.28610802],
              [-65.78173065, 18.28849792],
              [-65.78264618, 18.2908802],
              [-65.78495026, 18.29422379],
              [-65.78865051, 18.29422951],
              [-65.79327393, 18.29662323],
              [-65.79467773, 18.29138565],
              [-65.8002243, 18.28996658],
              [-65.80532074, 18.28807068],
              [-65.80716705, 18.28902245],
              [-65.8113327, 18.28998947],
              [-65.81179047, 18.28999138],
              [-65.81365204, 18.28665924],
              [-65.81597137, 18.2833252],
              [-65.81921387, 18.27951622],
              [-65.8243103, 18.27285576],
              [-65.8270874, 18.27334023],
              [-65.83171844, 18.27191544],
              [-65.83403015, 18.27287674],
              [-65.83633423, 18.27526283],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 60,
        name: 'Río Grande',
        hasc_1: 'PR.RG',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-65.87693024, 18.27191544, -65.7487793, 18.42263794],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.58',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.55258179, 18.15214539],
              [-66.5562439, 18.14687538],
              [-66.5585556, 18.14590645],
              [-66.55947876, 18.14590073],
              [-66.5622406, 18.14445305],
              [-66.56315613, 18.14349365],
              [-66.56452179, 18.13966751],
              [-66.56451416, 18.13871384],
              [-66.56539154, 18.13012314],
              [-66.56443787, 18.12535667],
              [-66.56160736, 18.11535645],
              [-66.55561066, 18.11587143],
              [-66.55091858, 18.10253906],
              [-66.55091095, 18.1015873],
              [-66.54902649, 18.09539413],
              [-66.55543518, 18.08486176],
              [-66.55543518, 18.0843811],
              [-66.55172729, 18.08202171],
              [-66.54986572, 18.07964516],
              [-66.5451889, 18.06869507],
              [-66.53765869, 18.04201508],
              [-66.5371933, 18.04106522],
              [-66.53855133, 18.03723717],
              [-66.53946686, 18.03580475],
              [-66.54222107, 18.03340149],
              [-66.54267883, 18.03244209],
              [-66.53941345, 18.02626038],
              [-66.53895569, 18.02578354],
              [-66.53754425, 18.02149582],
              [-66.53567505, 18.01673317],
              [-66.53567505, 18.01625633],
              [-66.53050995, 18.00101089],
              [-66.53600311, 17.99238586],
              [-66.54152679, 17.99044418],
              [-66.53930664, 17.97374725],
              [-66.53986359, 17.97374725],
              [-66.53986359, 17.9734726],
              [-66.54125214, 17.9734726],
              [-66.54125214, 17.97319412],
              [-66.54180908, 17.97319412],
              [-66.54180908, 17.9734726],
              [-66.54347229, 17.9734726],
              [-66.54347229, 17.97430611],
              [-66.54430389, 17.97430611],
              [-66.54430389, 17.97458267],
              [-66.54708099, 17.97458267],
              [-66.54708099, 17.97430611],
              [-66.54930878, 17.97430611],
              [-66.54930878, 17.97402954],
              [-66.54958344, 17.97402954],
              [-66.54958344, 17.97374725],
              [-66.55069733, 17.97374725],
              [-66.55069733, 17.9734726],
              [-66.55124664, 17.9734726],
              [-66.55124664, 17.97319412],
              [-66.55152893, 17.97319412],
              [-66.55152893, 17.97291946],
              [-66.55208588, 17.97291946],
              [-66.55208588, 17.97263718],
              [-66.55291748, 17.97263718],
              [-66.55291748, 17.97236061],
              [-66.55347443, 17.97236061],
              [-66.55347443, 17.97208595],
              [-66.55403137, 17.97208595],
              [-66.55403137, 17.97180557],
              [-66.55430603, 17.97180557],
              [-66.55430603, 17.97152519],
              [-66.55513763, 17.97152519],
              [-66.55513763, 17.97097206],
              [-66.55569458, 17.97097206],
              [-66.55569458, 17.97069359],
              [-66.55625153, 17.97069359],
              [-66.55625153, 17.97041512],
              [-66.55652618, 17.97041512],
              [-66.55652618, 17.97013855],
              [-66.55680847, 17.97013855],
              [-66.55680847, 17.96986198],
              [-66.55708313, 17.96986198],
              [-66.55708313, 17.96958351],
              [-66.55735779, 17.96958351],
              [-66.55735779, 17.96930313],
              [-66.55764008, 17.96930313],
              [-66.55764008, 17.96902847],
              [-66.55791473, 17.96902847],
              [-66.55791473, 17.96875],
              [-66.55819702, 17.96875],
              [-66.55819702, 17.96819305],
              [-66.55874634, 17.96819305],
              [-66.55874634, 17.96791649],
              [-66.55902863, 17.96791649],
              [-66.55902863, 17.96763802],
              [-66.56041718, 17.96763802],
              [-66.56041718, 17.96791649],
              [-66.56069183, 17.96791649],
              [-66.56069183, 17.96763802],
              [-66.56208038, 17.96763802],
              [-66.56208038, 17.96736145],
              [-66.56402588, 17.96736145],
              [-66.56402588, 17.96708488],
              [-66.56458282, 17.96708488],
              [-66.56458282, 17.96680641],
              [-66.56680298, 17.96680641],
              [-66.56680298, 17.96708488],
              [-66.56735992, 17.96708488],
              [-66.56735992, 17.96680641],
              [-66.56819153, 17.96680641],
              [-66.56819153, 17.96652794],
              [-66.56930542, 17.96652794],
              [-66.56930542, 17.96624947],
              [-66.56958008, 17.96624947],
              [-66.56958008, 17.9659729],
              [-66.56986237, 17.9659729],
              [-66.56986237, 17.96624947],
              [-66.57013702, 17.96624947],
              [-66.57013702, 17.9659729],
              [-66.57069397, 17.9659729],
              [-66.57069397, 17.96569443],
              [-66.57125092, 17.96569443],
              [-66.57125092, 17.96541595],
              [-66.57208252, 17.96541595],
              [-66.57208252, 17.96513939],
              [-66.57291412, 17.96513939],
              [-66.57291412, 17.96486282],
              [-66.57347107, 17.96486282],
              [-66.57347107, 17.96458244],
              [-66.57375336, 17.96458244],
              [-66.57375336, 17.96430588],
              [-66.57402802, 17.96430588],
              [-66.57402802, 17.9640274],
              [-66.57430267, 17.9640274],
              [-66.57430267, 17.96375084],
              [-66.57485962, 17.96375084],
              [-66.57485962, 17.96347237],
              [-66.57514191, 17.96347237],
              [-66.57514191, 17.96319389],
              [-66.57541656, 17.96319389],
              [-66.57541656, 17.96291733],
              [-66.57624817, 17.96291733],
              [-66.57624817, 17.96264076],
              [-66.57680511, 17.96264076],
              [-66.57680511, 17.96235847],
              [-66.57763672, 17.96235847],
              [-66.57763672, 17.96208382],
              [-66.57819366, 17.96208382],
              [-66.57819366, 17.96180534],
              [-66.57847595, 17.96180534],
              [-66.57847595, 17.96152878],
              [-66.58013916, 17.96152878],
              [-66.58013916, 17.96180534],
              [-66.58125305, 17.96180534],
              [-66.58125305, 17.96152878],
              [-66.58208466, 17.96152878],
              [-66.58208466, 17.96125031],
              [-66.58291626, 17.96125031],
              [-66.58291626, 17.96069527],
              [-66.58347321, 17.96069527],
              [-66.58347321, 17.96125031],
              [-66.58430481, 17.96125031],
              [-66.58430481, 17.96180534],
              [-66.5845871, 17.96180534],
              [-66.5845871, 17.96319389],
              [-66.58486176, 17.96319389],
              [-66.58486176, 17.96375084],
              [-66.58513641, 17.96375084],
              [-66.58513641, 17.96541595],
              [-66.5854187, 17.96541595],
              [-66.5854187, 17.96624947],
              [-66.58569336, 17.96624947],
              [-66.58569336, 17.96652794],
              [-66.58597565, 17.96652794],
              [-66.58597565, 17.96680641],
              [-66.58625031, 17.96680641],
              [-66.58625031, 17.96708488],
              [-66.58652496, 17.96708488],
              [-66.58652496, 17.96736145],
              [-66.58680725, 17.96736145],
              [-66.58680725, 17.96763802],
              [-66.58708191, 17.96763802],
              [-66.58708191, 17.96791649],
              [-66.5873642, 17.96791649],
              [-66.5873642, 17.96819305],
              [-66.58791351, 17.96819305],
              [-66.58791351, 17.96847153],
              [-66.58875275, 17.96847153],
              [-66.58875275, 17.96875],
              [-66.5890274, 17.96875],
              [-66.5890274, 17.96902847],
              [-66.58958435, 17.96902847],
              [-66.58958435, 17.96930313],
              [-66.5901413, 17.96930313],
              [-66.5901413, 17.96958351],
              [-66.59124756, 17.96958351],
              [-66.59124756, 17.96986198],
              [-66.5918045, 17.96986198],
              [-66.5918045, 17.97013855],
              [-66.59208679, 17.97013855],
              [-66.59208679, 17.97041512],
              [-66.59319305, 17.97041512],
              [-66.59319305, 17.97069359],
              [-66.59375, 17.97069359],
              [-66.59375, 17.97097206],
              [-66.59402466, 17.97097206],
              [-66.59402466, 17.97125053],
              [-66.59486389, 17.97125053],
              [-66.59486389, 17.97097206],
              [-66.5956955, 17.97097206],
              [-66.5956955, 17.97069359],
              [-66.59625244, 17.97069359],
              [-66.59625244, 17.97041512],
              [-66.5965271, 17.97041512],
              [-66.5965271, 17.97013855],
              [-66.59680176, 17.97013855],
              [-66.59680176, 17.96986198],
              [-66.59708405, 17.96986198],
              [-66.59708405, 17.96930313],
              [-66.5973587, 17.96930313],
              [-66.5973587, 17.96902847],
              [-66.59819794, 17.96902847],
              [-66.59819794, 17.96875],
              [-66.59874725, 17.96875],
              [-66.59874725, 17.96847153],
              [-66.59902954, 17.96847153],
              [-66.59902954, 17.96736145],
              [-66.59958649, 17.96736145],
              [-66.59958649, 17.96847153],
              [-66.60041809, 17.96847153],
              [-66.60041809, 17.96875],
              [-66.60097504, 17.96875],
              [-66.60097504, 17.96930313],
              [-66.60152435, 17.96930313],
              [-66.60152435, 17.96902847],
              [-66.60180664, 17.96902847],
              [-66.60180664, 17.96930313],
              [-66.6029129, 17.96930313],
              [-66.6029129, 17.96902847],
              [-66.60430908, 17.96902847],
              [-66.60430908, 17.96875],
              [-66.60597229, 17.96875],
              [-66.60597229, 17.96847153],
              [-66.60680389, 17.96847153],
              [-66.60680389, 17.96819305],
              [-66.60736084, 17.96819305],
              [-66.60736084, 17.96791649],
              [-66.60791779, 17.96791649],
              [-66.60791779, 17.96763802],
              [-66.60819244, 17.96763802],
              [-66.60819244, 17.96736145],
              [-66.60847473, 17.96736145],
              [-66.60847473, 17.96708488],
              [-66.60874939, 17.96708488],
              [-66.60874939, 17.96652794],
              [-66.60902405, 17.96652794],
              [-66.60902405, 17.96624947],
              [-66.60958099, 17.96624947],
              [-66.60958099, 17.96541595],
              [-66.60930634, 17.96541595],
              [-66.60930634, 17.96513939],
              [-66.60958099, 17.96513939],
              [-66.60958099, 17.96486282],
              [-66.60986328, 17.96486282],
              [-66.60986328, 17.96458244],
              [-66.61013794, 17.96458244],
              [-66.61013794, 17.96347237],
              [-66.61152649, 17.96347237],
              [-66.61152649, 17.96375084],
              [-66.61264038, 17.96375084],
              [-66.61264038, 17.96291733],
              [-66.61291504, 17.96291733],
              [-66.61291504, 17.96264076],
              [-66.61319733, 17.96264076],
              [-66.61319733, 17.96235847],
              [-66.61541748, 17.96235847],
              [-66.61541748, 17.96208382],
              [-66.61930847, 17.96208382],
              [-66.61930847, 17.96235847],
              [-66.61985779, 17.96235847],
              [-66.61985779, 17.96264076],
              [-66.61958313, 17.96264076],
              [-66.61958313, 17.96291733],
              [-66.61930847, 17.96291733],
              [-66.61930847, 17.96375084],
              [-66.61902618, 17.96375084],
              [-66.61902618, 17.96430588],
              [-66.61875153, 17.96430588],
              [-66.61875153, 17.96458244],
              [-66.61846924, 17.96458244],
              [-66.61846924, 17.96486282],
              [-66.61819458, 17.96486282],
              [-66.61819458, 17.96513939],
              [-66.61791992, 17.96513939],
              [-66.61791992, 17.96541595],
              [-66.61763763, 17.96541595],
              [-66.61763763, 17.96569443],
              [-66.61736298, 17.96569443],
              [-66.61736298, 17.96652794],
              [-66.61763763, 17.96652794],
              [-66.61763763, 17.96708488],
              [-66.61791992, 17.96708488],
              [-66.61791992, 17.96736145],
              [-66.61846924, 17.96736145],
              [-66.61846924, 17.96763802],
              [-66.61902618, 17.96763802],
              [-66.61902618, 17.96791649],
              [-66.61958313, 17.96791649],
              [-66.61958313, 17.96763802],
              [-66.62014008, 17.96763802],
              [-66.62014008, 17.96708488],
              [-66.62041473, 17.96708488],
              [-66.62041473, 17.96680641],
              [-66.62069702, 17.96680641],
              [-66.62069702, 17.96652794],
              [-66.62097168, 17.96652794],
              [-66.62097168, 17.96847153],
              [-66.62069702, 17.96847153],
              [-66.62069702, 17.96902847],
              [-66.62041473, 17.96902847],
              [-66.62041473, 17.96930313],
              [-66.62014008, 17.96930313],
              [-66.62014008, 17.96958351],
              [-66.61985779, 17.96958351],
              [-66.61985779, 17.96986198],
              [-66.61930847, 17.96986198],
              [-66.61930847, 17.97013855],
              [-66.61902618, 17.97013855],
              [-66.61902618, 17.97069359],
              [-66.61875153, 17.97069359],
              [-66.61875153, 17.97097206],
              [-66.61736298, 17.97097206],
              [-66.61736298, 17.97125053],
              [-66.61680603, 17.97125053],
              [-66.61680603, 17.97152519],
              [-66.61624908, 17.97152519],
              [-66.61624908, 17.97208595],
              [-66.61653137, 17.97208595],
              [-66.61653137, 17.97236061],
              [-66.61680603, 17.97236061],
              [-66.61680603, 17.97263718],
              [-66.61708069, 17.97263718],
              [-66.61708069, 17.97319412],
              [-66.61763763, 17.97319412],
              [-66.61763763, 17.9734726],
              [-66.61819458, 17.9734726],
              [-66.61819458, 17.97374725],
              [-66.61846924, 17.97374725],
              [-66.61846924, 17.97402954],
              [-66.61875153, 17.97402954],
              [-66.61875153, 17.97569466],
              [-66.61846924, 17.97569466],
              [-66.61846924, 17.9762516],
              [-66.61819458, 17.9762516],
              [-66.61819458, 17.97652817],
              [-66.61791992, 17.97652817],
              [-66.61791992, 17.97680473],
              [-66.61763763, 17.97680473],
              [-66.61763763, 17.97708321],
              [-66.61736298, 17.97708321],
              [-66.61736298, 17.97763824],
              [-66.61763763, 17.97763824],
              [-66.61763763, 17.97791672],
              [-66.61819458, 17.97791672],
              [-66.61819458, 17.97819519],
              [-66.61930847, 17.97819519],
              [-66.61930847, 17.97847176],
              [-66.62014008, 17.97847176],
              [-66.62014008, 17.97875023],
              [-66.62069702, 17.97875023],
              [-66.62069702, 17.9790287],
              [-66.62124634, 17.9790287],
              [-66.62124634, 17.97930527],
              [-66.62180328, 17.97930527],
              [-66.62180328, 17.97986031],
              [-66.62208557, 17.97986031],
              [-66.62208557, 17.98041725],
              [-66.62236023, 17.98041725],
              [-66.62236023, 17.98069382],
              [-66.62264252, 17.98069382],
              [-66.62264252, 17.98097229],
              [-66.62374878, 17.98097229],
              [-66.62374878, 17.98125076],
              [-66.62458038, 17.98125076],
              [-66.62458038, 17.98152733],
              [-66.62513733, 17.98152733],
              [-66.62513733, 17.9818058],
              [-66.62569427, 17.9818058],
              [-66.62569427, 17.98208427],
              [-66.62680817, 17.98208427],
              [-66.62680817, 17.98236084],
              [-66.62708282, 17.98236084],
              [-66.62708282, 17.98263931],
              [-66.62847137, 17.98263931],
              [-66.62847137, 17.98236084],
              [-66.62875366, 17.98236084],
              [-66.62875366, 17.98208427],
              [-66.62930298, 17.98208427],
              [-66.62930298, 17.98263931],
              [-66.63097382, 17.98263931],
              [-66.63097382, 17.98236084],
              [-66.63430786, 17.98236084],
              [-66.63430786, 17.98208427],
              [-66.63541412, 17.98208427],
              [-66.63541412, 17.9818058],
              [-66.63819122, 17.9818058],
              [-66.63819122, 17.98152733],
              [-66.63903046, 17.98152733],
              [-66.63903046, 17.98125076],
              [-66.6423645, 17.98125076],
              [-66.6423645, 17.98041725],
              [-66.64208221, 17.98041725],
              [-66.64208221, 17.97986031],
              [-66.6423645, 17.97986031],
              [-66.6423645, 17.97958374],
              [-66.64263916, 17.97958374],
              [-66.64263916, 17.97986031],
              [-66.64291382, 17.97986031],
              [-66.64291382, 17.98069382],
              [-66.64319611, 17.98069382],
              [-66.64319611, 17.98097229],
              [-66.64430237, 17.98097229],
              [-66.64430237, 17.98069382],
              [-66.64458466, 17.98069382],
              [-66.64458466, 17.98041725],
              [-66.64624786, 17.98041725],
              [-66.64624786, 17.98013878],
              [-66.6479187, 17.98013878],
              [-66.6479187, 17.97986031],
              [-66.64958191, 17.97986031],
              [-66.64958191, 17.97958374],
              [-66.6498642, 17.97958374],
              [-66.6498642, 17.97930527],
              [-66.65013885, 17.97930527],
              [-66.65013885, 17.9790287],
              [-66.65041351, 17.9790287],
              [-66.65041351, 17.97875023],
              [-66.6515274, 17.97875023],
              [-66.6515274, 17.97847176],
              [-66.65180206, 17.97847176],
              [-66.65180206, 17.97791672],
              [-66.65291595, 17.97791672],
              [-66.65291595, 17.97763824],
              [-66.6534729, 17.97763824],
              [-66.6534729, 17.97736168],
              [-66.65402985, 17.97736168],
              [-66.65402985, 17.97708321],
              [-66.6543045, 17.97708321],
              [-66.6543045, 17.97680473],
              [-66.65486145, 17.97680473],
              [-66.65486145, 17.97652817],
              [-66.65513611, 17.97652817],
              [-66.65513611, 17.9762516],
              [-66.6554184, 17.9762516],
              [-66.6554184, 17.97597313],
              [-66.65597534, 17.97597313],
              [-66.65597534, 17.97569466],
              [-66.65680695, 17.97569466],
              [-66.65680695, 17.97541618],
              [-66.6570816, 17.97541618],
              [-66.6570816, 17.97513962],
              [-66.65736389, 17.97513962],
              [-66.65736389, 17.97486115],
              [-66.65763855, 17.97486115],
              [-66.65763855, 17.97458267],
              [-66.65791321, 17.97458267],
              [-66.65791321, 17.97430611],
              [-66.6581955, 17.97430611],
              [-66.6581955, 17.97402954],
              [-66.65875244, 17.97402954],
              [-66.65875244, 17.97374725],
              [-66.65930176, 17.97374725],
              [-66.65930176, 17.9734726],
              [-66.65958405, 17.9734726],
              [-66.65958405, 17.97319412],
              [-66.66014099, 17.97319412],
              [-66.66014099, 17.97291946],
              [-66.66041565, 17.97291946],
              [-66.66041565, 17.97263718],
              [-66.66069794, 17.97263718],
              [-66.66069794, 17.97236061],
              [-66.66124725, 17.97236061],
              [-66.66124725, 17.97180557],
              [-66.66152954, 17.97180557],
              [-66.66152954, 17.97125053],
              [-66.6618042, 17.97125053],
              [-66.6618042, 17.97069359],
              [-66.66208649, 17.97069359],
              [-66.66208649, 17.97041512],
              [-66.66236115, 17.97041512],
              [-66.66236115, 17.97013855],
              [-66.66291809, 17.97013855],
              [-66.66291809, 17.97041512],
              [-66.66347504, 17.97041512],
              [-66.66347504, 17.97069359],
              [-66.66374969, 17.97069359],
              [-66.66374969, 17.97097206],
              [-66.66430664, 17.97097206],
              [-66.66430664, 17.97041512],
              [-66.66402435, 17.97041512],
              [-66.66402435, 17.97013855],
              [-66.66374969, 17.97013855],
              [-66.66374969, 17.96986198],
              [-66.66347504, 17.96986198],
              [-66.66347504, 17.96958351],
              [-66.66291809, 17.96958351],
              [-66.66291809, 17.96930313],
              [-66.6626358, 17.96930313],
              [-66.6626358, 17.96902847],
              [-66.66236115, 17.96902847],
              [-66.66236115, 17.96847153],
              [-66.66291809, 17.96847153],
              [-66.66291809, 17.96819305],
              [-66.66347504, 17.96819305],
              [-66.66347504, 17.96791649],
              [-66.6645813, 17.96791649],
              [-66.6645813, 17.96847153],
              [-66.66513824, 17.96847153],
              [-66.66513824, 17.96875],
              [-66.66680908, 17.96875],
              [-66.66680908, 17.96819305],
              [-66.66708374, 17.96819305],
              [-66.66708374, 17.96736145],
              [-66.66764069, 17.96736145],
              [-66.66764069, 17.96708488],
              [-66.66791534, 17.96708488],
              [-66.66791534, 17.96680641],
              [-66.66819763, 17.96680641],
              [-66.66819763, 17.96708488],
              [-66.66902924, 17.96708488],
              [-66.66902924, 17.96680641],
              [-66.67097473, 17.96680641],
              [-66.67097473, 17.96624947],
              [-66.67124939, 17.96624947],
              [-66.67124939, 17.9659729],
              [-66.67236328, 17.9659729],
              [-66.67236328, 17.96624947],
              [-66.67292023, 17.96624947],
              [-66.67292023, 17.96652794],
              [-66.67346954, 17.96652794],
              [-66.67346954, 17.96680641],
              [-66.67375183, 17.96680641],
              [-66.67375183, 17.96708488],
              [-66.67458344, 17.96708488],
              [-66.67458344, 17.96791649],
              [-66.67430878, 17.96791649],
              [-66.67430878, 17.96819305],
              [-66.67402649, 17.96819305],
              [-66.67402649, 17.96847153],
              [-66.67430878, 17.96847153],
              [-66.67430878, 17.96875],
              [-66.67458344, 17.96875],
              [-66.67458344, 17.96902847],
              [-66.67485809, 17.96902847],
              [-66.67485809, 17.96930313],
              [-66.67514038, 17.96930313],
              [-66.67514038, 17.96986198],
              [-66.67541504, 17.96986198],
              [-66.67541504, 17.97013855],
              [-66.67569733, 17.97013855],
              [-66.67569733, 17.97041512],
              [-66.67652893, 17.97041512],
              [-66.67652893, 17.97069359],
              [-66.67708588, 17.97069359],
              [-66.67708588, 17.97125053],
              [-66.67736053, 17.97125053],
              [-66.67736053, 17.97180557],
              [-66.67763519, 17.97180557],
              [-66.67763519, 17.97208595],
              [-66.67819214, 17.97208595],
              [-66.67819214, 17.97236061],
              [-66.67874908, 17.97236061],
              [-66.67874908, 17.97263718],
              [-66.67903137, 17.97263718],
              [-66.67903137, 17.97291946],
              [-66.67958069, 17.97291946],
              [-66.67958069, 17.97319412],
              [-66.68041992, 17.97319412],
              [-66.68041992, 17.9734726],
              [-66.68208313, 17.9734726],
              [-66.68208313, 17.97319412],
              [-66.68291473, 17.97319412],
              [-66.68291473, 17.97291946],
              [-66.68458557, 17.97291946],
              [-66.68458557, 17.97263718],
              [-66.68486023, 17.97263718],
              [-66.68486023, 17.97291946],
              [-66.68541718, 17.97291946],
              [-66.68541718, 17.97374725],
              [-66.68597412, 17.97374725],
              [-66.68597412, 17.9734726],
              [-66.68875122, 17.9734726],
              [-66.68875122, 17.97402954],
              [-66.68930817, 17.97402954],
              [-66.68930817, 17.97430611],
              [-66.68958282, 17.97430611],
              [-66.68958282, 17.97458267],
              [-66.69097137, 17.97458267],
              [-66.69097137, 17.97486115],
              [-66.69180298, 17.97486115],
              [-66.69180298, 17.97513962],
              [-66.69430542, 17.97513962],
              [-66.69430542, 17.97458267],
              [-66.69486237, 17.97458267],
              [-66.69486237, 17.97486115],
              [-66.69513702, 17.97486115],
              [-66.69513702, 17.97513962],
              [-66.69569397, 17.97513962],
              [-66.69569397, 17.97541618],
              [-66.69596863, 17.97541618],
              [-66.69596863, 17.97569466],
              [-66.69652557, 17.97569466],
              [-66.69652557, 17.97597313],
              [-66.69680786, 17.97597313],
              [-66.69680786, 17.9762516],
              [-66.69708252, 17.9762516],
              [-66.69708252, 17.97652817],
              [-66.69763947, 17.97652817],
              [-66.69763947, 17.97680473],
              [-66.69791412, 17.97680473],
              [-66.69791412, 17.97708321],
              [-66.69869232, 17.97708321],
              [-66.69827271, 17.98127174],
              [-66.69504547, 17.98224831],
              [-66.69321442, 17.9836998],
              [-66.69184113, 17.98513794],
              [-66.68680573, 17.99090576],
              [-66.68727875, 17.9928112],
              [-66.68778992, 17.99901581],
              [-66.69012451, 18.00377083],
              [-66.68651581, 18.015728],
              [-66.6865387, 18.01763725],
              [-66.68701172, 18.02049828],
              [-66.68938446, 18.03002548],
              [-66.68942261, 18.03480148],
              [-66.68946075, 18.04005051],
              [-66.68724823, 18.05390358],
              [-66.68497467, 18.05917358],
              [-66.67998505, 18.07066345],
              [-66.67952728, 18.07161903],
              [-66.67953491, 18.07257271],
              [-66.67824554, 18.08642006],
              [-66.67825317, 18.08832741],
              [-66.67881012, 18.10168457],
              [-66.67882538, 18.10359383],
              [-66.68115234, 18.10691643],
              [-66.69143677, 18.12496758],
              [-66.69425201, 18.13067055],
              [-66.68502045, 18.1316967],
              [-66.6831665, 18.13075256],
              [-66.68084717, 18.12933922],
              [-66.67761993, 18.12984276],
              [-66.67669678, 18.13032532],
              [-66.67307281, 18.14037132],
              [-66.67171478, 18.1446743],
              [-66.67038727, 18.15279388],
              [-66.66622925, 18.15329933],
              [-66.66392517, 18.15379333],
              [-66.65701294, 18.15670586],
              [-66.65379333, 18.15863419],
              [-66.65195465, 18.15912628],
              [-66.65010071, 18.15913773],
              [-66.64963531, 18.1586647],
              [-66.63942719, 18.15205956],
              [-66.63156891, 18.15068054],
              [-66.62976837, 18.15784836],
              [-66.62515259, 18.15836143],
              [-66.61591339, 18.15889549],
              [-66.60946655, 18.16275787],
              [-66.60533905, 18.16659927],
              [-66.59612274, 18.17143059],
              [-66.59104156, 18.17193985],
              [-66.57485199, 18.16822433],
              [-66.5674057, 18.15968513],
              [-66.55905914, 18.15353775],
              [-66.55258179, 18.15214539],
            ],
          ],
          [
            [
              [-66.55319214, 17.96180534],
              [-66.55319214, 17.96152878],
              [-66.55541992, 17.96152878],
              [-66.55541992, 17.96125031],
              [-66.55652618, 17.96125031],
              [-66.55652618, 17.96152878],
              [-66.55680847, 17.96152878],
              [-66.55680847, 17.96180534],
              [-66.55764008, 17.96180534],
              [-66.55764008, 17.96125031],
              [-66.55819702, 17.96125031],
              [-66.55819702, 17.96152878],
              [-66.55847168, 17.96152878],
              [-66.55847168, 17.96235847],
              [-66.55819702, 17.96235847],
              [-66.55819702, 17.96264076],
              [-66.55764008, 17.96264076],
              [-66.55764008, 17.96291733],
              [-66.55680847, 17.96291733],
              [-66.55680847, 17.96319389],
              [-66.55347443, 17.96319389],
              [-66.55347443, 17.96347237],
              [-66.55208588, 17.96347237],
              [-66.55208588, 17.96235847],
              [-66.55236053, 17.96235847],
              [-66.55236053, 17.96208382],
              [-66.55263519, 17.96208382],
              [-66.55263519, 17.96180534],
              [-66.55319214, 17.96180534],
            ],
          ],
          [
            [
              [-66.68013763, 17.95402718],
              [-66.68013763, 17.95375061],
              [-66.67958069, 17.95375061],
              [-66.67958069, 17.95347214],
              [-66.67930603, 17.95347214],
              [-66.67930603, 17.95319366],
              [-66.67903137, 17.95319366],
              [-66.67903137, 17.95236206],
              [-66.67930603, 17.95236206],
              [-66.67930603, 17.95180511],
              [-66.67958069, 17.95180511],
              [-66.67958069, 17.95152855],
              [-66.68041992, 17.95152855],
              [-66.68041992, 17.95208359],
              [-66.68096924, 17.95208359],
              [-66.68096924, 17.95180511],
              [-66.68152618, 17.95180511],
              [-66.68152618, 17.9509716],
              [-66.68264008, 17.9509716],
              [-66.68264008, 17.95125008],
              [-66.68291473, 17.95125008],
              [-66.68291473, 17.95152855],
              [-66.68319702, 17.95152855],
              [-66.68319702, 17.95208359],
              [-66.68347168, 17.95208359],
              [-66.68347168, 17.95263863],
              [-66.68319702, 17.95263863],
              [-66.68319702, 17.9529171],
              [-66.68264008, 17.9529171],
              [-66.68264008, 17.95347214],
              [-66.68180847, 17.95347214],
              [-66.68180847, 17.95402718],
              [-66.68125153, 17.95402718],
              [-66.68125153, 17.95375061],
              [-66.68069458, 17.95375061],
              [-66.68069458, 17.95402718],
              [-66.68013763, 17.95402718],
            ],
          ],
          [
            [
              [-66.51235962, 17.90291405],
              [-66.51235962, 17.90263939],
              [-66.51208496, 17.90263939],
              [-66.51208496, 17.90236092],
              [-66.51180267, 17.90236092],
              [-66.51180267, 17.90208244],
              [-66.51125336, 17.90208244],
              [-66.51125336, 17.90180588],
              [-66.51097107, 17.90180588],
              [-66.51097107, 17.9015274],
              [-66.51069641, 17.9015274],
              [-66.51069641, 17.90125084],
              [-66.51013947, 17.90125084],
              [-66.51013947, 17.90069389],
              [-66.50986481, 17.90069389],
              [-66.50986481, 17.89958382],
              [-66.50958252, 17.89958382],
              [-66.50958252, 17.89819527],
              [-66.50986481, 17.89819527],
              [-66.50986481, 17.89791679],
              [-66.51041412, 17.89791679],
              [-66.51041412, 17.89763832],
              [-66.51097107, 17.89763832],
              [-66.51097107, 17.89736176],
              [-66.51125336, 17.89736176],
              [-66.51125336, 17.89708328],
              [-66.51152802, 17.89708328],
              [-66.51152802, 17.89680481],
              [-66.51180267, 17.89680481],
              [-66.51180267, 17.89652824],
              [-66.51208496, 17.89652824],
              [-66.51208496, 17.8959713],
              [-66.51235962, 17.8959713],
              [-66.51235962, 17.89569473],
              [-66.51264191, 17.89569473],
              [-66.51264191, 17.89541626],
              [-66.51291656, 17.89541626],
              [-66.51291656, 17.89513969],
              [-66.51319122, 17.89513969],
              [-66.51319122, 17.89486122],
              [-66.51347351, 17.89486122],
              [-66.51347351, 17.89430618],
              [-66.51374817, 17.89430618],
              [-66.51374817, 17.89402962],
              [-66.51403046, 17.89402962],
              [-66.51403046, 17.89347267],
              [-66.51430511, 17.89347267],
              [-66.51430511, 17.8931942],
              [-66.51457977, 17.8931942],
              [-66.51457977, 17.89291573],
              [-66.51513672, 17.89291573],
              [-66.51513672, 17.89236069],
              [-66.51541901, 17.89236069],
              [-66.51541901, 17.89208412],
              [-66.51569366, 17.89208412],
              [-66.51569366, 17.89180756],
              [-66.51625061, 17.89180756],
              [-66.51625061, 17.89152527],
              [-66.51680756, 17.89152527],
              [-66.51680756, 17.89125061],
              [-66.5173645, 17.89125061],
              [-66.5173645, 17.89097214],
              [-66.51985931, 17.89097214],
              [-66.51985931, 17.89069366],
              [-66.52041626, 17.89069366],
              [-66.52041626, 17.89041519],
              [-66.52069092, 17.89041519],
              [-66.52069092, 17.89013863],
              [-66.52153015, 17.89013863],
              [-66.52153015, 17.88986206],
              [-66.5220871, 17.88986206],
              [-66.5220871, 17.88958359],
              [-66.52236176, 17.88958359],
              [-66.52236176, 17.88930321],
              [-66.52263641, 17.88930321],
              [-66.52263641, 17.88902855],
              [-66.52319336, 17.88902855],
              [-66.52319336, 17.8884716],
              [-66.52347565, 17.8884716],
              [-66.52347565, 17.88819313],
              [-66.52375031, 17.88819313],
              [-66.52375031, 17.88791656],
              [-66.52402496, 17.88791656],
              [-66.52402496, 17.88763809],
              [-66.52430725, 17.88763809],
              [-66.52430725, 17.88736153],
              [-66.52458191, 17.88736153],
              [-66.52458191, 17.88708305],
              [-66.52513885, 17.88708305],
              [-66.52513885, 17.88680649],
              [-66.52625275, 17.88680649],
              [-66.52625275, 17.88652802],
              [-66.5265274, 17.88652802],
              [-66.5265274, 17.88624954],
              [-66.52680206, 17.88624954],
              [-66.52680206, 17.88597107],
              [-66.52708435, 17.88597107],
              [-66.52708435, 17.8848629],
              [-66.52735901, 17.8848629],
              [-66.52735901, 17.88430595],
              [-66.5276413, 17.88430595],
              [-66.5276413, 17.88375092],
              [-66.52791595, 17.88375092],
              [-66.52791595, 17.88319397],
              [-66.5284729, 17.88319397],
              [-66.5284729, 17.8829174],
              [-66.52874756, 17.8829174],
              [-66.52874756, 17.88264084],
              [-66.52958679, 17.88264084],
              [-66.52958679, 17.88319397],
              [-66.52986145, 17.88319397],
              [-66.52986145, 17.88375092],
              [-66.53013611, 17.88375092],
              [-66.53013611, 17.88402748],
              [-66.5304184, 17.88402748],
              [-66.5304184, 17.88430595],
              [-66.53013611, 17.88430595],
              [-66.53013611, 17.88458252],
              [-66.52986145, 17.88458252],
              [-66.52986145, 17.88513947],
              [-66.5284729, 17.88513947],
              [-66.5284729, 17.88541603],
              [-66.52791595, 17.88541603],
              [-66.52791595, 17.8856945],
              [-66.5276413, 17.8856945],
              [-66.5276413, 17.88597107],
              [-66.52791595, 17.88597107],
              [-66.52791595, 17.88652802],
              [-66.52819824, 17.88652802],
              [-66.52819824, 17.88791656],
              [-66.52791595, 17.88791656],
              [-66.52791595, 17.88902855],
              [-66.5276413, 17.88902855],
              [-66.5276413, 17.88958359],
              [-66.52735901, 17.88958359],
              [-66.52735901, 17.89041519],
              [-66.52708435, 17.89041519],
              [-66.52708435, 17.89125061],
              [-66.52680206, 17.89125061],
              [-66.52680206, 17.89208412],
              [-66.5265274, 17.89208412],
              [-66.5265274, 17.89347267],
              [-66.52625275, 17.89347267],
              [-66.52625275, 17.89402962],
              [-66.52597046, 17.89402962],
              [-66.52597046, 17.89430618],
              [-66.5256958, 17.89430618],
              [-66.5256958, 17.89458275],
              [-66.52541351, 17.89458275],
              [-66.52541351, 17.89486122],
              [-66.52513885, 17.89486122],
              [-66.52513885, 17.89513969],
              [-66.5248642, 17.89513969],
              [-66.5248642, 17.89541626],
              [-66.52458191, 17.89541626],
              [-66.52458191, 17.8959713],
              [-66.52430725, 17.8959713],
              [-66.52430725, 17.89625168],
              [-66.52402496, 17.89625168],
              [-66.52402496, 17.89652824],
              [-66.52347565, 17.89652824],
              [-66.52347565, 17.89680481],
              [-66.5229187, 17.89680481],
              [-66.5229187, 17.89708328],
              [-66.52263641, 17.89708328],
              [-66.52263641, 17.89736176],
              [-66.52236176, 17.89736176],
              [-66.52236176, 17.89763832],
              [-66.5220871, 17.89763832],
              [-66.5220871, 17.89791679],
              [-66.52180481, 17.89791679],
              [-66.52180481, 17.89819527],
              [-66.52153015, 17.89819527],
              [-66.52153015, 17.89847183],
              [-66.52124786, 17.89847183],
              [-66.52124786, 17.89875031],
              [-66.5201416, 17.89875031],
              [-66.5201416, 17.89902687],
              [-66.51958466, 17.89902687],
              [-66.51958466, 17.89958382],
              [-66.51875305, 17.89958382],
              [-66.51875305, 17.89986038],
              [-66.51819611, 17.89986038],
              [-66.51819611, 17.90041733],
              [-66.51763916, 17.90041733],
              [-66.51763916, 17.90069389],
              [-66.51652527, 17.90069389],
              [-66.51652527, 17.90097237],
              [-66.51597595, 17.90097237],
              [-66.51597595, 17.90125084],
              [-66.51569366, 17.90125084],
              [-66.51569366, 17.9015274],
              [-66.51541901, 17.9015274],
              [-66.51541901, 17.90180588],
              [-66.51513672, 17.90180588],
              [-66.51513672, 17.90208244],
              [-66.51430511, 17.90208244],
              [-66.51430511, 17.90236092],
              [-66.51403046, 17.90236092],
              [-66.51403046, 17.90291405],
              [-66.51235962, 17.90291405],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 58,
        name: 'Ponce',
        hasc_1: 'PR.PO',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.69869232, 17.88264084, -66.50958252, 18.17193985],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.59',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.89871216, 18.36635017],
              [-66.9033432, 18.3663044],
              [-66.90473175, 18.36629295],
              [-66.90566254, 18.36723328],
              [-66.90756226, 18.37198257],
              [-66.90988922, 18.37386322],
              [-66.91407776, 18.37573051],
              [-66.91966248, 18.37901115],
              [-66.92202759, 18.38375092],
              [-66.92250824, 18.38565063],
              [-66.92344666, 18.38707161],
              [-66.92211914, 18.39328003],
              [-66.92214203, 18.39661407],
              [-66.92262268, 18.39803886],
              [-66.92355347, 18.39850616],
              [-66.92448425, 18.39849663],
              [-66.92679596, 18.39847374],
              [-66.931427, 18.39842987],
              [-66.94023132, 18.3992939],
              [-66.94255066, 18.3992691],
              [-66.94721222, 18.40160179],
              [-66.94815826, 18.4034996],
              [-66.947258, 18.40636826],
              [-66.9458847, 18.40828514],
              [-66.94404602, 18.40973663],
              [-66.94173431, 18.40976143],
              [-66.93942261, 18.41073608],
              [-66.93482971, 18.41554451],
              [-66.93437958, 18.41650581],
              [-66.93438721, 18.41745758],
              [-66.93533325, 18.41840172],
              [-66.94138336, 18.42167091],
              [-66.94326019, 18.42403412],
              [-66.94709015, 18.43685913],
              [-66.94756317, 18.4378109],
              [-66.94941711, 18.43826675],
              [-66.95824432, 18.44055939],
              [-66.96668243, 18.44999313],
              [-66.96349335, 18.4557476],
              [-66.95895386, 18.46531677],
              [-66.96365356, 18.47193718],
              [-66.96273804, 18.47337723],
              [-66.95304108, 18.47681236],
              [-66.95257568, 18.47729492],
              [-66.95708466, 18.48875046],
              [-66.95708466, 18.48902702],
              [-66.95652771, 18.48902702],
              [-66.95652771, 18.48875046],
              [-66.9548645, 18.48875046],
              [-66.9548645, 18.48847389],
              [-66.95430756, 18.48847389],
              [-66.95430756, 18.48875046],
              [-66.95347595, 18.48875046],
              [-66.95347595, 18.48902702],
              [-66.95291901, 18.48902702],
              [-66.95291901, 18.4893055],
              [-66.95263672, 18.4893055],
              [-66.95263672, 18.48958588],
              [-66.95236206, 18.48958588],
              [-66.95236206, 18.48986053],
              [-66.95180511, 18.48986053],
              [-66.95180511, 18.49013901],
              [-66.95153046, 18.49013901],
              [-66.95153046, 18.49041748],
              [-66.95124817, 18.49041748],
              [-66.95124817, 18.49069595],
              [-66.95069122, 18.49069595],
              [-66.95069122, 18.49097252],
              [-66.95041656, 18.49097252],
              [-66.95041656, 18.49124908],
              [-66.94985962, 18.49124908],
              [-66.94985962, 18.49097252],
              [-66.94958496, 18.49097252],
              [-66.94958496, 18.49069595],
              [-66.94902802, 18.49069595],
              [-66.94902802, 18.49097252],
              [-66.94847107, 18.49097252],
              [-66.94847107, 18.49124908],
              [-66.94819641, 18.49124908],
              [-66.94819641, 18.49152756],
              [-66.94791412, 18.49152756],
              [-66.94791412, 18.49180412],
              [-66.94736481, 18.49180412],
              [-66.94736481, 18.49152756],
              [-66.94680786, 18.49152756],
              [-66.94680786, 18.49124908],
              [-66.94596863, 18.49124908],
              [-66.94596863, 18.49152756],
              [-66.94374847, 18.49152756],
              [-66.94374847, 18.49180412],
              [-66.94319153, 18.49180412],
              [-66.94319153, 18.49152756],
              [-66.94291687, 18.49152756],
              [-66.94291687, 18.49124908],
              [-66.94264221, 18.49124908],
              [-66.94264221, 18.49097252],
              [-66.94125366, 18.49097252],
              [-66.94125366, 18.49124908],
              [-66.94097137, 18.49124908],
              [-66.94097137, 18.49152756],
              [-66.93985748, 18.49152756],
              [-66.93985748, 18.49124908],
              [-66.93875122, 18.49124908],
              [-66.93875122, 18.49097252],
              [-66.93846893, 18.49097252],
              [-66.93846893, 18.49041748],
              [-66.93819427, 18.49041748],
              [-66.93819427, 18.49013901],
              [-66.93763733, 18.49013901],
              [-66.93763733, 18.49041748],
              [-66.93736267, 18.49041748],
              [-66.93736267, 18.49069595],
              [-66.93624878, 18.49069595],
              [-66.93624878, 18.49013901],
              [-66.93569183, 18.49013901],
              [-66.93569183, 18.48986053],
              [-66.93486023, 18.48986053],
              [-66.93486023, 18.48958588],
              [-66.93319702, 18.48958588],
              [-66.93319702, 18.4893055],
              [-66.93264008, 18.4893055],
              [-66.93264008, 18.48902702],
              [-66.93125153, 18.48902702],
              [-66.93125153, 18.48875046],
              [-66.93069458, 18.48875046],
              [-66.93069458, 18.48847389],
              [-66.93041992, 18.48847389],
              [-66.93041992, 18.48791695],
              [-66.92986298, 18.48791695],
              [-66.92986298, 18.48763847],
              [-66.92930603, 18.48763847],
              [-66.92930603, 18.48791695],
              [-66.92874908, 18.48791695],
              [-66.92874908, 18.48819351],
              [-66.92819214, 18.48819351],
              [-66.92819214, 18.48847389],
              [-66.92736053, 18.48847389],
              [-66.92736053, 18.48819351],
              [-66.92708588, 18.48819351],
              [-66.92708588, 18.48791695],
              [-66.92680359, 18.48791695],
              [-66.92680359, 18.48763847],
              [-66.92485809, 18.48763847],
              [-66.92485809, 18.48736382],
              [-66.92430878, 18.48736382],
              [-66.92430878, 18.48708344],
              [-66.92402649, 18.48708344],
              [-66.92402649, 18.48680496],
              [-66.92346954, 18.48680496],
              [-66.92346954, 18.4865284],
              [-66.92180634, 18.4865284],
              [-66.92180634, 18.48625183],
              [-66.92124939, 18.48625183],
              [-66.92124939, 18.48596954],
              [-66.92069244, 18.48596954],
              [-66.92069244, 18.48569489],
              [-66.92041779, 18.48569489],
              [-66.92041779, 18.48541641],
              [-66.91958618, 18.48541641],
              [-66.91958618, 18.48513794],
              [-66.91847229, 18.48513794],
              [-66.91847229, 18.48485947],
              [-66.91652679, 18.48485947],
              [-66.91652679, 18.4845829],
              [-66.91596985, 18.4845829],
              [-66.91596985, 18.48430634],
              [-66.91513824, 18.48430634],
              [-66.91513824, 18.4845829],
              [-66.91402435, 18.4845829],
              [-66.91402435, 18.48485947],
              [-66.91374969, 18.48485947],
              [-66.91374969, 18.4845829],
              [-66.91347504, 18.4845829],
              [-66.91347504, 18.48430634],
              [-66.91236115, 18.48430634],
              [-66.91236115, 18.48402977],
              [-66.9118042, 18.48402977],
              [-66.9118042, 18.48374939],
              [-66.9098587, 18.48374939],
              [-66.9098587, 18.48402977],
              [-66.90875244, 18.48402977],
              [-66.90875244, 18.48374939],
              [-66.90791321, 18.48374939],
              [-66.90791321, 18.48347282],
              [-66.90597534, 18.48347282],
              [-66.90597534, 18.48374939],
              [-66.90569305, 18.48374939],
              [-66.90569305, 18.48402977],
              [-66.90486145, 18.48402977],
              [-66.90486145, 18.48430634],
              [-66.90374756, 18.48430634],
              [-66.90374756, 18.48374939],
              [-66.90319824, 18.48374939],
              [-66.90319824, 18.48347282],
              [-66.90291595, 18.48347282],
              [-66.90291595, 18.48374939],
              [-66.90164185, 18.48374939],
              [-66.90164948, 18.4835186],
              [-66.90150452, 18.46780205],
              [-66.90142822, 18.45922852],
              [-66.90083313, 18.44589806],
              [-66.90119171, 18.43493652],
              [-66.90150452, 18.41778374],
              [-66.90047455, 18.40683556],
              [-66.90045166, 18.40492821],
              [-66.89898682, 18.39636612],
              [-66.90119934, 18.38538551],
              [-66.90070343, 18.38205719],
              [-66.89871216, 18.36635017],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 59,
        name: 'Quebradillas',
        hasc_1: 'PR.QB',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.96668243, 18.36629295, -66.89871216, 18.49180412],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.66',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.05132294, 18.11038017],
              [-66.05271149, 18.11371994],
              [-66.04255676, 18.11420059],
              [-66.04025269, 18.11611176],
              [-66.03839874, 18.11754417],
              [-66.03517151, 18.11945343],
              [-66.0328598, 18.12517929],
              [-66.03240204, 18.12708855],
              [-66.0328598, 18.12947273],
              [-66.02639771, 18.13376808],
              [-66.02409363, 18.13567543],
              [-66.02409363, 18.13663101],
              [-66.020401, 18.14378738],
              [-66.020401, 18.1452179],
              [-66.02317047, 18.15332603],
              [-66.02224731, 18.16334343],
              [-66.02224731, 18.16525269],
              [-66.020401, 18.16858673],
              [-66.01347351, 18.18146515],
              [-66.01300812, 18.18242073],
              [-66.00423431, 18.18576241],
              [-66.00376892, 18.1857605],
              [-66.00284576, 18.18671417],
              [-66.00099945, 18.18909836],
              [-65.99915314, 18.19100761],
              [-65.99822235, 18.19243813],
              [-65.9996109, 18.19625282],
              [-66.00006866, 18.19768143],
              [-65.99776459, 18.20722008],
              [-65.99267578, 18.21055984],
              [-65.99175262, 18.21199036],
              [-65.99036407, 18.21818733],
              [-65.98990631, 18.21961975],
              [-65.98851776, 18.22009659],
              [-65.98620605, 18.22057343],
              [-65.9848175, 18.22009277],
              [-65.9825058, 18.22057152],
              [-65.98158264, 18.22104836],
              [-65.97141266, 18.22915459],
              [-65.96170044, 18.2296257],
              [-65.95106506, 18.22914314],
              [-65.95199585, 18.21579361],
              [-65.94784546, 18.21006966],
              [-65.94691467, 18.20911407],
              [-65.93860626, 18.20100212],
              [-65.93721771, 18.1995697],
              [-65.93582916, 18.19813728],
              [-65.92935944, 18.19717979],
              [-65.92797852, 18.18907166],
              [-65.92752838, 18.17715073],
              [-65.93122864, 18.16809082],
              [-65.93215942, 18.16141129],
              [-65.92985535, 18.15568542],
              [-65.92847443, 18.14281082],
              [-65.92617035, 18.13898849],
              [-65.92524719, 18.13469315],
              [-65.92524719, 18.13422012],
              [-65.92571259, 18.13135338],
              [-65.92756653, 18.12181664],
              [-65.92618561, 18.11991119],
              [-65.92572784, 18.11179543],
              [-65.92665863, 18.1089344],
              [-65.92804718, 18.1036911],
              [-65.93081665, 18.09701347],
              [-65.93359375, 18.09367561],
              [-65.93636322, 18.09176826],
              [-65.94190216, 18.08938789],
              [-65.94282532, 18.08938789],
              [-65.94421387, 18.08986282],
              [-65.94651794, 18.09129524],
              [-65.95021057, 18.09272766],
              [-65.95252228, 18.09273148],
              [-65.95574951, 18.0917778],
              [-65.95759583, 18.09034729],
              [-65.95806122, 18.08939171],
              [-65.96221924, 18.08653069],
              [-65.96406555, 18.08701134],
              [-65.96452332, 18.08701134],
              [-65.97006226, 18.08987617],
              [-65.97744751, 18.09512711],
              [-65.98484039, 18.08844757],
              [-65.9936142, 18.0898819],
              [-65.9996109, 18.08224487],
              [-66.00514984, 18.07604218],
              [-66.00699615, 18.07556534],
              [-66.01023102, 18.07556534],
              [-66.01807404, 18.08176613],
              [-66.01946259, 18.08367348],
              [-66.01899719, 18.08510971],
              [-66.01392365, 18.09274292],
              [-66.0125351, 18.0936985],
              [-66.0125351, 18.09655762],
              [-66.01946259, 18.10323524],
              [-66.02223206, 18.10275841],
              [-66.02731323, 18.10180473],
              [-66.03100586, 18.10227966],
              [-66.03331757, 18.10227776],
              [-66.03746796, 18.10323143],
              [-66.04254913, 18.10418129],
              [-66.04439545, 18.10513687],
              [-66.04855347, 18.10751915],
              [-66.05086517, 18.10942841],
              [-66.05132294, 18.11038017],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 66,
        name: 'San Lorenzo',
        hasc_1: 'PR.SL',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.05271149, 18.07556534, -65.92524719, 18.2296257],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.61',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-67.22597504, 18.29644775],
              [-67.2270813, 18.29652977],
              [-67.2270813, 18.29680634],
              [-67.22763824, 18.29680634],
              [-67.22763824, 18.2970829],
              [-67.22819519, 18.2970829],
              [-67.22819519, 18.29735947],
              [-67.22875214, 18.29735947],
              [-67.22875214, 18.29763985],
              [-67.23014069, 18.29763985],
              [-67.23014069, 18.29791641],
              [-67.23236084, 18.29791641],
              [-67.23236084, 18.29819489],
              [-67.23291779, 18.29819489],
              [-67.23291779, 18.29846954],
              [-67.23374939, 18.29846954],
              [-67.23374939, 18.29875183],
              [-67.23430634, 18.29875183],
              [-67.23430634, 18.2990284],
              [-67.23458099, 18.2990284],
              [-67.23458099, 18.29930496],
              [-67.23513794, 18.29930496],
              [-67.23513794, 18.29958344],
              [-67.23542023, 18.29958344],
              [-67.23542023, 18.29986382],
              [-67.23569489, 18.29986382],
              [-67.23569489, 18.30013847],
              [-67.23596954, 18.30013847],
              [-67.23596954, 18.30041695],
              [-67.23625183, 18.30041695],
              [-67.23625183, 18.30097389],
              [-67.23652649, 18.30097389],
              [-67.23652649, 18.30125046],
              [-67.23680878, 18.30125046],
              [-67.23680878, 18.3018055],
              [-67.23708344, 18.3018055],
              [-67.23708344, 18.30208206],
              [-67.23735809, 18.30208206],
              [-67.23735809, 18.30236053],
              [-67.23764038, 18.30236053],
              [-67.23764038, 18.30291748],
              [-67.23791504, 18.30291748],
              [-67.23791504, 18.30319595],
              [-67.23819733, 18.30319595],
              [-67.23819733, 18.30374908],
              [-67.23847198, 18.30374908],
              [-67.23847198, 18.30430412],
              [-67.23874664, 18.30430412],
              [-67.23874664, 18.30486107],
              [-67.23902893, 18.30486107],
              [-67.23902893, 18.30569458],
              [-67.23930359, 18.30569458],
              [-67.23930359, 18.30624962],
              [-67.23986053, 18.30624962],
              [-67.23986053, 18.30791664],
              [-67.24013519, 18.30791664],
              [-67.24013519, 18.30874825],
              [-67.24041748, 18.30874825],
              [-67.24041748, 18.30930519],
              [-67.24069214, 18.30930519],
              [-67.24069214, 18.31014061],
              [-67.24097443, 18.31014061],
              [-67.24097443, 18.31041718],
              [-67.24124908, 18.31041718],
              [-67.24124908, 18.31097031],
              [-67.24153137, 18.31097031],
              [-67.24153137, 18.31152725],
              [-67.24208069, 18.31152725],
              [-67.24208069, 18.31236267],
              [-67.24236298, 18.31236267],
              [-67.24236298, 18.31319427],
              [-67.24263763, 18.31319427],
              [-67.24263763, 18.31347275],
              [-67.24291992, 18.31347275],
              [-67.24291992, 18.31430626],
              [-67.24346924, 18.31430626],
              [-67.24346924, 18.31458473],
              [-67.24375153, 18.31458473],
              [-67.24375153, 18.31513977],
              [-67.24402618, 18.31513977],
              [-67.24402618, 18.31541634],
              [-67.24430847, 18.31541634],
              [-67.24430847, 18.31624985],
              [-67.24458313, 18.31624985],
              [-67.24458313, 18.31652832],
              [-67.24514008, 18.31652832],
              [-67.24514008, 18.31708336],
              [-67.24541473, 18.31708336],
              [-67.24541473, 18.31736183],
              [-67.24569702, 18.31736183],
              [-67.24569702, 18.3176384],
              [-67.24597168, 18.3176384],
              [-67.24597168, 18.31791687],
              [-67.24624634, 18.31791687],
              [-67.24624634, 18.31819534],
              [-67.24652863, 18.31819534],
              [-67.24652863, 18.31847191],
              [-67.24680328, 18.31847191],
              [-67.24680328, 18.31902695],
              [-67.24708557, 18.31902695],
              [-67.24708557, 18.31930542],
              [-67.24736023, 18.31930542],
              [-67.24736023, 18.32013893],
              [-67.24764252, 18.32013893],
              [-67.24764252, 18.32041931],
              [-67.24791718, 18.32041931],
              [-67.24791718, 18.32069397],
              [-67.24819183, 18.32069397],
              [-67.24819183, 18.32180595],
              [-67.24847412, 18.32180595],
              [-67.24847412, 18.32235909],
              [-67.24874878, 18.32235909],
              [-67.24874878, 18.3231945],
              [-67.24903107, 18.3231945],
              [-67.24903107, 18.32430649],
              [-67.24958038, 18.32430649],
              [-67.24958038, 18.32513809],
              [-67.24986267, 18.32513809],
              [-67.24986267, 18.32541656],
              [-67.25013733, 18.32541656],
              [-67.25013733, 18.32652855],
              [-67.25041962, 18.32652855],
              [-67.25041962, 18.3279171],
              [-67.25069427, 18.3279171],
              [-67.25069427, 18.32819366],
              [-67.25125122, 18.32819366],
              [-67.25125122, 18.32875061],
              [-67.25180817, 18.32875061],
              [-67.25180817, 18.32958412],
              [-67.25208282, 18.32958412],
              [-67.25208282, 18.33041573],
              [-67.25235748, 18.33041573],
              [-67.25235748, 18.33097267],
              [-67.25263977, 18.33097267],
              [-67.25263977, 18.33124924],
              [-67.25291443, 18.33124924],
              [-67.25291443, 18.33236122],
              [-67.25319672, 18.33236122],
              [-67.25319672, 18.33263969],
              [-67.25347137, 18.33263969],
              [-67.25347137, 18.33513832],
              [-67.25375366, 18.33513832],
              [-67.25375366, 18.33541679],
              [-67.25402832, 18.33541679],
              [-67.25402832, 18.33680534],
              [-67.25430298, 18.33680534],
              [-67.25430298, 18.33735847],
              [-67.25458527, 18.33735847],
              [-67.25458527, 18.33819389],
              [-67.25485992, 18.33819389],
              [-67.25485992, 18.33875275],
              [-67.25514221, 18.33875275],
              [-67.25514221, 18.33930588],
              [-67.25541687, 18.33930588],
              [-67.25541687, 18.33986282],
              [-67.25569153, 18.33986282],
              [-67.25569153, 18.34041595],
              [-67.25597382, 18.34041595],
              [-67.25597382, 18.34097481],
              [-67.25624847, 18.34097481],
              [-67.25624847, 18.34124947],
              [-67.25653076, 18.34124947],
              [-67.25653076, 18.34152794],
              [-67.25680542, 18.34152794],
              [-67.25680542, 18.34180641],
              [-67.25708008, 18.34180641],
              [-67.25708008, 18.34208488],
              [-67.25763702, 18.34208488],
              [-67.25763702, 18.34263802],
              [-67.25791931, 18.34263802],
              [-67.25791931, 18.34319305],
              [-67.25819397, 18.34319305],
              [-67.25819397, 18.34347153],
              [-67.25875092, 18.34347153],
              [-67.25875092, 18.34375],
              [-67.25958252, 18.34375],
              [-67.25958252, 18.34347153],
              [-67.25986481, 18.34347153],
              [-67.25986481, 18.34319305],
              [-67.26013947, 18.34319305],
              [-67.26013947, 18.34347153],
              [-67.26069641, 18.34347153],
              [-67.26069641, 18.34375],
              [-67.26125336, 18.34375],
              [-67.26125336, 18.34402847],
              [-67.26152802, 18.34402847],
              [-67.26152802, 18.34430695],
              [-67.26180267, 18.34430695],
              [-67.26180267, 18.34486198],
              [-67.26208496, 18.34486198],
              [-67.26208496, 18.34541512],
              [-67.26235962, 18.34541512],
              [-67.26235962, 18.34597206],
              [-67.26264191, 18.34597206],
              [-67.26264191, 18.34625053],
              [-67.26319122, 18.34625053],
              [-67.26319122, 18.34680557],
              [-67.26347351, 18.34680557],
              [-67.26347351, 18.34708405],
              [-67.26374817, 18.34708405],
              [-67.26374817, 18.3484726],
              [-67.26403046, 18.3484726],
              [-67.26403046, 18.34902763],
              [-67.26430511, 18.34902763],
              [-67.26430511, 18.34930611],
              [-67.26457977, 18.34930611],
              [-67.26457977, 18.34958267],
              [-67.26513672, 18.34958267],
              [-67.26513672, 18.34985924],
              [-67.26569366, 18.34985924],
              [-67.26569366, 18.35014153],
              [-67.26597595, 18.35014153],
              [-67.26597595, 18.35041618],
              [-67.26625061, 18.35041618],
              [-67.26625061, 18.35097313],
              [-67.26652527, 18.35097313],
              [-67.26652527, 18.35152817],
              [-67.26680756, 18.35152817],
              [-67.26680756, 18.35180473],
              [-67.26708221, 18.35180473],
              [-67.26708221, 18.35208321],
              [-67.2673645, 18.35208321],
              [-67.2673645, 18.35236168],
              [-67.26763916, 18.35236168],
              [-67.26763916, 18.35263824],
              [-67.26791382, 18.35263824],
              [-67.26791382, 18.35347366],
              [-67.26819611, 18.35347366],
              [-67.26819611, 18.35375023],
              [-67.26847076, 18.35375023],
              [-67.26847076, 18.3540287],
              [-67.26875305, 18.3540287],
              [-67.26875305, 18.35430527],
              [-67.26902771, 18.35430527],
              [-67.26902771, 18.35458374],
              [-67.26930237, 18.35458374],
              [-67.26930237, 18.35513878],
              [-67.26958466, 18.35513878],
              [-67.26958466, 18.3568058],
              [-67.26985931, 18.3568058],
              [-67.26985931, 18.35763931],
              [-67.2701416, 18.35763931],
              [-67.2701416, 18.35791588],
              [-67.27041626, 18.35791588],
              [-67.27041626, 18.35874939],
              [-67.27069092, 18.35874939],
              [-67.27069092, 18.3595829],
              [-67.27097321, 18.3595829],
              [-67.27097321, 18.36013794],
              [-67.27124786, 18.36013794],
              [-67.27124786, 18.36069489],
              [-67.27153015, 18.36069489],
              [-67.27153015, 18.36097145],
              [-67.27180481, 18.36097145],
              [-67.27180481, 18.36347008],
              [-67.27124786, 18.36347008],
              [-67.27124786, 18.36374855],
              [-67.27097321, 18.36374855],
              [-67.27097321, 18.36402702],
              [-67.27069092, 18.36402702],
              [-67.27069092, 18.36458397],
              [-67.27041626, 18.36458397],
              [-67.27041626, 18.36652756],
              [-67.2701416, 18.36652756],
              [-67.2701416, 18.36680603],
              [-67.26930237, 18.36680603],
              [-67.26930237, 18.3670826],
              [-67.26875305, 18.3670826],
              [-67.26875305, 18.36763954],
              [-67.26847076, 18.36763954],
              [-67.26847076, 18.36791611],
              [-67.26819611, 18.36791611],
              [-67.26819611, 18.36819458],
              [-67.2673645, 18.36819458],
              [-67.2673645, 18.36847305],
              [-67.26652527, 18.36847305],
              [-67.26652527, 18.36874962],
              [-67.26430511, 18.36874962],
              [-67.26430511, 18.36902809],
              [-67.26347351, 18.36902809],
              [-67.26347351, 18.36930466],
              [-67.26319122, 18.36930466],
              [-67.26319122, 18.36958313],
              [-67.26291656, 18.36958313],
              [-67.26291656, 18.3698616],
              [-67.26264191, 18.3698616],
              [-67.26264191, 18.37041664],
              [-67.26180267, 18.37041664],
              [-67.26180267, 18.37069511],
              [-67.26097107, 18.37069511],
              [-67.26097107, 18.37097168],
              [-67.25846863, 18.37097168],
              [-67.25846863, 18.37069511],
              [-67.25819397, 18.37069511],
              [-67.25819397, 18.37097168],
              [-67.25708008, 18.37097168],
              [-67.25708008, 18.37125015],
              [-67.25680542, 18.37125015],
              [-67.25680542, 18.37152863],
              [-67.25653076, 18.37152863],
              [-67.25653076, 18.37180519],
              [-67.25458527, 18.37180519],
              [-67.25458527, 18.37208366],
              [-67.25319672, 18.37208366],
              [-67.25319672, 18.37236023],
              [-67.25208282, 18.37236023],
              [-67.25208282, 18.3726387],
              [-67.25069427, 18.3726387],
              [-67.25069427, 18.37291527],
              [-67.25013733, 18.37291527],
              [-67.25013733, 18.37319374],
              [-67.24847412, 18.37319374],
              [-67.24847412, 18.37180519],
              [-67.24819183, 18.37180519],
              [-67.24819183, 18.37125015],
              [-67.24764252, 18.37125015],
              [-67.24764252, 18.37180519],
              [-67.24736023, 18.37180519],
              [-67.24736023, 18.37208366],
              [-67.24708557, 18.37208366],
              [-67.24708557, 18.37319374],
              [-67.24680328, 18.37319374],
              [-67.24680328, 18.37347221],
              [-67.24652863, 18.37347221],
              [-67.24652863, 18.37375069],
              [-67.24569702, 18.37375069],
              [-67.24569702, 18.37402725],
              [-67.24541473, 18.37402725],
              [-67.24541473, 18.37430573],
              [-67.24514008, 18.37430573],
              [-67.24514008, 18.3745842],
              [-67.24485779, 18.3745842],
              [-67.24485779, 18.37486076],
              [-67.24458313, 18.37486076],
              [-67.24458313, 18.37513733],
              [-67.24319458, 18.37513733],
              [-67.24319458, 18.37486076],
              [-67.24291992, 18.37486076],
              [-67.24291992, 18.3745842],
              [-67.24153137, 18.3745842],
              [-67.24153137, 18.37486076],
              [-67.24124908, 18.37486076],
              [-67.24124908, 18.37513733],
              [-67.24069214, 18.37513733],
              [-67.24069214, 18.3754158],
              [-67.24013519, 18.3754158],
              [-67.24013519, 18.37569427],
              [-67.23955536, 18.37569427],
              [-67.23912811, 18.37527084],
              [-67.22737122, 18.36065483],
              [-67.22594452, 18.35733795],
              [-67.22722626, 18.34873962],
              [-67.21134186, 18.33703804],
              [-67.21040344, 18.33609772],
              [-67.20947266, 18.33563423],
              [-67.20900726, 18.33563995],
              [-67.19785309, 18.33197021],
              [-67.19552612, 18.33057213],
              [-67.1879425, 18.31589127],
              [-67.18281555, 18.31309891],
              [-67.18186951, 18.31167984],
              [-67.18279266, 18.31119347],
              [-67.19385529, 18.30771255],
              [-67.19663239, 18.30767632],
              [-67.19944, 18.3105011],
              [-67.20175934, 18.31046867],
              [-67.20864868, 18.30656815],
              [-67.22020721, 18.30593681],
              [-67.2224884, 18.30304527],
              [-67.22564697, 18.29680824],
              [-67.22597504, 18.29644775],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 61,
        name: 'Rincón',
        hasc_1: 'PR.RC',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-67.27180481, 18.29644775, -67.18186951, 18.37569427],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.62',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.88497925, 18.02500725],
              [-66.8904953, 18.02352142],
              [-66.89511108, 18.02347946],
              [-66.89881134, 18.02440071],
              [-66.9052887, 18.02672005],
              [-66.90574646, 18.0262394],
              [-66.90942383, 18.02429581],
              [-66.91079712, 18.0237999],
              [-66.9200592, 18.02704811],
              [-66.93070984, 18.03076172],
              [-66.93393707, 18.03072739],
              [-66.93622589, 18.02927589],
              [-66.93854523, 18.03020477],
              [-66.94132233, 18.03160667],
              [-66.94734192, 18.0334549],
              [-66.95334625, 18.0338707],
              [-66.95886993, 18.03237915],
              [-66.96029663, 18.03665924],
              [-66.96723175, 18.03849602],
              [-66.98249054, 18.04119682],
              [-66.98352051, 18.05216217],
              [-66.98352814, 18.05264282],
              [-66.98101044, 18.07796288],
              [-66.98388672, 18.0884285],
              [-66.98401642, 18.10178947],
              [-66.98448181, 18.10226059],
              [-66.98406982, 18.10703468],
              [-66.98316956, 18.10895348],
              [-66.98134613, 18.11135674],
              [-66.97913361, 18.12140083],
              [-66.97501373, 18.12525749],
              [-66.97134399, 18.12768555],
              [-66.97088623, 18.1281662],
              [-66.97046661, 18.13198662],
              [-66.97797394, 18.14383316],
              [-66.9705658, 18.14200211],
              [-66.96828461, 18.14489174],
              [-66.9641571, 18.14827538],
              [-66.96089935, 18.14592171],
              [-66.95812988, 18.14595032],
              [-66.95120239, 18.14649963],
              [-66.9498291, 18.14699554],
              [-66.93874359, 18.14805984],
              [-66.93782806, 18.14806747],
              [-66.93505859, 18.14857483],
              [-66.93460083, 18.14905739],
              [-66.92630005, 18.15104675],
              [-66.92630005, 18.15056992],
              [-66.92578888, 18.14580727],
              [-66.92484283, 18.14295006],
              [-66.91873169, 18.13156509],
              [-66.91683197, 18.12633324],
              [-66.91724396, 18.12108421],
              [-66.91770172, 18.12012482],
              [-66.91815948, 18.11964226],
              [-66.92411804, 18.11528969],
              [-66.9217453, 18.10863113],
              [-66.91752625, 18.10199738],
              [-66.91928864, 18.09291458],
              [-66.91928101, 18.09195709],
              [-66.91881561, 18.09100914],
              [-66.91880035, 18.09005547],
              [-66.91460419, 18.08532715],
              [-66.91413116, 18.08437729],
              [-66.91362762, 18.08008575],
              [-66.9131546, 18.07865906],
              [-66.91311646, 18.07484245],
              [-66.90981293, 18.06628609],
              [-66.90377045, 18.06205177],
              [-66.90331268, 18.06205559],
              [-66.90330505, 18.06158257],
              [-66.90093231, 18.05444336],
              [-66.89994049, 18.04681778],
              [-66.89852905, 18.04444313],
              [-66.89620209, 18.04160309],
              [-66.89526367, 18.04018021],
              [-66.89293671, 18.03781891],
              [-66.89061737, 18.03688622],
              [-66.8868866, 18.03215027],
              [-66.88497925, 18.02500725],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 62,
        name: 'Sabana Grande',
        hasc_1: 'PR.SB',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.98448181, 18.02347946, -66.88497925, 18.15104675],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.65',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.04125214, 18.31209755],
              [-66.04171753, 18.31257057],
              [-66.04264069, 18.31257057],
              [-66.04541779, 18.3116188],
              [-66.04727173, 18.30923653],
              [-66.04772949, 18.30685234],
              [-66.0472641, 18.30446815],
              [-66.04541016, 18.29922485],
              [-66.0472641, 18.29827118],
              [-66.04865265, 18.29779434],
              [-66.04911041, 18.29826927],
              [-66.0528183, 18.30303574],
              [-66.06623077, 18.3030262],
              [-66.07131958, 18.29920769],
              [-66.07317352, 18.2977829],
              [-66.07687378, 18.29634476],
              [-66.08057404, 18.29729652],
              [-66.08473969, 18.2996769],
              [-66.08520508, 18.30015564],
              [-66.08335114, 18.3049221],
              [-66.08243561, 18.30778122],
              [-66.08798981, 18.31826019],
              [-66.0884552, 18.3196907],
              [-66.08846283, 18.32350349],
              [-66.08846283, 18.32541275],
              [-66.08661652, 18.33208466],
              [-66.08708191, 18.33303833],
              [-66.09634399, 18.33779526],
              [-66.09958649, 18.34255791],
              [-66.09820557, 18.35018539],
              [-66.09635925, 18.35352516],
              [-66.09450531, 18.35495186],
              [-66.0898819, 18.35638809],
              [-66.08849335, 18.35734367],
              [-66.09267426, 18.37210846],
              [-66.09406281, 18.37592125],
              [-66.09499359, 18.3778286],
              [-66.10240173, 18.3797245],
              [-66.10610962, 18.3825798],
              [-66.10797119, 18.38877106],
              [-66.10797882, 18.39735031],
              [-66.10705566, 18.40163803],
              [-66.1065979, 18.40211105],
              [-66.09687042, 18.40021706],
              [-66.09548187, 18.40212631],
              [-66.09501648, 18.4035511],
              [-66.09827423, 18.41736794],
              [-66.09920502, 18.41927528],
              [-66.10662079, 18.42688751],
              [-66.10755157, 18.42783928],
              [-66.108078, 18.42874718],
              [-66.1048584, 18.42874718],
              [-66.1048584, 18.42930603],
              [-66.10458374, 18.42930603],
              [-66.10458374, 18.4295826],
              [-66.10430908, 18.4295826],
              [-66.10430908, 18.42985916],
              [-66.10402679, 18.42985916],
              [-66.10402679, 18.43014145],
              [-66.10346985, 18.43014145],
              [-66.10346985, 18.43041611],
              [-66.1029129, 18.43041611],
              [-66.1029129, 18.43069458],
              [-66.1020813, 18.43069458],
              [-66.1020813, 18.43097305],
              [-66.10152435, 18.43097305],
              [-66.10152435, 18.43125153],
              [-66.10097504, 18.43125153],
              [-66.10097504, 18.43152809],
              [-66.1001358, 18.43152809],
              [-66.1001358, 18.43180466],
              [-66.09958649, 18.43180466],
              [-66.09958649, 18.43208122],
              [-66.0993042, 18.43208122],
              [-66.0993042, 18.4323616],
              [-66.09902954, 18.4323616],
              [-66.09902954, 18.43263817],
              [-66.09874725, 18.43263817],
              [-66.09874725, 18.43319511],
              [-66.0984726, 18.43319511],
              [-66.0984726, 18.43347359],
              [-66.09819794, 18.43347359],
              [-66.09819794, 18.43375015],
              [-66.09791565, 18.43375015],
              [-66.09791565, 18.43402863],
              [-66.0973587, 18.43402863],
              [-66.0973587, 18.43458366],
              [-66.09680176, 18.43458366],
              [-66.09680176, 18.43486023],
              [-66.0965271, 18.43486023],
              [-66.0965271, 18.4351387],
              [-66.09486389, 18.4351387],
              [-66.09486389, 18.43541718],
              [-66.09430695, 18.43541718],
              [-66.09430695, 18.43569374],
              [-66.09347534, 18.43569374],
              [-66.09347534, 18.43597221],
              [-66.09319305, 18.43597221],
              [-66.09319305, 18.43625069],
              [-66.09236145, 18.43625069],
              [-66.09236145, 18.43652725],
              [-66.09208679, 18.43652725],
              [-66.09208679, 18.43680573],
              [-66.0918045, 18.43680573],
              [-66.0918045, 18.4370842],
              [-66.09152985, 18.4370842],
              [-66.09152985, 18.43763924],
              [-66.09124756, 18.43763924],
              [-66.09124756, 18.4379158],
              [-66.0909729, 18.4379158],
              [-66.0909729, 18.43819427],
              [-66.09069824, 18.43819427],
              [-66.09069824, 18.43847275],
              [-66.09041595, 18.43847275],
              [-66.09041595, 18.43902779],
              [-66.0901413, 18.43902779],
              [-66.0901413, 18.43930626],
              [-66.08985901, 18.43930626],
              [-66.08985901, 18.4398613],
              [-66.0881958, 18.4398613],
              [-66.0881958, 18.44013977],
              [-66.0873642, 18.44013977],
              [-66.0873642, 18.44041634],
              [-66.08430481, 18.44041634],
              [-66.08430481, 18.44069481],
              [-66.08374786, 18.44069481],
              [-66.08374786, 18.44124985],
              [-66.08347321, 18.44124985],
              [-66.08347321, 18.44180489],
              [-66.08319092, 18.44180489],
              [-66.08319092, 18.44208336],
              [-66.08291626, 18.44208336],
              [-66.08291626, 18.44458389],
              [-66.08319092, 18.44458389],
              [-66.08319092, 18.44486237],
              [-66.08374786, 18.44486237],
              [-66.08374786, 18.44514084],
              [-66.08403015, 18.44514084],
              [-66.08403015, 18.44597244],
              [-66.08430481, 18.44597244],
              [-66.08430481, 18.44625282],
              [-66.0845871, 18.44625282],
              [-66.0845871, 18.44652748],
              [-66.08486176, 18.44652748],
              [-66.08486176, 18.44708061],
              [-66.08513641, 18.44708061],
              [-66.08513641, 18.4473629],
              [-66.08625031, 18.4473629],
              [-66.08625031, 18.44763947],
              [-66.0881958, 18.44763947],
              [-66.0881958, 18.44791603],
              [-66.08847046, 18.44791603],
              [-66.08847046, 18.44763947],
              [-66.08930206, 18.44763947],
              [-66.08930206, 18.4473629],
              [-66.08985901, 18.4473629],
              [-66.08985901, 18.44708061],
              [-66.09041595, 18.44708061],
              [-66.09041595, 18.44680595],
              [-66.0909729, 18.44680595],
              [-66.0909729, 18.44625282],
              [-66.09124756, 18.44625282],
              [-66.09124756, 18.44597244],
              [-66.09152985, 18.44597244],
              [-66.09152985, 18.4454174],
              [-66.0918045, 18.4454174],
              [-66.0918045, 18.44625282],
              [-66.09208679, 18.44625282],
              [-66.09208679, 18.44652748],
              [-66.09236145, 18.44652748],
              [-66.09236145, 18.44680595],
              [-66.09263611, 18.44680595],
              [-66.09263611, 18.44708061],
              [-66.09375, 18.44708061],
              [-66.09375, 18.44680595],
              [-66.09402466, 18.44680595],
              [-66.09402466, 18.44708061],
              [-66.0945816, 18.44708061],
              [-66.0945816, 18.44791603],
              [-66.09486389, 18.44791603],
              [-66.09486389, 18.4481945],
              [-66.09513855, 18.4481945],
              [-66.09513855, 18.44874954],
              [-66.09541321, 18.44874954],
              [-66.09541321, 18.44902802],
              [-66.09597015, 18.44902802],
              [-66.09597015, 18.44930649],
              [-66.09625244, 18.44930649],
              [-66.09625244, 18.44958305],
              [-66.09680176, 18.44958305],
              [-66.09680176, 18.44986153],
              [-66.09708405, 18.44986153],
              [-66.09708405, 18.45013809],
              [-66.09819794, 18.45013809],
              [-66.09819794, 18.45041656],
              [-66.0984726, 18.45041656],
              [-66.0984726, 18.45069695],
              [-66.0993042, 18.45069695],
              [-66.0993042, 18.4509716],
              [-66.10069275, 18.4509716],
              [-66.10069275, 18.45125008],
              [-66.10124969, 18.45125008],
              [-66.10124969, 18.4509716],
              [-66.10180664, 18.4509716],
              [-66.10180664, 18.45041656],
              [-66.1020813, 18.45041656],
              [-66.1020813, 18.45013809],
              [-66.10236359, 18.45013809],
              [-66.10236359, 18.44986153],
              [-66.10319519, 18.44986153],
              [-66.10319519, 18.44958305],
              [-66.10346985, 18.44958305],
              [-66.10346985, 18.44986153],
              [-66.10402679, 18.44986153],
              [-66.10402679, 18.45013809],
              [-66.10430908, 18.45013809],
              [-66.10430908, 18.45041656],
              [-66.10402679, 18.45041656],
              [-66.10402679, 18.45125008],
              [-66.10375214, 18.45125008],
              [-66.10375214, 18.45152855],
              [-66.10346985, 18.45152855],
              [-66.10346985, 18.45263863],
              [-66.10402679, 18.45263863],
              [-66.10402679, 18.45291519],
              [-66.10430908, 18.45291519],
              [-66.10430908, 18.45319366],
              [-66.10458374, 18.45319366],
              [-66.10458374, 18.45347214],
              [-66.1048584, 18.45347214],
              [-66.1048584, 18.45375061],
              [-66.10514069, 18.45375061],
              [-66.10514069, 18.45402718],
              [-66.10569763, 18.45402718],
              [-66.10569763, 18.45458412],
              [-66.10597229, 18.45458412],
              [-66.10597229, 18.45513725],
              [-66.10624695, 18.45513725],
              [-66.10624695, 18.45597267],
              [-66.10680389, 18.45597267],
              [-66.10680389, 18.45624733],
              [-66.10708618, 18.45624733],
              [-66.10708618, 18.45680618],
              [-66.10680389, 18.45680618],
              [-66.10680389, 18.45735931],
              [-66.10708618, 18.45735931],
              [-66.10708618, 18.4576416],
              [-66.10680389, 18.4576416],
              [-66.10680389, 18.45791626],
              [-66.10652924, 18.45791626],
              [-66.10652924, 18.45819473],
              [-66.10680389, 18.45819473],
              [-66.10680389, 18.45846939],
              [-66.10652924, 18.45846939],
              [-66.10652924, 18.45930481],
              [-66.10624695, 18.45930481],
              [-66.10624695, 18.45958328],
              [-66.10597229, 18.45958328],
              [-66.10597229, 18.45986366],
              [-66.10569763, 18.45986366],
              [-66.10569763, 18.46013832],
              [-66.10514069, 18.46013832],
              [-66.10514069, 18.46041679],
              [-66.10430908, 18.46041679],
              [-66.10430908, 18.46013832],
              [-66.10375214, 18.46013832],
              [-66.10375214, 18.45986366],
              [-66.10319519, 18.45986366],
              [-66.10319519, 18.46013832],
              [-66.1029129, 18.46013832],
              [-66.1029129, 18.46041679],
              [-66.10263824, 18.46041679],
              [-66.10263824, 18.46069527],
              [-66.10152435, 18.46069527],
              [-66.10152435, 18.46041679],
              [-66.10124969, 18.46041679],
              [-66.10124969, 18.46013832],
              [-66.10097504, 18.46013832],
              [-66.10097504, 18.45986366],
              [-66.10069275, 18.45986366],
              [-66.10069275, 18.45958328],
              [-66.1001358, 18.45958328],
              [-66.1001358, 18.45930481],
              [-66.09986115, 18.45930481],
              [-66.09986115, 18.45875168],
              [-66.09958649, 18.45875168],
              [-66.09958649, 18.45846939],
              [-66.09902954, 18.45846939],
              [-66.09902954, 18.45875168],
              [-66.09874725, 18.45875168],
              [-66.09874725, 18.45930481],
              [-66.09819794, 18.45930481],
              [-66.09819794, 18.45958328],
              [-66.09541321, 18.45958328],
              [-66.09541321, 18.45930481],
              [-66.09402466, 18.45930481],
              [-66.09402466, 18.45902824],
              [-66.09236145, 18.45902824],
              [-66.09236145, 18.45875168],
              [-66.09208679, 18.45875168],
              [-66.09208679, 18.45791626],
              [-66.09236145, 18.45791626],
              [-66.09236145, 18.45708275],
              [-66.09041595, 18.45708275],
              [-66.09041595, 18.45735931],
              [-66.08958435, 18.45735931],
              [-66.08958435, 18.45791626],
              [-66.08985901, 18.45791626],
              [-66.08985901, 18.45819473],
              [-66.0901413, 18.45819473],
              [-66.0901413, 18.45846939],
              [-66.09041595, 18.45846939],
              [-66.09041595, 18.45958328],
              [-66.09069824, 18.45958328],
              [-66.09069824, 18.45986366],
              [-66.09124756, 18.45986366],
              [-66.09124756, 18.45958328],
              [-66.09208679, 18.45958328],
              [-66.09208679, 18.45986366],
              [-66.09263611, 18.45986366],
              [-66.09263611, 18.46013832],
              [-66.09319305, 18.46013832],
              [-66.09319305, 18.46041679],
              [-66.09402466, 18.46041679],
              [-66.09402466, 18.46125031],
              [-66.09430695, 18.46125031],
              [-66.09430695, 18.46152687],
              [-66.09680176, 18.46152687],
              [-66.09680176, 18.46125031],
              [-66.09764099, 18.46125031],
              [-66.09764099, 18.46097374],
              [-66.09791565, 18.46097374],
              [-66.09791565, 18.46069527],
              [-66.09819794, 18.46069527],
              [-66.09819794, 18.46041679],
              [-66.09902954, 18.46041679],
              [-66.09902954, 18.46069527],
              [-66.10041809, 18.46069527],
              [-66.10041809, 18.46097374],
              [-66.10180664, 18.46097374],
              [-66.10180664, 18.46125031],
              [-66.1029129, 18.46125031],
              [-66.1029129, 18.46152687],
              [-66.10375214, 18.46152687],
              [-66.10375214, 18.46180534],
              [-66.1048584, 18.46180534],
              [-66.1048584, 18.46208382],
              [-66.10624695, 18.46208382],
              [-66.10624695, 18.46236038],
              [-66.10652924, 18.46236038],
              [-66.10652924, 18.46263885],
              [-66.10847473, 18.46263885],
              [-66.10847473, 18.46208382],
              [-66.10874939, 18.46208382],
              [-66.10874939, 18.46069527],
              [-66.10930634, 18.46069527],
              [-66.10930634, 18.46041679],
              [-66.10958099, 18.46041679],
              [-66.10958099, 18.46013832],
              [-66.10986328, 18.46013832],
              [-66.10986328, 18.46041679],
              [-66.11013794, 18.46041679],
              [-66.11013794, 18.46125031],
              [-66.11042023, 18.46125031],
              [-66.11042023, 18.46180534],
              [-66.11069489, 18.46180534],
              [-66.11069489, 18.46236038],
              [-66.11042023, 18.46236038],
              [-66.11042023, 18.46291733],
              [-66.11069489, 18.46291733],
              [-66.11069489, 18.46347237],
              [-66.11125183, 18.46347237],
              [-66.11125183, 18.4631958],
              [-66.11180878, 18.4631958],
              [-66.11180878, 18.46291733],
              [-66.11208344, 18.46291733],
              [-66.11208344, 18.46263885],
              [-66.11264038, 18.46263885],
              [-66.11264038, 18.46236038],
              [-66.11291504, 18.46236038],
              [-66.11291504, 18.46208382],
              [-66.11319733, 18.46208382],
              [-66.11319733, 18.46152687],
              [-66.11430359, 18.46152687],
              [-66.11430359, 18.46180534],
              [-66.11458588, 18.46180534],
              [-66.11458588, 18.46208382],
              [-66.11513519, 18.46208382],
              [-66.11513519, 18.46180534],
              [-66.11541748, 18.46180534],
              [-66.11541748, 18.46152687],
              [-66.11513519, 18.46152687],
              [-66.11513519, 18.46097374],
              [-66.11486053, 18.46097374],
              [-66.11486053, 18.46041679],
              [-66.11458588, 18.46041679],
              [-66.11458588, 18.46013832],
              [-66.11486053, 18.46013832],
              [-66.11486053, 18.45986366],
              [-66.11513519, 18.45986366],
              [-66.11513519, 18.45958328],
              [-66.11541748, 18.45958328],
              [-66.11541748, 18.45930481],
              [-66.11569214, 18.45930481],
              [-66.11569214, 18.45902824],
              [-66.11597443, 18.45902824],
              [-66.11597443, 18.45875168],
              [-66.11624908, 18.45875168],
              [-66.11624908, 18.45846939],
              [-66.11653137, 18.45846939],
              [-66.11653137, 18.45875168],
              [-66.11680603, 18.45875168],
              [-66.11680603, 18.45902824],
              [-66.11708069, 18.45902824],
              [-66.11708069, 18.45986366],
              [-66.11736298, 18.45986366],
              [-66.11736298, 18.46013832],
              [-66.11763763, 18.46013832],
              [-66.11763763, 18.46041679],
              [-66.11791992, 18.46041679],
              [-66.11791992, 18.46069527],
              [-66.11819458, 18.46069527],
              [-66.11819458, 18.46125031],
              [-66.11846924, 18.46125031],
              [-66.11846924, 18.46180534],
              [-66.11875153, 18.46180534],
              [-66.11875153, 18.46208382],
              [-66.11902618, 18.46208382],
              [-66.11902618, 18.46236038],
              [-66.11875153, 18.46236038],
              [-66.11875153, 18.46263885],
              [-66.11902618, 18.46263885],
              [-66.11902618, 18.46291733],
              [-66.11930847, 18.46291733],
              [-66.11930847, 18.46347237],
              [-66.11958313, 18.46347237],
              [-66.11958313, 18.4640274],
              [-66.11985779, 18.4640274],
              [-66.11985779, 18.46541405],
              [-66.12041473, 18.46541405],
              [-66.12041473, 18.46569443],
              [-66.12097168, 18.46569443],
              [-66.12097168, 18.4659729],
              [-66.12152863, 18.4659729],
              [-66.12152863, 18.46624947],
              [-66.12180328, 18.46624947],
              [-66.12180328, 18.46652794],
              [-66.12236023, 18.46652794],
              [-66.12236023, 18.46680641],
              [-66.12291718, 18.46680641],
              [-66.12291718, 18.46708298],
              [-66.12347412, 18.46708298],
              [-66.12347412, 18.46736145],
              [-66.12403107, 18.46736145],
              [-66.12403107, 18.46763611],
              [-66.12430573, 18.46763611],
              [-66.12430573, 18.46791649],
              [-66.12458038, 18.46791649],
              [-66.12458038, 18.46819496],
              [-66.12486267, 18.46819496],
              [-66.12486267, 18.46874809],
              [-66.12513733, 18.46874809],
              [-66.12513733, 18.47013855],
              [-66.12541962, 18.47013855],
              [-66.12541962, 18.47069359],
              [-66.12652588, 18.47069359],
              [-66.12652588, 18.47097015],
              [-66.12708282, 18.47097015],
              [-66.12708282, 18.47236252],
              [-66.12680817, 18.47236252],
              [-66.12680817, 18.47263908],
              [-66.12652588, 18.47263908],
              [-66.12652588, 18.47291756],
              [-66.12596893, 18.47291756],
              [-66.12596893, 18.47319412],
              [-66.12458038, 18.47319412],
              [-66.12458038, 18.47291756],
              [-66.12430573, 18.47291756],
              [-66.12430573, 18.47263908],
              [-66.12403107, 18.47263908],
              [-66.12403107, 18.47236252],
              [-66.12374878, 18.47236252],
              [-66.12374878, 18.47208405],
              [-66.12319183, 18.47208405],
              [-66.12319183, 18.47180557],
              [-66.12236023, 18.47180557],
              [-66.12236023, 18.4715271],
              [-66.12208557, 18.4715271],
              [-66.12208557, 18.47124863],
              [-66.12124634, 18.47124863],
              [-66.12124634, 18.47097015],
              [-66.12097168, 18.47097015],
              [-66.12097168, 18.47069359],
              [-66.12041473, 18.47069359],
              [-66.12041473, 18.47013855],
              [-66.11846924, 18.47013855],
              [-66.11846924, 18.46986008],
              [-66.11791992, 18.46986008],
              [-66.11791992, 18.47013855],
              [-66.11736298, 18.47013855],
              [-66.11736298, 18.46986008],
              [-66.11624908, 18.46986008],
              [-66.11624908, 18.47013855],
              [-66.11597443, 18.47013855],
              [-66.11597443, 18.46986008],
              [-66.11541748, 18.46986008],
              [-66.11541748, 18.46958351],
              [-66.11458588, 18.46958351],
              [-66.11458588, 18.46930504],
              [-66.11347198, 18.46930504],
              [-66.11347198, 18.46903038],
              [-66.11319733, 18.46903038],
              [-66.11319733, 18.46874809],
              [-66.11069489, 18.46874809],
              [-66.11069489, 18.46847153],
              [-66.11013794, 18.46847153],
              [-66.11013794, 18.46874809],
              [-66.1076355, 18.46874809],
              [-66.1076355, 18.46903038],
              [-66.10708618, 18.46903038],
              [-66.10708618, 18.46874809],
              [-66.10652924, 18.46874809],
              [-66.10652924, 18.46847153],
              [-66.10597229, 18.46847153],
              [-66.10597229, 18.46791649],
              [-66.10569763, 18.46791649],
              [-66.10569763, 18.46763611],
              [-66.10458374, 18.46763611],
              [-66.10458374, 18.46791649],
              [-66.10375214, 18.46791649],
              [-66.10375214, 18.46819496],
              [-66.10319519, 18.46819496],
              [-66.10319519, 18.46847153],
              [-66.1029129, 18.46847153],
              [-66.1029129, 18.46874809],
              [-66.10236359, 18.46874809],
              [-66.10236359, 18.46903038],
              [-66.10152435, 18.46903038],
              [-66.10152435, 18.46819496],
              [-66.10124969, 18.46819496],
              [-66.10124969, 18.46763611],
              [-66.10097504, 18.46763611],
              [-66.10097504, 18.46736145],
              [-66.10041809, 18.46736145],
              [-66.10041809, 18.46708298],
              [-66.09986115, 18.46708298],
              [-66.09986115, 18.46680641],
              [-66.09958649, 18.46680641],
              [-66.09958649, 18.46652794],
              [-66.09902954, 18.46652794],
              [-66.09902954, 18.46624947],
              [-66.0956955, 18.46624947],
              [-66.0956955, 18.46652794],
              [-66.0945816, 18.46652794],
              [-66.0945816, 18.46680641],
              [-66.09347534, 18.46680641],
              [-66.09347534, 18.46708298],
              [-66.09319305, 18.46708298],
              [-66.09319305, 18.46680641],
              [-66.09236145, 18.46680641],
              [-66.09236145, 18.46708298],
              [-66.09208679, 18.46708298],
              [-66.09208679, 18.46680641],
              [-66.09124756, 18.46680641],
              [-66.09124756, 18.46708298],
              [-66.09069824, 18.46708298],
              [-66.09069824, 18.46736145],
              [-66.08985901, 18.46736145],
              [-66.08985901, 18.46763611],
              [-66.08958435, 18.46763611],
              [-66.08958435, 18.46791649],
              [-66.0890274, 18.46791649],
              [-66.0890274, 18.46874809],
              [-66.08875275, 18.46874809],
              [-66.08875275, 18.46819496],
              [-66.08763885, 18.46819496],
              [-66.08763885, 18.46791649],
              [-66.08680725, 18.46791649],
              [-66.08680725, 18.46763611],
              [-66.08652496, 18.46763611],
              [-66.08652496, 18.46736145],
              [-66.08625031, 18.46736145],
              [-66.08625031, 18.46708298],
              [-66.08597565, 18.46708298],
              [-66.08597565, 18.46652794],
              [-66.08708191, 18.46652794],
              [-66.08708191, 18.4659729],
              [-66.08680725, 18.4659729],
              [-66.08680725, 18.46569443],
              [-66.08652496, 18.46569443],
              [-66.08652496, 18.46486092],
              [-66.08625031, 18.46486092],
              [-66.08625031, 18.46458244],
              [-66.08597565, 18.46458244],
              [-66.08597565, 18.46430588],
              [-66.08569336, 18.46430588],
              [-66.08569336, 18.46458244],
              [-66.08430481, 18.46458244],
              [-66.08430481, 18.46430588],
              [-66.08403015, 18.46430588],
              [-66.08403015, 18.46347237],
              [-66.08374786, 18.46347237],
              [-66.08374786, 18.46236038],
              [-66.08403015, 18.46236038],
              [-66.08403015, 18.46208382],
              [-66.0845871, 18.46208382],
              [-66.0845871, 18.46180534],
              [-66.08513641, 18.46180534],
              [-66.08513641, 18.46152687],
              [-66.08569336, 18.46152687],
              [-66.08569336, 18.46125031],
              [-66.08597565, 18.46125031],
              [-66.08597565, 18.46069527],
              [-66.08625031, 18.46069527],
              [-66.08625031, 18.45986366],
              [-66.08597565, 18.45986366],
              [-66.08597565, 18.45875168],
              [-66.08569336, 18.45875168],
              [-66.08569336, 18.45846939],
              [-66.0854187, 18.45846939],
              [-66.0854187, 18.45819473],
              [-66.08513641, 18.45819473],
              [-66.08513641, 18.4576416],
              [-66.08180237, 18.4576416],
              [-66.08180237, 18.45735931],
              [-66.08152771, 18.45735931],
              [-66.08152771, 18.45708275],
              [-66.08013916, 18.45708275],
              [-66.08013916, 18.45680618],
              [-66.0798645, 18.45680618],
              [-66.0798645, 18.45652771],
              [-66.07958221, 18.45652771],
              [-66.07958221, 18.45624733],
              [-66.07930756, 18.45624733],
              [-66.07930756, 18.45597267],
              [-66.07902527, 18.45597267],
              [-66.07902527, 18.4556942],
              [-66.07847595, 18.4556942],
              [-66.07847595, 18.45513725],
              [-66.07680511, 18.45513725],
              [-66.07680511, 18.45541573],
              [-66.07653046, 18.45541573],
              [-66.07653046, 18.45597267],
              [-66.07624817, 18.45597267],
              [-66.07624817, 18.45624733],
              [-66.07653046, 18.45624733],
              [-66.07653046, 18.45735931],
              [-66.07680511, 18.45735931],
              [-66.07680511, 18.45791626],
              [-66.07736206, 18.45791626],
              [-66.07736206, 18.45819473],
              [-66.07763672, 18.45819473],
              [-66.07763672, 18.45846939],
              [-66.07791901, 18.45846939],
              [-66.07791901, 18.45875168],
              [-66.07875061, 18.45875168],
              [-66.07875061, 18.45902824],
              [-66.07958221, 18.45902824],
              [-66.07958221, 18.45958328],
              [-66.0826416, 18.45958328],
              [-66.0826416, 18.45986366],
              [-66.08319092, 18.45986366],
              [-66.08319092, 18.46069527],
              [-66.08291626, 18.46069527],
              [-66.08291626, 18.46125031],
              [-66.0826416, 18.46125031],
              [-66.0826416, 18.46152687],
              [-66.08208466, 18.46152687],
              [-66.08208466, 18.46180534],
              [-66.08069611, 18.46180534],
              [-66.08069611, 18.46152687],
              [-66.07902527, 18.46152687],
              [-66.07902527, 18.46125031],
              [-66.07875061, 18.46125031],
              [-66.07875061, 18.46097374],
              [-66.07819366, 18.46097374],
              [-66.07819366, 18.46069527],
              [-66.07736206, 18.46069527],
              [-66.07736206, 18.46041679],
              [-66.07680511, 18.46041679],
              [-66.07680511, 18.46013832],
              [-66.07541656, 18.46013832],
              [-66.07541656, 18.45986366],
              [-66.07485962, 18.45986366],
              [-66.07485962, 18.45958328],
              [-66.07458496, 18.45958328],
              [-66.07458496, 18.45930481],
              [-66.07430267, 18.45930481],
              [-66.07430267, 18.45902824],
              [-66.07402802, 18.45902824],
              [-66.07402802, 18.45846939],
              [-66.07375336, 18.45846939],
              [-66.07375336, 18.45819473],
              [-66.07319641, 18.45819473],
              [-66.07319641, 18.45791626],
              [-66.07263947, 18.45791626],
              [-66.07263947, 18.4576416],
              [-66.07236481, 18.4576416],
              [-66.07236481, 18.45735931],
              [-66.07208252, 18.45735931],
              [-66.07208252, 18.4576416],
              [-66.07125092, 18.4576416],
              [-66.07125092, 18.45735931],
              [-66.07041931, 18.45735931],
              [-66.07041931, 18.4576416],
              [-66.07013702, 18.4576416],
              [-66.07013702, 18.45735931],
              [-66.06791687, 18.45735931],
              [-66.06791687, 18.4576416],
              [-66.06680298, 18.4576416],
              [-66.06680298, 18.45791626],
              [-66.06597137, 18.45791626],
              [-66.06597137, 18.45735931],
              [-66.06458282, 18.45735931],
              [-66.06458282, 18.45708275],
              [-66.06430817, 18.45708275],
              [-66.06430817, 18.45680618],
              [-66.06375122, 18.45680618],
              [-66.06375122, 18.45652771],
              [-66.06346893, 18.45652771],
              [-66.06346893, 18.45624733],
              [-66.06319427, 18.45624733],
              [-66.06319427, 18.45597267],
              [-66.06291962, 18.45597267],
              [-66.06291962, 18.4556942],
              [-66.06263733, 18.4556942],
              [-66.06263733, 18.45541573],
              [-66.06208038, 18.45541573],
              [-66.06208038, 18.45513725],
              [-66.06069183, 18.45513725],
              [-66.06069183, 18.45458412],
              [-66.05764008, 18.45458412],
              [-66.05764008, 18.45430565],
              [-66.05735779, 18.45430565],
              [-66.05735779, 18.45458412],
              [-66.05403137, 18.45458412],
              [-66.05403137, 18.45486069],
              [-66.05347443, 18.45486069],
              [-66.05347443, 18.45458412],
              [-66.05291748, 18.45458412],
              [-66.05291748, 18.45430565],
              [-66.05152893, 18.45430565],
              [-66.05152893, 18.45402718],
              [-66.05097198, 18.45402718],
              [-66.05097198, 18.45375061],
              [-66.05014038, 18.45375061],
              [-66.05014038, 18.45319366],
              [-66.04958344, 18.45319366],
              [-66.04958344, 18.45291519],
              [-66.04846954, 18.45291519],
              [-66.04846954, 18.45263863],
              [-66.04736328, 18.45263863],
              [-66.04736328, 18.45291519],
              [-66.04569244, 18.45291519],
              [-66.04569244, 18.45319366],
              [-66.04541779, 18.45319366],
              [-66.04541779, 18.45291519],
              [-66.04486084, 18.45291519],
              [-66.04486084, 18.45319366],
              [-66.04430389, 18.45319366],
              [-66.04430389, 18.45291519],
              [-66.04402924, 18.45291519],
              [-66.04402924, 18.45319366],
              [-66.04291534, 18.45319366],
              [-66.04291534, 18.45347214],
              [-66.0423584, 18.45347214],
              [-66.0423584, 18.45375061],
              [-66.04208374, 18.45375061],
              [-66.04208374, 18.45402718],
              [-66.04152679, 18.45402718],
              [-66.04152679, 18.45430565],
              [-66.04069519, 18.45430565],
              [-66.04069519, 18.45402718],
              [-66.0404129, 18.45402718],
              [-66.0404129, 18.45430565],
              [-66.03986359, 18.45430565],
              [-66.03986359, 18.45458412],
              [-66.03902435, 18.45458412],
              [-66.03902435, 18.45430565],
              [-66.03874969, 18.45430565],
              [-66.03874969, 18.45402718],
              [-66.03847504, 18.45402718],
              [-66.03847504, 18.45347214],
              [-66.03819275, 18.45347214],
              [-66.03819275, 18.45291519],
              [-66.03791809, 18.45291519],
              [-66.03791809, 18.45236206],
              [-66.0376358, 18.45236206],
              [-66.0376358, 18.45180511],
              [-66.03736115, 18.45180511],
              [-66.03736115, 18.45125008],
              [-66.03708649, 18.45125008],
              [-66.03708649, 18.4509716],
              [-66.0368042, 18.4509716],
              [-66.0368042, 18.45069695],
              [-66.03664398, 18.45069695],
              [-66.03652954, 18.45069695],
              [-66.03652954, 18.45041656],
              [-66.03668213, 18.45028305],
              [-66.04316711, 18.44408989],
              [-66.03899384, 18.44075775],
              [-66.03620911, 18.43932724],
              [-66.03250122, 18.43647003],
              [-66.03157806, 18.43218422],
              [-66.02972412, 18.4278965],
              [-66.02880096, 18.4264698],
              [-66.02045441, 18.42027855],
              [-66.00887299, 18.41313362],
              [-66.00099945, 18.40932274],
              [-65.99312592, 18.40741539],
              [-65.99173737, 18.39979362],
              [-65.99173737, 18.39883995],
              [-65.9912796, 18.39836311],
              [-65.99220276, 18.39836311],
              [-66.002388, 18.39502716],
              [-66.00285339, 18.38549614],
              [-65.9996109, 18.38025856],
              [-65.99914551, 18.38025856],
              [-66.00099945, 18.37978172],
              [-66.00146484, 18.37978172],
              [-66.00794983, 18.37882805],
              [-66.01350403, 18.37882805],
              [-66.02507782, 18.37977982],
              [-66.03294373, 18.37262726],
              [-66.03479767, 18.36976624],
              [-66.03572083, 18.36833763],
              [-66.03340149, 18.35642624],
              [-66.03340149, 18.35451698],
              [-66.03710175, 18.34593773],
              [-66.03385925, 18.33498001],
              [-66.03617096, 18.32782936],
              [-66.03662872, 18.32353973],
              [-66.03570557, 18.32115746],
              [-66.0352478, 18.31972694],
              [-66.04125214, 18.31209755],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 65,
        name: 'San Juan',
        hasc_1: 'PR.SJ',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio|Senatorial District',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.12708282, 18.29634476, -65.9912796, 18.47319412],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.63',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.2061615, 17.96264076],
              [-66.20680237, 17.96264076],
              [-66.20680237, 17.96235847],
              [-66.20708466, 17.96235847],
              [-66.20708466, 17.96208382],
              [-66.2076416, 17.96208382],
              [-66.2076416, 17.96180534],
              [-66.20986176, 17.96180534],
              [-66.20986176, 17.96125031],
              [-66.21013641, 17.96125031],
              [-66.21013641, 17.96069527],
              [-66.2104187, 17.96069527],
              [-66.2104187, 17.9604187],
              [-66.21069336, 17.9604187],
              [-66.21069336, 17.96013641],
              [-66.21097565, 17.96013641],
              [-66.21097565, 17.95986176],
              [-66.21125031, 17.95986176],
              [-66.21125031, 17.95930481],
              [-66.21208191, 17.95930481],
              [-66.21208191, 17.95958328],
              [-66.2123642, 17.95958328],
              [-66.2123642, 17.95986176],
              [-66.21347046, 17.95986176],
              [-66.21347046, 17.96013641],
              [-66.21458435, 17.96013641],
              [-66.21458435, 17.95986176],
              [-66.21541595, 17.95986176],
              [-66.21541595, 17.95930481],
              [-66.21569824, 17.95930481],
              [-66.21569824, 17.95874977],
              [-66.21541595, 17.95874977],
              [-66.21541595, 17.9584713],
              [-66.21485901, 17.9584713],
              [-66.21485901, 17.95819473],
              [-66.21458435, 17.95819473],
              [-66.21458435, 17.95791435],
              [-66.21430206, 17.95791435],
              [-66.21430206, 17.95763969],
              [-66.2140274, 17.95763969],
              [-66.2140274, 17.95736122],
              [-66.21375275, 17.95736122],
              [-66.21375275, 17.95680428],
              [-66.21347046, 17.95680428],
              [-66.21347046, 17.95541573],
              [-66.21485901, 17.95541573],
              [-66.21485901, 17.9556942],
              [-66.2151413, 17.9556942],
              [-66.2151413, 17.95597267],
              [-66.21541595, 17.95597267],
              [-66.21541595, 17.95624924],
              [-66.21569824, 17.95624924],
              [-66.21569824, 17.95652771],
              [-66.2159729, 17.95652771],
              [-66.2159729, 17.95680428],
              [-66.21652985, 17.95680428],
              [-66.21652985, 17.95708275],
              [-66.21708679, 17.95708275],
              [-66.21708679, 17.95736122],
              [-66.2179184, 17.95736122],
              [-66.2179184, 17.95652771],
              [-66.21763611, 17.95652771],
              [-66.21763611, 17.9556942],
              [-66.2179184, 17.9556942],
              [-66.2179184, 17.95513916],
              [-66.21875, 17.95513916],
              [-66.21875, 17.95486069],
              [-66.21902466, 17.95486069],
              [-66.21902466, 17.95458221],
              [-66.21930695, 17.95458221],
              [-66.21930695, 17.95402718],
              [-66.21986389, 17.95402718],
              [-66.21986389, 17.95375061],
              [-66.22013855, 17.95375061],
              [-66.22013855, 17.95347214],
              [-66.22125244, 17.95347214],
              [-66.22125244, 17.95319366],
              [-66.2215271, 17.95319366],
              [-66.2215271, 17.95263863],
              [-66.22180176, 17.95263863],
              [-66.22180176, 17.95041656],
              [-66.22208405, 17.95041656],
              [-66.22208405, 17.95125008],
              [-66.2223587, 17.95125008],
              [-66.2223587, 17.95263863],
              [-66.22458649, 17.95263863],
              [-66.22458649, 17.95236206],
              [-66.22486115, 17.95236206],
              [-66.22486115, 17.95208359],
              [-66.2251358, 17.95208359],
              [-66.2251358, 17.95180511],
              [-66.22541809, 17.95180511],
              [-66.22541809, 17.95152855],
              [-66.22569275, 17.95152855],
              [-66.22569275, 17.95125008],
              [-66.22624969, 17.95125008],
              [-66.22624969, 17.9509716],
              [-66.22652435, 17.9509716],
              [-66.22652435, 17.95041656],
              [-66.22624969, 17.95041656],
              [-66.22624969, 17.94986153],
              [-66.22597504, 17.94986153],
              [-66.22597504, 17.94958305],
              [-66.22569275, 17.94958305],
              [-66.22569275, 17.9481945],
              [-66.22541809, 17.9481945],
              [-66.22541809, 17.94791794],
              [-66.22569275, 17.94791794],
              [-66.22569275, 17.94763947],
              [-66.22597504, 17.94763947],
              [-66.22597504, 17.94735909],
              [-66.22624969, 17.94735909],
              [-66.22624969, 17.94708252],
              [-66.22652435, 17.94708252],
              [-66.22652435, 17.94735909],
              [-66.2270813, 17.94735909],
              [-66.2270813, 17.94763947],
              [-66.22763824, 17.94763947],
              [-66.22763824, 17.94791794],
              [-66.2279129, 17.94791794],
              [-66.2279129, 17.9481945],
              [-66.22846985, 17.9481945],
              [-66.22846985, 17.94791794],
              [-66.22930908, 17.94791794],
              [-66.22930908, 17.94763947],
              [-66.23069763, 17.94763947],
              [-66.23069763, 17.94735909],
              [-66.23180389, 17.94735909],
              [-66.23180389, 17.94708252],
              [-66.23236084, 17.94708252],
              [-66.23236084, 17.94680786],
              [-66.23291779, 17.94680786],
              [-66.23291779, 17.94652939],
              [-66.23319244, 17.94652939],
              [-66.23319244, 17.94625092],
              [-66.23347473, 17.94625092],
              [-66.23347473, 17.94569397],
              [-66.23374939, 17.94569397],
              [-66.23374939, 17.94486046],
              [-66.23152924, 17.94486046],
              [-66.23152924, 17.94430542],
              [-66.23124695, 17.94430542],
              [-66.23124695, 17.94402695],
              [-66.23097229, 17.94402695],
              [-66.23097229, 17.94208336],
              [-66.23069763, 17.94208336],
              [-66.23069763, 17.94152832],
              [-66.23097229, 17.94152832],
              [-66.23097229, 17.94097137],
              [-66.23124695, 17.94097137],
              [-66.23124695, 17.94041634],
              [-66.23097229, 17.94041634],
              [-66.23097229, 17.94013977],
              [-66.23124695, 17.94013977],
              [-66.23124695, 17.93958282],
              [-66.23097229, 17.93958282],
              [-66.23097229, 17.93930626],
              [-66.23069763, 17.93930626],
              [-66.23069763, 17.93902779],
              [-66.23041534, 17.93902779],
              [-66.23041534, 17.93874931],
              [-66.2298584, 17.93874931],
              [-66.2298584, 17.93847275],
              [-66.22958374, 17.93847275],
              [-66.22958374, 17.93736267],
              [-66.2298584, 17.93736267],
              [-66.2298584, 17.93597221],
              [-66.22958374, 17.93597221],
              [-66.22958374, 17.93569374],
              [-66.22930908, 17.93569374],
              [-66.22930908, 17.93541718],
              [-66.22875214, 17.93541718],
              [-66.22875214, 17.93514061],
              [-66.22846985, 17.93514061],
              [-66.22846985, 17.93485832],
              [-66.22875214, 17.93485832],
              [-66.22875214, 17.93430519],
              [-66.22902679, 17.93430519],
              [-66.22902679, 17.93347168],
              [-66.22930908, 17.93347168],
              [-66.22930908, 17.93291855],
              [-66.22958374, 17.93291855],
              [-66.22958374, 17.93263626],
              [-66.2298584, 17.93263626],
              [-66.2298584, 17.9323616],
              [-66.23041534, 17.9323616],
              [-66.23041534, 17.93208313],
              [-66.23069763, 17.93208313],
              [-66.23069763, 17.93180466],
              [-66.23124695, 17.93180466],
              [-66.23124695, 17.93152618],
              [-66.23152924, 17.93152618],
              [-66.23152924, 17.93124962],
              [-66.23180389, 17.93124962],
              [-66.23180389, 17.93097305],
              [-66.23236084, 17.93097305],
              [-66.23236084, 17.93124962],
              [-66.2326355, 17.93124962],
              [-66.2326355, 17.93152618],
              [-66.23291779, 17.93152618],
              [-66.23291779, 17.93180466],
              [-66.23319244, 17.93180466],
              [-66.23319244, 17.9323616],
              [-66.23347473, 17.9323616],
              [-66.23347473, 17.93263626],
              [-66.23458099, 17.93263626],
              [-66.23458099, 17.93291855],
              [-66.23486328, 17.93291855],
              [-66.23486328, 17.93319511],
              [-66.23513794, 17.93319511],
              [-66.23513794, 17.93347168],
              [-66.23542023, 17.93347168],
              [-66.23542023, 17.93374825],
              [-66.23569489, 17.93374825],
              [-66.23569489, 17.93402863],
              [-66.23596954, 17.93402863],
              [-66.23596954, 17.93430519],
              [-66.23625183, 17.93430519],
              [-66.23625183, 17.93458366],
              [-66.23791504, 17.93458366],
              [-66.23791504, 17.93402863],
              [-66.23764038, 17.93402863],
              [-66.23764038, 17.93374825],
              [-66.23735809, 17.93374825],
              [-66.23735809, 17.93347168],
              [-66.23680878, 17.93347168],
              [-66.23680878, 17.93319511],
              [-66.23652649, 17.93319511],
              [-66.23652649, 17.93291855],
              [-66.23596954, 17.93291855],
              [-66.23596954, 17.93263626],
              [-66.23569489, 17.93263626],
              [-66.23569489, 17.9323616],
              [-66.23542023, 17.9323616],
              [-66.23542023, 17.93208313],
              [-66.23513794, 17.93208313],
              [-66.23513794, 17.93180466],
              [-66.23486328, 17.93180466],
              [-66.23486328, 17.93152618],
              [-66.23458099, 17.93152618],
              [-66.23458099, 17.93124962],
              [-66.23430634, 17.93124962],
              [-66.23430634, 17.92930412],
              [-66.23458099, 17.92930412],
              [-66.23458099, 17.92902756],
              [-66.23513794, 17.92902756],
              [-66.23513794, 17.92874908],
              [-66.23542023, 17.92874908],
              [-66.23542023, 17.92847252],
              [-66.23569489, 17.92847252],
              [-66.23569489, 17.92791748],
              [-66.23596954, 17.92791748],
              [-66.23596954, 17.92763901],
              [-66.23625183, 17.92763901],
              [-66.23625183, 17.92736053],
              [-66.23652649, 17.92736053],
              [-66.23652649, 17.92708206],
              [-66.23680878, 17.92708206],
              [-66.23680878, 17.9268055],
              [-66.23708344, 17.9268055],
              [-66.23708344, 17.92652702],
              [-66.23764038, 17.92652702],
              [-66.23764038, 17.92625046],
              [-66.23819733, 17.92625046],
              [-66.23819733, 17.92597389],
              [-66.23930359, 17.92597389],
              [-66.23930359, 17.92763901],
              [-66.23902893, 17.92763901],
              [-66.23902893, 17.92847252],
              [-66.24013519, 17.92847252],
              [-66.24013519, 17.92874908],
              [-66.24041748, 17.92874908],
              [-66.24041748, 17.92930412],
              [-66.24069214, 17.92930412],
              [-66.24069214, 17.9295826],
              [-66.24041748, 17.9295826],
              [-66.24041748, 17.92986107],
              [-66.23930359, 17.92986107],
              [-66.23930359, 17.9295826],
              [-66.23902893, 17.9295826],
              [-66.23902893, 17.92930412],
              [-66.23847198, 17.92930412],
              [-66.23847198, 17.92986107],
              [-66.23819733, 17.92986107],
              [-66.23819733, 17.93152618],
              [-66.23791504, 17.93152618],
              [-66.23791504, 17.93208313],
              [-66.23764038, 17.93208313],
              [-66.23764038, 17.9323616],
              [-66.23791504, 17.9323616],
              [-66.23791504, 17.93263626],
              [-66.23819733, 17.93263626],
              [-66.23819733, 17.93291855],
              [-66.23874664, 17.93291855],
              [-66.23874664, 17.93319511],
              [-66.23958588, 17.93319511],
              [-66.23958588, 17.93374825],
              [-66.24013519, 17.93374825],
              [-66.24013519, 17.93541718],
              [-66.24041748, 17.93541718],
              [-66.24041748, 17.93569374],
              [-66.24069214, 17.93569374],
              [-66.24069214, 17.9370842],
              [-66.24041748, 17.9370842],
              [-66.24041748, 17.93736267],
              [-66.24013519, 17.93736267],
              [-66.24013519, 17.93763924],
              [-66.23930359, 17.93763924],
              [-66.23930359, 17.9379158],
              [-66.23958588, 17.9379158],
              [-66.23958588, 17.93819427],
              [-66.24124908, 17.93819427],
              [-66.24124908, 17.9379158],
              [-66.24153137, 17.9379158],
              [-66.24153137, 17.93819427],
              [-66.24236298, 17.93819427],
              [-66.24236298, 17.93847275],
              [-66.24319458, 17.93847275],
              [-66.24319458, 17.93874931],
              [-66.24346924, 17.93874931],
              [-66.24346924, 17.93902779],
              [-66.24375153, 17.93902779],
              [-66.24375153, 17.93958282],
              [-66.24458313, 17.93958282],
              [-66.24458313, 17.93902779],
              [-66.24514008, 17.93902779],
              [-66.24514008, 17.93874931],
              [-66.24541473, 17.93874931],
              [-66.24541473, 17.93847275],
              [-66.24597168, 17.93847275],
              [-66.24597168, 17.93874931],
              [-66.24652863, 17.93874931],
              [-66.24652863, 17.93847275],
              [-66.24708557, 17.93847275],
              [-66.24708557, 17.93874931],
              [-66.24791718, 17.93874931],
              [-66.24791718, 17.93763924],
              [-66.24819183, 17.93763924],
              [-66.24819183, 17.93736267],
              [-66.24680328, 17.93736267],
              [-66.24680328, 17.9370842],
              [-66.24652863, 17.9370842],
              [-66.24652863, 17.93680573],
              [-66.24485779, 17.93680573],
              [-66.24485779, 17.93625069],
              [-66.24458313, 17.93625069],
              [-66.24458313, 17.93597221],
              [-66.24402618, 17.93597221],
              [-66.24402618, 17.93514061],
              [-66.24430847, 17.93514061],
              [-66.24430847, 17.93485832],
              [-66.24514008, 17.93485832],
              [-66.24514008, 17.93458366],
              [-66.24569702, 17.93458366],
              [-66.24569702, 17.93430519],
              [-66.24597168, 17.93430519],
              [-66.24597168, 17.93347168],
              [-66.24541473, 17.93347168],
              [-66.24541473, 17.93319511],
              [-66.24514008, 17.93319511],
              [-66.24514008, 17.93291855],
              [-66.24458313, 17.93291855],
              [-66.24458313, 17.93319511],
              [-66.24402618, 17.93319511],
              [-66.24402618, 17.93291855],
              [-66.24346924, 17.93291855],
              [-66.24346924, 17.93263626],
              [-66.24319458, 17.93263626],
              [-66.24319458, 17.9323616],
              [-66.24291992, 17.9323616],
              [-66.24291992, 17.93124962],
              [-66.24263763, 17.93124962],
              [-66.24263763, 17.93097305],
              [-66.24291992, 17.93097305],
              [-66.24291992, 17.9304142],
              [-66.24402618, 17.9304142],
              [-66.24402618, 17.93069458],
              [-66.24430847, 17.93069458],
              [-66.24430847, 17.93097305],
              [-66.24402618, 17.93097305],
              [-66.24402618, 17.93152618],
              [-66.24430847, 17.93152618],
              [-66.24430847, 17.93180466],
              [-66.24485779, 17.93180466],
              [-66.24485779, 17.93124962],
              [-66.24514008, 17.93124962],
              [-66.24514008, 17.93097305],
              [-66.24541473, 17.93097305],
              [-66.24541473, 17.93069458],
              [-66.24624634, 17.93069458],
              [-66.24624634, 17.9304142],
              [-66.24652863, 17.9304142],
              [-66.24652863, 17.93013954],
              [-66.24708557, 17.93013954],
              [-66.24708557, 17.9304142],
              [-66.24736023, 17.9304142],
              [-66.24736023, 17.93069458],
              [-66.24791718, 17.93069458],
              [-66.24791718, 17.93097305],
              [-66.24847412, 17.93097305],
              [-66.24847412, 17.93124962],
              [-66.24903107, 17.93124962],
              [-66.24903107, 17.93152618],
              [-66.24930573, 17.93152618],
              [-66.24930573, 17.93208313],
              [-66.24986267, 17.93208313],
              [-66.24986267, 17.9323616],
              [-66.25041962, 17.9323616],
              [-66.25041962, 17.93263626],
              [-66.25096893, 17.93263626],
              [-66.25096893, 17.9323616],
              [-66.25125122, 17.9323616],
              [-66.25125122, 17.93208313],
              [-66.25152588, 17.93208313],
              [-66.25152588, 17.9323616],
              [-66.25263977, 17.9323616],
              [-66.25263977, 17.93263626],
              [-66.25375366, 17.93263626],
              [-66.25375366, 17.93291855],
              [-66.25402832, 17.93291855],
              [-66.25402832, 17.93402863],
              [-66.25375366, 17.93402863],
              [-66.25375366, 17.93458366],
              [-66.25402832, 17.93458366],
              [-66.25402832, 17.93485832],
              [-66.25430298, 17.93485832],
              [-66.25430298, 17.93458366],
              [-66.25624847, 17.93458366],
              [-66.25624847, 17.93485832],
              [-66.25653076, 17.93485832],
              [-66.25653076, 17.93514061],
              [-66.25680542, 17.93514061],
              [-66.25680542, 17.93541718],
              [-66.25708008, 17.93541718],
              [-66.25708008, 17.93569374],
              [-66.25763702, 17.93569374],
              [-66.25763702, 17.93541718],
              [-66.25875092, 17.93541718],
              [-66.25875092, 17.93569374],
              [-66.26097107, 17.93569374],
              [-66.26097107, 17.93625069],
              [-66.26125336, 17.93625069],
              [-66.26125336, 17.93652725],
              [-66.26152802, 17.93652725],
              [-66.26152802, 17.93680573],
              [-66.26180267, 17.93680573],
              [-66.26180267, 17.9370842],
              [-66.26235962, 17.9370842],
              [-66.26235962, 17.93736267],
              [-66.26291656, 17.93736267],
              [-66.26291656, 17.93763924],
              [-66.26319122, 17.93763924],
              [-66.26319122, 17.93819427],
              [-66.26374817, 17.93819427],
              [-66.26374817, 17.93847275],
              [-66.26403046, 17.93847275],
              [-66.26403046, 17.93902779],
              [-66.26430511, 17.93902779],
              [-66.26430511, 17.93958282],
              [-66.26457977, 17.93958282],
              [-66.26457977, 17.94069481],
              [-66.26486206, 17.94069481],
              [-66.26486206, 17.94097137],
              [-66.26430511, 17.94097137],
              [-66.26430511, 17.94069481],
              [-66.26374817, 17.94069481],
              [-66.26374817, 17.94041634],
              [-66.26347351, 17.94041634],
              [-66.26347351, 17.94013977],
              [-66.26319122, 17.94013977],
              [-66.26319122, 17.9398613],
              [-66.26235962, 17.9398613],
              [-66.26235962, 17.94013977],
              [-66.26180267, 17.94013977],
              [-66.26180267, 17.94069481],
              [-66.26208496, 17.94069481],
              [-66.26208496, 17.94097137],
              [-66.26235962, 17.94097137],
              [-66.26235962, 17.94208336],
              [-66.26264191, 17.94208336],
              [-66.26264191, 17.94236183],
              [-66.26319122, 17.94236183],
              [-66.26319122, 17.9426384],
              [-66.26347351, 17.9426384],
              [-66.26347351, 17.94291687],
              [-66.26374817, 17.94291687],
              [-66.26374817, 17.94319534],
              [-66.26403046, 17.94319534],
              [-66.26403046, 17.94347191],
              [-66.26430511, 17.94347191],
              [-66.26430511, 17.94375038],
              [-66.26486206, 17.94375038],
              [-66.26486206, 17.94402695],
              [-66.26569366, 17.94402695],
              [-66.26569366, 17.94430542],
              [-66.26597595, 17.94430542],
              [-66.26597595, 17.94458389],
              [-66.26625061, 17.94458389],
              [-66.26625061, 17.94486046],
              [-66.26652527, 17.94486046],
              [-66.26652527, 17.94513893],
              [-66.26763916, 17.94513893],
              [-66.26763916, 17.9454155],
              [-66.26819611, 17.9454155],
              [-66.26819611, 17.94569397],
              [-66.26847076, 17.94569397],
              [-66.26847076, 17.94597244],
              [-66.26930237, 17.94597244],
              [-66.26930237, 17.94625092],
              [-66.2701416, 17.94625092],
              [-66.2701416, 17.94652939],
              [-66.27069092, 17.94652939],
              [-66.27069092, 17.94680786],
              [-66.27124786, 17.94680786],
              [-66.27124786, 17.94708252],
              [-66.27319336, 17.94708252],
              [-66.27319336, 17.94680786],
              [-66.27347565, 17.94680786],
              [-66.27347565, 17.94652939],
              [-66.27430725, 17.94652939],
              [-66.27430725, 17.94680786],
              [-66.27458191, 17.94680786],
              [-66.27458191, 17.94708252],
              [-66.2748642, 17.94708252],
              [-66.2748642, 17.94791794],
              [-66.27513885, 17.94791794],
              [-66.27513885, 17.94847298],
              [-66.27541351, 17.94847298],
              [-66.27541351, 17.94874763],
              [-66.27625275, 17.94874763],
              [-66.27625275, 17.94847298],
              [-66.27680206, 17.94847298],
              [-66.27680206, 17.9481945],
              [-66.27708435, 17.9481945],
              [-66.27708435, 17.94791794],
              [-66.27735901, 17.94791794],
              [-66.27735901, 17.94735909],
              [-66.2776413, 17.94735909],
              [-66.2776413, 17.94680786],
              [-66.2784729, 17.94680786],
              [-66.2784729, 17.94708252],
              [-66.27986145, 17.94708252],
              [-66.27986145, 17.94680786],
              [-66.28013611, 17.94680786],
              [-66.28013611, 17.94652939],
              [-66.2804184, 17.94652939],
              [-66.2804184, 17.94625092],
              [-66.28069305, 17.94625092],
              [-66.28069305, 17.94569397],
              [-66.28152466, 17.94569397],
              [-66.28152466, 17.9454155],
              [-66.2820816, 17.9454155],
              [-66.2820816, 17.94569397],
              [-66.28291321, 17.94569397],
              [-66.28291321, 17.9454155],
              [-66.28430176, 17.9454155],
              [-66.28430176, 17.94513893],
              [-66.28458405, 17.94513893],
              [-66.28458405, 17.94486046],
              [-66.2848587, 17.94486046],
              [-66.2848587, 17.94430542],
              [-66.28514099, 17.94430542],
              [-66.28514099, 17.94402695],
              [-66.28569794, 17.94402695],
              [-66.28569794, 17.94375038],
              [-66.28624725, 17.94375038],
              [-66.28624725, 17.94319534],
              [-66.28652954, 17.94319534],
              [-66.28652954, 17.94291687],
              [-66.28736115, 17.94291687],
              [-66.28736115, 17.9426384],
              [-66.28791809, 17.9426384],
              [-66.28791809, 17.94236183],
              [-66.28819275, 17.94236183],
              [-66.28819275, 17.94208336],
              [-66.28847504, 17.94208336],
              [-66.28847504, 17.94180489],
              [-66.28902435, 17.94180489],
              [-66.28902435, 17.94208336],
              [-66.28930664, 17.94208336],
              [-66.28930664, 17.9426384],
              [-66.2895813, 17.9426384],
              [-66.2895813, 17.94291687],
              [-66.28986359, 17.94291687],
              [-66.28986359, 17.94402695],
              [-66.2904129, 17.94402695],
              [-66.2904129, 17.94458389],
              [-66.29069519, 17.94458389],
              [-66.29069519, 17.9454155],
              [-66.29096985, 17.9454155],
              [-66.29096985, 17.94569397],
              [-66.29125214, 17.94569397],
              [-66.29125214, 17.94680786],
              [-66.29096985, 17.94680786],
              [-66.29096985, 17.94735909],
              [-66.29069519, 17.94735909],
              [-66.29069519, 17.94763947],
              [-66.2904129, 17.94763947],
              [-66.2904129, 17.9481945],
              [-66.29013824, 17.9481945],
              [-66.29013824, 17.94847298],
              [-66.28986359, 17.94847298],
              [-66.28986359, 17.94930649],
              [-66.2895813, 17.94930649],
              [-66.2895813, 17.9509716],
              [-66.28986359, 17.9509716],
              [-66.28986359, 17.95208359],
              [-66.29013824, 17.95208359],
              [-66.29013824, 17.95236206],
              [-66.2904129, 17.95236206],
              [-66.2904129, 17.9529171],
              [-66.29069519, 17.9529171],
              [-66.29069519, 17.95319366],
              [-66.29096985, 17.95319366],
              [-66.29096985, 17.95430565],
              [-66.29069519, 17.95430565],
              [-66.29069519, 17.95458221],
              [-66.29013824, 17.95458221],
              [-66.29013824, 17.95763969],
              [-66.2904129, 17.95763969],
              [-66.2904129, 17.95874977],
              [-66.29069519, 17.95874977],
              [-66.29069519, 17.95902634],
              [-66.29096985, 17.95902634],
              [-66.29096985, 17.96069527],
              [-66.29125214, 17.96069527],
              [-66.29125214, 17.96152878],
              [-66.29152679, 17.96152878],
              [-66.29152679, 17.96180534],
              [-66.29180908, 17.96180534],
              [-66.29180908, 17.96208382],
              [-66.29291534, 17.96208382],
              [-66.29291534, 17.96235847],
              [-66.29402924, 17.96235847],
              [-66.29402924, 17.96208382],
              [-66.29430389, 17.96208382],
              [-66.29430389, 17.96180534],
              [-66.29486084, 17.96180534],
              [-66.29486084, 17.96097183],
              [-66.29458618, 17.96097183],
              [-66.29458618, 17.96069527],
              [-66.29430389, 17.96069527],
              [-66.29430389, 17.96013641],
              [-66.29402924, 17.96013641],
              [-66.29402924, 17.95986176],
              [-66.29374695, 17.95986176],
              [-66.29374695, 17.95958328],
              [-66.29347229, 17.95958328],
              [-66.29347229, 17.9584713],
              [-66.29374695, 17.9584713],
              [-66.29374695, 17.95791435],
              [-66.29458618, 17.95791435],
              [-66.29458618, 17.9584713],
              [-66.29486084, 17.9584713],
              [-66.29486084, 17.95874977],
              [-66.29597473, 17.95874977],
              [-66.29597473, 17.9584713],
              [-66.29652405, 17.9584713],
              [-66.29652405, 17.95874977],
              [-66.29792023, 17.95874977],
              [-66.29792023, 17.95930481],
              [-66.29819489, 17.95930481],
              [-66.29819489, 17.95958328],
              [-66.29875183, 17.95958328],
              [-66.29875183, 17.95986176],
              [-66.29902649, 17.95986176],
              [-66.29902649, 17.9604187],
              [-66.29930878, 17.9604187],
              [-66.29930878, 17.96069527],
              [-66.29985809, 17.96069527],
              [-66.29985809, 17.96097183],
              [-66.30014038, 17.96097183],
              [-66.30014038, 17.96125031],
              [-66.30041504, 17.96125031],
              [-66.30041504, 17.96152878],
              [-66.30069733, 17.96152878],
              [-66.30069733, 17.96180534],
              [-66.30124664, 17.96180534],
              [-66.30124664, 17.96208382],
              [-66.30180359, 17.96208382],
              [-66.30180359, 17.96264076],
              [-66.30236053, 17.96264076],
              [-66.30236053, 17.96291733],
              [-66.30263519, 17.96291733],
              [-66.30263519, 17.96319389],
              [-66.30291748, 17.96319389],
              [-66.30291748, 17.96347237],
              [-66.30347443, 17.96347237],
              [-66.30347443, 17.96375084],
              [-66.30403137, 17.96375084],
              [-66.30403137, 17.9640274],
              [-66.30458069, 17.9640274],
              [-66.30458069, 17.96458244],
              [-66.30513763, 17.96458244],
              [-66.30513763, 17.96486282],
              [-66.30569458, 17.96486282],
              [-66.30569458, 17.96513939],
              [-66.30625153, 17.96513939],
              [-66.30625153, 17.96541595],
              [-66.30652618, 17.96541595],
              [-66.30652618, 17.96569443],
              [-66.30764008, 17.96569443],
              [-66.30764008, 17.9659729],
              [-66.30791473, 17.9659729],
              [-66.30791473, 17.96624947],
              [-66.30847168, 17.96624947],
              [-66.30847168, 17.96652794],
              [-66.30902863, 17.96652794],
              [-66.30902863, 17.96680641],
              [-66.30958557, 17.96680641],
              [-66.30958557, 17.96708488],
              [-66.31014252, 17.96708488],
              [-66.31014252, 17.96736145],
              [-66.31069183, 17.96736145],
              [-66.31069183, 17.96763802],
              [-66.31153107, 17.96763802],
              [-66.31153107, 17.96791649],
              [-66.31236267, 17.96791649],
              [-66.31236267, 17.96819305],
              [-66.31263733, 17.96819305],
              [-66.31263733, 17.96847153],
              [-66.31346893, 17.96847153],
              [-66.31346893, 17.96930313],
              [-66.31375122, 17.96930313],
              [-66.31375122, 17.96958351],
              [-66.31402588, 17.96958351],
              [-66.31402588, 17.96986198],
              [-66.31430817, 17.96986198],
              [-66.31430817, 17.97097206],
              [-66.31458282, 17.97097206],
              [-66.31458282, 17.97152519],
              [-66.31485748, 17.97152519],
              [-66.31485748, 17.97208595],
              [-66.31513977, 17.97208595],
              [-66.31513977, 17.97263718],
              [-66.31541443, 17.97263718],
              [-66.31541443, 17.97374725],
              [-66.31569672, 17.97374725],
              [-66.31569672, 17.97458267],
              [-66.31597137, 17.97458267],
              [-66.31597137, 17.97541618],
              [-66.31625366, 17.97541618],
              [-66.31625366, 17.97569466],
              [-66.31652832, 17.97569466],
              [-66.31652832, 17.9762516],
              [-66.31680298, 17.9762516],
              [-66.31680298, 17.97680473],
              [-66.31708527, 17.97680473],
              [-66.31708527, 17.97708321],
              [-66.31791687, 17.97708321],
              [-66.31791687, 17.97736168],
              [-66.31874847, 17.97736168],
              [-66.31874847, 17.97763824],
              [-66.31958008, 17.97763824],
              [-66.31958008, 17.97791672],
              [-66.32013702, 17.97791672],
              [-66.32013702, 17.97819519],
              [-66.32180786, 17.97819519],
              [-66.32180786, 17.97847176],
              [-66.32291412, 17.97847176],
              [-66.32291412, 17.97875023],
              [-66.32458496, 17.97875023],
              [-66.32458496, 17.97847176],
              [-66.32541656, 17.97847176],
              [-66.32541656, 17.97819519],
              [-66.32597351, 17.97819519],
              [-66.32597351, 17.97791672],
              [-66.32707977, 17.97791672],
              [-66.32707977, 17.97763824],
              [-66.32736206, 17.97763824],
              [-66.32736206, 17.97791672],
              [-66.32819366, 17.97791672],
              [-66.32819366, 17.97763824],
              [-66.32847595, 17.97763824],
              [-66.32847595, 17.97791672],
              [-66.33041382, 17.97791672],
              [-66.33041382, 17.97763824],
              [-66.33069611, 17.97763824],
              [-66.33069611, 17.97791672],
              [-66.33097076, 17.97791672],
              [-66.33097076, 17.97763824],
              [-66.33235931, 17.97763824],
              [-66.33235931, 17.97736168],
              [-66.33319092, 17.97736168],
              [-66.33319092, 17.97708321],
              [-66.33403015, 17.97708321],
              [-66.33403015, 17.97680473],
              [-66.33486176, 17.97680473],
              [-66.33486176, 17.97652817],
              [-66.33513641, 17.97652817],
              [-66.33513641, 17.97680473],
              [-66.33569336, 17.97680473],
              [-66.33569336, 17.97652817],
              [-66.33796692, 17.97652817],
              [-66.33808136, 17.97652817],
              [-66.33994293, 17.98186874],
              [-66.34133911, 17.9852066],
              [-66.34275055, 17.99378967],
              [-66.33496094, 18.00861549],
              [-66.3312912, 18.01483345],
              [-66.3188858, 18.02967644],
              [-66.31519318, 18.02968788],
              [-66.31243896, 18.03351402],
              [-66.31292725, 18.04257965],
              [-66.31293488, 18.04353523],
              [-66.30971527, 18.04784012],
              [-66.3037262, 18.05072403],
              [-66.29866791, 18.05646706],
              [-66.29774475, 18.05694771],
              [-66.28529358, 18.05937386],
              [-66.28299713, 18.06319809],
              [-66.28024292, 18.06797791],
              [-66.278862, 18.06941414],
              [-66.27147675, 18.06895828],
              [-66.26916504, 18.06896591],
              [-66.26686096, 18.06992912],
              [-66.25948334, 18.07376671],
              [-66.25810242, 18.07472229],
              [-66.25764465, 18.07615662],
              [-66.24980164, 18.07665634],
              [-66.24195099, 18.07715416],
              [-66.24011993, 18.08336258],
              [-66.23966217, 18.084795],
              [-66.2396698, 18.08765411],
              [-66.24013519, 18.08908653],
              [-66.24014282, 18.09290314],
              [-66.23506927, 18.09434891],
              [-66.22953033, 18.09531593],
              [-66.22444916, 18.09532928],
              [-66.22399139, 18.09532738],
              [-66.22029114, 18.09390831],
              [-66.21844482, 18.09247971],
              [-66.21659088, 18.0896225],
              [-66.21520233, 18.08628654],
              [-66.21565247, 18.08246803],
              [-66.21564484, 18.07960701],
              [-66.20870209, 18.07103348],
              [-66.2017746, 18.06770706],
              [-66.20084381, 18.0643692],
              [-66.20130157, 18.06341553],
              [-66.20129395, 18.05912018],
              [-66.20129395, 18.05768967],
              [-66.20220947, 18.05625534],
              [-66.20405579, 18.05529213],
              [-66.20451355, 18.05529213],
              [-66.20451355, 18.0548172],
              [-66.20172882, 18.04623222],
              [-66.19895935, 18.04719353],
              [-66.19297028, 18.04959106],
              [-66.18005371, 18.0524807],
              [-66.1782074, 18.05344009],
              [-66.17359161, 18.05344963],
              [-66.16620636, 18.05012131],
              [-66.17494202, 18.03292465],
              [-66.18692017, 18.02478981],
              [-66.19705963, 18.02047157],
              [-66.19798279, 18.01999283],
              [-66.20028687, 18.01903152],
              [-66.20535278, 18.01329613],
              [-66.20581055, 18.01138306],
              [-66.20578003, 17.9984951],
              [-66.20578003, 17.99801826],
              [-66.20574951, 17.98274422],
              [-66.20574951, 17.98226547],
              [-66.20574188, 17.97844887],
              [-66.2061615, 17.96264076],
            ],
          ],
          [
            [
              [-66.29402924, 17.95736122],
              [-66.29402924, 17.95708275],
              [-66.29374695, 17.95708275],
              [-66.29374695, 17.95458221],
              [-66.29347229, 17.95458221],
              [-66.29347229, 17.95430565],
              [-66.29319763, 17.95430565],
              [-66.29319763, 17.95402718],
              [-66.29264069, 17.95402718],
              [-66.29264069, 17.95236206],
              [-66.2923584, 17.95236206],
              [-66.2923584, 17.95180511],
              [-66.29208374, 17.95180511],
              [-66.29208374, 17.9509716],
              [-66.29180908, 17.9509716],
              [-66.29180908, 17.94986153],
              [-66.29208374, 17.94986153],
              [-66.29208374, 17.94874763],
              [-66.29264069, 17.94874763],
              [-66.29264069, 17.94847298],
              [-66.29402924, 17.94847298],
              [-66.29402924, 17.94874763],
              [-66.29486084, 17.94874763],
              [-66.29486084, 17.94902992],
              [-66.29541779, 17.94902992],
              [-66.29541779, 17.94930649],
              [-66.29569244, 17.94930649],
              [-66.29569244, 17.94958305],
              [-66.29597473, 17.94958305],
              [-66.29597473, 17.94986153],
              [-66.29569244, 17.94986153],
              [-66.29569244, 17.9509716],
              [-66.29597473, 17.9509716],
              [-66.29597473, 17.95180511],
              [-66.29624939, 17.95180511],
              [-66.29624939, 17.95402718],
              [-66.29652405, 17.95402718],
              [-66.29652405, 17.95513916],
              [-66.29680634, 17.95513916],
              [-66.29680634, 17.95541573],
              [-66.29708099, 17.95541573],
              [-66.29708099, 17.95624924],
              [-66.29541779, 17.95624924],
              [-66.29541779, 17.95680428],
              [-66.29486084, 17.95680428],
              [-66.29486084, 17.95708275],
              [-66.29458618, 17.95708275],
              [-66.29458618, 17.95736122],
              [-66.29402924, 17.95736122],
            ],
          ],
          [
            [
              [-66.26708221, 17.94402695],
              [-66.26708221, 17.94375038],
              [-66.26569366, 17.94375038],
              [-66.26569366, 17.94347191],
              [-66.26513672, 17.94347191],
              [-66.26513672, 17.94208336],
              [-66.26541901, 17.94208336],
              [-66.26541901, 17.94180489],
              [-66.26569366, 17.94180489],
              [-66.26569366, 17.94152832],
              [-66.26625061, 17.94152832],
              [-66.26625061, 17.94180489],
              [-66.2673645, 17.94180489],
              [-66.2673645, 17.94236183],
              [-66.26791382, 17.94236183],
              [-66.26791382, 17.9426384],
              [-66.26847076, 17.9426384],
              [-66.26847076, 17.94291687],
              [-66.26902771, 17.94291687],
              [-66.26902771, 17.94347191],
              [-66.26930237, 17.94347191],
              [-66.26930237, 17.94375038],
              [-66.26958466, 17.94375038],
              [-66.26958466, 17.94402695],
              [-66.26708221, 17.94402695],
            ],
          ],
          [
            [
              [-66.29819489, 17.93736267],
              [-66.29819489, 17.93680573],
              [-66.29763794, 17.93680573],
              [-66.29763794, 17.93652725],
              [-66.29708099, 17.93652725],
              [-66.29708099, 17.93625069],
              [-66.29680634, 17.93625069],
              [-66.29680634, 17.93569374],
              [-66.29652405, 17.93569374],
              [-66.29652405, 17.93514061],
              [-66.29624939, 17.93514061],
              [-66.29624939, 17.93430519],
              [-66.29652405, 17.93430519],
              [-66.29652405, 17.93402863],
              [-66.29708099, 17.93402863],
              [-66.29708099, 17.93374825],
              [-66.29958344, 17.93374825],
              [-66.29958344, 17.93402863],
              [-66.30124664, 17.93402863],
              [-66.30124664, 17.93430519],
              [-66.30152893, 17.93430519],
              [-66.30152893, 17.93541718],
              [-66.30124664, 17.93541718],
              [-66.30124664, 17.93569374],
              [-66.30069733, 17.93569374],
              [-66.30069733, 17.93597221],
              [-66.30041504, 17.93597221],
              [-66.30041504, 17.93625069],
              [-66.30014038, 17.93625069],
              [-66.30014038, 17.93652725],
              [-66.29985809, 17.93652725],
              [-66.29985809, 17.93680573],
              [-66.29930878, 17.93680573],
              [-66.29930878, 17.9370842],
              [-66.29875183, 17.9370842],
              [-66.29875183, 17.93736267],
              [-66.29819489, 17.93736267],
            ],
          ],
          [
            [
              [-66.29152679, 17.93625069],
              [-66.29152679, 17.93597221],
              [-66.28986359, 17.93597221],
              [-66.28986359, 17.93569374],
              [-66.28930664, 17.93569374],
              [-66.28930664, 17.93541718],
              [-66.28819275, 17.93541718],
              [-66.28819275, 17.93569374],
              [-66.28736115, 17.93569374],
              [-66.28736115, 17.93541718],
              [-66.2868042, 17.93541718],
              [-66.2868042, 17.93514061],
              [-66.28624725, 17.93514061],
              [-66.28624725, 17.93485832],
              [-66.2859726, 17.93485832],
              [-66.2859726, 17.93458366],
              [-66.28263855, 17.93458366],
              [-66.28263855, 17.93430519],
              [-66.28236389, 17.93430519],
              [-66.28236389, 17.93402863],
              [-66.2820816, 17.93402863],
              [-66.2820816, 17.93374825],
              [-66.28180695, 17.93374825],
              [-66.28180695, 17.93319511],
              [-66.2820816, 17.93319511],
              [-66.2820816, 17.93291855],
              [-66.28236389, 17.93291855],
              [-66.28236389, 17.93263626],
              [-66.28430176, 17.93263626],
              [-66.28430176, 17.9323616],
              [-66.2895813, 17.9323616],
              [-66.2895813, 17.93263626],
              [-66.29125214, 17.93263626],
              [-66.29125214, 17.93291855],
              [-66.29152679, 17.93291855],
              [-66.29152679, 17.93319511],
              [-66.29180908, 17.93319511],
              [-66.29180908, 17.93347168],
              [-66.2923584, 17.93347168],
              [-66.2923584, 17.93374825],
              [-66.29291534, 17.93374825],
              [-66.29291534, 17.93514061],
              [-66.29319763, 17.93514061],
              [-66.29319763, 17.93541718],
              [-66.29291534, 17.93541718],
              [-66.29291534, 17.93597221],
              [-66.2923584, 17.93597221],
              [-66.2923584, 17.93625069],
              [-66.29152679, 17.93625069],
            ],
          ],
          [
            [
              [-66.26208496, 17.92569351],
              [-66.26208496, 17.92541695],
              [-66.26152802, 17.92541695],
              [-66.26152802, 17.92513847],
              [-66.26125336, 17.92513847],
              [-66.26125336, 17.92486191],
              [-66.26097107, 17.92486191],
              [-66.26097107, 17.92458344],
              [-66.26069641, 17.92458344],
              [-66.26069641, 17.9240284],
              [-66.26041412, 17.9240284],
              [-66.26041412, 17.92375183],
              [-66.26013947, 17.92375183],
              [-66.26013947, 17.92347145],
              [-66.25958252, 17.92347145],
              [-66.25958252, 17.92319489],
              [-66.25846863, 17.92319489],
              [-66.25846863, 17.92291641],
              [-66.25819397, 17.92291641],
              [-66.25819397, 17.92263985],
              [-66.25791931, 17.92263985],
              [-66.25791931, 17.92180634],
              [-66.25819397, 17.92180634],
              [-66.25819397, 17.92152977],
              [-66.25902557, 17.92152977],
              [-66.25902557, 17.92124748],
              [-66.25986481, 17.92124748],
              [-66.25986481, 17.92152977],
              [-66.26069641, 17.92152977],
              [-66.26069641, 17.92180634],
              [-66.26125336, 17.92180634],
              [-66.26125336, 17.9220829],
              [-66.26152802, 17.9220829],
              [-66.26152802, 17.92236137],
              [-66.26208496, 17.92236137],
              [-66.26208496, 17.92263985],
              [-66.26235962, 17.92263985],
              [-66.26235962, 17.92291641],
              [-66.26319122, 17.92291641],
              [-66.26319122, 17.92319489],
              [-66.26347351, 17.92319489],
              [-66.26347351, 17.9240284],
              [-66.26374817, 17.9240284],
              [-66.26374817, 17.92541695],
              [-66.26347351, 17.92541695],
              [-66.26347351, 17.92569351],
              [-66.26208496, 17.92569351],
            ],
          ],
          [
            [
              [-66.25096893, 17.92097282],
              [-66.25096893, 17.92069435],
              [-66.25041962, 17.92069435],
              [-66.25041962, 17.92041779],
              [-66.24986267, 17.92041779],
              [-66.24986267, 17.91986084],
              [-66.24958038, 17.91986084],
              [-66.24958038, 17.91930771],
              [-66.24930573, 17.91930771],
              [-66.24930573, 17.91902542],
              [-66.24874878, 17.91902542],
              [-66.24874878, 17.91875076],
              [-66.24819183, 17.91875076],
              [-66.24819183, 17.91847229],
              [-66.24736023, 17.91847229],
              [-66.24736023, 17.91875076],
              [-66.24708557, 17.91875076],
              [-66.24708557, 17.91847229],
              [-66.24597168, 17.91847229],
              [-66.24597168, 17.91819382],
              [-66.24541473, 17.91819382],
              [-66.24541473, 17.91791534],
              [-66.24485779, 17.91791534],
              [-66.24485779, 17.91819382],
              [-66.24346924, 17.91819382],
              [-66.24346924, 17.91791534],
              [-66.24124908, 17.91791534],
              [-66.24124908, 17.91819382],
              [-66.24069214, 17.91819382],
              [-66.24069214, 17.91847229],
              [-66.23958588, 17.91847229],
              [-66.23958588, 17.91819382],
              [-66.23902893, 17.91819382],
              [-66.23902893, 17.91791534],
              [-66.23847198, 17.91791534],
              [-66.23847198, 17.91763878],
              [-66.23735809, 17.91763878],
              [-66.23735809, 17.91736031],
              [-66.23708344, 17.91736031],
              [-66.23708344, 17.91708374],
              [-66.23680878, 17.91708374],
              [-66.23680878, 17.91680527],
              [-66.23652649, 17.91680527],
              [-66.23652649, 17.9165287],
              [-66.23374939, 17.9165287],
              [-66.23374939, 17.91680527],
              [-66.23236084, 17.91680527],
              [-66.23236084, 17.91708374],
              [-66.23180389, 17.91708374],
              [-66.23180389, 17.91736031],
              [-66.23152924, 17.91736031],
              [-66.23152924, 17.91763878],
              [-66.2298584, 17.91763878],
              [-66.2298584, 17.91680527],
              [-66.22958374, 17.91680527],
              [-66.22958374, 17.9165287],
              [-66.22930908, 17.9165287],
              [-66.22930908, 17.91625023],
              [-66.22875214, 17.91625023],
              [-66.22875214, 17.91597176],
              [-66.22846985, 17.91597176],
              [-66.22846985, 17.91569328],
              [-66.22541809, 17.91569328],
              [-66.22541809, 17.91513824],
              [-66.2251358, 17.91513824],
              [-66.2251358, 17.91319466],
              [-66.22541809, 17.91319466],
              [-66.22541809, 17.91291618],
              [-66.22569275, 17.91291618],
              [-66.22569275, 17.91263962],
              [-66.22624969, 17.91263962],
              [-66.22624969, 17.91236115],
              [-66.22819519, 17.91236115],
              [-66.22819519, 17.91263962],
              [-66.2298584, 17.91263962],
              [-66.2298584, 17.91291618],
              [-66.23014069, 17.91291618],
              [-66.23014069, 17.91319466],
              [-66.23069763, 17.91319466],
              [-66.23069763, 17.91291618],
              [-66.23124695, 17.91291618],
              [-66.23124695, 17.91263962],
              [-66.23374939, 17.91263962],
              [-66.23374939, 17.91291618],
              [-66.23430634, 17.91291618],
              [-66.23430634, 17.91319466],
              [-66.23458099, 17.91319466],
              [-66.23458099, 17.91347122],
              [-66.23486328, 17.91347122],
              [-66.23486328, 17.91374969],
              [-66.23513794, 17.91374969],
              [-66.23513794, 17.91402817],
              [-66.23569489, 17.91402817],
              [-66.23569489, 17.91430473],
              [-66.23596954, 17.91430473],
              [-66.23596954, 17.91458321],
              [-66.23652649, 17.91458321],
              [-66.23652649, 17.91430473],
              [-66.23680878, 17.91430473],
              [-66.23680878, 17.91402817],
              [-66.23708344, 17.91402817],
              [-66.23708344, 17.91374969],
              [-66.23735809, 17.91374969],
              [-66.23735809, 17.91347122],
              [-66.23764038, 17.91347122],
              [-66.23764038, 17.91291618],
              [-66.23791504, 17.91291618],
              [-66.23791504, 17.91263962],
              [-66.23930359, 17.91263962],
              [-66.23930359, 17.91291618],
              [-66.24013519, 17.91291618],
              [-66.24013519, 17.91319466],
              [-66.24097443, 17.91319466],
              [-66.24097443, 17.91347122],
              [-66.24153137, 17.91347122],
              [-66.24153137, 17.91374969],
              [-66.24208069, 17.91374969],
              [-66.24208069, 17.91402817],
              [-66.24291992, 17.91402817],
              [-66.24291992, 17.91430473],
              [-66.24319458, 17.91430473],
              [-66.24319458, 17.91458321],
              [-66.24346924, 17.91458321],
              [-66.24346924, 17.91486168],
              [-66.24375153, 17.91486168],
              [-66.24375153, 17.91541672],
              [-66.24430847, 17.91541672],
              [-66.24430847, 17.91569328],
              [-66.24458313, 17.91569328],
              [-66.24458313, 17.91541672],
              [-66.24485779, 17.91541672],
              [-66.24485779, 17.91513824],
              [-66.24541473, 17.91513824],
              [-66.24541473, 17.91486168],
              [-66.24680328, 17.91486168],
              [-66.24680328, 17.91513824],
              [-66.24791718, 17.91513824],
              [-66.24791718, 17.91541672],
              [-66.24847412, 17.91541672],
              [-66.24847412, 17.91569328],
              [-66.24958038, 17.91569328],
              [-66.24958038, 17.91597176],
              [-66.24986267, 17.91597176],
              [-66.24986267, 17.9165287],
              [-66.25041962, 17.9165287],
              [-66.25041962, 17.91680527],
              [-66.25069427, 17.91680527],
              [-66.25069427, 17.91708374],
              [-66.25096893, 17.91708374],
              [-66.25096893, 17.91736031],
              [-66.25125122, 17.91736031],
              [-66.25125122, 17.91763878],
              [-66.25152588, 17.91763878],
              [-66.25152588, 17.91791534],
              [-66.25180817, 17.91791534],
              [-66.25180817, 17.91819382],
              [-66.25208282, 17.91819382],
              [-66.25208282, 17.91875076],
              [-66.25347137, 17.91875076],
              [-66.25347137, 17.91902542],
              [-66.25375366, 17.91902542],
              [-66.25375366, 17.91930771],
              [-66.25402832, 17.91930771],
              [-66.25402832, 17.91986084],
              [-66.25375366, 17.91986084],
              [-66.25375366, 17.92013741],
              [-66.25347137, 17.92013741],
              [-66.25347137, 17.92041779],
              [-66.25319672, 17.92041779],
              [-66.25319672, 17.92069435],
              [-66.25235748, 17.92069435],
              [-66.25235748, 17.92097282],
              [-66.25096893, 17.92097282],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 63,
        name: 'Salinas',
        hasc_1: 'PR.SA',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.34275055, 17.91236115, -66.16620636, 18.09532928],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.64',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-67.10910797, 18.05645943],
              [-67.10686493, 18.0622139],
              [-67.10275269, 18.06608391],
              [-67.09999847, 18.06706429],
              [-67.09908295, 18.06755829],
              [-67.09864044, 18.06947136],
              [-67.0986557, 18.07090187],
              [-67.09866333, 18.07138062],
              [-67.10146332, 18.07420921],
              [-67.10192871, 18.07468033],
              [-67.10330963, 18.07466507],
              [-67.1060791, 18.07463074],
              [-67.10614014, 18.07988167],
              [-67.10529327, 18.08656883],
              [-67.10487366, 18.08991623],
              [-67.10043335, 18.10619164],
              [-67.09072113, 18.10487366],
              [-67.0870285, 18.10444069],
              [-67.08104706, 18.10689735],
              [-67.07968903, 18.10882378],
              [-67.07878113, 18.11074066],
              [-67.07788086, 18.11313629],
              [-67.07789612, 18.11409378],
              [-67.07745361, 18.11601067],
              [-67.07931519, 18.11693764],
              [-67.09328461, 18.12774658],
              [-67.09429169, 18.13489151],
              [-67.09390259, 18.14157486],
              [-67.09391785, 18.14252472],
              [-67.08654022, 18.14452171],
              [-67.08562469, 18.1450119],
              [-67.08427429, 18.14836693],
              [-67.08614349, 18.14977455],
              [-67.0871048, 18.15357971],
              [-67.08712769, 18.15549088],
              [-67.08581543, 18.16170311],
              [-67.0844574, 18.16457939],
              [-67.08218384, 18.16794968],
              [-67.08171844, 18.16795349],
              [-67.07299805, 18.17282486],
              [-67.07206726, 18.17283821],
              [-67.06099701, 18.17391968],
              [-67.06053162, 18.17392159],
              [-67.05175018, 18.17402458],
              [-67.05082703, 18.17403603],
              [-67.04896545, 18.17214966],
              [-67.04756165, 18.17120934],
              [-67.04384613, 18.16934395],
              [-67.03505707, 18.16801262],
              [-67.02672577, 18.16667747],
              [-67.02626038, 18.1666832],
              [-67.02163696, 18.16625214],
              [-67.01281738, 18.16253662],
              [-67.0072403, 18.15925598],
              [-67.00444794, 18.15738106],
              [-67.00213623, 18.15692711],
              [-66.99890137, 18.15696716],
              [-66.99751282, 18.15650177],
              [-66.99658966, 18.15651131],
              [-66.99472809, 18.15510178],
              [-66.99378204, 18.15320587],
              [-66.99377441, 18.1522522],
              [-66.99376678, 18.15177536],
              [-66.99330139, 18.15130424],
              [-66.99282837, 18.15035248],
              [-66.98957825, 18.14848137],
              [-66.97797394, 18.14383316],
              [-66.97046661, 18.13198662],
              [-66.97088623, 18.1281662],
              [-66.97134399, 18.12768555],
              [-66.97501373, 18.12525749],
              [-66.97913361, 18.12140083],
              [-66.98134613, 18.11135674],
              [-66.98316956, 18.10895348],
              [-66.98406982, 18.10703468],
              [-66.98448181, 18.10226059],
              [-66.98401642, 18.10178947],
              [-66.98388672, 18.0884285],
              [-66.98101044, 18.07796288],
              [-66.98352814, 18.05264282],
              [-66.99229431, 18.05254745],
              [-67.00201416, 18.05530548],
              [-67.00806427, 18.06048965],
              [-67.01821136, 18.05894661],
              [-67.02700806, 18.06123161],
              [-67.04084778, 18.06059837],
              [-67.05518341, 18.06329918],
              [-67.06119537, 18.06466103],
              [-67.06997681, 18.06551361],
              [-67.07688141, 18.06352615],
              [-67.0879364, 18.06148529],
              [-67.08977509, 18.0614624],
              [-67.09622955, 18.06090927],
              [-67.10910797, 18.05645943],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 64,
        name: 'San Germán',
        hasc_1: 'PR.SG',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-67.10910797, 18.05254745, -66.97046661, 18.17403603],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.67',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-67.03772736, 18.2895813],
              [-67.0406189, 18.30051422],
              [-67.05271149, 18.30657578],
              [-67.04130554, 18.32147789],
              [-67.0371933, 18.32629204],
              [-67.03629303, 18.32916069],
              [-67.0368042, 18.3334465],
              [-67.03961945, 18.33722687],
              [-67.04290009, 18.34100342],
              [-67.04716492, 18.35001183],
              [-67.04766083, 18.35286331],
              [-67.04813385, 18.35381126],
              [-67.04260254, 18.35625839],
              [-67.04214478, 18.35721588],
              [-67.04170227, 18.35865021],
              [-67.03945923, 18.36534691],
              [-67.03901672, 18.36725616],
              [-67.03901672, 18.36773109],
              [-67.03904724, 18.3705883],
              [-67.03814697, 18.37251091],
              [-67.03814697, 18.37298775],
              [-67.03727722, 18.37776184],
              [-67.0327301, 18.38591385],
              [-67.0272522, 18.39359856],
              [-67.00595856, 18.39431],
              [-67.00224304, 18.39292145],
              [-66.99758911, 18.3910656],
              [-66.99200439, 18.38874435],
              [-66.9850235, 18.38548279],
              [-66.98455811, 18.38501167],
              [-66.97948456, 18.38649368],
              [-66.97392273, 18.38607788],
              [-66.95952606, 18.38193893],
              [-66.94881439, 18.37680817],
              [-66.94556427, 18.37541199],
              [-66.93677521, 18.37693024],
              [-66.9367981, 18.37931442],
              [-66.93727875, 18.38121414],
              [-66.93728638, 18.38169098],
              [-66.93731689, 18.38454819],
              [-66.93548584, 18.38742638],
              [-66.93503571, 18.38838768],
              [-66.93090057, 18.39176559],
              [-66.92905426, 18.39273453],
              [-66.92211914, 18.39328003],
              [-66.92344666, 18.38707161],
              [-66.92250824, 18.38565063],
              [-66.92202759, 18.38375092],
              [-66.91966248, 18.37901115],
              [-66.91407776, 18.37573051],
              [-66.90988922, 18.37386322],
              [-66.90756226, 18.37198257],
              [-66.90566254, 18.36723328],
              [-66.90473175, 18.36629295],
              [-66.9033432, 18.3663044],
              [-66.89871216, 18.36635017],
              [-66.89316559, 18.36735725],
              [-66.89801788, 18.34157181],
              [-66.90432739, 18.32292175],
              [-66.90477753, 18.32149124],
              [-66.90476227, 18.31958199],
              [-66.90969849, 18.30332756],
              [-66.91010284, 18.29664993],
              [-66.91001892, 18.28807259],
              [-66.91085815, 18.27852821],
              [-66.90888977, 18.26615143],
              [-66.90738678, 18.25329208],
              [-66.91804504, 18.25556946],
              [-66.92729187, 18.25547791],
              [-66.93749237, 18.25823784],
              [-66.93842316, 18.25870323],
              [-66.94306183, 18.2605648],
              [-66.94354248, 18.26198769],
              [-66.94549561, 18.27293777],
              [-66.945961, 18.27340698],
              [-66.94688416, 18.27292442],
              [-66.9575119, 18.27185822],
              [-66.95981598, 18.27135658],
              [-66.96165466, 18.26990891],
              [-66.96531677, 18.26605606],
              [-66.9671402, 18.26365089],
              [-66.96945953, 18.2636261],
              [-66.96992493, 18.26410103],
              [-66.97040558, 18.26600266],
              [-66.97137451, 18.27028275],
              [-66.97325897, 18.27407646],
              [-66.9783783, 18.2759304],
              [-66.97930145, 18.27592087],
              [-66.98069, 18.27590561],
              [-66.9871521, 18.27488136],
              [-66.99271393, 18.27577591],
              [-67.00569916, 18.27897453],
              [-67.00708008, 18.27895546],
              [-67.01539612, 18.2774353],
              [-67.0177002, 18.27693367],
              [-67.0279007, 18.27872658],
              [-67.02882385, 18.27871704],
              [-67.03214264, 18.28630638],
              [-67.03772736, 18.2895813],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 67,
        name: 'San Sebastián',
        hasc_1: 'PR.SS',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-67.05271149, 18.25329208, -66.89316559, 18.39431],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.68',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.39125061, 17.94513893],
              [-66.39125061, 17.94569397],
              [-66.39152527, 17.94569397],
              [-66.39152527, 17.94597244],
              [-66.39180756, 17.94597244],
              [-66.39180756, 17.94625092],
              [-66.39208221, 17.94625092],
              [-66.39208221, 17.94652939],
              [-66.39263916, 17.94652939],
              [-66.39263916, 17.94625092],
              [-66.39347076, 17.94625092],
              [-66.39347076, 17.94680786],
              [-66.39375305, 17.94680786],
              [-66.39375305, 17.94708252],
              [-66.39402771, 17.94708252],
              [-66.39402771, 17.94735909],
              [-66.39485931, 17.94735909],
              [-66.39485931, 17.94763947],
              [-66.39569092, 17.94763947],
              [-66.39569092, 17.94791794],
              [-66.39624786, 17.94791794],
              [-66.39624786, 17.9481945],
              [-66.39763641, 17.9481945],
              [-66.39763641, 17.94847298],
              [-66.3979187, 17.94847298],
              [-66.3979187, 17.94902992],
              [-66.39847565, 17.94902992],
              [-66.39847565, 17.94958305],
              [-66.39875031, 17.94958305],
              [-66.39875031, 17.94986153],
              [-66.39902496, 17.94986153],
              [-66.39902496, 17.95069504],
              [-66.39958191, 17.95069504],
              [-66.39958191, 17.9509716],
              [-66.40041351, 17.9509716],
              [-66.40041351, 17.95125008],
              [-66.4015274, 17.95125008],
              [-66.4015274, 17.95152855],
              [-66.40235901, 17.95152855],
              [-66.40235901, 17.95180511],
              [-66.4026413, 17.95180511],
              [-66.4026413, 17.95208359],
              [-66.40291595, 17.95208359],
              [-66.40291595, 17.95236206],
              [-66.40319824, 17.95236206],
              [-66.40319824, 17.95263863],
              [-66.4043045, 17.95263863],
              [-66.4043045, 17.95208359],
              [-66.40458679, 17.95208359],
              [-66.40458679, 17.95263863],
              [-66.40513611, 17.95263863],
              [-66.40513611, 17.9529171],
              [-66.40569305, 17.9529171],
              [-66.40569305, 17.95319366],
              [-66.40597534, 17.95319366],
              [-66.40597534, 17.95347214],
              [-66.40652466, 17.95347214],
              [-66.40652466, 17.95375061],
              [-66.4090271, 17.95375061],
              [-66.4090271, 17.95402718],
              [-66.40958405, 17.95402718],
              [-66.40958405, 17.95375061],
              [-66.41014099, 17.95375061],
              [-66.41014099, 17.95402718],
              [-66.41041565, 17.95402718],
              [-66.41041565, 17.95458221],
              [-66.41069794, 17.95458221],
              [-66.41069794, 17.95652771],
              [-66.41124725, 17.95652771],
              [-66.41124725, 17.95708275],
              [-66.41152954, 17.95708275],
              [-66.41152954, 17.95736122],
              [-66.41208649, 17.95736122],
              [-66.41208649, 17.95763969],
              [-66.41347504, 17.95763969],
              [-66.41347504, 17.95791435],
              [-66.41402435, 17.95791435],
              [-66.41402435, 17.95819473],
              [-66.4145813, 17.95819473],
              [-66.4145813, 17.9584713],
              [-66.41486359, 17.9584713],
              [-66.41486359, 17.95874977],
              [-66.41569519, 17.95874977],
              [-66.41569519, 17.95902634],
              [-66.41652679, 17.95902634],
              [-66.41652679, 17.95930481],
              [-66.41764069, 17.95930481],
              [-66.41764069, 17.95958328],
              [-66.41819763, 17.95958328],
              [-66.41819763, 17.95986176],
              [-66.42041779, 17.95986176],
              [-66.42041779, 17.95958328],
              [-66.42402649, 17.95958328],
              [-66.42402649, 17.95930481],
              [-66.42514038, 17.95930481],
              [-66.42514038, 17.95902634],
              [-66.42541504, 17.95902634],
              [-66.42541504, 17.95874977],
              [-66.42569733, 17.95874977],
              [-66.42569733, 17.95819473],
              [-66.42597198, 17.95819473],
              [-66.42597198, 17.9584713],
              [-66.42624664, 17.9584713],
              [-66.42624664, 17.95874977],
              [-66.42680359, 17.95874977],
              [-66.42680359, 17.95902634],
              [-66.42736053, 17.95902634],
              [-66.42736053, 17.95930481],
              [-66.42763519, 17.95930481],
              [-66.42763519, 17.95958328],
              [-66.42791748, 17.95958328],
              [-66.42791748, 17.95986176],
              [-66.42847443, 17.95986176],
              [-66.42847443, 17.96013641],
              [-66.42903137, 17.96013641],
              [-66.42903137, 17.9604187],
              [-66.42958069, 17.9604187],
              [-66.42958069, 17.96097183],
              [-66.42986298, 17.96097183],
              [-66.42986298, 17.96125031],
              [-66.43041992, 17.96125031],
              [-66.43041992, 17.96208382],
              [-66.43096924, 17.96208382],
              [-66.43096924, 17.96264076],
              [-66.43125153, 17.96264076],
              [-66.43125153, 17.96291733],
              [-66.43180847, 17.96291733],
              [-66.43180847, 17.96347237],
              [-66.43208313, 17.96347237],
              [-66.43208313, 17.96375084],
              [-66.43235779, 17.96375084],
              [-66.43235779, 17.96430588],
              [-66.43264008, 17.96430588],
              [-66.43264008, 17.96486282],
              [-66.43291473, 17.96486282],
              [-66.43291473, 17.96513939],
              [-66.43319702, 17.96513939],
              [-66.43319702, 17.96541595],
              [-66.43374634, 17.96541595],
              [-66.43374634, 17.9659729],
              [-66.43402863, 17.9659729],
              [-66.43402863, 17.96652794],
              [-66.43430328, 17.96652794],
              [-66.43430328, 17.96680641],
              [-66.43458557, 17.96680641],
              [-66.43458557, 17.96791649],
              [-66.43486023, 17.96791649],
              [-66.43486023, 17.96819305],
              [-66.43514252, 17.96819305],
              [-66.43514252, 17.96847153],
              [-66.43541718, 17.96847153],
              [-66.43541718, 17.96902847],
              [-66.43569183, 17.96902847],
              [-66.43569183, 17.96958351],
              [-66.43597412, 17.96958351],
              [-66.43597412, 17.96986198],
              [-66.43624878, 17.96986198],
              [-66.43624878, 17.97013855],
              [-66.43653107, 17.97013855],
              [-66.43653107, 17.97041512],
              [-66.43680573, 17.97041512],
              [-66.43680573, 17.97069359],
              [-66.43708038, 17.97069359],
              [-66.43708038, 17.97097206],
              [-66.43736267, 17.97097206],
              [-66.43736267, 17.97125053],
              [-66.43763733, 17.97125053],
              [-66.43763733, 17.97152519],
              [-66.43791962, 17.97152519],
              [-66.43791962, 17.97180557],
              [-66.43819427, 17.97180557],
              [-66.43819427, 17.97236061],
              [-66.43846893, 17.97236061],
              [-66.43846893, 17.97263718],
              [-66.43902588, 17.97263718],
              [-66.43902588, 17.97319412],
              [-66.43930817, 17.97319412],
              [-66.43930817, 17.9734726],
              [-66.43958282, 17.9734726],
              [-66.43958282, 17.97402954],
              [-66.43985748, 17.97402954],
              [-66.43985748, 17.97458267],
              [-66.44013977, 17.97458267],
              [-66.44013977, 17.97486115],
              [-66.44041443, 17.97486115],
              [-66.44041443, 17.97513962],
              [-66.44097137, 17.97513962],
              [-66.44097137, 17.97569466],
              [-66.44125366, 17.97569466],
              [-66.44125366, 17.9762516],
              [-66.44152832, 17.9762516],
              [-66.44152832, 17.97652817],
              [-66.44208527, 17.97652817],
              [-66.44208527, 17.97680473],
              [-66.44235992, 17.97680473],
              [-66.44235992, 17.97708321],
              [-66.44291687, 17.97708321],
              [-66.44291687, 17.97736168],
              [-66.44319153, 17.97736168],
              [-66.44319153, 17.97763824],
              [-66.44347382, 17.97763824],
              [-66.44347382, 17.97791672],
              [-66.44374847, 17.97791672],
              [-66.44374847, 17.97819519],
              [-66.44403076, 17.97819519],
              [-66.44403076, 17.97847176],
              [-66.44430542, 17.97847176],
              [-66.44430542, 17.97875023],
              [-66.44458008, 17.97875023],
              [-66.44458008, 17.9790287],
              [-66.44486237, 17.9790287],
              [-66.44486237, 17.97930527],
              [-66.44513702, 17.97930527],
              [-66.44513702, 17.97958374],
              [-66.44569397, 17.97958374],
              [-66.44569397, 17.97986031],
              [-66.44652557, 17.97986031],
              [-66.44652557, 17.98013878],
              [-66.44708252, 17.98013878],
              [-66.44708252, 17.98041725],
              [-66.44819641, 17.98041725],
              [-66.44819641, 17.98069382],
              [-66.44902802, 17.98069382],
              [-66.44902802, 17.98097229],
              [-66.44930267, 17.98097229],
              [-66.44930267, 17.98125076],
              [-66.45180511, 17.98125076],
              [-66.45180511, 17.98152733],
              [-66.45212555, 17.98152733],
              [-66.44971466, 17.98425674],
              [-66.44696808, 17.98761559],
              [-66.4377594, 17.99147224],
              [-66.43684387, 17.99243355],
              [-66.43455505, 17.99674034],
              [-66.43550873, 18.00294495],
              [-66.43460083, 18.00724411],
              [-66.43092346, 18.01012611],
              [-66.42815399, 18.00966263],
              [-66.42539978, 18.01206017],
              [-66.42127228, 18.0187645],
              [-66.42594147, 18.03115082],
              [-66.42688751, 18.03734779],
              [-66.4269104, 18.04259872],
              [-66.39504242, 18.03748894],
              [-66.38813019, 18.04038239],
              [-66.38628387, 18.03991318],
              [-66.37750244, 18.03470039],
              [-66.37657166, 18.03422737],
              [-66.37149811, 18.03281784],
              [-66.36872864, 18.03378105],
              [-66.3562851, 18.03669357],
              [-66.35164642, 18.03146362],
              [-66.34840393, 18.02670479],
              [-66.34516907, 18.02480888],
              [-66.34054565, 18.02291679],
              [-66.3359375, 18.02293205],
              [-66.33547211, 18.02198219],
              [-66.33408356, 18.02055359],
              [-66.3312912, 18.01483345],
              [-66.33496094, 18.00861549],
              [-66.34275055, 17.99378967],
              [-66.34133911, 17.9852066],
              [-66.33994293, 17.98186874],
              [-66.33808136, 17.97652817],
              [-66.3390274, 17.97652817],
              [-66.3390274, 17.9762516],
              [-66.33930206, 17.9762516],
              [-66.33930206, 17.97597313],
              [-66.33985901, 17.97597313],
              [-66.33985901, 17.97569466],
              [-66.34041595, 17.97569466],
              [-66.34041595, 17.97541618],
              [-66.34069824, 17.97541618],
              [-66.34069824, 17.97513962],
              [-66.34152985, 17.97513962],
              [-66.34152985, 17.97486115],
              [-66.34208679, 17.97486115],
              [-66.34208679, 17.97458267],
              [-66.34236145, 17.97458267],
              [-66.34236145, 17.97430611],
              [-66.34263611, 17.97430611],
              [-66.34263611, 17.97402954],
              [-66.3429184, 17.97402954],
              [-66.3429184, 17.97374725],
              [-66.34319305, 17.97374725],
              [-66.34319305, 17.97319412],
              [-66.34375, 17.97319412],
              [-66.34375, 17.97291946],
              [-66.34402466, 17.97291946],
              [-66.34402466, 17.97263718],
              [-66.34430695, 17.97263718],
              [-66.34430695, 17.97236061],
              [-66.3445816, 17.97236061],
              [-66.3445816, 17.97208595],
              [-66.34486389, 17.97208595],
              [-66.34486389, 17.97180557],
              [-66.34513855, 17.97180557],
              [-66.34513855, 17.97152519],
              [-66.34541321, 17.97152519],
              [-66.34541321, 17.97125053],
              [-66.3465271, 17.97125053],
              [-66.3465271, 17.97097206],
              [-66.34708405, 17.97097206],
              [-66.34708405, 17.97069359],
              [-66.3473587, 17.97069359],
              [-66.3473587, 17.97041512],
              [-66.34819794, 17.97041512],
              [-66.34819794, 17.97013855],
              [-66.3484726, 17.97013855],
              [-66.3484726, 17.96986198],
              [-66.3493042, 17.96986198],
              [-66.3493042, 17.96958351],
              [-66.34986115, 17.96958351],
              [-66.34986115, 17.96930313],
              [-66.3501358, 17.96930313],
              [-66.3501358, 17.96902847],
              [-66.35069275, 17.96902847],
              [-66.35069275, 17.96875],
              [-66.35124969, 17.96875],
              [-66.35124969, 17.96847153],
              [-66.35180664, 17.96847153],
              [-66.35180664, 17.96819305],
              [-66.3520813, 17.96819305],
              [-66.3520813, 17.96791649],
              [-66.35236359, 17.96791649],
              [-66.35236359, 17.96763802],
              [-66.35263824, 17.96763802],
              [-66.35263824, 17.96736145],
              [-66.35319519, 17.96736145],
              [-66.35319519, 17.96708488],
              [-66.35346985, 17.96708488],
              [-66.35346985, 17.96680641],
              [-66.35375214, 17.96680641],
              [-66.35375214, 17.9659729],
              [-66.35402679, 17.9659729],
              [-66.35402679, 17.96569443],
              [-66.35430908, 17.96569443],
              [-66.35430908, 17.96541595],
              [-66.3548584, 17.96541595],
              [-66.3548584, 17.96513939],
              [-66.35597229, 17.96513939],
              [-66.35597229, 17.96541595],
              [-66.35680389, 17.96541595],
              [-66.35680389, 17.96513939],
              [-66.35736084, 17.96513939],
              [-66.35736084, 17.96486282],
              [-66.35791779, 17.96486282],
              [-66.35791779, 17.96541595],
              [-66.35819244, 17.96541595],
              [-66.35819244, 17.96680641],
              [-66.35791779, 17.96680641],
              [-66.35791779, 17.96791649],
              [-66.35819244, 17.96791649],
              [-66.35819244, 17.96819305],
              [-66.35847473, 17.96819305],
              [-66.35847473, 17.96847153],
              [-66.35902405, 17.96847153],
              [-66.35902405, 17.96875],
              [-66.36152649, 17.96875],
              [-66.36152649, 17.96847153],
              [-66.36208344, 17.96847153],
              [-66.36208344, 17.96819305],
              [-66.36319733, 17.96819305],
              [-66.36319733, 17.96791649],
              [-66.36347198, 17.96791649],
              [-66.36347198, 17.96763802],
              [-66.36374664, 17.96763802],
              [-66.36374664, 17.96736145],
              [-66.36402893, 17.96736145],
              [-66.36402893, 17.96708488],
              [-66.36430359, 17.96708488],
              [-66.36430359, 17.96680641],
              [-66.36458588, 17.96680641],
              [-66.36458588, 17.96624947],
              [-66.36486053, 17.96624947],
              [-66.36486053, 17.96458244],
              [-66.36458588, 17.96458244],
              [-66.36458588, 17.9640274],
              [-66.36430359, 17.9640274],
              [-66.36430359, 17.96347237],
              [-66.36402893, 17.96347237],
              [-66.36402893, 17.96319389],
              [-66.36374664, 17.96319389],
              [-66.36374664, 17.96291733],
              [-66.36347198, 17.96291733],
              [-66.36347198, 17.96264076],
              [-66.36264038, 17.96264076],
              [-66.36264038, 17.96235847],
              [-66.36042023, 17.96235847],
              [-66.36042023, 17.96208382],
              [-66.35986328, 17.96208382],
              [-66.35986328, 17.96180534],
              [-66.35958099, 17.96180534],
              [-66.35958099, 17.96097183],
              [-66.35986328, 17.96097183],
              [-66.35986328, 17.9604187],
              [-66.36013794, 17.9604187],
              [-66.36013794, 17.95986176],
              [-66.36042023, 17.95986176],
              [-66.36042023, 17.95930481],
              [-66.36069489, 17.95930481],
              [-66.36069489, 17.9584713],
              [-66.36152649, 17.9584713],
              [-66.36152649, 17.95819473],
              [-66.36180878, 17.95819473],
              [-66.36180878, 17.95763969],
              [-66.36208344, 17.95763969],
              [-66.36208344, 17.95736122],
              [-66.36235809, 17.95736122],
              [-66.36235809, 17.95708275],
              [-66.36264038, 17.95708275],
              [-66.36264038, 17.95680428],
              [-66.36402893, 17.95680428],
              [-66.36402893, 17.95652771],
              [-66.36430359, 17.95652771],
              [-66.36430359, 17.95708275],
              [-66.36458588, 17.95708275],
              [-66.36458588, 17.95736122],
              [-66.36486053, 17.95736122],
              [-66.36486053, 17.95791435],
              [-66.36513519, 17.95791435],
              [-66.36513519, 17.95874977],
              [-66.36541748, 17.95874977],
              [-66.36541748, 17.95902634],
              [-66.36569214, 17.95902634],
              [-66.36569214, 17.95930481],
              [-66.36597443, 17.95930481],
              [-66.36597443, 17.95958328],
              [-66.36624908, 17.95958328],
              [-66.36624908, 17.96013641],
              [-66.36653137, 17.96013641],
              [-66.36653137, 17.96069527],
              [-66.36680603, 17.96069527],
              [-66.36680603, 17.96097183],
              [-66.36708069, 17.96097183],
              [-66.36708069, 17.96125031],
              [-66.36736298, 17.96125031],
              [-66.36736298, 17.96152878],
              [-66.36846924, 17.96152878],
              [-66.36846924, 17.96125031],
              [-66.36930847, 17.96125031],
              [-66.36930847, 17.96097183],
              [-66.36958313, 17.96097183],
              [-66.36958313, 17.96069527],
              [-66.36985779, 17.96069527],
              [-66.36985779, 17.9604187],
              [-66.37014008, 17.9604187],
              [-66.37014008, 17.95930481],
              [-66.36958313, 17.95930481],
              [-66.36958313, 17.95902634],
              [-66.36985779, 17.95902634],
              [-66.36985779, 17.9584713],
              [-66.36958313, 17.9584713],
              [-66.36958313, 17.95819473],
              [-66.36902618, 17.95819473],
              [-66.36902618, 17.95791435],
              [-66.36875153, 17.95791435],
              [-66.36875153, 17.95763969],
              [-66.36846924, 17.95763969],
              [-66.36846924, 17.95736122],
              [-66.36819458, 17.95736122],
              [-66.36819458, 17.9556942],
              [-66.36791992, 17.9556942],
              [-66.36791992, 17.95430565],
              [-66.36763763, 17.95430565],
              [-66.36763763, 17.95375061],
              [-66.36736298, 17.95375061],
              [-66.36736298, 17.95319366],
              [-66.36763763, 17.95319366],
              [-66.36763763, 17.9529171],
              [-66.36791992, 17.9529171],
              [-66.36791992, 17.95236206],
              [-66.36875153, 17.95236206],
              [-66.36875153, 17.95208359],
              [-66.36902618, 17.95208359],
              [-66.36902618, 17.95152855],
              [-66.36930847, 17.95152855],
              [-66.36930847, 17.9509716],
              [-66.36958313, 17.9509716],
              [-66.36958313, 17.95069504],
              [-66.37041473, 17.95069504],
              [-66.37041473, 17.9509716],
              [-66.37097168, 17.9509716],
              [-66.37097168, 17.95125008],
              [-66.37152863, 17.95125008],
              [-66.37152863, 17.95152855],
              [-66.37180328, 17.95152855],
              [-66.37180328, 17.95180511],
              [-66.37208557, 17.95180511],
              [-66.37208557, 17.95208359],
              [-66.37236023, 17.95208359],
              [-66.37236023, 17.95236206],
              [-66.37208557, 17.95236206],
              [-66.37208557, 17.9529171],
              [-66.37180328, 17.9529171],
              [-66.37180328, 17.95319366],
              [-66.37152863, 17.95319366],
              [-66.37152863, 17.95375061],
              [-66.37124634, 17.95375061],
              [-66.37124634, 17.95430565],
              [-66.37152863, 17.95430565],
              [-66.37152863, 17.95486069],
              [-66.37208557, 17.95486069],
              [-66.37208557, 17.95513916],
              [-66.37264252, 17.95513916],
              [-66.37264252, 17.95541573],
              [-66.37291718, 17.95541573],
              [-66.37291718, 17.9556942],
              [-66.37430573, 17.9556942],
              [-66.37430573, 17.95513916],
              [-66.37513733, 17.95513916],
              [-66.37513733, 17.95541573],
              [-66.37596893, 17.95541573],
              [-66.37596893, 17.9556942],
              [-66.37625122, 17.9556942],
              [-66.37625122, 17.95541573],
              [-66.37652588, 17.95541573],
              [-66.37652588, 17.95513916],
              [-66.37680817, 17.95513916],
              [-66.37680817, 17.95458221],
              [-66.37652588, 17.95458221],
              [-66.37652588, 17.95402718],
              [-66.37625122, 17.95402718],
              [-66.37625122, 17.95375061],
              [-66.37569427, 17.95375061],
              [-66.37569427, 17.95402718],
              [-66.37541962, 17.95402718],
              [-66.37541962, 17.95375061],
              [-66.37513733, 17.95375061],
              [-66.37513733, 17.95347214],
              [-66.37486267, 17.95347214],
              [-66.37486267, 17.95180511],
              [-66.37513733, 17.95180511],
              [-66.37513733, 17.95152855],
              [-66.37541962, 17.95152855],
              [-66.37541962, 17.9509716],
              [-66.37569427, 17.9509716],
              [-66.37569427, 17.95041656],
              [-66.37596893, 17.95041656],
              [-66.37596893, 17.94986153],
              [-66.37625122, 17.94986153],
              [-66.37625122, 17.94902992],
              [-66.37708282, 17.94902992],
              [-66.37708282, 17.94847298],
              [-66.37652588, 17.94847298],
              [-66.37652588, 17.9481945],
              [-66.37625122, 17.9481945],
              [-66.37625122, 17.94680786],
              [-66.37652588, 17.94680786],
              [-66.37652588, 17.94625092],
              [-66.37680817, 17.94625092],
              [-66.37680817, 17.94597244],
              [-66.37708282, 17.94597244],
              [-66.37708282, 17.9454155],
              [-66.37735748, 17.9454155],
              [-66.37735748, 17.94486046],
              [-66.37763977, 17.94486046],
              [-66.37763977, 17.94430542],
              [-66.37819672, 17.94430542],
              [-66.37819672, 17.94402695],
              [-66.37902832, 17.94402695],
              [-66.37902832, 17.94430542],
              [-66.38014221, 17.94430542],
              [-66.38014221, 17.94402695],
              [-66.38041687, 17.94402695],
              [-66.38041687, 17.94375038],
              [-66.38069153, 17.94375038],
              [-66.38069153, 17.94319534],
              [-66.38041687, 17.94319534],
              [-66.38041687, 17.9426384],
              [-66.38014221, 17.9426384],
              [-66.38014221, 17.94152832],
              [-66.38041687, 17.94152832],
              [-66.38041687, 17.94124985],
              [-66.38097382, 17.94124985],
              [-66.38097382, 17.94097137],
              [-66.38180542, 17.94097137],
              [-66.38180542, 17.94069481],
              [-66.38236237, 17.94069481],
              [-66.38236237, 17.94041634],
              [-66.38291931, 17.94041634],
              [-66.38291931, 17.94013977],
              [-66.38319397, 17.94013977],
              [-66.38319397, 17.9398613],
              [-66.38375092, 17.9398613],
              [-66.38375092, 17.93958282],
              [-66.38402557, 17.93958282],
              [-66.38402557, 17.93902779],
              [-66.38430786, 17.93902779],
              [-66.38430786, 17.93874931],
              [-66.38458252, 17.93874931],
              [-66.38458252, 17.93847275],
              [-66.38486481, 17.93847275],
              [-66.38486481, 17.93819427],
              [-66.38513947, 17.93819427],
              [-66.38513947, 17.9379158],
              [-66.38541412, 17.9379158],
              [-66.38541412, 17.93763924],
              [-66.38569641, 17.93763924],
              [-66.38569641, 17.93736267],
              [-66.38680267, 17.93736267],
              [-66.38680267, 17.93763924],
              [-66.38708496, 17.93763924],
              [-66.38708496, 17.93847275],
              [-66.38735962, 17.93847275],
              [-66.38735962, 17.93902779],
              [-66.38764191, 17.93902779],
              [-66.38764191, 17.93930626],
              [-66.38791656, 17.93930626],
              [-66.38791656, 17.93958282],
              [-66.38819122, 17.93958282],
              [-66.38819122, 17.94013977],
              [-66.38874817, 17.94013977],
              [-66.38874817, 17.93930626],
              [-66.39041901, 17.93930626],
              [-66.39041901, 17.93958282],
              [-66.39069366, 17.93958282],
              [-66.39069366, 17.94041634],
              [-66.39097595, 17.94041634],
              [-66.39097595, 17.94097137],
              [-66.39125061, 17.94097137],
              [-66.39125061, 17.94124985],
              [-66.39152527, 17.94124985],
              [-66.39152527, 17.94180489],
              [-66.39180756, 17.94180489],
              [-66.39180756, 17.94208336],
              [-66.39319611, 17.94208336],
              [-66.39319611, 17.94236183],
              [-66.39375305, 17.94236183],
              [-66.39375305, 17.9426384],
              [-66.39402771, 17.9426384],
              [-66.39402771, 17.94291687],
              [-66.39430237, 17.94291687],
              [-66.39430237, 17.94319534],
              [-66.39458466, 17.94319534],
              [-66.39458466, 17.94347191],
              [-66.39485931, 17.94347191],
              [-66.39485931, 17.94375038],
              [-66.3951416, 17.94375038],
              [-66.3951416, 17.94402695],
              [-66.39569092, 17.94402695],
              [-66.39569092, 17.94430542],
              [-66.39597321, 17.94430542],
              [-66.39597321, 17.94458389],
              [-66.39624786, 17.94458389],
              [-66.39624786, 17.94486046],
              [-66.39653015, 17.94486046],
              [-66.39653015, 17.94513893],
              [-66.39680481, 17.94513893],
              [-66.39680481, 17.9454155],
              [-66.3970871, 17.9454155],
              [-66.3970871, 17.94569397],
              [-66.39736176, 17.94569397],
              [-66.39736176, 17.94597244],
              [-66.39819336, 17.94597244],
              [-66.39819336, 17.94625092],
              [-66.3979187, 17.94625092],
              [-66.3979187, 17.94652939],
              [-66.39819336, 17.94652939],
              [-66.39819336, 17.94680786],
              [-66.39930725, 17.94680786],
              [-66.39930725, 17.94708252],
              [-66.39958191, 17.94708252],
              [-66.39958191, 17.94735909],
              [-66.3998642, 17.94735909],
              [-66.3998642, 17.94763947],
              [-66.40041351, 17.94763947],
              [-66.40041351, 17.9481945],
              [-66.4015274, 17.9481945],
              [-66.4015274, 17.94847298],
              [-66.40208435, 17.94847298],
              [-66.40208435, 17.94902992],
              [-66.40235901, 17.94902992],
              [-66.40235901, 17.94986153],
              [-66.40180206, 17.94986153],
              [-66.40180206, 17.94958305],
              [-66.40125275, 17.94958305],
              [-66.40125275, 17.94930649],
              [-66.40097046, 17.94930649],
              [-66.40097046, 17.94874763],
              [-66.40041351, 17.94874763],
              [-66.40041351, 17.94902992],
              [-66.40013885, 17.94902992],
              [-66.40013885, 17.94874763],
              [-66.3998642, 17.94874763],
              [-66.3998642, 17.9481945],
              [-66.39958191, 17.9481945],
              [-66.39958191, 17.94791794],
              [-66.39875031, 17.94791794],
              [-66.39875031, 17.94763947],
              [-66.3979187, 17.94763947],
              [-66.3979187, 17.94735909],
              [-66.39624786, 17.94735909],
              [-66.39624786, 17.94708252],
              [-66.39597321, 17.94708252],
              [-66.39597321, 17.94625092],
              [-66.39569092, 17.94625092],
              [-66.39569092, 17.94597244],
              [-66.39541626, 17.94597244],
              [-66.39541626, 17.94569397],
              [-66.39485931, 17.94569397],
              [-66.39485931, 17.9454155],
              [-66.39458466, 17.9454155],
              [-66.39458466, 17.94513893],
              [-66.39430237, 17.94513893],
              [-66.39430237, 17.94486046],
              [-66.39375305, 17.94486046],
              [-66.39375305, 17.9454155],
              [-66.39319611, 17.9454155],
              [-66.39319611, 17.94597244],
              [-66.39263916, 17.94597244],
              [-66.39263916, 17.9454155],
              [-66.3923645, 17.9454155],
              [-66.3923645, 17.94513893],
              [-66.39263916, 17.94513893],
              [-66.39263916, 17.94375038],
              [-66.3923645, 17.94375038],
              [-66.3923645, 17.94319534],
              [-66.39208221, 17.94319534],
              [-66.39208221, 17.94291687],
              [-66.39069366, 17.94291687],
              [-66.39069366, 17.9426384],
              [-66.39097595, 17.9426384],
              [-66.39097595, 17.94180489],
              [-66.39069366, 17.94180489],
              [-66.39069366, 17.94152832],
              [-66.39041901, 17.94152832],
              [-66.39041901, 17.94097137],
              [-66.38957977, 17.94097137],
              [-66.38957977, 17.94152832],
              [-66.38986206, 17.94152832],
              [-66.38986206, 17.94291687],
              [-66.39013672, 17.94291687],
              [-66.39013672, 17.94375038],
              [-66.39041901, 17.94375038],
              [-66.39041901, 17.94458389],
              [-66.39069366, 17.94458389],
              [-66.39069366, 17.94486046],
              [-66.39097595, 17.94486046],
              [-66.39097595, 17.94513893],
              [-66.39125061, 17.94513893],
            ],
          ],
          [
            [
              [-66.36430359, 17.93013954],
              [-66.36430359, 17.92986107],
              [-66.36402893, 17.92986107],
              [-66.36402893, 17.9295826],
              [-66.36374664, 17.9295826],
              [-66.36374664, 17.92930412],
              [-66.36208344, 17.92930412],
              [-66.36208344, 17.92847252],
              [-66.36235809, 17.92847252],
              [-66.36235809, 17.92819214],
              [-66.36458588, 17.92819214],
              [-66.36458588, 17.92847252],
              [-66.36486053, 17.92847252],
              [-66.36486053, 17.92819214],
              [-66.36513519, 17.92819214],
              [-66.36513519, 17.92763901],
              [-66.36541748, 17.92763901],
              [-66.36541748, 17.92736053],
              [-66.36846924, 17.92736053],
              [-66.36846924, 17.92763901],
              [-66.36875153, 17.92763901],
              [-66.36875153, 17.92791748],
              [-66.36902618, 17.92791748],
              [-66.36902618, 17.92819214],
              [-66.36930847, 17.92819214],
              [-66.36930847, 17.92874908],
              [-66.36902618, 17.92874908],
              [-66.36902618, 17.92902756],
              [-66.36763763, 17.92902756],
              [-66.36763763, 17.92930412],
              [-66.36708069, 17.92930412],
              [-66.36708069, 17.9295826],
              [-66.36680603, 17.9295826],
              [-66.36680603, 17.92986107],
              [-66.36597443, 17.92986107],
              [-66.36597443, 17.93013954],
              [-66.36430359, 17.93013954],
            ],
          ],
          [
            [
              [-66.38236237, 17.92291641],
              [-66.38236237, 17.92263985],
              [-66.38208008, 17.92263985],
              [-66.38208008, 17.92236137],
              [-66.38180542, 17.92236137],
              [-66.38180542, 17.92152977],
              [-66.38153076, 17.92152977],
              [-66.38153076, 17.92097282],
              [-66.38124847, 17.92097282],
              [-66.38124847, 17.92069435],
              [-66.38097382, 17.92069435],
              [-66.38097382, 17.92041779],
              [-66.38124847, 17.92041779],
              [-66.38124847, 17.92013741],
              [-66.38153076, 17.92013741],
              [-66.38153076, 17.91986084],
              [-66.38208008, 17.91986084],
              [-66.38208008, 17.91958427],
              [-66.38291931, 17.91958427],
              [-66.38291931, 17.91986084],
              [-66.38346863, 17.91986084],
              [-66.38346863, 17.92013741],
              [-66.38375092, 17.92013741],
              [-66.38375092, 17.92069435],
              [-66.38402557, 17.92069435],
              [-66.38402557, 17.92124748],
              [-66.38430786, 17.92124748],
              [-66.38430786, 17.9220829],
              [-66.38402557, 17.9220829],
              [-66.38402557, 17.92236137],
              [-66.38375092, 17.92236137],
              [-66.38375092, 17.92263985],
              [-66.38346863, 17.92263985],
              [-66.38346863, 17.92291641],
              [-66.38236237, 17.92291641],
            ],
          ],
          [
            [
              [-66.38569641, 17.9220829],
              [-66.38569641, 17.92180634],
              [-66.38541412, 17.92180634],
              [-66.38541412, 17.91986084],
              [-66.38569641, 17.91986084],
              [-66.38569641, 17.91902542],
              [-66.38874817, 17.91902542],
              [-66.38874817, 17.91930771],
              [-66.38903046, 17.91930771],
              [-66.38903046, 17.91958427],
              [-66.38930511, 17.91958427],
              [-66.38930511, 17.91986084],
              [-66.38903046, 17.91986084],
              [-66.38903046, 17.92013741],
              [-66.38819122, 17.92013741],
              [-66.38819122, 17.92041779],
              [-66.38791656, 17.92041779],
              [-66.38791656, 17.92097282],
              [-66.38764191, 17.92097282],
              [-66.38764191, 17.92152977],
              [-66.38735962, 17.92152977],
              [-66.38735962, 17.92180634],
              [-66.38708496, 17.92180634],
              [-66.38708496, 17.9220829],
              [-66.38569641, 17.9220829],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 68,
        name: 'Santa Isabel',
        hasc_1: 'PR.SI',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.45212555, 17.91902542, -66.3312912, 18.04259872],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.69',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.2782135, 18.32979965],
              [-66.27775574, 18.33075333],
              [-66.28380585, 18.34407806],
              [-66.28473663, 18.34598351],
              [-66.2870636, 18.35074425],
              [-66.28845978, 18.3531208],
              [-66.28985596, 18.35502434],
              [-66.29124451, 18.35597229],
              [-66.29403687, 18.35881996],
              [-66.29589081, 18.35976791],
              [-66.30331421, 18.36641693],
              [-66.304245, 18.36736488],
              [-66.30702209, 18.36592674],
              [-66.30794525, 18.36497116],
              [-66.31118011, 18.36448669],
              [-66.31256866, 18.36447906],
              [-66.31535339, 18.36590195],
              [-66.31859589, 18.36731529],
              [-66.31907654, 18.37160301],
              [-66.31677246, 18.3749485],
              [-66.31447601, 18.38019753],
              [-66.31401062, 18.38067627],
              [-66.30846405, 18.38307953],
              [-66.30522156, 18.38404083],
              [-66.30383301, 18.38404465],
              [-66.28855896, 18.38552475],
              [-66.28623962, 18.38314629],
              [-66.28253174, 18.38315964],
              [-66.27142334, 18.3836689],
              [-66.27050018, 18.38557816],
              [-66.26957703, 18.38606071],
              [-66.26402283, 18.38798141],
              [-66.26031494, 18.38703728],
              [-66.25800323, 18.38752174],
              [-66.25476837, 18.38991165],
              [-66.25246429, 18.39420891],
              [-66.24459076, 18.39470482],
              [-66.24412537, 18.39470673],
              [-66.23996735, 18.39709854],
              [-66.23719025, 18.39853668],
              [-66.23487854, 18.40092278],
              [-66.23395538, 18.40140343],
              [-66.23256683, 18.4009285],
              [-66.19688416, 18.38910294],
              [-66.19916534, 18.37289238],
              [-66.19961548, 18.36955833],
              [-66.19915009, 18.36860466],
              [-66.19776154, 18.36527443],
              [-66.19728851, 18.36193848],
              [-66.19728851, 18.36050797],
              [-66.19958496, 18.3547821],
              [-66.20050049, 18.34668159],
              [-66.19956207, 18.3400116],
              [-66.20372009, 18.33666611],
              [-66.2013855, 18.32904625],
              [-66.20552826, 18.31664467],
              [-66.20738983, 18.3237915],
              [-66.21294403, 18.32377815],
              [-66.21387482, 18.32473183],
              [-66.21620178, 18.33282661],
              [-66.21713257, 18.33377838],
              [-66.22129822, 18.33281136],
              [-66.22545624, 18.3299427],
              [-66.23052979, 18.32468987],
              [-66.23284149, 18.32373047],
              [-66.23377228, 18.32372665],
              [-66.23377228, 18.3242054],
              [-66.23795319, 18.33181572],
              [-66.23841858, 18.3327713],
              [-66.23934174, 18.33276939],
              [-66.24536133, 18.33179855],
              [-66.24628448, 18.33131981],
              [-66.24628448, 18.33084106],
              [-66.24858093, 18.32607079],
              [-66.25275421, 18.32844353],
              [-66.25878143, 18.33223724],
              [-66.26293945, 18.32984543],
              [-66.27127075, 18.32886696],
              [-66.27311707, 18.32933807],
              [-66.27729034, 18.32980156],
              [-66.2782135, 18.32979965],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 69,
        name: 'Toa Alta',
        hasc_1: 'PR.TA',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.31907654, 18.31664467, -66.19688416, 18.40140343],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.70',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.18235779, 18.47624969],
              [-66.18235779, 18.47597313],
              [-66.18180847, 18.47597313],
              [-66.18180847, 18.47569466],
              [-66.18125153, 18.47569466],
              [-66.18125153, 18.47541618],
              [-66.17903137, 18.47541618],
              [-66.17903137, 18.47513962],
              [-66.17874908, 18.47513962],
              [-66.17874908, 18.47402763],
              [-66.17903137, 18.47402763],
              [-66.17903137, 18.4734726],
              [-66.17930603, 18.4734726],
              [-66.17930603, 18.47319412],
              [-66.18013763, 18.47319412],
              [-66.18013763, 18.4734726],
              [-66.18041992, 18.4734726],
              [-66.18041992, 18.47430611],
              [-66.18069458, 18.47430611],
              [-66.18069458, 18.47486115],
              [-66.18180847, 18.47486115],
              [-66.18180847, 18.47458458],
              [-66.18208313, 18.47458458],
              [-66.18208313, 18.47430611],
              [-66.18264008, 18.47430611],
              [-66.18264008, 18.47402763],
              [-66.18319702, 18.47402763],
              [-66.18319702, 18.47374916],
              [-66.18374634, 18.47374916],
              [-66.18374634, 18.4734726],
              [-66.18402863, 18.4734726],
              [-66.18402863, 18.47319412],
              [-66.18430328, 18.47319412],
              [-66.18430328, 18.47263908],
              [-66.18458557, 18.47263908],
              [-66.18458557, 18.47236252],
              [-66.18486023, 18.47236252],
              [-66.18486023, 18.47208405],
              [-66.18514252, 18.47208405],
              [-66.18514252, 18.47180557],
              [-66.18541718, 18.47180557],
              [-66.18541718, 18.47208405],
              [-66.18569183, 18.47208405],
              [-66.18569183, 18.47430611],
              [-66.18541718, 18.47430611],
              [-66.18541718, 18.47458458],
              [-66.18569183, 18.47458458],
              [-66.18569183, 18.47513962],
              [-66.18624878, 18.47513962],
              [-66.18624878, 18.47486115],
              [-66.18680573, 18.47486115],
              [-66.18680573, 18.47569466],
              [-66.18624878, 18.47569466],
              [-66.18624878, 18.47597313],
              [-66.18264008, 18.47597313],
              [-66.18264008, 18.47624969],
              [-66.18235779, 18.47624969],
            ],
          ],
          [
            [
              [-66.13930511, 18.47624969],
              [-66.13930511, 18.47597313],
              [-66.13903046, 18.47597313],
              [-66.13903046, 18.47541618],
              [-66.13874817, 18.47541618],
              [-66.13874817, 18.47513962],
              [-66.13735962, 18.47513962],
              [-66.13735962, 18.47486115],
              [-66.13708496, 18.47486115],
              [-66.13708496, 18.47458458],
              [-66.13680267, 18.47458458],
              [-66.13680267, 18.47402763],
              [-66.13652802, 18.47402763],
              [-66.13652802, 18.4734726],
              [-66.13569641, 18.4734726],
              [-66.13569641, 18.47374916],
              [-66.13597107, 18.47374916],
              [-66.13597107, 18.47402763],
              [-66.13569641, 18.47402763],
              [-66.13569641, 18.47430611],
              [-66.13402557, 18.47430611],
              [-66.13402557, 18.47402763],
              [-66.13375092, 18.47402763],
              [-66.13375092, 18.4734726],
              [-66.13402557, 18.4734726],
              [-66.13402557, 18.47319412],
              [-66.13458252, 18.47319412],
              [-66.13458252, 18.47236252],
              [-66.13370514, 18.47236252],
              [-66.13346863, 18.47236252],
              [-66.13346863, 18.4715271],
              [-66.13383484, 18.4715271],
              [-66.13402557, 18.4715271],
              [-66.13402557, 18.47124863],
              [-66.13458252, 18.47124863],
              [-66.13458252, 18.47097015],
              [-66.13513947, 18.47097015],
              [-66.13513947, 18.47041893],
              [-66.13569641, 18.47041893],
              [-66.13569641, 18.46763611],
              [-66.13597107, 18.46763611],
              [-66.13597107, 18.46708298],
              [-66.13625336, 18.46708298],
              [-66.13625336, 18.46680641],
              [-66.13680267, 18.46680641],
              [-66.13680267, 18.46708298],
              [-66.13708496, 18.46708298],
              [-66.13708496, 18.46819496],
              [-66.13680267, 18.46819496],
              [-66.13680267, 18.46874809],
              [-66.13708496, 18.46874809],
              [-66.13708496, 18.46930504],
              [-66.13735962, 18.46930504],
              [-66.13735962, 18.47180557],
              [-66.13764191, 18.47180557],
              [-66.13764191, 18.47236252],
              [-66.13791656, 18.47236252],
              [-66.13791656, 18.47291756],
              [-66.13819122, 18.47291756],
              [-66.13819122, 18.47319412],
              [-66.13874817, 18.47319412],
              [-66.13874817, 18.47374916],
              [-66.13930511, 18.47374916],
              [-66.13930511, 18.47402763],
              [-66.13986206, 18.47402763],
              [-66.13986206, 18.47430611],
              [-66.14013672, 18.47430611],
              [-66.14013672, 18.47486115],
              [-66.14041901, 18.47486115],
              [-66.14041901, 18.47569466],
              [-66.13986206, 18.47569466],
              [-66.13986206, 18.47624969],
              [-66.13930511, 18.47624969],
            ],
          ],
          [
            [
              [-66.16916656, 18.43108177],
              [-66.17055511, 18.42964745],
              [-66.17055511, 18.42917442],
              [-66.16962433, 18.42584038],
              [-66.17008209, 18.42441177],
              [-66.1723938, 18.42107201],
              [-66.17378235, 18.42059135],
              [-66.17470551, 18.42011261],
              [-66.17841339, 18.41915321],
              [-66.17933655, 18.42010689],
              [-66.18212128, 18.42200661],
              [-66.18536377, 18.42152214],
              [-66.18721771, 18.42056847],
              [-66.18767548, 18.41913605],
              [-66.18952942, 18.4186573],
              [-66.19138336, 18.41817474],
              [-66.19786072, 18.41482735],
              [-66.19645691, 18.40863609],
              [-66.19783783, 18.40386772],
              [-66.19644165, 18.40005875],
              [-66.19550323, 18.39672661],
              [-66.19688416, 18.38910294],
              [-66.23256683, 18.4009285],
              [-66.23395538, 18.40140343],
              [-66.23487854, 18.40092278],
              [-66.23719025, 18.39853668],
              [-66.23996735, 18.39709854],
              [-66.24412537, 18.39470673],
              [-66.24459076, 18.39470482],
              [-66.25246429, 18.39420891],
              [-66.25617218, 18.39610481],
              [-66.25991058, 18.40895271],
              [-66.25621033, 18.41182327],
              [-66.25575256, 18.41182327],
              [-66.25621796, 18.41325569],
              [-66.25668335, 18.41563416],
              [-66.25807953, 18.41658401],
              [-66.26224518, 18.41466522],
              [-66.26364136, 18.41656876],
              [-66.26411438, 18.42037773],
              [-66.26411438, 18.42085457],
              [-66.26041412, 18.42276955],
              [-66.25949097, 18.42372704],
              [-66.25811768, 18.43087769],
              [-66.25535583, 18.43850517],
              [-66.25489807, 18.43898582],
              [-66.25536346, 18.44041061],
              [-66.25909424, 18.44897461],
              [-66.25909424, 18.45040131],
              [-66.25817871, 18.45516968],
              [-66.25400543, 18.45422745],
              [-66.24797821, 18.45138741],
              [-66.2456665, 18.45377541],
              [-66.24427795, 18.45472717],
              [-66.24150085, 18.45569038],
              [-66.23918152, 18.45521927],
              [-66.235466, 18.45284462],
              [-66.23036957, 18.45047951],
              [-66.23083496, 18.45428848],
              [-66.22436523, 18.45859528],
              [-66.21834564, 18.4624157],
              [-66.21140289, 18.46652794],
              [-66.2076416, 18.46652794],
              [-66.2076416, 18.46680641],
              [-66.20735931, 18.46680641],
              [-66.20735931, 18.46652794],
              [-66.20597076, 18.46652794],
              [-66.20597076, 18.46624947],
              [-66.20569611, 18.46624947],
              [-66.20569611, 18.4659729],
              [-66.20430756, 18.4659729],
              [-66.20430756, 18.46624947],
              [-66.20319366, 18.46624947],
              [-66.20319366, 18.46652794],
              [-66.20236206, 18.46652794],
              [-66.20236206, 18.46624947],
              [-66.20180511, 18.46624947],
              [-66.20180511, 18.46652794],
              [-66.20097351, 18.46652794],
              [-66.20097351, 18.46680641],
              [-66.20041656, 18.46680641],
              [-66.20041656, 18.46708298],
              [-66.20014191, 18.46708298],
              [-66.20014191, 18.46763611],
              [-66.19985962, 18.46763611],
              [-66.19985962, 18.46874809],
              [-66.19902802, 18.46874809],
              [-66.19902802, 18.46847153],
              [-66.19875336, 18.46847153],
              [-66.19875336, 18.46680641],
              [-66.19847107, 18.46680641],
              [-66.19847107, 18.46652794],
              [-66.19819641, 18.46652794],
              [-66.19819641, 18.46624947],
              [-66.19430542, 18.46624947],
              [-66.19430542, 18.4659729],
              [-66.19403076, 18.4659729],
              [-66.19403076, 18.46624947],
              [-66.19347382, 18.46624947],
              [-66.19347382, 18.46652794],
              [-66.19208527, 18.46652794],
              [-66.19208527, 18.46680641],
              [-66.19152832, 18.46680641],
              [-66.19152832, 18.46708298],
              [-66.19041443, 18.46708298],
              [-66.19041443, 18.46736145],
              [-66.19013977, 18.46736145],
              [-66.19013977, 18.46763611],
              [-66.18930817, 18.46763611],
              [-66.18930817, 18.46791649],
              [-66.18875122, 18.46791649],
              [-66.18875122, 18.46819496],
              [-66.18819427, 18.46819496],
              [-66.18819427, 18.46874809],
              [-66.18763733, 18.46874809],
              [-66.18763733, 18.46903038],
              [-66.18708038, 18.46903038],
              [-66.18708038, 18.46930504],
              [-66.18653107, 18.46930504],
              [-66.18653107, 18.46958351],
              [-66.18624878, 18.46958351],
              [-66.18624878, 18.46986008],
              [-66.18597412, 18.46986008],
              [-66.18597412, 18.46736145],
              [-66.18569183, 18.46736145],
              [-66.18569183, 18.46708298],
              [-66.18541718, 18.46708298],
              [-66.18541718, 18.46624947],
              [-66.18569183, 18.46624947],
              [-66.18569183, 18.46513939],
              [-66.18541718, 18.46513939],
              [-66.18541718, 18.46458244],
              [-66.18514252, 18.46458244],
              [-66.18514252, 18.4640274],
              [-66.18486023, 18.4640274],
              [-66.18486023, 18.46375084],
              [-66.18458557, 18.46375084],
              [-66.18458557, 18.46347237],
              [-66.18486023, 18.46347237],
              [-66.18486023, 18.4631958],
              [-66.18458557, 18.4631958],
              [-66.18458557, 18.46236038],
              [-66.18430328, 18.46236038],
              [-66.18430328, 18.46152687],
              [-66.18402863, 18.46152687],
              [-66.18402863, 18.46069527],
              [-66.18430328, 18.46069527],
              [-66.18430328, 18.46013832],
              [-66.18402863, 18.46013832],
              [-66.18402863, 18.45958328],
              [-66.18374634, 18.45958328],
              [-66.18374634, 18.45930481],
              [-66.18347168, 18.45930481],
              [-66.18347168, 18.45902824],
              [-66.18319702, 18.45902824],
              [-66.18319702, 18.45875168],
              [-66.18291473, 18.45875168],
              [-66.18291473, 18.45846939],
              [-66.18264008, 18.45846939],
              [-66.18264008, 18.45819473],
              [-66.18235779, 18.45819473],
              [-66.18235779, 18.45791626],
              [-66.18208313, 18.45791626],
              [-66.18208313, 18.45735931],
              [-66.18180847, 18.45735931],
              [-66.18180847, 18.45708275],
              [-66.18125153, 18.45708275],
              [-66.18125153, 18.45652771],
              [-66.18069458, 18.45652771],
              [-66.18069458, 18.45624733],
              [-66.18013763, 18.45624733],
              [-66.18013763, 18.45652771],
              [-66.17986298, 18.45652771],
              [-66.17986298, 18.45597267],
              [-66.17930603, 18.45597267],
              [-66.17930603, 18.45486069],
              [-66.17903137, 18.45486069],
              [-66.17903137, 18.45458412],
              [-66.17819214, 18.45458412],
              [-66.17819214, 18.45430565],
              [-66.17791748, 18.45430565],
              [-66.17791748, 18.45402718],
              [-66.17763519, 18.45402718],
              [-66.17763519, 18.45375061],
              [-66.17680359, 18.45375061],
              [-66.17680359, 18.45347214],
              [-66.17597198, 18.45347214],
              [-66.17597198, 18.45319366],
              [-66.17541504, 18.45319366],
              [-66.17541504, 18.45291519],
              [-66.17485809, 18.45291519],
              [-66.17485809, 18.45263863],
              [-66.17430878, 18.45263863],
              [-66.17430878, 18.45236206],
              [-66.17375183, 18.45236206],
              [-66.17375183, 18.45208359],
              [-66.17346954, 18.45208359],
              [-66.17346954, 18.45180511],
              [-66.17236328, 18.45180511],
              [-66.17236328, 18.45152855],
              [-66.17208099, 18.45152855],
              [-66.17208099, 18.45125008],
              [-66.17152405, 18.45125008],
              [-66.17152405, 18.4509716],
              [-66.17097473, 18.4509716],
              [-66.17097473, 18.45125008],
              [-66.17041779, 18.45125008],
              [-66.17041779, 18.4509716],
              [-66.16874695, 18.4509716],
              [-66.16874695, 18.45069695],
              [-66.16764069, 18.45069695],
              [-66.16764069, 18.4509716],
              [-66.16680908, 18.4509716],
              [-66.16680908, 18.45069695],
              [-66.16486359, 18.45069695],
              [-66.16486359, 18.4509716],
              [-66.1645813, 18.4509716],
              [-66.1645813, 18.45125008],
              [-66.16402435, 18.45125008],
              [-66.16402435, 18.45152855],
              [-66.16319275, 18.45152855],
              [-66.16319275, 18.45125008],
              [-66.1626358, 18.45125008],
              [-66.1626358, 18.45152855],
              [-66.16236115, 18.45152855],
              [-66.16236115, 18.45180511],
              [-66.16208649, 18.45180511],
              [-66.16208649, 18.45208359],
              [-66.16152954, 18.45208359],
              [-66.16152954, 18.45180511],
              [-66.16124725, 18.45180511],
              [-66.16124725, 18.45125008],
              [-66.1609726, 18.45125008],
              [-66.1609726, 18.4509716],
              [-66.16041565, 18.4509716],
              [-66.16041565, 18.45152855],
              [-66.16014099, 18.45152855],
              [-66.16014099, 18.45180511],
              [-66.15958405, 18.45180511],
              [-66.15958405, 18.45208359],
              [-66.15875244, 18.45208359],
              [-66.15875244, 18.45263863],
              [-66.15791321, 18.45263863],
              [-66.15791321, 18.45291519],
              [-66.15736389, 18.45291519],
              [-66.15736389, 18.45263863],
              [-66.1570816, 18.45263863],
              [-66.1570816, 18.45291519],
              [-66.15652466, 18.45291519],
              [-66.15652466, 18.45319366],
              [-66.15597534, 18.45319366],
              [-66.15597534, 18.45347214],
              [-66.15513611, 18.45347214],
              [-66.15513611, 18.45375061],
              [-66.15486145, 18.45375061],
              [-66.15486145, 18.45402718],
              [-66.1543045, 18.45402718],
              [-66.1543045, 18.45375061],
              [-66.1534729, 18.45375061],
              [-66.1534729, 18.45402718],
              [-66.15319824, 18.45402718],
              [-66.15319824, 18.45458412],
              [-66.15291595, 18.45458412],
              [-66.15291595, 18.45486069],
              [-66.1526413, 18.45486069],
              [-66.1526413, 18.45513725],
              [-66.15235901, 18.45513725],
              [-66.15235901, 18.45541573],
              [-66.15180206, 18.45541573],
              [-66.15180206, 18.4556942],
              [-66.1515274, 18.4556942],
              [-66.1515274, 18.45597267],
              [-66.15125275, 18.45597267],
              [-66.15125275, 18.45624733],
              [-66.1506958, 18.45624733],
              [-66.1506958, 18.45680618],
              [-66.15041351, 18.45680618],
              [-66.15041351, 18.45735931],
              [-66.15013885, 18.45735931],
              [-66.15013885, 18.4576416],
              [-66.14930725, 18.4576416],
              [-66.14930725, 18.45791626],
              [-66.14902496, 18.45791626],
              [-66.14902496, 18.45819473],
              [-66.14847565, 18.45819473],
              [-66.14847565, 18.45846939],
              [-66.14819336, 18.45846939],
              [-66.14819336, 18.45875168],
              [-66.14736176, 18.45875168],
              [-66.14736176, 18.45930481],
              [-66.1470871, 18.45930481],
              [-66.1470871, 18.45986366],
              [-66.14680481, 18.45986366],
              [-66.14680481, 18.46013832],
              [-66.14653015, 18.46013832],
              [-66.14653015, 18.46069527],
              [-66.14624786, 18.46069527],
              [-66.14624786, 18.46097374],
              [-66.14569092, 18.46097374],
              [-66.14569092, 18.46152687],
              [-66.14541626, 18.46152687],
              [-66.14541626, 18.46208382],
              [-66.1451416, 18.46208382],
              [-66.1451416, 18.46236038],
              [-66.14485931, 18.46236038],
              [-66.14485931, 18.46263885],
              [-66.14458466, 18.46263885],
              [-66.14458466, 18.46291733],
              [-66.14430237, 18.46291733],
              [-66.14430237, 18.4631958],
              [-66.14402771, 18.4631958],
              [-66.14402771, 18.46347237],
              [-66.14319611, 18.46347237],
              [-66.14319611, 18.46375084],
              [-66.14263916, 18.46375084],
              [-66.14263916, 18.4640274],
              [-66.1423645, 18.4640274],
              [-66.1423645, 18.46375084],
              [-66.14180756, 18.46375084],
              [-66.14180756, 18.46347237],
              [-66.14097595, 18.46347237],
              [-66.14097595, 18.46458244],
              [-66.14013672, 18.46458244],
              [-66.14013672, 18.46486092],
              [-66.13930511, 18.46486092],
              [-66.13930511, 18.4640274],
              [-66.13957977, 18.4640274],
              [-66.13957977, 18.46263885],
              [-66.13930511, 18.46263885],
              [-66.13930511, 18.46180534],
              [-66.13903046, 18.46180534],
              [-66.13903046, 18.46125031],
              [-66.13874817, 18.46125031],
              [-66.13874817, 18.46069527],
              [-66.13847351, 18.46069527],
              [-66.13847351, 18.46013832],
              [-66.13819122, 18.46013832],
              [-66.13819122, 18.45986366],
              [-66.13791656, 18.45986366],
              [-66.13791656, 18.45958328],
              [-66.14650726, 18.45541382],
              [-66.14835358, 18.45160103],
              [-66.14927673, 18.45064545],
              [-66.15158844, 18.44826126],
              [-66.15390778, 18.44587326],
              [-66.15483093, 18.44587326],
              [-66.15853882, 18.44586754],
              [-66.15992737, 18.44586754],
              [-66.16316986, 18.44586182],
              [-66.16362762, 18.44395447],
              [-66.16362762, 18.44157219],
              [-66.16501617, 18.43823433],
              [-66.16593933, 18.43632507],
              [-66.16917419, 18.43536758],
              [-66.16963959, 18.43536949],
              [-66.17009735, 18.43441963],
              [-66.17009735, 18.43346024],
              [-66.16916656, 18.43108177],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 70,
        name: 'Toa Baja',
        hasc_1: 'PR.TB',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.26411438, 18.38910294, -66.13346863, 18.47624969],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.71',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-65.94224548, 18.29350281],
              [-65.95288849, 18.2944603],
              [-65.96352386, 18.30066109],
              [-65.96537018, 18.30399704],
              [-65.96814728, 18.31019783],
              [-65.97370148, 18.31019974],
              [-65.9861908, 18.3111515],
              [-65.99497986, 18.30686378],
              [-65.99775696, 18.30591202],
              [-66.00655365, 18.30448151],
              [-66.00747681, 18.30448151],
              [-66.00839996, 18.30448151],
              [-66.01580811, 18.30829239],
              [-66.01673126, 18.30876923],
              [-66.02182007, 18.30495453],
              [-66.02829742, 18.31019974],
              [-66.03292847, 18.30924225],
              [-66.03430939, 18.30924225],
              [-66.03662872, 18.31019402],
              [-66.04125214, 18.31209755],
              [-66.0352478, 18.31972694],
              [-66.03570557, 18.32115746],
              [-66.03662872, 18.32353973],
              [-66.03617096, 18.32782936],
              [-66.03385925, 18.33498001],
              [-66.03710175, 18.34593773],
              [-66.03340149, 18.35451698],
              [-66.03340149, 18.35642624],
              [-66.03572083, 18.36833763],
              [-66.03479767, 18.36976624],
              [-66.03294373, 18.37262726],
              [-66.02507782, 18.37977982],
              [-66.01350403, 18.37882805],
              [-66.00794983, 18.37882805],
              [-66.00146484, 18.37978172],
              [-66.00099945, 18.37978172],
              [-65.99914551, 18.38025856],
              [-65.99173737, 18.37692261],
              [-65.98942566, 18.36595917],
              [-65.97554016, 18.35261536],
              [-65.96906281, 18.3373642],
              [-65.96721649, 18.33021355],
              [-65.96305084, 18.32496834],
              [-65.96166229, 18.3230648],
              [-65.95657349, 18.31829453],
              [-65.94223785, 18.31209183],
              [-65.94177246, 18.31209183],
              [-65.94038391, 18.31113243],
              [-65.93899536, 18.30874825],
              [-65.94224548, 18.29350281],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 71,
        name: 'Trujillo Alto',
        hasc_1: 'PR.TJ',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.04125214, 18.29350281, -65.93899536, 18.38025856],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.72',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.56682587, 18.29844856],
              [-66.57328033, 18.29459572],
              [-66.57692719, 18.28598785],
              [-66.57784271, 18.28455544],
              [-66.57830811, 18.2840786],
              [-66.57923126, 18.28406906],
              [-66.58385468, 18.28404045],
              [-66.58986664, 18.28305054],
              [-66.59496307, 18.28492355],
              [-66.59680939, 18.28443527],
              [-66.59726715, 18.28395462],
              [-66.59864807, 18.28203964],
              [-66.59816742, 18.27918243],
              [-66.59583282, 18.27633858],
              [-66.59628296, 18.27299309],
              [-66.59901428, 18.26678085],
              [-66.59938812, 18.25247574],
              [-66.59985352, 18.25199318],
              [-66.61739349, 18.2475853],
              [-66.61833191, 18.2490139],
              [-66.61878967, 18.24901009],
              [-66.61972046, 18.24947929],
              [-66.62248993, 18.24898338],
              [-66.62479401, 18.24754143],
              [-66.62525177, 18.24706078],
              [-66.6256485, 18.23608971],
              [-66.62653351, 18.22988129],
              [-66.62835693, 18.22605705],
              [-66.62970734, 18.22080421],
              [-66.63380432, 18.21123886],
              [-66.639328, 18.20738792],
              [-66.63978577, 18.20690346],
              [-66.64386749, 18.19447708],
              [-66.64194489, 18.18351936],
              [-66.64286804, 18.1830368],
              [-66.65210724, 18.18249321],
              [-66.65155792, 18.16962242],
              [-66.65057373, 18.16056824],
              [-66.64963531, 18.1586647],
              [-66.65010071, 18.15913773],
              [-66.65195465, 18.15912628],
              [-66.65379333, 18.15863419],
              [-66.65701294, 18.15670586],
              [-66.66392517, 18.15379333],
              [-66.66622925, 18.15329933],
              [-66.67038727, 18.15279388],
              [-66.67684174, 18.15083694],
              [-66.6769104, 18.1603756],
              [-66.67140961, 18.16709328],
              [-66.67095184, 18.16805458],
              [-66.66497803, 18.17286682],
              [-66.66452026, 18.1738224],
              [-66.66500092, 18.17572594],
              [-66.66963959, 18.17903137],
              [-66.67195892, 18.18044281],
              [-66.67567444, 18.1828022],
              [-66.68032074, 18.18610764],
              [-66.68171692, 18.18800545],
              [-66.68453217, 18.19370842],
              [-66.68271637, 18.19896507],
              [-66.68134308, 18.20088577],
              [-66.68089294, 18.20231628],
              [-66.68136597, 18.2037468],
              [-66.68136597, 18.20422363],
              [-66.6860199, 18.20895576],
              [-66.68741608, 18.20990181],
              [-66.69254303, 18.21606064],
              [-66.69301605, 18.2170105],
              [-66.69347382, 18.21700668],
              [-66.70780945, 18.21689987],
              [-66.73191833, 18.22624779],
              [-66.73927307, 18.22094154],
              [-66.73973083, 18.22093964],
              [-66.75547028, 18.22319412],
              [-66.75593567, 18.22366524],
              [-66.76010895, 18.22554207],
              [-66.76057434, 18.22601128],
              [-66.76058197, 18.22696686],
              [-66.76154327, 18.23125267],
              [-66.76389313, 18.23599815],
              [-66.76436615, 18.23742676],
              [-66.77878571, 18.24827576],
              [-66.78250122, 18.25062561],
              [-66.7931366, 18.25005722],
              [-66.79451752, 18.25004768],
              [-66.79498291, 18.24956512],
              [-66.79497528, 18.24908829],
              [-66.80001068, 18.24284744],
              [-66.80091858, 18.24093056],
              [-66.80045319, 18.2399826],
              [-66.79994965, 18.23521423],
              [-66.79898071, 18.22998238],
              [-66.79989624, 18.22854233],
              [-66.80081177, 18.22757912],
              [-66.80358124, 18.22755432],
              [-66.8170166, 18.230299],
              [-66.82683563, 18.24356079],
              [-66.82730103, 18.24403572],
              [-66.82696533, 18.25929451],
              [-66.82606506, 18.26216316],
              [-66.82563782, 18.26598167],
              [-66.82662201, 18.27312469],
              [-66.83036804, 18.27786064],
              [-66.82952118, 18.2874012],
              [-66.82461548, 18.30937195],
              [-66.82697296, 18.31411743],
              [-66.82743835, 18.31459045],
              [-66.82743835, 18.31506729],
              [-66.82611847, 18.32318115],
              [-66.79418182, 18.32203293],
              [-66.78538513, 18.32163239],
              [-66.77010345, 18.3208065],
              [-66.7701416, 18.32509422],
              [-66.75681305, 18.33712578],
              [-66.75266266, 18.33906364],
              [-66.7457428, 18.34197617],
              [-66.74066925, 18.34440041],
              [-66.73928833, 18.34584236],
              [-66.7383728, 18.34680176],
              [-66.73514557, 18.34825706],
              [-66.73052216, 18.34925079],
              [-66.72631073, 18.34356117],
              [-66.72351074, 18.33977318],
              [-66.72071838, 18.33788681],
              [-66.71977997, 18.33646584],
              [-66.72023773, 18.33550835],
              [-66.72298431, 18.33167458],
              [-66.72063446, 18.32692719],
              [-66.71966553, 18.32073784],
              [-66.71827698, 18.32074928],
              [-66.71825409, 18.31788826],
              [-66.71731567, 18.31599045],
              [-66.7131424, 18.3150692],
              [-66.71176147, 18.31555557],
              [-66.70852661, 18.31653595],
              [-66.7066803, 18.31654549],
              [-66.70300293, 18.3203907],
              [-66.69930267, 18.32089424],
              [-66.69791412, 18.3209095],
              [-66.6965332, 18.3218689],
              [-66.69052124, 18.32191658],
              [-66.68911743, 18.32049179],
              [-66.68819427, 18.32002449],
              [-66.6854248, 18.32099915],
              [-66.68310547, 18.3210125],
              [-66.68173218, 18.32245445],
              [-66.67940521, 18.32151985],
              [-66.67802429, 18.32200813],
              [-66.67295074, 18.32442856],
              [-66.67156219, 18.3244381],
              [-66.66273499, 18.31973457],
              [-66.65720367, 18.32215691],
              [-66.65628052, 18.32263756],
              [-66.65444946, 18.32551003],
              [-66.65307617, 18.32790565],
              [-66.6484375, 18.32650757],
              [-66.64518738, 18.32557869],
              [-66.63963318, 18.32466125],
              [-66.63824463, 18.32515144],
              [-66.63594055, 18.32611847],
              [-66.63225555, 18.32852745],
              [-66.63040161, 18.3285408],
              [-66.62901306, 18.3280735],
              [-66.62669373, 18.32808876],
              [-66.62392426, 18.32858467],
              [-66.62162018, 18.33098221],
              [-66.6193161, 18.33195305],
              [-66.61237335, 18.33152199],
              [-66.60679626, 18.32822227],
              [-66.61138916, 18.32247162],
              [-66.610466, 18.32247925],
              [-66.59473419, 18.32353401],
              [-66.58501434, 18.32264709],
              [-66.58407593, 18.32026672],
              [-66.58312225, 18.31645966],
              [-66.58172607, 18.31408501],
              [-66.57289886, 18.3088932],
              [-66.56682587, 18.29844856],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 72,
        name: 'Utuado',
        hasc_1: 'PR.UT',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.83036804, 18.15083694, -66.56682587, 18.34925079],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.73',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.34958649, 18.48347282],
              [-66.34819794, 18.48347282],
              [-66.34819794, 18.48319435],
              [-66.3473587, 18.48319435],
              [-66.3473587, 18.48291588],
              [-66.34680176, 18.48291588],
              [-66.34680176, 18.48263741],
              [-66.34597015, 18.48263741],
              [-66.34597015, 18.48236084],
              [-66.34541321, 18.48236084],
              [-66.34541321, 18.48208427],
              [-66.34513855, 18.48208427],
              [-66.34513855, 18.4818058],
              [-66.3445816, 18.4818058],
              [-66.3445816, 18.48152733],
              [-66.34430695, 18.48152733],
              [-66.34430695, 18.48125076],
              [-66.34375, 18.48125076],
              [-66.34375, 18.48097229],
              [-66.3418045, 18.48097229],
              [-66.3418045, 18.48125076],
              [-66.33985901, 18.48125076],
              [-66.33985901, 18.48152733],
              [-66.33930206, 18.48152733],
              [-66.33930206, 18.4818058],
              [-66.3390274, 18.4818058],
              [-66.3390274, 18.48208427],
              [-66.33875275, 18.48208427],
              [-66.33875275, 18.48319435],
              [-66.3390274, 18.48319435],
              [-66.3390274, 18.4845829],
              [-66.33930206, 18.4845829],
              [-66.33930206, 18.48513794],
              [-66.33958435, 18.48513794],
              [-66.33958435, 18.48569489],
              [-66.3401413, 18.48569489],
              [-66.3401413, 18.48596954],
              [-66.33985901, 18.48596954],
              [-66.33985901, 18.4865284],
              [-66.33875275, 18.4865284],
              [-66.33875275, 18.48625183],
              [-66.33847046, 18.48625183],
              [-66.33847046, 18.48596954],
              [-66.3381958, 18.48596954],
              [-66.3381958, 18.48541641],
              [-66.33708191, 18.48541641],
              [-66.33708191, 18.48513794],
              [-66.33680725, 18.48513794],
              [-66.33680725, 18.48485947],
              [-66.33652496, 18.48485947],
              [-66.33652496, 18.4845829],
              [-66.33569336, 18.4845829],
              [-66.33569336, 18.48485947],
              [-66.33513641, 18.48485947],
              [-66.33513641, 18.4845829],
              [-66.3345871, 18.4845829],
              [-66.3345871, 18.48430634],
              [-66.33430481, 18.48430634],
              [-66.33430481, 18.48374939],
              [-66.33403015, 18.48374939],
              [-66.33403015, 18.48347282],
              [-66.33374786, 18.48347282],
              [-66.33374786, 18.48319435],
              [-66.33347321, 18.48319435],
              [-66.33347321, 18.48291588],
              [-66.33319092, 18.48291588],
              [-66.33319092, 18.48263741],
              [-66.33152771, 18.48263741],
              [-66.33152771, 18.48236084],
              [-66.33041382, 18.48236084],
              [-66.33041382, 18.48208427],
              [-66.3298645, 18.48208427],
              [-66.3298645, 18.4818058],
              [-66.32930756, 18.4818058],
              [-66.32930756, 18.48152733],
              [-66.32875061, 18.48152733],
              [-66.32875061, 18.48125076],
              [-66.32847595, 18.48125076],
              [-66.32847595, 18.48152733],
              [-66.32763672, 18.48152733],
              [-66.32763672, 18.48125076],
              [-66.32736206, 18.48125076],
              [-66.32736206, 18.48069382],
              [-66.32707977, 18.48069382],
              [-66.32707977, 18.48041725],
              [-66.32680511, 18.48041725],
              [-66.32680511, 18.48013878],
              [-66.32597351, 18.48013878],
              [-66.32597351, 18.47986031],
              [-66.32541656, 18.47986031],
              [-66.32541656, 18.4790287],
              [-66.32569122, 18.4790287],
              [-66.32569122, 18.47791672],
              [-66.32514191, 18.47791672],
              [-66.32514191, 18.47763824],
              [-66.32375336, 18.47763824],
              [-66.32375336, 18.47791672],
              [-66.32347107, 18.47791672],
              [-66.32347107, 18.47875023],
              [-66.32319641, 18.47875023],
              [-66.32319641, 18.4790287],
              [-66.32291412, 18.4790287],
              [-66.32291412, 18.47930527],
              [-66.32236481, 18.47930527],
              [-66.32236481, 18.47958374],
              [-66.32152557, 18.47958374],
              [-66.32152557, 18.47930527],
              [-66.32125092, 18.47930527],
              [-66.32125092, 18.47847176],
              [-66.32096863, 18.47847176],
              [-66.32096863, 18.47819519],
              [-66.32125092, 18.47819519],
              [-66.32125092, 18.47736168],
              [-66.32152557, 18.47736168],
              [-66.32152557, 18.47624969],
              [-66.32125092, 18.47624969],
              [-66.32125092, 18.47597313],
              [-66.32096863, 18.47597313],
              [-66.32096863, 18.47569466],
              [-66.31903076, 18.47569466],
              [-66.31903076, 18.47597313],
              [-66.31819153, 18.47597313],
              [-66.31819153, 18.47624969],
              [-66.31735992, 18.47624969],
              [-66.31735992, 18.47652817],
              [-66.31652832, 18.47652817],
              [-66.31652832, 18.47597313],
              [-66.31625366, 18.47597313],
              [-66.31625366, 18.47486115],
              [-66.31597137, 18.47486115],
              [-66.31597137, 18.47458458],
              [-66.31490326, 18.47458458],
              [-66.31568909, 18.46880341],
              [-66.31703949, 18.45736694],
              [-66.31702423, 18.4540329],
              [-66.31747437, 18.45022011],
              [-66.3132782, 18.441185],
              [-66.31234741, 18.43928528],
              [-66.30862427, 18.43357849],
              [-66.30213928, 18.43407631],
              [-66.30212402, 18.42883682],
              [-66.30025482, 18.4255085],
              [-66.29978943, 18.42312431],
              [-66.2997818, 18.42074394],
              [-66.30069733, 18.41835976],
              [-66.30162048, 18.41788101],
              [-66.30208588, 18.41740417],
              [-66.30392456, 18.41120338],
              [-66.30389404, 18.4031086],
              [-66.30388641, 18.4007225],
              [-66.30383301, 18.38404465],
              [-66.30522156, 18.38404083],
              [-66.30846405, 18.38307953],
              [-66.31401062, 18.38067627],
              [-66.31447601, 18.38019753],
              [-66.31677246, 18.3749485],
              [-66.31907654, 18.37160301],
              [-66.31859589, 18.36731529],
              [-66.32971191, 18.36775398],
              [-66.3334198, 18.36821747],
              [-66.3361969, 18.36820793],
              [-66.33805084, 18.36820221],
              [-66.33850861, 18.36772346],
              [-66.33896637, 18.36724663],
              [-66.33943176, 18.36629105],
              [-66.34126282, 18.3619976],
              [-66.34125519, 18.36008835],
              [-66.3403244, 18.35818863],
              [-66.34078979, 18.35770607],
              [-66.34403229, 18.35769844],
              [-66.34680939, 18.36006546],
              [-66.35005951, 18.36100578],
              [-66.353302, 18.36146927],
              [-66.35469055, 18.36098862],
              [-66.35514832, 18.36051559],
              [-66.35514832, 18.35956001],
              [-66.35513306, 18.3567009],
              [-66.35513306, 18.35574913],
              [-66.35466766, 18.35479736],
              [-66.35095215, 18.35338211],
              [-66.34909821, 18.35148239],
              [-66.34863281, 18.35053062],
              [-66.34909058, 18.34957886],
              [-66.35140228, 18.34908867],
              [-66.35186768, 18.34861183],
              [-66.3532486, 18.34765434],
              [-66.35601807, 18.34573746],
              [-66.35648346, 18.34478188],
              [-66.35739899, 18.34382439],
              [-66.35785675, 18.34144402],
              [-66.35736847, 18.3347683],
              [-66.35874176, 18.33047485],
              [-66.36523437, 18.33330917],
              [-66.36569214, 18.3337822],
              [-66.37033844, 18.33710289],
              [-66.37126923, 18.33805275],
              [-66.37127686, 18.34043694],
              [-66.37730408, 18.34422493],
              [-66.37595367, 18.35471344],
              [-66.37319183, 18.35758209],
              [-66.37273407, 18.35806084],
              [-66.3727417, 18.36091995],
              [-66.37323761, 18.36949539],
              [-66.3695755, 18.38142395],
              [-66.36450958, 18.38858986],
              [-66.36405182, 18.39002228],
              [-66.36268616, 18.39526939],
              [-66.3599472, 18.40766525],
              [-66.35861206, 18.42100906],
              [-66.35676575, 18.42340469],
              [-66.35399628, 18.42531776],
              [-66.35260773, 18.42627525],
              [-66.35029602, 18.42676163],
              [-66.35030365, 18.42961693],
              [-66.35081482, 18.44295692],
              [-66.34851837, 18.44868088],
              [-66.348526, 18.45153618],
              [-66.34954071, 18.47534752],
              [-66.34954834, 18.47725677],
              [-66.34955597, 18.47963333],
              [-66.34958649, 18.48347282],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 73,
        name: 'Vega Alta',
        hasc_1: 'PR.VA',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.37730408, 18.33047485, -66.2997818, 18.4865284],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.74',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.34958649, 18.48347282],
              [-66.34955597, 18.47963333],
              [-66.34954834, 18.47725677],
              [-66.34954071, 18.47534752],
              [-66.348526, 18.45153618],
              [-66.34851837, 18.44868088],
              [-66.35081482, 18.44295692],
              [-66.35030365, 18.42961693],
              [-66.35029602, 18.42676163],
              [-66.35260773, 18.42627525],
              [-66.35399628, 18.42531776],
              [-66.35676575, 18.42340469],
              [-66.35861206, 18.42100906],
              [-66.3599472, 18.40766525],
              [-66.36268616, 18.39526939],
              [-66.36405182, 18.39002228],
              [-66.36450958, 18.38858986],
              [-66.3695755, 18.38142395],
              [-66.37323761, 18.36949539],
              [-66.3727417, 18.36091995],
              [-66.37273407, 18.35806084],
              [-66.37319183, 18.35758209],
              [-66.37595367, 18.35471344],
              [-66.37730408, 18.34422493],
              [-66.37915039, 18.34278679],
              [-66.3814621, 18.34229851],
              [-66.38610077, 18.34466171],
              [-66.38703918, 18.34751701],
              [-66.38891602, 18.35322762],
              [-66.38894653, 18.36180496],
              [-66.38895416, 18.36275864],
              [-66.39081573, 18.36608505],
              [-66.39128113, 18.36655998],
              [-66.39546967, 18.3717823],
              [-66.40099335, 18.36365891],
              [-66.40516663, 18.36602211],
              [-66.41492462, 18.37217712],
              [-66.41585541, 18.37312508],
              [-66.41725159, 18.37454796],
              [-66.41679382, 18.37741089],
              [-66.41819, 18.3778801],
              [-66.4214325, 18.37786293],
              [-66.42420197, 18.37594795],
              [-66.42787933, 18.37116241],
              [-66.42880249, 18.37068367],
              [-66.4440918, 18.37108803],
              [-66.44504547, 18.37727737],
              [-66.44551849, 18.38013458],
              [-66.4469223, 18.38346481],
              [-66.4487915, 18.38726616],
              [-66.44879913, 18.3891716],
              [-66.448349, 18.39155769],
              [-66.44191742, 18.40206528],
              [-66.44008636, 18.40684319],
              [-66.4396286, 18.40970421],
              [-66.44018555, 18.42971039],
              [-66.44023132, 18.43971443],
              [-66.43980408, 18.44781494],
              [-66.43981934, 18.45115089],
              [-66.43940735, 18.46210861],
              [-66.43858337, 18.48592567],
              [-66.43875122, 18.48625183],
              [-66.43819427, 18.48625183],
              [-66.43819427, 18.4865284],
              [-66.43736267, 18.4865284],
              [-66.43736267, 18.48680496],
              [-66.43569183, 18.48680496],
              [-66.43569183, 18.48708344],
              [-66.43514252, 18.48708344],
              [-66.43514252, 18.48736382],
              [-66.43402863, 18.48736382],
              [-66.43402863, 18.48708344],
              [-66.43319702, 18.48708344],
              [-66.43319702, 18.48736382],
              [-66.43208313, 18.48736382],
              [-66.43208313, 18.48763847],
              [-66.42958069, 18.48763847],
              [-66.42958069, 18.48791695],
              [-66.42791748, 18.48791695],
              [-66.42791748, 18.48819351],
              [-66.42597198, 18.48819351],
              [-66.42597198, 18.48847389],
              [-66.42514038, 18.48847389],
              [-66.42514038, 18.48875046],
              [-66.42346954, 18.48875046],
              [-66.42346954, 18.48847389],
              [-66.42236328, 18.48847389],
              [-66.42236328, 18.48819351],
              [-66.42069244, 18.48819351],
              [-66.42069244, 18.48847389],
              [-66.41958618, 18.48847389],
              [-66.41958618, 18.48819351],
              [-66.41625214, 18.48819351],
              [-66.41625214, 18.48847389],
              [-66.41513824, 18.48847389],
              [-66.41513824, 18.48875046],
              [-66.41430664, 18.48875046],
              [-66.41430664, 18.48902702],
              [-66.41374969, 18.48902702],
              [-66.41374969, 18.4893055],
              [-66.41347504, 18.4893055],
              [-66.41347504, 18.48958588],
              [-66.4109726, 18.48958588],
              [-66.4109726, 18.48986053],
              [-66.40930176, 18.48986053],
              [-66.40930176, 18.48958588],
              [-66.40875244, 18.48958588],
              [-66.40875244, 18.4893055],
              [-66.40736389, 18.4893055],
              [-66.40736389, 18.48902702],
              [-66.40625, 18.48902702],
              [-66.40625, 18.48875046],
              [-66.4034729, 18.48875046],
              [-66.4034729, 18.48902702],
              [-66.4026413, 18.48902702],
              [-66.4026413, 18.4893055],
              [-66.4015274, 18.4893055],
              [-66.4015274, 18.48958588],
              [-66.40097046, 18.48958588],
              [-66.40097046, 18.48986053],
              [-66.4006958, 18.48986053],
              [-66.4006958, 18.49013901],
              [-66.40013885, 18.49013901],
              [-66.40013885, 18.49041748],
              [-66.3998642, 18.49041748],
              [-66.3998642, 18.49069595],
              [-66.39930725, 18.49069595],
              [-66.39930725, 18.49097252],
              [-66.39902496, 18.49097252],
              [-66.39902496, 18.49124908],
              [-66.39875031, 18.49124908],
              [-66.39875031, 18.49152756],
              [-66.39847565, 18.49152756],
              [-66.39847565, 18.49180412],
              [-66.39819336, 18.49180412],
              [-66.39819336, 18.4920826],
              [-66.3979187, 18.4920826],
              [-66.3979187, 18.49236107],
              [-66.39763641, 18.49236107],
              [-66.39763641, 18.49319458],
              [-66.40097046, 18.49319458],
              [-66.40097046, 18.49347305],
              [-66.4015274, 18.49347305],
              [-66.4015274, 18.49374962],
              [-66.40125275, 18.49374962],
              [-66.40125275, 18.49430466],
              [-66.39930725, 18.49430466],
              [-66.39930725, 18.49402618],
              [-66.3970871, 18.49402618],
              [-66.3970871, 18.49374962],
              [-66.39680481, 18.49374962],
              [-66.39680481, 18.49347305],
              [-66.39291382, 18.49347305],
              [-66.39291382, 18.49263954],
              [-66.39624786, 18.49263954],
              [-66.39624786, 18.4920826],
              [-66.39597321, 18.4920826],
              [-66.39597321, 18.49152756],
              [-66.39569092, 18.49152756],
              [-66.39569092, 18.49097252],
              [-66.39541626, 18.49097252],
              [-66.39541626, 18.49041748],
              [-66.39569092, 18.49041748],
              [-66.39569092, 18.49013901],
              [-66.39541626, 18.49013901],
              [-66.39541626, 18.48986053],
              [-66.3951416, 18.48986053],
              [-66.3951416, 18.48958588],
              [-66.39458466, 18.48958588],
              [-66.39458466, 18.4893055],
              [-66.39430237, 18.4893055],
              [-66.39430237, 18.48902702],
              [-66.39375305, 18.48902702],
              [-66.39375305, 18.48875046],
              [-66.39180756, 18.48875046],
              [-66.39180756, 18.48847389],
              [-66.39125061, 18.48847389],
              [-66.39125061, 18.48819351],
              [-66.38957977, 18.48819351],
              [-66.38957977, 18.48791695],
              [-66.38847351, 18.48791695],
              [-66.38847351, 18.48763847],
              [-66.38791656, 18.48763847],
              [-66.38791656, 18.48736382],
              [-66.38735962, 18.48736382],
              [-66.38735962, 18.48708344],
              [-66.38652802, 18.48708344],
              [-66.38652802, 18.48680496],
              [-66.38597107, 18.48680496],
              [-66.38597107, 18.4865284],
              [-66.38569641, 18.4865284],
              [-66.38569641, 18.48625183],
              [-66.38541412, 18.48625183],
              [-66.38541412, 18.48569489],
              [-66.38513947, 18.48569489],
              [-66.38513947, 18.48541641],
              [-66.38486481, 18.48541641],
              [-66.38486481, 18.48513794],
              [-66.38402557, 18.48513794],
              [-66.38402557, 18.48485947],
              [-66.38375092, 18.48485947],
              [-66.38375092, 18.4845829],
              [-66.38346863, 18.4845829],
              [-66.38346863, 18.48430634],
              [-66.38319397, 18.48430634],
              [-66.38319397, 18.48402977],
              [-66.38291931, 18.48402977],
              [-66.38291931, 18.48374939],
              [-66.38236237, 18.48374939],
              [-66.38236237, 18.48347282],
              [-66.38208008, 18.48347282],
              [-66.38208008, 18.48319435],
              [-66.38153076, 18.48319435],
              [-66.38153076, 18.48291588],
              [-66.38041687, 18.48291588],
              [-66.38041687, 18.48319435],
              [-66.38014221, 18.48319435],
              [-66.38014221, 18.48347282],
              [-66.37958527, 18.48347282],
              [-66.37958527, 18.48374939],
              [-66.37902832, 18.48374939],
              [-66.37902832, 18.48402977],
              [-66.37875366, 18.48402977],
              [-66.37875366, 18.48430634],
              [-66.37847137, 18.48430634],
              [-66.37847137, 18.48485947],
              [-66.37819672, 18.48485947],
              [-66.37819672, 18.48513794],
              [-66.37847137, 18.48513794],
              [-66.37847137, 18.48569489],
              [-66.37875366, 18.48569489],
              [-66.37875366, 18.48596954],
              [-66.37930298, 18.48596954],
              [-66.37930298, 18.48736382],
              [-66.37875366, 18.48736382],
              [-66.37875366, 18.48791695],
              [-66.37847137, 18.48791695],
              [-66.37847137, 18.48819351],
              [-66.37819672, 18.48819351],
              [-66.37819672, 18.48847389],
              [-66.37763977, 18.48847389],
              [-66.37763977, 18.48819351],
              [-66.37708282, 18.48819351],
              [-66.37708282, 18.48791695],
              [-66.37652588, 18.48791695],
              [-66.37652588, 18.48763847],
              [-66.37374878, 18.48763847],
              [-66.37374878, 18.48736382],
              [-66.37236023, 18.48736382],
              [-66.37236023, 18.48708344],
              [-66.37180328, 18.48708344],
              [-66.37180328, 18.48680496],
              [-66.37124634, 18.48680496],
              [-66.37124634, 18.4865284],
              [-66.37069702, 18.4865284],
              [-66.37069702, 18.48625183],
              [-66.36985779, 18.48625183],
              [-66.36985779, 18.48596954],
              [-66.36846924, 18.48596954],
              [-66.36846924, 18.48569489],
              [-66.36763763, 18.48569489],
              [-66.36763763, 18.48541641],
              [-66.36680603, 18.48541641],
              [-66.36680603, 18.48513794],
              [-66.36624908, 18.48513794],
              [-66.36624908, 18.48485947],
              [-66.36513519, 18.48485947],
              [-66.36513519, 18.4845829],
              [-66.36319733, 18.4845829],
              [-66.36319733, 18.48485947],
              [-66.36180878, 18.48485947],
              [-66.36180878, 18.48513794],
              [-66.36152649, 18.48513794],
              [-66.36152649, 18.48541641],
              [-66.36096954, 18.48541641],
              [-66.36096954, 18.48569489],
              [-66.36069489, 18.48569489],
              [-66.36069489, 18.48596954],
              [-66.36042023, 18.48596954],
              [-66.36042023, 18.48625183],
              [-66.35902405, 18.48625183],
              [-66.35902405, 18.48596954],
              [-66.35708618, 18.48596954],
              [-66.35708618, 18.48569489],
              [-66.35624695, 18.48569489],
              [-66.35624695, 18.48596954],
              [-66.35514069, 18.48596954],
              [-66.35514069, 18.48569489],
              [-66.35458374, 18.48569489],
              [-66.35458374, 18.48541641],
              [-66.35375214, 18.48541641],
              [-66.35375214, 18.48513794],
              [-66.3529129, 18.48513794],
              [-66.3529129, 18.48485947],
              [-66.35236359, 18.48485947],
              [-66.35236359, 18.4845829],
              [-66.35124969, 18.4845829],
              [-66.35124969, 18.48430634],
              [-66.35069275, 18.48430634],
              [-66.35069275, 18.48402977],
              [-66.3501358, 18.48402977],
              [-66.3501358, 18.48374939],
              [-66.34958649, 18.48374939],
              [-66.34958649, 18.48347282],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 74,
        name: 'Vega Baja',
        hasc_1: 'PR.VB',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.44879913, 18.34229851, -66.34851837, 18.49430466],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.75',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-65.43347168, 18.09152794],
              [-65.43819427, 18.09152794],
              [-65.43819427, 18.09208298],
              [-65.43846893, 18.09208298],
              [-65.43846893, 18.09263611],
              [-65.43875122, 18.09263611],
              [-65.43875122, 18.09319687],
              [-65.43958282, 18.09319687],
              [-65.43958282, 18.09347153],
              [-65.43985748, 18.09347153],
              [-65.43985748, 18.09458351],
              [-65.43958282, 18.09458351],
              [-65.43958282, 18.09486198],
              [-65.43930817, 18.09486198],
              [-65.43930817, 18.09569359],
              [-65.43902588, 18.09569359],
              [-65.43902588, 18.09791756],
              [-65.43930817, 18.09791756],
              [-65.43930817, 18.0984726],
              [-65.43958282, 18.0984726],
              [-65.43958282, 18.09930611],
              [-65.43985748, 18.09930611],
              [-65.43985748, 18.09986115],
              [-65.44013977, 18.09986115],
              [-65.44013977, 18.10013962],
              [-65.44041443, 18.10013962],
              [-65.44041443, 18.10041618],
              [-65.44097137, 18.10041618],
              [-65.44097137, 18.10069466],
              [-65.44013977, 18.10069466],
              [-65.44013977, 18.10097313],
              [-65.43958282, 18.10097313],
              [-65.43958282, 18.10208321],
              [-65.43930817, 18.10208321],
              [-65.43930817, 18.10347176],
              [-65.43958282, 18.10347176],
              [-65.43958282, 18.10375023],
              [-65.43985748, 18.10375023],
              [-65.43985748, 18.1040287],
              [-65.44152832, 18.1040287],
              [-65.44152832, 18.10375023],
              [-65.44208527, 18.10375023],
              [-65.44208527, 18.1040287],
              [-65.44291687, 18.1040287],
              [-65.44291687, 18.10430527],
              [-65.44347382, 18.10430527],
              [-65.44347382, 18.10458374],
              [-65.44403076, 18.10458374],
              [-65.44403076, 18.10486031],
              [-65.44541931, 18.10486031],
              [-65.44541931, 18.10513878],
              [-65.44596863, 18.10513878],
              [-65.44596863, 18.10541534],
              [-65.44708252, 18.10541534],
              [-65.44708252, 18.10513878],
              [-65.44763947, 18.10513878],
              [-65.44763947, 18.10486031],
              [-65.44930267, 18.10486031],
              [-65.44930267, 18.10458374],
              [-65.44958496, 18.10458374],
              [-65.44958496, 18.10430527],
              [-65.45041656, 18.10430527],
              [-65.45041656, 18.1040287],
              [-65.45069122, 18.1040287],
              [-65.45069122, 18.10375023],
              [-65.45097351, 18.10375023],
              [-65.45097351, 18.10069466],
              [-65.45069122, 18.10069466],
              [-65.45069122, 18.10041618],
              [-65.45041656, 18.10041618],
              [-65.45041656, 18.10013962],
              [-65.44958496, 18.10013962],
              [-65.44958496, 18.09986115],
              [-65.44902802, 18.09986115],
              [-65.44902802, 18.10013962],
              [-65.44819641, 18.10013962],
              [-65.44819641, 18.09986115],
              [-65.44625092, 18.09986115],
              [-65.44625092, 18.09958267],
              [-65.44513702, 18.09958267],
              [-65.44513702, 18.09930611],
              [-65.44235992, 18.09930611],
              [-65.44235992, 18.09902763],
              [-65.44180298, 18.09902763],
              [-65.44180298, 18.09874916],
              [-65.44152832, 18.09874916],
              [-65.44152832, 18.0984726],
              [-65.44069672, 18.0984726],
              [-65.44069672, 18.09819412],
              [-65.44013977, 18.09819412],
              [-65.44013977, 18.09680557],
              [-65.44041443, 18.09680557],
              [-65.44041443, 18.09569359],
              [-65.44097137, 18.09569359],
              [-65.44097137, 18.09541893],
              [-65.44125366, 18.09541893],
              [-65.44125366, 18.09514046],
              [-65.44152832, 18.09514046],
              [-65.44152832, 18.09486198],
              [-65.44180298, 18.09486198],
              [-65.44180298, 18.09430504],
              [-65.44235992, 18.09430504],
              [-65.44235992, 18.09236145],
              [-65.44264221, 18.09236145],
              [-65.44264221, 18.09208298],
              [-65.44458008, 18.09208298],
              [-65.44458008, 18.09152794],
              [-65.44513702, 18.09152794],
              [-65.44513702, 18.0909729],
              [-65.44486237, 18.0909729],
              [-65.44486237, 18.08930588],
              [-65.44513702, 18.08930588],
              [-65.44513702, 18.0890274],
              [-65.44596863, 18.0890274],
              [-65.44596863, 18.08930588],
              [-65.44625092, 18.08930588],
              [-65.44625092, 18.0890274],
              [-65.44652557, 18.0890274],
              [-65.44652557, 18.08875084],
              [-65.44680786, 18.08875084],
              [-65.44680786, 18.08819389],
              [-65.44708252, 18.08819389],
              [-65.44708252, 18.08763885],
              [-65.44763947, 18.08763885],
              [-65.44763947, 18.08736038],
              [-65.44819641, 18.08736038],
              [-65.44819641, 18.08708382],
              [-65.44902802, 18.08708382],
              [-65.44902802, 18.08847237],
              [-65.44930267, 18.08847237],
              [-65.44930267, 18.08875084],
              [-65.44902802, 18.08875084],
              [-65.44902802, 18.09013939],
              [-65.44930267, 18.09013939],
              [-65.44930267, 18.09069443],
              [-65.44985962, 18.09069443],
              [-65.44985962, 18.0909729],
              [-65.45069122, 18.0909729],
              [-65.45069122, 18.09069443],
              [-65.45097351, 18.09069443],
              [-65.45097351, 18.09041405],
              [-65.45124817, 18.09041405],
              [-65.45124817, 18.08986092],
              [-65.45097351, 18.08986092],
              [-65.45097351, 18.08847237],
              [-65.45069122, 18.08847237],
              [-65.45069122, 18.08652687],
              [-65.45097351, 18.08652687],
              [-65.45097351, 18.08597374],
              [-65.45180511, 18.08597374],
              [-65.45180511, 18.08652687],
              [-65.45347595, 18.08652687],
              [-65.45347595, 18.08680534],
              [-65.45375061, 18.08680534],
              [-65.45375061, 18.08708382],
              [-65.45569611, 18.08708382],
              [-65.45569611, 18.08736038],
              [-65.45652771, 18.08736038],
              [-65.45652771, 18.08763885],
              [-65.45680237, 18.08763885],
              [-65.45680237, 18.08791733],
              [-65.45708466, 18.08791733],
              [-65.45708466, 18.08847237],
              [-65.45680237, 18.08847237],
              [-65.45680237, 18.08875084],
              [-65.45652771, 18.08875084],
              [-65.45652771, 18.0890274],
              [-65.45625305, 18.0890274],
              [-65.45625305, 18.08930588],
              [-65.45597076, 18.08930588],
              [-65.45597076, 18.08958244],
              [-65.45541382, 18.08958244],
              [-65.45541382, 18.08986092],
              [-65.45513916, 18.08986092],
              [-65.45513916, 18.09069443],
              [-65.4548645, 18.09069443],
              [-65.4548645, 18.09180832],
              [-65.45458221, 18.09180832],
              [-65.45458221, 18.09236145],
              [-65.45430756, 18.09236145],
              [-65.45430756, 18.09430504],
              [-65.4548645, 18.09430504],
              [-65.4548645, 18.09458351],
              [-65.45513916, 18.09458351],
              [-65.45513916, 18.09486198],
              [-65.45597076, 18.09486198],
              [-65.45597076, 18.09541893],
              [-65.45625305, 18.09541893],
              [-65.45625305, 18.09569359],
              [-65.45652771, 18.09569359],
              [-65.45652771, 18.09597206],
              [-65.45791626, 18.09597206],
              [-65.45791626, 18.09625053],
              [-65.45847321, 18.09625053],
              [-65.45847321, 18.0965271],
              [-65.4604187, 18.0965271],
              [-65.4604187, 18.09680557],
              [-65.4640274, 18.09680557],
              [-65.4640274, 18.0965271],
              [-65.46458435, 18.0965271],
              [-65.46458435, 18.09625053],
              [-65.46541595, 18.09625053],
              [-65.46541595, 18.09597206],
              [-65.46569824, 18.09597206],
              [-65.46569824, 18.09569359],
              [-65.4659729, 18.09569359],
              [-65.4659729, 18.09514046],
              [-65.46652985, 18.09514046],
              [-65.46652985, 18.09458351],
              [-65.4668045, 18.09458351],
              [-65.4668045, 18.09430504],
              [-65.46708679, 18.09430504],
              [-65.46708679, 18.09402657],
              [-65.46763611, 18.09402657],
              [-65.46763611, 18.09291649],
              [-65.46736145, 18.09291649],
              [-65.46736145, 18.09208298],
              [-65.46708679, 18.09208298],
              [-65.46708679, 18.09180832],
              [-65.46652985, 18.09180832],
              [-65.46652985, 18.09124947],
              [-65.4659729, 18.09124947],
              [-65.4659729, 18.0909729],
              [-65.46569824, 18.0909729],
              [-65.46569824, 18.09041405],
              [-65.46541595, 18.09041405],
              [-65.46541595, 18.08986092],
              [-65.4651413, 18.08986092],
              [-65.4651413, 18.08819389],
              [-65.46541595, 18.08819389],
              [-65.46541595, 18.08763885],
              [-65.46569824, 18.08763885],
              [-65.46569824, 18.08736038],
              [-65.4659729, 18.08736038],
              [-65.4659729, 18.08708382],
              [-65.46736145, 18.08708382],
              [-65.46736145, 18.08763885],
              [-65.46763611, 18.08763885],
              [-65.46763611, 18.08819389],
              [-65.46819305, 18.08819389],
              [-65.46819305, 18.08847237],
              [-65.46875, 18.08847237],
              [-65.46875, 18.0890274],
              [-65.46902466, 18.0890274],
              [-65.46902466, 18.08930588],
              [-65.46930695, 18.08930588],
              [-65.46930695, 18.09069443],
              [-65.46902466, 18.09069443],
              [-65.46902466, 18.0909729],
              [-65.46847534, 18.0909729],
              [-65.46847534, 18.09208298],
              [-65.46875, 18.09208298],
              [-65.46875, 18.09291649],
              [-65.46986389, 18.09291649],
              [-65.46986389, 18.09319687],
              [-65.47125244, 18.09319687],
              [-65.47125244, 18.09374809],
              [-65.47180176, 18.09374809],
              [-65.47180176, 18.09402657],
              [-65.4723587, 18.09402657],
              [-65.4723587, 18.09430504],
              [-65.47319794, 18.09430504],
              [-65.47319794, 18.09458351],
              [-65.47374725, 18.09458351],
              [-65.47374725, 18.09486198],
              [-65.47486115, 18.09486198],
              [-65.47486115, 18.09514046],
              [-65.47736359, 18.09514046],
              [-65.47736359, 18.09541893],
              [-65.4779129, 18.09541893],
              [-65.4779129, 18.09569359],
              [-65.47819519, 18.09569359],
              [-65.47819519, 18.09597206],
              [-65.47846985, 18.09597206],
              [-65.47846985, 18.09569359],
              [-65.47875214, 18.09569359],
              [-65.47875214, 18.09597206],
              [-65.47930908, 18.09597206],
              [-65.47930908, 18.09625053],
              [-65.48041534, 18.09625053],
              [-65.48041534, 18.09597206],
              [-65.48124695, 18.09597206],
              [-65.48124695, 18.09569359],
              [-65.48208618, 18.09569359],
              [-65.48208618, 18.09541893],
              [-65.48236084, 18.09541893],
              [-65.48236084, 18.09514046],
              [-65.48374939, 18.09514046],
              [-65.48374939, 18.09486198],
              [-65.48458099, 18.09486198],
              [-65.48458099, 18.09458351],
              [-65.48486328, 18.09458351],
              [-65.48486328, 18.09430504],
              [-65.48596954, 18.09430504],
              [-65.48596954, 18.09402657],
              [-65.48708344, 18.09402657],
              [-65.48708344, 18.09374809],
              [-65.48819733, 18.09374809],
              [-65.48819733, 18.09347153],
              [-65.48847198, 18.09347153],
              [-65.48847198, 18.09319687],
              [-65.48902893, 18.09319687],
              [-65.48902893, 18.09347153],
              [-65.48958588, 18.09347153],
              [-65.48958588, 18.09402657],
              [-65.48986053, 18.09402657],
              [-65.48986053, 18.09458351],
              [-65.49013519, 18.09458351],
              [-65.49013519, 18.09486198],
              [-65.49041748, 18.09486198],
              [-65.49041748, 18.09514046],
              [-65.49263763, 18.09514046],
              [-65.49263763, 18.09486198],
              [-65.49319458, 18.09486198],
              [-65.49319458, 18.09458351],
              [-65.49375153, 18.09458351],
              [-65.49375153, 18.09430504],
              [-65.49458313, 18.09430504],
              [-65.49458313, 18.09402657],
              [-65.49514008, 18.09402657],
              [-65.49514008, 18.09374809],
              [-65.49541473, 18.09374809],
              [-65.49541473, 18.09347153],
              [-65.49764252, 18.09347153],
              [-65.49764252, 18.09319687],
              [-65.50235748, 18.09319687],
              [-65.50235748, 18.09291649],
              [-65.50319672, 18.09291649],
              [-65.50319672, 18.09263611],
              [-65.50485992, 18.09263611],
              [-65.50485992, 18.09208298],
              [-65.50597382, 18.09208298],
              [-65.50597382, 18.09180832],
              [-65.50680542, 18.09180832],
              [-65.50680542, 18.09152794],
              [-65.50708008, 18.09152794],
              [-65.50708008, 18.09124947],
              [-65.50763702, 18.09124947],
              [-65.50763702, 18.0909729],
              [-65.50930786, 18.0909729],
              [-65.50930786, 18.09069443],
              [-65.51097107, 18.09069443],
              [-65.51097107, 18.09013939],
              [-65.51125336, 18.09013939],
              [-65.51125336, 18.08986092],
              [-65.51152802, 18.08986092],
              [-65.51152802, 18.08958244],
              [-65.51180267, 18.08958244],
              [-65.51180267, 18.08930588],
              [-65.51291656, 18.08930588],
              [-65.51291656, 18.0890274],
              [-65.51374817, 18.0890274],
              [-65.51374817, 18.08875084],
              [-65.51457977, 18.08875084],
              [-65.51457977, 18.08847237],
              [-65.51486206, 18.08847237],
              [-65.51486206, 18.08791733],
              [-65.51513672, 18.08791733],
              [-65.51513672, 18.08763885],
              [-65.51569366, 18.08763885],
              [-65.51569366, 18.08736038],
              [-65.51680756, 18.08736038],
              [-65.51680756, 18.08708382],
              [-65.51708221, 18.08708382],
              [-65.51708221, 18.08680534],
              [-65.5173645, 18.08680534],
              [-65.5173645, 18.08652687],
              [-65.51763916, 18.08652687],
              [-65.51763916, 18.08625031],
              [-65.51819611, 18.08625031],
              [-65.51819611, 18.08597374],
              [-65.51875305, 18.08597374],
              [-65.51875305, 18.08569527],
              [-65.51902771, 18.08569527],
              [-65.51902771, 18.08513832],
              [-65.51930237, 18.08513832],
              [-65.51930237, 18.08486176],
              [-65.51958466, 18.08486176],
              [-65.51958466, 18.08430481],
              [-65.51985931, 18.08430481],
              [-65.51985931, 18.08402824],
              [-65.52041626, 18.08402824],
              [-65.52041626, 18.08375168],
              [-65.52069092, 18.08375168],
              [-65.52069092, 18.08346939],
              [-65.52097321, 18.08346939],
              [-65.52097321, 18.08319473],
              [-65.52153015, 18.08319473],
              [-65.52153015, 18.08291626],
              [-65.5220871, 18.08291626],
              [-65.5220871, 18.08263969],
              [-65.5229187, 18.08263969],
              [-65.5229187, 18.08236122],
              [-65.52375031, 18.08236122],
              [-65.52375031, 18.08208275],
              [-65.52402496, 18.08208275],
              [-65.52402496, 18.08180618],
              [-65.5248642, 18.08180618],
              [-65.5248642, 18.08124733],
              [-65.5304184, 18.08124733],
              [-65.5304184, 18.08152962],
              [-65.53069305, 18.08152962],
              [-65.53069305, 18.08097267],
              [-65.53125, 18.08097267],
              [-65.53125, 18.07986069],
              [-65.53347015, 18.07986069],
              [-65.53347015, 18.08013725],
              [-65.53375244, 18.08013725],
              [-65.53375244, 18.08097267],
              [-65.5340271, 18.08097267],
              [-65.5340271, 18.08124733],
              [-65.53458405, 18.08124733],
              [-65.53458405, 18.08152962],
              [-65.5348587, 18.08152962],
              [-65.5348587, 18.08180618],
              [-65.53847504, 18.08180618],
              [-65.53847504, 18.08152962],
              [-65.53902435, 18.08152962],
              [-65.53902435, 18.08124733],
              [-65.53930664, 18.08124733],
              [-65.53930664, 18.08097267],
              [-65.54264069, 18.08097267],
              [-65.54264069, 18.08124733],
              [-65.54319763, 18.08124733],
              [-65.54319763, 18.08152962],
              [-65.54430389, 18.08152962],
              [-65.54430389, 18.08180618],
              [-65.54486084, 18.08180618],
              [-65.54486084, 18.08208275],
              [-65.54624939, 18.08208275],
              [-65.54624939, 18.08236122],
              [-65.54652405, 18.08236122],
              [-65.54652405, 18.08263969],
              [-65.54708099, 18.08263969],
              [-65.54708099, 18.08291626],
              [-65.54846954, 18.08291626],
              [-65.54846954, 18.08319473],
              [-65.55014038, 18.08319473],
              [-65.55014038, 18.08346939],
              [-65.55041504, 18.08346939],
              [-65.55041504, 18.08375168],
              [-65.55069733, 18.08375168],
              [-65.55069733, 18.08402824],
              [-65.55124664, 18.08402824],
              [-65.55124664, 18.08430481],
              [-65.55319214, 18.08430481],
              [-65.55319214, 18.08458328],
              [-65.55403137, 18.08458328],
              [-65.55403137, 18.08486176],
              [-65.55680847, 18.08486176],
              [-65.55680847, 18.08458328],
              [-65.55708313, 18.08458328],
              [-65.55708313, 18.08430481],
              [-65.55847168, 18.08430481],
              [-65.55847168, 18.08486176],
              [-65.55874634, 18.08486176],
              [-65.55874634, 18.08541679],
              [-65.55902863, 18.08541679],
              [-65.55902863, 18.08569527],
              [-65.55930328, 18.08569527],
              [-65.55930328, 18.08597374],
              [-65.55958557, 18.08597374],
              [-65.55958557, 18.08625031],
              [-65.55986023, 18.08625031],
              [-65.55986023, 18.08652687],
              [-65.56014252, 18.08652687],
              [-65.56014252, 18.08680534],
              [-65.56069183, 18.08680534],
              [-65.56069183, 18.08736038],
              [-65.56124878, 18.08736038],
              [-65.56124878, 18.08791733],
              [-65.56180573, 18.08791733],
              [-65.56180573, 18.08819389],
              [-65.56236267, 18.08819389],
              [-65.56236267, 18.08847237],
              [-65.56319427, 18.08847237],
              [-65.56319427, 18.08875084],
              [-65.56375122, 18.08875084],
              [-65.56375122, 18.0890274],
              [-65.56513977, 18.0890274],
              [-65.56513977, 18.08847237],
              [-65.56569672, 18.08847237],
              [-65.56569672, 18.08958244],
              [-65.56597137, 18.08958244],
              [-65.56597137, 18.08986092],
              [-65.56625366, 18.08986092],
              [-65.56625366, 18.09013939],
              [-65.56680298, 18.09013939],
              [-65.56680298, 18.09041405],
              [-65.56708527, 18.09041405],
              [-65.56708527, 18.09069443],
              [-65.56819153, 18.09069443],
              [-65.56819153, 18.0909729],
              [-65.56903076, 18.0909729],
              [-65.56903076, 18.09124947],
              [-65.56958008, 18.09124947],
              [-65.56958008, 18.09208298],
              [-65.56986237, 18.09208298],
              [-65.56986237, 18.09402657],
              [-65.57013702, 18.09402657],
              [-65.57013702, 18.09514046],
              [-65.57041931, 18.09514046],
              [-65.57041931, 18.09541893],
              [-65.57096863, 18.09541893],
              [-65.57096863, 18.09569359],
              [-65.57125092, 18.09569359],
              [-65.57125092, 18.09819412],
              [-65.57152557, 18.09819412],
              [-65.57152557, 18.0984726],
              [-65.57180786, 18.0984726],
              [-65.57180786, 18.09902763],
              [-65.57208252, 18.09902763],
              [-65.57208252, 18.09930611],
              [-65.57291412, 18.09930611],
              [-65.57291412, 18.09958267],
              [-65.57347107, 18.09958267],
              [-65.57347107, 18.10013962],
              [-65.57375336, 18.10013962],
              [-65.57375336, 18.10041618],
              [-65.57430267, 18.10041618],
              [-65.57430267, 18.10069466],
              [-65.57485962, 18.10069466],
              [-65.57485962, 18.10097313],
              [-65.57541656, 18.10097313],
              [-65.57541656, 18.10124969],
              [-65.57597351, 18.10124969],
              [-65.57597351, 18.10152817],
              [-65.57624817, 18.10152817],
              [-65.57624817, 18.10180473],
              [-65.57736206, 18.10180473],
              [-65.57736206, 18.10208321],
              [-65.57763672, 18.10208321],
              [-65.57763672, 18.10347176],
              [-65.57736206, 18.10347176],
              [-65.57736206, 18.10375023],
              [-65.57707977, 18.10375023],
              [-65.57707977, 18.1040287],
              [-65.57653046, 18.1040287],
              [-65.57653046, 18.10430527],
              [-65.57624817, 18.10430527],
              [-65.57624817, 18.10541534],
              [-65.57653046, 18.10541534],
              [-65.57653046, 18.10569382],
              [-65.57624817, 18.10569382],
              [-65.57624817, 18.10791588],
              [-65.57597351, 18.10791588],
              [-65.57597351, 18.10847282],
              [-65.57569122, 18.10847282],
              [-65.57569122, 18.10930634],
              [-65.57541656, 18.10930634],
              [-65.57541656, 18.11069489],
              [-65.57569122, 18.11069489],
              [-65.57569122, 18.1115284],
              [-65.57597351, 18.1115284],
              [-65.57597351, 18.11180496],
              [-65.57624817, 18.11180496],
              [-65.57624817, 18.11236382],
              [-65.57597351, 18.11236382],
              [-65.57597351, 18.11347389],
              [-65.57624817, 18.11347389],
              [-65.57624817, 18.11458206],
              [-65.57597351, 18.11458206],
              [-65.57597351, 18.11624908],
              [-65.57624817, 18.11624908],
              [-65.57624817, 18.11652756],
              [-65.57653046, 18.11652756],
              [-65.57653046, 18.11736107],
              [-65.57680511, 18.11736107],
              [-65.57680511, 18.1179142],
              [-65.57707977, 18.1179142],
              [-65.57707977, 18.11819458],
              [-65.57736206, 18.11819458],
              [-65.57736206, 18.11930466],
              [-65.57707977, 18.11930466],
              [-65.57707977, 18.11958313],
              [-65.57514191, 18.11958313],
              [-65.57514191, 18.11930466],
              [-65.57458496, 18.11930466],
              [-65.57458496, 18.11902618],
              [-65.57347107, 18.11902618],
              [-65.57347107, 18.11874962],
              [-65.57291412, 18.11874962],
              [-65.57291412, 18.11847305],
              [-65.57125092, 18.11847305],
              [-65.57125092, 18.11902618],
              [-65.57041931, 18.11902618],
              [-65.57041931, 18.11930466],
              [-65.56986237, 18.11930466],
              [-65.56986237, 18.11958313],
              [-65.56958008, 18.11958313],
              [-65.56958008, 18.1198616],
              [-65.56930542, 18.1198616],
              [-65.56930542, 18.12013626],
              [-65.56903076, 18.12013626],
              [-65.56903076, 18.12041855],
              [-65.56847382, 18.12041855],
              [-65.56847382, 18.12069511],
              [-65.56680298, 18.12069511],
              [-65.56680298, 18.12041855],
              [-65.56652832, 18.12041855],
              [-65.56652832, 18.12013626],
              [-65.56541443, 18.12013626],
              [-65.56541443, 18.1198616],
              [-65.56513977, 18.1198616],
              [-65.56513977, 18.11958313],
              [-65.56402588, 18.11958313],
              [-65.56402588, 18.1198616],
              [-65.56291962, 18.1198616],
              [-65.56291962, 18.11958313],
              [-65.56263733, 18.11958313],
              [-65.56263733, 18.1198616],
              [-65.56236267, 18.1198616],
              [-65.56236267, 18.12013626],
              [-65.56208038, 18.12013626],
              [-65.56208038, 18.12041855],
              [-65.56124878, 18.12041855],
              [-65.56124878, 18.12069511],
              [-65.56097412, 18.12069511],
              [-65.56097412, 18.12097168],
              [-65.55958557, 18.12097168],
              [-65.55958557, 18.12069511],
              [-65.55847168, 18.12069511],
              [-65.55847168, 18.12041855],
              [-65.55764008, 18.12041855],
              [-65.55764008, 18.12013626],
              [-65.55735779, 18.12013626],
              [-65.55735779, 18.1198616],
              [-65.55569458, 18.1198616],
              [-65.55569458, 18.12041855],
              [-65.55374908, 18.12041855],
              [-65.55374908, 18.12013626],
              [-65.55319214, 18.12013626],
              [-65.55319214, 18.1198616],
              [-65.55152893, 18.1198616],
              [-65.55152893, 18.11958313],
              [-65.55124664, 18.11958313],
              [-65.55124664, 18.11930466],
              [-65.54819489, 18.11930466],
              [-65.54819489, 18.11958313],
              [-65.54763794, 18.11958313],
              [-65.54763794, 18.1198616],
              [-65.54541779, 18.1198616],
              [-65.54541779, 18.12013626],
              [-65.54430389, 18.12013626],
              [-65.54430389, 18.12041855],
              [-65.54374695, 18.12041855],
              [-65.54374695, 18.12069511],
              [-65.5423584, 18.12069511],
              [-65.5423584, 18.12097168],
              [-65.54152679, 18.12097168],
              [-65.54152679, 18.12124825],
              [-65.5404129, 18.12124825],
              [-65.5404129, 18.12153053],
              [-65.54013824, 18.12153053],
              [-65.54013824, 18.12180519],
              [-65.53930664, 18.12180519],
              [-65.53930664, 18.12208366],
              [-65.53819275, 18.12208366],
              [-65.53819275, 18.12235832],
              [-65.53736115, 18.12235832],
              [-65.53736115, 18.12264061],
              [-65.53652954, 18.12264061],
              [-65.53652954, 18.12291718],
              [-65.53569794, 18.12291718],
              [-65.53569794, 18.12319374],
              [-65.53514099, 18.12319374],
              [-65.53514099, 18.12347221],
              [-65.5348587, 18.12347221],
              [-65.5348587, 18.12375069],
              [-65.53430176, 18.12375069],
              [-65.53430176, 18.12402725],
              [-65.53375244, 18.12402725],
              [-65.53375244, 18.12430573],
              [-65.53291321, 18.12430573],
              [-65.53291321, 18.1245842],
              [-65.53180695, 18.1245842],
              [-65.53180695, 18.12486267],
              [-65.53125, 18.12486267],
              [-65.53125, 18.12513924],
              [-65.53069305, 18.12513924],
              [-65.53069305, 18.1254158],
              [-65.53013611, 18.1254158],
              [-65.53013611, 18.12569427],
              [-65.52958679, 18.12569427],
              [-65.52958679, 18.12597275],
              [-65.5293045, 18.12597275],
              [-65.5293045, 18.12624931],
              [-65.52819824, 18.12624931],
              [-65.52819824, 18.12652779],
              [-65.52708435, 18.12652779],
              [-65.52708435, 18.12680626],
              [-65.52597046, 18.12680626],
              [-65.52597046, 18.12708282],
              [-65.5256958, 18.12708282],
              [-65.5256958, 18.1273613],
              [-65.52513885, 18.1273613],
              [-65.52513885, 18.12763977],
              [-65.52458191, 18.12763977],
              [-65.52458191, 18.12791634],
              [-65.52347565, 18.12791634],
              [-65.52347565, 18.12819481],
              [-65.52236176, 18.12819481],
              [-65.52236176, 18.12847137],
              [-65.52180481, 18.12847137],
              [-65.52180481, 18.12874985],
              [-65.51902771, 18.12874985],
              [-65.51902771, 18.12902832],
              [-65.51680756, 18.12902832],
              [-65.51680756, 18.12930489],
              [-65.51597595, 18.12930489],
              [-65.51597595, 18.12958336],
              [-65.51569366, 18.12958336],
              [-65.51569366, 18.12986183],
              [-65.51513672, 18.12986183],
              [-65.51513672, 18.1301384],
              [-65.51486206, 18.1301384],
              [-65.51486206, 18.13041687],
              [-65.51457977, 18.13041687],
              [-65.51457977, 18.13069534],
              [-65.51430511, 18.13069534],
              [-65.51430511, 18.13125038],
              [-65.51403046, 18.13125038],
              [-65.51403046, 18.13180542],
              [-65.51374817, 18.13180542],
              [-65.51374817, 18.13208389],
              [-65.51264191, 18.13208389],
              [-65.51264191, 18.13236046],
              [-65.51235962, 18.13236046],
              [-65.51235962, 18.13458252],
              [-65.51264191, 18.13458252],
              [-65.51264191, 18.13708305],
              [-65.51291656, 18.13708305],
              [-65.51291656, 18.13930511],
              [-65.51319122, 18.13930511],
              [-65.51319122, 18.14180565],
              [-65.51347351, 18.14180565],
              [-65.51347351, 18.14430428],
              [-65.51374817, 18.14430428],
              [-65.51374817, 18.14652634],
              [-65.51403046, 18.14652634],
              [-65.51403046, 18.1479187],
              [-65.51457977, 18.1479187],
              [-65.51457977, 18.14819527],
              [-65.51513672, 18.14819527],
              [-65.51513672, 18.14847183],
              [-65.51569366, 18.14847183],
              [-65.51569366, 18.14902878],
              [-65.51457977, 18.14902878],
              [-65.51457977, 18.1487484],
              [-65.51430511, 18.1487484],
              [-65.51430511, 18.14847183],
              [-65.51403046, 18.14847183],
              [-65.51403046, 18.15125275],
              [-65.51319122, 18.15125275],
              [-65.51319122, 18.14958382],
              [-65.51291656, 18.14958382],
              [-65.51291656, 18.14680481],
              [-65.51264191, 18.14680481],
              [-65.51264191, 18.14402771],
              [-65.51235962, 18.14402771],
              [-65.51235962, 18.14125061],
              [-65.51208496, 18.14125061],
              [-65.51208496, 18.1384716],
              [-65.51180267, 18.1384716],
              [-65.51180267, 18.1356945],
              [-65.51152802, 18.1356945],
              [-65.51152802, 18.13402939],
              [-65.51097107, 18.13402939],
              [-65.51097107, 18.13375092],
              [-65.51013947, 18.13375092],
              [-65.51013947, 18.13347244],
              [-65.50819397, 18.13347244],
              [-65.50819397, 18.13375092],
              [-65.50763702, 18.13375092],
              [-65.50763702, 18.13347244],
              [-65.50597382, 18.13347244],
              [-65.50597382, 18.13375092],
              [-65.50458527, 18.13375092],
              [-65.50458527, 18.13402939],
              [-65.50402832, 18.13402939],
              [-65.50402832, 18.13430786],
              [-65.50375366, 18.13430786],
              [-65.50375366, 18.13513756],
              [-65.50347137, 18.13513756],
              [-65.50347137, 18.13597298],
              [-65.50319672, 18.13597298],
              [-65.50319672, 18.13625145],
              [-65.50291443, 18.13625145],
              [-65.50291443, 18.13652992],
              [-65.49986267, 18.13652992],
              [-65.49986267, 18.13597298],
              [-65.49874878, 18.13597298],
              [-65.49874878, 18.1356945],
              [-65.49764252, 18.1356945],
              [-65.49764252, 18.13513756],
              [-65.49652863, 18.13513756],
              [-65.49652863, 18.13541794],
              [-65.49624634, 18.13541794],
              [-65.49624634, 18.1356945],
              [-65.49569702, 18.1356945],
              [-65.49569702, 18.13597298],
              [-65.49346924, 18.13597298],
              [-65.49346924, 18.13625145],
              [-65.49153137, 18.13625145],
              [-65.49153137, 18.13652992],
              [-65.49041748, 18.13652992],
              [-65.49041748, 18.13680649],
              [-65.48986053, 18.13680649],
              [-65.48986053, 18.13708305],
              [-65.48902893, 18.13708305],
              [-65.48902893, 18.13736153],
              [-65.48819733, 18.13736153],
              [-65.48819733, 18.13680649],
              [-65.48764038, 18.13680649],
              [-65.48764038, 18.13708305],
              [-65.48708344, 18.13708305],
              [-65.48708344, 18.13763809],
              [-65.48680878, 18.13763809],
              [-65.48680878, 18.13791656],
              [-65.48652649, 18.13791656],
              [-65.48652649, 18.13819504],
              [-65.48596954, 18.13819504],
              [-65.48596954, 18.1384716],
              [-65.48513794, 18.1384716],
              [-65.48513794, 18.13875008],
              [-65.48486328, 18.13875008],
              [-65.48486328, 18.13902855],
              [-65.48458099, 18.13902855],
              [-65.48458099, 18.13930511],
              [-65.48430634, 18.13930511],
              [-65.48430634, 18.13958359],
              [-65.48402405, 18.13958359],
              [-65.48402405, 18.13986206],
              [-65.4826355, 18.13986206],
              [-65.4826355, 18.14013863],
              [-65.48208618, 18.14013863],
              [-65.48208618, 18.1404171],
              [-65.48180389, 18.1404171],
              [-65.48180389, 18.14069366],
              [-65.48097229, 18.14069366],
              [-65.48097229, 18.14097214],
              [-65.48014069, 18.14097214],
              [-65.48014069, 18.14152718],
              [-65.47846985, 18.14152718],
              [-65.47846985, 18.14180565],
              [-65.47736359, 18.14180565],
              [-65.47736359, 18.14152718],
              [-65.47624969, 18.14152718],
              [-65.47624969, 18.14236069],
              [-65.47374725, 18.14236069],
              [-65.47374725, 18.14291573],
              [-65.4734726, 18.14291573],
              [-65.4734726, 18.1431942],
              [-65.47319794, 18.1431942],
              [-65.47319794, 18.14347267],
              [-65.47208405, 18.14347267],
              [-65.47208405, 18.14374924],
              [-65.47125244, 18.14374924],
              [-65.47125244, 18.14402771],
              [-65.4695816, 18.14402771],
              [-65.4695816, 18.14430428],
              [-65.46708679, 18.14430428],
              [-65.46708679, 18.14458275],
              [-65.46430206, 18.14458275],
              [-65.46430206, 18.14486122],
              [-65.46375275, 18.14486122],
              [-65.46375275, 18.14541626],
              [-65.46208191, 18.14541626],
              [-65.46208191, 18.14569473],
              [-65.46069336, 18.14569473],
              [-65.46069336, 18.1459713],
              [-65.45930481, 18.1459713],
              [-65.45930481, 18.14624977],
              [-65.45069122, 18.14624977],
              [-65.45069122, 18.14652634],
              [-65.44958496, 18.14652634],
              [-65.44958496, 18.14680481],
              [-65.44847107, 18.14680481],
              [-65.44847107, 18.14708328],
              [-65.44680786, 18.14708328],
              [-65.44680786, 18.14736176],
              [-65.44541931, 18.14736176],
              [-65.44541931, 18.14763641],
              [-65.44513702, 18.14763641],
              [-65.44513702, 18.1479187],
              [-65.44486237, 18.1479187],
              [-65.44486237, 18.14985847],
              [-65.44430542, 18.14985847],
              [-65.44430542, 18.15014076],
              [-65.44403076, 18.15014076],
              [-65.44403076, 18.15069389],
              [-65.44374847, 18.15069389],
              [-65.44374847, 18.15125275],
              [-65.44347382, 18.15125275],
              [-65.44347382, 18.15180588],
              [-65.44403076, 18.15180588],
              [-65.44403076, 18.15236282],
              [-65.44430542, 18.15236282],
              [-65.44430542, 18.15458488],
              [-65.44403076, 18.15458488],
              [-65.44403076, 18.15541649],
              [-65.44374847, 18.15541649],
              [-65.44374847, 18.15569305],
              [-65.44347382, 18.15569305],
              [-65.44347382, 18.15597153],
              [-65.44319153, 18.15597153],
              [-65.44319153, 18.15625],
              [-65.44291687, 18.15625],
              [-65.44291687, 18.15652847],
              [-65.44208527, 18.15652847],
              [-65.44208527, 18.15680313],
              [-65.44180298, 18.15680313],
              [-65.44180298, 18.15708351],
              [-65.44097137, 18.15708351],
              [-65.44097137, 18.15736198],
              [-65.44069672, 18.15736198],
              [-65.44069672, 18.15763855],
              [-65.43985748, 18.15763855],
              [-65.43985748, 18.15791512],
              [-65.43958282, 18.15791512],
              [-65.43958282, 18.15819359],
              [-65.43846893, 18.15819359],
              [-65.43846893, 18.15847206],
              [-65.43569183, 18.15847206],
              [-65.43569183, 18.15819359],
              [-65.43208313, 18.15819359],
              [-65.43208313, 18.15847206],
              [-65.43152618, 18.15847206],
              [-65.43152618, 18.15875053],
              [-65.42874908, 18.15875053],
              [-65.42874908, 18.15902519],
              [-65.42541504, 18.15902519],
              [-65.42541504, 18.15930557],
              [-65.4201355, 18.15930557],
              [-65.4201355, 18.15958405],
              [-65.41791534, 18.15958405],
              [-65.41791534, 18.15986061],
              [-65.41291809, 18.15986061],
              [-65.41291809, 18.16013718],
              [-65.41208649, 18.16013718],
              [-65.41208649, 18.16041946],
              [-65.41152954, 18.16041946],
              [-65.41152954, 18.16069412],
              [-65.41069794, 18.16069412],
              [-65.41069794, 18.1609726],
              [-65.40847015, 18.1609726],
              [-65.40847015, 18.16124725],
              [-65.40791321, 18.16124725],
              [-65.40791321, 18.16152954],
              [-65.40652466, 18.16152954],
              [-65.40652466, 18.16180611],
              [-65.40513611, 18.16180611],
              [-65.40513611, 18.16208267],
              [-65.40319824, 18.16208267],
              [-65.40319824, 18.16235924],
              [-65.39958191, 18.16235924],
              [-65.39958191, 18.16263771],
              [-65.39847565, 18.16263771],
              [-65.39847565, 18.16291618],
              [-65.3970871, 18.16291618],
              [-65.3970871, 18.16263771],
              [-65.39653015, 18.16263771],
              [-65.39653015, 18.16235924],
              [-65.39624786, 18.16235924],
              [-65.39624786, 18.16263771],
              [-65.39569092, 18.16263771],
              [-65.39569092, 18.16291618],
              [-65.39375305, 18.16291618],
              [-65.39375305, 18.16263771],
              [-65.39208221, 18.16263771],
              [-65.39208221, 18.16235924],
              [-65.39180756, 18.16235924],
              [-65.39180756, 18.16208267],
              [-65.39097595, 18.16208267],
              [-65.39097595, 18.16180611],
              [-65.39041901, 18.16180611],
              [-65.39041901, 18.16124725],
              [-65.39013672, 18.16124725],
              [-65.39013672, 18.1609726],
              [-65.38986206, 18.1609726],
              [-65.38986206, 18.16069412],
              [-65.38930511, 18.16069412],
              [-65.38930511, 18.16041946],
              [-65.38847351, 18.16041946],
              [-65.38847351, 18.16069412],
              [-65.38708496, 18.16069412],
              [-65.38708496, 18.1609726],
              [-65.38625336, 18.1609726],
              [-65.38625336, 18.16069412],
              [-65.38458252, 18.16069412],
              [-65.38458252, 18.1609726],
              [-65.38402557, 18.1609726],
              [-65.38402557, 18.16069412],
              [-65.38375092, 18.16069412],
              [-65.38375092, 18.16041946],
              [-65.38346863, 18.16041946],
              [-65.38346863, 18.16013718],
              [-65.38263702, 18.16013718],
              [-65.38263702, 18.15986061],
              [-65.38208008, 18.15986061],
              [-65.38208008, 18.16013718],
              [-65.38124847, 18.16013718],
              [-65.38124847, 18.15986061],
              [-65.38097382, 18.15986061],
              [-65.38097382, 18.15958405],
              [-65.38041687, 18.15958405],
              [-65.38041687, 18.15930557],
              [-65.37985992, 18.15930557],
              [-65.37985992, 18.15902519],
              [-65.37958527, 18.15902519],
              [-65.37958527, 18.15875053],
              [-65.37902832, 18.15875053],
              [-65.37902832, 18.15847206],
              [-65.37875366, 18.15847206],
              [-65.37875366, 18.15819359],
              [-65.37735748, 18.15819359],
              [-65.37735748, 18.15791512],
              [-65.37513733, 18.15791512],
              [-65.37513733, 18.15763855],
              [-65.37486267, 18.15763855],
              [-65.37486267, 18.15736198],
              [-65.37069702, 18.15736198],
              [-65.37069702, 18.15791512],
              [-65.36875153, 18.15791512],
              [-65.36875153, 18.15763855],
              [-65.36846924, 18.15763855],
              [-65.36846924, 18.15819359],
              [-65.36541748, 18.15819359],
              [-65.36541748, 18.15791512],
              [-65.36486053, 18.15791512],
              [-65.36486053, 18.15763855],
              [-65.36458588, 18.15763855],
              [-65.36458588, 18.15736198],
              [-65.36430359, 18.15736198],
              [-65.36430359, 18.15708351],
              [-65.36402893, 18.15708351],
              [-65.36402893, 18.15680313],
              [-65.36374664, 18.15680313],
              [-65.36374664, 18.15652847],
              [-65.36347198, 18.15652847],
              [-65.36347198, 18.15625],
              [-65.36319733, 18.15625],
              [-65.36319733, 18.15569305],
              [-65.36291504, 18.15569305],
              [-65.36291504, 18.15541649],
              [-65.36264038, 18.15541649],
              [-65.36264038, 18.15486145],
              [-65.36235809, 18.15486145],
              [-65.36235809, 18.15458488],
              [-65.36208344, 18.15458488],
              [-65.36208344, 18.15430641],
              [-65.36180878, 18.15430641],
              [-65.36180878, 18.15374947],
              [-65.36096954, 18.15374947],
              [-65.36096954, 18.1534729],
              [-65.36069489, 18.1534729],
              [-65.36069489, 18.15319443],
              [-65.36013794, 18.15319443],
              [-65.36013794, 18.1534729],
              [-65.35958099, 18.1534729],
              [-65.35958099, 18.15374947],
              [-65.35902405, 18.15374947],
              [-65.35902405, 18.15402794],
              [-65.35847473, 18.15402794],
              [-65.35847473, 18.15430641],
              [-65.35791779, 18.15430641],
              [-65.35791779, 18.15458488],
              [-65.35736084, 18.15458488],
              [-65.35736084, 18.15486145],
              [-65.35708618, 18.15486145],
              [-65.35708618, 18.15513802],
              [-65.35680389, 18.15513802],
              [-65.35680389, 18.15597153],
              [-65.35624695, 18.15597153],
              [-65.35624695, 18.15569305],
              [-65.35541534, 18.15569305],
              [-65.35541534, 18.15541649],
              [-65.35430908, 18.15541649],
              [-65.35430908, 18.15513802],
              [-65.35319519, 18.15513802],
              [-65.35319519, 18.15486145],
              [-65.35263824, 18.15486145],
              [-65.35263824, 18.15458488],
              [-65.3520813, 18.15458488],
              [-65.3520813, 18.15430641],
              [-65.35180664, 18.15430641],
              [-65.35180664, 18.15402794],
              [-65.35152435, 18.15402794],
              [-65.35152435, 18.15374947],
              [-65.35097504, 18.15374947],
              [-65.35097504, 18.15319443],
              [-65.35069275, 18.15319443],
              [-65.35069275, 18.15291595],
              [-65.35041809, 18.15291595],
              [-65.35041809, 18.15263939],
              [-65.34986115, 18.15263939],
              [-65.34986115, 18.15236282],
              [-65.34874725, 18.15236282],
              [-65.34874725, 18.15208244],
              [-65.3473587, 18.15208244],
              [-65.3473587, 18.15180588],
              [-65.3465271, 18.15180588],
              [-65.3465271, 18.1515274],
              [-65.3456955, 18.1515274],
              [-65.3456955, 18.15125275],
              [-65.34486389, 18.15125275],
              [-65.34486389, 18.15069389],
              [-65.34347534, 18.15069389],
              [-65.34347534, 18.15041733],
              [-65.34236145, 18.15041733],
              [-65.34236145, 18.15014076],
              [-65.3418045, 18.15014076],
              [-65.3418045, 18.14985847],
              [-65.3409729, 18.14985847],
              [-65.3409729, 18.14930534],
              [-65.3401413, 18.14930534],
              [-65.3401413, 18.14958382],
              [-65.33875275, 18.14958382],
              [-65.33875275, 18.14985847],
              [-65.33791351, 18.14985847],
              [-65.33791351, 18.15014076],
              [-65.33652496, 18.15014076],
              [-65.33652496, 18.15041733],
              [-65.3345871, 18.15041733],
              [-65.3345871, 18.14930534],
              [-65.3354187, 18.14930534],
              [-65.3354187, 18.14847183],
              [-65.33513641, 18.14847183],
              [-65.33513641, 18.14819527],
              [-65.33403015, 18.14819527],
              [-65.33403015, 18.14847183],
              [-65.33291626, 18.14847183],
              [-65.33291626, 18.1487484],
              [-65.33208466, 18.1487484],
              [-65.33208466, 18.14847183],
              [-65.33180237, 18.14847183],
              [-65.33180237, 18.14819527],
              [-65.33152771, 18.14819527],
              [-65.33152771, 18.14736176],
              [-65.33125305, 18.14736176],
              [-65.33125305, 18.14680481],
              [-65.3298645, 18.14680481],
              [-65.3298645, 18.1479187],
              [-65.32958221, 18.1479187],
              [-65.32958221, 18.14847183],
              [-65.32930756, 18.14847183],
              [-65.32930756, 18.1487484],
              [-65.32875061, 18.1487484],
              [-65.32875061, 18.14847183],
              [-65.32819366, 18.14847183],
              [-65.32819366, 18.1487484],
              [-65.32791901, 18.1487484],
              [-65.32791901, 18.14902878],
              [-65.32763672, 18.14902878],
              [-65.32763672, 18.14958382],
              [-65.32707977, 18.14958382],
              [-65.32707977, 18.14985847],
              [-65.32485962, 18.14985847],
              [-65.32485962, 18.14958382],
              [-65.32430267, 18.14958382],
              [-65.32430267, 18.14930534],
              [-65.32347107, 18.14930534],
              [-65.32347107, 18.14902878],
              [-65.32319641, 18.14902878],
              [-65.32319641, 18.1487484],
              [-65.32291412, 18.1487484],
              [-65.32291412, 18.1479187],
              [-65.32263947, 18.1479187],
              [-65.32263947, 18.14708328],
              [-65.32236481, 18.14708328],
              [-65.32236481, 18.14680481],
              [-65.32208252, 18.14680481],
              [-65.32208252, 18.14652634],
              [-65.32152557, 18.14652634],
              [-65.32152557, 18.14624977],
              [-65.32096863, 18.14624977],
              [-65.32096863, 18.14652634],
              [-65.32041931, 18.14652634],
              [-65.32041931, 18.14624977],
              [-65.32013702, 18.14624977],
              [-65.32013702, 18.1459713],
              [-65.31986237, 18.1459713],
              [-65.31986237, 18.14569473],
              [-65.31903076, 18.14569473],
              [-65.31903076, 18.14541626],
              [-65.31819153, 18.14541626],
              [-65.31819153, 18.14569473],
              [-65.31791687, 18.14569473],
              [-65.31791687, 18.14541626],
              [-65.31708527, 18.14541626],
              [-65.31708527, 18.14513969],
              [-65.31680298, 18.14513969],
              [-65.31680298, 18.14486122],
              [-65.31597137, 18.14486122],
              [-65.31597137, 18.14458275],
              [-65.31541443, 18.14458275],
              [-65.31541443, 18.14486122],
              [-65.31402588, 18.14486122],
              [-65.31402588, 18.14458275],
              [-65.31375122, 18.14458275],
              [-65.31375122, 18.14430428],
              [-65.31291962, 18.14430428],
              [-65.31291962, 18.14458275],
              [-65.31180573, 18.14458275],
              [-65.31180573, 18.14486122],
              [-65.31069183, 18.14486122],
              [-65.31069183, 18.14513969],
              [-65.30986023, 18.14513969],
              [-65.30986023, 18.14541626],
              [-65.30847168, 18.14541626],
              [-65.30847168, 18.14513969],
              [-65.30819702, 18.14513969],
              [-65.30819702, 18.14486122],
              [-65.30791473, 18.14486122],
              [-65.30791473, 18.14430428],
              [-65.30735779, 18.14430428],
              [-65.30735779, 18.14402771],
              [-65.30708313, 18.14402771],
              [-65.30708313, 18.14374924],
              [-65.30652618, 18.14374924],
              [-65.30652618, 18.14347267],
              [-65.30625153, 18.14347267],
              [-65.30625153, 18.1431942],
              [-65.30569458, 18.1431942],
              [-65.30569458, 18.14291573],
              [-65.30541992, 18.14291573],
              [-65.30541992, 18.14263916],
              [-65.30513763, 18.14263916],
              [-65.30513763, 18.14236069],
              [-65.30458069, 18.14236069],
              [-65.30458069, 18.14208412],
              [-65.30347443, 18.14208412],
              [-65.30347443, 18.14180565],
              [-65.30319214, 18.14180565],
              [-65.30319214, 18.14152718],
              [-65.30263519, 18.14152718],
              [-65.30263519, 18.14180565],
              [-65.30236053, 18.14180565],
              [-65.30236053, 18.14208412],
              [-65.30180359, 18.14208412],
              [-65.30180359, 18.14374924],
              [-65.30208588, 18.14374924],
              [-65.30208588, 18.14402771],
              [-65.30152893, 18.14402771],
              [-65.30152893, 18.14430428],
              [-65.30014038, 18.14430428],
              [-65.30014038, 18.14402771],
              [-65.29875183, 18.14402771],
              [-65.29875183, 18.14374924],
              [-65.29792023, 18.14374924],
              [-65.29792023, 18.14347267],
              [-65.29708099, 18.14347267],
              [-65.29708099, 18.1431942],
              [-65.29680634, 18.1431942],
              [-65.29680634, 18.14291573],
              [-65.29541779, 18.14291573],
              [-65.29541779, 18.14347267],
              [-65.29486084, 18.14347267],
              [-65.29486084, 18.14513969],
              [-65.29458618, 18.14513969],
              [-65.29458618, 18.14541626],
              [-65.29430389, 18.14541626],
              [-65.29430389, 18.14569473],
              [-65.29402924, 18.14569473],
              [-65.29402924, 18.1459713],
              [-65.2923584, 18.1459713],
              [-65.2923584, 18.14624977],
              [-65.29208374, 18.14624977],
              [-65.29208374, 18.14652634],
              [-65.29152679, 18.14652634],
              [-65.29152679, 18.14708328],
              [-65.29125214, 18.14708328],
              [-65.29125214, 18.14736176],
              [-65.29096985, 18.14736176],
              [-65.29096985, 18.1479187],
              [-65.29069519, 18.1479187],
              [-65.29069519, 18.14819527],
              [-65.2895813, 18.14819527],
              [-65.2895813, 18.14847183],
              [-65.28819275, 18.14847183],
              [-65.28819275, 18.14819527],
              [-65.28791809, 18.14819527],
              [-65.28791809, 18.14763641],
              [-65.2876358, 18.14763641],
              [-65.2876358, 18.14736176],
              [-65.28736115, 18.14736176],
              [-65.28736115, 18.14708328],
              [-65.28708649, 18.14708328],
              [-65.28708649, 18.14680481],
              [-65.2868042, 18.14680481],
              [-65.2868042, 18.14513969],
              [-65.28652954, 18.14513969],
              [-65.28652954, 18.14402771],
              [-65.2848587, 18.14402771],
              [-65.2848587, 18.14458275],
              [-65.28458405, 18.14458275],
              [-65.28458405, 18.14513969],
              [-65.28430176, 18.14513969],
              [-65.28430176, 18.14569473],
              [-65.28291321, 18.14569473],
              [-65.28291321, 18.14541626],
              [-65.28263855, 18.14541626],
              [-65.28263855, 18.14513969],
              [-65.28236389, 18.14513969],
              [-65.28236389, 18.14402771],
              [-65.2820816, 18.14402771],
              [-65.2820816, 18.14347267],
              [-65.28180695, 18.14347267],
              [-65.28180695, 18.14291573],
              [-65.28152466, 18.14291573],
              [-65.28152466, 18.14263916],
              [-65.28125, 18.14263916],
              [-65.28125, 18.14236069],
              [-65.28097534, 18.14236069],
              [-65.28097534, 18.14180565],
              [-65.28069305, 18.14180565],
              [-65.28069305, 18.14013863],
              [-65.2804184, 18.14013863],
              [-65.2804184, 18.13986206],
              [-65.28013611, 18.13986206],
              [-65.28013611, 18.13958359],
              [-65.27986145, 18.13958359],
              [-65.27986145, 18.13930511],
              [-65.27958679, 18.13930511],
              [-65.27958679, 18.13902855],
              [-65.27735901, 18.13902855],
              [-65.27735901, 18.13875008],
              [-65.2776413, 18.13875008],
              [-65.2776413, 18.1384716],
              [-65.27791595, 18.1384716],
              [-65.27791595, 18.13819504],
              [-65.27819824, 18.13819504],
              [-65.27819824, 18.13791656],
              [-65.2784729, 18.13791656],
              [-65.2784729, 18.13763809],
              [-65.27819824, 18.13763809],
              [-65.27819824, 18.13652992],
              [-65.27791595, 18.13652992],
              [-65.27791595, 18.13625145],
              [-65.2776413, 18.13625145],
              [-65.2776413, 18.13597298],
              [-65.27735901, 18.13597298],
              [-65.27735901, 18.1356945],
              [-65.27708435, 18.1356945],
              [-65.27708435, 18.13541794],
              [-65.27680206, 18.13541794],
              [-65.27680206, 18.13513756],
              [-65.2765274, 18.13513756],
              [-65.2765274, 18.13485909],
              [-65.27597046, 18.13485909],
              [-65.27597046, 18.13430786],
              [-65.2756958, 18.13430786],
              [-65.2756958, 18.13402939],
              [-65.27541351, 18.13402939],
              [-65.27541351, 18.13375092],
              [-65.27458191, 18.13375092],
              [-65.27458191, 18.13402939],
              [-65.27430725, 18.13402939],
              [-65.27430725, 18.13430786],
              [-65.27375031, 18.13430786],
              [-65.27375031, 18.13458252],
              [-65.27319336, 18.13458252],
              [-65.27319336, 18.13430786],
              [-65.2720871, 18.13430786],
              [-65.2720871, 18.13485909],
              [-65.27180481, 18.13485909],
              [-65.27180481, 18.13513756],
              [-65.27124786, 18.13513756],
              [-65.27124786, 18.13541794],
              [-65.26985931, 18.13541794],
              [-65.26985931, 18.1356945],
              [-65.26958466, 18.1356945],
              [-65.26958466, 18.13597298],
              [-65.26902771, 18.13597298],
              [-65.26902771, 18.13625145],
              [-65.26791382, 18.13625145],
              [-65.26791382, 18.13597298],
              [-65.26763916, 18.13597298],
              [-65.26763916, 18.1356945],
              [-65.2673645, 18.1356945],
              [-65.2673645, 18.13541794],
              [-65.26708221, 18.13541794],
              [-65.26708221, 18.13402939],
              [-65.2673645, 18.13402939],
              [-65.2673645, 18.13375092],
              [-65.26791382, 18.13375092],
              [-65.26791382, 18.13347244],
              [-65.26930237, 18.13347244],
              [-65.26930237, 18.13402939],
              [-65.26985931, 18.13402939],
              [-65.26985931, 18.13375092],
              [-65.27041626, 18.13375092],
              [-65.27041626, 18.13347244],
              [-65.27069092, 18.13347244],
              [-65.27069092, 18.1329155],
              [-65.27097321, 18.1329155],
              [-65.27097321, 18.13236046],
              [-65.27124786, 18.13236046],
              [-65.27124786, 18.13125038],
              [-65.27153015, 18.13125038],
              [-65.27153015, 18.13097191],
              [-65.27180481, 18.13097191],
              [-65.27180481, 18.13069534],
              [-65.2720871, 18.13069534],
              [-65.2720871, 18.12902832],
              [-65.27236176, 18.12902832],
              [-65.27236176, 18.12874985],
              [-65.2729187, 18.12874985],
              [-65.2729187, 18.12958336],
              [-65.27319336, 18.12958336],
              [-65.27319336, 18.12986183],
              [-65.27347565, 18.12986183],
              [-65.27347565, 18.1301384],
              [-65.27375031, 18.1301384],
              [-65.27375031, 18.13041687],
              [-65.27513885, 18.13041687],
              [-65.27513885, 18.13125038],
              [-65.27541351, 18.13125038],
              [-65.27541351, 18.13152695],
              [-65.27597046, 18.13152695],
              [-65.27597046, 18.13180542],
              [-65.27791595, 18.13180542],
              [-65.27791595, 18.13208389],
              [-65.28013611, 18.13208389],
              [-65.28013611, 18.13236046],
              [-65.28180695, 18.13236046],
              [-65.28180695, 18.13263893],
              [-65.28291321, 18.13263893],
              [-65.28291321, 18.1329155],
              [-65.28347015, 18.1329155],
              [-65.28347015, 18.13263893],
              [-65.28375244, 18.13263893],
              [-65.28375244, 18.13236046],
              [-65.28430176, 18.13236046],
              [-65.28430176, 18.13208389],
              [-65.28458405, 18.13208389],
              [-65.28458405, 18.13180542],
              [-65.28514099, 18.13180542],
              [-65.28514099, 18.13152695],
              [-65.2859726, 18.13152695],
              [-65.2859726, 18.13125038],
              [-65.28624725, 18.13125038],
              [-65.28624725, 18.13097191],
              [-65.2868042, 18.13097191],
              [-65.2868042, 18.13069534],
              [-65.2876358, 18.13069534],
              [-65.2876358, 18.13041687],
              [-65.28847504, 18.13041687],
              [-65.28847504, 18.1301384],
              [-65.29013824, 18.1301384],
              [-65.29013824, 18.12986183],
              [-65.29208374, 18.12986183],
              [-65.29208374, 18.12958336],
              [-65.29264069, 18.12958336],
              [-65.29264069, 18.12930489],
              [-65.29291534, 18.12930489],
              [-65.29291534, 18.12902832],
              [-65.29374695, 18.12902832],
              [-65.29374695, 18.12874985],
              [-65.29402924, 18.12874985],
              [-65.29402924, 18.12819481],
              [-65.29430389, 18.12819481],
              [-65.29430389, 18.12791634],
              [-65.29458618, 18.12791634],
              [-65.29458618, 18.1273613],
              [-65.29486084, 18.1273613],
              [-65.29486084, 18.12708282],
              [-65.2951355, 18.12708282],
              [-65.2951355, 18.12680626],
              [-65.29569244, 18.12680626],
              [-65.29569244, 18.12708282],
              [-65.29624939, 18.12708282],
              [-65.29624939, 18.1273613],
              [-65.29652405, 18.1273613],
              [-65.29652405, 18.12819481],
              [-65.29680634, 18.12819481],
              [-65.29680634, 18.1301384],
              [-65.29708099, 18.1301384],
              [-65.29708099, 18.13069534],
              [-65.29736328, 18.13069534],
              [-65.29736328, 18.13097191],
              [-65.29763794, 18.13097191],
              [-65.29763794, 18.13152695],
              [-65.29792023, 18.13152695],
              [-65.29792023, 18.13208389],
              [-65.29819489, 18.13208389],
              [-65.29819489, 18.13430786],
              [-65.29846954, 18.13430786],
              [-65.29846954, 18.13458252],
              [-65.29875183, 18.13458252],
              [-65.29875183, 18.13513756],
              [-65.29985809, 18.13513756],
              [-65.29985809, 18.13541794],
              [-65.30097198, 18.13541794],
              [-65.30097198, 18.13402939],
              [-65.30291748, 18.13402939],
              [-65.30291748, 18.13430786],
              [-65.30347443, 18.13430786],
              [-65.30347443, 18.13513756],
              [-65.30403137, 18.13513756],
              [-65.30403137, 18.13541794],
              [-65.30458069, 18.13541794],
              [-65.30458069, 18.13458252],
              [-65.30430603, 18.13458252],
              [-65.30430603, 18.13375092],
              [-65.30541992, 18.13375092],
              [-65.30541992, 18.13430786],
              [-65.30569458, 18.13430786],
              [-65.30569458, 18.13513756],
              [-65.30596924, 18.13513756],
              [-65.30596924, 18.13541794],
              [-65.30680847, 18.13541794],
              [-65.30680847, 18.13513756],
              [-65.30764008, 18.13513756],
              [-65.30764008, 18.13485909],
              [-65.30791473, 18.13485909],
              [-65.30791473, 18.13458252],
              [-65.30819702, 18.13458252],
              [-65.30819702, 18.13430786],
              [-65.30847168, 18.13430786],
              [-65.30847168, 18.13402939],
              [-65.30874634, 18.13402939],
              [-65.30874634, 18.13375092],
              [-65.30902863, 18.13375092],
              [-65.30902863, 18.13347244],
              [-65.30930328, 18.13347244],
              [-65.30930328, 18.1329155],
              [-65.30958557, 18.1329155],
              [-65.30958557, 18.13263893],
              [-65.30986023, 18.13263893],
              [-65.30986023, 18.13180542],
              [-65.31014252, 18.13180542],
              [-65.31014252, 18.12958336],
              [-65.30986023, 18.12958336],
              [-65.30986023, 18.12930489],
              [-65.30958557, 18.12930489],
              [-65.30958557, 18.12902832],
              [-65.30930328, 18.12902832],
              [-65.30930328, 18.12874985],
              [-65.30819702, 18.12874985],
              [-65.30819702, 18.12902832],
              [-65.30764008, 18.12902832],
              [-65.30764008, 18.12874985],
              [-65.30735779, 18.12874985],
              [-65.30735779, 18.1273613],
              [-65.30764008, 18.1273613],
              [-65.30764008, 18.12597275],
              [-65.30791473, 18.12597275],
              [-65.30791473, 18.12569427],
              [-65.30874634, 18.12569427],
              [-65.30874634, 18.12624931],
              [-65.30930328, 18.12624931],
              [-65.30930328, 18.12652779],
              [-65.30958557, 18.12652779],
              [-65.30958557, 18.12680626],
              [-65.31014252, 18.12680626],
              [-65.31014252, 18.12652779],
              [-65.31041718, 18.12652779],
              [-65.31041718, 18.12624931],
              [-65.31069183, 18.12624931],
              [-65.31069183, 18.12597275],
              [-65.31236267, 18.12597275],
              [-65.31236267, 18.12569427],
              [-65.31291962, 18.12569427],
              [-65.31291962, 18.1254158],
              [-65.31319427, 18.1254158],
              [-65.31319427, 18.12486267],
              [-65.31513977, 18.12486267],
              [-65.31513977, 18.12513924],
              [-65.31569672, 18.12513924],
              [-65.31569672, 18.12430573],
              [-65.31597137, 18.12430573],
              [-65.31597137, 18.12347221],
              [-65.31625366, 18.12347221],
              [-65.31625366, 18.12319374],
              [-65.31680298, 18.12319374],
              [-65.31680298, 18.12375069],
              [-65.31708527, 18.12375069],
              [-65.31708527, 18.12402725],
              [-65.31764221, 18.12402725],
              [-65.31764221, 18.12513924],
              [-65.31791687, 18.12513924],
              [-65.31791687, 18.1254158],
              [-65.31819153, 18.1254158],
              [-65.31819153, 18.12569427],
              [-65.31903076, 18.12569427],
              [-65.31903076, 18.12652779],
              [-65.31930542, 18.12652779],
              [-65.31930542, 18.12680626],
              [-65.31986237, 18.12680626],
              [-65.31986237, 18.12708282],
              [-65.32013702, 18.12708282],
              [-65.32013702, 18.12680626],
              [-65.32125092, 18.12680626],
              [-65.32125092, 18.12652779],
              [-65.32236481, 18.12652779],
              [-65.32236481, 18.12624931],
              [-65.32263947, 18.12624931],
              [-65.32263947, 18.12597275],
              [-65.32402802, 18.12597275],
              [-65.32402802, 18.12569427],
              [-65.32458496, 18.12569427],
              [-65.32458496, 18.1254158],
              [-65.32569122, 18.1254158],
              [-65.32569122, 18.12513924],
              [-65.32624817, 18.12513924],
              [-65.32624817, 18.12486267],
              [-65.32680511, 18.12486267],
              [-65.32680511, 18.1245842],
              [-65.32707977, 18.1245842],
              [-65.32707977, 18.12430573],
              [-65.32736206, 18.12430573],
              [-65.32736206, 18.12347221],
              [-65.32763672, 18.12347221],
              [-65.32763672, 18.12264061],
              [-65.32736206, 18.12264061],
              [-65.32736206, 18.1198616],
              [-65.32791901, 18.1198616],
              [-65.32791901, 18.12013626],
              [-65.32847595, 18.12013626],
              [-65.32847595, 18.12097168],
              [-65.32875061, 18.12097168],
              [-65.32875061, 18.12153053],
              [-65.32902527, 18.12153053],
              [-65.32902527, 18.12180519],
              [-65.32930756, 18.12180519],
              [-65.32930756, 18.12208366],
              [-65.33013916, 18.12208366],
              [-65.33013916, 18.12235832],
              [-65.33041382, 18.12235832],
              [-65.33041382, 18.1245842],
              [-65.33069611, 18.1245842],
              [-65.33069611, 18.12513924],
              [-65.33125305, 18.12513924],
              [-65.33125305, 18.12569427],
              [-65.33347321, 18.12569427],
              [-65.33347321, 18.1254158],
              [-65.33403015, 18.1254158],
              [-65.33403015, 18.12513924],
              [-65.33430481, 18.12513924],
              [-65.33430481, 18.12430573],
              [-65.3345871, 18.12430573],
              [-65.3345871, 18.12375069],
              [-65.33430481, 18.12375069],
              [-65.33430481, 18.12347221],
              [-65.33403015, 18.12347221],
              [-65.33403015, 18.12319374],
              [-65.33374786, 18.12319374],
              [-65.33374786, 18.12153053],
              [-65.33403015, 18.12153053],
              [-65.33403015, 18.12124825],
              [-65.33430481, 18.12124825],
              [-65.33430481, 18.12069511],
              [-65.33486176, 18.12069511],
              [-65.33486176, 18.12097168],
              [-65.33513641, 18.12097168],
              [-65.33513641, 18.12124825],
              [-65.3354187, 18.12124825],
              [-65.3354187, 18.12069511],
              [-65.33513641, 18.12069511],
              [-65.33513641, 18.12013626],
              [-65.33486176, 18.12013626],
              [-65.33486176, 18.11958313],
              [-65.33430481, 18.11958313],
              [-65.33430481, 18.11902618],
              [-65.33403015, 18.11902618],
              [-65.33403015, 18.11874962],
              [-65.33347321, 18.11874962],
              [-65.33347321, 18.11680412],
              [-65.33403015, 18.11680412],
              [-65.33403015, 18.1170826],
              [-65.3345871, 18.1170826],
              [-65.3345871, 18.11736107],
              [-65.33513641, 18.11736107],
              [-65.33513641, 18.11763954],
              [-65.3354187, 18.11763954],
              [-65.3354187, 18.1179142],
              [-65.33569336, 18.1179142],
              [-65.33569336, 18.11819458],
              [-65.33597565, 18.11819458],
              [-65.33597565, 18.11847305],
              [-65.33625031, 18.11847305],
              [-65.33625031, 18.11874962],
              [-65.33680725, 18.11874962],
              [-65.33680725, 18.12013626],
              [-65.33708191, 18.12013626],
              [-65.33708191, 18.12041855],
              [-65.3373642, 18.12041855],
              [-65.3373642, 18.12069511],
              [-65.33791351, 18.12069511],
              [-65.33791351, 18.1198616],
              [-65.33847046, 18.1198616],
              [-65.33847046, 18.11930466],
              [-65.3381958, 18.11930466],
              [-65.3381958, 18.11847305],
              [-65.33763885, 18.11847305],
              [-65.33763885, 18.11819458],
              [-65.3373642, 18.11819458],
              [-65.3373642, 18.11763954],
              [-65.33708191, 18.11763954],
              [-65.33708191, 18.1170826],
              [-65.33625031, 18.1170826],
              [-65.33625031, 18.11624908],
              [-65.33597565, 18.11624908],
              [-65.33597565, 18.1143055],
              [-65.33652496, 18.1143055],
              [-65.33652496, 18.11402702],
              [-65.3373642, 18.11402702],
              [-65.3373642, 18.11375046],
              [-65.33763885, 18.11375046],
              [-65.33763885, 18.11347389],
              [-65.33791351, 18.11347389],
              [-65.33791351, 18.11319351],
              [-65.3381958, 18.11319351],
              [-65.3381958, 18.11263847],
              [-65.33847046, 18.11263847],
              [-65.33847046, 18.11208344],
              [-65.33958435, 18.11208344],
              [-65.33958435, 18.11236382],
              [-65.33985901, 18.11236382],
              [-65.33985901, 18.11263847],
              [-65.3401413, 18.11263847],
              [-65.3401413, 18.1143055],
              [-65.33985901, 18.1143055],
              [-65.33985901, 18.11486053],
              [-65.33958435, 18.11486053],
              [-65.33958435, 18.11597252],
              [-65.34069824, 18.11597252],
              [-65.34069824, 18.11624908],
              [-65.3409729, 18.11624908],
              [-65.3409729, 18.11597252],
              [-65.34124756, 18.11597252],
              [-65.34124756, 18.11541748],
              [-65.34152985, 18.11541748],
              [-65.34152985, 18.11486053],
              [-65.3418045, 18.11486053],
              [-65.3418045, 18.11402702],
              [-65.34152985, 18.11402702],
              [-65.34152985, 18.11291695],
              [-65.3418045, 18.11291695],
              [-65.3418045, 18.11180496],
              [-65.34208679, 18.11180496],
              [-65.34208679, 18.1115284],
              [-65.34263611, 18.1115284],
              [-65.34263611, 18.11096954],
              [-65.34319305, 18.11096954],
              [-65.34319305, 18.1115284],
              [-65.34375, 18.1115284],
              [-65.34375, 18.11180496],
              [-65.34541321, 18.11180496],
              [-65.34541321, 18.11208344],
              [-65.34597015, 18.11208344],
              [-65.34597015, 18.1115284],
              [-65.34625244, 18.1115284],
              [-65.34625244, 18.11041641],
              [-65.3473587, 18.11041641],
              [-65.3473587, 18.11096954],
              [-65.34764099, 18.11096954],
              [-65.34764099, 18.11125183],
              [-65.34791565, 18.11125183],
              [-65.34791565, 18.1115284],
              [-65.34819794, 18.1115284],
              [-65.34819794, 18.11180496],
              [-65.3484726, 18.11180496],
              [-65.3484726, 18.11208344],
              [-65.34874725, 18.11208344],
              [-65.34874725, 18.11236382],
              [-65.34902954, 18.11236382],
              [-65.34902954, 18.11208344],
              [-65.3501358, 18.11208344],
              [-65.3501358, 18.11180496],
              [-65.35041809, 18.11180496],
              [-65.35041809, 18.1115284],
              [-65.35180664, 18.1115284],
              [-65.35180664, 18.11180496],
              [-65.3520813, 18.11180496],
              [-65.3520813, 18.11208344],
              [-65.35458374, 18.11208344],
              [-65.35458374, 18.11236382],
              [-65.35541534, 18.11236382],
              [-65.35541534, 18.11263847],
              [-65.35597229, 18.11263847],
              [-65.35597229, 18.11347389],
              [-65.35514069, 18.11347389],
              [-65.35514069, 18.11319351],
              [-65.35263824, 18.11319351],
              [-65.35263824, 18.11347389],
              [-65.35236359, 18.11347389],
              [-65.35236359, 18.11375046],
              [-65.35124969, 18.11375046],
              [-65.35124969, 18.11402702],
              [-65.35097504, 18.11402702],
              [-65.35097504, 18.1143055],
              [-65.35069275, 18.1143055],
              [-65.35069275, 18.11458206],
              [-65.3493042, 18.11458206],
              [-65.3493042, 18.11486053],
              [-65.34819794, 18.11486053],
              [-65.34819794, 18.11541748],
              [-65.34708405, 18.11541748],
              [-65.34708405, 18.11569595],
              [-65.3456955, 18.11569595],
              [-65.3456955, 18.11597252],
              [-65.34513855, 18.11597252],
              [-65.34513855, 18.11569595],
              [-65.34347534, 18.11569595],
              [-65.34347534, 18.11597252],
              [-65.34375, 18.11597252],
              [-65.34375, 18.1170826],
              [-65.34347534, 18.1170826],
              [-65.34347534, 18.11736107],
              [-65.34319305, 18.11736107],
              [-65.34319305, 18.11763954],
              [-65.34263611, 18.11763954],
              [-65.34263611, 18.1179142],
              [-65.34236145, 18.1179142],
              [-65.34236145, 18.11819458],
              [-65.3418045, 18.11819458],
              [-65.3418045, 18.11847305],
              [-65.3409729, 18.11847305],
              [-65.3409729, 18.11874962],
              [-65.34069824, 18.11874962],
              [-65.34069824, 18.11902618],
              [-65.34041595, 18.11902618],
              [-65.34041595, 18.12041855],
              [-65.3409729, 18.12041855],
              [-65.3409729, 18.12097168],
              [-65.34263611, 18.12097168],
              [-65.34263611, 18.12069511],
              [-65.34347534, 18.12069511],
              [-65.34347534, 18.12041855],
              [-65.34375, 18.12041855],
              [-65.34375, 18.12069511],
              [-65.34430695, 18.12069511],
              [-65.34430695, 18.12097168],
              [-65.34486389, 18.12097168],
              [-65.34486389, 18.12124825],
              [-65.34541321, 18.12124825],
              [-65.34541321, 18.12235832],
              [-65.3456955, 18.12235832],
              [-65.3456955, 18.12180519],
              [-65.34597015, 18.12180519],
              [-65.34597015, 18.12153053],
              [-65.34764099, 18.12153053],
              [-65.34764099, 18.12264061],
              [-65.3484726, 18.12264061],
              [-65.3484726, 18.12235832],
              [-65.34902954, 18.12235832],
              [-65.34902954, 18.12208366],
              [-65.34958649, 18.12208366],
              [-65.34958649, 18.12264061],
              [-65.34986115, 18.12264061],
              [-65.34986115, 18.12291718],
              [-65.35041809, 18.12291718],
              [-65.35041809, 18.12319374],
              [-65.35069275, 18.12319374],
              [-65.35069275, 18.12430573],
              [-65.35041809, 18.12430573],
              [-65.35041809, 18.1254158],
              [-65.3501358, 18.1254158],
              [-65.3501358, 18.12569427],
              [-65.34986115, 18.12569427],
              [-65.34986115, 18.12624931],
              [-65.3501358, 18.12624931],
              [-65.3501358, 18.12652779],
              [-65.35041809, 18.12652779],
              [-65.35041809, 18.12624931],
              [-65.35069275, 18.12624931],
              [-65.35069275, 18.12597275],
              [-65.35180664, 18.12597275],
              [-65.35180664, 18.12569427],
              [-65.35236359, 18.12569427],
              [-65.35236359, 18.12513924],
              [-65.3529129, 18.12513924],
              [-65.3529129, 18.1254158],
              [-65.35375214, 18.1254158],
              [-65.35375214, 18.12569427],
              [-65.35402679, 18.12569427],
              [-65.35402679, 18.1254158],
              [-65.35430908, 18.1254158],
              [-65.35430908, 18.12513924],
              [-65.3548584, 18.12513924],
              [-65.3548584, 18.12597275],
              [-65.35541534, 18.12597275],
              [-65.35541534, 18.12624931],
              [-65.35624695, 18.12624931],
              [-65.35624695, 18.12652779],
              [-65.35680389, 18.12652779],
              [-65.35680389, 18.12624931],
              [-65.35736084, 18.12624931],
              [-65.35736084, 18.12597275],
              [-65.35791779, 18.12597275],
              [-65.35791779, 18.12486267],
              [-65.35819244, 18.12486267],
              [-65.35819244, 18.12347221],
              [-65.35902405, 18.12347221],
              [-65.35902405, 18.12680626],
              [-65.35930634, 18.12680626],
              [-65.35930634, 18.12708282],
              [-65.35958099, 18.12708282],
              [-65.35958099, 18.1273613],
              [-65.35986328, 18.1273613],
              [-65.35986328, 18.12763977],
              [-65.36042023, 18.12763977],
              [-65.36042023, 18.12791634],
              [-65.36069489, 18.12791634],
              [-65.36069489, 18.12874985],
              [-65.36125183, 18.12874985],
              [-65.36125183, 18.12958336],
              [-65.36152649, 18.12958336],
              [-65.36152649, 18.12986183],
              [-65.36180878, 18.12986183],
              [-65.36180878, 18.1301384],
              [-65.36374664, 18.1301384],
              [-65.36374664, 18.12597275],
              [-65.36402893, 18.12597275],
              [-65.36402893, 18.12569427],
              [-65.36430359, 18.12569427],
              [-65.36430359, 18.1254158],
              [-65.36486053, 18.1254158],
              [-65.36486053, 18.12513924],
              [-65.36541748, 18.12513924],
              [-65.36541748, 18.12402725],
              [-65.36513519, 18.12402725],
              [-65.36513519, 18.12291718],
              [-65.36486053, 18.12291718],
              [-65.36486053, 18.12264061],
              [-65.36458588, 18.12264061],
              [-65.36458588, 18.12180519],
              [-65.36430359, 18.12180519],
              [-65.36430359, 18.12069511],
              [-65.36458588, 18.12069511],
              [-65.36458588, 18.12013626],
              [-65.36597443, 18.12013626],
              [-65.36597443, 18.12041855],
              [-65.36624908, 18.12041855],
              [-65.36624908, 18.12235832],
              [-65.36736298, 18.12235832],
              [-65.36736298, 18.12264061],
              [-65.36763763, 18.12264061],
              [-65.36763763, 18.12319374],
              [-65.36791992, 18.12319374],
              [-65.36791992, 18.12430573],
              [-65.36819458, 18.12430573],
              [-65.36819458, 18.12486267],
              [-65.36846924, 18.12486267],
              [-65.36846924, 18.12513924],
              [-65.36875153, 18.12513924],
              [-65.36875153, 18.12486267],
              [-65.37069702, 18.12486267],
              [-65.37069702, 18.1245842],
              [-65.37264252, 18.1245842],
              [-65.37264252, 18.12402725],
              [-65.37374878, 18.12402725],
              [-65.37374878, 18.12347221],
              [-65.37430573, 18.12347221],
              [-65.37430573, 18.12264061],
              [-65.37458038, 18.12264061],
              [-65.37458038, 18.12208366],
              [-65.37430573, 18.12208366],
              [-65.37430573, 18.12069511],
              [-65.37458038, 18.12069511],
              [-65.37458038, 18.12041855],
              [-65.37430573, 18.12041855],
              [-65.37430573, 18.1198616],
              [-65.37403107, 18.1198616],
              [-65.37403107, 18.11958313],
              [-65.37374878, 18.11958313],
              [-65.37374878, 18.11930466],
              [-65.37347412, 18.11930466],
              [-65.37347412, 18.11902618],
              [-65.37291718, 18.11902618],
              [-65.37291718, 18.11874962],
              [-65.37264252, 18.11874962],
              [-65.37264252, 18.11819458],
              [-65.37208557, 18.11819458],
              [-65.37208557, 18.11736107],
              [-65.37180328, 18.11736107],
              [-65.37180328, 18.11624908],
              [-65.37208557, 18.11624908],
              [-65.37208557, 18.11569595],
              [-65.37291718, 18.11569595],
              [-65.37291718, 18.11597252],
              [-65.37319183, 18.11597252],
              [-65.37319183, 18.11624908],
              [-65.37374878, 18.11624908],
              [-65.37374878, 18.11652756],
              [-65.37403107, 18.11652756],
              [-65.37403107, 18.1170826],
              [-65.37430573, 18.1170826],
              [-65.37430573, 18.11763954],
              [-65.37513733, 18.11763954],
              [-65.37513733, 18.1179142],
              [-65.37541962, 18.1179142],
              [-65.37541962, 18.11763954],
              [-65.37569427, 18.11763954],
              [-65.37569427, 18.11736107],
              [-65.37625122, 18.11736107],
              [-65.37625122, 18.1170826],
              [-65.37652588, 18.1170826],
              [-65.37652588, 18.11652756],
              [-65.37625122, 18.11652756],
              [-65.37625122, 18.11624908],
              [-65.37596893, 18.11624908],
              [-65.37596893, 18.11569595],
              [-65.37569427, 18.11569595],
              [-65.37569427, 18.11513901],
              [-65.37596893, 18.11513901],
              [-65.37596893, 18.11347389],
              [-65.37625122, 18.11347389],
              [-65.37625122, 18.11291695],
              [-65.37652588, 18.11291695],
              [-65.37652588, 18.1115284],
              [-65.37680817, 18.1115284],
              [-65.37680817, 18.11125183],
              [-65.37652588, 18.11125183],
              [-65.37652588, 18.11096954],
              [-65.37596893, 18.11096954],
              [-65.37596893, 18.11069489],
              [-65.37513733, 18.11069489],
              [-65.37513733, 18.10930634],
              [-65.37486267, 18.10930634],
              [-65.37486267, 18.10902977],
              [-65.37430573, 18.10902977],
              [-65.37430573, 18.10874748],
              [-65.37403107, 18.10874748],
              [-65.37403107, 18.10763741],
              [-65.37430573, 18.10763741],
              [-65.37430573, 18.10680771],
              [-65.37569427, 18.10680771],
              [-65.37569427, 18.10708427],
              [-65.37625122, 18.10708427],
              [-65.37625122, 18.10736084],
              [-65.37708282, 18.10736084],
              [-65.37708282, 18.10763741],
              [-65.37735748, 18.10763741],
              [-65.37735748, 18.10819435],
              [-65.37763977, 18.10819435],
              [-65.37763977, 18.10791588],
              [-65.37791443, 18.10791588],
              [-65.37791443, 18.10708427],
              [-65.37819672, 18.10708427],
              [-65.37819672, 18.10680771],
              [-65.37902832, 18.10680771],
              [-65.37902832, 18.10763741],
              [-65.37875366, 18.10763741],
              [-65.37875366, 18.10847282],
              [-65.37847137, 18.10847282],
              [-65.37847137, 18.10874748],
              [-65.37819672, 18.10874748],
              [-65.37819672, 18.10930634],
              [-65.37791443, 18.10930634],
              [-65.37791443, 18.11041641],
              [-65.37819672, 18.11041641],
              [-65.37819672, 18.11096954],
              [-65.37847137, 18.11096954],
              [-65.37847137, 18.11125183],
              [-65.37930298, 18.11125183],
              [-65.37930298, 18.1115284],
              [-65.37958527, 18.1115284],
              [-65.37958527, 18.11125183],
              [-65.37985992, 18.11125183],
              [-65.37985992, 18.11096954],
              [-65.38069153, 18.11096954],
              [-65.38069153, 18.11180496],
              [-65.38097382, 18.11180496],
              [-65.38097382, 18.11208344],
              [-65.38124847, 18.11208344],
              [-65.38124847, 18.11236382],
              [-65.38153076, 18.11236382],
              [-65.38153076, 18.11263847],
              [-65.38208008, 18.11263847],
              [-65.38208008, 18.11236382],
              [-65.38319397, 18.11236382],
              [-65.38319397, 18.11208344],
              [-65.38375092, 18.11208344],
              [-65.38375092, 18.11180496],
              [-65.38402557, 18.11180496],
              [-65.38402557, 18.1115284],
              [-65.38458252, 18.1115284],
              [-65.38458252, 18.11180496],
              [-65.38486481, 18.11180496],
              [-65.38486481, 18.11208344],
              [-65.38513947, 18.11208344],
              [-65.38513947, 18.11236382],
              [-65.38625336, 18.11236382],
              [-65.38625336, 18.11263847],
              [-65.39069366, 18.11263847],
              [-65.39069366, 18.11180496],
              [-65.39097595, 18.11180496],
              [-65.39097595, 18.11096954],
              [-65.39125061, 18.11096954],
              [-65.39125061, 18.10986137],
              [-65.39208221, 18.10986137],
              [-65.39208221, 18.10763741],
              [-65.3923645, 18.10763741],
              [-65.3923645, 18.10708427],
              [-65.39402771, 18.10708427],
              [-65.39402771, 18.10736084],
              [-65.39430237, 18.10736084],
              [-65.39430237, 18.10986137],
              [-65.39458466, 18.10986137],
              [-65.39458466, 18.11013985],
              [-65.39541626, 18.11013985],
              [-65.39541626, 18.11041641],
              [-65.39569092, 18.11041641],
              [-65.39569092, 18.11069489],
              [-65.39597321, 18.11069489],
              [-65.39597321, 18.11096954],
              [-65.39624786, 18.11096954],
              [-65.39624786, 18.11125183],
              [-65.39653015, 18.11125183],
              [-65.39653015, 18.1115284],
              [-65.39736176, 18.1115284],
              [-65.39736176, 18.11125183],
              [-65.3979187, 18.11125183],
              [-65.3979187, 18.11041641],
              [-65.39819336, 18.11041641],
              [-65.39819336, 18.1095829],
              [-65.39847565, 18.1095829],
              [-65.39847565, 18.10930634],
              [-65.39875031, 18.10930634],
              [-65.39875031, 18.10902977],
              [-65.39902496, 18.10902977],
              [-65.39902496, 18.10874748],
              [-65.3998642, 18.10874748],
              [-65.3998642, 18.10902977],
              [-65.40013885, 18.10902977],
              [-65.40013885, 18.11125183],
              [-65.40041351, 18.11125183],
              [-65.40041351, 18.1115284],
              [-65.4015274, 18.1115284],
              [-65.4015274, 18.11125183],
              [-65.40180206, 18.11125183],
              [-65.40180206, 18.11096954],
              [-65.40208435, 18.11096954],
              [-65.40208435, 18.11069489],
              [-65.40235901, 18.11069489],
              [-65.40235901, 18.10930634],
              [-65.4026413, 18.10930634],
              [-65.4026413, 18.10874748],
              [-65.40458679, 18.10874748],
              [-65.40458679, 18.10847282],
              [-65.4054184, 18.10847282],
              [-65.4054184, 18.10791588],
              [-65.40625, 18.10791588],
              [-65.40625, 18.10847282],
              [-65.40652466, 18.10847282],
              [-65.40652466, 18.10930634],
              [-65.40625, 18.10930634],
              [-65.40625, 18.11013985],
              [-65.40597534, 18.11013985],
              [-65.40597534, 18.11041641],
              [-65.40569305, 18.11041641],
              [-65.40569305, 18.11096954],
              [-65.4054184, 18.11096954],
              [-65.4054184, 18.11291695],
              [-65.40597534, 18.11291695],
              [-65.40597534, 18.11347389],
              [-65.40736389, 18.11347389],
              [-65.40736389, 18.11375046],
              [-65.40847015, 18.11375046],
              [-65.40847015, 18.11347389],
              [-65.4098587, 18.11347389],
              [-65.4098587, 18.11263847],
              [-65.41014099, 18.11263847],
              [-65.41014099, 18.11236382],
              [-65.41041565, 18.11236382],
              [-65.41041565, 18.11180496],
              [-65.41069794, 18.11180496],
              [-65.41069794, 18.11125183],
              [-65.41014099, 18.11125183],
              [-65.41014099, 18.11096954],
              [-65.4098587, 18.11096954],
              [-65.4098587, 18.11069489],
              [-65.40958405, 18.11069489],
              [-65.40958405, 18.11013985],
              [-65.40930176, 18.11013985],
              [-65.40930176, 18.10902977],
              [-65.40763855, 18.10902977],
              [-65.40763855, 18.10874748],
              [-65.40791321, 18.10874748],
              [-65.40791321, 18.10847282],
              [-65.40763855, 18.10847282],
              [-65.40763855, 18.10819435],
              [-65.40736389, 18.10819435],
              [-65.40736389, 18.10680771],
              [-65.40791321, 18.10680771],
              [-65.40791321, 18.10652733],
              [-65.40930176, 18.10652733],
              [-65.40930176, 18.10625076],
              [-65.40958405, 18.10625076],
              [-65.40958405, 18.10597229],
              [-65.4118042, 18.10597229],
              [-65.4118042, 18.10791588],
              [-65.41208649, 18.10791588],
              [-65.41208649, 18.10847282],
              [-65.4126358, 18.10847282],
              [-65.4126358, 18.10874748],
              [-65.41486359, 18.10874748],
              [-65.41486359, 18.10847282],
              [-65.4154129, 18.10847282],
              [-65.4154129, 18.10819435],
              [-65.41652679, 18.10819435],
              [-65.41652679, 18.10791588],
              [-65.41708374, 18.10791588],
              [-65.41708374, 18.10763741],
              [-65.4173584, 18.10763741],
              [-65.4173584, 18.10708427],
              [-65.41764069, 18.10708427],
              [-65.41764069, 18.10652733],
              [-65.41791534, 18.10652733],
              [-65.41791534, 18.10625076],
              [-65.41819763, 18.10625076],
              [-65.41819763, 18.10486031],
              [-65.41847229, 18.10486031],
              [-65.41847229, 18.1040287],
              [-65.41819763, 18.1040287],
              [-65.41819763, 18.10375023],
              [-65.4173584, 18.10375023],
              [-65.4173584, 18.10347176],
              [-65.41708374, 18.10347176],
              [-65.41708374, 18.10319328],
              [-65.41680908, 18.10319328],
              [-65.41680908, 18.10236168],
              [-65.41708374, 18.10236168],
              [-65.41708374, 18.10208321],
              [-65.4173584, 18.10208321],
              [-65.4173584, 18.10180473],
              [-65.41791534, 18.10180473],
              [-65.41791534, 18.10152817],
              [-65.41902924, 18.10152817],
              [-65.41902924, 18.10124969],
              [-65.41986084, 18.10124969],
              [-65.41986084, 18.10152817],
              [-65.42069244, 18.10152817],
              [-65.42069244, 18.10180473],
              [-65.42180634, 18.10180473],
              [-65.42180634, 18.10347176],
              [-65.42236328, 18.10347176],
              [-65.42236328, 18.10375023],
              [-65.42263794, 18.10375023],
              [-65.42263794, 18.10458374],
              [-65.42292023, 18.10458374],
              [-65.42292023, 18.10486031],
              [-65.42319489, 18.10486031],
              [-65.42319489, 18.10513878],
              [-65.42346954, 18.10513878],
              [-65.42346954, 18.10597229],
              [-65.42375183, 18.10597229],
              [-65.42375183, 18.10625076],
              [-65.42402649, 18.10625076],
              [-65.42402649, 18.10652733],
              [-65.42430878, 18.10652733],
              [-65.42430878, 18.10680771],
              [-65.42458344, 18.10680771],
              [-65.42458344, 18.10708427],
              [-65.42485809, 18.10708427],
              [-65.42485809, 18.10763741],
              [-65.42430878, 18.10763741],
              [-65.42430878, 18.10791588],
              [-65.42402649, 18.10791588],
              [-65.42402649, 18.10819435],
              [-65.42430878, 18.10819435],
              [-65.42430878, 18.10847282],
              [-65.42402649, 18.10847282],
              [-65.42402649, 18.10874748],
              [-65.42430878, 18.10874748],
              [-65.42430878, 18.10902977],
              [-65.42458344, 18.10902977],
              [-65.42458344, 18.1095829],
              [-65.42514038, 18.1095829],
              [-65.42514038, 18.11013985],
              [-65.42541504, 18.11013985],
              [-65.42541504, 18.11069489],
              [-65.42569733, 18.11069489],
              [-65.42569733, 18.11096954],
              [-65.42597198, 18.11096954],
              [-65.42597198, 18.11125183],
              [-65.42708588, 18.11125183],
              [-65.42708588, 18.11096954],
              [-65.42763519, 18.11096954],
              [-65.42763519, 18.11125183],
              [-65.42847443, 18.11125183],
              [-65.42847443, 18.11096954],
              [-65.42930603, 18.11096954],
              [-65.42930603, 18.11069489],
              [-65.42958069, 18.11069489],
              [-65.42958069, 18.11013985],
              [-65.42986298, 18.11013985],
              [-65.42986298, 18.10986137],
              [-65.43069458, 18.10986137],
              [-65.43069458, 18.1095829],
              [-65.43096924, 18.1095829],
              [-65.43096924, 18.10930634],
              [-65.43180847, 18.10930634],
              [-65.43180847, 18.10902977],
              [-65.43208313, 18.10902977],
              [-65.43208313, 18.10874748],
              [-65.43264008, 18.10874748],
              [-65.43264008, 18.10847282],
              [-65.43347168, 18.10847282],
              [-65.43347168, 18.10819435],
              [-65.43374634, 18.10819435],
              [-65.43374634, 18.10763741],
              [-65.43402863, 18.10763741],
              [-65.43402863, 18.10708427],
              [-65.43597412, 18.10708427],
              [-65.43597412, 18.10652733],
              [-65.43569183, 18.10652733],
              [-65.43569183, 18.10625076],
              [-65.43486023, 18.10625076],
              [-65.43486023, 18.10597229],
              [-65.43430328, 18.10597229],
              [-65.43430328, 18.10541534],
              [-65.43374634, 18.10541534],
              [-65.43374634, 18.10569382],
              [-65.43264008, 18.10569382],
              [-65.43264008, 18.10541534],
              [-65.43069458, 18.10541534],
              [-65.43069458, 18.10513878],
              [-65.43041992, 18.10513878],
              [-65.43041992, 18.10458374],
              [-65.43013763, 18.10458374],
              [-65.43013763, 18.1040287],
              [-65.42986298, 18.1040287],
              [-65.42986298, 18.10375023],
              [-65.42680359, 18.10375023],
              [-65.42680359, 18.10347176],
              [-65.42652893, 18.10347176],
              [-65.42652893, 18.10375023],
              [-65.42624664, 18.10375023],
              [-65.42624664, 18.10319328],
              [-65.42597198, 18.10319328],
              [-65.42597198, 18.10263824],
              [-65.42569733, 18.10263824],
              [-65.42569733, 18.10208321],
              [-65.42541504, 18.10208321],
              [-65.42541504, 18.10152817],
              [-65.42485809, 18.10152817],
              [-65.42485809, 18.10124969],
              [-65.42430878, 18.10124969],
              [-65.42430878, 18.10097313],
              [-65.42402649, 18.10097313],
              [-65.42402649, 18.10069466],
              [-65.42375183, 18.10069466],
              [-65.42375183, 18.10041618],
              [-65.42292023, 18.10041618],
              [-65.42292023, 18.10013962],
              [-65.42236328, 18.10013962],
              [-65.42236328, 18.09986115],
              [-65.42208099, 18.09986115],
              [-65.42208099, 18.09902763],
              [-65.42236328, 18.09902763],
              [-65.42236328, 18.09874916],
              [-65.42263794, 18.09874916],
              [-65.42263794, 18.0984726],
              [-65.42292023, 18.0984726],
              [-65.42292023, 18.09819412],
              [-65.42319489, 18.09819412],
              [-65.42319489, 18.09791756],
              [-65.42346954, 18.09791756],
              [-65.42346954, 18.09763908],
              [-65.42375183, 18.09763908],
              [-65.42375183, 18.0973587],
              [-65.42402649, 18.0973587],
              [-65.42402649, 18.09680557],
              [-65.42346954, 18.09680557],
              [-65.42346954, 18.0965271],
              [-65.42319489, 18.0965271],
              [-65.42319489, 18.09597206],
              [-65.42346954, 18.09597206],
              [-65.42346954, 18.09569359],
              [-65.42375183, 18.09569359],
              [-65.42375183, 18.09541893],
              [-65.42402649, 18.09541893],
              [-65.42402649, 18.09514046],
              [-65.42485809, 18.09514046],
              [-65.42485809, 18.09486198],
              [-65.42541504, 18.09486198],
              [-65.42541504, 18.09458351],
              [-65.42569733, 18.09458351],
              [-65.42569733, 18.09430504],
              [-65.42597198, 18.09430504],
              [-65.42597198, 18.09402657],
              [-65.42763519, 18.09402657],
              [-65.42763519, 18.09374809],
              [-65.42791748, 18.09374809],
              [-65.42791748, 18.09347153],
              [-65.42958069, 18.09347153],
              [-65.42958069, 18.09319687],
              [-65.43041992, 18.09319687],
              [-65.43041992, 18.09374809],
              [-65.43096924, 18.09374809],
              [-65.43096924, 18.09319687],
              [-65.43125153, 18.09319687],
              [-65.43125153, 18.09291649],
              [-65.43152618, 18.09291649],
              [-65.43152618, 18.09263611],
              [-65.43264008, 18.09263611],
              [-65.43264008, 18.09236145],
              [-65.43291473, 18.09236145],
              [-65.43291473, 18.09208298],
              [-65.43347168, 18.09208298],
              [-65.43347168, 18.09152794],
            ],
          ],
          [
            [
              [-65.38402557, 18.11013985],
              [-65.38402557, 18.10986137],
              [-65.38346863, 18.10986137],
              [-65.38346863, 18.10930634],
              [-65.38319397, 18.10930634],
              [-65.38319397, 18.10874748],
              [-65.38291931, 18.10874748],
              [-65.38291931, 18.10819435],
              [-65.38263702, 18.10819435],
              [-65.38263702, 18.10708427],
              [-65.38236237, 18.10708427],
              [-65.38236237, 18.10597229],
              [-65.38208008, 18.10597229],
              [-65.38208008, 18.10541534],
              [-65.38291931, 18.10541534],
              [-65.38291931, 18.10486031],
              [-65.38430786, 18.10486031],
              [-65.38430786, 18.10513878],
              [-65.38458252, 18.10513878],
              [-65.38458252, 18.10569382],
              [-65.38486481, 18.10569382],
              [-65.38486481, 18.10708427],
              [-65.38458252, 18.10708427],
              [-65.38458252, 18.10763741],
              [-65.38486481, 18.10763741],
              [-65.38486481, 18.10791588],
              [-65.38513947, 18.10791588],
              [-65.38513947, 18.10819435],
              [-65.38541412, 18.10819435],
              [-65.38541412, 18.10902977],
              [-65.38486481, 18.10902977],
              [-65.38486481, 18.10986137],
              [-65.38458252, 18.10986137],
              [-65.38458252, 18.11013985],
              [-65.38402557, 18.11013985],
            ],
          ],
          [
            [
              [-65.4723587, 18.09013939],
              [-65.4723587, 18.08986092],
              [-65.4715271, 18.08986092],
              [-65.4715271, 18.08958244],
              [-65.47125244, 18.08958244],
              [-65.47125244, 18.08680534],
              [-65.47208405, 18.08680534],
              [-65.47208405, 18.08652687],
              [-65.47264099, 18.08652687],
              [-65.47264099, 18.08680534],
              [-65.47374725, 18.08680534],
              [-65.47374725, 18.08708382],
              [-65.47402954, 18.08708382],
              [-65.47402954, 18.08763885],
              [-65.4743042, 18.08763885],
              [-65.4743042, 18.0890274],
              [-65.47402954, 18.0890274],
              [-65.47402954, 18.08986092],
              [-65.47319794, 18.08986092],
              [-65.47319794, 18.09013939],
              [-65.4723587, 18.09013939],
            ],
          ],
          [
            [
              [-64.99986267, 17.99986076],
              [-65.00013733, 17.99986076],
              [-65.00013733, 18.00013733],
              [-64.99986267, 18.00013733],
              [-64.99986267, 17.99986076],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 75,
        name: 'Vieques',
        hasc_1: 'PR.VQ',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-65.57763672, 17.99986076, -64.99986267, 18.16291618],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.76',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.43031311, 18.08219147],
              [-66.43401337, 18.08360481],
              [-66.43724823, 18.08454132],
              [-66.43910217, 18.08501244],
              [-66.44371033, 18.0845108],
              [-66.4464798, 18.08402061],
              [-66.4524765, 18.08303642],
              [-66.45986938, 18.08348083],
              [-66.47927856, 18.08767319],
              [-66.48529816, 18.0919342],
              [-66.48947144, 18.09477425],
              [-66.49178314, 18.09619713],
              [-66.49317169, 18.09618759],
              [-66.50193787, 18.09566307],
              [-66.50515747, 18.09373856],
              [-66.5056076, 18.09182549],
              [-66.50236511, 18.08850288],
              [-66.50189972, 18.08755112],
              [-66.50234985, 18.08564186],
              [-66.50509644, 18.08229065],
              [-66.50740051, 18.08084488],
              [-66.51063538, 18.08177948],
              [-66.51804352, 18.08507919],
              [-66.52222443, 18.08982277],
              [-66.530159, 18.10695648],
              [-66.52694702, 18.10983467],
              [-66.52331543, 18.12226105],
              [-66.52845001, 18.13177109],
              [-66.52799988, 18.13415718],
              [-66.52800751, 18.13511658],
              [-66.52709961, 18.1389389],
              [-66.52664948, 18.14084816],
              [-66.5234375, 18.14516068],
              [-66.5220871, 18.15184784],
              [-66.51379395, 18.1557045],
              [-66.51103973, 18.15906334],
              [-66.51058197, 18.16002083],
              [-66.50598145, 18.16386032],
              [-66.50460052, 18.16434669],
              [-66.50136566, 18.16436195],
              [-66.49951935, 18.16484833],
              [-66.49675751, 18.16677284],
              [-66.48844147, 18.16633797],
              [-66.47736359, 18.16973877],
              [-66.47322083, 18.17214584],
              [-66.47093201, 18.1774025],
              [-66.47047424, 18.17788124],
              [-66.46908569, 18.17788506],
              [-66.46122742, 18.17697144],
              [-66.45845795, 18.17698669],
              [-66.45384216, 18.17844009],
              [-66.45245361, 18.17844772],
              [-66.44459534, 18.17658234],
              [-66.44319916, 18.17563248],
              [-66.43995667, 18.17326164],
              [-66.43903351, 18.17279053],
              [-66.42883301, 18.16473007],
              [-66.42928314, 18.16329575],
              [-66.42925262, 18.15661621],
              [-66.41997528, 18.14711952],
              [-66.41950989, 18.14569283],
              [-66.42131805, 18.13614464],
              [-66.42453003, 18.13231087],
              [-66.43045807, 18.11510849],
              [-66.42160797, 18.09750175],
              [-66.42758942, 18.09269905],
              [-66.42897034, 18.09126091],
              [-66.430336, 18.08695984],
              [-66.43031311, 18.08219147],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 76,
        name: 'Villalba',
        hasc_1: 'PR.VL',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.530159, 18.08084488, -66.41950989, 18.17844772],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.77',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-65.98853302, 18.05886459],
              [-65.99868774, 18.0602951],
              [-66.01068878, 18.06793022],
              [-66.01068878, 18.06888199],
              [-66.01022339, 18.07174873],
              [-66.01023102, 18.07556534],
              [-66.00699615, 18.07556534],
              [-66.00514984, 18.07604218],
              [-65.9996109, 18.08224487],
              [-65.9936142, 18.0898819],
              [-65.98484039, 18.08844757],
              [-65.97744751, 18.09512711],
              [-65.97006226, 18.08987617],
              [-65.96452332, 18.08701134],
              [-65.96406555, 18.08701134],
              [-65.96221924, 18.08653069],
              [-65.95806122, 18.08939171],
              [-65.95759583, 18.09034729],
              [-65.95574951, 18.0917778],
              [-65.95252228, 18.09273148],
              [-65.95021057, 18.09272766],
              [-65.94651794, 18.09129524],
              [-65.94421387, 18.08986282],
              [-65.94282532, 18.08938789],
              [-65.94190216, 18.08938789],
              [-65.93636322, 18.09176826],
              [-65.93359375, 18.09367561],
              [-65.93081665, 18.09701347],
              [-65.92804718, 18.1036911],
              [-65.92665863, 18.1089344],
              [-65.92572784, 18.11179543],
              [-65.92618561, 18.11991119],
              [-65.92479706, 18.12038231],
              [-65.91786957, 18.12228584],
              [-65.91694641, 18.12228394],
              [-65.91416931, 18.12323761],
              [-65.91278839, 18.12371254],
              [-65.90816498, 18.12561607],
              [-65.90631866, 18.12561607],
              [-65.90077972, 18.12560844],
              [-65.89477539, 18.1241703],
              [-65.88693237, 18.12177849],
              [-65.87769318, 18.11794853],
              [-65.87123108, 18.11269379],
              [-65.87031555, 18.10839653],
              [-65.86985016, 18.10839653],
              [-65.86523438, 18.10791397],
              [-65.86338806, 18.10886574],
              [-65.86384583, 18.11125183],
              [-65.86338043, 18.11268044],
              [-65.86014557, 18.11553383],
              [-65.85644531, 18.11696243],
              [-65.85506439, 18.11743546],
              [-65.85137177, 18.11790848],
              [-65.85090637, 18.11838722],
              [-65.84536743, 18.11503983],
              [-65.84260559, 18.11217117],
              [-65.84214783, 18.1102581],
              [-65.84353638, 18.10740089],
              [-65.84815979, 18.09929657],
              [-65.84355164, 18.09786224],
              [-65.84262848, 18.0964241],
              [-65.84078217, 18.09451294],
              [-65.8403244, 18.09308243],
              [-65.83802032, 18.09021759],
              [-65.83018494, 18.08590508],
              [-65.82187653, 18.08159828],
              [-65.81402588, 18.08158302],
              [-65.81172943, 18.0777626],
              [-65.81035614, 18.07442093],
              [-65.80897522, 18.07155228],
              [-65.80758667, 18.07107162],
              [-65.80158997, 18.07010651],
              [-65.79688263, 18.0696125],
              [-65.79652405, 18.06958389],
              [-65.79628754, 18.06958389],
              [-65.7951355, 18.0694294],
              [-65.7951355, 18.06930542],
              [-65.79458618, 18.06930542],
              [-65.79458618, 18.06902885],
              [-65.79430389, 18.06902885],
              [-65.79430389, 18.06847191],
              [-65.79597473, 18.06847191],
              [-65.79597473, 18.06819534],
              [-65.79624939, 18.06819534],
              [-65.79624939, 18.06763649],
              [-65.79652405, 18.06763649],
              [-65.79652405, 18.06680489],
              [-65.79680634, 18.06680489],
              [-65.79680634, 18.06624985],
              [-65.79652405, 18.06624985],
              [-65.79652405, 18.06430435],
              [-65.79736328, 18.06430435],
              [-65.79736328, 18.06458282],
              [-65.79902649, 18.06458282],
              [-65.79902649, 18.06402779],
              [-65.79958344, 18.06402779],
              [-65.79958344, 18.06319427],
              [-65.79985809, 18.06319427],
              [-65.79985809, 18.0629158],
              [-65.79958344, 18.0629158],
              [-65.79958344, 18.06180573],
              [-65.79930878, 18.06180573],
              [-65.79930878, 18.06097221],
              [-65.80097198, 18.06097221],
              [-65.80097198, 18.0601387],
              [-65.80124664, 18.0601387],
              [-65.80124664, 18.05875015],
              [-65.80152893, 18.05875015],
              [-65.80152893, 18.05819511],
              [-65.80208588, 18.05819511],
              [-65.80208588, 18.05791664],
              [-65.80291748, 18.05791664],
              [-65.80291748, 18.05763817],
              [-65.80374908, 18.05763817],
              [-65.80374908, 18.0573616],
              [-65.80708313, 18.0573616],
              [-65.80708313, 18.05708313],
              [-65.80735779, 18.05708313],
              [-65.80735779, 18.05680466],
              [-65.80764008, 18.05680466],
              [-65.80764008, 18.05652809],
              [-65.80958557, 18.05652809],
              [-65.80958557, 18.05680466],
              [-65.81014252, 18.05680466],
              [-65.81014252, 18.05708313],
              [-65.81069183, 18.05708313],
              [-65.81069183, 18.0573616],
              [-65.81097412, 18.0573616],
              [-65.81097412, 18.05763817],
              [-65.81124878, 18.05763817],
              [-65.81124878, 18.05847168],
              [-65.81153107, 18.05847168],
              [-65.81153107, 18.05875015],
              [-65.81180573, 18.05875015],
              [-65.81180573, 18.05930519],
              [-65.81208038, 18.05930519],
              [-65.81208038, 18.05958366],
              [-65.81236267, 18.05958366],
              [-65.81236267, 18.05986023],
              [-65.81375122, 18.05986023],
              [-65.81375122, 18.0601387],
              [-65.81458282, 18.0601387],
              [-65.81458282, 18.06041718],
              [-65.81485748, 18.06041718],
              [-65.81485748, 18.06069374],
              [-65.81513977, 18.06069374],
              [-65.81513977, 18.06097221],
              [-65.81485748, 18.06097221],
              [-65.81485748, 18.06180573],
              [-65.81513977, 18.06180573],
              [-65.81513977, 18.0620842],
              [-65.81541443, 18.0620842],
              [-65.81541443, 18.06236076],
              [-65.81569672, 18.06236076],
              [-65.81569672, 18.06263924],
              [-65.81597137, 18.06263924],
              [-65.81597137, 18.0629158],
              [-65.81791687, 18.0629158],
              [-65.81791687, 18.06263924],
              [-65.81903076, 18.06263924],
              [-65.81903076, 18.06236076],
              [-65.81958008, 18.06236076],
              [-65.81958008, 18.0620842],
              [-65.82013702, 18.0620842],
              [-65.82013702, 18.06180573],
              [-65.82041931, 18.06180573],
              [-65.82041931, 18.06097221],
              [-65.82096863, 18.06097221],
              [-65.82096863, 18.06041718],
              [-65.82152557, 18.06041718],
              [-65.82152557, 18.0601387],
              [-65.82180786, 18.0601387],
              [-65.82180786, 18.05986023],
              [-65.82263947, 18.05986023],
              [-65.82263947, 18.05958366],
              [-65.82319641, 18.05958366],
              [-65.82319641, 18.05902863],
              [-65.82347107, 18.05902863],
              [-65.82347107, 18.05847168],
              [-65.82375336, 18.05847168],
              [-65.82375336, 18.05819511],
              [-65.82402802, 18.05819511],
              [-65.82402802, 18.05763817],
              [-65.82430267, 18.05763817],
              [-65.82430267, 18.0573616],
              [-65.82458496, 18.0573616],
              [-65.82458496, 18.05708313],
              [-65.82485962, 18.05708313],
              [-65.82485962, 18.05652809],
              [-65.82514191, 18.05652809],
              [-65.82514191, 18.05625153],
              [-65.82569122, 18.05625153],
              [-65.82569122, 18.05597305],
              [-65.82597351, 18.05597305],
              [-65.82597351, 18.05569458],
              [-65.82624817, 18.05569458],
              [-65.82624817, 18.05541611],
              [-65.82707977, 18.05541611],
              [-65.82707977, 18.05513954],
              [-65.82763672, 18.05513954],
              [-65.82763672, 18.05291557],
              [-65.82736206, 18.05291557],
              [-65.82736206, 18.0526371],
              [-65.82707977, 18.0526371],
              [-65.82707977, 18.05208397],
              [-65.82875061, 18.05208397],
              [-65.82875061, 18.0526371],
              [-65.82958221, 18.0526371],
              [-65.82958221, 18.05291557],
              [-65.8298645, 18.05291557],
              [-65.8298645, 18.05319405],
              [-65.83041382, 18.05319405],
              [-65.83041382, 18.05347252],
              [-65.83069611, 18.05347252],
              [-65.83069611, 18.05430794],
              [-65.83125305, 18.05430794],
              [-65.83125305, 18.0545826],
              [-65.83180237, 18.0545826],
              [-65.83180237, 18.05485916],
              [-65.83208466, 18.05485916],
              [-65.83208466, 18.05541611],
              [-65.83235931, 18.05541611],
              [-65.83235931, 18.05513954],
              [-65.83347321, 18.05513954],
              [-65.83347321, 18.05485916],
              [-65.83374786, 18.05485916],
              [-65.83374786, 18.05430794],
              [-65.83430481, 18.05430794],
              [-65.83430481, 18.05402946],
              [-65.8345871, 18.05402946],
              [-65.8345871, 18.05374718],
              [-65.83486176, 18.05374718],
              [-65.83486176, 18.05347252],
              [-65.8354187, 18.05347252],
              [-65.8354187, 18.05319405],
              [-65.83625031, 18.05319405],
              [-65.83625031, 18.05291557],
              [-65.83652496, 18.05291557],
              [-65.83652496, 18.05236053],
              [-65.83680725, 18.05236053],
              [-65.83680725, 18.05069351],
              [-65.83652496, 18.05069351],
              [-65.83652496, 18.05013847],
              [-65.83625031, 18.05013847],
              [-65.83625031, 18.04986191],
              [-65.83597565, 18.04986191],
              [-65.83597565, 18.04958344],
              [-65.8345871, 18.04958344],
              [-65.8345871, 18.04986191],
              [-65.83430481, 18.04986191],
              [-65.83430481, 18.05013847],
              [-65.83374786, 18.05013847],
              [-65.83374786, 18.05069351],
              [-65.83347321, 18.05069351],
              [-65.83347321, 18.05097198],
              [-65.83291626, 18.05097198],
              [-65.83291626, 18.05125046],
              [-65.83208466, 18.05125046],
              [-65.83208466, 18.05097198],
              [-65.83152771, 18.05097198],
              [-65.83152771, 18.05069351],
              [-65.83125305, 18.05069351],
              [-65.83125305, 18.05013847],
              [-65.83097076, 18.05013847],
              [-65.83097076, 18.04958344],
              [-65.83125305, 18.04958344],
              [-65.83125305, 18.04874992],
              [-65.83152771, 18.04874992],
              [-65.83152771, 18.04736137],
              [-65.83180237, 18.04736137],
              [-65.83180237, 18.04680634],
              [-65.83208466, 18.04680634],
              [-65.83208466, 18.04513931],
              [-65.83235931, 18.04513931],
              [-65.83235931, 18.04319382],
              [-65.8326416, 18.04319382],
              [-65.8326416, 18.04264069],
              [-65.83291626, 18.04264069],
              [-65.83291626, 18.04124832],
              [-65.83319092, 18.04124832],
              [-65.83319092, 18.03847313],
              [-65.83291626, 18.03847313],
              [-65.83291626, 18.03819466],
              [-65.83235931, 18.03819466],
              [-65.83235931, 18.0368042],
              [-65.83208466, 18.0368042],
              [-65.83208466, 18.03486061],
              [-65.83180237, 18.03486061],
              [-65.83180237, 18.03458214],
              [-65.83152771, 18.03458214],
              [-65.83152771, 18.03430557],
              [-65.83125305, 18.03430557],
              [-65.83125305, 18.0340271],
              [-65.83097076, 18.0340271],
              [-65.83097076, 18.03319359],
              [-65.83041382, 18.03319359],
              [-65.83041382, 18.03263855],
              [-65.83013916, 18.03263855],
              [-65.83013916, 18.03236389],
              [-65.8298645, 18.03236389],
              [-65.8298645, 18.03208351],
              [-65.82930756, 18.03208351],
              [-65.82930756, 18.03180504],
              [-65.82902527, 18.03180504],
              [-65.82902527, 18.03125191],
              [-65.82875061, 18.03125191],
              [-65.82875061, 18.03097153],
              [-65.82847595, 18.03097153],
              [-65.82847595, 18.03013992],
              [-65.82930756, 18.03013992],
              [-65.82930756, 18.02958298],
              [-65.8298645, 18.02958298],
              [-65.8298645, 18.02930641],
              [-65.83041382, 18.02930641],
              [-65.83041382, 18.02902985],
              [-65.83069611, 18.02902985],
              [-65.83069611, 18.02874756],
              [-65.83125305, 18.02874756],
              [-65.83125305, 18.0284729],
              [-65.83152771, 18.0284729],
              [-65.83152771, 18.02819443],
              [-65.83180237, 18.02819443],
              [-65.83180237, 18.02541542],
              [-65.83208466, 18.02541542],
              [-65.83208466, 18.02375031],
              [-65.83235931, 18.02375031],
              [-65.83235931, 18.02208328],
              [-65.83208466, 18.02208328],
              [-65.83208466, 18.02180481],
              [-65.83041382, 18.02180481],
              [-65.83041382, 18.02152824],
              [-65.83013916, 18.02152824],
              [-65.83013916, 18.0209713],
              [-65.8298645, 18.0209713],
              [-65.8298645, 18.01986122],
              [-65.83013916, 18.01986122],
              [-65.83013916, 18.01902771],
              [-65.83041382, 18.01902771],
              [-65.83041382, 18.01874924],
              [-65.83069611, 18.01874924],
              [-65.83069611, 18.01847267],
              [-65.83097076, 18.01847267],
              [-65.83097076, 18.0181942],
              [-65.83152771, 18.0181942],
              [-65.83152771, 18.01791573],
              [-65.83208466, 18.01791573],
              [-65.83208466, 18.01736069],
              [-65.83235931, 18.01736069],
              [-65.83235931, 18.0154171],
              [-65.83208466, 18.0154171],
              [-65.83208466, 18.01402664],
              [-65.83291626, 18.01402664],
              [-65.83291626, 18.01458359],
              [-65.83319092, 18.01458359],
              [-65.83319092, 18.01514053],
              [-65.83347321, 18.01514053],
              [-65.83347321, 18.01569366],
              [-65.83403015, 18.01569366],
              [-65.83403015, 18.01597214],
              [-65.83430481, 18.01597214],
              [-65.83430481, 18.0134716],
              [-65.83403015, 18.0134716],
              [-65.83403015, 18.01236153],
              [-65.83374786, 18.01236153],
              [-65.83374786, 18.0118084],
              [-65.83403015, 18.0118084],
              [-65.83403015, 18.01152611],
              [-65.83486176, 18.01152611],
              [-65.83486176, 18.01208305],
              [-65.8354187, 18.01208305],
              [-65.8354187, 18.01236153],
              [-65.83597565, 18.01236153],
              [-65.83597565, 18.01263618],
              [-65.83652496, 18.01263618],
              [-65.83652496, 18.01319695],
              [-65.83680725, 18.01319695],
              [-65.83680725, 18.0134716],
              [-65.83708191, 18.0134716],
              [-65.83708191, 18.01374817],
              [-65.8373642, 18.01374817],
              [-65.8373642, 18.01402664],
              [-65.83791351, 18.01402664],
              [-65.83791351, 18.01430511],
              [-65.83875275, 18.01430511],
              [-65.83875275, 18.01458359],
              [-65.84041595, 18.01458359],
              [-65.84041595, 18.01486206],
              [-65.8418045, 18.01486206],
              [-65.8418045, 18.01514053],
              [-65.8429184, 18.01514053],
              [-65.8429184, 18.0154171],
              [-65.84347534, 18.0154171],
              [-65.84347534, 18.01569366],
              [-65.8445816, 18.01569366],
              [-65.8445816, 18.0154171],
              [-65.84513855, 18.0154171],
              [-65.84513855, 18.01514053],
              [-65.84541321, 18.01514053],
              [-65.84541321, 18.01486206],
              [-65.84597015, 18.01486206],
              [-65.84597015, 18.01458359],
              [-65.84625244, 18.01458359],
              [-65.84625244, 18.01430511],
              [-65.8465271, 18.01430511],
              [-65.8465271, 18.0134716],
              [-65.84680176, 18.0134716],
              [-65.84680176, 18.01319695],
              [-65.84708405, 18.01319695],
              [-65.84708405, 18.01291847],
              [-65.8473587, 18.01291847],
              [-65.8473587, 18.01263618],
              [-65.84791565, 18.01263618],
              [-65.84791565, 18.0118084],
              [-65.84819794, 18.0118084],
              [-65.84819794, 18.01152611],
              [-65.8493042, 18.01152611],
              [-65.8493042, 18.01135826],
              [-65.8493042, 18.01124954],
              [-65.85034943, 18.01124954],
              [-65.8506012, 18.0114975],
              [-65.85152435, 18.01244926],
              [-65.85382843, 18.01484299],
              [-65.85751343, 18.01675797],
              [-65.86212158, 18.02201653],
              [-65.86304474, 18.02344894],
              [-65.86442566, 18.02583885],
              [-65.86765289, 18.02584267],
              [-65.87181091, 18.02441597],
              [-65.87458038, 18.0234623],
              [-65.88104248, 18.02061081],
              [-65.88195801, 18.02252007],
              [-65.88794708, 18.03016472],
              [-65.89763641, 18.03399086],
              [-65.90640259, 18.03543282],
              [-65.90732574, 18.03591156],
              [-65.9119339, 18.03305054],
              [-65.92301941, 18.03496933],
              [-65.92993927, 18.03688622],
              [-65.9336319, 18.03688622],
              [-65.93408966, 18.03688622],
              [-65.94100952, 18.04023361],
              [-65.95162201, 18.04501534],
              [-65.95207977, 18.04453659],
              [-65.95761108, 18.05122185],
              [-65.95807648, 18.05217552],
              [-65.96453857, 18.05408669],
              [-65.96638489, 18.05408859],
              [-65.96915436, 18.05313301],
              [-65.97238159, 18.0526619],
              [-65.97377014, 18.05456543],
              [-65.97792053, 18.06124687],
              [-65.9871521, 18.05933952],
              [-65.98853302, 18.05886459],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 77,
        name: 'Yabucoa',
        hasc_1: 'PR.YB',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.01068878, 18.01124954, -65.79430389, 18.12561607],
    },
    {
      type: 'Feature',
      id: 'zj165kf9377.78',
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-66.85912323, 17.95430565],
              [-66.8622818, 17.96221161],
              [-66.86322784, 17.96506882],
              [-66.86388397, 17.98750114],
              [-66.86299896, 17.99180603],
              [-66.86302185, 17.99467087],
              [-66.86305237, 17.99801254],
              [-66.8686676, 18.00702477],
              [-66.87519073, 18.01412392],
              [-66.87704468, 18.01553917],
              [-66.87797546, 18.01648331],
              [-66.88450623, 18.02453232],
              [-66.88497925, 18.02500725],
              [-66.8868866, 18.03215027],
              [-66.89061737, 18.03688622],
              [-66.89293671, 18.03781891],
              [-66.89526367, 18.04018021],
              [-66.89620209, 18.04160309],
              [-66.89852905, 18.04444313],
              [-66.89994049, 18.04681778],
              [-66.90093231, 18.05444336],
              [-66.90330505, 18.06158257],
              [-66.90331268, 18.06205559],
              [-66.90377045, 18.06205177],
              [-66.90981293, 18.06628609],
              [-66.91311646, 18.07484245],
              [-66.9131546, 18.07865906],
              [-66.91362762, 18.08008575],
              [-66.91413116, 18.08437729],
              [-66.91460419, 18.08532715],
              [-66.91880035, 18.09005547],
              [-66.91881561, 18.09100914],
              [-66.91928101, 18.09195709],
              [-66.91928864, 18.09291458],
              [-66.91752625, 18.10199738],
              [-66.9217453, 18.10863113],
              [-66.92411804, 18.11528969],
              [-66.91815948, 18.11964226],
              [-66.91770172, 18.12012482],
              [-66.91724396, 18.12108421],
              [-66.91683197, 18.12633324],
              [-66.91873169, 18.13156509],
              [-66.92484283, 18.14295006],
              [-66.92578888, 18.14580727],
              [-66.92630005, 18.15056992],
              [-66.92630005, 18.15104675],
              [-66.92538452, 18.15153503],
              [-66.91524506, 18.15402031],
              [-66.91152954, 18.15167046],
              [-66.90408325, 18.14649773],
              [-66.9031601, 18.14603043],
              [-66.90131378, 18.14652634],
              [-66.89116669, 18.14805412],
              [-66.89025116, 18.14901733],
              [-66.88981628, 18.15236282],
              [-66.88890839, 18.15332794],
              [-66.88751984, 18.15333748],
              [-66.88612366, 18.15239525],
              [-66.878685, 18.14674377],
              [-66.87541199, 18.14248276],
              [-66.87030029, 18.13871384],
              [-66.86289978, 18.13830566],
              [-66.86151886, 18.13832092],
              [-66.85967255, 18.13833618],
              [-66.85827637, 18.1378727],
              [-66.85689545, 18.13836288],
              [-66.85597992, 18.13932419],
              [-66.85419464, 18.14602089],
              [-66.85018158, 18.16275406],
              [-66.84786224, 18.1622982],
              [-66.84369659, 18.16090584],
              [-66.83866882, 18.1671524],
              [-66.8377533, 18.16811371],
              [-66.8368454, 18.17050743],
              [-66.82714844, 18.1710701],
              [-66.82388306, 18.16728783],
              [-66.82613373, 18.16058731],
              [-66.82613373, 18.16011047],
              [-66.82426453, 18.15774345],
              [-66.82099152, 18.1530056],
              [-66.81357574, 18.14972878],
              [-66.806633, 18.14835739],
              [-66.80432129, 18.14838028],
              [-66.80337524, 18.14552307],
              [-66.80382538, 18.14409065],
              [-66.80335236, 18.14266205],
              [-66.80194092, 18.13981247],
              [-66.79773712, 18.13364792],
              [-66.79721832, 18.12649727],
              [-66.79625702, 18.12221336],
              [-66.79624939, 18.1212616],
              [-66.80313873, 18.11690521],
              [-66.80819702, 18.11447525],
              [-66.81004333, 18.11398125],
              [-66.8105011, 18.1135006],
              [-66.81047821, 18.1111145],
              [-66.80665588, 18.09540367],
              [-66.8052597, 18.0939827],
              [-66.80246735, 18.09114456],
              [-66.81387329, 18.07482338],
              [-66.81615448, 18.07146263],
              [-66.81752014, 18.06906319],
              [-66.81839752, 18.06380463],
              [-66.8188324, 18.06045914],
              [-66.81834412, 18.05760193],
              [-66.8242569, 18.04657173],
              [-66.82423401, 18.04418755],
              [-66.81858063, 18.03039742],
              [-66.81811523, 18.02944756],
              [-66.81856537, 18.02801132],
              [-66.82955933, 18.01884651],
              [-66.83087158, 18.01024055],
              [-66.8266983, 18.0078907],
              [-66.82617187, 17.99978256],
              [-66.82846832, 17.99880409],
              [-66.8307724, 17.99878502],
              [-66.83213806, 17.99686241],
              [-66.83213806, 17.99638748],
              [-66.83529663, 17.9882431],
              [-66.83613586, 17.97868729],
              [-66.83653259, 17.9705677],
              [-66.83648682, 17.96579361],
              [-66.8529129, 17.95425415],
              [-66.8529129, 17.95458221],
              [-66.85319519, 17.95458221],
              [-66.85319519, 17.95486069],
              [-66.85346985, 17.95486069],
              [-66.85346985, 17.95513916],
              [-66.85375214, 17.95513916],
              [-66.85375214, 17.95541573],
              [-66.85402679, 17.95541573],
              [-66.85402679, 17.9556942],
              [-66.85458374, 17.9556942],
              [-66.85458374, 17.95597267],
              [-66.8548584, 17.95597267],
              [-66.8548584, 17.9556942],
              [-66.85736084, 17.9556942],
              [-66.85736084, 17.95513916],
              [-66.8576355, 17.95513916],
              [-66.8576355, 17.95486069],
              [-66.85847473, 17.95486069],
              [-66.85847473, 17.95458221],
              [-66.85902405, 17.95458221],
              [-66.85902405, 17.95430565],
              [-66.85912323, 17.95430565],
            ],
          ],
        ],
      },
      geometry_name: 'geom',
      properties: {
        id_0: 183,
        iso: 'PRI',
        name_0: 'Puerto Rico',
        id_1: 78,
        name: 'Yauco',
        hasc_1: 'PR.YU',
        ccn_1: 0,
        cca_1: null,
        type_1: 'Municipio',
        engtype_1: 'Municipality',
        nl_name_1: null,
        varname_1: null,
      },
      bbox: [-66.92630005, 17.95425415, -66.79624939, 18.1710701],
    },
  ],
};

export default boundaries;
