import { MapPinIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import * as turf from '@turf/turf';
import { isMobile } from 'react-device-detect';
import axios from 'axios';
import IconButton from '../../IconButton';
import { useMap } from '../../../../context/MapProvider';
import {
  activeAtom,
  bufferAtom,
  dataAtom,
  filterAtom,
  geoLocationAtom,
  loadingPositionAtom,
  municipalityAtom,
  positionAtom,
  showAtom,
  sidebarAtom,
  subsetAtom,
} from '../../../../atoms/atoms';

function Buffer() {
  const { map } = useMap();
  const data = useRecoilValue(dataAtom);
  const setOpen = useSetRecoilState(sidebarAtom);
  const setSubset = useSetRecoilState(subsetAtom);
  const setBuffer = useSetRecoilState(bufferAtom);
  const setPosition = useSetRecoilState(positionAtom);
  const setGeolocation = useSetRecoilState(geoLocationAtom);
  const setShow = useSetRecoilState(showAtom);
  const setActive = useSetRecoilState(activeAtom);
  const setMunicipality = useSetRecoilState(municipalityAtom);
  const setFilter = useSetRecoilState(filterAtom);
  const [loading, setLoading] = useRecoilState(loadingPositionAtom);

  const emptyGeoJSON = {
    type: 'FeatureCollection',
    features: [],
  };

  const getRoute = async (originLng, originLat, waypointLng, waypointLat) => {
    const res = await axios.get(
      `https://api.mapbox.com/directions/v5/mapbox/driving-traffic/${originLng},${originLat};${waypointLng},${waypointLat}?steps=true&alternatives=false&geometries=geojson&language=es&access_token=pk.eyJ1IjoicHJzY2llbmNldHJ1c3QiLCJhIjoiY2w1OGZpeWN5MXpkNTNkbjk4eG5mOXhibiJ9.3YTjKwV6V1h6C5DgLMQZVg`
    );
    return res.data.routes[0];
  };

  const smoothBuffer = (center, radiusInKm, points = 64) => {
    const coords = {
      latitude: center[1],
      longitude: center[0],
    };
    const km = radiusInKm;
    const ret = [];
    const distanceX =
      km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
    const distanceY = km / 110.574;
    let theta;
    let x;
    let y;
    for (let i = 0; i < points; i += 1) {
      theta = (i / points) * (2 * Math.PI);
      x = distanceX * Math.cos(theta);
      y = distanceY * Math.sin(theta);
      ret.push([coords.longitude + x, coords.latitude + y]);
    }
    ret.push(ret[0]);
    return {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [ret],
      },
    };
  };

  const geoLocate = async (lng, lat) => {
    const location = turf.point([lng, lat]);

    const b = turf.buffer(turf.point([lng, lat]), 3, {
      units: 'miles',
    });

    const box = turf.bbox(b);

    map.fitBounds(box, {
      linear: true,
      easing(x) {
        return x * x * x;
      },
      padding: { top: 100, bottom: 100, left: 100, right: 100 },
    });

    map.getSource('buffer').setData(smoothBuffer([lng, lat], 4.82803));

    map.getSource('geolocation').setData(location);

    const geojson = {
      type: 'FeatureCollection',
      features: [],
    };

    for (let i = 0; i < data.features.length; i++) {
      if (data.features[i].properties.lat && data.features[i].properties.lng) {
        const pt = turf.point([
          data.features[i].properties.lng,
          data.features[i].properties.lat,
        ]);
        const pts = turf.booleanPointInPolygon(pt, b);

        if (pts) {
          const dir = await getRoute(
            lng,
            lat,
            data.features[i].properties.lng,
            data.features[i].properties.lat
          );

          geojson.features.push(
            turf.point(
              [
                data.features[i].properties.lng,
                data.features[i].properties.lat,
              ],
              {
                ...data.features[i].properties,
                route: dir,
              }
            )
          );
        }
      }
    }

    map.getSource('shelters').setData(geojson);
    setSubset(geojson);
    setGeolocation(location);
    setBuffer(smoothBuffer([lng, lat], 4.82803));
    setLoading(false);
    setShow(true);
    if (isMobile) {
      setOpen(true);
    }
  };

  return (
    <IconButton
      Icon={MapPinIcon}
      tooltip="Facilidades cercanas (3 millas)"
      tooltipPlacement="bottom"
      loading={loading}
      disabled={loading}
      onClick={() => {
        navigator.geolocation.getCurrentPosition(
          (res) => {
            setPosition(res.coords);
            setMunicipality(null);
            setLoading(true);
            setFilter({});
            setActive(null);
            map.getSource('municipality').setData(emptyGeoJSON);
            geoLocate(res.coords.longitude, res.coords.latitude);
          },
          (err) => console.log(err),
          { maximumAge: 60000, timeout: 5000, enableHighAccuracy: true }
        );
      }}
    />
  );
}

export default Buffer;
