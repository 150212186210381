import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { AnimatePresence, motion } from 'framer-motion';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { XMarkIcon } from '@heroicons/react/20/solid';
import * as turf from '@turf/turf';
import {
  activeAtom,
  bufferAtom,
  routeAtom,
  stepAtom,
  waypointAtom,
} from '../../atoms/atoms';
import { useMap } from '../../context/MapProvider';

function Progress() {
  const { map } = useMap();
  const buffer = useRecoilValue(bufferAtom);
  const setWaypoint = useSetRecoilState(waypointAtom);
  const [route, setRoute] = useRecoilState(routeAtom);
  const [active, setActive] = useRecoilState(activeAtom);
  const [steps, setSteps] = useState([]);
  const [step, setStep] = useRecoilState(stepAtom);

  useEffect(() => {
    if (route) {
      setSteps(route?.properties?.legs?.[0].steps);
    }
  }, [route]);

  return (
    <AnimatePresence>
      {route && (
        <motion.nav
          aria-label="Progress"
          initial={{ opacity: 0, x: '-110%' }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: '-110%' }}
          transition={{ duration: 1, delay: 1 }}
          className="text-sm lg:w-96 border-l border-neutral-700 hidden lg:flex flex-col flex-shrink-0 bg-neutral-900/90 text-neutral-200 h-full absolute z-50 p-4"
        >
          <div className="flex justify-end mb-4">
            <button
              type="button"
              className="text-neutral-100 hover:text-neutral-500 ring-0 focus:ring-0 outline-none focus:outline-none transition duration-500 ease-in-out"
              onClick={() => {
                const emptyGeoJSON = {
                  type: 'FeatureCollection',
                  features: [],
                };

                const box = turf.bbox(buffer);

                map.fitBounds(box, {
                  padding: { top: 100, bottom: 100, left: 100, right: 100 },
                });

                setActive(null);
                setRoute(null);
                setWaypoint(null);
                setStep(null);
                map.getSource('route').setData(emptyGeoJSON);
                map.getSource('waypoint').setData(emptyGeoJSON);
                map.getSource('step').setData(emptyGeoJSON);
              }}
            >
              <XMarkIcon className="x-5 w-5" />
            </button>
          </div>
          <ol className="overflow-y-auto scrollbar-hide">
            <li className="pb-8 relative">
              <div
                className="absolute left-2 top-4 -ml-px mt-0.5 h-full w-0.5 bg-neutral-600"
                aria-hidden="true"
              />
              <div className="group relative flex items-start">
                <span className="flex h-6 items-center" aria-hidden="true">
                  <span className="relative z-10 flex h-4 w-4 items-center justify-center rounded-full border-2 border-white bg-[#3bb2d0]">
                    <span className="h-2.5 w-2.5 rounded-full bg-transparent" />
                  </span>
                </span>
                <span className="ml-4 flex min-w-0 flex-col">
                  <span className="text-sm font-medium text-neutral-200">
                    Mi localizacion
                  </span>
                  <span className="text-sm text-neutral-400">
                    Via: {route?.properties?.legs?.[0]?.summary}
                  </span>
                </span>
              </div>
            </li>
            {_.map(steps, (s, index) => (
              <li key={`step-${index}`} className={clsx('relative pb-8')}>
                <div
                  className="absolute left-2 top-4 -ml-px mt-0.5 h-full w-0.5 bg-neutral-600"
                  aria-hidden="true"
                />
                <div className="group relative flex items-start">
                  <span
                    className="flex h-6 items-center cursor-pointer"
                    aria-hidden="true"
                  >
                    <button
                      type="button"
                      onClick={() => {
                        map.flyTo({
                          center: s?.maneuver?.location,
                          zoom: 17,
                        });
                        const geoJSON = {
                          type: 'FeatureCollection',
                          features: [
                            {
                              type: 'Feature',
                              properties: s,
                              geometry: {
                                coordinates: s?.maneuver?.location,
                                type: 'Point',
                              },
                            },
                          ],
                        };
                        setStep(geoJSON);
                        map.getSource('step').setData(geoJSON);
                      }}
                      className="relative z-10 flex h-4 w-4 items-center justify-center rounded-full border-2 border-neutral-500 bg-neutral-700"
                    >
                      <span
                        className={clsx(
                          step?.features?.[0].properties === s
                            ? 'bg-amber-400 ring-2 ring-amber-200'
                            : 'bg-transparent hover:bg-neutral-500',
                          'h-2.5 w-2.5 rounded-full transition duration-500 ease-in-out'
                        )}
                      />
                    </button>
                  </span>
                  <span className="ml-4 flex min-w-0 flex-col">
                    <span className="text-sm font-medium text-neutral-200">
                      {s?.name}
                    </span>
                    <span className="text-sm text-neutral-400">
                      {s?.maneuver?.instruction}
                    </span>
                  </span>
                </div>
              </li>
            ))}
            <li className="pb-8 relative">
              <div className="group relative flex items-start">
                <span className="flex h-6 items-center" aria-hidden="true">
                  <span className="relative z-10 flex h-4 w-4 items-center justify-center rounded-full border-2 border-white bg-[#8a8bc9]">
                    <span className="h-2.5 w-2.5 rounded-full bg-transparent" />
                  </span>
                </span>
                <span className="ml-4 flex min-w-0 flex-col">
                  <span className="text-sm font-medium text-neutral-200">
                    {active?.properties?.name}
                  </span>
                  <span className="text-sm text-neutral-400">
                    {active?.properties?.address}
                  </span>
                </span>
              </div>
            </li>
          </ol>
        </motion.nav>
      )}
    </AnimatePresence>
  );
}

export default Progress;
