import React from 'react';

function Footer() {
  return (
    <div className="h-10 flex items-center justify-between space-x-4 text-white text-xs font-medium bg-gradient-to-r from-orange-600 via-yellow-500 to-lime-500 to-90% px-4">
      <div>#WeAreTheTrust</div>
      <div>prsciencetrust.org</div>
    </div>
  );
}

export default Footer;