const shelters = {
  type: 'FeatureCollection',
  name: 'updated_shelters_clinics_official_',
  crs: {
    type: 'name',
    properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' },
  },
  features: [
    {
      type: 'Feature',
      properties: {
        id: '1',
        municipality: 'Arecibo',
        name: 'Atlantic Clinica Arecibo',
        address: 'Carr #636 Km 2, Sabana Hojos, Arecibo',
        lat: 18.41637861300006,
        lng: -66.611184970999943,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.611184970999943, 18.41637861300006],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '2',
        municipality: 'Barceloneta',
        name: 'Atlantic Clinica Barceloneta',
        address: 'Carr #2 Km 17.8, Cruce Dávila, Barceloneta',
        lat: 18.430082106000039,
        lng: -66.564021485999945,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.564021485999945, 18.430082106000039],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '3',
        municipality: 'Camuy',
        name: 'Camuy Clinica Ave Munoz Rivera ',
        address: 'Ave. Muñoz Marin #63',
        lat: 18.484257225000022,
        lng: -66.843057803999955,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.843057803999955, 18.484257225000022],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '4',
        municipality: 'Camuy',
        name: 'Camuy Clinica Bo Quebrada',
        address: 'Carr 455 Km 2.1 Bo. Quebrada, Camuy',
        lat: 18.355908732000049,
        lng: -66.834857140999986,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.834857140999986, 18.355908732000049],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '5',
        municipality: 'Toa Baja',
        name: 'Cancha Bajo Techo Esc. Basilio Milán (Siglo XXI)',
        address: 'Ave. Sabana Seca esquina Ave. José De Diego, Toa Baja',
        lat: 18.43839,
        lng: -66.17358,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.17358, 18.43839] },
    },
    {
      type: 'Feature',
      properties: {
        id: '6',
        municipality: 'Lajas',
        name: 'Cancha Juan E. Lluch',
        address: 'Carr 315 Km 0.8 Bo. Pueblo, Lajas',
        lat: 18.0428,
        lng: -67.0579,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.0579, 18.0428] },
    },
    {
      type: 'Feature',
      properties: {
        id: '7',
        municipality: 'Adjuntas',
        name: 'Castaner Clinica Adjuntas',
        address:
          'Carr. 123 # 44 km. 35.7 Bo. Garzas, Adjuntas, PR 00601 Antiguo local de Fomento\n\n',
        lat: 18.166071927000079,
        lng: -66.728882681999949,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.728882681999949, 18.166071927000079],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '8',
        municipality: 'Jayuya',
        name: 'Castaner Clinica Jayuya',
        address: '49 c/Guillermo Esteves #141, Jayuya',
        lat: 18.219626816000069,
        lng: -66.590120669999976,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.590120669999976, 18.219626816000069],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '9',
        municipality: 'Lares',
        name: 'Castaner Clinica Lares',
        address: 'PR-135 KM 4.5,Castañer, Lares',
        lat: 18.180984637000051,
        lng: -66.83322276499996,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.83322276499996, 18.180984637000051],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '10',
        municipality: 'San Juan ',
        name: 'CDT Hoare Salon de los Inmortales ',
        address: 'C. Cerra, San Juan, 00907, Puerto Rico',
        lat: 18.447216271791198,
        lng: -66.081977614546403,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.081977614546403, 18.447216271791198],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '11',
        municipality: 'Hormigueros',
        name: 'Centro Comunal  Nelson Del Valle',
        address: 'Urb Valle Hermoso Arriba',
        lat: 18.15073,
        lng: -67.14025,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.14025, 18.15073] },
    },
    {
      type: 'Feature',
      properties: {
        id: '12',
        municipality: 'Peñuelas',
        name: 'Centro Comunal Alturas',
        address: 'Bo. Cotto Quebradas Urb. Alturas 11',
        lat: 18.0613,
        lng: -66.73244,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.73244, 18.0613] },
    },
    {
      type: 'Feature',
      properties: {
        id: '13',
        municipality: 'Cayey',
        name: 'Centro Comunal Alturas de Monte Llano     ',
        address: 'Ave. R. Barceló, Alturas de Monte Llano',
        lat: 18.1231,
        lng: -66.1446,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.1446, 18.1231] },
    },
    {
      type: 'Feature',
      properties: {
        id: '14',
        municipality: 'Río Grande    ',
        name: 'Centro Comunal Alturas de Rio Grande',
        address: 'Calle 5 Alturas de Rio Grande',
        lat: 18.378,
        lng: -65.845,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.845, 18.378] },
    },
    {
      type: 'Feature',
      properties: {
        id: '15',
        municipality: 'Arecibo',
        name: 'Centro Comunal Antonio Márquez Arbona',
        address: 'Hato Abajo, Arecibo 00612',
        lat: 18.4683,
        lng: -66.7358,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.7358, 18.4683] },
    },
    {
      type: 'Feature',
      properties: {
        id: '16',
        municipality: 'Ponce     ',
        name: 'Centro comunal Arístides Chavier',
        address: 'Ave. Roosvelt , Bo Canas',
        lat: 18.00823,
        lng: -66.63116,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.63116, 18.00823] },
    },
    {
      type: 'Feature',
      properties: {
        id: '17',
        municipality: 'San Juan ',
        name: 'Centro Comunal Barrio Obrero',
        address: 'Avenida Borinquen en Barrio Obrero Santurce',
        lat: 18.4374958876951,
        lng: -66.054991334487696,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.054991334487696, 18.4374958876951],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '18',
        municipality: 'Yauco           ',
        name: 'Centro Comunal Bo. Naranjo',
        address: 'Cerr. 303 Km.2.8',
        lat: 18.116,
        lng: -66.848,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.848, 18.116] },
    },
    {
      type: 'Feature',
      properties: {
        id: '19',
        municipality: 'San Juan ',
        name: 'Centro Comunal Buen Consejo',
        address: '',
        lat: 18.395191397852699,
        lng: -66.048324592710898,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.048324592710898, 18.395191397852699],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '20',
        municipality: 'Guayama',
        name: 'Centro Comunal Carite',
        address: 'Bo. Carite Sec. Cambrene carr. 179 Km10.3',
        lat: 18.054,
        lng: -66.0916,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.0916, 18.054] },
    },
    {
      type: 'Feature',
      properties: {
        id: '21',
        municipality: 'Hormigueros',
        name: 'Centro Comunal Carr Nueva',
        address: 'Carr. 344 Hormigueros  Km. 2.9 bo. Jaguita',
        lat: 18.1556,
        lng: -67.11267,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.11267, 18.1556] },
    },
    {
      type: 'Feature',
      properties: {
        id: '22',
        municipality: 'Isabela',
        name: 'Centro Comunal de la Juventud ',
        address: 'Ave. Hernandez Ortiz 00662',
        lat: 18.49857,
        lng: -67.02214,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.02214, 18.49857] },
    },
    {
      type: 'Feature',
      properties: {
        id: '23',
        municipality: 'Hatillo',
        name: 'Centro comunal Edelmiro Vélez',
        address: 'Carr. 487 Km. 8.3 Bo. Bayaney',
        lat: 18.3642,
        lng: -66.8057,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.8057, 18.3642] },
    },
    {
      type: 'Feature',
      properties: {
        id: '24',
        municipality: 'San Germán',
        name: 'Centro Comunal El Recreo',
        address: 'Residencial El Recreo',
        lat: 18.07741,
        lng: -67.0323,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.0323, 18.07741] },
    },
    {
      type: 'Feature',
      properties: {
        id: '25',
        municipality: 'Utuado',
        name: 'Centro Comunal Fernando L. García',
        address: 'Ave. Fernando Rivas Dominicci, Carr. 111, Km 1.4',
        lat: 18.2687,
        lng: -66.6987,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.6987, 18.2687] },
    },
    {
      type: 'Feature',
      properties: {
        id: '26',
        municipality: 'Guánica',
        name: 'Centro Comunal Fuig',
        address: 'Carr. 331 Bo. Fuig',
        lat: 17.98847,
        lng: -66.92147,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.92147, 17.98847] },
    },
    {
      type: 'Feature',
      properties: {
        id: '27',
        municipality: 'Toa Baja',
        name: 'Centro Comunal Jesusa Matías - Sabana Seca',
        address: 'Sabana Seca, Toa Baja',
        lat: 18.429873,
        lng: -66.185291,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.185291, 18.429873],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '28',
        municipality: 'Toa Baja',
        name: 'Centro Comunal Jose Pipo Negron',
        address: 'Calle Aetria Bo. Ingenio',
        lat: 18.444742060324,
        lng: -66.228584595475795,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.228584595475795, 18.444742060324],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '29',
        municipality: 'Barceloneta',
        name: 'Centro comunal Magueyes',
        address: 'Carr. 664 int carr 140 ',
        lat: 18.4211,
        lng: -66.5721,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.5721, 18.4211] },
    },
    {
      type: 'Feature',
      properties: {
        id: '30',
        municipality: 'Comerío ',
        name: 'Centro Comunal Manuel Martorrel',
        address: 'Carr. 156 Barriada pasarell (frente Puente Pasarell)',
        lat: 18.22111797,
        lng: -66.22250611,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.22250611, 18.22111797],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '31',
        municipality: 'Toa Baja',
        name: 'Centro Comunal Pájaros',
        address: 'Carr. 863 KM 1 Bo. Pajaros',
        lat: 18.399,
        lng: -66.2103,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.2103, 18.399] },
    },
    {
      type: 'Feature',
      properties: {
        id: '32',
        municipality: 'Santa Isabel  ',
        name: 'Centro Comunal Pedro Descartes',
        address: 'Calle General juan Contreras, Santa Isabel, PR, 00757',
        lat: 17.966,
        lng: -66.402,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.402, 17.966] },
    },
    {
      type: 'Feature',
      properties: {
        id: '33',
        municipality: 'Guánica',
        name: 'Centro Comunal Playa Santa',
        address: 'Carr. 325 Bo. Playa Santa',
        lat: 17.94038,
        lng: -66.95186,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.95186, 17.94038] },
    },
    {
      type: 'Feature',
      properties: {
        id: '34',
        municipality: 'San Germán',
        name: 'Centro comunal Poblado Rosario',
        address: 'Carr. 348 Km 9 Rosario',
        lat: 18.16088,
        lng: -67.0791,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.0791, 18.16088] },
    },
    {
      type: 'Feature',
      properties: {
        id: '35',
        municipality: 'Guayama ',
        name: 'Centro Comunal Pueblito Carmen',
        address: 'Carr 172 Pueblito del Carmen Guayana',
        lat: 18.0317,
        lng: -66.1717,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.1717, 18.0317] },
    },
    {
      type: 'Feature',
      properties: {
        id: '36',
        municipality: 'Utuado',
        name: 'Centro Comunal Res. Jardines de Utuado',
        address: 'Res. Jardines de Utuado, Carr 111, (Frente al Parque)',
        lat: 18.2707,
        lng: -66.7008,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.7008, 18.2707] },
    },
    {
      type: 'Feature',
      properties: {
        id: '37',
        municipality: 'Cayey',
        name: 'Centro Comunal Res. Luis Muñoz Morales   ',
        address: 'Ave. Fernández García #105',
        lat: 18.1088,
        lng: -66.1643,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.1643, 18.1088] },
    },
    {
      type: 'Feature',
      properties: {
        id: '38',
        municipality: 'San Juan ',
        name: 'Centro Comunal Residencial Juan C. Cordero',
        address: 'Res. Juan C. Cordero, Calle Irlanda, Hato Rey',
        lat: 18.414431386322899,
        lng: -66.047132509788597,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.047132509788597, 18.414431386322899],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '39',
        municipality: 'Santa Isabel  ',
        name: 'Centro comunal Rincon Taino',
        address: 'Carr. 153 Km. 0 Santa Isabel',
        lat: 17.9704,
        lng: -66.4046,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.4046, 17.9704] },
    },
    {
      type: 'Feature',
      properties: {
        id: '40',
        municipality: 'Río Grande    ',
        name: 'Centro Comunal Rio Grande States',
        address: 'Urb. Rio Grande States',
        lat: 18.3831,
        lng: -65.7957,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.7957, 18.3831] },
    },
    {
      type: 'Feature',
      properties: {
        id: '41',
        municipality: 'Loiza',
        name: 'Centro Comunal San Patricio',
        address: 'Calle Espiritu Santo detras Alcaldia',
        lat: 18.432430101603401,
        lng: -65.880423764524707,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-65.880423764524707, 18.432430101603401],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '42',
        municipality: 'Guánica',
        name: 'Centro Comunal Santa Juanita',
        address: 'Bo. Santa Juanita',
        lat: 18.00559,
        lng: -66.89978,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.89978, 18.00559] },
    },
    {
      type: 'Feature',
      properties: {
        id: '43',
        municipality: 'Fajardo',
        name: 'Centro Comunal Santiago Veve Calzada',
        address: 'Residencial Calle 5 p 00738',
        lat: 18.332,
        lng: -65.6548,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.6548, 18.332] },
    },
    {
      type: 'Feature',
      properties: {
        id: '44',
        municipality: 'Peñuelas',
        name: 'Centro Comunal Tallaboa Encarnación',
        address: 'Bo. Tallaboa Encarnación',
        lat: 17.99444,
        lng: -66.71667,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.71667, 17.99444] },
    },
    {
      type: 'Feature',
      properties: {
        id: '45',
        municipality: 'San Juan ',
        name: 'Centro Comunal Villa España',
        address: 'Ave. Piñero',
        lat: 18.394848207976299,
        lng: -66.098405896239797,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.098405896239797, 18.394848207976299],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '46',
        municipality: 'Arroyo',
        name: 'Centro Dr Palmieri Clinica Arroyo',
        address: 'c/Morse #46, Esquina Valentina, Arroyo',
        lat: 17.96858994400003,
        lng: -66.060254748999967,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.060254748999967, 17.96858994400003],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '47',
        municipality: 'Guayama',
        name: 'Centro Dr Palmieri Clinica Guayama',
        address: 'Linea Capo #179 Km 0.35, Bo. Olimpo, Guayama',
        lat: 18.00211173200006,
        lng: -66.114185474999942,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.114185474999942, 18.00211173200006],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '48',
        municipality: 'Coamo',
        name: 'Centro Envejecientes Villa Madrid',
        address: 'Urb. Villa Madrid Calle 15, Coamo 00769',
        lat: 18.082,
        lng: -66.3443,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.3443, 18.082] },
    },
    {
      type: 'Feature',
      properties: {
        id: '49',
        municipality: 'Barranquitas',
        name: 'Centro Geriátrico La Hermandad',
        address: 'Carr. 152 KM 6.2 Bo. Quebradilla',
        lat: 18.22769091,
        lng: -66.28768315000001,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.28768315, 18.22769091],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '50',
        municipality: 'Río Grande    ',
        name: 'Centro Las Dolores',
        address: 'Comunidad Las Dolores.',
        lat: 18.3768,
        lng: -65.8549,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.8549, 18.3768] },
    },
    {
      type: 'Feature',
      properties: {
        id: '51',
        municipality: 'Guayama',
        name: 'Centro Servicios Mutiples',
        address: 'Bo. Cimarrona Carr. #713 km 4.0',
        lat: 17.9961,
        lng: -66.1901,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.1901, 17.9961] },
    },
    {
      type: 'Feature',
      properties: {
        id: '52',
        municipality: 'San Germán',
        name: 'Coliseo Arquelio Torres',
        address: 'Carr. 101   Calle Los Atleticos',
        lat: 18.07844,
        lng: -67.03027,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.03027, 18.07844] },
    },
    {
      type: 'Feature',
      properties: {
        id: '53',
        municipality: 'Bayamón',
        name: 'Coliseo Rubén Rodriguez',
        address: 'Carr. 2 Esquina Carr. 174 Bayamón',
        lat: 18.395321,
        lng: -66.151066,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.151066, 18.395321],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '54',
        municipality: 'Bayamón',
        name: 'Community Health Bayamon',
        address: 'C-17 Marginal Santa Cruz, Urb. Santa Cruz, Bayamon',
        lat: 18.395919075000052,
        lng: -66.14845375699997,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.14845375699997, 18.395919075000052],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '55',
        municipality: 'Ceiba',
        name: 'Concilio Loiza Clinica Ceiba',
        address: '',
        lat: 18.26469390800003,
        lng: -65.648417002999963,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-65.648417002999963, 18.26469390800003],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '56',
        municipality: 'Luquillo',
        name: 'Concilio Loiza Clinica Luquillo',
        address: 'Plaza Azul Center Local #1 Carr. 193 Km 1.1, Luquillo',
        lat: 18.37985684400007,
        lng: -65.721043993999956,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-65.721043993999956, 18.37985684400007],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '57',
        municipality: 'Río Grande',
        name: 'Concilio Loiza Clinica Rio Grande',
        address: 'c/Pimentel #16 Bo. Las Flores, Rio Grande',
        lat: 18.37908379900006,
        lng: -65.836804468999958,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-65.836804468999958, 18.37908379900006],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '58',
        municipality: 'Aibonito',
        name: 'COSSMA Clinica Aibonito',
        address: 'PR-12 Km 49.9, Bo. Llanos, Aibonito',
        lat: 18.132531792000069,
        lng: -66.275236993999954,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.275236993999954, 18.132531792000069],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '59',
        municipality: 'Cidra',
        name: 'COSSMA Clinica Cidra',
        address: 'Ave. El Jíbaro, Carr. 172 Km 13.5, Cidra',
        lat: 18.178587710000041,
        lng: -66.154402234999964,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.154402234999964, 18.178587710000041],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '60',
        municipality: 'Humacao',
        name: 'COSSMA Clinica Humacao',
        address: 'Boulevard del Río Ramal #3',
        lat: 18.145347486000048,
        lng: -65.820165362999944,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-65.820165362999944, 18.145347486000048],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '61',
        municipality: 'Las Piedras',
        name: 'COSSMA Clinica Las Piedras',
        address: 'Centro Industrial Tejas Lote 8 Carr 9921, Las Piedras',
        lat: 18.177897207000061,
        lng: -65.869767597999953,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-65.869767597999953, 18.177897207000061],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '62',
        municipality: 'Naguabo',
        name: 'COSSMA Clinica Naguabo',
        address: '',
        lat: 18.212050379000061,
        lng: -65.735099686999945,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-65.735099686999945, 18.212050379000061],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '63',
        municipality: 'San Lorenzo',
        name: 'COSSMA Clinica San Lorenzo',
        address: 'Calle Muñoz Rivera #175, San Lorenzo',
        lat: 18.18638212300004,
        lng: -65.963030167999932,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-65.963030167999932, 18.18638212300004],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '64',
        municipality: 'Aguada',
        name: 'Costa Clinica Aguada',
        address: 'c/ Colon #106, Aguada ',
        lat: 18.380810274000051,
        lng: -67.184275706999983,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-67.184275706999983, 18.380810274000051],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '65',
        municipality: 'Moca',
        name: 'Costa Clinica Moca',
        address: '',
        lat: 18.394779670000052,
        lng: -67.11377970999996,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-67.11377971, 18.394779670000052],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '66',
        municipality: 'Rincón',
        name: 'Costa Clinica Rincon',
        address: 'Calle Muñoz Rivera #28, Rincon',
        lat: 18.338567239000039,
        lng: -67.251645920999977,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-67.251645920999977, 18.338567239000039],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '67',
        municipality: 'Hatillo',
        name: 'CSM Hatillo Clinica Hatillo',
        address: 'Avenida Dr. Susoni #116, Hatillo',
        lat: 18.48715433000007,
        lng: -66.815195530999972,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.815195530999972, 18.48715433000007],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '68',
        municipality: 'Utuado',
        name: 'CSM Hatillo Clinica Utuado',
        address: 'Calle Antonio R. Barcelo #5, Utuado',
        lat: 18.26570851300005,
        lng: -66.698578764999979,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.698578764999979, 18.26570851300005],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '69',
        municipality: 'Maunabo',
        name: 'CSPS Patillas Clinica Maunabo',
        address: 'Calle Muñoz Rivera #45, Maunabo',
        lat: 18.007362,
        lng: -65.898999,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-65.898999, 18.007362],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '70',
        municipality: 'Patillas',
        name: 'CSPS Patillas Clinica Patillas',
        address: 'c/ Riefkohl #99, Patillas',
        lat: 18.00520223500007,
        lng: -66.013975418999962,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.013975418999962, 18.00520223500007],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '71',
        municipality: 'Salinas',
        name: 'CSPS Patillas Clinica Salinas',
        address: '',
        lat: 17.976697054000059,
        lng: -66.29822121899997,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.29822121899997, 17.976697054000059],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '72',
        municipality: 'Santa Isabel',
        name: 'CSPS Patillas Clinica Santa Isabel',
        address: 'Calle Muñoz Rivera #32',
        lat: 17.964882,
        lng: -66.4042995,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.404299499999979, 17.964882],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '73',
        municipality: 'San Juan ',
        name: 'Departamento de Recreación y Deportes',
        address: 'Calle Los Angeles, Parada 2412',
        lat: 18.4387,
        lng: -66.0667,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.0667, 18.4387] },
    },
    {
      type: 'Feature',
      properties: {
        id: '74',
        municipality: 'Toa Baja',
        name: 'Esc Efraín Sánchez',
        address: 'Calle antonio Blanco Ext. Levitown',
        lat: 18.44143,
        lng: -66.16946,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.16946, 18.44143] },
    },
    {
      type: 'Feature',
      properties: {
        id: '75',
        municipality: 'Aibonito',
        name: 'Esc Federico Degetau',
        address: 'Calle Ignacio López Esquina Calle San José',
        lat: 18.15036984,
        lng: -66.26471838000001,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.26471838, 18.15036984],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '76',
        municipality: 'Humacao',
        name: 'Esc Juan Ponce de León',
        address: 'Calle Dr. Vidal Humacao',
        lat: 18.1487,
        lng: -65.823,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.823, 18.1487] },
    },
    {
      type: 'Feature',
      properties: {
        id: '77',
        municipality: 'Las Piedras ',
        name: 'Esc Luis Muñoz Rivera',
        address: 'Carr 937 Int Carr 936 Boqueron',
        lat: 18.20659561,
        lng: -65.84508442000001,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-65.84508442, 18.20659561],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '78',
        municipality: 'Naguabo',
        name: 'Esc Lutgarda Rivera Reyes',
        address: 'Calle 969 Bo. Florida',
        lat: 18.23751,
        lng: -65.78243185,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-65.78243185, 18.23751],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '79',
        municipality: 'Las Piedras ',
        name: 'Esc Ramón Power y Giralt',
        address: '22 Ave. Jesus T. Piñero, Las Piedras',
        lat: 18.1819,
        lng: -65.87,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.87, 18.1819] },
    },
    {
      type: 'Feature',
      properties: {
        id: '80',
        municipality: 'Lajas',
        name: 'Esc Rosendo Matienzo',
        address: 'Bo. Lajas  Arriba Carr. 1.7 km ',
        lat: 18.0382,
        lng: -67.01143,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.01143, 18.0382] },
    },
    {
      type: 'Feature',
      properties: {
        id: '81',
        municipality: 'Maunabo',
        name: 'Esc Wilfredo La Fuente',
        address: 'Carr. 750 Bo. Atalante',
        lat: 18.0099,
        lng: -65.9021,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.9021, 18.0099] },
    },
    {
      type: 'Feature',
      properties: {
        id: '82',
        municipality: 'San Juan',
        name: 'Esc.  Venus Gardens                                   ',
        address: 'Calle Aster, Urb. Venus Gardens',
        lat: 18.37236,
        lng: -66.03593,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.03593, 18.37236] },
    },
    {
      type: 'Feature',
      properties: {
        id: '83',
        municipality: 'Caguas',
        name: 'Esc. Abelardo Díaz Morales',
        address: 'Urb. Santa Elvira  calle santa Gertrudis',
        lat: 18.23677089,
        lng: -66.01365155000001,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.01365155, 18.23677089],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '84',
        municipality: 'San Juan ',
        name: 'Esc. Abraham Lincoln',
        address: 'Calle Sol 351 Viejo San Juan',
        lat: 18.467700664512101,
        lng: -66.1135451242789,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.1135451242789, 18.467700664512101],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '85',
        municipality: 'Toa Alta',
        name: 'Esc. Adela Rolón Fuentes',
        address: 'Urb. Toa Alta Heights, Ave. Principal',
        lat: 18.3611,
        lng: -66.2152042,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.2152042, 18.3611] },
    },
    {
      type: 'Feature',
      properties: {
        id: '86',
        municipality: 'Peñuelas',
        name: 'Esc. Adolfo Grana',
        address: 'Calle Luis Munoz Rivera',
        lat: 18.05278,
        lng: -66.72204,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.72204, 18.05278] },
    },
    {
      type: 'Feature',
      properties: {
        id: '87',
        municipality: 'Jayuya',
        name: 'Esc. Adrián Torres Torres',
        address: 'Carr. 141Desvio Norte Ramal 2.1',
        lat: 18.2244,
        lng: -66.595,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.595, 18.2244] },
    },
    {
      type: 'Feature',
      properties: {
        id: '88',
        municipality: 'Humacao',
        name: 'Esc. Agapito Lopez Flores',
        address: 'Urb. Verde Mar Punta Santiago',
        lat: 18.161,
        lng: -65.7598,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.7598, 18.161] },
    },
    {
      type: 'Feature',
      properties: {
        id: '89',
        municipality: 'Vega Baja',
        name: 'Esc. Agapito Rosario Rosario',
        address: 'Calle S Esq. T, Urb. Alturas de Vega Baja',
        lat: 18.4345,
        lng: -66.4021,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.4021, 18.4345] },
    },
    {
      type: 'Feature',
      properties: {
        id: '90',
        municipality: 'Guánica',
        name: 'Esc. Agripina Seda',
        address: 'calle 13 de marzo',
        lat: 17.97204,
        lng: -66.90642,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.90642, 17.97204] },
    },
    {
      type: 'Feature',
      properties: {
        id: '91',
        municipality: 'Orocovis',
        name: 'Esc. Alberto Meléndez Torres',
        address: 'Calle Juan D. Rivera y Santiago Orocovis',
        lat: 18.2238,
        lng: -66.3928,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.3928, 18.2238] },
    },
    {
      type: 'Feature',
      properties: {
        id: '92',
        municipality: 'Toa Alta',
        name: 'Esc. Alejandro Jr. Cruz',
        address: 'Carr. 165 Km. 1.1, Bo. Galateo, Sector Villa Josco',
        lat: 18.36603,
        lng: -66.25677,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.25677, 18.36603] },
    },
    {
      type: 'Feature',
      properties: {
        id: '93',
        municipality: 'San Juan',
        name: 'Esc. Alejandro Tapia y Rivera',
        address: '369 Calle Belle View, Villa Palmeras, Santurce',
        lat: 18.4385,
        lng: -66.045,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.045, 18.4385] },
    },
    {
      type: 'Feature',
      properties: {
        id: '94',
        municipality: 'Juncos ',
        name: 'Esc. Alfonso Díaz Lebrón',
        address: ' PR 9913 00777',
        lat: 18.22696267,
        lng: -65.91826697,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-65.91826697, 18.22696267],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '95',
        municipality: 'Toa Baja',
        name: 'Esc. Altinencia Valle Santana',
        address: 'Bo. Campanillas calle palma 2',
        lat: 18.4234,
        lng: -66.2341,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.2341, 18.4234] },
    },
    {
      type: 'Feature',
      properties: {
        id: '96',
        municipality: 'San Juan',
        name: 'Esc. Amalia Marín',
        address: 'Calle Peñasco, Río Piedras Heights',
        lat: 18.39322,
        lng: -66.05413,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.05413, 18.39322] },
    },
    {
      type: 'Feature',
      properties: {
        id: '97',
        municipality: 'Humacao',
        name: 'Esc. Ana Roque Duprey',
        address: 'Calle Dr. Vidal Humacao',
        lat: 18.14945,
        lng: -65.82,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.82, 18.14945] },
    },
    {
      type: 'Feature',
      properties: {
        id: '98',
        municipality: 'Santa Isabel  ',
        name: 'Esc. Ana Valdejully (JAUCA)',
        address: 'Urb. Llanos de Santa Isabel, Sta. Isabel, PR 00757',
        lat: 17.9688,
        lng: -66.3678,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.3678, 17.9688] },
    },
    {
      type: 'Feature',
      properties: {
        id: '99',
        municipality: 'Morovis',
        name: 'Esc. Angel G. Quintero',
        address: 'Carr. 633 Km. 4.7 Bo. Barahona',
        lat: 18.35847143,
        lng: -66.44538831,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.44538831, 18.35847143],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '100',
        municipality: 'Lares',
        name: 'Esc. Angelita Delgado Sella (S.U. América)',
        address: 'Carr. 129, Km. 13.5, Bo. Buenos Aires, Sec. La América',
        lat: 18.2698,
        lng: -66.8527,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.8527, 18.2698] },
    },
    {
      type: 'Feature',
      properties: {
        id: '101',
        municipality: 'Aguada',
        name: 'Esc. Anselmo Villarubia',
        address: 'Carr. 4416 Km. 1.6 Bo. Malpaso ',
        lat: 18.36126,
        lng: -67.17256,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.17256, 18.36126] },
    },
    {
      type: 'Feature',
      properties: {
        id: '102',
        municipality: 'Humacao',
        name: 'Esc. Antonia Rosa Guzmán',
        address: 'Carr. 909',
        lat: 18.1343,
        lng: -65.8433,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.8433, 18.1343] },
    },
    {
      type: 'Feature',
      properties: {
        id: '103',
        municipality: 'Cabo Rojo',
        name: 'Esc. Antonio Acarón Correa',
        address: 'Carr 102 Km 22 Bo Monte Grande',
        lat: 18.08711,
        lng: -67.13106,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.13106, 18.08711] },
    },
    {
      type: 'Feature',
      properties: {
        id: '104',
        municipality: 'Utuado ',
        name: 'Esc. Antonio Tulla Torres (S.U. Mameyes)',
        address: 'Carr. 140, Km. 5.1, Bo. Mameyes',
        lat: 18.2986,
        lng: -66.6019,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.6019, 18.2986] },
    },
    {
      type: 'Feature',
      properties: {
        id: '105',
        municipality: 'Manatí',
        name: 'Esc. Antonio Vélez Alvarado',
        address: 'Carr. 686, Int. Carr. 2, Bo. Campo Alegre',
        lat: 18.43523199,
        lng: -66.46583416,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.46583416, 18.43523199],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '106',
        municipality: 'Vega Alta',
        name: 'Esc. Apolo San Antonio',
        address: 'Carr. 676 Km 1.0 Bo Bajura Vega Alta',
        lat: 18.4122,
        lng: -66.3328,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.3328, 18.4122] },
    },
    {
      type: 'Feature',
      properties: {
        id: '107',
        municipality: 'Yabucoa',
        name: 'Esc. Asunción Lugo',
        address: 'Bo. Camino Nuevo',
        lat: 18.0363,
        lng: -65.8467,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.8467, 18.0363] },
    },
    {
      type: 'Feature',
      properties: {
        id: '108',
        municipality: 'San Sebastián',
        name: 'Esc. Aurora Méndez',
        address: 'Calle Pedro Albizu Campos',
        lat: 18.3345,
        lng: -66.9852,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.9852, 18.3345] },
    },
    {
      type: 'Feature',
      properties: {
        id: '109',
        municipality: 'Humacao',
        name: 'Esc. Avelino Peña Reyes',
        address: 'Calle Dr. Vidal 00791',
        lat: 18.149,
        lng: -65.821198,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.821198, 18.149] },
    },
    {
      type: 'Feature',
      properties: {
        id: '110',
        municipality: 'Mayagüez',
        name: 'Esc. Barbarita Rodríguez',
        address: 'Bo. Quebrada grande Calle Santa Ana Marquez',
        lat: 18.188,
        lng: -67.1369,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.1369, 18.188] },
    },
    {
      type: 'Feature',
      properties: {
        id: '111',
        municipality: 'Aguadilla',
        name: 'Esc. Benito Cerezo Vázquez',
        address: 'Carr. 107 Int. 458 Km. 1, Bda. Borinquén     ',
        lat: 18.45849,
        lng: -67.15533,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.15533, 18.45849] },
    },
    {
      type: 'Feature',
      properties: {
        id: '112',
        municipality: 'Coamo',
        name: 'Esc. Benjamin Frankiln',
        address: 'Calle Jose Quinton #82 Coamo 00769',
        lat: 18.0795,
        lng: -66.3616,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.3616, 18.0795] },
    },
    {
      type: 'Feature',
      properties: {
        id: '113',
        municipality: 'Ponce',
        name: 'Esc. Bernardino Cordero',
        address: 'Carr. 488 Ave. JuB. Roman',
        lat: 18.00257,
        lng: -66.62902,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.62902, 18.00257] },
    },
    {
      type: 'Feature',
      properties: {
        id: '114',
        municipality: 'Utuado',
        name: 'Esc. Bernardo González Colón',
        address: 'Antigua Carr 10, Carr. 123, Km. 56.3, Bo. Salto Abajo',
        lat: 18.2756,
        lng: -66.7112,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.7112, 18.2756] },
    },
    {
      type: 'Feature',
      properties: {
        id: '115',
        municipality: 'Fajardo',
        name: 'Esc. Berta Zalduondo',
        address: 'Urb. Monte Brisas III, Calle 104',
        lat: 18.3493,
        lng: -65.6505,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.6505, 18.3493] },
    },
    {
      type: 'Feature',
      properties: {
        id: '116',
        municipality: 'Guaynabo',
        name: 'Esc. Betty Rosado de Vega',
        address: 'Carr. 169, Km. 8.6, Bo. Camarones',
        lat: 18.3387,
        lng: -66.1019,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.1019, 18.3387] },
    },
    {
      type: 'Feature',
      properties: {
        id: '117',
        municipality: 'Sabana Grande',
        name: 'Esc. Blanca Malaret',
        address: 'Calle Pedro Rodriguez',
        lat: 18.07807,
        lng: -66.95798,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.95798, 18.07807] },
    },
    {
      type: 'Feature',
      properties: {
        id: '118',
        municipality: 'Luquillo',
        name: 'Esc. Camilo Vallés  Matienzo                        ',
        address: 'Calle 3 Urb. Brisas del Mar',
        lat: 18.3742,
        lng: -65.7214,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.7214, 18.3742] },
    },
    {
      type: 'Feature',
      properties: {
        id: '119',
        municipality: 'Salinas',
        name: 'Esc. Carlos Colón Burgos',
        address: 'Calle 3 sector Godreau',
        lat: 17.9738,
        lng: -66.2812,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.2812, 17.9738] },
    },
    {
      type: 'Feature',
      properties: {
        id: '120',
        municipality: 'Loiza',
        name: 'Esc. Carlos Escobar (cerrada Administrada por municipio)',
        address: 'Calle Final, Urbanización Santiago',
        lat: 18.430451311704299,
        lng: -65.881668004326599,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-65.881668004326599, 18.430451311704299],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '121',
        municipality: 'Culebra',
        name: 'Esc. Carmelo Ortiz  (Ecológica Elemental)',
        address: 'Bda. Clark #155, Calle Monserrate Colón',
        lat: 18.307742781264899,
        lng: -65.302565047806894,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-65.302565047806894, 18.307742781264899],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '122',
        municipality: 'Añasco',
        name: 'Esc. Carmen Casasús Martí',
        address: 'Carr. 405, Bo Carreras',
        lat: 18.28698,
        lng: -67.13232,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.13232, 18.28698] },
    },
    {
      type: 'Feature',
      properties: {
        id: '123',
        municipality: 'Moca',
        name: 'Esc. Catalina Morales',
        address: 'Carr 110 calle Concepcion ',
        lat: 18.3852,
        lng: -67.11099,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.11099, 18.3852] },
    },
    {
      type: 'Feature',
      properties: {
        id: '124',
        municipality: 'Patillas ',
        name: 'Esc. Cecilio Lebrón Ramos                                           ',
        address: 'Calle Muñoz Rivera Final',
        lat: 18.0048,
        lng: -66.0127,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.0127, 18.0048] },
    },
    {
      type: 'Feature',
      properties: {
        id: '125',
        municipality: 'Loiza',
        name: 'Esc. Celso González Vallant',
        address: 'Carr 187, Int. carr. 188 (lado del CDT)',
        lat: 18.4258,
        lng: -65.88067,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.88067, 18.4258] },
    },
    {
      type: 'Feature',
      properties: {
        id: '126',
        municipality: 'Caguas',
        name: 'Esc. Cipriano Manrique',
        address: 'Bo Borinquén Parcelas Viejas Carr 765 km 3.1',
        lat: 18.17487656,
        lng: -66.04113246,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.04113246, 18.17487656],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '127',
        municipality: 'Comerío',
        name: 'Esc. Claudio Ferrer Cotto',
        address: 'Carr. 156, Km. 30.5, Bo. Río Hondo, Sector Bordones',
        lat: 18.21104558,
        lng: -66.23653901,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.23653901, 18.21104558],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '128',
        municipality: 'Aguadilla',
        name: 'Esc. Conchita Igartua de Suárez',
        address: 'Carr. Núm. 2 HM 122 Corrales 00603',
        lat: 18.44352,
        lng: -67.11676,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.11676, 18.44352] },
    },
    {
      type: 'Feature',
      properties: {
        id: '129',
        municipality: 'Yabucoa',
        name: 'Esc. Cristóbal del Campo',
        address: 'Bo. Limones Carr.902',
        lat: 18.0777,
        lng: -65.8944,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.8944, 18.0777] },
    },
    {
      type: 'Feature',
      properties: {
        id: '130',
        municipality: 'Dorado',
        name: 'Esc. Cristóbal Santana Melecio (Río Lajas)',
        address: 'Carr. 165 Km 2.3, 10 Calle 8, Bo. Río Lajas',
        lat: 18.3958,
        lng: -66.2589,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.2589, 18.3958] },
    },
    {
      type: 'Feature',
      properties: {
        id: '131',
        municipality: 'Lares',
        name: 'Esc. Daniel Vélez Soto',
        address: 'Urb. Villa Seral, Calle B, Ave. Los Patriotas, Carr. 111',
        lat: 18.2995,
        lng: -66.8821,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.8821, 18.2995] },
    },
    {
      type: 'Feature',
      properties: {
        id: '132',
        municipality: 'Peñuelas      ',
        name: 'Esc. Daniel Webster                                ',
        address: 'Carr. Luis Muñoz Rivera',
        lat: 18.05446,
        lng: -66.72229,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.72229, 18.05446] },
    },
    {
      type: 'Feature',
      properties: {
        id: '133',
        municipality: 'Toa Baja',
        name: 'Esc. Delia Caban',
        address: 'Calle Lago las curias 5ta seccion',
        lat: 18.43508,
        lng: -66.18152,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.18152, 18.43508] },
    },
    {
      type: 'Feature',
      properties: {
        id: '134',
        municipality: 'Arroyo',
        name: 'Esc. Dolores González',
        address: 'Urb. Jardines de Arroyo',
        lat: 17.9742,
        lng: -66.0647,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.0647, 17.9742] },
    },
    {
      type: 'Feature',
      properties: {
        id: '135',
        municipality: 'Lares',
        name: 'Esc. Domingo Aponte Collazo                                                    ',
        address: 'Calle Ramón de Jesús Sierra, Carr. 111',
        lat: 18.2965,
        lng: -66.8799,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.8799, 18.2965] },
    },
    {
      type: 'Feature',
      properties: {
        id: '136',
        municipality: 'Yauco           ',
        name: 'Esc. Doris Martínez',
        address: 'Carr. 371 Bo Almacigo Abajo',
        lat: 18.06061,
        lng: -66.88108,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.88108, 18.06061] },
    },
    {
      type: 'Feature',
      properties: {
        id: '137',
        municipality: 'San Juan ',
        name: 'Esc. Dr. Arturo Morales Carrión',
        address: 'Barrio Tortugo Caimito',
        lat: 18.34597,
        lng: -66.09417,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.09417, 18.34597] },
    },
    {
      type: 'Feature',
      properties: {
        id: '138',
        municipality: 'Carolina',
        name: 'Esc. Dr. Gilberto Concepción',
        address: 'Ave. Felipe Sánchez Osorio',
        lat: 18.401015,
        lng: -65.9774,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.9774, 18.401015] },
    },
    {
      type: 'Feature',
      properties: {
        id: '139',
        municipality: 'Ponce',
        name: 'Esc. Dr. José Celso Barbosa',
        address: '#188 Calle Guadalupe Final',
        lat: 18.0147,
        lng: -66.61969,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.61969, 18.0147] },
    },
    {
      type: 'Feature',
      properties: {
        id: '140',
        municipality: 'Corozal',
        name: 'Esc. Dr. José Padín.                                        ',
        address: 'Carr. 568, Km. 22.4, Bo. Cuchillas',
        lat: 18.29055489,
        lng: -66.35324982,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.35324982, 18.29055489],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '141',
        municipality: 'Caguas',
        name: 'Esc. Dr. Juan José Osuna #2',
        address: 'Carr. 1, Km. 38.5, Bo. Turabo, Sector Villa Esperanza',
        lat: 18.20340123,
        lng: -66.05224622,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.05224622, 18.20340123],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '142',
        municipality: 'Florida',
        name: 'Esc. Dr. Leonardo  Valentín Tirado',
        address: 'Carr 140, Sector Las Vázquez, (Frente Garage Gulf)',
        lat: 18.37204771,
        lng: -66.56544614000001,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.56544614, 18.37204771],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '143',
        municipality: 'Toa Baja',
        name: 'Esc. Dr. Pedro Albizu Campos',
        address: 'Ave. Boulevard 4ta Sección Levittown',
        lat: 18.4491,
        lng: -66.1814,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.1814, 18.4491] },
    },
    {
      type: 'Feature',
      properties: {
        id: '144',
        municipality: 'Quebradillas',
        name: 'Esc. Dr. Pedro Albizu Campos                                                                        ',
        address: '61 Calle Linares Carr. 113',
        lat: 18.4687,
        lng: -66.9389,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.9389, 18.4687] },
    },
    {
      type: 'Feature',
      properties: {
        id: '145',
        municipality: 'Aguas Buenas',
        name: 'Esc. Dr. Pedro Albizu Campos (Int.)',
        address:
          'Carr. 156 Ramal 794 Km. 1.0, Bo. Caguitas, Sector Camino Verde',
        lat: 18.25236942,
        lng: -66.1014713,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.1014713, 18.25236942],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '146',
        municipality: 'Aibonito',
        name: 'Esc. Dra. Carmen D. Colón',
        address: 'Carr. 725, Km 4.3, Bo Llanos',
        lat: 18.15952631,
        lng: -66.29210362000001,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.29210362, 18.15952631],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '147',
        municipality: 'Utuado',
        name: 'Esc. Efrain González Tejera',
        address: 'Carretera 111 Km 16.6 Barrio Caguana en Utuado',
        lat: 18.2927,
        lng: -66.8035,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.8035, 18.2927] },
    },
    {
      type: 'Feature',
      properties: {
        id: '148',
        municipality: 'Moca',
        name: 'Esc. Efrain Sánchez Hidalgo',
        address: 'Calle Monsenor J. Torres',
        lat: 18.39152,
        lng: -67.11177,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.11177, 18.39152] },
    },
    {
      type: 'Feature',
      properties: {
        id: '149',
        municipality: 'Aguada',
        name: 'Esc. Eladio Tirado López',
        address: 'Carr. 417 Km 3.1, Bo. Guanábano ',
        lat: 18.36934,
        lng: -67.15601,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.15601, 18.36934] },
    },
    {
      type: 'Feature',
      properties: {
        id: '150',
        municipality: 'Dorado',
        name: 'Esc. Elemental Jose de Diego (Ecológica)         ',
        address: 'Carr 695 km 1 Bo. Higuillar',
        lat: 18.4326,
        lng: -66.301,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.301, 18.4326] },
    },
    {
      type: 'Feature',
      properties: {
        id: '151',
        municipality: 'Hormigueros',
        name: 'Esc. Elemental Nueva',
        address: 'Ave. Muñoz Marín, Camino los Santana, Hormigueros',
        lat: 18.1393,
        lng: -67.13331,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.13331, 18.1393] },
    },
    {
      type: 'Feature',
      properties: {
        id: '152',
        municipality: 'Coamo',
        name: 'Esc. Elemental Sabino Rivera',
        address: 'Carr. 723, Km. 7.4, Sector Progreso, Bo. Pulguillas',
        lat: 18.1535,
        lng: -66.3406,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.3406, 18.1535] },
    },
    {
      type: 'Feature',
      properties: {
        id: '153',
        municipality: 'Cayey           ',
        name: 'Esc. Elemental Salvador Brau',
        address: 'Urb. Jardines I, Calle 13 Cayey',
        lat: 18.1141,
        lng: -66.1566,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.1566, 18.1141] },
    },
    {
      type: 'Feature',
      properties: {
        id: '154',
        municipality: 'Cidra',
        name: 'Esc. Elemental Urbana Violeta Reyes',
        address: 'Bo. Rio Abajo Carr. 172 Km 11',
        lat: 18.17538694,
        lng: -66.17104524,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.17104524, 18.17538694],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '155',
        municipality: 'Barceloneta',
        name: 'Esc. Elemental Vicente Acevedo Ballester',
        address: 'Bo. Magueyes Carr. 664 KM 0.6 ',
        lat: 18.4204,
        lng: -66.5765,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.5765, 18.4204] },
    },
    {
      type: 'Feature',
      properties: {
        id: '156',
        municipality: 'Barceloneta',
        name: 'Esc. Eli Ramos Rosario',
        address: 'Bo. Llanares Sector Fortunas Carr. 140',
        lat: 18.4468,
        lng: -66.5529,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.5529, 18.4468] },
    },
    {
      type: 'Feature',
      properties: {
        id: '157',
        municipality: 'Corozal',
        name: 'Esc. Emilio R. Delgado',
        address: 'Carr. 159 Km 13.0 Corozal',
        lat: 18.34027372,
        lng: -66.32188966,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.32188966, 18.34027372],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '158',
        municipality: 'Toa Baja',
        name: 'Esc. Ernestina Bracero Pérez (Villa Gutiérrez)',
        address: 'Carr. 865 Km. 1.4, Bo. Candelaria Arena, ',
        lat: 18.41672,
        lng: -66.21051,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.21051, 18.41672] },
    },
    {
      type: 'Feature',
      properties: {
        id: '159',
        municipality: 'San Juan',
        name: 'Esc. Ernesto Ramos Antonini',
        address: 'Ave. Borinquen Esq. Cortijo, Bo. Obrero',
        lat: 18.43623,
        lng: -66.05043,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.05043, 18.43623] },
    },
    {
      type: 'Feature',
      properties: {
        id: '160',
        municipality: 'Cidra',
        name: 'Esc. Ernesto Vicente Caratini',
        address: 'Carr 782 km 5.9 Barrio Ceiba',
        lat: 18.2133291,
        lng: -66.16464828,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.16464828, 18.2133291],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '161',
        municipality: 'Cayey',
        name: 'Esc. Especializada en Artes (Julia Collazo)',
        address: 'Calle Núñez Romeu',
        lat: 18.116,
        lng: -66.1671,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.1671, 18.116] },
    },
    {
      type: 'Feature',
      properties: {
        id: '162',
        municipality: 'Aguadilla',
        name: 'Esc. Esther Feliciano Mendoza',
        address: 'Calle E 467 Base Ramey, P.R.',
        lat: 18.50013,
        lng: -67.14557,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.14557, 18.50013] },
    },
    {
      type: 'Feature',
      properties: {
        id: '163',
        municipality: 'Naguabo',
        name: 'Esc. Eugenio Brac',
        address: 'Calle Antonio Rios',
        lat: 18.2098,
        lng: -65.7336,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.7336, 18.2098] },
    },
    {
      type: 'Feature',
      properties: {
        id: '164',
        municipality: 'Hatillo',
        name: 'Esc. Eugenio Maria De Hostos',
        address: 'Carr 2 KM 84 Bo Carrizalez',
        lat: 18.4821,
        lng: -66.79,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.79, 18.4821] },
    },
    {
      type: 'Feature',
      properties: {
        id: '165',
        municipality: 'Mayagüez',
        name: 'Esc. Eugenio María de Hostos',
        address: 'Calle Nenadich # 50',
        lat: 18.1979,
        lng: -67.14447,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.14447, 18.1979] },
    },
    {
      type: 'Feature',
      properties: {
        id: '166',
        municipality: 'Las Marías',
        name: 'Esc. Eugenio Maria de Hostos Nueva',
        address: 'Paseo Ramon Rivera',
        lat: 18.2469,
        lng: -66.9975,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.9975, 18.2469] },
    },
    {
      type: 'Feature',
      properties: {
        id: '167',
        municipality: 'Coamo',
        name: 'Esc. Eugenio Nazario Soto',
        address: 'Carr. 784, Km 2.3, Bo Cuyon',
        lat: 18.0797,
        lng: -66.3025,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.3025, 18.0797] },
    },
    {
      type: 'Feature',
      properties: {
        id: '168',
        municipality: 'Arecibo',
        name: 'Esc. Factor 5',
        address: 'Carr #2 Factor ',
        lat: 18.4472,
        lng: -66.6486,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.6486, 18.4472] },
    },
    {
      type: 'Feature',
      properties: {
        id: '169',
        municipality: 'San Juan',
        name: 'Esc. Felipe Gutiérrez',
        address:
          'Calle Arístides Chavier, Esq. Calle Ana Otero, Villa Prades, R.P.',
        lat: 18.406645,
        lng: -66.02393,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.02393, 18.406645] },
    },
    {
      type: 'Feature',
      properties: {
        id: '170',
        municipality: 'Salinas',
        name: 'Esc. Felix Garay Ortiz',
        address: 'Bo. El Coco Calle Antonia Saez',
        lat: 18.002,
        lng: -66.2635,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.2635, 18.002] },
    },
    {
      type: 'Feature',
      properties: {
        id: '171',
        municipality: 'Río Grande    ',
        name: 'Esc. Félix Sánchez (Río Grande States)                                                                 ',
        address: 'Calle 9 Esq. 7A, Urb. Río Grande States',
        lat: 18.38174,
        lng: -65.7958,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.7958, 18.38174] },
    },
    {
      type: 'Feature',
      properties: {
        id: '172',
        municipality: 'Manatí',
        name: 'Esc. Fernando Callejo',
        address: 'Calle Bendin esq. Rosario Manatí',
        lat: 18.42776784,
        lng: -66.4875837,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.4875837, 18.42776784],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '173',
        municipality: 'Barceloneta',
        name: 'Esc. Fernando Suria Chávez',
        address: '1 calle Georgetti Barceloneta 00617',
        lat: 18.4519,
        lng: -66.5387,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.5387, 18.4519] },
    },
    {
      type: 'Feature',
      properties: {
        id: '174',
        municipality: 'Las Piedras    ',
        name: 'Esc. Florencia García',
        address: 'La Fermina, Las Piedras 00771',
        lat: 18.17803,
        lng: -65.86002,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.86002, 18.17803] },
    },
    {
      type: 'Feature',
      properties: {
        id: '175',
        municipality: 'Guayama',
        name: 'Esc. Francisco A. García Boyre',
        address: 'Calle 9, Urb. Costa Azul',
        lat: 17.976,
        lng: -66.099,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.099, 17.976] },
    },
    {
      type: 'Feature',
      properties: {
        id: '176',
        municipality: 'San Juan ',
        name: 'Esc. Francisco Hernández/Manuel Gaetán',
        address: 'Ave. Robreto H. Todd Esq. Labra',
        lat: 18.4455,
        lng: -66.07782,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.07782, 18.4455] },
    },
    {
      type: 'Feature',
      properties: {
        id: '177',
        municipality: 'Utuado',
        name: 'Esc. Francisco Jordán',
        address: 'Carr 111, Bo Caguana, Utuado',
        lat: 18.2832,
        lng: -66.7518,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.7518, 18.2832] },
    },
    {
      type: 'Feature',
      properties: {
        id: '178',
        municipality: 'Naranjito',
        name: 'Esc. Francisco Lopez Cruz',
        address: 'Urb. La Vega, Naranjito 00719',
        lat: 18.29857234,
        lng: -66.24047711,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.24047711, 18.29857234],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '179',
        municipality: 'Bayamón',
        name: 'Esc. Francisco Manrique Cabrera',
        address: 'C/41 final Urb. Rexville Bayamón',
        lat: 18.368342938393901,
        lng: -66.192523764381605,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.192523764381605, 18.368342938393901],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '180',
        municipality: 'Isabela',
        name: 'Esc. Francisco Mendoza',
        address: 'Calle Corchado, al lado OMME - Isabela',
        lat: 18.5018,
        lng: -67.01909,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.01909, 18.5018] },
    },
    {
      type: 'Feature',
      properties: {
        id: '181',
        municipality: 'Manatí',
        name: 'Esc. Francisco Menéndez Balbañez',
        address: 'Carr. 667, Km. 4.6, Bo. Cortés',
        lat: 18.4110013,
        lng: -66.5313631,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.5313631, 18.4110013],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '182',
        municipality: 'Canóvanas',
        name: 'Esc. Francisco Paco Dávila',
        address: 'Sector Las 400',
        lat: 18.2709,
        lng: -65.9131,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.9131, 18.2709] },
    },
    {
      type: 'Feature',
      properties: {
        id: '183',
        municipality: 'Juana Díaz',
        name: 'Esc. Francisco Prado Picart',
        address: 'Carr 149, PR 552, Bo Guayabal',
        lat: 18.07128,
        lng: -66.49031,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.49031, 18.07128] },
    },
    {
      type: 'Feature',
      properties: {
        id: '184',
        municipality: 'Morovis',
        name: 'Esc. Francisco Rivera Claudio',
        address: 'Carr. 159, Int. 137, Ave. Corozal Km. 0.1, Bo. Monte Llano',
        lat: 18.32638111,
        lng: -66.40133204,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.40133204, 18.32638111],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '185',
        municipality: 'Naranjito',
        name: 'Esc. Francisco Roque Muñiz',
        address: 'Carr. 814 Int. 813 Sector La Sierra',
        lat: 18.26755666,
        lng: -66.23519822,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.23519822, 18.26755666],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '186',
        municipality: 'Caguas',
        name: 'Esc. Francisco Valdés',
        address: 'Carr. 798 Km. 12.5, Bo. Río Canas',
        lat: 18.29229196,
        lng: -66.05033141,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.05033141, 18.29229196],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '187',
        municipality: 'Guánica',
        name: 'Esc. Franklin Roosevelt',
        address: 'Calle 13 de marzo',
        lat: 17.972,
        lng: -66.9066,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.9066, 17.972] },
    },
    {
      type: 'Feature',
      properties: {
        id: '188',
        municipality: 'San Juan ',
        name: 'Esc. Fray Bartolomé de las Casas',
        address: 'Calle Las Almas #2003 00915',
        lat: 18.435,
        lng: -66.03576,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.03576, 18.435] },
    },
    {
      type: 'Feature',
      properties: {
        id: '189',
        municipality: 'Lares',
        name: 'Esc. Gabriela Mistral                                  ',
        address: 'Carr. 135, Km. 64.6, Bo. Castañer',
        lat: 18.1784,
        lng: -66.8307,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.8307, 18.1784] },
    },
    {
      type: 'Feature',
      properties: {
        id: '190',
        municipality: 'San Juan',
        name: 'Esc. Gaspar Vilá Mayans',
        address: 'Calle General Valero, Urb. Las Delicias, Sabana Llana',
        lat: 18.3984,
        lng: -66.0299,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.0299, 18.3984] },
    },
    {
      type: 'Feature',
      properties: {
        id: '191',
        municipality: 'Canóvanas',
        name: 'Esc. Georgina Baquero',
        address: 'Bo. Cubuy Carr. 186 Km 7.6',
        lat: 18.2664,
        lng: -65.8735,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.8735, 18.2664] },
    },
    {
      type: 'Feature',
      properties: {
        id: '192',
        municipality: 'Guayanilla',
        name: 'Esc. Gloria Borrero',
        address: 'Carr. 132, Km. 4.2, Bo Macana',
        lat: 18.05374,
        lng: -66.7777,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.7777, 18.05374] },
    },
    {
      type: 'Feature',
      properties: {
        id: '193',
        municipality: 'Peñuelas       ',
        name: 'Esc. Gonzalez  Bauzá',
        address: 'Calle 1 Sector Caracoles',
        lat: 18.05556,
        lng: -66.71127,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.71127, 18.05556] },
    },
    {
      type: 'Feature',
      properties: {
        id: '194',
        municipality: 'Loiza',
        name: 'Esc. Guillermina Rosado de Ayala',
        address: 'Calle 25 Esq. 29, Villas de Loiza  (Carr. 186 Km. 3.5)',
        lat: 18.3838,
        lng: -65.868,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.868, 18.3838] },
    },
    {
      type: 'Feature',
      properties: {
        id: '195',
        municipality: 'Patillas ',
        name: 'Esc. Guillermo Riefkhol',
        address: 'Bo. Bajos KM 121.1',
        lat: 17.9801,
        lng: -65.9959,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.9959, 17.9801] },
    },
    {
      type: 'Feature',
      properties: {
        id: '196',
        municipality: 'San Juan ',
        name: 'Esc. Gustavo Adolfo ',
        address: 'Calle Francia Esq. Arecibo',
        lat: 18.413805269928101,
        lng: -66.049126169730599,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.049126169730599, 18.413805269928101],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '197',
        municipality: 'Isabela',
        name: 'Esc. Heriberto Domenech',
        address: 'Carr. 2 Bo mora ',
        lat: 18.47078,
        lng: -67.0261,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.0261, 18.47078] },
    },
    {
      type: 'Feature',
      properties: {
        id: '198',
        municipality: 'Bayamón',
        name: 'Esc. Herminia Rivera Fernández',
        address: 'Carr. 174 Km 10.9  Sector La Morenía',
        lat: 18.3135,
        lng: -66.14559,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.14559, 18.3135] },
    },
    {
      type: 'Feature',
      properties: {
        id: '199',
        municipality: 'Maricao',
        name: 'Esc. Indiera Fría',
        address: 'Carr. 366 Km 3.4 Bo. Indiera Fria',
        lat: 18.14932,
        lng: -66.9361,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.9361, 18.14932] },
    },
    {
      type: 'Feature',
      properties: {
        id: '200',
        municipality: 'Comerío',
        name: 'Esc. Inés M. Mendoza',
        address: 'Carr. 156, Km. 33.7, Bo. Palomas Abajo, Sector Manuel Espina',
        lat: 18.22588949,
        lng: -66.21748567,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.21748567, 18.22588949],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '201',
        municipality: 'Caguas',
        name: 'Esc. Inés María Mendoza                              ',
        address: 'JJ 11 Calle 10, Villas de Castro, Bo. Tomás de Castro',
        lat: 18.22060762,
        lng: -66.01053179,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.01053179, 18.22060762],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '202',
        municipality: 'Salinas',
        name: 'Esc. Intermedia El Coquí',
        address: 'Carr. 3 KM 152.8 Salinas',
        lat: 17.9715,
        lng: -66.2255,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.2255, 17.9715] },
    },
    {
      type: 'Feature',
      properties: {
        id: '203',
        municipality: 'Barranquitas',
        name: 'Esc. Intermedia Nueva Bo. Quebradillas',
        address: 'carr 152 km 7.5 bo Quebradillas',
        lat: 18.23459747,
        lng: -66.28530411,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.28530411, 18.23459747],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '204',
        municipality: 'Ceiba',
        name: 'Esc. Intermedia Nueva.  Irma Pedraza',
        address: 'Urb. Rossey Valley, Calle Cordova',
        lat: 18.263928589566898,
        lng: -65.646238671918496,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-65.646238671918496, 18.263928589566898],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '205',
        municipality: 'Aguada',
        name: 'Esc. Intermedia Prof. Juana Rosario',
        address: 'Ave. Nativo Alers Aguada',
        lat: 18.37647,
        lng: -67.185,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.185, 18.37647] },
    },
    {
      type: 'Feature',
      properties: {
        id: '206',
        municipality: 'Cataño',
        name: 'Esc. Isaac del Rosario',
        address: 'Ave. Flor del Valle, Urb. Las Vegas',
        lat: 18.4298,
        lng: -66.1439,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.1439, 18.4298] },
    },
    {
      type: 'Feature',
      properties: {
        id: '207',
        municipality: 'Añasco',
        name: 'Esc. Isabel Suárez',
        address: 'Calle 65 Infantería # 10',
        lat: 18.2813,
        lng: -67.13682,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.13682, 18.2813] },
    },
    {
      type: 'Feature',
      properties: {
        id: '208',
        municipality: 'Luquillo',
        name: 'Esc. Isidro Sánchez',
        address: 'Urb. Brisas del Mar calle 2',
        lat: 18.3778,
        lng: -65.7221,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.7221, 18.3778] },
    },
    {
      type: 'Feature',
      properties: {
        id: '209',
        municipality: 'Dorado',
        name: 'Esc. Jacinto López',
        address: 'Calle Norte 200 Pueblo Dorado',
        lat: 18.459937947984461,
        lng: -66.261873516933903,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.261873516933903, 18.459937947984461],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '210',
        municipality: 'Yauco           ',
        name: 'Esc. Jaime Castañer',
        address: 'Bo. Duey carr 372 km 9',
        lat: 18.08916,
        lng: -66.8413,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.8413, 18.08916] },
    },
    {
      type: 'Feature',
      properties: {
        id: '211',
        municipality: 'Cidra',
        name: 'Esc. Jesús T Piñero',
        address: 'Calle Jose de Diego Final Salida Arenas',
        lat: 18.17401076,
        lng: -66.15836368,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.15836368, 18.17401076],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '212',
        municipality: 'Las Piedras    ',
        name: 'Esc. José de Diego                                    ',
        address: 'Carr. 937, Km. 4, Bo. Montones I',
        lat: 18.1854,
        lng: -65.8735,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.8735, 18.1854] },
    },
    {
      type: 'Feature',
      properties: {
        id: '213',
        municipality: 'Adjuntas',
        name: 'Esc. José Emilio Lugo                                                 ',
        address: '75 Calle Francisco Pietri, Urb. Los Cerros',
        lat: 18.16937,
        lng: -66.72147,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.72147, 18.16937] },
    },
    {
      type: 'Feature',
      properties: {
        id: '214',
        municipality: 'Trujillo Alto',
        name: 'Esc. Jose F. Díaz',
        address: '843 Carr. Antigua Bo Carraizo Trujillo Alto',
        lat: 18.3319,
        lng: -66.0288,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.0288, 18.3319] },
    },
    {
      type: 'Feature',
      properties: {
        id: '215',
        municipality: 'Coamo',
        name: 'Esc. José Felipe Zayas                                             ',
        address: 'Calle 19, Urb Jardines de Coamo',
        lat: 18.0762,
        lng: -66.3742,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.3742, 18.0762] },
    },
    {
      type: 'Feature',
      properties: {
        id: '216',
        municipality: 'Arroyo',
        name: 'Esc. José Horacio Cora',
        address: 'Carr. 753, Km. 2.2, Bda. Marín, Bo. Pitahaya',
        lat: 17.9926,
        lng: -66.0552,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.0552, 17.9926] },
    },
    {
      type: 'Feature',
      properties: {
        id: '217',
        municipality: 'Toa Alta',
        name: 'Esc. José M. Del Valle',
        address: 'Carr. 819 Km. 1.6, Bo. Bucarabones II',
        lat: 18.3865,
        lng: -66.2168,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.2168, 18.3865] },
    },
    {
      type: 'Feature',
      properties: {
        id: '218',
        municipality: 'Arroyo',
        name: 'Esc. José Massari',
        address: 'Calle Morse 162',
        lat: 17.9712,
        lng: -66.0598,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.0598, 17.9712] },
    },
    {
      type: 'Feature',
      properties: {
        id: '219',
        municipality: 'Toa Baja',
        name: 'Esc. José Nevárez López',
        address: 'Ramal 165, Bo. Media Luna, Sector San José',
        lat: 18.4022872654817,
        lng: -66.253993982373999,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.253993982373999, 18.4022872654817],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '220',
        municipality: 'Vega Alta',
        name: 'Esc. José Pagán de Jesús',
        address: 'Carr. 690 Km. 3.2',
        lat: 18.4403,
        lng: -66.3352,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.3352, 18.4403] },
    },
    {
      type: 'Feature',
      properties: {
        id: '221',
        municipality: 'Toa Baja',
        name: 'ESC. Jose Robles Otero ',
        address: 'Barrio Ingenio',
        lat: 18.4441,
        lng: -66.2294,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.2294, 18.4441] },
    },
    {
      type: 'Feature',
      properties: {
        id: '222',
        municipality: 'Dorado',
        name: 'Esc. José Santos Alegría',
        address: '10 Calle Pedro Rosa',
        lat: 18.4634252186158,
        lng: -66.266681930496105,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.266681930496105, 18.4634252186158],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '223',
        municipality: 'Utuado',
        name: 'Esc. José Vizcarrondo (S.U. Angeles)',
        address: 'Carr. 111 Int. 602, Bo. Angeles',
        lat: 18.2843,
        lng: -66.8033,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.8033, 18.2843] },
    },
    {
      type: 'Feature',
      properties: {
        id: '224',
        municipality: 'Morovis',
        name: 'Esc. Josefa del Río',
        address: 'Carr. 155 Km. 51  Barrio torrecilla',
        lat: 18.33483589,
        lng: -66.42973806000001,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.42973806, 18.33483589],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '225',
        municipality: 'Jayuya',
        name: 'Esc. Josefina León',
        address: 'Carr. 144 Ramal 141',
        lat: 18.2189,
        lng: -66.58845,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.58845, 18.2189] },
    },
    {
      type: 'Feature',
      properties: {
        id: '226',
        municipality: 'Patillas ',
        name: 'Esc. Josefina Muñoz de Bernier',
        address: 'Calle Jesus T. Piñero',
        lat: 18.0075,
        lng: -66.0168,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.0168, 18.0075] },
    },
    {
      type: 'Feature',
      properties: {
        id: '227',
        municipality: 'Florida',
        name: 'Esc. Juan  Ponce de León',
        address: 'PR 631 Km 5 Bo. Ceiba Florida',
        lat: 18.36951428,
        lng: -66.57311443,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.57311443, 18.36951428],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '228',
        municipality: 'Ciales',
        name: 'Esc. Juan A. Corretjer.                                                                                   ',
        address: 'Carr. 146, Km. 27.8, Bo. Cordillera',
        lat: 18.34640419,
        lng: -66.47564917,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.47564917, 18.34640419],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '229',
        municipality: 'Manatí',
        name: 'Esc. Juan A. Sánchez Dávila',
        address: "Carr. 670, Km. 1.2, Frente Urb. O'Neill",
        lat: 18.42406631,
        lng: -66.4808716,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.4808716, 18.42406631],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '230',
        municipality: 'San Juan ',
        name: 'Esc. Juan Antonio Corretjer',
        address: 'Km. 3 Carr. 844, Cupey Bajo ',
        lat: 18.354537734752601,
        lng: -66.0393795508664,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.0393795508664, 18.354537734752601],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '231',
        municipality: 'Moca',
        name: 'Esc. Juan de Dios Quiñones',
        address: 'Carr 125 Bo. Voladoras',
        lat: 18.3786,
        lng: -67.07897,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.07897, 18.3786] },
    },
    {
      type: 'Feature',
      properties: {
        id: '232',
        municipality: 'Naguabo',
        name: 'Esc. Juan J. Maunéz Pimentel',
        address: 'Calle Buenos Aires Naguabo PR',
        lat: 18.20828,
        lng: -65.7368,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.7368, 18.20828] },
    },
    {
      type: 'Feature',
      properties: {
        id: '233',
        municipality: 'San Juan ',
        name: 'Esc. Juan Jose Ozuna',
        address: 'Urb. Baldrich Calle Tousn Soto',
        lat: 18.4125,
        lng: -66.0627,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.0627, 18.4125] },
    },
    {
      type: 'Feature',
      properties: {
        id: '234',
        municipality: 'Guaynabo ',
        name: 'Esc. Juan Ponce de León',
        address: 'Carr. 2, Km. 7.3, Bo. Juan Domingo',
        lat: 18.4001,
        lng: -66.1209,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.1209, 18.4001] },
    },
    {
      type: 'Feature',
      properties: {
        id: '235',
        municipality: 'San Juan ',
        name: 'Esc. Juan Ponce de León',
        address: 'Avenida Barbosa',
        lat: 18.42046,
        lng: -66.0433,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.0433, 18.42046] },
    },
    {
      type: 'Feature',
      properties: {
        id: '236',
        municipality: 'Manatí',
        name: 'Esc. Juan S. Marchand Gonzalez',
        address: 'Sector Rabanas Bo. Boquillas Carr. 685',
        lat: 18.46047156,
        lng: -66.48654856,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.48654856, 18.46047156],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '237',
        municipality: 'Morovis',
        name: 'Esc. Juana G. (Goyita) Avilés',
        address: 'Bo. Franquez, Carr. 155 KM 2 HM 1',
        lat: 18.35659985,
        lng: -66.40967726,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.40967726, 18.35659985],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '238',
        municipality: 'Carolina',
        name: 'Esc. Juana Méndez Meléndez',
        address: 'Urb. Metrópolis Calle 1 final',
        lat: 18.3699,
        lng: -65.9855,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.9855, 18.3699] },
    },
    {
      type: 'Feature',
      properties: {
        id: '239',
        municipality: 'Canóvanas',
        name: 'Esc. Juana Rodríguez Mundo',
        address: 'Calle Adonis Final, Urb. Loiza Valley',
        lat: 18.3727,
        lng: -65.9136,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.9136, 18.3727] },
    },
    {
      type: 'Feature',
      properties: {
        id: '240',
        municipality: 'Florida',
        name: 'Esc. Juanita Ramírez González',
        address: 'PR 642 km. 10.0 Sec. Arroyo',
        lat: 18.3638254,
        lng: -66.55088847,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.55088847, 18.3638254],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '241',
        municipality: 'Vieques',
        name: 'Esc. Juanita Rivera Albert',
        address: '10 Calle Hucar Sector La Esperanza',
        lat: 18.0979,
        lng: -65.4757,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.4757, 18.0979] },
    },
    {
      type: 'Feature',
      properties: {
        id: '242',
        municipality: 'Arecibo',
        name: 'Esc. Julio Seijo',
        address: 'Carr. 490 int. 129 KM 0.6',
        lat: 18.4448,
        lng: -66.7551,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.7551, 18.4448] },
    },
    {
      type: 'Feature',
      properties: {
        id: '243',
        municipality: 'San Germán',
        name: 'Esc. Julio Víctor Guzmán',
        address: 'Ave. Jorge Alberto Comas H65',
        lat: 18.07851,
        lng: -67.0496,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.0496, 18.07851] },
    },
    {
      type: 'Feature',
      properties: {
        id: '244',
        municipality: 'Maricao',
        name: 'Esc. La Carmén',
        address: 'Carr. 105, Km 21.2, Bo Montoso, Sector la Carmen',
        lat: 18.17699,
        lng: -67.00709,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.00709, 18.17699] },
    },
    {
      type: 'Feature',
      properties: {
        id: '245',
        municipality: 'Vega Alta',
        name: 'Esc. Ladislao Rabell (Maestro Ladi)',
        address: 'Calle C, Urb Las Colinas',
        lat: 18.4095,
        lng: -66.3248,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.3248, 18.4095] },
    },
    {
      type: 'Feature',
      properties: {
        id: '246',
        municipality: 'Yauco           ',
        name: 'Esc. Lena M. Franceshi (Rubias Km. 25) ',
        address: 'Carr. 128, Km. 25 Int. Carr. 105, Bo. Rubias',
        lat: 18.15286,
        lng: -66.88588,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.88588, 18.15286] },
    },
    {
      type: 'Feature',
      properties: {
        id: '247',
        municipality: 'Las Piedras    ',
        name: 'Esc. Leoncio Meléndez',
        address: 'Carr 9922 Desvio Sur Anibal Garcia',
        lat: 18.177,
        lng: -65.8674,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.8674, 18.177] },
    },
    {
      type: 'Feature',
      properties: {
        id: '248',
        municipality: 'Lajas',
        name: 'Esc. Leonides Morales Rodríguez',
        address: '20 Calle Santa Rosa',
        lat: 18.0489,
        lng: -67.0568,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.0568, 18.0489] },
    },
    {
      type: 'Feature',
      properties: {
        id: '249',
        municipality: 'Río Grande    ',
        name: 'Esc. Liberata Iraldo',
        address: 'Calle Main Urb Alturas de Rio Grande',
        lat: 18.3792,
        lng: -65.8495,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.8495, 18.3792] },
    },
    {
      type: 'Feature',
      properties: {
        id: '250',
        municipality: 'Ponce     ',
        name: 'Esc. Lila Maria Mercedes Mayoral',
        address: 'Carr 2 Km 22 Bo El Tuque',
        lat: 17.9839,
        lng: -66.6639,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.6639, 17.9839] },
    },
    {
      type: 'Feature',
      properties: {
        id: '251',
        municipality: 'Vega Baja',
        name: 'Esc. Lino Padrón Rivera',
        address: 'Calle Tulio Otero',
        lat: 18.4455,
        lng: -66.3847,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.3847, 18.4455] },
    },
    {
      type: 'Feature',
      properties: {
        id: '252',
        municipality: 'Hatillo',
        name: 'Esc. Lorenzo Coballes Gandía',
        address: 'Carr. 130, Km. 0.1, Bo. Pueblo, Sector Los Alvarez',
        lat: 18.4817,
        lng: -66.8211,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.8211, 18.4817] },
    },
    {
      type: 'Feature',
      properties: {
        id: '253',
        municipality: 'Moca',
        name: 'Esc. Luis A. Colón',
        address: 'Calle Blanca E. Chico #230  00626',
        lat: 18.3959,
        lng: -67.11232,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.11232, 18.3959] },
    },
    {
      type: 'Feature',
      properties: {
        id: '254',
        municipality: 'Sabana Grande',
        name: 'Esc. Luis A. Negrón',
        address: 'Carr 368 km 1.0 bo. Machuchal',
        lat: 18.07451,
        lng: -66.9492,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.9492, 18.07451] },
    },
    {
      type: 'Feature',
      properties: {
        id: '255',
        municipality: 'Camuy',
        name: 'Esc. Luis Felipe Crespo',
        address: 'Carr 486, Km. 1.2, Bo. Puente',
        lat: 18.4656,
        lng: -66.8522,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.8522, 18.4656] },
    },
    {
      type: 'Feature',
      properties: {
        id: '256',
        municipality: 'Camuy',
        name: 'Esc. Luis Felipe Rodríguez García',
        address: 'Carr. 119, Int. 483',
        lat: 18.4325,
        lng: -66.8917,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.8917, 18.4325] },
    },
    {
      type: 'Feature',
      properties: {
        id: '257',
        municipality: 'Canóvanas',
        name: 'Esc. Luis Hernaiz',
        address: 'Calle Autonomia Final',
        lat: 18.376,
        lng: -65.9012,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.9012, 18.376] },
    },
    {
      type: 'Feature',
      properties: {
        id: '258',
        municipality: 'San Juan ',
        name: 'Esc. Luis Llorens Torres',
        address: 'Calle Mansabel Res. Luis Llorens',
        lat: 18.0468,
        lng: -66.5052,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.5052, 18.0468] },
    },
    {
      type: 'Feature',
      properties: {
        id: '259',
        municipality: 'Ceiba',
        name: 'Esc. Luis Muñoz  Marín',
        address: 'Desvío Felisa Rincón de Gautier,  Carr. 979',
        lat: 18.2604639872151,
        lng: -65.648440901279599,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-65.648440901279599, 18.2604639872151],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '260',
        municipality: 'San Lorenzo',
        name: 'Esc. Luis Muñoz  Rivera                                  ',
        address: 'Calle Luis Muñoz Rivera Final, frente al Cuartel Policía',
        lat: 18.18630371,
        lng: -65.96355797,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-65.96355797, 18.18630371],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '261',
        municipality: 'Añasco',
        name: 'Esc. Luis Muñoz Marín',
        address: 'Carr 402 KM 1.6 Bo María',
        lat: 18.29411,
        lng: -67.13986,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.13986, 18.29411] },
    },
    {
      type: 'Feature',
      properties: {
        id: '262',
        municipality: 'Arecibo',
        name: 'Esc. Luis Muñoz Marín',
        address: 'Carr. 635 KM Sector Barranca',
        lat: 18.4659,
        lng: -66.7471,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.7471, 18.4659] },
    },
    {
      type: 'Feature',
      properties: {
        id: '263',
        municipality: 'Comerío',
        name: 'Esc. Luis Muñoz Marín',
        address: 'Carr. 7774, Km. 0.1, Bo. Piñas',
        lat: 18.22988999,
        lng: -66.22605205000001,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.22605205, 18.22988999],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '264',
        municipality: 'Mayagüez',
        name: 'Esc. Luis Muñoz Rivera',
        address: 'Calle antonio cruz Bo. Manantiales',
        lat: 18.1942,
        lng: -67.134,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.134, 18.1942] },
    },
    {
      type: 'Feature',
      properties: {
        id: '265',
        municipality: 'Guaynabo',
        name: 'Esc. Luis Muñoz Rivera II',
        address: 'Ave. Ponce de León, Bo. Amelia, Sector Sabana',
        lat: 18.4305,
        lng: -66.1141,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.1141, 18.4305] },
    },
    {
      type: 'Feature',
      properties: {
        id: '266',
        municipality: 'Dorado',
        name: 'Esc. Luisa M. Valderrama (San Antonio)',
        address: 'Carr. 696 Km. 1.6, Bo. Higuillar',
        lat: 18.4488,
        lng: -66.2937,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.2937, 18.4488] },
    },
    {
      type: 'Feature',
      properties: {
        id: '267',
        municipality: 'Aguada',
        name: 'Esc. Lydia Meléndez (Elemental Urbana Nueva)               ',
        address: 'Carr. 115 Km. 23.9, Bo. Asomante (Calle Colón)',
        lat: 18.38286,
        lng: -67.18314,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.18314, 18.38286] },
    },
    {
      type: 'Feature',
      properties: {
        id: '268',
        municipality: 'San Juan ',
        name: 'Esc. Maestro Manuel Boada',
        address: 'Ave.  Borinquen Esq. Cortijo Bo. Obrero',
        lat: 18.435877963737301,
        lng: -66.050907745815195,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.050907745815195, 18.435877963737301],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '269',
        municipality: 'Juana Díaz',
        name: 'Esc. Manuel Fernández Juncos',
        address: 'Calle Comercio 125 Juana Diaz',
        lat: 18.05236,
        lng: -66.50275,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.50275, 18.05236] },
    },
    {
      type: 'Feature',
      properties: {
        id: '270',
        municipality: 'Rincón          ',
        name: 'Esc. Manuel García Pérez',
        address: 'Calle Albizu Campos Int 12',
        lat: 18.3356,
        lng: -67.2493,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.2493, 18.3356] },
    },
    {
      type: 'Feature',
      properties: {
        id: '271',
        municipality: 'Rincón          ',
        name: 'Esc. Manuel González Melo',
        address: 'Carr 115 km 9 Hm 0 Bo. Camvalache',
        lat: 18.31429,
        lng: -67.22657,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.22657, 18.31429] },
    },
    {
      type: 'Feature',
      properties: {
        id: '272',
        municipality: 'Santa Isabel  ',
        name: 'Esc. Manuel Martín Monserrate',
        address: 'Calle Baldorioty #6, Santa Isabel PR 00757',
        lat: 17.9668,
        lng: -66.4059,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.4059, 17.9668] },
    },
    {
      type: 'Feature',
      properties: {
        id: '273',
        municipality: 'Quebradillas',
        name: 'Esc. Manuel Ramos Hernández',
        address: 'Carr.#2 101 km 3 ',
        lat: 18.4779,
        lng: -66.9366,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.9366, 18.4779] },
    },
    {
      type: 'Feature',
      properties: {
        id: '274',
        municipality: 'Arecibo',
        name: 'Esc. Manuel Ruiz Gandía',
        address: 'Carr. 635 KM 2.2 esq. 651 Sector 4 calle',
        lat: 18.4214,
        lng: -66.7487,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.7487, 18.4214] },
    },
    {
      type: 'Feature',
      properties: {
        id: '275',
        municipality: 'Gurabo',
        name: 'Esc. Margarita Rivera de Janer',
        address: 'Calle San antonio Bo. Jaguas',
        lat: 18.25719,
        lng: -65.97409,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.97409, 18.25719] },
    },
    {
      type: 'Feature',
      properties: {
        id: '276',
        municipality: 'Arecibo',
        name: 'Esc. María Cadilla de Martínez',
        address: 'Carr. 129 Sector Cuneta',
        lat: 18.4408,
        lng: -66.7579,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.7579, 18.4408] },
    },
    {
      type: 'Feature',
      properties: {
        id: '277',
        municipality: 'Toa Baja',
        name: 'Esc. María Corredor Rivera',
        address: 'Calle Palacio Valdes',
        lat: 18.40016,
        lng: -66.20008,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.20008, 18.40016] },
    },
    {
      type: 'Feature',
      properties: {
        id: '278',
        municipality: 'Guánica',
        name: 'Esc. María L. McDougall',
        address:
          '24 Calle 65 Infanteria, Esq. Dr. Veve, frente Plaza de Recreo',
        lat: 17.97281,
        lng: -66.90796,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.90796, 17.97281] },
    },
    {
      type: 'Feature',
      properties: {
        id: '279',
        municipality: 'Vieques',
        name: 'Esc. María Simmons de Rivera',
        address: 'Carr 993 KM 2 BO. Coffi Sector Cañon 00765 ',
        lat: 18.144054836808799,
        lng: -65.446057706245696,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-65.446057706245696, 18.144054836808799],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '280',
        municipality: 'San Lorenzo',
        name: 'Esc. María T. Delgado',
        address: 'Bo. Florida carr. 9929 Km. 0.1',
        lat: 18.188,
        lng: -65.9571,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.9571, 18.188] },
    },
    {
      type: 'Feature',
      properties: {
        id: '281',
        municipality: 'Carolina',
        name: 'Esc. María Teresa Serrano                                              ',
        address: 'Vía 8 Urb. Villa Fontana',
        lat: 18.4037,
        lng: -65.97526,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.97526, 18.4037] },
    },
    {
      type: 'Feature',
      properties: {
        id: '282',
        municipality: 'Yabucoa',
        name: 'Esc. Marta Sánchez Alverio',
        address: 'Carr. 900 Km. 2.6 sector Playita',
        lat: 18.0446,
        lng: -65.9071966,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.9071966, 18.0446] },
    },
    {
      type: 'Feature',
      properties: {
        id: '283',
        municipality: 'Toa Baja',
        name: 'Esc. Martín García Giusti',
        address: 'Carr 863, Km. 0.9, Bo. Pájaros',
        lat: 18.39914,
        lng: -66.20904,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.20904, 18.39914] },
    },
    {
      type: 'Feature',
      properties: {
        id: '284',
        municipality: 'Trujillo Alto',
        name: 'Esc. Medardo Carazo                     ',
        address: 'Calle Andres varcarcel',
        lat: 18.35367986514818,
        lng: -66.005741440067098,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.005741440067098, 18.35367986514818],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '285',
        municipality: 'Loiza',
        name: 'Esc. Medianía Alta Elemental',
        address: 'Carr. 187, Bo. Medianía Alta Km 5.6',
        lat: 18.4263,
        lng: -65.8425,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.8425, 18.4263] },
    },
    {
      type: 'Feature',
      properties: {
        id: '286',
        municipality: 'Cataño',
        name: 'Esc. Mercedes García de Colorado.',
        address: 'Ave. Flor del Valle Final, Urb. Las Vegas',
        lat: 18.4355,
        lng: -66.1477,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.1477, 18.4355] },
    },
    {
      type: 'Feature',
      properties: {
        id: '287',
        municipality: 'Jayuya',
        name: 'Esc. Miguel A Sastre Oliver',
        address: 'Carr 527 Km 2.8 Veguita Zama',
        lat: 18.20217,
        lng: -66.57904,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.57904, 18.20217] },
    },
    {
      type: 'Feature',
      properties: {
        id: '288',
        municipality: 'Cabo Rojo',
        name: 'Esc. Milderd Arroyo',
        address: 'Carr. 308 km 5.4 Puerto Real Cabo Rojo 00623',
        lat: 18.08639,
        lng: -67.18725,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.18725, 18.08639] },
    },
    {
      type: 'Feature',
      properties: {
        id: '289',
        municipality: 'Cabo Rojo',
        name: 'Esc. Monserrate Leon',
        address: 'Carr. 101Km. 18.2 Pobaldo boqueron',
        lat: 18.0276,
        lng: -67.1697,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.1697, 18.0276] },
    },
    {
      type: 'Feature',
      properties: {
        id: '290',
        municipality: 'Orocovis',
        name: 'Esc. Nelida Meléndez Meléndez',
        address: 'Ave. Luis Muñoz Marin',
        lat: 18.2295,
        lng: -66.3919,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.3919, 18.2295] },
    },
    {
      type: 'Feature',
      properties: {
        id: '291',
        municipality: 'Jayuya',
        name: 'Esc. Nemesio R Canales',
        address: 'Carr. 144 Km 9',
        lat: 18.21287,
        lng: -66.5676,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.5676, 18.21287] },
    },
    {
      type: 'Feature',
      properties: {
        id: '292',
        municipality: 'San Juan ',
        name: 'Esc. Nemesio R. Canales I',
        address: 'Calle Res. Nemesio Canales',
        lat: 18.4221406229446,
        lng: -66.078770687953707,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.078770687953707, 18.4221406229446],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '293',
        municipality: 'Toa Alta',
        name: 'Esc. Nicolas Sevilla',
        address: 'Urb. Jard deToa Alta Calle 1',
        lat: 18.3913,
        lng: -66.24841,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.24841, 18.3913] },
    },
    {
      type: 'Feature',
      properties: {
        id: '294',
        municipality: 'Guayama ',
        name: 'Esc. Oscar Hernández Guevara ',
        address: 'Calle D, Bda. Blondet',
        lat: 17.9741,
        lng: -66.1244,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.1244, 17.9741] },
    },
    {
      type: 'Feature',
      properties: {
        id: '295',
        municipality: 'Hatillo',
        name: 'Esc. Padre Aníbal Reyes',
        address: 'Carr. 130 K12 4 Bo. Campo Alegre',
        lat: 18.4077,
        lng: -66.7764,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.7764, 18.4077] },
    },
    {
      type: 'Feature',
      properties: {
        id: '296',
        municipality: 'San Lorenzo',
        name: 'Esc. Padre Jorge Rosario del Valle',
        address: 'Carr. 181, Ramal 745, Km. 1.0, Bo. Espino',
        lat: 18.1202,
        lng: -65.9934,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.9934, 18.1202] },
    },
    {
      type: 'Feature',
      properties: {
        id: '297',
        municipality: 'San Sebastián',
        name: 'Esc. Patria La Torre',
        address: 'Carr. 125 Km. 23.2',
        lat: 18.3338,
        lng: -66.97803,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.97803, 18.3338] },
    },
    {
      type: 'Feature',
      properties: {
        id: '298',
        municipality: 'Juncos',
        name: 'Esc. Pedro Bosch Salgas                                 ',
        address: 'Carr. 935, Bo. Ceiba Norte, Sector Santana 2',
        lat: 18.2174,
        lng: -65.8917,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.8917, 18.2174] },
    },
    {
      type: 'Feature',
      properties: {
        id: '299',
        municipality: 'Río Grande    ',
        name: 'Esc. Pedro Falú Orellano',
        address: 'Calle Pimentel Bo. Las Flores',
        lat: 18.38,
        lng: -65.8366,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.8366, 18.38] },
    },
    {
      type: 'Feature',
      properties: {
        id: '300',
        municipality: 'Juana Díaz',
        name: 'Esc. Pedro Nini Colón',
        address: 'Bo. Jacaguax Km11.1 Carretera 14 Juana Diaz',
        lat: 18.05334,
        lng: -66.52266,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.52266, 18.05334] },
    },
    {
      type: 'Feature',
      properties: {
        id: '301',
        municipality: 'Bayamón',
        name: 'Esc. Pedro P. Casablanca Catinchi.',
        address: 'Calle 21 Jardines de Caparra',
        lat: 18.395086714664501,
        lng: -66.126471944828594,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.126471944828594, 18.395086714664501],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '302',
        municipality: 'Mayagüez',
        name: 'Esc. Pedro Perea',
        address: 'Ave. Luis Llorens Torres Carr #2',
        lat: 18.2085,
        lng: -67.14574,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.14574, 18.2085] },
    },
    {
      type: 'Feature',
      properties: {
        id: '303',
        municipality: 'Guayama       ',
        name: 'Esc. Rafael A. Delgado Mateo (Olimpo 2)',
        address: 'Calle 2, Bo. Olimpo',
        lat: 18.0028,
        lng: -66.1107,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.1107, 18.0028] },
    },
    {
      type: 'Feature',
      properties: {
        id: '304',
        municipality: 'Adjuntas',
        name: 'Esc. Rafael Aparicio Jiménez',
        address: '71 PR 5516  Adjuntas 00601',
        lat: 18.16746,
        lng: -66.72475,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.72475, 18.16746] },
    },
    {
      type: 'Feature',
      properties: {
        id: '305',
        municipality: 'Cataño',
        name: 'Esc. Rafael Cordero',
        address: 'Ave. Los Caños, Marginal Perucho Cepeda ',
        lat: 18.43734,
        lng: -66.1249,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.1249, 18.43734] },
    },
    {
      type: 'Feature',
      properties: {
        id: '306',
        municipality: 'Camuy',
        name: 'Esc. Ralph W. Emerson',
        address: 'Bo Membrillo Carr. 2 km 93.4',
        lat: 18.4722,
        lng: -66.8731,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.8731, 18.4722] },
    },
    {
      type: 'Feature',
      properties: {
        id: '307',
        municipality: 'Quebradillas',
        name: 'Esc. Ramon Emeterio Betances',
        address: 'Calle san justo 236 Quebradillas',
        lat: 18.4762,
        lng: -66.9391,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.9391, 18.4762] },
    },
    {
      type: 'Feature',
      properties: {
        id: '308',
        municipality: 'Guaynabo',
        name: 'Esc. Ramón Marín Solá',
        address: 'Calle Azalea, Esq. Campobello, Urb. Muñoz Rivera',
        lat: 18.3767,
        lng: -66.103,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.103, 18.3767] },
    },
    {
      type: 'Feature',
      properties: {
        id: '309',
        municipality: 'Yabucoa',
        name: 'Esc. Ramon Quiñones Medina',
        address: 'Avenida los Veteranos Reparto Horizonte Yabucoa',
        lat: 18.0445,
        lng: -65.8823,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.8823, 18.0445] },
    },
    {
      type: 'Feature',
      properties: {
        id: '310',
        municipality: 'Maricao',
        name: 'Esc. Raul  Ibarra',
        address: 'Ave. Lucetti #8 Carr. 120 norte',
        lat: 18.18251,
        lng: -66.9796,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.9796, 18.18251] },
    },
    {
      type: 'Feature',
      properties: {
        id: '311',
        municipality: 'San Juan',
        name: 'Esc. República de Colombia',
        address: 'Calle Cabo Máximo Alomar, Urb. San Agustín, R.P.',
        lat: 18.3969,
        lng: -66.0414,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.0414, 18.3969] },
    },
    {
      type: 'Feature',
      properties: {
        id: '312',
        municipality: 'Juncos ',
        name: 'Esc. Roberto Silva Morales',
        address: 'Bo. Los Lirios Carr. 929 K.m. 6.0',
        lat: 18.2249,
        lng: -65.9355,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.9355, 18.2249] },
    },
    {
      type: 'Feature',
      properties: {
        id: '313',
        municipality: 'Naranjito',
        name: 'Esc. Ruben Rodriguez',
        address: 'Carr. 164 Bo. Nuevo',
        lat: 18.29935686,
        lng: -66.2364774,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.2364774, 18.29935686],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '314',
        municipality: 'Ciales',
        name: 'Esc. S.U.  Francisco Serrano',
        address: 'Carr. 149 Km. 20, Bo. Pesa',
        lat: 18.29553004,
        lng: -66.49239232,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.49239232, 18.29553004],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '315',
        municipality: 'Vega Baja',
        name: 'Esc. S.U. Almirante Norte',
        address: 'Km. 4.3 Carr 160 Bo. Almirante Norte',
        lat: 18.4092,
        lng: -66.3679,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.3679, 18.4092] },
    },
    {
      type: 'Feature',
      properties: {
        id: '316',
        municipality: 'Yabucoa',
        name: 'Esc. S.U. Andrés Sandín',
        address: 'Carr. 906 Km. 1.2, Bo. Aguacate',
        lat: 18.0855,
        lng: -65.8467,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.8467, 18.0855] },
    },
    {
      type: 'Feature',
      properties: {
        id: '317',
        municipality: 'Carolina',
        name: 'Esc. S.U. Ángel P. Millán Rohena ',
        address: 'Carr. 857 Km. 9.0, Bo. Canovanillas, Urb. Parque Ecuestre',
        lat: 18.3693,
        lng: -65.9305,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.9305, 18.3693] },
    },
    {
      type: 'Feature',
      properties: {
        id: '318',
        municipality: 'Jayuya',
        name: 'Esc. S.U. Antonia Serrano González',
        address: 'Carr. 141 Int. Hacienda Rodriguez',
        lat: 18.25964,
        lng: -66.57775,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.57775, 18.25964] },
    },
    {
      type: 'Feature',
      properties: {
        id: '319',
        municipality: 'Camuy',
        name: 'Esc. S.U. Antonio Reyes',
        address: 'Carr. 119, Carr. 486 Km. 2.1, Bo. Zanja',
        lat: 18.4516,
        lng: -66.854,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.854, 18.4516] },
    },
    {
      type: 'Feature',
      properties: {
        id: '320',
        municipality: 'Bayamón',
        name: 'Esc. S.U. Antonio Rivera Rivera                                      ',
        address: 'Carr. 167 Ramal 816 Int. 879, Km. 1, Bo. Nuevo',
        lat: 18.278293545483901,
        lng: -66.186318608296702,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.186318608296702, 18.278293545483901],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '321',
        municipality: 'Barranquitas',
        name: 'Esc. S.U. Antonio Vázquez Ramos (La Loma)',
        address: 'Carr. 159, Km 20.1, Bo. Quebrada Grande, Sector La Loma',
        lat: 18.18459575,
        lng: -66.2773908,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.2773908, 18.18459575],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '322',
        municipality: 'Orocovis',
        name: 'Esc. S.U. Botijas I',
        address: 'Carr. 568 Km 44.0, Bo. Botijas-El Puente',
        lat: 18.2416824,
        lng: -66.3660742,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.3660742, 18.2416824],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '323',
        municipality: 'Cidra',
        name: 'Esc. S.U. Certenejas II                                                                                                           ',
        address: 'Carr. 172, Km. 7.5, Bo. Bayamón, Sector Certenejas',
        lat: 18.19687391,
        lng: -66.11909536,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.11909536, 18.19687391],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '324',
        municipality: 'Corozal',
        name: 'Esc. S.U. Demetrio Rivera (Palmarito Centro)',
        address: 'Carr. 801, Km. 0.5, Bo. Palmarito',
        lat: 18.2688748,
        lng: -66.3397678,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.3397678, 18.2688748],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '325',
        municipality: 'Aguada',
        name: 'Esc. S.U. Epifanio Estrada',
        address: 'Carr 411, Km. 2.7, Bo. Jagüey',
        lat: 18.36161,
        lng: -67.19967,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.19967, 18.36161] },
    },
    {
      type: 'Feature',
      properties: {
        id: '326',
        municipality: 'Toa Baja',
        name: 'Esc. S.U. Francisca Dávila Semprit ',
        address: 'Carr. 866 Ave. Principal, Bo. Sabana Seca',
        lat: 18.4266,
        lng: -66.1857,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.1857, 18.4266] },
    },
    {
      type: 'Feature',
      properties: {
        id: '327',
        municipality: 'Maunabo',
        name: 'Esc. S.U. Higinio Figueroa Villegas ( Bordaleza)',
        address:
          'Carr. 9901 Ramal 7760, Km. 1.3, Bo Emajaguas, Sector Bordaleza',
        lat: 17.9995,
        lng: -65.8885,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.8885, 17.9995] },
    },
    {
      type: 'Feature',
      properties: {
        id: '328',
        municipality: 'Ciales',
        name: 'Esc. S.U. Jaime Coira Ortiz.',
        address: 'Carr. 615 Barrio Poza sector Llanadas en Ciales',
        lat: 18.2724136,
        lng: -66.48301403000001,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.48301403, 18.2724136],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '329',
        municipality: 'Carolina',
        name: 'Esc. S.U. Jesús T. Piñero                      ',
        address: 'Carr. 657 Int. Km. 9.5, Bo. Carruzos',
        lat: 18.3166,
        lng: -65.9145,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.9145, 18.3166] },
    },
    {
      type: 'Feature',
      properties: {
        id: '330',
        municipality: 'Maunabo',
        name: 'Esc. S.U. Manuel Ortiz Suya                               ',
        address: 'carr. 753, Km. 2.2, Bda. Marín, Bo. Pitahaya',
        lat: 18.0085,
        lng: -65.9329,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.9329, 18.0085] },
    },
    {
      type: 'Feature',
      properties: {
        id: '331',
        municipality: 'Utuado',
        name: 'Esc. S.U. Marta Lafontaine',
        address: 'Carr. 140 Int. Carr. 613 Km. 2.3, Bo. Caonillas',
        lat: 18.2683,
        lng: -66.6442,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.6442, 18.2683] },
    },
    {
      type: 'Feature',
      properties: {
        id: '332',
        municipality: 'Orocovis',
        name: 'Esc. S.U. Matrullas',
        address: 'Carr. 564, Km. 4.3, Bo. Matrullas',
        lat: 18.20128427,
        lng: -66.48751052,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.48751052, 18.20128427],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '333',
        municipality: 'Santa Isabel',
        name: 'Esc. S.U. Pedro Meléndez Santiago (Playita Cortada)                        ',
        address: 'Calle Libertad Bo. Playita Cortada',
        lat: 17.9845,
        lng: -66.4373,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.4373, 17.9845] },
    },
    {
      type: 'Feature',
      properties: {
        id: '334',
        municipality: 'Juncos',
        name: 'Esc. S.U. Pedro Rivera Molina ',
        address: 'Carr. 183, Km. 16.3, Bo. Valenciano Abajo',
        lat: 18.1755,
        lng: -65.9179,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.9179, 18.1755] },
    },
    {
      type: 'Feature',
      properties: {
        id: '335',
        municipality: 'Río Grande    ',
        name: 'Esc. S.U. Rafael Rexach Dueño                                           ',
        address: 'Carr. 955 Km 7.3, Calle Principal, Bo. Palmer',
        lat: 18.36922,
        lng: -65.7738,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.7738, 18.36922] },
    },
    {
      type: 'Feature',
      properties: {
        id: '336',
        municipality: 'Hatillo',
        name: 'Esc. S.U. Rafael Zamot Cruz',
        address: 'Carr. 134, Km. 26.7, Bo. Bayaney',
        lat: 18.365,
        lng: -66.801,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.801, 18.365] },
    },
    {
      type: 'Feature',
      properties: {
        id: '337',
        municipality: 'Arecibo',
        name: 'Esc. S.U. Sabana Hoyos',
        address: 'Carr. 639 Km 3 H 2',
        lat: 18.4086,
        lng: -66.6064,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.6064, 18.4086] },
    },
    {
      type: 'Feature',
      properties: {
        id: '338',
        municipality: 'Orocovis',
        name: 'Esc. S.U. Saltos Cabras  (Angel Diaz)                                                          ',
        address: 'Carr. 566 Km 21.6, Carr. 155 Int., Bo. Saltos',
        lat: 18.2043,
        lng: -66.4225,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.4225, 18.2043] },
    },
    {
      type: 'Feature',
      properties: {
        id: '339',
        municipality: 'Naguabo',
        name: 'Esc. S.U. Silverio García                      ',
        address: 'Carr. 3, Km. 9.5, Bo. Daguao, Sector Cuesta La Esperanza',
        lat: 18.21902,
        lng: -65.6899,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.6899, 18.21902] },
    },
    {
      type: 'Feature',
      properties: {
        id: '340',
        municipality: 'Ciales',
        name: 'Esc. S.U. Toribio Rivera                                                  ',
        address: 'Carr. 146 Km. 19, Bo. Frontón',
        lat: 18.30413929,
        lng: -66.55544771,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.55544771, 18.30413929],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '341',
        municipality: 'Gurabo',
        name: 'Esc. S.U. Vidal Serrano                                                                 ',
        address: 'Carr. 30, Carr. 189 Ramal 931, Bo. Navarro',
        lat: 18.2302,
        lng: -66.0038,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.0038, 18.2302] },
    },
    {
      type: 'Feature',
      properties: {
        id: '342',
        municipality: 'San Juan',
        name: 'Esc. San Agustín',
        address: 'Calle 6 Urb. San Agustín, R.P.',
        lat: 18.3937,
        lng: -66.0378,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.0378, 18.3937] },
    },
    {
      type: 'Feature',
      properties: {
        id: '343',
        municipality: 'Jayuya',
        name: 'Esc. San Patricio',
        address: 'Carr. 143 Km. 8.0 Bo. Pica',
        lat: 18.15721,
        lng: -66.64383,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.64383, 18.15721] },
    },
    {
      type: 'Feature',
      properties: {
        id: '344',
        municipality: 'Guaynabo',
        name: 'Esc. Santa Rosa III',
        address: 'Carr 833, Km 11.6, Bo Santa Rosa III',
        lat: 18.3707,
        lng: -66.135,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.135, 18.3707] },
    },
    {
      type: 'Feature',
      properties: {
        id: '345',
        municipality: 'Ponce',
        name: 'Esc. Santa Teresita                                                  ',
        address: 'Calle 23, Esq. 24, Urb. Santa Teresita',
        lat: 18.03116,
        lng: -66.60792,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.60792, 18.03116] },
    },
    {
      type: 'Feature',
      properties: {
        id: '346',
        municipality: 'San Juan ',
        name: 'Esc. Santiago Iglesias Pantín',
        address: 'Calle Flandes Esq.Un Diales',
        lat: 18.422027897507899,
        lng: -66.034979626083299,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.034979626083299, 18.422027897507899],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '347',
        municipality: 'Camuy',
        name: 'Esc. Santiago R. Palmer (Int.)',
        address: 'Carr. 129 Ramal. 486 Km. 7.0, Bo. Quebrada',
        lat: 18.368,
        lng: -66.834,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.834, 18.368] },
    },
    {
      type: 'Feature',
      properties: {
        id: '348',
        municipality: 'Fajardo',
        name: 'Esc. Santiago Veve Calzada',
        address: 'Ave. Osvaldo Molina Vazquez',
        lat: 18.3329,
        lng: -65.65,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.65, 18.3329] },
    },
    {
      type: 'Feature',
      properties: {
        id: '349',
        municipality: 'Hormigueros',
        name: 'Esc. Segundo Ruiz Belvis',
        address: 'Calle Segundo Ruiz Belvis, Hormigueros',
        lat: 18.13834,
        lng: -67.126,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.126, 18.13834] },
    },
    {
      type: 'Feature',
      properties: {
        id: '350',
        municipality: 'Cabo Rojo',
        name: 'Esc. Severo E. Colberg Ramírez',
        address: '102 Ave. Pedro Albizu Campos, Carr 102 Km. 19.5 ',
        lat: 18.0868,
        lng: -67.1524,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.1524, 18.0868] },
    },
    {
      type: 'Feature',
      properties: {
        id: '351',
        municipality: 'Naranjito',
        name: 'Esc. Silvestre Martinez',
        address: 'Comunidad Guadiana ',
        lat: 18.32208479,
        lng: -66.22545596000001,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.22545596, 18.32208479],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '352',
        municipality: 'Villalba',
        name: 'Esc. Silvia Torres',
        address: 'Carr 149 Bo Jagueyes Arriba',
        lat: 18.1117,
        lng: -66.48581,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.48581, 18.1117] },
    },
    {
      type: 'Feature',
      properties: {
        id: '353',
        municipality: 'Guayama       ',
        name: 'Esc. Simon Madera',
        address: 'Calle Mcarthur final Carr. #3',
        lat: 17.9827,
        lng: -66.1192,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.1192, 17.9827] },
    },
    {
      type: 'Feature',
      properties: {
        id: '354',
        municipality: 'Salinas',
        name: 'Esc. Stella Márquez',
        address: 'Calle Unión Final (Santos P. Amadeo), frente al Hospital',
        lat: 17.9737,
        lng: -66.2965,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.2965, 17.9737] },
    },
    {
      type: 'Feature',
      properties: {
        id: '355',
        municipality: 'Corozal',
        name: 'Esc. SU Julian Marrero',
        address: 'Carr. 164 int 803 Bo Palmarejo Corozal PR 00783',
        lat: 18.3211,
        lng: -66.2971,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.2971, 18.3211] },
    },
    {
      type: 'Feature',
      properties: {
        id: '356',
        municipality: 'Coamo',
        name: 'Esc. SU Román Colón Correa',
        address: 'Carr 154 Km.4 Bo. Rio Jueyes',
        lat: 18.0211,
        lng: -66.3323,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.3323, 18.0211] },
    },
    {
      type: 'Feature',
      properties: {
        id: '357',
        municipality: 'Aguadilla',
        name: 'Esc. Sup. Juan Suárez Pelegrina',
        address: 'Carr. 459 Bo Montana Aguadilla',
        lat: 18.4869,
        lng: -67.0972,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.0972, 18.4869] },
    },
    {
      type: 'Feature',
      properties: {
        id: '358',
        municipality: 'Moca',
        name: 'Esc. Sup. Marcelino Rodriguez',
        address: 'Bo. Voladoras carr 434',
        lat: 18.38011,
        lng: -67.07868,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.07868, 18.38011] },
    },
    {
      type: 'Feature',
      properties: {
        id: '359',
        municipality: 'Aibonito',
        name: 'Esc. Superior Dr. José N. Gándara',
        address: '#315 Calle Degetau Final',
        lat: 18.14518294,
        lng: -66.26909885000001,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.26909885, 18.14518294],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '360',
        municipality: 'Santa Isabel  ',
        name: 'Esc. Superior Elvira Colón',
        address: 'Calle Celis Aguilera Sector Zona Industrial 155',
        lat: 17.9603,
        lng: -66.4053,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.4053, 17.9603] },
    },
    {
      type: 'Feature',
      properties: {
        id: '361',
        municipality: 'Barranquitas',
        name: 'Esc. Superior Luis Muñoz Marín                                         ',
        address:
          'Ramal 152 Km. 1.4, Interior,  Sector Nuevo Barranquitas, Calle A',
        lat: 18.19147373,
        lng: -66.3076466,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.3076466, 18.19147373],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '362',
        municipality: 'Arroyo',
        name: 'Esc. Superior Natividad Rodríguez',
        address: 'PR 178, Km. 2.9 Bo Guácima',
        lat: 17.9747,
        lng: -66.0691,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.0691, 17.9747] },
    },
    {
      type: 'Feature',
      properties: {
        id: '363',
        municipality: 'Aguas Buenas',
        name: 'Esc. Superior Urbana',
        address: 'Bo. Caguitas Carr. 156 km 54.5',
        lat: 18.25694967,
        lng: -66.09119494,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.09119494, 18.25694967],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '364',
        municipality: 'Villalba',
        name: 'Esc. Superiror Vocacional Cristina Martínez',
        address: 'Carr. 149 Km. 59.1 Bo. Hato Puerto Rico',
        lat: 18.1105,
        lng: -66.50328,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.50328, 18.1105] },
    },
    {
      type: 'Feature',
      properties: {
        id: '365',
        municipality: 'Coamo',
        name: 'Esc. Susana Rivera',
        address: 'Los Llanos Coamo',
        lat: 18.0507,
        lng: -66.3989,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.3989, 18.0507] },
    },
    {
      type: 'Feature',
      properties: {
        id: '366',
        municipality: 'Hatillo',
        name: 'Esc. Timoteo Delgado',
        address: 'Bo. Pajuil Carr. 130 K. 8.3',
        lat: 18.4282,
        lng: -66.7901,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.7901, 18.4282] },
    },
    {
      type: 'Feature',
      properties: {
        id: '367',
        municipality: 'Arecibo',
        name: 'Esc. Trina Padilla de Sanz',
        address: 'Urb. Villa de los Santos Carr. 653 Km 1',
        lat: 18.4657,
        lng: -66.7434,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.7434, 18.4657] },
    },
    {
      type: 'Feature',
      properties: {
        id: '368',
        municipality: 'Orocovis',
        name: 'Esc. Viisitacion Pagan (El Gato)',
        address: 'Carr. 155 Km 34.1 Bo. Gatos Orocovis',
        lat: 18.26187782,
        lng: -66.40147376,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.40147376, 18.26187782],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '369',
        municipality: 'Gurabo',
        name: 'Esc. Villa Marina',
        address: 'C 79 Calle Bahía Oeste, Urb. Villa Marina',
        lat: 18.2523,
        lng: -65.9773,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.9773, 18.2523] },
    },
    {
      type: 'Feature',
      properties: {
        id: '370',
        municipality: 'Canóvanas',
        name: 'Esc. Vocacional William Rivera Betancourt',
        address: 'Bo. San Isidro, Carr. 188 Km 1.6',
        lat: 18.3892,
        lng: -65.8875,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.8875, 18.3892] },
    },
    {
      type: 'Feature',
      properties: {
        id: '371',
        municipality: 'San Juan',
        name: 'Esc. William D. Boyce                                      ',
        address: 'Calle 31 SO, Urb. Las Lomas, Río Piedras',
        lat: 18.39383,
        lng: -66.09574,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.09574, 18.39383] },
    },
    {
      type: 'Feature',
      properties: {
        id: '372',
        municipality: 'Juana Díaz',
        name: 'Esc. Zoilo García',
        address: 'Carr. 512 Km. 5 H2 00795',
        lat: 18.091,
        lng: -66.53877,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.53877, 18.091] },
    },
    {
      type: 'Feature',
      properties: {
        id: '373',
        municipality: 'San Juan ',
        name: 'Esc.Trina Padilla Sanz',
        address: '950 AVE Jesus T. Pinero  00921',
        lat: 18.4063,
        lng: -66.0736,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.0736, 18.4063] },
    },
    {
      type: 'Feature',
      properties: {
        id: '374',
        municipality: 'Lajas',
        name: 'GYM. Luis  Pimba Alvarado',
        address: 'Carr. 65 Infanteria Lajas Pueblo',
        lat: 18.04254,
        lng: -67.05836,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.05836, 18.04254] },
    },
    {
      type: 'Feature',
      properties: {
        id: '375',
        municipality: 'Culebra',
        name: 'HealthProMed Clinica Culebra',
        address: 'Calle Pedro Márquez #44',
        lat: 18.303255866000029,
        lng: -65.299930725999957,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-65.299930725999957, 18.303255866000029],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '376',
        municipality: 'Guaynabo',
        name: 'HealthProMed Clinica Guaynabo',
        address: '',
        lat: 18.35787972200006,
        lng: -66.112055853999948,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.112055853999948, 18.35787972200006],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '377',
        municipality: 'San Juan',
        name: 'HealthProMed Clinica San Juan Escolar',
        address: '',
        lat: 18.437382,
        lng: -66.0558285,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.055828499999961, 18.437382],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '378',
        municipality: 'San Juan',
        name: 'HealthProMed Clinica Santurce',
        address: '2020 Avenida Borinquen, Santurce',
        lat: 18.437598,
        lng: -66.0558915,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.055891499999973, 18.437598],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '379',
        municipality: 'Vieques',
        name: 'HealthProMed Clinica Vieques',
        address: 'c/ 65 Infanteria #171, Vieques',
        lat: 18.148511732000031,
        lng: -65.442569831999947,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-65.442569831999947, 18.148511732000031],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '380',
        municipality: 'Coamo',
        name: 'MED Centro Clinica Coamo',
        address: '',
        lat: 18.080700436000029,
        lng: -66.356321295999976,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.356321295999976, 18.080700436000029],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '381',
        municipality: 'Peñuelas',
        name: 'MED Centro Clinica Penuelas',
        address: 'Bo. Cuevas, Carr. 387, Km 0.5, Peñuelas',
        lat: 18.046,
        lng: -66.717640223999979,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.717640223999979, 18.046],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '382',
        municipality: 'Ponce',
        name: 'MED Centro Clinica Ponce El Tuque',
        address: 'Calle H N-9 Sector Nueva Vida, El Tuque, Ponce',
        lat: 17.993498603000031,
        lng: -66.661861033999969,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.661861033999969, 17.993498603000031],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '383',
        municipality: 'Ponce',
        name: 'MED Centro Clinica Ponce La Cantera',
        address: '72 calle Mayor, La Cantera, Ponce',
        lat: 18.01728268200003,
        lng: -66.612301675999959,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.612301675999959, 18.01728268200003],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '384',
        municipality: 'Ponce',
        name: 'MED Centro Clinica Ponce Playa',
        address: '1034 Avenida Hostos, Ponce',
        lat: 17.991946369000061,
        lng: -66.615805586999954,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.615805586999954, 17.991946369000061],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '385',
        municipality: 'Villalba',
        name: 'MED Centro Clinica Villalba',
        address: 'Villalba Sector Arriba, Carr 149 Km 61.1, Villalba',
        lat: 18.12398743000006,
        lng: -66.500991619999979,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.500991619999979, 18.12398743000006],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '386',
        municipality: 'Cabo Rojo ',
        name: 'Migrant Clinica Cabo Rojo',
        address: '',
        lat: 18.087145503000041,
        lng: -67.146160346999977,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-67.146160346999977, 18.087145503000041],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '387',
        municipality: 'Guánica',
        name: 'Migrant Clinica Guanica',
        address: 'Bo. Ensenada, El batey, Carr 324, Guanica',
        lat: 17.966509497000061,
        lng: -66.937906144999943,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.937906144999943, 17.966509497000061],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '388',
        municipality: 'Isabela',
        name: 'Migrant Clinica Isabela',
        address: 'Carr 457 Km 2.2 Bo. Planas, Isabela',
        lat: 18.386889385000071,
        lng: -66.945687150999959,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.945687150999959, 18.386889385000071],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '389',
        municipality: 'Lajas ',
        name: 'Migrant Clinica Lajas',
        address: 'Carr. 101 Km. 7.1 Barrio Palmarejo, Lajas',
        lat: 18.042775838000068,
        lng: -67.074563547999958,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-67.074563547999958, 18.042775838000068],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '390',
        municipality: 'Las Marías',
        name: 'Migrant Clinica Las Marias',
        address: 'Calle Luis Montalvo #119, Bo. Maravilla Norte, Las Marias',
        lat: 18.255575681000039,
        lng: -66.990823585999976,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.990823585999976, 18.255575681000039],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '391',
        municipality: 'Maricao',
        name: 'Migrant Clinica Maricao',
        address: 'Calle Zusuaregui # 7',
        lat: 18.181242,
        lng: -66.979728,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.979728, 18.181242],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '392',
        municipality: 'Mayaguez',
        name: 'Migrant Clinica Mayaguez AMA',
        address: '#189 Ave. Dunscombe, Mayagüez',
        lat: 18.193858655000039,
        lng: -67.154887967999969,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-67.154887967999969, 18.193858655000039],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '393',
        municipality: 'Mayaguez ',
        name: 'Migrant Clinica Mayaguez Central',
        address: 'Calle Ramón E. Betances 393, Mayaguez',
        lat: 18.184817874000029,
        lng: -67.143508846999964,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-67.143508846999964, 18.184817874000029],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '394',
        municipality: 'Mayaguez',
        name: 'Migrant Clinica Mayaguez La Salud',
        address: 'Calle San Rafael #222, Mayagüez',
        lat: 18.199790649000018,
        lng: -67.13359625399994,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-67.13359625399994, 18.199790649000018],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '395',
        municipality: 'San Sebastian',
        name: 'Migrant Clinica San Sebastian',
        address: 'Carr. 119 Km. 35.2 Barrio Piedras Blancas, San Sebastian',
        lat: 18.32994636900003,
        lng: -66.986442457999942,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.986442457999942, 18.32994636900003],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '396',
        municipality: 'Yauco',
        name: 'Migrant Clinica Yauco',
        address: 'Carr 128 Km 4.1, Almacigo Bajo Yauco',
        lat: 18.053554469000058,
        lng: -66.856847066999933,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.856847066999933, 18.053554469000058],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '397',
        municipality: 'Morovis',
        name: 'Morovis Clinica Morovis',
        address: 'c/patron #2, Morovis',
        lat: 18.326357542000039,
        lng: -66.404616759999954,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.404616759999954, 18.326357542000039],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '398',
        municipality: 'Toa Baja',
        name: 'Morovis Clinica Toa Baja',
        address: 'Carr 863 Km 1.0 Sector Pajaros, Candelaria, Toa Baja',
        lat: 18.398397445000061,
        lng: -66.206519713999967,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.206519713999967, 18.398397445000061],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '399',
        municipality: 'Vega Alta',
        name: 'Morovis Clinica Vega Alta',
        address: '',
        lat: 18.413059433000061,
        lng: -66.324413193999987,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.324413193999987, 18.413059433000061],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '400',
        municipality: 'Aguas Buenas',
        name: 'NeoMed Clinica Aguas Buenas',
        address: 'Calle Rafael Laza #32 Esquina Monserrate, Aguas Buenas',
        lat: 18.257213408000041,
        lng: -66.105682681999951,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.105682681999951, 18.257213408000041],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '401',
        municipality: 'Aguas Buenas',
        name: 'NeoMed Clinica Bayamoncito',
        address: 'Bo. Bayamoncito Parcelas, Carr 156 Km 41.3, Aguas Buenas',
        lat: 18.237181341000049,
        lng: -66.165915418999987,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.165915418999987, 18.237181341000049],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '402',
        municipality: 'Gurabo',
        name: 'NeoMed Clinica Gurabo',
        address: 'Carr 941 salida Bo. Jaguas C/ San Antonio Final, Gurabo',
        lat: 18.257624581000069,
        lng: -65.975010055999974,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-65.975010055999974, 18.257624581000069],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '403',
        municipality: 'Juncos',
        name: 'NeoMed Clinica Juncos',
        address: 'Carr #31 Km 22.6 Bo. Ceiba Norte, Juncos',
        lat: 18.230359637000049,
        lng: -65.908460195999965,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-65.908460195999965, 18.230359637000049],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '404',
        municipality: 'Naguabo',
        name: 'NeoMed Clinica Naguabo',
        address: '',
        lat: 18.212062804000031,
        lng: -65.735253126999964,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-65.735253126999964, 18.212062804000031],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '405',
        municipality: 'San Lorenzo',
        name: 'NeoMed Clinica San Lorenzo',
        address: 'c/ Condado #11 San Lorenzo',
        lat: 18.18515444600007,
        lng: -65.96215058599995,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-65.96215058599995, 18.18515444600007],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '406',
        municipality: 'Trujillo Alto',
        name: 'NeoMed Clinica Trujillo Alto',
        address: 'c/ Carite #130, Urb. Lago Alto, Trujillo Alto',
        lat: 18.363662710000028,
        lng: -66.00502094999996,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.00502095, 18.363662710000028],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '407',
        municipality: 'Ciales',
        name: 'Nueva Urbana',
        address: 'Ext. Corchado Ave. Bo. Pueblo',
        lat: 18.33839158,
        lng: -66.46677009,
        category: 'shelter',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.46677009, 18.33839158],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '408',
        municipality: 'San Juan ',
        name: 'Orat+I65:I373orio San Juan Bosco',
        address: 'Calle Constitución, Cantera Santurce',
        lat: 18.4313,
        lng: -66.0418,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-66.0418, 18.4313] },
    },
    {
      type: 'Feature',
      properties: {
        id: '409',
        municipality: 'Hormigueros',
        name: 'Pabellón de Gimnasio',
        address: 'Carr. 345 KM. 1.7 Hormigueros',
        lat: 18.13844,
        lng: -67.11944,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-67.11944, 18.13844] },
    },

    {
      type: 'Feature',
      properties: {
        id: '410',
        municipality: 'Ciales',
        name: 'PryMed Clinica Ciales',
        address: 'Carr #149 Km 13, Ciales',
        lat: 18.34142108900005,
        lng: -66.468949021999947,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.468949021999947, 18.34142108900005],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '411',
        municipality: 'Vega Baja',
        name: 'PryMed Clinica Vega Baja',
        address: 'Carr 2 Km 39.8 Bo. Algarrobo, Vega Baja',
        lat: 18.44721368700004,
        lng: -66.400942737999969,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.400942737999969, 18.44721368700004],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '412',
        municipality: 'Juncos ',
        name: 'Refugio Municipal Antigua Escuela Los Lirios (Mirador del Deporte)',
        address: 'Bo. Los Lirios Carr. 929 Juncos',
        lat: 18.2249,
        lng: -65.93711,
        category: 'shelter',
      },
      geometry: { type: 'Point', coordinates: [-65.93711, 18.2249] },
    },
    {
      type: 'Feature',
      properties: {
        id: '413',
        municipality: 'Caguas ',
        name: 'SANOS Clinica Caguas Centro',
        address: 'Consolidate Mall Ave. Gautier Benitez, Caguas',
        lat: 18.233604046000039,
        lng: -66.030283236999935,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.030283236999935, 18.233604046000039],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '414',
        municipality: 'Caguas ',
        name: 'SANOS Clinica Caguas Consolidated',
        address: 'Ave. Rafael Cordero esq. Calle Troche, Caguas',
        lat: 18.218630058000031,
        lng: -66.040789016999952,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.040789016999952, 18.218630058000031],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '415',
        municipality: 'Caguas',
        name: 'SANOS Clinica Caguas Los Prados',
        address: '',
        lat: 18.236011298000051,
        lng: -66.057777378999958,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.057777378999958, 18.236011298000051],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '416',
        municipality: 'Barranquitas',
        name: 'SIM Clinica Barranquitas',
        address: 'c/Barcelo #53 Carr 156, Barranquitas',
        lat: 18.186080447000052,
        lng: -66.303010055999948,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.303010055999948, 18.186080447000052],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '417',
        municipality: 'Bayamón',
        name: 'SIM Clinica Bayamon',
        address: 'Carr 167 Km 1.8 Bo. Pajaros Puertoriquenos, Bayamon',
        lat: 18.363374603000072,
        lng: -66.189302158999965,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.189302158999965, 18.363374603000072],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '418',
        municipality: 'Comerío',
        name: 'SIM Clinica Comerio',
        address: 'c/Georgetti #18 Carr 167, Comerio',
        lat: 18.22120019800008,
        lng: -66.225636830999974,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.225636830999974, 18.22120019800008],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '419',
        municipality: 'Corozal',
        name: 'SIM Clinica Corozal',
        address: 'Carr 891 Km 15.1 Bo. Pueblo, Corozal',
        lat: 18.341962011000081,
        lng: -66.31254525099996,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.31254525099996, 18.341962011000081],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '420',
        municipality: 'Toa Alta ',
        name: 'SIM Clinica Naranjito',
        address: 'Calle Barcelo 16 Barrio Pueblo, Toa Alta',
        lat: 18.389317333000061,
        lng: -66.247907933999954,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.247907933999954, 18.389317333000061],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '421',
        municipality: 'Naranjito',
        name: 'SIM Clinica Naranjito Central',
        address: 'Carr 164 Sector El Desvio, Bo. Achiote, Naranjito',
        lat: 18.30311110600007,
        lng: -66.243557058999954,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.243557058999954, 18.30311110600007],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '422',
        municipality: 'Orocovis',
        name: 'SIM Clinica Orocovis',
        address:
          'Ave. Luis Muñoz Marín Carr. 155 Sector El Desvío, Orocovis\n\n',
        lat: 18.226627933000032,
        lng: -66.394196647999934,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.394196647999934, 18.226627933000032],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '423',
        municipality: 'Toa Alta',
        name: 'SIM Clinica Toa Alta I',
        address: 'Ave. Luis Muñoz Rivera # 86, Toa Alta',
        lat: 18.387770998000061,
        lng: -66.249487502999955,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.249487502999955, 18.387770998000061],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: '424',
        municipality: 'Toa Alta',
        name: 'SIM Clinica Toa Alta II',
        address: 'Bo. Quebrada Cruz, Carr 165 Km 4.5, Toa Alta',
        lat: 18.342329311000071,
        lng: -66.276292072999979,
        category: 'clinic',
      },
      geometry: {
        type: 'Point',
        coordinates: [-66.276292072999979, 18.342329311000071],
      },
    },
  ],
};
export default shelters;
