import { createContext, useContext, useMemo, useState } from 'react';

export const MapContext = createContext({
  map: null,
  setMap: () => {},
});

function MapProvider({ children }) {
  const [map, setMap] = useState(null);

  const value = useMemo(
    () => ({
      map,
      setMap,
    }),
    [map]
  );

  return <MapContext.Provider value={value}>{children}</MapContext.Provider>;
}

export default MapProvider;

export const useMap = () => useContext(MapContext);
