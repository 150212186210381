import { useRef, useState } from 'react';
import {
  arrow,
  autoUpdate,
  FloatingArrow,
  offset,
  useFloating,
  useHover,
  useInteractions,
} from '@floating-ui/react';
import { AnimatePresence, motion } from 'framer-motion';

function Tooltip({ content, children, placement = 'top' }) {
  const arrowRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    whileelementsmounted: autoUpdate,
    placement,
    middleware: [
      offset(7),
      arrow({
        element: arrowRef,
      }),
    ],
  });
  const hover = useHover(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

  return (
    <motion.div>
      <div ref={refs.setReference} {...getReferenceProps()}>
        {children}
      </div>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="font-medium text-xs text-center text-white bg-neutral-900/90 p-2 rounded z-10 max-w-xs"
          >
            {content}
            <FloatingArrow
              ref={arrowRef}
              context={context}
              tipRadius={2}
              className="fill-neutral-900/90"
            />
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}

export default Tooltip;
