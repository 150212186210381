import { AnimatePresence, motion } from 'framer-motion';
import { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useMount } from 'react-use';
import {
  dataAtom,
  favoritesAtom,
  loadingAtom,
  subsetAtom,
} from '../../atoms/atoms';
import shelters from '../../utils/shelters';
import Footer from './Footer';
import Header from './header/Header';
import Loader from './Loader';
import Map from './map/Map';
import Sidebar from './sidebar/Sidebar';

function Layout() {
  const [loading, setLoading] = useRecoilState(loadingAtom);
  const setData = useSetRecoilState(dataAtom);
  const setSubset = useSetRecoilState(subsetAtom);
  const setFavorites = useSetRecoilState(favoritesAtom);

  useMount(() => {
    setFavorites(JSON.parse(localStorage.getItem('favorites') || '[]'));
  });

  useEffect(() => {
    if (shelters) {
      setData(shelters);
      setSubset(shelters);
      setTimeout(() => {
        setLoading(false);
      }, 800);
    }
  }, [shelters]);

  return (
    <AnimatePresence mode="wait">
      {loading ? (
        <div className="flex h-screen overflow-hidden text-white bg-neutral-950">
          <motion.div
            initial={{ opacity: 0, scale: 0, y: -10 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0, y: -10 }}
            className="flex flex-1 flex-col items-center justify-center space-y-3 "
          >
            <Loader />
            <div className="text-xs text-neutral-400 font-semibold font-mono">
              Cargando Datos...
            </div>
          </motion.div>
        </div>
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="h-screen overflow-hidden bg-neutral-100"
        >
          <Header />
          <div className="flex" style={{ height: `calc(100vh - 120px)` }}>
            <Sidebar />
            <Map />
          </div>
          <Footer />
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default Layout;
