import _ from 'lodash';
import { matchSorter } from 'match-sorter';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { dataAtom, subsetAtom, termAtom } from '../../../../atoms/atoms';
import Counter from './Counter';
import SearchBar from './SearchBar';
import Legend from './Legend';
import Item from './Item';

function DesktopSidebar() {
  const data = useRecoilValue(dataAtom);
  const subset = useRecoilValue(subsetAtom);
  const term = useRecoilValue(termAtom);

  return (
    <aside
      style={{ height: `calc(100vh - 120px)` }}
      className="relative lg:w-96 hidden lg:flex flex-col flex-shrink-0 bg-white text-neutral-700"
    >
      <SearchBar />
      <div className="flex-1 overflow-y-auto divide-y scrollbar-hide">
        {_.map(
          _.sortBy(
            matchSorter(subset?.features || data?.features, term, {
              keys: [
                'properties.name',
                'properties.municipality',
                'properties.address',
              ],
            }),
            (item) => {
              return item.properties?.route?.duration || item.properties.name;
            }
          ),
          (feature) => (
            <Item key={feature.properties.id} feature={feature} />
          )
        )}
      </div>
      <Legend />
      <Counter />
    </aside>
  );
}

export default DesktopSidebar;
