import { matchSorter } from 'match-sorter';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { dataAtom, subsetAtom, termAtom } from '../../../../atoms/atoms';

function Counter() {
  const data = useRecoilValue(dataAtom);
  const subset = useRecoilValue(subsetAtom);
  const term = useRecoilValue(termAtom);

  return (
    <div className="w-full font-mono text-right text-xs text-neutral-100 bg-neutral-800 p-4">
      <span>Mostrando </span>
      <span>
        {
          matchSorter(subset?.features || data?.features, term, {
            keys: [
              'properties.name',
              'properties.municipality',
              'properties.address',
            ],
          }).length
        }
      </span>
      <span> de {data?.features.length}</span>
    </div>
  );
}

export default Counter;
