import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';

function Legend() {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="border-t text-xs">
      <motion.button
        initial={false}
        animate={{
          backgroundColor: expanded ? '#1f2937' : '#f5f5f5',
          color: expanded ? '#ffffff' : '#525252',
        }}
        onClick={() => setExpanded(!expanded)}
        className="flex p-4 items-center justify-between w-full text-left text-xs font-bold text-neutral-600 ring-0 focus:ring-0 outline-none focus:outline-none"
      >
        <div className="flex items-center gap-1">
          <div className="w-2 h-2 bg-orange-400" />
          <div className="w-2 h-2 bg-sky-400" />
          <div className="w-2 h-2 bg-pink-400" />
          <span>Leyenda</span>
        </div>
        <ChevronUpIcon
          className={clsx(
            expanded && 'rotate-180',
            'h-5 w-5 transform transition duration-500 ease-in-out'
          )}
        />
      </motion.button>
      <AnimatePresence initial={false}>
        {expanded && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{
              duration: 0.8,
              ease: [0.04, 0.62, 0.23, 0.98],
            }}
          >
            <motion.div
              variants={{
                collapsed: { scale: 0.8 },
                open: { scale: 1 },
              }}
              transition={{ duration: 0.8 }}
              className="border-t p-4 space-y-1"
            >
              <div className="flex items-center gap-1">
                <div className="w-3 h-3 bg-sky-400 rounded-full" />
                <div className="font-medium">Clínica de Salud</div>
              </div>
              <div className="flex items-center gap-1">
                <div className="w-3 h-3 bg-orange-400 rounded-full" />
                <div className="font-medium">
                  Refugio (Escuela, Centro Comunal, etc.)
                </div>
              </div>
            </motion.div>
          </motion.section>
        )}
      </AnimatePresence>
    </div>
  );
}

export default Legend;
