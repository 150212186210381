import clsx from 'clsx';
import { CursorArrowRippleIcon, StarIcon } from '@heroicons/react/20/solid';
import { motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import _ from 'lodash';
import * as turf from '@turf/turf';
import IconButton from '../../IconButton';
import { Arrows } from '../../Icons';
import Driving from '../../Driving';
import {
  activeAtom,
  favoritesAtom,
  routeAtom,
  sidebarAtom,
  waypointAtom,
} from '../../../../atoms/atoms';
import { useMap } from '../../../../context/MapProvider';
import Tooltip from '../../Tooltip';

function Item({ feature }) {
  const { map } = useMap();
  const setWaypoint = useSetRecoilState(waypointAtom);
  const setRoute = useSetRecoilState(routeAtom);
  const setOpen = useSetRecoilState(sidebarAtom);
  const [active, setActive] = useRecoilState(activeAtom);
  const [favorites, setFavorites] = useRecoilState(favoritesAtom);

  const getRoute = async (ft) => {
    if (ft.properties?.route) {
      const route = {
        type: 'Feature',
        properties: _.omit(ft.properties?.route, ['geometry']),
        geometry: ft.properties?.route.geometry,
      };

      const waypoint = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: ft.properties,
            geometry: {
              coordinates:
                route.geometry.coordinates[
                  route.geometry.coordinates.length - 1
                ],
              type: 'Point',
            },
          },
        ],
      };

      const bbox = turf.bbox(route);

      map.fitBounds(bbox, {
        padding: { top: 100, bottom: 100, left: 100, right: 100 },
      });

      map.getSource('route').setData(route);
      map.getSource('waypoint').setData(waypoint);
      setRoute(route);
      setWaypoint(waypoint);
      setOpen(false);
    }
  };

  const toggleFavorite = (ft) => {
    const id = _.get(ft, 'properties.id');
    if (!id) return;

    const index = _.findIndex(favorites, (f) => {
      return f.properties.id === ft.properties.id;
    });

    if (index === -1) {
      setFavorites([...favorites, ft]);
    } else {
      const newFavorites = [...favorites];
      newFavorites.splice(index, 1);
      setFavorites(newFavorites);
    }
  };

  useEffect(() => {
    localStorage.setItem('favorites', JSON.stringify(favorites));
  }, [favorites]);

  return (
    <motion.div
      key={feature.properties.id}
      id={`facility-${feature.properties.id}`}
      className={clsx(
        active?.properties?.id === feature.properties.id
          ? 'bg-neutral-50'
          : 'bg-white',
        'flex items-start p-4 gap-2'
      )}
    >
      <span className="relative flex h-2 w-2 mt-1">
        {active?.properties?.id === feature.properties.id && (
          <span
            className={clsx(
              feature?.properties?.category === 'shelter'
                ? 'bg-orange-400'
                : 'bg-sky-400',
              'animate-ping absolute inline-flex h-full w-full rounded-full opacity-75'
            )}
          />
        )}
        <span
          className={clsx(
            feature?.properties?.category === 'shelter'
              ? 'bg-orange-400 ring-orange-500'
              : 'bg-sky-400 ring-sky-500',
            'relative inline-flex rounded-full h-2 w-2'
          )}
        />
      </span>
      <div className="flex-1">
        <div
          className={clsx(
            'flex items-center justify-between font-bold text-xs text-neutral-600'
          )}
        >
          <span>{feature.properties.name}</span>
          <Tooltip content="Añadir a favoritos" placement="left">
            <motion.button
              whileTap={{ scale: 0.8 }}
              type="button"
              onClick={() => toggleFavorite(feature)}
              className="ring-0 focus:ring-0 outline-none focus:outline-none"
            >
              <StarIcon
                className={clsx(
                  _.find(favorites, (f) => {
                    return f.properties.id === feature.properties.id;
                  })
                    ? 'text-amber-400 hover:text-amber-500'
                    : 'text-neutral-300 hover:text-neutral-400',
                  'w-4 h-4 transition duration-500 ease-in-out'
                )}
              />
            </motion.button>
          </Tooltip>
        </div>
        <div className="text-neutral-400 text-xs">
          {feature.properties.address}
        </div>
        <div className="text-neutral-400 text-xs">
          {feature.properties.municipality}
        </div>
        <div className="flex space-x-2 mt-2">
          <IconButton
            Icon={Arrows}
            tooltip="Ver direcciones en Google Maps"
            tooltipPlacement="right"
            onClick={() => {
              window.open(
                `https://maps.google.com?q=${feature.geometry.coordinates[1]},${feature.geometry.coordinates[0]}`
              );
            }}
          />
          <IconButton
            Icon={CursorArrowRippleIcon}
            tooltip="Ver mas información"
            tooltipPlacement="right"
            onClick={() => setActive(feature)}
          />
          {feature.properties.route && (
            <Driving
              duration={feature.properties.route?.duration}
              distance={feature.properties.route?.distance}
              onClick={() => {
                const emptyGeoJSON = {
                  type: 'FeatureCollection',
                  features: [],
                };

                if (map) {
                  map.getSource('step').setData(emptyGeoJSON);
                }

                setActive(feature);
                if (feature.properties.route) {
                  getRoute(feature);
                }
              }}
            />
          )}
        </div>
      </div>
    </motion.div>
  );
}

export default Item;
