import { atom } from 'recoil';

export const loadingAtom = atom({
  key: 'loading',
  default: true,
});

export const dataAtom = atom({
  key: 'data',
  default: null,
});

export const subsetAtom = atom({
  key: 'subset',
  default: null,
});

export const bufferAtom = atom({
  key: 'buffer',
  default: null,
});

export const waypointAtom = atom({
  key: 'waypoint',
  default: null,
});

export const geoLocationAtom = atom({
  key: 'geolocation',
  default: null,
});

export const routeAtom = atom({
  key: 'route',
  default: null,
});

export const termAtom = atom({
  key: 'term',
  default: '',
});

export const sidebarAtom = atom({
  key: 'sidebar',
  default: false,
});

export const activeAtom = atom({
  key: 'active',
  default: null,
});

export const positionAtom = atom({
  key: 'position',
  default: null,
});

export const loadingPositionAtom = atom({
  key: 'loading-position',
  default: false,
});

export const showAtom = atom({
  key: 'show',
  default: false,
});

export const filterAtom = atom({
  key: 'filter',
  default: {},
});

export const municipalityAtom = atom({
  key: 'municipality',
  default: null,
});

export const stepAtom = atom({
  key: 'step',
  default: null,
});

export const favoritesAtom = atom({
  key: 'favorites',
  default: [],
});
